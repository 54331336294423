import { combineReducers } from 'redux'

import configReducer from './config/reducer'
import dashboardReducer from './dashboard/reducer'
import estimateReducer from './estimate/reducer'
import estimateItemsReducer from './estimateItems/reducer'
import estimatesReducer from './estimates/reducer'
import jobReducer from './job/reducer'
import orderReducer from './order/reducer'
import jobItemsReducer from './jobItems/reducer'
import jobsReducer from './jobs/reducer'
import newEstimateReducer from './newEstimate/reducer'
import newInspectionReducer from './newInspection/reducer'
import newRepairListReducer from './newRepairList/reducer'
import paymentsReducer from './payments/reducer'
import paymentReducer from './payment/reducer'
import propertiesReducer from './properties/reducer'
import propertyDetailReducer from './propertyDetail/reducer'
import propertyContactsReducer from './propertyContacts/reducer'
import propertyActivityReducer from './propertyActivity/reducer'
import territoriesReducer from './territories/reducer'
import userReducer from './user/reducer'
import invoiceReducer from './invoice/reducer'
import adminReducer from './admin/reducer'
import orderMaintenanceReducer from './orderMaintenance/reducer'
import newOrderMaintenanceReducer from './newOrderMaintenance/reducer'
import subscriptionReducer from './subscription/reducer'
import subscriptionsReducer from './subscriptions/reducer'
import discountsReducer from './discounts/reducer'
import itemsReducer from './items/reducer'
import campaignsReducer from './campaigns/reducer'
import homemartReducer from './homemart/reducer'
import approvalReducer from './approval/reducer'

import { ConfigType } from './config/types'
import { DashboardType } from './dashboard/types'
import { EstimateType } from './estimate/types'
import { EstimateItemsType } from './estimateItems/types'
import { EstimatesType } from './estimates/types'
import { JobType } from './job/types'
import { OrderType } from './order/types'
import { JobItemsType } from './jobItems/types'
import { JobsType } from './jobs/types'
import { NewEstimateType } from './newEstimate/types'
import { NewInspectionType } from './newInspection/types'
import { NewRepairListType } from './newRepairList/types'
import { PaymentsType } from './payments/types'
import { PaymentType } from './payment/types'
import { PropertiesType } from './properties/types'
import { PropertyDetailType } from './propertyDetail/types'
import { TerritoriesType } from './territories/types'
import { UserType } from './user/types'
import { InvoiceType } from './invoice/types'
import { AdminType } from './admin/types'
import { OrderMaintenanceType } from './orderMaintenance/types'
import { NewOrderMaintenanceType } from './newOrderMaintenance/types'
import { SubscriptionType } from './subscription/types'
import { SubscriptionsType } from './subscriptions/types'
import { DiscountsType } from './discounts/types'
import { ItemsType } from './items/types'
import { CampaignsType } from './campaigns/types'
import { PropertyContactsType } from './propertyContacts/types'
import { PropertyActivityType } from './propertyActivity/types'
import { ApprovalType } from './approval/types'

export interface State {
  config: ConfigType
  dashboard: DashboardType
  estimate: EstimateType
  estimateItems: EstimateItemsType
  estimates: EstimatesType
  job: JobType
  order: OrderType
  jobItems: JobItemsType
  jobs: JobsType
  newEstimate: NewEstimateType
  newInspection: NewInspectionType
  newRepairList: NewRepairListType
  payments: PaymentsType
  payment: PaymentType
  properties: PropertiesType
  propertyDetail: PropertyDetailType
  propertyContacts: PropertyContactsType
  propertyActivity: PropertyActivityType
  territories: TerritoriesType
  user: UserType
  invoice: InvoiceType
  admin: AdminType[]
  subscription: SubscriptionType
  subscriptions: SubscriptionsType
  orderMaintenance: OrderMaintenanceType
  newOrderMaintenance: NewOrderMaintenanceType
  discounts: DiscountsType
  items: ItemsType
  campaigns: CampaignsType
  approval: ApprovalType
}

const rootReducer = combineReducers({
  config: configReducer,
  dashboard: dashboardReducer,
  estimate: estimateReducer,
  estimateItems: estimateItemsReducer,
  estimates: estimatesReducer,
  job: jobReducer,
  order: orderReducer,
  jobItems: jobItemsReducer,
  jobs: jobsReducer,
  newEstimate: newEstimateReducer,
  newInspection: newInspectionReducer,
  newRepairList: newRepairListReducer,
  payments: paymentsReducer,
  payment: paymentReducer,
  properties: propertiesReducer,
  propertyDetail: propertyDetailReducer,
  propertyContacts: propertyContactsReducer,
  propertyActivity: propertyActivityReducer,
  territories: territoriesReducer,
  user: userReducer,
  invoice: invoiceReducer,
  admin: adminReducer,
  subscription: subscriptionReducer,
  subscriptions: subscriptionsReducer,
  homemart: homemartReducer,
  orderMaintenance: orderMaintenanceReducer,
  newOrderMaintenance: newOrderMaintenanceReducer,
  discounts: discountsReducer,
  items: itemsReducer,
  campaigns: campaignsReducer,
  approval: approvalReducer,
})

export default rootReducer
