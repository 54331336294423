import { Box, Fade, Modal, Typography } from '@material-ui/core';
import React from 'react'
import { icons } from 'assets';
import useStyles from './styles'
import { isMobile } from 'react-device-detect'

type Props = {
    children: React.ReactNode;
    open: boolean;
    title?: string;
    onClose: () => void;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    bgcolor: 'background.paper',
    borderRadius: "8px",
    boxShadow: 24,
    p: 2,
};

const modalStyleMobile = {
    position: 'absolute' as 'absolute',
    bottom: '0%',
    left: '0%',
    width: "100%",
    bgcolor: 'background.paper',
    borderRadius: "8px 8px 0 0",
    boxShadow: 24,
    p: 2,
};

function BottomSheet({ children, title, open, onClose }: Props) {
    const classes = useStyles()
    return (
        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{ style: { backgroundColor: "rgba(26, 42, 85, 0.5)" } }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade timeout={350} in={open}>
                <Box sx={isMobile ? modalStyleMobile : modalStyle} style={{ zIndex: 99999 }}>
                    {title && <Typography className={classes.Title}>{title}</Typography>}
                    <Box onClick={onClose} className={classes.CloseButton}>
                        <icons.Close className={classes.CloseIcon} />
                    </Box>
                    <Box className={classes.Body}>
                        {children}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default BottomSheet