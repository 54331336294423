import { Box } from '@material-ui/core'
import { getEstimate, getUserPrimaryEmail } from 'ducks/selectors'
import { FC, ReactChildren } from 'react'
import { useSelector } from 'react-redux'

interface UnclickableStepProps {
  children?: any
}

const UnclickableStep: FC<UnclickableStepProps> = ({ children }) => {
  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)

  const showBanner = !!estimate?.properties.contacts?.find(
    (contact) => contact.approvalFlowInProgress && contact.email !== userEmail
  )

  return (
    <Box position="relative">
      <Box
        position="absolute"
        style={{ width: '100vw', height: '100%' }}
        zIndex={showBanner ? 10 : -10}
      />
      {children}
    </Box>
  )
}

export default UnclickableStep
