import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      margin: '0 auto',
      position: 'relative',
      gridTemplateRows: 'auto min-content',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '24px !important'
      }
    },
    stepper: {
      margin: '1.5rem 0',
      // position: "fixed",
      // bottom: 0,
      // left: 0,
      // width: "100%",
      // maxWidth: "1250px",
    },
    lgFont: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    backButton: {
      position: 'absolute',
      left: '24px',
      top: '30px',
      height: '44px',
      zIndex: 10,
      [theme.breakpoints.down('md')]: {
        top: '25px',
        left: '50px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '25px',
        left: '14px',
      }
    },
    content: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '0px 12px 0px 4px',
      maxWidth: '1250px ',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: '0px 12px 0px 12px',
      }
    },
    footer: {
      background: 'rgba(249, 250, 250, 1)',
      zIndex: 1
    },
    footerContent: {
      display: 'grid',
      gap: '4px',
      maxWidth: '1250px', // theme.breakpoints.values.xxl,
      width: '100%',
      margin: '0 auto',
      padding: '24px 0 12px 24px'
    }
  })
)
