import { USER_TYPE } from 'helpers'
import { authTypes } from 'ducks/auth/types'
import { configTypes, ConfigType, ConfigActionTypes, ConfigValue, ErrorValue } from './types'

const initialState: ConfigType = {
  publicLoading: false,
  userType: USER_TYPE.HOMEOWNER,
  paymentsOption: null,
  dashboardDateRange: null,
  estimateDateRange: null,
  estimateStatus: null,
  estimatePagination: null,
  estimateStats: [],
  jobDateRange: null,
  jobStatus: null,
  jobPagination: null,
  jobStats: [],
  overlayLoading: false,
  paymentsYear: null,
  paymentsFilter: null,
  estimatesServices: [],
  error: { show: false, type: '' },
  isAdmin: null,
  redirectAfterLogin: null,
  redirectPath: null,
  registerActiveStep: 0,
  showReferralModal: false,
  registrationLoading: false,
  registerImg: '',
  sideMenuOpen: true
}

const reducer = (state = initialState, action: ConfigActionTypes): ConfigType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return {
        ...initialState,
        redirectPath: state.redirectPath,
        registerActiveStep: state.registerActiveStep
      }
    }
    case authTypes.LOGOUT_REQUEST: {
      return {
        ...initialState,
        redirectPath: state.redirectPath
      }
    }

    case configTypes.SET_CONFIG_VALUE: {
      return {
        ...state,
        [(payload as ConfigValue).type]: (payload as ConfigValue).value
      }
    }
    case configTypes.SWITCH_USER_TYPE: {
      let newUserType = state.userType
      switch (newUserType) {
        case USER_TYPE.HOMEOWNER: newUserType = USER_TYPE.BROKER; break
        case USER_TYPE.BROKER: newUserType = USER_TYPE.INSTITUTIONAL; break
        case USER_TYPE.INSTITUTIONAL: newUserType = USER_TYPE.HOMEOWNER; break
      }
      return {
        ...state,
        userType: newUserType
      }
    }
    case configTypes.SET_ERROR: {
      return {
        ...state,
        error: {
          show: (payload as unknown as ErrorValue).show,
          type: (payload as unknown as ErrorValue).type
        }
      }
    }
    case configTypes.SET_MENU_OPEN: {
      return {
        ...state,
        sideMenuOpen: payload as unknown as boolean
      }
    }
    default:
      return state
  }
}

export default reducer
