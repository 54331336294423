import { icons, createAccountLoading } from 'assets'
import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Radio, RadioGroup, TextFieldLabel, Typography } from 'components/UI'
import { PrivateLoader } from 'components/templates'
import { newEstimateActions, newOrderMaintenanceActions } from 'ducks/actions'
import { getNewEstimateValues, getNewOrderMaintenanceValue, getNewOrderMaintenanceValues, getUser, isAuthorized } from 'ducks/selectors'
import { EstimateContact } from 'ducks/types'
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'
import { CONTACT_ROLE, PHONE_MASK_INPUT, history, isEmpty } from 'helpers'
import { useStepperContext } from 'hooks/useStepperContext'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './styles'

import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { NewEstimateType } from 'ducks/newEstimate/types'
import { isMobile } from 'react-device-detect'

const UserInformation = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    showWarnings, setCanGoForward, setShowWarnings, goBack, goForward
  } = useStepperContext()

  const clientType = useSelector(getNewOrderMaintenanceValue('clientType'))
  const step = useSelector(getNewOrderMaintenanceValue('step'))
  const purchaser = useSelector(getNewOrderMaintenanceValue('purchaser'))
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))
  const homeowner = useSelector(getNewOrderMaintenanceValue('homeowner'))

  const propertyAddress = useSelector(getNewOrderMaintenanceValue('propertyAddress'))
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const user = useSelector(getUser)

  const isOther = [CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)
  const isAgent = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)
  const isInstitutional = [CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)


  const [consent, setConsent] = useState(true)
  const [terms, setTerms] = useState(true)
  /*   const [phone, setPhone] = useState(user.phone ? user.phone?.toString() : contacts[0]?.phone ? contacts[0].phone : '') */
  const [phone, setPhone] = useState<string>(user?.phone?.toString() || mainContact?.phone?.toString() || '')

  const pushParams = history.usePushParams()


  const {
    preferredCommunicationPhone,
    preferredCommunicationMail,
    preferredCommunicationText
  } = useSelector(getNewOrderMaintenanceValues())

  const [validate, setValidate] = useState(false)
  //const isHomeOwnerRegistered = mainContact.registrationComplete && clientType === USER_TYPE.HOMEOWNER
  const [newContacts, setNewContacts] = useState<Partial<EstimateContact>[]>([])
  const validatePhone = !isEmpty(phone) && parseInt(phone?.replaceAll('-', ''))?.toString().length === 10

  const { saveNewValue } = useStepperContext()

  const authorized = useSelector(isAuthorized)

  const isValid = () => {
    if (authorized) return consent && terms && (preferredCommunicationPhone || preferredCommunicationMail || preferredCommunicationText)
    if (isOther) {
      return consent &&
        terms &&
        validatePhone &&
        !isEmpty(mainContact?.companyName?.trim()) &&
        !isEmpty(mainContact?.address?.zipCode) &&
        (preferredCommunicationPhone || preferredCommunicationMail || preferredCommunicationText)
    }
    else {
      return consent &&
        terms &&
        validatePhone &&
        (preferredCommunicationPhone || preferredCommunicationMail || preferredCommunicationText)
    }
  }

  const handleNext = () => {
    if (validate) {
      // setLoading(true)
      updateMainContact('phone', parseInt(phone?.replaceAll('-', '')))
      const preferredCommunicationMethods = []

      if (preferredCommunicationPhone) preferredCommunicationMethods.push('PHONE')
      if (preferredCommunicationMail) preferredCommunicationMethods.push('EMAIL')
      if (preferredCommunicationText) preferredCommunicationMethods.push('TEXT')

      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'purchaser', value: {
          ...purchaser,
          phone: parseInt(phone?.replaceAll('-', '')),
          preferredCommunicationMethods,
          nar: mainContact?.nar || false,
          companyName: mainContact?.companyName || '',
          address: {
            zipCode: mainContact?.address?.zipCode || user?.address?.zipCode || ''
          }
        }
      }))
      if (!gift) {
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'homeowner', value: {
            ...homeowner,
            phone: parseInt(phone?.replaceAll('-', '')),
            preferredCommunicationMethods,
            nar: mainContact?.nar || false,
            companyName: mainContact?.companyName || '',
            address: propertyAddress
          }
        }))
      }
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'step', value: step + 1
      }))
      // setLoading(false)

    } else setShowWarnings(true)
  }

  /*   const updateUser = (attr: keyof UserType, newValue: any) => {
      dispatch(userActions.setUserValue({
        attr,
        value: newValue
      }))
    } */


  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value }
      })
    )
  }

  const updateNewOrderMaintenance = useCallback(
    (attr: any, value: any) => {
      saveNewValue(attr, value)
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr,
          value,
        })
      )
    },
    [saveNewValue]
  )

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }, [mainContact, phone, consent, terms, preferredCommunicationMail, preferredCommunicationPhone, preferredCommunicationText])

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }, [consent, terms, preferredCommunicationPhone, preferredCommunicationMail, preferredCommunicationText, newContacts])

  useEffect(() => {
    !isOther && setNewContacts([...newContacts, {
      firstName: mainContact.firstName,
      lastName: mainContact.lastName ?? '',
      email: mainContact.email,
      phone: '',
      sendEstimate: true
    }])
  }, [])

  useEffect(() => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "activeStepProgress", value: isAgent ? 4 : 2 }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "totalStepProgress", value: isAgent ? 5 : 3 }))
  }, [])

  return (
    <div>
      <Grid container item spacing={2} className={classes.root}>
        <Grid container item direction="column" xs={12} className={classes.container}>
          <Typography variant='h4' className={classes.title}>
            {mainContact.firstName &&
              `${mainContact.firstName}, you're almost done!`
            }
          </Typography>

          <Grid item className={isOther ? classes.containerForm : classes.containerFormMax}>
            {!authorized &&
              <>
                <Typography variant='subtitle1' className={classes.text}>
                  Please provide your contact information in case we have questions about your maintenance.
                </Typography>

                <Grid item container spacing={2} xs={12}>
                  <>
                    <Grid item container spacing={2} style={isInstitutional && !isMobile ? { flexWrap: "nowrap", alignItems: "center" } : {}}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldLabel
                          label="Your Mobile Phone Number"
                          type='tel'
                          value={phone?.toString() || ''}
                          mask={PHONE_MASK_INPUT}
                          error={showWarnings && !validatePhone}
                          onChange={(event) => {
                            setPhone(event.target.value)
                            updateMainContact('phone', parseInt(event.target.value.replaceAll('-', '')))
                          }}
                          placeholder='555-555-1234'
                        />
                      </Grid>
                      {isAgent &&
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography className={classes.label}>
                            Are you a NAR member?
                          </Typography>
                          <RadioGroup
                            value={mainContact.nar || false}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => updateMainContact('nar', e.target.value === 'true')}
                            className={classes.radioContainer}
                          >
                            <FormRadioButton
                              style={mainContact.nar ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                              value={true}
                              control={<Radio color='primary' />}
                              label={<Typography variant='caption'>Yes</Typography>} className={classes.radio}
                            />
                            <FormRadioButton
                              style={!mainContact.nar ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                              value={false}
                              control={<Radio color='primary' />}
                              label={<Typography variant='caption'>No</Typography>} className={classes.radio}
                            />
                          </RadioGroup>
                        </Grid>}
                      {isInstitutional && <Grid item container spacing={2} style={!isMobile ? { marginTop: '0.8rem', marginBottom: '0.8rem' } : {}}>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextFieldLabel
                            label="Company Name"
                            error={showWarnings && isEmpty(mainContact.companyName.trim())}
                            onChange={(ev) => {
                              updateMainContact('companyName', ev.target.value)
                            }}
                            value={mainContact.companyName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextFieldLabel
                            label="Company Zip Code"
                            error={showWarnings && isEmpty(mainContact?.companyName?.trim())}
                            onChange={(ev) => {
                              updateMainContact('address', { zipCode: ev.target.value })
                            }}
                            type='number'
                            value={mainContact?.address?.zipCode || ''}
                          />
                        </Grid>
                      </Grid>}
                    </Grid>

                    {isAgent && <Grid item container spacing={2} style={{ marginTop: '0.8rem', marginBottom: '0.8rem' }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldLabel
                          label="Company Name"
                          error={showWarnings && isEmpty(mainContact?.companyName?.trim())}
                          onChange={(ev) => {
                            updateMainContact('companyName', ev.target.value)
                          }}
                          value={mainContact.companyName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldLabel
                          label="Company Zip Code"
                          error={showWarnings && isEmpty(mainContact?.address?.zipcode?.trim())}
                          onChange={(ev) => {
                            let zipcodeValidation = /^(\d{7}(?:-\d{6})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
                            const validZipcode = zipcodeValidation.test(ev.target.value)
                            if (ev.target.value.length <= 7) {
                              updateMainContact('address', { zipCode: ev.target.value })
                            } else {
                              if (validZipcode) {
                                updateMainContact('address', { zipCode: ev.target.value })
                              }
                            }
                          }}
                          type='text'
                          value={mainContact?.address?.zipCode || ''}
                        />
                      </Grid>
                    </Grid>}
                  </>
                </Grid>
              </>
            }

            <Grid item xs={12}>
              <Typography variant='body1' className={`${classes.subtitle}`}>
                What is your preferred method of communication? Check all that apply.
              </Typography>
              <Grid item container xs={12}
                className={classes.preferredContainer}
              // style={isMobile ? { gap: "1rem 0.25rem" } : {}}
              >
                <FormControlLabel
                  style={preferredCommunicationPhone ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  control={
                    <Checkbox
                      checked={preferredCommunicationPhone}
                      onChange={() => {
                        updateNewOrderMaintenance(
                          'preferredCommunicationPhone',
                          !preferredCommunicationPhone
                        )
                      }}
                      name='preferredPhone'
                      color='primary'
                    />
                  }
                  label={<Typography variant='body2'>Phone</Typography>}
                  className={classes.check}
                />
                <FormControlLabel
                  style={preferredCommunicationMail ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  control={
                    <Checkbox
                      checked={preferredCommunicationMail}
                      onChange={() => {
                        updateNewOrderMaintenance(
                          'preferredCommunicationMail',
                          !preferredCommunicationMail
                        )
                      }}

                      name='preferredMail'
                      color='primary'
                    />
                  }
                  label={<Typography variant='body2'>Email</Typography>}
                  className={classes.check}
                />
                <FormControlLabel
                  style={preferredCommunicationText ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  control={
                    <Checkbox
                      checked={preferredCommunicationText}
                      onChange={() => {
                        updateNewOrderMaintenance(
                          'preferredCommunicationText',
                          !preferredCommunicationText
                        )
                      }}
                      name='preferredText'
                      color='primary'
                    />
                  }
                  label={<Typography variant='body2'>Text</Typography>}
                  className={classes.check}
                />
              </Grid>
            </Grid>
            <Box className={classes.buttonsCheck}>
              <Grid container item xs={12} className={classes.consent}>
                <Checkbox
                  checked={consent}
                  onChange={() => setConsent(!consent)}
                  name='consent'
                  color='primary'
                  style={{
                    color: showWarnings && !consent ? 'red' : 'primary'
                  }}
                />
                <Typography variant='body1' className={`${classes.checkConsent} ${classes.homeOwnerConsent}`}>
                  I consent to receive SMS messages from BOSSCAT regarding my estimate and related services.
                </Typography>
              </Grid>
              <Grid container item xs={12} className={classes.legalContainer}>
                <Checkbox
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                  name='terms'
                  color='primary'
                  style={{
                    color: showWarnings && !terms ? 'red' : 'primary'
                  }}
                />
                <Box className={classes.acceptText}>
                  <Typography variant='body1' className={`${classes.checkTerms}`}>
                    I accept
                  </Typography>
                  <Link target='_blank' href='https://bosscathome.com/terms/' className={classes.legalLink}>BOSSCAT Legal Terms & Conditions <ArrowOutwardIcon style={{ color: "#2F7BF7", transform: "rotate(45deg)" }} /></Link>
                </Box>

              </Grid>
            </Box>
            {!authorized && clientType !== CONTACT_ROLE.LISTING_AGENT &&
              <Box className={classes.accountContainer}>
                <img src={createAccountLoading} alt="BOSSCAT loading" style={{ width: "48px", height: "48px" }} />
                <Typography className={classes.accountCopy}>A BOSSCAT account will be created, and a temporary password will be sent to your email.</Typography>
              </Box>
            }

            {/* <Grid item container>
              <Grid item>
                <img alt="temporary acount" src={temporaryAcount} />
              </Grid>
              <Grid item>
                <Typography>A BOSSCAT account will be created, and a temporary password will be sent to your email.</Typography>
              </Grid>
            </Grid> */}
            <Grid className={classes.buttonsContainer} container item xs={12} style={{ display: 'flex', marginTop: '1rem', width: '100%' }} justifyContent='space-between'>
              <Button
                type='submit'
                variant='outlined'
                size='small'
                className={classes.buttonBack}
                startIcon={<icons.ArrowBack />}
                onClick={goBack}
              >
                Back
              </Button>
              <Button
                type='submit'
                variant='contained'
                size='small'
                className={classes.buttonNext}
                endIcon={<icons.ArrowForward />}
                disabled={!validate}
                onClick={handleNext}
              >
                Go
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default UserInformation