/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import { subscriptionTypes, FetchSubscriptionAction, AddSubscriptionToNotificationListAction, CreateSubscriptionAction, UpdateSubscriptionAction } from './types'
import { orderMaintenanceActions, subscriptionActions, userActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'
import { getOrderMaintenanceValues } from 'ducks/selectors'

export function* fetchSubscription({ payload, callback }: FetchSubscriptionAction): SagaIterator {
  let success = false
  try {
    const subscription = yield call(Punchlist.subscriptions.getSubscription, payload)

    if (subscription) {
      yield put(subscriptionActions.setSubscription(subscription))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the subscription'))
    if (callback) yield call(callback, false)
  }
}

export function* addSubscriptionToNotificationList({
  callback,
  payload
}: AddSubscriptionToNotificationListAction): SagaIterator {
  try {
    yield call(Punchlist.subscriptions.addSubscriptionToNotificationList, payload)
    yield put(
      userActions.setUserValue({
        attr: 'onProtectPlanNotificationList',
        value: true
      })
    )
    if (callback) yield call(callback, true)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('add the subscription to the notification list'))
    if (callback) yield call(callback, false)
  }
}

export function* createSubscription({ payload, callback }: CreateSubscriptionAction): SagaIterator {
  let success = false
  const orderMaintenance = yield select(getOrderMaintenanceValues())

  try {
    if (orderMaintenance?.promo) {
      payload = { ...payload, price: orderMaintenance?.price - orderMaintenance.promo.amount }
    }
    const subscription = yield call(Punchlist.subscriptions.createSubscription, payload)

    if (subscription) {
      yield put(subscriptionActions.setSubscription(subscription))
      success = true
      // yield put(orderMaintenanceActions.setOrderMaintenance({ ...orderMaintenance, ...subscription }))
    }
    if (callback) yield call(callback, success, subscription?.clientSecret)
  } catch (error: any) {
    Sentry.captureException(error);
    if (error?.response?.data?.errorCode === "PL-0019") {
      yield call(toast.error, error?.response?.data?.message)
    } else {
      yield call(toast.error, errorTextTryingTo('create the subscription'))
    }
    if (callback) yield call(callback, false, null)
  }
}
export function* updateSubscription({ payload, callback }: UpdateSubscriptionAction): SagaIterator {
  let success = false

  try {
    const { subscriptionId, request } = payload;
    const subscription = yield call(Punchlist.subscriptions.updateSubscription, subscriptionId, request)

    if (!isEmpty(subscription)) {
      const orderMaintenance = yield select(getOrderMaintenanceValues())

      success = true
      yield put(subscriptionActions.setSubscription(subscription))
      success = true
      yield put(orderMaintenanceActions.setOrderMaintenance({ ...orderMaintenance, ...subscription }))
    }
    if (callback) yield call(callback, success, subscription)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the subscription plan'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(subscriptionTypes.FETCH_SUBSCRIPTION, fetchSubscription)
  yield takeLatest(subscriptionTypes.ADD_SUBSCRIPTION_TO_NOTIFICATION_LIST, addSubscriptionToNotificationList)
  yield takeLatest(subscriptionTypes.CREATE_SUBSCRIPTION, createSubscription)
  yield takeLatest(subscriptionTypes.UPDATE_SUBSCRIPTION, updateSubscription)
}
