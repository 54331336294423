import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'



export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      marginTop: '30px'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    rows: {
      display: 'flex',
      flexDirection: 'row'
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    subtitle: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    text: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 0px 0px 0px',
      color: 'var(--input-color)'
    },
    text2: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 0px 0px 0px',
      color: 'var(--input-color)'
    },
    detail: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '18px',
      fontSize: '12px',
      margin: '0px 0px 0px 0px',
      color: 'var(--input-color)'
    },
    total: {
      fontFamily: 'Lato',
      fontWeight: 700,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    totalValue: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 4px',
      color: 'var(--head-text-color)'
    },
    smallTotal: {
      fontSize: '20px'
    },
    icon: {
      height: '15px',
      color: 'var(--head-text-color)',
      margin: '2px 7px 0px 0px'
    },
    logo: {
      height: '66px',
      margin: '0px 9px 0px 0px'
    },
    pdf: {
      height: '64px',
      width: '64px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px'
      }
    },
    button: {
      height: '64px',
      width: '64px',
      margin: '0px 2px 0px 8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      },
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px',
        margin: '0px 2px 0px 5px'
      }
    },
    pendingFurtherContainer: {
      backgroundColor: theme.palette.warning.main,
      padding: '2px 4px',
      width: 'fit-content',
      borderRadius: '4px',
      color: theme.palette.text.primary,
    },
    email: {
      textOverflow: " ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    buttonContacts: {
      textTransform: 'capitalize',
      color: 'var(--bosscat-blue-600)',
      '@global': {
        '.MuiButton-label': {
          flexDirection: 'column',
          fontFamily: 'LatoNormal'
        },
        '.MuiButton-startIcon': {
          margin: 0,
          marginBottom: '4px'
        }
      }
    },
    modal: {
      '@global': {
        '.MuiPaper-root': {
          // minHeight: '80vh',
          // height: 'calc(100% - 64px)'
          // minHeight: '80vh',
          height: 'fit-content',
          paddingBottom: '1rem'
        },
        '.MuiDialogContent-root': {
          maxWidth: '520px',
        }
      }
    }
  })
)
