/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { invoiceTypes, FetchInvoiceAction } from './types'
import { invoiceActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchInvoice({ payload, callback }: FetchInvoiceAction): SagaIterator {
  let success = false
  try {
    const invoice = yield call(Punchlist.invoice.getInvoiceById, payload)

    if (invoice) {
      yield put(invoiceActions.setInvoice(invoice))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the invoice'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(invoiceTypes.FETCH_INVOICE, fetchInvoice)
}
