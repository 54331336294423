import { FC } from 'react'

import { Tooltip } from '../../../'
import { ValuesComponentProps } from './types'
import useStyles from './styles'
import { icons } from '../../../../../assets'
import { useClassName } from '../../../../../hooks'

const ValuesComponent: FC<ValuesComponentProps> = ({
  title,
  titleColor = 'primary',
  information,
  value,
  icon,
  height
}) => {
  const classes = useStyles()
  const className = useClassName()
  // const height = '120px'

  const getTitleColor = (): string => {
    switch (titleColor) {
      case 'primary':
        return classes.primaryColor
      case 'secondary':
        return classes.secondaryColor
      case 'info':
        return classes.informationColor
      case 'warning':
        return classes.warningColor
      case 'error':
        return classes.errorColor
      case 'success':
        return classes.successColor
      case 'approved':
        return classes.approvedColor
    }
  }

  const getIcon = (): JSX.Element => {
    switch (titleColor) {
      case 'primary':
        return <icons.FormatListNumbered className={classes.icon} />
      case 'secondary':
        return <icons.FormatListNumbered className={classes.icon} />
      case 'info':
        return <icons.Restore className={classes.icon} />
      case 'warning':
        return <icons.Alarm className={classes.icon} />
      case 'error':
        return <icons.Warning className={classes.icon} />
      case 'success':
        return <icons.CheckCircleOutline className={classes.icon} />
      case 'approved':
        return <icons.CheckCircle className={classes.icon} />
    }
  }

  return (
    <div
      className={className(classes.root, getTitleColor())}
      style={height ? { height: height } : {}}
    >
      <div className={classes.titleContainer}>
        {icon ?? getIcon()}
        <h1 className={classes.title}>{title}</h1>
      </div>
      {information ? <Tooltip title={information}>
        <icons.InfoOutlined className={classes.informationIcon} />
      </Tooltip> : null}
      <h1 className={classes.value}>
        {value}
      </h1>
    </div>
  )
}

export default ValuesComponent
