import { createStyles, makeStyles, Theme } from '../../..'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '640px',
      borderRadius: '32px',
      textAlign: 'center',
      backgroundColor: 'white'
    },
    title: {
      fontSize: '30px',
      padding: '0 4px 0 24px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px'
      }
    },
    title2: {
      fontSize: '30px',
      padding: '0 24px 0 4px',
      fontFamily: 'Lato',
      fontWeight: 400,
      color: 'var(--bosscat-blue-600) !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px'
      }
    },
    text: {
      fontSize: '20px',
      padding: '0 20px',
      fontFamily: 'LatoNormal',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
      }
    },
    image: {
      width: '192px',
      height: '192px',
      marginBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        width: '120px',
        height: '120px',
        marginBottom: '6px'
      }
    },
    submit: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'BetterSansRegular',
      '&:hover': {
        color: 'var(--progress-color-back) !important',
        backgroundColor: 'var(--button-focus-color)'
      }
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px'
    },
    titles: {
      display: 'flex',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  })
)
