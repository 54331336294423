import { Theme, createStyles, makeStyles } from 'components/UI/MaterialUI'

export default makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Lato',
            paddingBottom: '8px'
        },
        textfield: {
            width: '100%',
            marginTop: '8px',
            [theme.breakpoints.up('xl')]: {
                minWidth: '220px'
            },
            height: '54px',
            padding: '0px 20px 0px 20px',
            borderRadius: '8px',
            border: '2px solid var(--border-color)',
            shadowBox: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
            color: 'var(--text-color)',
            inputColor: '#FFFFFF',
            textDecorationColor: '#FFFFFF',
            fontFamily: 'LatoNormal',
            fontWeight: 400,
            fontSize: 16,
            transition: 'all 200ms',
            '&:hover': {
                borderColor: 'var(--border-color-focus)',
                transition: 'all 200ms',
                borderRadius: 8
            },
            '&.Mui-focused': {
                borderColor: 'var(--border-color-focus)',
                borderRadius: 8
            },
            '& .MuiInputBase-input': {
                '&::placeholder': {
                    color: 'var(--placeholder-color)'
                }
            }
        },
        inputError: {
            borderColor: 'var(--expired-color) !important'
        },
        root: {
            width: '100%',
        },
        clearIndicator: {
            display: 'none'
        },
        endAdornment: {
            top: 0
        },
        popupIndicator: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        popper: {
            margin: '6px 0 0 -24px',
            zIndex: 9999
        },
        option: {
            padding: '12px 20px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&[data-focus="true"]': {
                backgroundColor: 'var(--row-focus)',
                borderColor: 'transparent'
            },
            '&[aria-selected="true"]': {
                backgroundColor: 'var(--row-focus)',
                borderColor: 'transparent'
            }

        }
    })
)