import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    contact: {
      width: '100%',
    },
    error:{
      color: 'red'
    }
  })
)
