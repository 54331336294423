import { Dialog, DialogContent, Typography, Grid } from '@material-ui/core'

import useStyles from './styles'
import { SuccessModalProps } from './types'

import { computer } from '../../../../../assets'
import { useEffect } from 'react'

const SuccessModal = ({ open, setOpen }: SuccessModalProps) => {
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      setOpen && setOpen(false)
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog onClose={() => setOpen && setOpen(!open)} open={open} classes={{ paper: `${classes.root}` }}>
      <DialogContent className={classes.header}>
        <Grid container justifyContent='center' alignItems='center' className={classes.headerContainer}>
          <img alt='add new member icon' src={computer} />
          <Typography className={classes.title}>Invite sent successfully!</Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessModal
