import { ESTIMATE_STATUS } from 'helpers'
import { authTypes } from 'ducks/auth/types'
import {
  newEstimateTypes,
  NewEstimateType,
  NewEstimateActions,
  NewEstimateValue,
  NewEstimateValues,
} from './types'

const initialState: NewEstimateType = {
  step: -1, // first step
  substep: -1, // first step
  estimate: {
    status: ESTIMATE_STATUS.NEW
  },
  paymentOptions: [],
  paymentOption: null,
  clientSecret: '',
  isExpedite: false,
  clientType: '',
  serviceable: true,
  redirectAfterLogin: false,
  showWelcome: false,
  inspectionConsent: false,

  mainContact: {},
  address: {},
  additionalEmails: '',
  closingDate: 0,
  clientNotes: '',
  repairTime: '',
  inspectionFiles: null,
  addendumFiles: null,
  repairListFiles: null,
  userIsloggedIn: false,
  preferredCommunicationPhone: true,
  preferredCommunicationText: true,
  preferredCommunicationMail: true,
}

const reducer = (
  state = initialState,
  action: NewEstimateActions
): NewEstimateType => {
  const { type, payload } = action
  switch (type) {
    /*  case authTypes.LOGIN_REQUEST: {
       return initialState
     } */
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }
    case newEstimateTypes.CLEAN: {
      return {
        ...initialState,
        estimate: { ...state.estimate, status: ESTIMATE_STATUS.NEW },
        paymentOptions: state.paymentOptions,
        paymentOption: state.paymentOption,
        clientSecret: state.clientSecret,
        isExpedite: state.isExpedite,
        redirectAfterLogin: state.redirectAfterLogin,
        userIsloggedIn: state.userIsloggedIn
      }
    }
    case newEstimateTypes.ERASE: {
      return {
        ...initialState,
        redirectAfterLogin: state.redirectAfterLogin,
        mainContact: state.mainContact,
        clientType: state.clientType,
        userIsloggedIn: state.userIsloggedIn
      }
    }
    case newEstimateTypes.SET_STEP: {
      return {
        ...state,
        step: payload as number,
      }
    }
    case newEstimateTypes.SET_VALUE: {
      const { attr, value } = payload as NewEstimateValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case newEstimateTypes.SET_VALUES: {
      const { attrs } = payload as NewEstimateValues
      return {
        ...state,
        ...attrs,
      }
    }

    case newEstimateTypes.SET_SUBSTEP: {
      return {
        ...state,
        substep: payload as number,
      }
    }

    default:
      return state
  }
}

export default reducer
