import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'grid',
            gridAutoFlow: 'column',
            width: '100%',
            padding: '24px 0'
        },
        forward: {
            width: '185px',
            height: '45px',
            textTransform: 'none',
            color: 'var(--white-color)',
            backgroundColor: 'var(--bosscat-blue-600)',
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                width: '135px'
            }
        },
        back: {
            width: '185px',
            height: '45px',
            textTransform: 'none',
            color: 'var(--bosscat-blue-600)',
            backgroundColor: 'var(--background-color-secondary)',
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                width: '135px'
            }
        },
        icon: {
            width: '15px',
            height: '15px'
        },
        button: {
            display: 'flex',
            justifyContent: 'right',
            [theme.breakpoints.down('sm')]: {
                margin: '0px 40px 0px 0px'
            }
        }
    })
)
