import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      width: '100%',
      maxWidth: '800px',
      maxHeight: '500px',
      borderRadius: '16px',
      height: 'fit-content !important'
    },
    content: {
      /* display: 'flex',
      gridTemplateRows: 'min-content auto',
      gap: '24px',
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        gap: '12px',
      },
      padding: '0 24px 24px', */
    },
    close: {
      position: 'absolute',
      zIndex: 1,
      top: theme.spacing(1) / 3,
      right: theme.spacing(1) / 2,
    },
    title: {
      fontSize: 24,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },
    text: {
      fontSize: 20,
      fontFamily: 'LatoLight',
      marginTop: '2rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        marginTop: '1rem',
      },
    },
    buttonContainer: {
      display: 'flex',
      gap: '2rem',
      margin: '1.5rem 0',
      [theme.breakpoints.down('sm')]: {
        gap: '.3rem'
      },
    },
    buttonBack: {
      width: '100%',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      border: '2px solid var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: 18,
      fontFamily: 'LatoNormal',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: 1.2
      },
    },
    buttonNext: {
      width: '100%',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: 18,
      fontFamily: 'LatoNormal',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: 1.2
      },
    },
  })
)
