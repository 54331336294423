import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: '2rem 0',
            [theme.breakpoints.down('sm')]: {
                padding: " 16px",
                margin: '0'
            }
        },
        root: {
            width: '100%',
            maxWidth: "50%",
            display: 'flex',
            flexWrap: 'wrap',
            gap: "24px",
            justifyContent: 'center',
            margin: "2rem auto 0",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "100% ",
            }
        },
        button: {
            backgroundColor: 'var(--hardware-haze-700)',
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '48%',
            color: 'white',
            cursor: "pointer",
            borderRadius: '32px',
            transition: 'all 0.3s ease',
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
        },
        disabled: {
            backgroundColor: "var(--bosscat-grey-400)",
            cursor: "not-allowed",
            pointerEvents: "none",
            "&:hover": {
                backgroundColor: "var(--bosscat-grey-400)",
            }
        },
        buttonText: {
            color: "white",
            margin: 0,
            textTransform: 'uppercase',
            fontWeight: 900
        },
        title: {
            fontSize: "32px",
            fontFamily: "LatoBold",
            textAlign: "center",
            color: "#0b060f",
            padding: '0 3rem',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.75rem",
                width: '100%',
            }
        },
        input: {
            width: "48%",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },
            "@global": {
                ".MuiOutlinedInput-notchedOutline": {
                    border: '0',
                    borderBottom: "2px solid rgba(143, 144, 145, 0.4)",
                    borderRadius: '0',
                    "&:hover": {
                        borderRadius: '0',
                    }
                },
                ".MuiInputBase-root": {
                    boxShadow: 'none',
                    height: "46px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: '0',
                        borderBottom: "2px solid rgba(143, 144, 145, 0.4)",
                        borderRadius: '0',
                    },
                },
                ".MuiInputBase-input": {
                    padding: '8px 0',
                    fontFamily: "LatoNormal",
                },
            }
        },
        inputError: {
            "@global": {

                ".MuiInputBase-root": {
                    boxShadow: 'none',
                    height: "46px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: '0',
                        borderBottom: "2px solid var(--expired-color)",
                        borderRadius: '0',
                    },
                },
            },
        },
        inputLabel: {
            color: 'var(--bosscat-grey-800)',
            fontSize: "16px",
            fontWeight: 900,
            fontFamily: 'LatoBlack',
            paddingBottom: 0
        },
        selectAddress: {
            width: "100%",
            margin: 0,
            "&> .MuiGrid-root": {
                width: "100%",
                padding: 0,
                maxWidth: "100%",
                flexBasis: "100%",
            },
            "& .MuiFormControl-root": {
                border: 0,
                borderBottom: "2px solid rgba(143, 144, 145, 0.4)",
                borderRadius: 0,
                paddingInline: 0,
                height: "46px",
                marginTop: "0px",
            },
            "& .MuiInputBase-input": {
                color: "#0b060f"
            }
        },
        selectAddressTitle: {
            fontSize: "16px",
            fontFamily: "LatoBlack",
            fontWeight: 900,
            color: "var(--bosscat-grey-800)"

        },
        textArea: {
            borderRadius: 0,
            borderColor: "rgba(143, 144, 145, 0.4)",
            "&:hover": {
                borderRadius: 0,
                borderColor: "rgba(143, 144, 145, 0.4)",
            }
        },
        textAreaLabel: {
            fontSize: "16px",
            fontFamily: "LatoBlack",
            fontWeight: 900,
            color: "var(--bosscat-grey-800)"
        },
        addressContainer: {
            width: "48%",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },
        },
        disclaimer: {
            fontSize: '9px',
            color: 'rgb(130,126,122) !important',
            textAlign: 'center',
            marginBottom: '4rem'
        },
        inputAutocomplete: {
            color: 'var(--bosscat-grey-800)',
            fontSize: "16px",
            paddingBottom: 0,
            fontFamily: "LatoBlack",
            fontWeight: 900,
        },
        inputAuto: {
            borderRadius: 0,
            borderColor: "rgba(143, 144, 145, 0.4)",
            marginTop: "0px",
            '&:hover': {
                borderRadius: 0,
                borderColor: "rgba(143, 144, 145, 0.4)",
            },
            // height: "46px",
            "& .MuiIconButton-root": {
                height: "46px",
            }
        }
    })
)
