import React, { useReducer } from 'react'
import { DispatchReducer } from './reducer'
import { DispatchContextType } from './types'

const initialState = {
  showWarning: false,
  errorMessage: undefined,
  address: '',
  cardHolder: '',
  clientSecret: '',
  estimateId: '',
  amount: 0,
  payIntent: false,
}

export const DispatchPaymentCreditCardContext = React.createContext<{
  state: DispatchContextType
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

export const DispatchPaymentCreditCardProvider: React.FunctionComponent = ({
  children,
}: any) => {
  const [state, dispatch] = useReducer(DispatchReducer, initialState)

  return (
    <DispatchPaymentCreditCardContext.Provider value={{ state, dispatch }}>
      {children}
    </DispatchPaymentCreditCardContext.Provider>
  )
}
