import { FC, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AuthPages } from '../components/pages'
import { LoginPage, RegisterPage, PunchlistLoader } from '../components/templates'
import { authActions } from '../ducks/actions'
import { getConfigValue, getNewEstimateValue, isAuthorized } from '../ducks/selectors'
import useRouter from '../hooks/useRouter'
import { RoutesType } from './types'

const AuthRouter = (): React.ComponentType => {
  const [, Router] = useRouter(AuthPages, 'page')
  return Router
}

const AuthRoutes: FC<RoutesType> = ({ children, location }) => {
  const authorized = useSelector(isAuthorized)
  const redirectPath = useSelector(getConfigValue('redirectPath'))
  const dispatch = useDispatch()
  const Router = AuthRouter()
  const userIsloggedIn = useSelector(getNewEstimateValue('userIsloggedIn'))

  if (!authorized && userIsloggedIn) {
    // clean persisted estimate data when auth0 token expired
    dispatch(
      authActions.logoutRequest({
        callback: (success) => {
          // if (success) replace('/')
        },
      })
    )
  }

  if (authorized) {
    if (redirectPath && redirectPath !== "/p/docusignSuccess") {
      return <Redirect to={redirectPath} />
    }
    return <Redirect to='/p/dashboard' />
  }

  if (location?.pathname === "register") {
    return (
      <Suspense fallback={<PunchlistLoader />}>
        <RegisterPage>
          {children}
        </RegisterPage>
      </Suspense>
    )
  } else {
    return (
      <Suspense fallback={<PunchlistLoader />}>
        <LoginPage>
          {children}
        </LoginPage>
      </Suspense>
    )
  }

}

export default AuthRoutes
