import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: '650px',
    },
    logoBox: {
      margin: '0 auto',
      maxWidth: '950px',
    },
    logo: {
      margin: '20px',
      width: '250px',
    },
    header: {
      fontSize: '32px',
      padding: '0 0 12px 12px',
      '& > span': {
        color: 'var(--bosscat-blue-600)',
      },
    },
    inputs: {
      paddingLeft: '16px',
    },
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      width: '100%',
      padding: '24px 16px 24px 16px',
      gap: '8px',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gridAutoFlow: 'row',
        justifyContent: 'normal',
      },
    },
    submit: {
      margin: theme.spacing(1, 0),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    back: {
      margin: theme.spacing(1, 0),
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    icon: {
      margin: '0 0 2px 0',
      width: '16px',
      height: '16px',
    },
    summary: {
      display: 'grid',
      gap: '12px',
      backgroundColor: 'var(--background-color-secondary)',
      padding: '16px',
      borderRadius: '12px',
    },
  })
)
