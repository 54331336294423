import { FC, useState, SyntheticEvent, useEffect } from 'react'
// import { Button, Grid, Tab, Tabs } from '@mui/material'
import { TabNavigationProps } from './types'
import styles from './styles.module.scss'

import { Grid, styled, Tab, Tabs } from '@material-ui/core'
// import { styled } from '@mui/system'
import { icons } from './../../../../../assets'

const TabNavigation: FC<TabNavigationProps> = ({
  tabs,
  defaultActive = 0,
  variant = 'text',
  margin,
  className = '',
  style = {},
  tabWidth = "100%",
  tabAlign = "flex-start",
  showExpand = false,
  getSelectedTab,
  containerSx = {},
  additionalContent,
  tabsStyles = {},
  selectedTab
}) => {
  const [selected, setSelected] = useState(defaultActive)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    getSelectedTab && getSelectedTab(selected)
  }, [selected])

  useEffect(() => {
    selectedTab && setSelected(selectedTab)
  }, [selectedTab])

  const handleChange = (_event: any, newValue: number) => {
    if (newValue > tabs.length - 1) {
      setExpanded(!expanded)
    } else {
      const action = tabs[newValue].action
      const preventOpen = tabs[newValue].preventOpen
      if (!preventOpen) setSelected(newValue)
      //if (getSelectedTab) getSelectedTab(newValue)
      if (action) action(tabs[newValue]?.id)
    }
  }

  if (!tabs.length) return null

  const TabsStyled = styled(Tabs)({
    width: tabWidth,
    '& .MuiTabs-flexContainer': {
      gridTemplateColumns: variant === 'contained' || variant === 'containedCircular' ? `repeat(${tabs.length}, 1fr)` : 'inherit',
    },
    '.PrivateTabIndicator-root': {
      display: 'none !important'
    }
  })

  return (
    <Grid
      container
      // display='grid'
      className={`${styles.Tabs__container} ${expanded ? styles.Expanded : ''}`}
      // gridTemplateRows='min-content auto'
      // height='100%'
      // width='100%'
      alignItems={tabAlign}
    >
      <Grid
        // sx={containerSx}
        className={styles.Tab__container}
        container
        item
        style={{ justifyContent: tabs[selected].actionButton ? "space-between" : tabAlign, ...containerSx }}
      // alignItems="center"
      // justifyContent={tabs[selected].actionButton ? "space-between" : tabAlign}
      >
        {tabs[selected].actionButton && tabs[selected].actionButton}
        <TabsStyled
          value={selected}
          onChange={handleChange}
          className={`${styles.Tabs} ${styles[variant]} ${styles[`${variant}--${tabs.length}`]} ${className} `}
          style={{ margin: margin, ...tabsStyles }}
        >
          {tabs.map((tab, index) =>
            <Tab
              className={`${tab.className || ''} ${tab.error ? styles.Error : ''} ${styles.Tab} ${styles[variant]} ${styles.Tab} ${tab.action ? styles.Tab__action : ''}`}
              key={index} label={tab.element ? tab.element : tab.label} style={{ color: tab.color, ...style }}
            />
          )}
          {showExpand && (
            <Tab
              className={`${styles.Tab} ${styles.Expand} ${styles[variant]} `}
              key='flow'
              label={expanded ? <icons.KeyboardArrowDown /> : <icons.KeyboardArrowUp />}
              style={{
                ...style,
                position: 'absolute',
                right: '0',
              }}
            />
          )}
        </TabsStyled>
        {additionalContent && additionalContent}
      </Grid>

      <Grid
        item
        className={styles.TabContent}
      // height="100%"
      // width="100%"
      //  display="grid"
      >
        {tabs[selected].content}
      </Grid>
    </Grid>
  )
}

export default TabNavigation
