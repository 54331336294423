import { Box } from '@material-ui/core'
import GiftSelector from './components/GiftSelector'
import HomeToBeServiced from './components/HomeToBeServiced'
import HomeownerInformation from './components/HomeownerInformation'

const GiftInformation = () => {

  /*   useEffect(()=> {
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'redirectAfterLogin',
          value: false
        })
      )
    },[]) */

  return (
    <>
      <Box>
        <GiftSelector />
        <HomeToBeServiced />
        <HomeownerInformation />
      </Box>
    </>
  )
}

export default GiftInformation
