import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
} from '@material-ui/core'
import useStyles from '../styles'
import { Property } from 'ducks/types'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import Slider from 'components/UI/CustomUI/molecules/Slider'
import Icon from 'components/UI/CustomUI/atoms/Icon'
import { tempHome } from 'assets/index'
import { Edit } from '@material-ui/icons'
import { propertiesActions, propertyActivityActions, propertyDetailActions } from 'ducks/actions'
import { useDispatch } from 'react-redux'
import SelectPropertyPictureModal from '../Modals/SelectPropertyPictureModal'
import { ThreeDotsPopup } from 'components/UI/CustomUI/molecules/ThreeDotsPopup'
import { Image, Inbox } from '@material-ui/icons'

const HomeCard = ({
  property,
  index,
}: {
  property: Property
  index: number
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [open, setOpen] = useState<boolean>(false)

  const [selectedImage, setSelectedImage] = useState<string | null>(
    property.imageUrl || null
  )

  const { push } = useHistory()

  const handleHomeCardClick = () => {
    dispatch(
      propertyDetailActions.setPropertyDetail({
        address: property.address,
        details: {},
        imageUrl: '',
      })
    )
    push(`/p/dashboard/${property.id}`)
  }

  const changeImage = (e: any) => {
    setSelectedImage(e.target.value || null)

    dispatch(
      propertiesActions.updateProperty(
        {
          propertyId: property.id,
          partialProperty: { imageUrl: e.target.value },
        },
        (succ) => {}
      )
    )
  }

  const handleAddPicture = () => {
    setOpen(true)
  }

  const handlePopupChange = (option: string) => {
    if (option === 'Unarchive Property') {
      dispatch(
        propertiesActions.deleteProperty(property.id, (succ) => {
          dispatch(
            propertiesActions.createProperty(
              { ...property, archived: !property.archived },
              (succ) => {
                dispatch(propertiesActions.fetchProperties({ archived: true }, (succ) => {}))
                dispatch(propertyActivityActions.fetchPropertiesActivity({}, () => {}))
              }
            )
          )
        })
      )
    } else if (option === 'Archive Property') {
      dispatch(
        propertiesActions.deleteProperty(property.id, (succ) => {
          dispatch(propertiesActions.fetchProperties({ archived: false }, (succ) => {}))
          dispatch(propertyActivityActions.fetchPropertiesActivity({}, () => {}))
        })
      )
    }
    if (option === 'Update Photo') {
      setOpen(true)
    }
  }

  return (
    <Box className={styles.homeCard} onClick={handleHomeCardClick}>
      {/* <img src={property.imageUrl} alt="home img" style={{ width: '100%', height: '160px' }} /> */}
      <Box
        style={{ width: '100%', height: '14em', zoom: 1 }}
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        overflow="hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {property.imageUrl ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            onClick={handleHomeCardClick}
          >
            <img
              src={property.imageUrl}
              alt="property"
              style={{ width: '100%' }}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="var(--background-bg-soft-3)"
            height="100%"
          >
            <img
              src={tempHome}
              alt="temp home"
              style={{ width: '80px', height: '80px' }}
              onClick={handleHomeCardClick}
            />
            <Button
              className={styles.inquireButton}
              style={{ padding: '10px 16px', marginTop: '16px' }}
              onClick={handleAddPicture}
            >
              <Typography
                className={styles.title}
                style={{ fontWeight: 700, color: 'var(--bosscat-black-600)' }}
              >
                Upload Photo
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems='flex-end'>
        <Box>
          <Typography className={styles.nextArtTitle}>
            {`${property.address.line_1 || ''} ${
              property.address.line_2 ? `UNIT ${property.address.line_2}` : ''
            }`}
          </Typography>
          <Typography
            className={styles.latoSmFont}
            style={{ border: 0 }}
          >{`${property.address.city}, ${property.address.state}. ${property.address.zipCode}`}</Typography>
        </Box>
        <Box onClick={(e) => e.stopPropagation()}>
          <ThreeDotsPopup
            otherIcon={<Edit style={{ width: '16px', height: '16px' }} />}
            options={[
              {
                icon: <Image style={{ width: '16px', height: '16px' }} />,
                label: 'Update Photo',
              },
              {
                icon: <Inbox style={{ width: '16px', height: '16px' }} />,
                label: property.archived
                  ? 'Unarchive Property'
                  : 'Archive Property',
              },
            ]}
            onChange={handlePopupChange}
          />
        </Box>
      </Box>
      <SelectPropertyPictureModal
        property={property}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  )
}

export default HomeCard
