import { authTypes } from 'ducks/auth/types'
import { Property } from 'ducks/types'
import { propertiesTypes, PropertiesType, PropertiesActions } from './types'

const initialState: PropertiesType = []

const reducer = (state = initialState, action: PropertiesActions): PropertiesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case propertiesTypes.SET_PROPERTIES: {
      return payload as Property[]
    }

    default:
      return state
  }
}

export default reducer
