import Login from './Login'
import Register from './Register'
import Reset from './Reset'

const AuthPages = {
  default: Login,
  login: Login,
  register: Register,
  reset: Reset
}

export default AuthPages
