import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from '../../../../../styles'
import {
  InsertDriveFile,
  KeyboardArrowUp,
  KeyboardArrowDown,
  ArrowForward,
} from '@material-ui/icons'
import { PropertyActivityActiveData } from 'ducks/types'
import moment from 'moment'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'

const EstimateStepContent = ({
  activeData,
}: {
  activeData: PropertyActivityActiveData
}) => {
  const styles = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  return (
    <Grid
      item
      container
      spacing={2}
      direction="column"
      style={{ marginLeft: isSmallScreen ? 0 : '60px' }}
    >
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography className={styles.title}>
            {`Estimate requested by ${
              activeData?.estimateCreatedByName
            } on ${moment(activeData?.estimateCreatedOn * 1000).format(
              'MMMM DD, YYYY'
            )}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={styles.title}>
            {`Estimate Created on ${moment(
              activeData?.estimateCreatedOn * 1000
            ).format('MMMM DD, YYYY')}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={styles.title}>
            {activeData?.estimateLastDeliveredOn &&
              `Estimate Updated on ${moment(
                activeData?.estimateLastDeliveredOn * 1000
              ).format('MMMM DD, YYYY')}`}
          </Typography>
        </Grid>
        <Grid container item style={{ gap: '4px' }}>
          <Typography className={styles.title}>
            {`Purchase #${activeData?.publicId} - ${activeData.numItems} items `}
          </Typography>
          {/* {!!activeData?.estimateDeliveredOn && (
            <Box
              display="flex"
              style={{ gap: '4px', cursor: 'pointer' }}
              color="var(--bosscat-blue-600)"
              alignItems="center"
              width="fit-content"
            >
              <Typography
                component="span"
                className={styles.title}
                style={{ textDecoration: 'underline' }}
              >
                Download Order PDF
              </Typography>
              <InsertDriveFile className={styles.buttonIcon} />
            </Box>
          )} */}
        </Grid>
        <Grid item>
          <Typography className={styles.title}>
            {activeData?.approvedOn &&
              `Estimate Approved on ${moment(
                activeData?.approvedOn * 1000
              ).format('MMMM DD, YYYY')}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EstimateStepContent
