
/** TYPES **/
export const clientQuestionsTypes = {
  CREATE_CLIENT_QUESTION: 'CREATE_CLIENT_QUESTION'
}

export enum COMMUNICATION_METHODS {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export interface ClientQuestion {
  estimate_id: string,
  question?: string,
  communication_method?: COMMUNICATION_METHODS,
  estimate_contact_id?: string,
  requested_date?: string,
  item_ids?: string[]
}

/** ACTIONS **/
export interface SendClientQuestionAction {
  type: typeof clientQuestionsTypes.CREATE_CLIENT_QUESTION
  payload: ClientQuestion
  callback?: (succ: boolean) => void
}

export type ClientQuestionsActions =
  | SendClientQuestionAction
