import { IconButton, Typography, Button } from '@material-ui/core'
import { FC, memo } from 'react'
import { icons } from '../../../../../../../../../assets'
import { ImagesSelector, Box, TextFieldLabel } from '../../../../../../../../UI'
import { imageMimeTypes, applicationMimeTypes, videoMimeTypes } from '../../../../../../../../../helpers/files'
import useStyles from '../styles'
import { ItemRowProps, MemoizedImagesProps } from './types'

const filesExtensions = [...imageMimeTypes, ...applicationMimeTypes, ...videoMimeTypes]

const MemoizedImages = memo((props: MemoizedImagesProps) => {
  const { htmlFiles, submitAction, images, onDeleteImageFilesActions } = props
  return (
    <Box display='flex' aria-label='memoized-images'>
      <ImagesSelector
        imageFiles={images}
        images={htmlFiles as unknown as File[] || []}
        deleteImageFilesAction={onDeleteImageFilesActions}
        submitAction={submitAction}
        multiple
        disabled={false}
        allowedFiles={filesExtensions}
        canUpload
      />
    </Box>
  )
}, (beforeProps: MemoizedImagesProps, nextProps: MemoizedImagesProps) => JSON.stringify(beforeProps) === JSON.stringify(nextProps))

const ItemInputs: FC<ItemRowProps> = ({
  index,
  inspectionReportNote,
  measurement,
  htmlFiles,
  onChangeMeasurement,
  onChangeNote,
  onChangeImages,
  onDeleteItem,
  onAddItem,
  images,
  onDeleteImagesFiles,
  totalItems,
  imagesUpdater,
  deleteEnabled
}: ItemRowProps) => {
  const classes = useStyles()

  return (
    <Box data-testid='list-builder-input' key={index} className={classes.inputsItem}>
      <Box className={classes.subtitleContainer}>
        <Typography className={classes.titleItem}>
          Item #{index + 1}
        </Typography>
        {deleteEnabled && <IconButton onClick={() => { onDeleteItem(index) }}>
          <icons.Delete htmlColor='#CA0000' />
        </IconButton>}
      </Box>

      <TextFieldLabel
        fullWidth
        label='Description:'
        placeholder='Example: Paint 4 walls and ceiling. (I’ll provide paint.)'
        value={inspectionReportNote}
        // error={!inspectionReportNote}
        onChange={(event: any) => {
          onChangeNote(event.target.value, index)
        }}
        className={classes.inputsItem}
      />
      <TextFieldLabel
        fullWidth
        label='Measurements:'
        placeholder='Room size: 15’ x 19’5” x 8’ ceiling height'
        value={measurement}
        // error={!measurement}
        onChange={(event: any) => {
          onChangeMeasurement(event.target.value, index)
        }}
        className={classes.inputsItem}
      />
      <Typography style={{ fontSize: 16, fontFamily: 'LatoNormal', marginTop: '8px' }}>
        Upload Photos/Videos:
      </Typography>
      {index === 0 &&
        <Typography style={{ fontSize: 12, fontFamily: 'LatoNormal', color: '#AAACBB' }}>
          Provide close up and full shot (from further away) photos and/or videos.
        </Typography>}
      <MemoizedImages
        htmlFiles={htmlFiles as unknown as File[] || []}
        submitAction={(images: any) => {
          onChangeImages(images, index, () => { })
        }}
        images={images}
        onDeleteImageFilesActions={onDeleteImagesFiles}
        totalItems={totalItems}
        imagesUpdater={imagesUpdater}
      />

      {index === 0 &&
        <Button
          type='submit'
          variant='contained'
          size='small'
          className={classes.addItem}
          endIcon={<icons.Add />}
          onClick={onAddItem}
        >
          Add Item
        </Button>}
      {index !== 0 && <Box style={{ marginBottom: '1rem' }} />}
    </Box>
  )
}
export default ItemInputs
