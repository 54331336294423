import { UpdateRequest } from 'api/types'
import { Estimate, Item, EstimateContact, EstimateProperties } from 'ducks/types'
import { EstimateActions, estimateTypes, ShareEstimateRequest } from './types'

export type NotificationListContact = Partial<EstimateContact> & NotificationListContactExtends
interface NotificationListContactExtends {
  marketing: {
    source: string | null;
    medium: string | null;
    campaign: string | null;
    content: string | null;
  }
  address: string
  email: string | null;
  //TODO: replace to camelcase
  firstName: string | null;
  lastName: string | null;
  resourceType: string | null;
  territoryId: string | null;
}

const actions = {
  fetchEstimate: (
    payload: string,
    callback?: (succ: boolean, estimate?: Estimate) => void
  ): EstimateActions => ({
    type: estimateTypes.FETCH_ESTIMATE,
    payload,
    callback
  }),
  setEstimate: (payload: Estimate): EstimateActions => ({
    type: estimateTypes.SET_ESTIMATE,
    payload
  }),
  updateEstimateByPath: (payload: UpdateRequest, callback?: (succ: boolean, estimate?: Estimate) => void): EstimateActions => ({
    type: estimateTypes.UPDATE_ESTIMATE_BY_PATH,
    payload,
    callback
  }),
  updateEstimateProperties: (
    payload: Partial<EstimateProperties>,
    callback: (succ: boolean) => void
  ): EstimateActions => ({
    type: estimateTypes.UPDATE_ESTIMATE_PROPERTIES,
    payload,
    callback
  }),
  approval: (payload: Partial<Estimate>, callback?: (succ: boolean, status: string, payAtClose: boolean) => void): EstimateActions => ({
    type: estimateTypes.APPROVAL,
    payload,
    callback
  }),
  applyPromoCode: (payload: string, callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.APPLY_PROMO_CODE,
    payload,
    callback
  }),
  shareEstimate: (payload: Partial<ShareEstimateRequest>, callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.SHARE_ESTIMATE,
    payload,
    callback
  }),
  addItems: (payload: Item[], callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.ADD_ITEMS,
    payload,
    callback
  }),
  removeItems: (payload: Item[], callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.REMOVE_ITEMS,
    payload,
    callback
  }),
  addEstimateToNotificationList: (callback: (succ: boolean) => void, payload?: NotificationListContact,
  ): EstimateActions => ({
    type: estimateTypes.ADD_ESTIMATE_TO_NOTIFICATION_LIST,
    payload,
    callback
  }),
  requestMoreItems: (payload: string, callback?: (succ: boolean) => void): EstimateActions => ({
    type: estimateTypes.REQUEST_MORE_ITEMS,
    payload,
    callback
  }),
}

export default actions
