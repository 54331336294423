import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { equalHousingLogo } from 'assets/index'

const PropertyDisclaimer = () => {
  const styles = useStyles()

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item>
        <img src={equalHousingLogo} alt="home" className={styles.equalHousingLogo} />
      </Grid>
      <Grid item>
        <a
          className={`${styles.title} ${styles.bottomLink}`}
          href="https://bosscathome.com/disclaimer/"
          rel="noreferrer"
          target="_blank"
        >
          Disclaimer
        </a>
      </Grid>
      <Grid item>
        <Typography style={{ paddingLeft: '8px' }}>|</Typography>
      </Grid>
      <Grid item>
        <a
          className={`${styles.title} ${styles.bottomLink}`}
          href="https://bosscathome.com/terms"
          rel="noreferrer"
          target="_blank"
        >
          Terms
        </a>
      </Grid>
      <Grid item>
        <Typography style={{ paddingLeft: '8px' }}>|</Typography>
      </Grid>
      <Grid item>
        <a
          className={`${styles.title} ${styles.bottomLink}`}
          href="https://bosscathome.com/privacy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </Grid>
    </Grid>
  )
}

export default PropertyDisclaimer
