import { Box, CircularProgress } from '@material-ui/core'
import { DesktopPage } from 'components/templates'
import React, { useEffect } from 'react'
import useStyles from './styles'

type Props = {
    opacity?: number
}

function FullScreenLoader({ opacity = 1 }: Props) {
    const classes = useStyles();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const envelopeId = urlSearchParams.get('envelope_id');

    useEffect(() => {
        if (envelopeId) {
            const windowAny = window as any;
            windowAny?.ReactNativeWebView?.postMessage(`{envelopeId: ${envelopeId}}`);
        }
    }, [envelopeId])



    return (
        <DesktopPage>
            <Box className={classes.Container} style={{ opacity }} >
                <CircularProgress size={40} />
            </Box>
        </DesktopPage>
    )
}

export default FullScreenLoader