import { Grid, Radio, Typography } from '@material-ui/core'
import { getApprovalValues, getUserIsHomeowner } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './../../styles'
import { FC } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import PayAtClose from '../PayAtClose'
import { CONTACT_ROLE } from 'helpers/constants'

export interface PaymentOptionsProps {
  fullWidth?: boolean
  paymentOptions: {
    key: string
    label: string
  }[]
}

const PaymentOptions: FC<PaymentOptionsProps> = ({
  fullWidth = false,
  paymentOptions,
}) => {
  const classes = useStyles()
  const { saveNewValue } = useStepperContext()
  // const isHomeowner = getUserIsHomeowner()
  const { paymentOption, agentIsHomeowner, role } = useSelector(
    getApprovalValues()
  )

  const isHomeowner =
    agentIsHomeowner ||
    getUserIsHomeowner() 

  return (
    <>
      <Grid item>
        <Typography className={classes.title}>
          💵{isHomeowner ? 'payment options' : 'payment'}
        </Typography>
        <Typography variant="body1" className={classes.latoPrimaryText}>
          {isHomeowner
            ? 'How would you like to pay for the project?'
            : 'Payment Option'}
        </Typography>
        <Grid container spacing={2}>
          {paymentOptions.map((option, index) => {
            const selectedOption = paymentOptions.find(
              (option) => option.key === paymentOption
            )
            return (
              <Grid item xs={12} lg={fullWidth ? 12 : 6} key={index}>
                <FormRadioButton
                  style={
                    selectedOption?.key === option.key
                      ? { borderColor: 'var(--bosscat-blue-600)' }
                      : {}
                  }
                  value={option.label}
                  control={<Radio color="primary" />}
                  label={
                    <Typography variant="caption">{option?.label}</Typography>
                  }
                  onClick={() => {
                    saveNewValue('paymentOption', option.key)
                    // saveNewValue('payAtClose', option.key === 'pay_at_close')
                  }}
                  checked={selectedOption?.key === option.key}
                  className={classes.formRadioButton}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentOptions
