import { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import useStyles from './styles'
import { DashboardValueComponentProps } from './types'
import { NorthEast } from 'assets/index'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'

const DashboardValueComponent: FC<DashboardValueComponentProps> = ({
  label,
  labelColor,
  value,
  showArrow,
  clickable,
  onClick,
}) => {
  const styles = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  return (
    <Box
      className={`${styles.container} ${clickable ? styles.clickable : ''}`}
      onClick={() => (onClick && clickable) && onClick({ label, value, labelColor })}
    >
      <Box className={styles.labelContainer} color={labelColor}>
        <Typography className={styles.label}>{label}</Typography>
        {(showArrow && !isSmallScreen) && <img src={NorthEast} alt="north_east" />}
      </Box>
      <Typography className={styles.value}>{value}</Typography>
    </Box>
  )
}

export default DashboardValueComponent
