import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './styles'
import { Autocomplete, Box, Checkbox, FormControlLabel, Link, SelectAddress, TextFieldLabel, Typography } from 'components/UI'
import { Address } from 'ducks/types'
import { PHONE_MASK_INPUT, canadaStates, getSelectedAsOption, getStatesOptionList, getStatesOptionListCanada, isEmpty, states, validateEmail } from 'helpers/index'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { newOrderMaintenanceActions, territoriesActions } from 'ducks/actions'
import { getNewOrderMaintenanceValue } from 'ducks/selectors'
import { CheckBox } from '@material-ui/icons'

interface FormData {
    firstName: string
    lastName: string
    email: string
    phone: string
    address: Address
    city: string
    zipCode: string
    state: string
    promoCode?: string
}

type Props = {
    title?: string
    onSubmit: (data: FormData) => void
}

const ContactForm = ({ onSubmit, title }: Props) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [fullAddress, setFullAddress] = useState<Address>()
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [state, setState] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [serviceable, setServiceable] = useState(false)
    const [country, setCountry] = useState('United States');
    const [acknowledge, setAcknowledge] = useState(false);
    const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))

    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        address: false,
        city: false,
        zipCode: false,
        state: false,
    })
    const dispatch = useDispatch()

    const classes = useStyles()


    const validateAddress: boolean = !isEmpty(fullAddress?.line_1) &&
        fullAddress?.line_1 !== ' ' &&
        (!!fullAddress?.zipCode || !!zipCode) ? true : false

    const handleSubmit = () => {
        if (!firstName || !lastName || !email || !phone || !validateAddress) {
            if (!firstName) { setErrors((prevErrors) => ({ ...prevErrors, firstName: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, firstName: false })) }
            if (!lastName) { setErrors((prevErrors) => ({ ...prevErrors, lastName: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, lastName: false })) }
            if (!email || !validateEmail(email)) {
                setErrors((prevErrors) => ({ ...prevErrors, email: true }))
            } else { setErrors((prevErrors) => ({ ...prevErrors, email: false })) }
            if (!phone) { setErrors((prevErrors) => ({ ...prevErrors, phone: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, phone: false })) }
            if (!validateAddress) { setErrors((prevErrors) => ({ ...prevErrors, address: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, address: false })) }
            if (!city) { setErrors((prevErrors) => ({ ...prevErrors, city: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, city: false })) }
            if (!zipCode) { setErrors((prevErrors) => ({ ...prevErrors, zipCode: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, zipCode: false })) }
            if (!state) { setErrors((prevErrors) => ({ ...prevErrors, state: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, state: false })) }
            return
        }
        const mergedAddress = {
            ...fullAddress,
            latitude: fullAddress?.latitude,
            longitude: fullAddress?.longitude,
            city,
            zipCode,
            state,
            country,
        }

        setErrors({
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            address: false,
            city: false,
            zipCode: false,
            state: false,
        })

        onSubmit({
            firstName,
            lastName,
            email,
            phone,
            address: mergedAddress as Address,
            city,
            zipCode,
            state,
            promoCode,
        })
    }

    const fetchZipCodeServices = useCallback((zipCode: string) => {
        dispatch(
            territoriesActions.fetchTerritory(zipCode, (succ, terr) => {
                setServiceable(terr?.serviceable || false)
            })
        )
    }, [dispatch])

    const handleAddressChange = (newAddress: Address) => {
        if (newAddress.zipCode) {
            setFullAddress(newAddress)
            setCity(newAddress.city)
            setZipCode(newAddress.zipCode)
            setState(newAddress.state)
            fetchZipCodeServices(newAddress.zipCode)
            dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'propertyAddress', value: newAddress }))
        }
    }

    const updateMainContact = (attr: string, value: any) => {
        dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'mainContact',
                value: { ...mainContact, [attr]: value }
            })
        )
    }


    useEffect(() => {
        if (zipCode.length > 3) {
            fetchZipCodeServices(zipCode)
        }
    }, [zipCode])


    return (
        <div className={classes.container}>
            {title && <h1 className={classes.title}>{title}</h1>}
            <div className={classes.root}>
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.firstName ? classes.inputError : ""}`} label="FIRST NAME" placeholder='Enter your first name' value={firstName} onChange={(e) => {
                    setFirstName(e.target.value)
                    updateMainContact('firstName', e.target.value)
                }} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.lastName ? classes.inputError : ""}`} label="LAST NAME" placeholder='Enter your last name' value={lastName} onChange={(e) => {
                    setLastName(e.target.value)
                    updateMainContact('lastName', e.target.value)
                }} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.email ? classes.inputError : ""}`} label="EMAIL" placeholder='Enter your email' value={email} onChange={(e) => {
                    setEmail(e.target.value)
                    updateMainContact('email', e.target.value)
                }} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.phone ? classes.inputError : ""}`} label="PHONE" placeholder='Enter your phone' type='tel' mask={PHONE_MASK_INPUT} value={phone} onChange={(e) => {
                    setPhone(e.target.value)
                    updateMainContact('phone', parseInt(e.target.value.replaceAll('-', '')))
                }} />
                <SelectAddress
                    showWarnings={errors.address}
                    containerClass={classes.addressContainer}
                    savedAddress={address}
                    onChange={handleAddressChange}
                    customClass={classes.selectAddress}
                    showUnitNumberBesideAddress={false}
                    onAddressTextChange={(text: string) => {
                        setFullAddress({ line_1: text, city: '', state: '', zipCode: '', country: '', line_2: '', county: '', latitude: 0, longitude: 0 })
                    }}
                    showUnit={false}
                    placeholder="Enter your address"
                    showVertical={isMobile}
                    title={<Typography variant="h6" className={classes.selectAddressTitle}>ADDRESS</Typography>}
                    scrollOnFocus
                    showMap={false}
                    resetOnBlur={false}
                />
                {/* <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="ADDRESS" placeholder='Enter your address' value={address} onChange={(e) => setAddress(e.target.value)} /> */}
                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="CITY" placeholder='Enter your city' value={city} onChange={(e) => setCity(e.target.value)} />
                <Box style={{ width: isMobile ? "100%" : "48%" }}>
                    <Autocomplete
                        label='STATE'
                        onChange={(value: any) => setState(value.key)}
                        value={!isEmpty(states) && !isEmpty(state)
                            ? getSelectedAsOption(states.find(opt => opt?.abbreviation === state) || canadaStates.find(opt => opt?.abbreviation === state))
                            : null}
                        error={errors.state && !state}
                        options={country === 'Canada' ? getStatesOptionListCanada() : getStatesOptionList()}
                        placeholder='Enter your state'
                        className={classes.inputAuto}
                        labelClassName={classes.inputAutocomplete}
                    />
                </Box>
                {/* <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="STATE" placeholder='Enter your state' value={state} onChange={(e) => setState(e.target.value)} /> */}
                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="ZIP CODE" placeholder='Enter your zip code' value={zipCode} onChange={(e) => setZipCode(e.target.value)} />

                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label={
                    <Typography variant='body1' className={classes.inputLabel}>
                        PROMO CODE <span style={{ fontFamily: 'Lato' }}> (OPTIONAL)</span>
                    </Typography>
                } placeholder='Enter your promo code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                <div className={`${classes.button} ${!acknowledge ? classes.disabled : ''}`} onClick={handleSubmit}>
                    <p className={classes.buttonText}>Order</p>
                </div>
                <div style={{ width: "100%" }}>
                    <FormControlLabel
                        style={{ width: "100%" }}
                        control={
                            <Checkbox
                                checked={acknowledge}
                                onChange={() => setAcknowledge((prevAcknowledge) => !prevAcknowledge)}
                                name="remember"
                                color="primary"
                            />
                        }
                        label={<Link target="_blank" href='https://bosscathome.com/mellohome-terms/' variant="body2" style={{ fontSize: '14px', lineHeight: '27px', color: "var(--bosscat-black-600)" }}>I have read and acknowledge mellohome's disclosure regarding the partnership with BOSSCAT Home Services.</Link>}
                    />
                </div>
                <p className={classes.disclaimer}>
                    By submitting my name and information above, I consent to receive telephone calls and/or text/SMS messages from BOSSCAT communicating offers and information related to BOSSCAT's products, services, sales and promotions, at the telephone number I enter above, even if my number is already on a do not call list. I represent that the number I provide is my number. I acknowledge that I am not required to provide consent as a condition of purchasing products or services. I also understand that standard messaging, data rates, or cellular fees may apply. I understand that if I no longer desire to receive communications, I can reply “STOP” to any text/SMS from BOSSCAT in order to opt out. I may Opt-Out at any time, without penalty or charge. More detail regarding this Policy may be found at www.bosscathome.com
                </p>
            </div>

        </div>
    )
}

export default ContactForm