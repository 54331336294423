/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@material-ui/core'
import { FC, useState } from 'react'
import { PrivateLoader, ScrollWrapper } from '../../../../templates'
import Details from './Details'
import useStyles from './styles'
import { BOSSCAT_PHONE_NUMBER } from 'helpers/constants'

const SuccessInspection: FC = () => {

  const [pageLoading, setPageLoading] = useState(false)

  const classes = useStyles()

  const [mail, setMail] = useState<'gmail' | 'yahoo' | 'outlook' | '' | null>(
    'gmail'
  )




  return (
    <PrivateLoader loading={pageLoading}>
      <Box className={classes.root} aria-label='estimate-success'>
        <Box className={classes.content} aria-label='success-content'>
          <ScrollWrapper>
            <>
              <Details setMail={setMail} />
            </>
          </ScrollWrapper>
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.footerContent}>
            <Typography variant='body2'>
              Questions? Speak with a live Customer Care Professional
            </Typography>
            <Typography variant='body2'>
              <a href={`tel:${BOSSCAT_PHONE_NUMBER}`}>{BOSSCAT_PHONE_NUMBER}</a> M-F, 8:00am - 6:00pm
              EST
            </Typography>
          </Box>
        </Box>
      </Box>
    </PrivateLoader>
  )
}
export default SuccessInspection
