import { Grid, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './styles'
import { FC, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { UploadDocuments } from 'components/UI'
import { filesActions } from 'ducks/actions'
import {
  FILE_TYPE,
  ParseFilesUrl,
  applicationMimeTypes,
  isEmpty,
} from 'helpers/index'
import env from '@beam-australia/react-env'

export interface ComparableMarketAnalysisProps {
  comparableMarketAnalysis: any
  setComparableMarketAnalysis: (listingAgreementDocument: any) => void
}

const ComparableMarketAnalysis: FC<ComparableMarketAnalysisProps> = ({
  comparableMarketAnalysis,
  setComparableMarketAnalysis,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [uploading, setUploading] = useState(false)
  const { agentAsHomeownerRepresentative } = useSelector(getApprovalValues())

  const { showWarnings, saveNewValue } = useStepperContext()

  const FILES_URL = env('FILES_URL') ?? ''

  const handleUploadFileChange = (files: any, callback: () => void) => {
    files && setComparableMarketAnalysis(files)
    setUploading(true)
    if (files) {
      dispatch(
        filesActions.uploadFiles(files as any, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? '',
                  }),
                  description: `Estimate Inspection - ${index + 1}`,
                  fileType: FILE_TYPE.ADDENDUM,
                }
              }
            })
            files && setComparableMarketAnalysis(newFiles)
            setUploading(false)
            // saveNewValue('documents', newFiles)
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
    }
    setUploading(false)
  }

  return (
    <>
      <Grid item>
        <Typography variant="body1" className={classes.latoPrimaryText}>
          Comparable Market Analysis
        </Typography>
        <UploadDocuments
          files={comparableMarketAnalysis}
          error={showWarnings && !comparableMarketAnalysis.length}
          filesLimit={1}
          onChange={
            !uploading
              ? (files, callback) =>
                  handleUploadFileChange(files as any, callback)
              : () => {}
          }
          className={classes.dropzone}
          handleDelete={() => setComparableMarketAnalysis([])}
          allowedFiles={applicationMimeTypes}
          sx={{ minHeight: '180px !important' }}
        />
      </Grid>
    </>
  )
}

export default ComparableMarketAnalysis
