import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from './styles'
import { stripeError } from 'assets/index'

interface ErrorMessageI {
  error?: {
    title: string
    subtitle: string
  }
}

const ErrorMessage = ({ error }: ErrorMessageI) => {
  const classes = useStyles()

  if (!error) return null

  return (
    <Grid item xs={12} className={classes.errorMessage}>
      <img src={stripeError} alt="" />
      <Box className={classes.messageContainer}>
        <Typography variant="caption" className={classes.errorMessageTitle}>
          {error.title}
        </Typography>
        <Typography variant="caption" className={classes.errorMessageSubtitle}>
          {error.subtitle}
        </Typography>
      </Box>
    </Grid>
  )
}

export default ErrorMessage
