import { FC, useEffect, useState } from 'react'
import { PrivateLoader, DesktopPage } from '../../../templates'
import { Box /*, ChangePasswordModal */ } from '../../../UI'
import UserInfo from './UserInfo'
// import ChangePassword from './ChangePassword'
import useStyles from './styles'
// import { history } from '../../../../helpers'

const Profile: FC = () => {
  const classes = useStyles()
  // const { section } = history.getParamValues()

  const [loading, setLoading] = useState(true)
  // const [open, setOpen] = useState(false)

  /* const submitPasswordChange = () => {
    setOpen(true)
  } */

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  /* useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      if (section !== undefined && section === 'password') {
        const changePassword = document.getElementById('scrollChangePassword')
        if (changePassword) changePassword.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, 1000)
  }, [section]) */

  return (
    <DesktopPage title='Profile'>
      <PrivateLoader loading={loading}>
        <Box className={classes.container}>
          <UserInfo />
          {/* <ChangePasswordModal open={open} setOpen={setOpen} />
          <ChangePassword onSubmit={submitPasswordChange} />
          <div id='scrollChangePassword' /> */}
        </Box>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Profile
