import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  body: {
    position: 'relative',
    padding: '48px 24px',
  },
  bodyItem: {
    padding: '8px 2px',
  },
  bodyItems: {
    padding: '12px 4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoView: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  logo: {
    display: 'flex',
    width: '175px',
  },
  block: {
    paddingTop: '10px',
  },
  topSeparation: {
    paddingTop: '16px',
  },
  leftSeparation: {
    padding: '0px 8px',
  },
  verticalSeparator: {
    margin: '8px 0px',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFBFF',
    padding: '8px 16px',
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: '#1A2A55',
  },
  littleSpacing: {
    margin: '8px 0px',
  },
  bigSpacing: {
    margin: '20px 0px 4px',
  },
  textRight: {
    display: 'flex',
    width: '100%',
    textAlign: 'right',
  },
  title: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1A2A55',
  },
  titleBold: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1A2A55',
  },
  price: {
    fontFamily: 'LatoNormal',
    fontSize: '20px',
    color: '#1A2A55',
  },
  content: {
    position: 'relative',
    padding: '4px 16px',
  },
  itemText: {
    fontFamily: 'LatoNormal',
    flexWrap: 'wrap',
    fontSize: '10px',
    color: '#1A2A55',
  },
  userInfoText: {
    fontFamily: 'LatoNormal',
    fontSize: '8px',
    color: '#494A5A',
  },
  userInfoTitle: {
    fontFamily: 'LatoNormal',
    fontSize: '12px',
    color: '#494A5A',
    margin: '8px 0px 6px 0px',
  },
  userInfoTextRight: {
    fontFamily: 'LatoNormal',
    fontSize: '8px',
    color: '#494A5A',
    textAlign: 'right',
  },
  userInfoTextRed: {
    fontFamily: 'LatoNormal',
    fontSize: '8px',
    color: '#CA0000',
    textAlign: 'right',
  },
  userInfoTextBlue: {
    fontFamily: 'LatoNormal',
    fontSize: '10px',
    textAlign: 'center',
    color: '#FFFFFF',
    justifyContent: 'center',
    align: 'center',
  },
  userInfoTextCenter: {
    fontFamily: 'LatoNormal',
    fontSize: '6px',
    color: '#494A5A',
    textAlign: 'center',
    align: 'center',
    justifyContent: 'center',
  },
  userInfoCenter: {
    fontFamily: 'LatoNormal',
    fontSize: '6px',
    color: '#494A5A',
    textAlign: 'center',
    align: 'center',
    justifyContent: 'center',
    margin: '4px 130px',
  },
  btn: {
    margin: '8px 40px 8px 0px',
    width: '150px',
    height: '25px',
    backgroundColor: '#2F7BF7',
    borderRadius: 8,
    justifyContent: 'center',
    align: 'right',
  },
  userInfoBlue: {
    fontFamily: 'LatoNormal',
    fontSize: '8px',
    color: '#2F7BF7',
  },
  textBold: {
    fontFamily: 'LatoBold',
  },
  printDate: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 30,
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    right: 30,
    color: 'grey',
  },
  itemImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '8px 0px',
  },
  image: {
    display: 'flex',
    height: '72px',
    borderRadius: '8px',
    marginRight: '16px',
  },
  userInfoImage: {
    display: 'flex',
    height: '32px',
    marginRight: '4px',
  },
  userInfoLabelImage: {
    display: 'flex',
    width: '130px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '120px',
  },
  itemRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '190px',
  },
  itemEnd: {
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
  },
  alignBetween: {
    alignContent: 'space-between',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
  h5Title: {
    fontFamily: 'LatoNormal',
    fontWeight: 500,
    fontSize: '12px',
    backgroundColor: 'white',
    color: '#72748D',
    margin: '4px 8px',
  },
  h5Value: {
    fontFamily: 'LatoNormal',
    fontWeight: 700,
    fontSize: '12px',
    color: '#1A2A55',
    backgroundColor: 'white',
    margin: '4px 8px 4px 0px',
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: '#EBECEF',
    margin: '6px 0px',
  },
  radio: {
    height: '5px',
    width: '5px',
    margin: '2px 4px',
    borderRadius: 8,
    border: '1px solid #0F102C',
  },
  radioSel: {
    height: '5px',
    width: '5px',
    backgroundColor: '#0F102C',
    margin: '2px 4px',
    borderRadius: 8,
  },
})
