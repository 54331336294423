import { INSPECTION_STATUS, OCCUPANCY_STATUS } from 'helpers'
import {
  Address,
  EstimateContact,
  PaymentOption,
} from '../types'

/** TYPES**/
export const newInspectionTypes = {
  CLEAN: 'CLEAN_NEW_INSPECTION',
  ERASE: 'ERASE_NEW_INSPECTION',
  SET_STEP: 'SET_NEW_INSPECTION_STEP',
  SET_SUBSTEP: 'SET_NEW_INSPECTION_SET_SUBSTEP',
  SET_VALUE: 'SET_NEW_INSPECTION_VALUE',
  SET_VALUES: 'SET_NEW_INSPECTION_VALUES',
  CREATE_INSPECTION: 'CREATE_INSPECTION',
}

/** ACTIONS**/
export interface CleanNewInspectionAction {
  type: typeof newInspectionTypes.CLEAN
  payload: null
}

export interface NewInspectionSetStepAction {
  type: typeof newInspectionTypes.SET_STEP
  payload: number
}

export interface NewInspectionValue {
  attr: keyof NewInspectionType
  value: any
}

export interface NewInspectionSetValueAction {
  type: typeof newInspectionTypes.SET_VALUE
  payload: NewInspectionValue
}

export interface NewInspectionValues {
  attrs: Partial<NewInspectionType>
}

export interface NewInspectionSetValuesAction {
  type: typeof newInspectionTypes.SET_VALUES
  payload: NewInspectionValues
}

export interface CreateInspectionAction {
  type: typeof newInspectionTypes.CREATE_INSPECTION
  payload: PaymentOption | null
  callback: (succ: boolean, estimateId?: string) => void
}

export interface ReplaceInterface {
  path: string,
  value: any
}

export type NewInspectionActions =
  | CleanNewInspectionAction
  | NewInspectionSetStepAction
  | NewInspectionSetValueAction
  | NewInspectionSetValuesAction
  | CreateInspectionAction
/** REDUCER **/
export interface NewInspectionType {
  step: number
  substep: number
  id: string
  clientType: string

  mainContact: Partial<EstimateContact>
  requester: Partial<EstimateContact>
  address: Address | Partial<Address>
  homeSize?: number
  preferredDate?: number
  occupancyStatus: OCCUPANCY_STATUS | ''
  inspectionStatus: INSPECTION_STATUS | ''
  affiliate?: string
  createdOn?: number
  showProgress?: boolean
  activeStepProgress?: number
  totalStepProgress?: number
}
