import env from '@beam-australia/react-env'
import { CircularProgress, Input, Modal, Typography } from '@material-ui/core'
import { icons } from 'assets/index'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Autocomplete } from 'components/UI'
import { PHONE_MASK_INPUT, errorTextTryingTo, flattenedAddress, formatTimestamp, getContactRoleOpt, history, validateEmail } from 'helpers/index'
import { useEffect, useMemo, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify'
import { EstimateInfo, PTPAddress } from '..'
import useStyles from '../styles'
import { CollapseQuestion } from './Banner3'

const BASE_API_URL = env('PUNCHLIST_BASE_API_URL') ?? ''
const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -100 }



const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '150px',
    borderRadius: '8px',
    marginBottom: "2rem"
}


type Props = {
    address?: PTPAddress,
    estimateInfo: EstimateInfo
    showNotifyModal: boolean
    setShowNotifyModal: (value: boolean) => void
    estimateId: string;
}

interface Role {
    key: string,
    value: string
}

const BannerEstimate = ({ address, estimateInfo, showNotifyModal, setShowNotifyModal, estimateId }: Props) => {
    const classes = useStyles()
    const isInProgress = !estimateInfo.deliveredOn;
    const isOutOfArea = !estimateInfo.serviceable;
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [role, setRole] = useState<Role>()
    const [loading, setLoading] = useState(false)
    const [addressCoords, setAddressCoords] = useState<any>(null)
    const push = history.usePush()

    const handleNotifyModal = () => {
        setShowNotifyModal(true)
    }

    const handleViewEstimate = () => {
        push(`p/estimates/${estimateId}`)
    }

    const autoCompleteService = useMemo(() => {
        if (window && window.google && window.google.maps && window.google.maps.places) {
            return new window.google.maps.places.AutocompleteService();
        }
        return undefined;
    }, [window]);

    const placesService = useMemo(() => {
        if (window && window.google && window.google.maps && window.google.maps.places) {
            return new window.google.maps.places.PlacesService(document.createElement('div'));
        }
        return undefined;
    }, [window]);

    const fetchData = (value: string) => {
        const request = {
            input: value,
            componentRestrictions: { country: ['us', 'ca'] },
        }

        value && autoCompleteService?.getPlacePredictions(request).then((results) => {
            const { predictions } = results

            placesService?.getDetails(
                {
                    placeId: predictions?.[0]?.place_id,
                },
                (place: any) => {
                    if (place !== null && flattenedAddress(place).zipCode) {
                        setAddressCoords({
                            lat: place.geometry.location.lat(), lng: place.geometry.location.lng()
                        })
                    }
                }
            )
        })
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${GOOGLE_MAPS_API_KEY}&loading=async`,
    })

    const todayTimestamp = new Date().getTime();
    const isExpired = estimateInfo.dueOn ? estimateInfo.dueOn > todayTimestamp : false;

    const isButtonDisabled = !firstName || !lastName || !email || !validateEmail(email) || !phoneNumber || phoneNumber.length < 10 || !role

    const patchEstimate = async (formData: any) => {
        setLoading(true)
        const urlHasALastSlash = BASE_API_URL.includes("/", BASE_API_URL.length - 1)
        try {
            const res = await fetch(`${BASE_API_URL}${urlHasALastSlash ? "" : "/"}estimates/${address?.estimateId}`, {
                method: "PATCH",
                body: JSON.stringify([{ op: "add", path: "/properties/contacts/-", value: { ...formData } }]),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer undefined`
                }
            })

            if (res.status === 200) {
                toast.success("Contact added successfully!")
                setShowNotifyModal(false)
                setFirstName("")
                setLastName("")
                setEmail("")
                setPhoneNumber("")
                setRole(undefined)
            } else {
                toast.error(errorTextTryingTo("update estimate details. Please try again."))
            }
            setLoading(false)
        } catch (err) {
            toast.error(errorTextTryingTo("update estimate details"))
            setShowNotifyModal(false)
            setLoading(false)
        }
    }

    const handleSubmit = () => {
        patchEstimate({ firstName, lastName, email, phoneNumber, role: role?.key })
    }

    useEffect(() => {
        if (address) {
            fetchData(`${address.line1} ${address.city} ${address.countrySubDivisionCode} ${address.postalCode} ${address.country}`)
        }
    }, [address])

    const hasCoordinates =
        addressCoords?.lat !== defaultCenter?.lat &&
        addressCoords?.lng !== defaultCenter?.lng

    return (
        <>

            <div className={classes.banner1_Container}>
                <div className={`${classes.bannerEstimate_Body} ${classes.maxWidth}`}>
                    <div className={classes.bannerEstimate_TopInformation}>
                        <Typography className={classes.bannerEstimate_Title}>{!isInProgress ? "This estimate was prepared and delivered" : "This estimate has been requested and is expected to be ready by"}</Typography>
                        <Typography className={classes.bannerEstimate_ExpectedBy}>{!isInProgress ? estimateInfo.deliveredOn ? `${formatTimestamp(estimateInfo.deliveredOn, 'MMMM DD, YYYY hh:mm A')}` : "null" : estimateInfo.dueOn ? `${formatTimestamp(estimateInfo.dueOn, 'MMMM DD, YYYY hh:mm A')}` : "null"}</Typography>
                        <Typography className={classes.bannerEstimate_Inspection}>{`Inspection Number: ${address?.inspectionReport}`}</Typography>

                        {isInProgress ?
                            <>
                                <Typography className={classes.bannerEstimate_Notified}>Would you like to be notified?</Typography>
                                <div onClick={handleNotifyModal} className={classes.bannerEstimate_ButtonNotify}>
                                    <Typography>NOTIFY ME</Typography>
                                </div>
                            </>
                            : <div onClick={handleViewEstimate} className={classes.bannerEstimate_ButtonNotify}>
                                <Typography>VIEW ESTIMATE</Typography>
                            </div>}
                    </div>
                    <div className={classes.bannerEstimate_BodyLeft}>
                        {isLoaded && (<GoogleMap
                            center={addressCoords ? addressCoords : { lat: defaultCenter?.lat, lng: defaultCenter?.lng }}
                            zoom={hasCoordinates ? 18 : 3}
                            mapContainerStyle={containerStyle}
                            // mapContainerClassName={customClass}
                            options={{ clickableIcons: false }}
                            // onLoad={!savedAddress.line_1 ? onMapLoad : undefined}
                            // onLoad={onMapLoad}
                            onClick={(e) => {
                            }}
                        >
                            {hasCoordinates && <Marker position={addressCoords} />}
                        </GoogleMap>)}
                    </div>
                    <div className={classes.bannerEstimate_BodyRight}>
                        <Typography className={classes.bannerEstimate_BodyRightAddressLine1}>
                            {address?.line1 || "null"}
                        </Typography>
                        <Typography className={classes.bannerEstimate_BodyRightAddressLine2}>
                            {`${address?.city || "null"}, ${address?.countrySubDivisionCode || "null"} ${address?.postalCode || "null"}`}
                        </Typography>
                        <div className={`${classes.statusTag} ${isInProgress && !isExpired ? classes.inProgress : classes.completed}`}>
                            <Typography className={classes.statusTagText}>
                                {`estimate ${isInProgress && !isExpired ? "in progress" : "completed"}`}
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.bannerEstimate_BodyRightExpected}>
                                Expected delivery
                            </Typography>
                            <Typography className={classes.bannerEstimate_BodyRightExpectedDate}>
                                {formatTimestamp(estimateInfo.deliveredOn ? estimateInfo.deliveredOn : estimateInfo.dueOn, 'MMMM DD, YYYY hh:mm A') || "null"}
                            </Typography>
                        </div>
                        {!isInProgress ? <div onClick={handleViewEstimate} style={{ marginTop: "64px", marginBottom: "64px" }} className={classes.bannerEstimate_ButtonNotify}>
                            <Typography>VIEW ESTIMATE</Typography>
                        </div> : isOutOfArea ?
                            <div>
                                <CollapseQuestion question="How It Works" answer="BOSSCAT has successfully retreived your home inspection details from Pillar To Post. Our team is working hard on price estimating your inspection summary. In some cases, there is not enough information to provide a price for certain items, if that happens, we may need to reach out for further detail." />
                                <CollapseQuestion question="Out of Area" answer="This property address lies outside of our technician service area. We are be unable to perform repairs at this time." />
                            </div>
                            :
                            <div>
                                <CollapseQuestion question="Step 1 - Estimating" answer="BOSSCAT has successfully retreived your home inspection details from Pillar To Post. Our team is working hard on price estimating your inspection summary. In some cases, there is not enough information to provide a price for certain items, if that happens, we may need to reach out for further detail." />
                                <CollapseQuestion question="Step 2 - Scheduling Repairs" answer="This property is within our BOSSCAT Service Area! We have qualified technicians available to perform the repairs associated with this estimate. Once this estimate is delivered to you, simply click the button to approve and schedule and our dedicated success managers will handle all the logistics!" />
                                <CollapseQuestion question="Step 3 - Peace of Mind!" answer="BOSSCAT repairs are guranteed for a year! And the best part is that it doesn't matter who owns the home. We are so sure you will be happy with the work we do, we want you to have the peace of mind we will stand behind it." />
                            </div>}
                    </div>
                </div>
            </div>
            <Modal
                open={showNotifyModal}
                onClose={() => setShowNotifyModal(false)}
                aria-labelledby="feedback-modal"
                aria-describedby="feedback-modal-description"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className={classes.modal}
            >
                <div className={classes.modalContainer}>
                    <div className={classes.modalHeader}>
                        <Typography>Your Contact Information</Typography>
                    </div>
                    <div className={classes.modalForm}>
                        <icons.Mail style={{ width: "65px", height: "65px", color: "var(--bosscat-blue-600)" }} />
                        <Input className={`${classes.banner1_BodyRightInput}`} placeholder="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                        <Input className={`${classes.banner1_BodyRightInput}`} placeholder="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                        <Input className={`${classes.banner1_BodyRightInput}`} placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                        <MaskedInput mask={PHONE_MASK_INPUT} placeholderChar={'\u2000'} showMask={false} className={`${classes.banner1_BodyRightInput} ${classes.marginTop}`} placeholder="Phone Number" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} />
                        <Autocomplete
                            label=''
                            onChange={(value) => {
                                setRole(value as Role)
                            }}
                            value={role}
                            options={getContactRoleOpt()}
                            placeholder='Your role in this transaction'
                            className={classes.autocompleteRole}
                        />
                        <div onClick={loading ? () => { } : handleSubmit} style={loading ? { pointerEvents: "none" } : {}} className={`${classes.bannerEstimate_ButtonNotify} ${isButtonDisabled ? classes.modalFormButtonDisabled : ""}`}>
                            {loading ? <CircularProgress style={{ color: "#fff", width: "20px", height: "20px" }} /> : <Typography>SUBMIT</Typography>}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default BannerEstimate
