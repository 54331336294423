import { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useStyles from '../../../styles'
import EcoIcon from '@material-ui/icons/Eco'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import CheckIcon from '@material-ui/icons/Check'
import { Table } from 'components/UI'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPropertyDetail,
  getPropertyInquiredItems,
  getTerritory,
} from 'ducks/selectors'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import InquireModal from '../../../Modals/InquireModal'
import { useIsLgScreen, useIsXlScreen } from 'hooks/useMediaQuery'
import RenovationEstimateRow from '../../../RowComponents/RenovationEstimateRow'

const RenovationEstimate = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const property = useSelector(getPropertyDetail())
  const {
    details: { renovationEstimate },
  } = property

  // const [inquiredItems, setInquiredItems] = useState<string[]>([])
  const inquiredItems = useSelector(getPropertyInquiredItems())
  const territory = useSelector(getTerritory())

  const [lgScreen] = useIsLgScreen()
  const [xlScreen] = useIsXlScreen()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [costType, setCostType] = useState<string>('Good')

  const costTypeOptions = [
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Better',
      value: 'Better',
    },
    {
      label: 'Best',
      value: 'Best',
    },
  ]

  const handleOptionChange = (newOption: string) => {
    setCostType(newOption)
  }

  const getOptionStyle = (option: string) => {
    if (option === 'Good') {
      return styles.selectedGoodCostTypeOption
    } else if (option === 'Better') {
      return styles.selectedBetterCostTypeOption
    } else {
      return styles.selectedBestCostTypeOption
    }
  }

  const handleInquire = (condition: any) => {
    // setInquiredItems([...inquiredItems, condition.project])
    dispatch(
      propertiesActions.requestPropertyRenovation(
        { propertyId: property?.id, request: condition },
        () => {}
      )
    )
    dispatch(
      propertyDetailActions.setPropertyInquiredItems([
        ...inquiredItems,
        condition.project,
      ])
    )
  }

  return (
    <Grid
      item
      container
      direction="column"
      style={{ marginTop: '24px' }}
      spacing={2}
    >
      {/* <Grid item container>
        <Box
          className={styles.containerBox}
          padding="8px"
          width="100%"
          bgcolor="var(--bosscat-green-100)"
          display="flex"
          flexDirection="column"
          style={{ gap: '8px' }}
        >
          <Box display="flex" color="var(--bosscat-green-600)">
            <EcoIcon style={{ width: '24px', height: '24px' }} />
            <Typography className={styles.lgFont}>
              = Green Rebate and Tax Incentives
            </Typography>
          </Box>
          <Typography className={styles.title}>
            Items with this indicator means you’ll have incentives and/or green
            rebates.
          </Typography>
        </Box>
      </Grid> */}
      <Grid item container>
        {xlScreen || lgScreen ? (
          <Box className={styles.containerBox} width="100%">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={styles.cellHead}
                    align="center"
                    style={{ borderTopLeftRadius: '8px' }}
                  >
                    Category / Item
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Count
                  </TableCell>
                  <TableCell
                    className={styles.cellHead}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexFlow: 'column',
                    }}
                  >
                    Cost
                    <Box
                      className={styles.containerBox}
                      width="100%"
                      marginTop="4px"
                    >
                      <Grid container>
                        {costTypeOptions.map((option) => (
                          <Grid
                            item
                            onClick={() => handleOptionChange(option.value)}
                            xs
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={
                              option.value === costType
                                ? getOptionStyle(option.value)
                                : styles.unSelectedCostTypeOption
                            }
                          >
                            <Typography className={styles.description}>
                              {option.label}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </TableCell>
                  {territory?.serviceable && (
                    <TableCell
                      className={styles.cellHead}
                      style={{
                        textAlign: 'center',
                        width: '150px',
                        borderTopRightRadius: '8px',
                      }}
                    >
                      Consultation Request
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {renovationEstimate?.items?.map((item) => (
                  <TableRow>
                    <TableCell
                      className={styles.cell}
                      style={{ fontWeight: 700 }}
                    >
                      {item.project}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {`${item.quantity} ${item.unit}`}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {`$${(item as any)[`cost${costType}`]?.toLocaleString()}`}
                    </TableCell>
                    {territory?.serviceable && (
                      <TableCell className={styles.cell}>
                        <Button
                          startIcon={
                            inquiredItems.includes(item.project) ? (
                              <CheckIcon className={styles.buttonIcon} />
                            ) : (
                              <></>
                            )
                          }
                          variant="contained"
                          className={
                            inquiredItems.includes(item.project)
                              ? styles.inquiredButton
                              : styles.inquireButton
                          }
                          style={{
                            height: '44px',
                          }}
                          fullWidth
                          onClick={() => {
                            if (!inquiredItems.includes(item.project)) {
                              setModalOpen(true)
                              handleInquire(item)
                            }
                          }}
                        >
                          <Typography
                            className={styles.lgFont}
                            style={{
                              textTransform: 'none',
                            }}
                          >
                            {inquiredItems.includes(item.project)
                              ? ''
                              : 'Inquire'}
                          </Typography>
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    className={styles.cellHead}
                    style={{ borderBottomLeftRadius: '8px' }}
                  >
                    Total
                  </TableCell>
                  <TableCell className={styles.cellHead}></TableCell>
                  <TableCell
                    className={styles.cellHead}
                    style={{ textAlign: 'center' }}
                  >{`$${(renovationEstimate as any)[
                    `totalCost${costType}`
                  ]?.toLocaleString()}`}</TableCell>
                  {territory?.serviceable && (
                    <TableCell
                      className={styles.cellHead}
                      style={{ borderBottomRightRadius: '8px' }}
                    ></TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Grid container item direction="column" style={{ gap: '16px' }}>
            {renovationEstimate?.items?.map((item) => (
              <RenovationEstimateRow item={item} setModalOpen={setModalOpen} />
            ))}
          </Grid>
        )}
      </Grid>
      <InquireModal open={modalOpen} setOpen={setModalOpen} />
    </Grid>
  )
}

export default RenovationEstimate
