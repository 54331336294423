import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        container: {
            marginTop: '.5rem',
            height: 'fit-content',
            rowGap: '0rem'
        },
        cancel: {
            textTransform: 'none',
            color: 'var(--bosscat-blue-600)',
            backgroundColor: '#FAFBFF',
            borderRadius: 8,
            height: '44px',
            fontFamily: 'LatoBold',
            border: 'none',
            '&:hover': {
                color: 'var(--bosscat-blue-600)',
                backgroundColor: 'var(--white-color)'
            }
        },
        submit: {
            textTransform: 'none',
            color: 'var(--white-color)',
            backgroundColor: 'var(--bosscat-blue-600)',
            borderRadius: 8,
            height: '44px',
            fontFamily: 'LatoBold',
        },
        buttonsContainer: {
            display: 'flex',
            width: '100%',
            gap: '.5rem',
            marginTop: '1.5rem'
        },
        spinner: {
            '& .MuiCircularProgress-circle': {
                color: 'var(--white-color)'
            }
        },
    }))
