import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    upload: {
      minHeight: '240px',
      position: 'relative',
      background: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    dropzone: {
      display: 'flex',
      border: '2px dashed var(--bosscat-blue-600)',
      backgroundColor: 'rgba(245, 247, 255, 1)',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      minHeight: 'auto',
      margin: '24px',
      top: '-3px',
      bottom: '-3px',
      left: '-3px',
      right: '-3px',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        '& .MuiDropzoneArea-text': {
          fontSize: '18px !important',
          fontFamily: 'LatoBlack',
          color: "var(--bosscat-blue-600)"
        },
        [theme.breakpoints.down('sm')]: {
          width: 'fit-content',
          '& .MuiDropzoneArea-text': {
            fontSize: '16px !important'
          }
        }
      }
    },
    dropzoneTextContainer: {
      display: "flex",
    },
    buttonBox: {
      textAlign: 'end'
    },
    button: {
      width: '220px',
      height: '36px',
      margin: '12px',
      textTransform: 'none',
      alignItems: 'base',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      whiteSpace: "nowrap",
      [theme.breakpoints.down('sm')]: {
        width: 'fit-content'
      },
      '& .MuiButton-label': {
        display: "flex"
      }
    },
    image: {
      width: '16px',
      height: '16px',
      paddingBottom: '2px'
    },
    filesBox: {
      margin: '0 16px 16px',
      padding: '16px 8px',
      borderRadius: '8px',
      display: 'grid',
      gap: '16px',
      backgroundColor: 'var(--background-color-secondary)'
    },
    file: {
      display: 'flex',
      padding: '12px',
      margin: '0 8px',
      borderRadius: '8px',
      gap: '8px',
      alignItems: 'center',
      backgroundColor: 'var(--white-color)',
      position: 'relative'
    },
    delete: {
      position: 'absolute',
      right: '16px'
    },
    error: {
      borderColor: theme.palette.error.main
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    },
    name: {
      marginRight: '35px'
    }
  })
)
