/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { computer, logo, streamlinedOnline, success } from '../../../../../../assets'
import {
  isAuthorized,
  isRegisterCompleted
} from '../../../../../../ducks/selectors'
import {
  CONTACT_ROLE,
  history
} from '../../../../../../helpers'
import {
  Box,
  Button,
  Typography
} from '../../../../../UI'
import useStyles from './styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'




const Details: FC<{
  setPage: (page: 'success' | 'expedite' | 'register') => void,
  clientType: string
}> = ({ setPage, clientType }) => {
  const classes = useStyles()
  const authorized = useSelector(isAuthorized)

  const registerCompleted = useSelector(isRegisterCompleted)

  const push = history.usePush()

  return (
    <Box className={classes.root}>
      <a href="https://www.bosscathome.com/" target="_blank" rel="noreferrer">
        <img src={logo} className={classes.logo} alt="BOSSCAT Logo" />
      </a>
      <img src={success} className={classes.success} alt="success" />
      <Typography className={classes.nextHeavyHardware}>
        Your order is confirmed!
      </Typography>
      <Typography className={classes.header} style={clientType && clientType !== CONTACT_ROLE.LISTING_AGENT ? { marginBottom: 0 } : {}}>
        Thanks for submitting your {' '}
        <span className={classes.bold}>{`Maintenance Membership order!`}</span>
      </Typography>

      {!authorized && clientType && clientType !== CONTACT_ROLE.LISTING_AGENT && (
        <Typography className={`${classes.header} ${classes.nextStep}`}>
          Next Step: Please log in to your account
          and activate your membership.
        </Typography>
      )}

      {authorized && <div style={{ marginTop: "1rem" }}>
        <Button
          variant="contained"
          className={classes.createButton}
          onClick={() => push('p/maintenance')}
        >Go to Maintenance Dashboard</Button>
      </div>
      }

      {authorized && !registerCompleted && (
        <Box className={classes.completeProfile}>
          <img alt="add new member icon" width={72} src={computer} />
          <Box>
            <Typography className={classes.ask}>Great work! Now it’s time to finish creating your BOSSCAT Profile</Typography>
            <Typography style={{ fontSize: '14px !important', lineHeight: '1.2' }} className={classes.desc2}>Complete your profile to see your Estimate when it is ready. Just 2 simple steps!</Typography>
          </Box>
          <Button
            variant="contained"
            className={classes.createButton}
            onClick={() => push('u/register')}
          >Complete my Profile</Button>
        </Box>
      )}

      {!authorized && (
        <Box className={classes.createAccount}>
          {/* <img className={classes.icon} src={streamlinedOnline} alt="" /> */}
          <Typography variant="body1" className={classes.createAccountAsk}>
            We’ve created an account for you in the bosscat portal
          </Typography>
          <Typography className={classes.createAccountDesc1}>
            Check your Email for your Password.
          </Typography>
          <Typography className={classes.createAccountDesc2}>
            You will need to log in to your account to view the Maintenance Membership.
          </Typography>
          <Button
            variant="contained"
            className={classes.createAccountCreateButton}
            onClick={() => {
              // setPage('register')
              push('login')
            }}
            endIcon={<ArrowForwardIcon style={{ width: '14px', height: '14px' }} />}
          >
            Go to BOSSCAT Portal
          </Button>
        </Box>
      )}

    </Box>
  )
}
export default Details
