import { authTypes } from 'ducks/auth/types'
import { PriceItem, Territory } from 'ducks/types'
import { territoriesTypes, TerritoriesType, TerritoriesActions } from './types'

const initialState: TerritoriesType = {
  territories: [],
  territory: null,
  repairItems: []
}

const reducer = (state = initialState, action: TerritoriesActions): TerritoriesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case territoriesTypes.SET_TERRITORIES: {
      return {
        ...state,
        territories: payload as Territory[]
      }
    }

    case territoriesTypes.SET_TERRITORY: {
      return {
        ...state,
        territory: payload as Territory
      }
    }

    case territoriesTypes.SET_REPAIR_ITEMS: {
      return {
        ...state,
        repairItems: payload as PriceItem[]
      }
    }

    default:
      return state
  }
}

export default reducer
