import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '85%',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '95%',
            },
            [theme.breakpoints.up('xl')]: {
                margin: '8rem auto',
                maxWidth: '68%',
            }
        },
        title: {
            color: '#A5965A',
            fontSize: '45px',
            fontFamily: "NextArtHeavy",
            textAlign: 'center',
            marginBottom: "1rem"
        },
        subtitle: {
            textAlign: 'center',
            fontSize: "1.5rem",
            // paddingInline: "12rem",
            [theme.breakpoints.down('sm')]: {
                paddingInline: "0rem",
            }
        },
        banners: {
            display: 'flex',
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "6rem",
            marginTop: "6rem",
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",
                gap: "3rem",
                marginTop: "2rem"
            }
        },
        banner: {
            width: "33%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            }
        },
        bannerTitle: {
            fontSize: "1.5rem",
            fontFamily: "NextArtHeavy",
            color: "var(--bosscat-black-600)",
            textAlign: "center",
            maxWidth: "80%",
        },
        bannerSubtitle: {
            fontSize: "1rem",
            color: "var(--bosscat-black-600)",
            opacity: 0.7,
            textAlign: "center",
        },
        bannerImg: {
            width: "128px",
            height: "128px",
        }
    })
)
