import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { HOURS } from './constanst'
import useStyles from './styles'

type Props = {
    onChange: (time: string) => void;
}

function TimeSelector({ onChange }: Props) {
    const [hourTime, setHourTime] = useState<"AM" | "PM">("AM")
    const [hourSelected, setHourSelected] = useState(0)

    const classes = useStyles()

    const changeHourTime = (time: "AM" | "PM") => {
        setHourTime(time)
        setHourSelected(0)
    }

    function handleSelectTime(index: number) {
        setHourSelected(index)
        onChange(`${HOURS[hourTime][index]} ${hourTime}`)
    }

    return (
        <Box className={classes.Container}>
            <Box className={classes.TabContainer}>
                <Box onClick={() => changeHourTime("AM")} className={`${classes.Tab} ${hourTime === "AM" && classes.Selected}`}>
                    <Typography>AM</Typography>
                </Box>
                <Box onClick={() => changeHourTime("PM")} className={`${classes.Tab} ${hourTime === "PM" && classes.Selected}`}>
                    <Typography>PM</Typography>
                </Box>
            </Box>
            <Box className={classes.HoursContainer}>
                {HOURS[hourTime].map((hour, index) =>
                    <Box onClick={() => handleSelectTime(index)} key={`${hour}-${index}`} className={`${classes.Hour} ${index === hourSelected && classes.Selected}`}>
                        <Typography>
                            {hour}
                        </Typography>
                        <Typography className={classes.HourTime}>
                            {hourTime}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default TimeSelector