import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: 'grid',
      alignItems: 'center',
      alignContent: 'center',
      width: 560,
      zIndex: 999
    },
    container: {
      background: 'var(--head-text-color)',
      height: '100%',
      minHeight: '100vh',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        background: 'white'
      }
    },
    textureBottom: {
      position: 'absolute',
      bottom: -100,
      right: 0,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  })
)