import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'baseline',
      fontSize: 20,
      fontFamily: 'LatoLight',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      }
    },
    form: {
      maxWidth: '360px',
      marginTop: theme.spacing(4)
    },
    submit: {
      margin: theme.spacing(4, 0, 2),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8
    },
    email: {
      paddingBottom: theme.spacing(1)
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    pass: {
      width: '360px'
    },
    icon: {
      margin: '0 0 4px 0',
      width: '12px',
      height: '12px'
    },
    successText: {
      color: 'red',
    }
  })
)
