import {
  NewOrderMaintenanceValue,
  NewOrderMaintenanceValues,
  newOrderMaintenanceTypes,
  NewOrderMaintenanceActions,
  NewOrderMaintenanceType,
} from './types'

const actions = {
  goBackOrderMaintenance: (): NewOrderMaintenanceActions => ({
    type: newOrderMaintenanceTypes.GO_BACK,
    payload: null
  }),
  goForwardOrderMaintenance: (): NewOrderMaintenanceActions => ({
    type: newOrderMaintenanceTypes.GO_FORWARD,
    payload: null
  }),
  setNewOrderMaintenanceValue: (payload: NewOrderMaintenanceValue): NewOrderMaintenanceActions => {
    return ({
      type: newOrderMaintenanceTypes.SET_VALUE,
      payload
    })
  },
  setNewOrderMaintenanceValues: (payload: NewOrderMaintenanceValues): NewOrderMaintenanceActions => ({
    type: newOrderMaintenanceTypes.SET_VALUES,
    payload
  }),
  clearOrderMaintenance: (): NewOrderMaintenanceActions => ({
    type: newOrderMaintenanceTypes.CLEAR,
    payload: null
  }),
  setOrderMaintenance: (payload: NewOrderMaintenanceType): NewOrderMaintenanceActions => ({
    type: newOrderMaintenanceTypes.SET_ORDER_MAINTENANCE,
    payload
  }),
}

export default actions
