/* eslint-disable @typescript-eslint/no-unused-vars */
import { format } from 'date-fns'
import moment from 'moment'
import storeInfo from 'ducks/store'
import { formatTimestamp } from 'helpers'
import { EstimateService, PaymentOption } from 'ducks/types'
import { ConfigType, ErrorValue } from './types'
import { formatTimestampDate } from 'helpers/index'

export const getConfigValue = (type: string) => (): any => {
  const { config } = storeInfo.store.getState()
  return (config && config[type as keyof ConfigType]) || null
}

export const getConfigDashboardDateRange = () => (): any => {
  const {
    config: { dashboardDateRange }
  } = storeInfo.store.getState()
  if (dashboardDateRange) {
    if (dashboardDateRange.startDate && dashboardDateRange.endDate) {
      if (dashboardDateRange.startDate instanceof Date) {
        return dashboardDateRange
      } else {
        return {
          startDate: new Date(dashboardDateRange.startDate),
          endDate: new Date(dashboardDateRange.endDate)
        }
      }
    }
    return {}
  }
  // return {
  //   startDate: moment()
  //     .startOf('month')
  //     .toDate(),
  //   endDate: moment()
  //     .endOf('month')
  //     .toDate()
  // } // This Month
  return {} // All History
}

export const getConfigEstimateDateRange = () => (): any => {
  const {
    config: { estimateDateRange }
  } = storeInfo.store.getState()
  if (estimateDateRange) {
    if (estimateDateRange.startDate && estimateDateRange.endDate) {
      if (estimateDateRange.startDate instanceof Date) {
        return estimateDateRange
      } else {
        return {
          startDate: new Date(estimateDateRange.startDate),
          endDate: new Date(estimateDateRange.endDate)
        }
      }
    }
    return {}
  }
  return {
    startDate: moment()
      .startOf('month')
      .toDate(),
    endDate: moment()
      .endOf('month')
      .toDate()
  } // This Month
}

export const getConfigJobDateRange = () => (): any => {
  const {
    config: { jobDateRange }
  } = storeInfo.store.getState()
  if (jobDateRange) {
    if (jobDateRange.startDate && jobDateRange.endDate) {
      if (jobDateRange.startDate instanceof Date) {
        return jobDateRange
      } else {
        return {
          startDate: new Date(jobDateRange.startDate),
          endDate: new Date(jobDateRange.endDate)
        }
      }
    }
    return {}
  }
  return {
    startDate: moment()
      .startOf('month')
      .toDate(),
    endDate: moment()
      .endOf('month')
      .toDate()
  } // This Month
}

export const getError = () => (): ErrorValue => {
  const { config } = storeInfo.store.getState()
  if (!config) return { show: false, type: '' }
  return config.error || null
}

export const getSideMenuOpen = () => (): boolean => {
  const { config } = storeInfo.store.getState()
  if (!config) return false
  return config.sideMenuOpen || false
}

export const getEstimatesServices = () => {
  const {
    config
  } = storeInfo.store.getState()
  return config?.estimatesServices || []
}

export const getPaymentOptions = () => (): PaymentOption[] => {
  const {
    config
  } = storeInfo.store.getState()

  if (!config?.estimatesServices || !config?.estimatesServices?.length) return []

  let sum = 0
  let dayToAdd = new Date(Date.now())
  let dayToAddNumber = Number.parseInt(format(dayToAdd.getTime(), 'dd'))
  let monthToAddNumber = dayToAdd.getMonth()
  let yearToAddNumber = dayToAdd.getFullYear()
  const today = Number.parseInt(format(dayToAdd.getTime(), 'dd'))

  let bestSetted = false

  const paymentOptions: PaymentOption[] =
    config?.estimatesServices?.sort((a, b) => a.date - b.date).map((service: EstimateService) => {
      const isBest = !bestSetted
      bestSetted = true

      return {
        id: service.id,
        date: service.date,
        title: formatTimestamp(service.date, 'dddd'),
        day: formatTimestamp(service.date, 'DD'),
        subtitle: service.title,
        enable: true,
        best: isBest,
        price: service.cost,
        days: service.days
      }
    }) ?? []

  const finalPaymentOptions: PaymentOption[] = []

  const _addNullDay = (id: string, date: number, title: string, day: string, subtitle: string) => {
    finalPaymentOptions.push({
      id,
      date,
      title,
      day,
      subtitle,
      enable: false,
      best: false,
      price: 0,
      days: 0
    })
  }

  const _getSubtitle = (title: string, day: string) => {
    return title === 'Saturday' || title === 'Sunday'
      ? 'Weekend'
      : today === Number.parseInt(day)
        ? 'Today'
        : today + 1 === Number.parseInt(day)
          ? 'Tomorrow'
          : title
  }

  //* ADDING DAYS FROM TODAY TO THE FIRST DAY IN OPTIONS
  for (
    let day = dayToAddNumber;
    day < Number.parseInt(paymentOptions[0].day) ||
    monthToAddNumber < formatTimestampDate(paymentOptions[0].date).getMonth() ||
    yearToAddNumber < formatTimestampDate(paymentOptions[0].date).getFullYear();
    day++
  ) {
    const title = format(dayToAdd.getTime(), 'EEEE')
    const day = format(dayToAdd.getTime(), 'dd')
    const subtitle = _getSubtitle(title, day)
    _addNullDay(sum.toString(), Math.floor(dayToAdd.getTime() / 1000), title, day, subtitle)
    sum++
    dayToAdd.setDate(dayToAdd.getDate() + 1)
    monthToAddNumber = dayToAdd.getMonth()
    yearToAddNumber = dayToAdd.getFullYear()
  }

  if (paymentOptions.length < 2) {
    // * CHECK IF THE OPTIONS ARRAY HAVE ONLY 1 OPTION
    finalPaymentOptions.push(...paymentOptions)
  } else {
    // * IF IT HAS MORE THAN ONE, REPEAT DAYS FROM THE FIRST DAY
    // * IN OPTIONS TO THE LAST AND THEN ADD ALL REST OPTIONS
    // * INTO THE FINAL ARRAY OF OPTIONS
    const [firstOption, ...rest] = paymentOptions
    finalPaymentOptions.push(firstOption)
    dayToAdd = formatTimestampDate(firstOption.date)
    dayToAdd.setDate(dayToAdd.getDate() + 1)
    dayToAddNumber = Number.parseInt(format(dayToAdd.getTime(), 'dd'))
    for (let day = dayToAddNumber; day < Number.parseInt(rest[0].day); day++) {
      const title = format(dayToAdd.getTime(), 'EEEE')
      const day = format(dayToAdd.getTime(), 'dd')
      const subtitle = _getSubtitle(title, day)
      _addNullDay(sum.toString(), dayToAdd.getTime(), title, day, subtitle)
      sum++
      dayToAdd.setDate(dayToAdd.getDate() + 1)
    }
    finalPaymentOptions.push(...rest)
  }
  if (finalPaymentOptions.length < 4) {
    for (let i = finalPaymentOptions.length; i < 4; i++) {
      const dayToAdd = formatTimestampDate(finalPaymentOptions[i - 1].date)
      dayToAdd.setDate(dayToAdd.getDate() + 1)
      const title = format(dayToAdd.getTime(), 'EEEE')
      const day = format(dayToAdd.getTime(), 'dd')
      const subtitle = _getSubtitle(title, day)
      _addNullDay(sum.toString(), dayToAdd.getTime(), title, day, subtitle)
    }
  }
  return finalPaymentOptions
}
