import { Stepper } from 'components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { getApprovalStep } from 'ducks/selectors'
import { approvalActions } from 'ducks/actions'
import { ApprovalValue } from 'ducks/approval/types'
import Step1SecondaryHomeowner from './Step1/Step1Secondary'
import Step2SecondaryHomeowner from './Step2/Step2Secondary'
import Step3SecondaryHomeowner from './Step3/Step3Secondary'

const HomeownerSecondApprovalStepper = () => {
  const dispatch = useDispatch()
  const activeStep = useSelector(getApprovalStep)

  const steps = [
    {
      content: <Step1SecondaryHomeowner />,
    },
    {
      content: <Step2SecondaryHomeowner />,
      disableBack: true,
      // actionNext: () => setIsFinished(true),
    },
    {
      content: <Step3SecondaryHomeowner />,
      disableBack: true,
    },
  ]

  return (
    <>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStepAction={(step: number) =>
          dispatch(approvalActions.setApprovalStep(step))
        }
        saveNewValueAction={(value: ApprovalValue) =>
          dispatch(approvalActions.setApprovalValue(value))
        }
        //className={classes.stepper}
      />
    </>
  )
}

export default HomeownerSecondApprovalStepper
