import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '0px 26px',
      [theme.breakpoints.down('md')]: {
        padding: '0px 16px'
      }
    }
  })
)
