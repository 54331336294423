import { Box, Grid, List, ListItem, Typography } from 'components/UI'
import { supportTeam } from 'assets/index'
import useStyles from './styles'
import { Bullet, QAType } from './types'
import BulletList from './BulletList'
import { isMobile } from 'react-device-detect'
import { PERKS } from 'helpers/index'

const QAs: QAType[] = [
  {
    question: 'Q: What is the VIP Home Maintenance Membership program?',
    answer:
      'An annual membership program for homeowners that provides seasonal services and scheduled home health checkups to preserve home value and extend the life of major systems through proactive care and professional maintenance.',
  },
  {
    question: `Q: What's included in the membership?`,
    answer: `A: With the membership, you receive four (4) annual service visits, which include seasonal, routine maintenance services and home health check-ups of all major systems. The membership also includes a "Welcome Home" gift set delivered at the program's start and a dedicated VIP Home Maintenance team (1-800-282-4859) for prompt assistance.`,
  },
  {
    question: 'Q: What services are provided?',
    answer: `A: The service visits are specific to each season and include:`,
    bullets: [
      {
        desc: '',
        bold: 'Spring',
        bullets: PERKS.find((perk) => perk?.title === 'Spring')?.bullets?.map(
          (bullet) => ({ desc: bullet })
        ),
      },
      {
        desc: '',
        bold: 'Summer',
        bullets: PERKS.find((perk) => perk?.title === 'Summer')?.bullets?.map(
          (bullet) => ({ desc: bullet })
        ),
      },
      {
        desc: '',
        bold: 'Fall',
        bullets: PERKS.find((perk) => perk?.title === 'Fall')?.bullets?.map(
          (bullet) => ({ desc: bullet })
        ),
      },
      {
        desc: '',
        bold: 'Winter',
        bullets: PERKS.find((perk) => perk?.title === 'Winter')?.bullets?.map(
          (bullet) => ({ desc: bullet })
        ),
        extra:
          'The scope of the maintenance services is intended to cover customary equipment and costs; any equipment or material that is not customary may not be covered or may require additional fees.',
      },
    ],
  },
  {
    question: 'Q: What is the home health assessment?',
    answer:
      'A: During each seasonal maintenance visit, our professional team will conduct a routine check-up of your home’s health, including a review of all major systems and common issues that frequently increase repair costs, reduce home value or create health issues if left unaddressed. ',
  },
  {
    question: 'Q: Are materials included?',
    answer:
      'A: Customary materials for the listed seasonal services are included in the membership. The maintenance services are intended to cover customary equipment and costs, and any specialized equipment and materials that are not customary may not be covered or require an additional fee. ',
  },
  {
    question: 'Q: Who will be coming to my house for the quarterly visits? ',
    answer:
      'A: A professional Bosscat field team member will conduct your quarterly service visits and assist with any questions you may have about your home. All Bosscat field team members are badged for easy identification and specially trained for the VIP Home Maintenance Membership program. ',
  },
  {
    question:
      'Q: Can I request additional maintenance and repair services during my quarterly visits? ',
    answer:
      'A: Yes. In addition to services selected at the time of membership purchase, you can request additional services through your Customer Success Manager after you’ve activated your membership. Add-on services include, but are not limited to: ',

    bullets: [
      { desc: 'HVAC full service' },
      { desc: 'Pressure washing' },
      { desc: 'Caulking repair' },
      { desc: 'Window cleaning' },
      { desc: 'Additional gutter cleaning' },
      { desc: 'Repair of loose deck and stair components' },
      { desc: 'Septic treatment' },
      { desc: 'Lubrication of doors and windows' },
      { desc: 'Dishwasher filter and garbage disposal cleaning' },
      { desc: 'Doorbell and camera installation' },
      { desc: 'Handyman tasks' },
    ],
  },
  {
    question: 'Q: Is there a payment plan?',
    answer:
      'A: If you purchase the membership for your own home or renew at the end of the 1-year gift membership, you will have the option to pay upfront for the year or quarterly.',
  },
]

const FaqModal = () => {
  const styles = useStyles()

  return (
    <Box width={'100%'} display="flex" flexDirection="column" gridGap="32px">
      {QAs.map(({ question, answer, bullets }, index) => (
        <Box key={index} display="flex" flexDirection="column" gridGap="8px">
          <Typography className={styles.Question}>{`${question}`}</Typography>
          <Typography className={styles.Answer}>{`${answer}`}</Typography>
          <List>
            {(bullets?.length || 0) < 6 ? (
              <BulletList items={bullets || []} />
            ) : (
              <Grid container justifyContent="space-between">
                <BulletList
                  items={bullets?.slice(0, Math.ceil(bullets.length / 2)) || []}
                />
                <BulletList
                  items={
                    bullets?.slice(
                      Math.ceil(bullets.length / 2),
                      bullets.length
                    ) || []
                  }
                />
              </Grid>
            )}
          </List>
        </Box>
      ))}

      <Box padding={2} bgcolor="#F9FAFA" display="flex" gridGap={16}>
        <img src={supportTeam} alt="support team" />
        <Box display="flex" flexDirection="column">
          <Typography className={styles.Extra} style={{ fontFamily: 'Lato' }}>
            Have more questions?
          </Typography>
          <Typography className={styles.Extra}>
            Feel free to contact us. Our Team is ready to help.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default FaqModal
