/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import * as Sentry from '@sentry/react'
import { GetPromoAction, promoTypes } from './types'

export function* getPromo({ payload, callback }: GetPromoAction): SagaIterator {
  let success = false
  try {
    const { promoCode, stripeProductPriceId } = payload
    const promo = yield call(Punchlist.promos.getPromo, promoCode, stripeProductPriceId)

    if (promo && callback) {
      success = true
      yield call(callback, success, promo)
    }

  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the promo code'))
    if (callback) yield call(callback, false, null)
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(promoTypes.GET_PROMO, getPromo)
}
