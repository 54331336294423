import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    linearRoot: {
      background: 'rgba(39, 186, 45, 0.08)',
      height: 8,
      borderRadius: 4
    },
    linearColor: {
      backgroundColor: 'var(--bosscat-green-600)'
    },
    value: {
      paddingLeft: '4px',
      textAlign: 'end',
      color: 'var(--bosscat-green-600)',
    },
    mainFont: {
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '150%',
    },
    valueText: {
      paddingLeft: '8px',
      textAlign: 'end',
      color: 'var(--bosscat-green-600)',
    }
  })
)
