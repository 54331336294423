import storeInfo from 'ducks/store'
import { Subscription } from 'ducks/types'
import { isEmpty } from 'helpers/index'
import { NewOrderMaintenanceType } from './types'

export const getNewOrderMaintenanceStep = () => (): any => {
  const { newEstimate } = storeInfo.store.getState() || {}
  return newEstimate?.step || 0
}

export const getNewOrderMaintenanceValue = (type: keyof NewOrderMaintenanceType) => (): any => {
  const { newOrderMaintenance } = storeInfo.store.getState()
  // if (!orderMaintenance) return null
  return newOrderMaintenance[type as keyof NewOrderMaintenanceType]
}

export const getNewOrderMaintenanceValues = () => (): NewOrderMaintenanceType => {
  const { newOrderMaintenance } = storeInfo.store.getState()
  return newOrderMaintenance
}

export const getNewOrderMaintenanceToCreate = () => (): Partial<Subscription> => {
  const { newOrderMaintenance } = storeInfo.store.getState()
  const { step, mobileStep, contractSigned, promo, homeownerReceives, ...rest } = newOrderMaintenance
  if (isEmpty(promo)) {
    rest.promoCode = null
  }
  return Object.fromEntries(Object.entries(rest)?.filter(([_, v]) => v));
}