import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      width: '100%',
      height: '88px',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'var(--better-light-color)',
      borderRadius: '12px',
      position: 'sticky',
      bottom: 0,
      zIndex: 4
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    line1: {
      fontFamily: 'BetterSansBold',
      fontSize: '12px',
      color: 'var(--better-color)'
    },
    line2: {
      fontFamily: 'BetterSansRegular',
      fontSize: '12px',
      color: 'var(--better-color)'
    },
    rowTitle: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 20px'
    },
    rowButtons: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '0px 10px',
        width: '100%'
      }
    },
    forward: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'BetterSansRegular',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-color)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    selectedItems: {
      // width: '220px',
      height: '60px',
      color: 'var(--white-color)',
      fontWeight: 900,
      // backgroundColor: 'var(--repairs-yellow-500)',
      backgroundColor: '#01AA59 !important',
      borderRadius: 8,
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--repairs-yellow-700) !important',
      },
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      }
    },
    requestMoreButton: {
      height: '54px',
      padding: '0 1rem',
      color: 'var(--white-color)',
      backgroundColor: '#01AA59 !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      '&:hover': {
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
    },
    speak: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'BetterSansRegular',
      color: 'var(--better-bold-color)',
      backgroundColor: 'var(--better-light-color)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      '&:hover': {
        backgroundColor: 'var(--white-button)'
      }
    },
    icon: {
      width: '15px',
      height: '15px'
    },
    total: {
      fontFamily: 'BetterSansBold',
      fontWeight: 700,
      color: 'var(--better-color)',
      fontSize: '24px'
    },
    price: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      color: 'var(--better-color)',
      fontSize: '24px',
      marginLeft: '4px',
      minWidth: '80px'
    }
  })
)
