import axios from '../axios'
import { ApiController } from './types'

const base = '/promos'

const apiController: ApiController = {
  getPromo: async (
    promoCode: string,
    stripeProductPriceId: string
  ) =>
    await axios
      .get(`${base}/${promoCode}?stripeProductPriceId=${stripeProductPriceId}`)
      .then(response => response.data),
}

export default apiController
