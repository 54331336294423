import { createStyles, makeStyles, Theme } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      background: 'white',
      // minHeight: '580px',
      borderRadius: 16,
      padding: 24,
      margin: '0 auto',
      gridTemplateRows: 'auto min-content',
      width: '100%',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px !important',
        padding: 12
      }
    },
    title: {
      [theme.breakpoints.down('md')]: {
        paddingTop: '12px',
      }
    },
    content: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '0px 12px 0px 24px',
      maxWidth: '1250px', // theme.breakpoints.values.xxl,
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    stepper: {
      '@global': {
        '.MuiLinearProgress-root': {
          borderRadius: 8,
          height: 8,
          margin: '1rem 0'
        },
        '.MuiLinearProgress-colorPrimary': {
          backgroundColor: '#EBECEF !important'
        },
        '.MuiLinearProgress-barColorPrimary': {
          // background: 'linear-gradient(90deg, rgba(112,144,255,1) 0%, rgba(58,102,255,1) 100%) !important',
          backgroundColor: '#01AA59',
          borderRadius: 8
        },
        '>.MuiBox-root:first-child': {
          justifyContent: 'center !important',
          marginBottom: '1rem'
        },
        '#stepper-controls': {
          [theme.breakpoints.down('md')]: {
            position: 'absolute',
            bottom: 0,
            left: 0
          }
        },
        '>.MuiBox-root': {
          '@global': {
            '>.MuiBox-root': {
              position: 'relative !important',
              overflow: 'hidden',
              '@global': {
                '>.MuiBox-root': {
                  marginBottom: '10px !important',
                  [theme.breakpoints.down('md')]: {
                    margin: '0 !important'
                  }
                }
              }
            }
          }
        },

      },
    },
    center: {
      display: 'grid',
      alignItems: 'center',
      alignContent: 'center',
      width: 560,
      // zIndex: 999
    },
    container: {
      // background: 'var(--head-text-color)',
      height: '100%',
      minHeight: '100vh',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
      }
    },
    textureTop: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    textureBottom: {
      position: 'absolute',
      bottom: -100,
      right: 0,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  })
)
