/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { Punchlist } from 'api'
import { feedbackTypes, FeedbackActions } from './types'
import * as Sentry from '@sentry/react'

export function* sendFeedback({ payload, callback }: FeedbackActions): SagaIterator {
  let success = false
  try {
    const feedback = yield call(Punchlist.feedback.sendFeedback, payload)
    if (feedback) { success = true }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(feedbackTypes.SEND_FEEDBACK, sendFeedback)
}
