import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        CloseButton: {
            position: "absolute",
            top: "16px",
            right: "16px",
            backgroundColor: "#F9FAFA",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            cursor: "pointer"
        },
        CloseIcon: {
            width: "14px",
            height: "14px",
        },
        Title: {
            fontFamily: "Lato",
            fontSize: "20px",
            marginBottom: "8px",
            [theme.breakpoints.down('md')]: {
                maxWidth: "90%"
            }
        },
        Body: {
            maxHeight: "80vh",
            overflowY: "scroll",
            '&::-webkit-scrollbar': {
                display: "none"
            },
            scrollBarWidth: "none",
            "-ms-overflow-style": "none"
        }
    }))
