import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(10, auto)'
    },
    pictureItem: {
      gridColumn: 'span 2',
      gridRow: 'span 2',
      padding: '8px 0px !important',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumn: 'span 10'
      }
    },
    topInputItems: {
      gridColumn: 'span 4',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 10'
      }
    },
    middleInputItem: {
      gridColumn: 'span 10'
    },
    bottomInputItems: {
      gridColumn: 'span 5',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 10'
      }
    },
    submitContainer: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      color: 'var(--white-color)',
      width: '180px',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    line: {
      display: 'flex',
      flexWrap: 'wrap',
      maxHeight: '48px !important',
      '@global': {
        '.MuiTextField-root': {
          height: '30px !important'
        }
      }
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '0 0 0 8px',
    },
    subtitle: {
      fontSize: 18,
      color: '#1A2A55'
    },
    selected: {
      margin: '0px',
      background: '#F9FAFA',
      border: '2px solid var(--bosscat-blue-600)',
      borderRadius: '8px',
    },
    border: {
      border: '2px solid #EBECEF',
      borderRadius: '8px',
      margin: '0px'
    },
    titleContainer: {
      display: 'flex'
    },
    narContent: {
      display: 'grid',
      margin: '8px 0 0 4px',
      padding: '8px',
      backgroundColor: '#F9FAFA'
    },
    borderWarning: {
      border: '2px solid transparent',
    },
    error: {
      border: '2px solid rgba(244, 67, 54, 1)',
      boxSizing: 'border-box',
      borderRadius: '8px'
    },
    container: {
      display: 'flex',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 0
      }
    },
    content: {
      margin: '2px 0px',
      padding: '6px',
      //border: '2px solid var(--bosscat-blue-600)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
        borderRadius: '8px'
      },
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    '@global': {
      '.MuiRadio-root': {
        padding: 0,
        height: 'fit-content'
      }
    }
  })
)
