import { FC } from 'react'
import { icons } from '../../../../../../../../../assets'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '../../../../../../../../UI'
import ContactCard from '../ContactCard'
import useStyles from './styles'
import RemoveContactModalProps from './types'

const ModalRemoveContact: FC<RemoveContactModalProps> = ({
  open,
  setOpen,
  onRemoveContact,
  contactToDelete,
  index,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemove = () => {
    setOpen(false)
    onRemoveContact()
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent className={classes.left}>
        <Box className={classes.titles}>
          <Typography className={classes.title}>
            Are you sure to delete this contact?
          </Typography>
        </Box>
      </DialogContent>

      <DialogContent className={classes.left}>
        <ContactCard
          contact={contactToDelete}
          index={index}
          showButtons={false}
          showWarnings={false}
          handleChange={() => {}}
        />
      </DialogContent>

      <DialogContent className={classes.submitContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.buttonClose}
              fullWidth
              onClick={handleClose}
            >
              No, cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.buttonRemove}
              fullWidth
              onClick={handleRemove}
              endIcon={<icons.Delete />}
            >
              Yes, delete
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default ModalRemoveContact
