import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionLink: {
      color: 'var(--bosscat-blue-600)',
      textDecoration: 'underline',
      paddingLeft: '8px',
      fontSize: '18px',
      lineHeight: '27px',
    },
    question: {
      color: 'var(--bosscat-black-600)',
      width: 'fit-content',
      fontSize: '18px',
      lineHeight: '27px',
    }
  })
)
