import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto min-content',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 20px !important'
      }
    },
    items: {
      height: '238px',
      [theme.breakpoints.down('sm')]: {
        height: '138px'
      }
    },
    line: {
      display: 'flex',
      flexDirection: 'column',
      margin: '62px 20px 6px 20px',
      width: '2px',
      backgroundColor: 'var(--border-color)'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    topColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginRight: '20px'
      }
    },
    tablerow: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      }
    },
    subtitle: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoBold',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '30px',
      margin: '4px 8px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '10px 8px 0px 0px',
        fontSize: '16px',
        lineHeight: '22px'
      }
    },
    comboParent: {
      minWidth: '300px',
      height: '48px',
      marginTop: '16px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    tableComponent: {
      minWidth: '300px',
      height: '100%',
      margin: '20px 0px',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12
    },
    cellHead: {
      backgroundColor: 'var(--background-color-secondary)',
      color: 'var(--head-text-color)',
      borderWidth: 0,
      fontFamily: 'Lato',
      fontSize: '16px'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0
    },
    icon: {
      width: '15px',
      height: '15px'
    },
    textAddress: {
      color: 'var(--head-text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0px 0px 0px 10px'
    },
    iconHouse: {
      width: '23px',
      height: '23px'
    },
    add: {
      width: '220px',
      height: '44px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '44px 0px 0px 50%',
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    rowImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      justifyItems: 'center'
    },
    image: {
      height: '120px',
      margin: '60px 0px 40px 0px',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
        margin: '60px 0px 0px 50px'
      }
    },
    rowLeft: {
      display: 'flex',
      flexDirection: 'row',
      justifySelf: 'left',
      alignSelf: 'left'
    },
    columnItems: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '230px'
    },
    estimate: {
      color: 'var(--black-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      margin: '15px 0px 0px 0px'
    },
    estimates: {
      color: 'var(--black-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      margin: '0px 0px 10px 0px'
    },
    title1: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '24px',
      margin: '5px 8px 5px 0px'
    },
    title2: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      fontSize: '24px',
      margin: '5px 0px'
    },
    button: {
      width: '56px',
      height: '56px',
      margin: '5px 40px',
      borderRadius: '32px',
      backgroundColor: 'var(--bosscat-blue-600)',
      display: 'flex',
      alignSelf: 'flex-end'
    },
    plus: {
      width: '12px',
      height: '12px',
      margin: '0px 6px 0px 0px',
      color: 'white'
    },
    containerTop: {
      display: 'flex',
      flexDirection: 'column'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        marginBottom: '80px'
      }
    },
    alert: {
      position: 'absolute',
      zIndex: 200,
      width: '90%',
      height: '100%',
      backgroundColor: 'white',
      margin: '0px 10px 0px 0px'
    },
    hidde: {
      height: '0px',
      maxMeight: '0px'
    },
    show: {
      height: '100px',
      maxMeight: '100px',
      backgroundColor: 'white'
    }
  })
)
