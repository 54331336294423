import axios from '../axios'
import { ApiController } from './types'

const base = '/territories'

const apiController: ApiController = {
  getTerritories: async (serviceable) => {
    let params = {}
    if (serviceable) {
      params = {
        serviceable
      }
    }
    return await axios
      .get(base, {
        params
      })
      .then(response => response.data)
  },

  getTerritoryByZipCode: async (zipCode, source) =>
    await axios
      .get(`${base}/zipCode/${zipCode}`, {
        params: {
          source
        }
      })
      .then(response => response.data),

  getTerritoryRepairItems: async (territoryId, search) =>
    await axios
      .get(`${base}/${territoryId}/repairItems${search ? `?search=${search}` : ''}`)
      .then(response => response.data)

}

export default apiController
