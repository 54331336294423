import { FC, useEffect, useRef, useState } from 'react'
import { icons, photoDefault } from '../../../../../assets'
import { ImagePickerProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'
import { CircularProgress, Typography } from '@material-ui/core'

/**
 *
 * This component allows to upload or delete
 * one image at the time
 *
 * (Multiple images selection with `ImagesSelector`)
 *
 * @param `handleDeleteFile` callback to handle image's deletion
 * @param `handleUploadFile` callback to handle image's upload
 *
 * @returns JSX.Element
 *
 * @example
 * <ImagePicker
 *   handleUploadFile={() => null}
 *   handleDeleteFile={() => null}
 * />
 *
 */
const ImagePicker: FC<ImagePickerProps> = ({
  handleDeleteFile = () => null,
  handleUploadFile = () => null,
  imageUrl = null,
  disabled = false,
  multiple,
  canDelete = true,
  allowedFiles = ['image/*'],
}) => {
  const classes = useStyles()
  const className = useClassName()
  const inputRef = useRef<any>(null)

  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleClick = () => {
    if (!imageUrl && !disabled) {
      inputRef.current.click()
    }
  }

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0] && !disabled) {
      handleUploadFile(event.target.files)
    }
  }

  const handleDelete = (event: any) => {
    if (!disabled) {
      event.target.files = undefined
      handleDeleteFile()
    }
  }

  const handleError = () => {
    if (attempts > 0) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <div
      aria-label='image-picker'
      className={className(classes.imagePicker, classes.marginRight, 'imagePicker')}
      style={!imageUrl && !disabled ? { cursor: 'pointer' } : {}}
      onClick={handleClick}
    >
      {!imageUrl
        ? (
          <>
            <input
              data-testid='file-uploader'
              type='file'
              accept={allowedFiles.join(', ')}
              onChange={handleUpload}
              ref={inputRef}
              disabled={disabled}
              className={classes.hiddenInput}
              multiple={multiple}
            />
            <icons.AddPhotoAlternate data-testid='add-photo-alternate' className={classes.icon} />
          </>
        )
        : (
          <>
            {loading
              ? <CircularProgress color='primary' size={24} className={`${classes.image} imagePicker--image`} />
              : error
                ? <img data-testid='photo-default' alt='' src={photoDefault} />
                : <img data-testid='photo-real' src={imageUrl} onError={handleError} className={`${classes.image} imagePicker--image`} alt='' />}
            {!disabled && canDelete && (
              <icons.CloseRounded
                data-testid="close-rounded"
                className={classes.deleteButton}
                onClick={handleDelete}
              />
            )}
          </>
        )}
    </div>
  )
}

export default ImagePicker
