import { Order } from 'ducks/types'

/** TYPES **/
export const orderTypes = {
  FETCH_ORDER: 'FETCH_ORDER',
  SET_ORDER: 'SET_ORDER'
}

/** ACTIONS **/
export interface FetchOrderAction {
  type: typeof orderTypes.FETCH_ORDER
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetOrderAction {
  type: typeof orderTypes.SET_ORDER
  payload: Order | null
}

export type OrderActions =
  | FetchOrderAction
  | SetOrderAction

/** REDUCER **/
export type OrderType = Order | null
