import { FeedbackActions, feedbackTypes, Feedback } from './types'

const actions = {
  sendFeedback: (
    payload: Feedback,
    callback?: (succ: boolean) => void
  ): FeedbackActions => ({
    type: feedbackTypes.SEND_FEEDBACK,
    payload,
    callback
  })
}

export default actions
