/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouteLink } from 'react-router-dom'
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'

import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { getNewOrderMaintenanceValue } from 'ducks/newOrderMaintenance/selectors'
import { territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'
import { Address } from 'ducks/types'
import { useStepperContext } from 'hooks/useStepperContext'
import {
  icons,
  obAgent,
  obHomeowner,
  obInstitutionalClient,
} from '../../../../../../assets'
import {
  newOrderMaintenanceActions,
  subscriptionsActions,
  territoriesActions,
  userActions
} from '../../../../../../ducks/actions'
import { getNewEstimateValue, getTerritory, getUser, getUserPrimaryEmail, isAuthorized } from '../../../../../../ducks/selectors'
import {
  CONTACT_ROLE,
  USER_TYPE,
  agentOptions,
  history,
  institutionalOptions,
  isEmpty,
  validateEmail
} from '../../../../../../helpers'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Radio,
  RadioGroup,
  SelectAddress,
  TextFieldLabel,
  Typography
} from '../../../../../UI'
import useStyles from './styles'
import { WorkProps } from './types'
import { toast } from 'react-toastify'

export const roleOptions = [
  {
    title: `I'm a Homeowner/Homebuyer`,
    icon: obHomeowner,
    id: CONTACT_ROLE.HOMEOWNER,
  },
  {
    title: `I represent a Homeowner/Homebuyer`,
    icon: obAgent,
    id: CONTACT_ROLE.LISTING_AGENT,
  },
  {
    title: `I'm an Institutional Investor`,
    icon: obInstitutionalClient,
    id: CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  },
]

const Work: FC<WorkProps> = ({
  //  setShowRegister,
  // setShowLogin,
  loading: upperLoading
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const query = history.useQuery()

  const fromUrlParam = query.get('from') || ''

  const {
    showWarnings,
  } = useStepperContext()

  const savedAddress = useSelector(getNewOrderMaintenanceValue('propertyAddress'))
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const clientType = useSelector(getNewOrderMaintenanceValue('clientType'))
  const isInstitutional = clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR
  const authorized = useSelector(isAuthorized)
  const user = useSelector(getUser)

  const [firstName, setFirstName] = useState(mainContact?.firstName ?? '')
  const [lastName, setLastName] = useState(mainContact?.lastName ?? '')
  const [address, setAddress] = useState(!isEmpty(savedAddress?.line_1) ? savedAddress : !isEmpty(user?.address?.latitude?.toString()) ? user?.address : null)
  const [email, setEmail] = useState(mainContact?.email ?? '')
  const [loading, setLoading] = useState(false)
  const [emailIsVIP, setEmailIsVIP] = useState(false)
  const isAgent = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)

  const [showProfessionalType, setShowProfessionalType] = useState(!isEmpty(mainContact?.institutionalRole))

  const professionalTypeOptions = isInstitutional ? institutionalOptions : agentOptions

  const userNar = useSelector(getNewEstimateValue('isNar'))
  const queryNar = query.get('nar')
  const isNar = userNar || queryNar === 'true'
  const userMail = useSelector(getUserPrimaryEmail)

  const affiliate = query.get('affiliate')

  const queryParams = query.toString()

  const territory = useSelector(getTerritory())

  const cityIsAvailableForMaintenance = useSelector(
    territoryIsAvailableForMaintenance(territory?.id)
  )


  const addressIsValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    !isEmpty(address.latitude?.toString()) &&
    !isEmpty(address.longitude?.toString())


  const validate = () => {
    if (authorized) {
      return addressIsValid && cityIsAvailableForMaintenance
    } else {
      return !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(email) && validateEmail(email) && !isEmpty(clientType) && (mainContact.clientType !== CONTACT_ROLE.LISTING_AGENT ? addressIsValid : true) && (showProfessionalType ? professionalTypeOptions.some(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType)) : true) && (mainContact.clientType === CONTACT_ROLE.LISTING_AGENT ? true : cityIsAvailableForMaintenance)
    }
  }

  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value },
      })
    )
  }


  const handleSelect = (idx: any): void => {
    updateMainContact('role', idx)
    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'clientType',
        value: idx,
      })
    )
    if (idx === CONTACT_ROLE.LISTING_AGENT) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: "propertyAddress",
        value: {}
      }))
    }
    updateMainContact('clientType', idx)
  }

  const fetchZipCodeServices = useCallback((zipCode: string) => {
    dispatch(
      territoriesActions.fetchTerritory(zipCode, (succ, terr) => {
        dispatch(
          newOrderMaintenanceActions.setNewOrderMaintenanceValue({
            attr: 'serviceable',
            value: terr?.serviceable || false
          })
        )
      })
    )
  }, [])

  const handleChangeAddress = (newAddress: Address) => {
    if (!isEmpty(newAddress.line_1)) {
      setAddress(newAddress)

      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'propertyAddress',
          value: newAddress
        })
      )
    }
    if (newAddress.zipCode) {
      if (newAddress?.zipCode !== address?.zipCode) {
        fetchZipCodeServices(newAddress.zipCode)
      }
    }
  }

  const saveValuesInStoreAndContinue = () => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
      attr: "homeowner",
      value: {
        firstName,
        lastName,
        phone: "",
        email: [{ id: null, emailType: "PRIMARY", email, name: null, description: null }]
      }
    }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
      attr: "purchaser",
      value: {
        firstName,
        lastName,
        phone: "",
        email: [{ id: null, emailType: "PRIMARY", email, name: null, description: null }],
        address: address,
        affiliate: affiliate || ""

      }
    }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
      attr: 'giftBox',
      value: {
        shippingName: `${firstName} ${lastName}`,
        shippingAddress: address,
        shippingPhone: "",
      }
    }))

    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'step',
        value: 0,
      })
    )
    setLoading(false)
  }

  const handleNext = () => {
    if (validate()) {
      setLoading(true)

      dispatch(
        userActions.fetchUserHead(
          {
            email: email || mainContact?.email || "",
            userType: 'Client',
          },
          (succ) => {
            /*  dispatch(
               newEstimateActions.setNewEstimateValue({
                 attr: 'mainContact',
                 value: { ...mainContact, firstName, email, address, clientType },
               })
             ) */
            if (succ && !authorized) {
              push('login?redirect=new-maintenance')
            } else {
              if ([CONTACT_ROLE.HOMEOWNER, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)) {

                return dispatch(subscriptionsActions.checkUserVIP({ email }, (succ, isVip) => {
                  if (succ) {
                    if (isVip) {
                      setEmailIsVIP(true)
                      setLoading(false)
                    } else {
                      setEmailIsVIP(false)
                      if ([CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType) && !showProfessionalType && !authorized) {
                        setShowProfessionalType(true)
                        setLoading(false)
                      } else {
                        setLoading(true)
                        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                          attr: "gift",
                          value: false
                        }))
                        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                          attr: "giftBox",
                          value: false
                        }))
                        saveValuesInStoreAndContinue()
                      }
                    }
                  } else {
                    toast.error('The user already belongs to the maintenance program')
                    setLoading(false)
                  }
                }))
              }
              if ([CONTACT_ROLE.LISTING_AGENT].includes(clientType) && !showProfessionalType && !authorized) {
                setShowProfessionalType(true)
                setLoading(false)
              } else {
                setLoading(true)
                if (CONTACT_ROLE.LISTING_AGENT === clientType) {
                  dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                    attr: "propertyAddress",
                    value: {}
                  }))
                }
                saveValuesInStoreAndContinue()
              }
            }
          }
        )
      )
    }
  }

  useEffect(() => {
    if (isNar || !isEmpty(affiliate)) {
      handleSelect(CONTACT_ROLE.LISTING_AGENT)
    }
  }, [])

  useEffect(() => {
    // If user is logged in we replace the mainContact with the user info
    if (user.id) {
      isEmpty(savedAddress?.line_1) && fetchZipCodeServices(user.address.zipCode)
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'mainContact',
        value: {
          ...mainContact,
          email: userMail,
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user.phone,
          sendEstimate: true
        }
      }))
      !clientType && dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'clientType',
        value: user.clientType === USER_TYPE.HOMEOWNER
          ? user.clientType
          : CONTACT_ROLE.LISTING_AGENT
      }))
      if (fromUrlParam !== 'property' && isEmpty(savedAddress?.line_1)) {
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'propertyAddress',
          value: user?.address?.latitude && user?.address?.latitude ? user?.address : {}
        }))
      }
    }
  }, [user])

  const handleChangeProfessionalType = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = professionalTypeOptions.find(option => option.label === e.target.value)
    updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', selectedOption?.key)

    //dispatch(userActions.setUserValue({ attr: isInstitutional ? 'institutionalRole' : 'clientType', value: selectedOption?.key }))
  }

  /* const updateUser = (attr: keyof UserType, newValue: any) => {
    dispatch(userActions.setUserValue({
      attr,
      value: newValue
    }))


    serviceable && addressIsValid
  } */

  useEffect(() => {
    if (clientType) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "activeStepProgress", value: 1 }))
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "totalStepProgress", value: isAgent ? 5 : 3 }))
    }
  }, [clientType])

  useEffect(() => {
    if (clientType) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "clientType", value: "" }))
    }
  }, [])

  return (
    <>
      {!showProfessionalType ?
        <Box>
          <Box aria-label='estimate-account-work' >
            {!authorized &&
              <div style={authorized ? {} : { paddingInline: "2px" }}>
                <Typography className={classes.title}>
                  It’s nice to meet you!
                </Typography>
                <Box className={classes.subtitleContainer}>
                  <Typography className={classes.nextArtBold}>
                    Have we already met?
                  </Typography>
                  <RouteLink className={classes.loginLink} to={`/login?redirect=new-maintenance${queryParams ? `&${queryParams}` : ''}`}>
                    Log in to your account here
                  </RouteLink>
                  <ArrowOutwardIcon className={classes.iconArrow} />
                </Box>
              </div>
            }
          </Box>

          <Grid container spacing={4} direction="column" alignItems='flex-end'>
            {!authorized &&
              <Grid item container spacing={2} style={{ padding: "16px 20px" }} >
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="First Name"
                    type="text"
                    value={firstName}
                    error={showWarnings && isEmpty(firstName)}
                    onChange={(event) => {
                      setFirstName(event.target.value)
                      updateMainContact('firstName', event.target.value)
                      // updateUser('firstName', event.target.value)
                    }}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Last Name"
                    type="text"
                    className={classes.inputLabel}
                    value={lastName}
                    error={showWarnings && isEmpty(lastName)}
                    onChange={(event) => {
                      setLastName(event.target.value)
                      updateMainContact('lastName', event.target.value)
                      //updateUser('lastName', event.target.value)
                    }}
                    placeholder="Last Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Email"
                    type="email"
                    value={email}
                    className={classes.inputLabel}
                    error={showWarnings && (isEmpty(email) || !validateEmail(email))}
                    onChange={(event) => {
                      setEmail(event.target.value)
                      updateMainContact('email', event.target.value)
                      // updateUser('email', event.target.value)
                    }}
                    placeholder="Email"
                  />
                </Grid>
              </Grid>
            }
            {!authorized &&
              <Grid item container spacing={2} style={{ padding: "0 27px" }}>
                <Typography className={classes.roleTitle}>
                  Which option best describes you?
                </Typography>
                <Grid item container className={classes.options}>
                  {roleOptions.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      className={clsx(
                        classes.option,
                        clientType === item.id && classes.selected
                      )}
                      onClick={() => handleSelect(item.id)}
                    >
                      <Box className={classes.content}>
                        <Box className={classes.details}>
                          <Typography variant="body1">
                            {item.title}
                          </Typography>
                        </Box>
                      </Box>
                      <Radio
                        color="primary"
                        disableRipple
                        checked={clientType === item.id}
                        style={{ backgroundColor: 'transparent' }}
                      // classes={{ checked: classes['Mui-checked'] }}
                      />
                    </Grid>
                  ))}

                </Grid>
              </Grid>}
            {[CONTACT_ROLE.HOMEOWNER, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType || user.clientType) &&
              <Grid item container spacing={2}>
                <Box className={classes.selectAddressContainer} aria-label='steps-location'>
                  {authorized &&
                    <>
                      <Typography className={classes.title}>
                        Let's get started, {user.firstName}
                      </Typography>
                    </>
                  }
                  <Grid container spacing={2} justifyContent='space-between'>
                    <Grid container item xs={12} xl={12} >
                      <Grid item xs={12}>
                        <SelectAddress
                          showUnitNumberBesideAddress
                          //showVertical
                          xl={12}
                          showWarnings={!addressIsValid && !isEmpty(address) && address?.line_1 !== ''}
                          onChange={handleChangeAddress}
                          title={
                            <Typography variant='subtitle2' className={classes.titleLabel}>
                              Address for the Maintenance Service
                            </Typography>
                          }
                          placeholder='Property address'
                          hasCallback
                          savedAddress={savedAddress}
                          /*            errorMessage={
                                       <Box className={classes.addressError}>
                                         <Typography>Hey{mainContact?.firstName && `, ${mainContact.firstName}`}!</Typography>
                                         <Typography className={classes.addressErrorSubtitle}>This is an invalid address. Let’s modify the address to go on with your request.</Typography>
                                       </Box>
                                     } */
                          /*  showUnit={false} */
                          showMap={false}
                          customClass={classes.selectAddress}
                          unitNumberClassName={classes.unitNumber}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
          </Grid>
        </Box>
        :
        <Box aria-label='estimate-account-work' >
          <Typography className={classes.title}>
            tell us about your professional type
          </Typography>
          <Grid item spacing={2} className={classes.professionalTypeOptions}>
            <RadioGroup
              value={professionalTypeOptions.find(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType))?.label}
              onChange={handleChangeProfessionalType}
            //className={classes.radioContainer}
            >
              {professionalTypeOptions.map((option, index) => {
                const selectedOption = professionalTypeOptions.find(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType))
                return <FormRadioButton
                  style={selectedOption?.key === option.key ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  key={index}
                  value={option.label}
                  control={<Radio color='primary' />}
                  label={<Typography variant='caption'>{option?.label}</Typography>}
                //className={classes.radio}
                />
              })
              }
            </RadioGroup>

          </Grid>
        </Box>
      }
      <Grid container className={classes.buttons} style={{ justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          className={`${classes.buttonBack} ${showProfessionalType ? `${classes.buttonBackMargin}` : ''}`}
          startIcon={<icons.ArrowBack className={classes.icon} />}
          onClick={() => {
            if (showProfessionalType) {
              updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', null)
              setShowProfessionalType(false)
            } else {
              dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'step',
                value: -2,
              }))
            }
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          className={`${classes.button} ${showProfessionalType ? `${classes.buttonBackMargin}` : ''}`}
          disabled={!validate()}
          endIcon={!loading ? <icons.ArrowForward className={classes.icon} /> : <></>}
          onClick={handleNext}
        >
          {(loading || upperLoading)
            ? (
              <CircularProgress
                className={classes.spinner}
                color='secondary'
                size={30}
              />
            )
            : (
              'Go'
            )}
        </Button>
      </Grid>
    </>
  )
}

export default Work
