import {
  Grid,
  Button,
  Typography,
} from '../../../UI'
import {
  contactSupport,
  deposit,
  easyBooking,
  icons,
  inHomeConsultation,
  logo,
  onlineEstimate,
  priceMatch,
  qualityPros,
  warranty,
} from '../../../../assets'
import useStyles from './styles'
import { useHistory } from 'react-router-dom'

const Widget = () => {
  const classes = useStyles()

  const history = useHistory();

    const handleNextClick = () => {
        history.push('/estimate'); 
    };



  return (
    <Grid className={classes.root}>
      <Typography variant="h3" className={`${classes.title} ${classes.nextHeavy}`}>welcome to <img className={classes.logoimg} src={logo} alt="logo" /></Typography>
      <Grid container className={`${classes.container} ${classes.top}`}>
        <Grid item xs={12}>
          <Typography className={classes.nextHeavyHardware}>Complete any home improvement effortlessly</Typography>
          <Typography className={classes.nextHeavyHardware}>with BOSSCAT's streamlined repair and renovation solutions</Typography>
          </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.top}>
        <Grid item xs={12} sm={12} md={4} className={classes.container} >
          <img className={classes.images} src={onlineEstimate} alt="logo" />
          <Typography className={classes.subNextHeavy}>online estimates</Typography>
          <Typography className={classes.subText}>Get an estimate within a business day, simply by uploading your inspection report or repair list.</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.container}>
          <img className={classes.images} src={easyBooking} alt="logo" />
          <Typography className={classes.subNextHeavy}>easy booking</Typography>
          <Typography className={classes.subText}>Select the services you want, select your start date, and our Pro team will get to work!</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={`${classes.container} ${classes.top}`}>
          <img className={classes.images} src={contactSupport} alt="logo" />
          <Typography className={classes.subNextHeavy}>dedicated support</Typography>
          <Typography className={classes.subText}>Your dedicated Success Manager will coordinate the scheduling and keep you updated from start to finish.</Typography>
        </Grid>
      </Grid>
      <Grid container className={`${classes.container} ${classes.top}`}>
        <Grid item xs={12} sm={8}>
          <Typography className={classes.nextHeavyHardware2}>REPAIRS AND RENOVATIONS MADE EASY</Typography>
          </Grid>
      </Grid>
      <Grid container className={`${classes.container2} ${classes.top} ${classes.bottom}`}>
        <Grid item xs={6} sm={4} className={classes.item} >
          <img className={classes.images} src={inHomeConsultation} alt="logo" />
          <Typography className={classes.subNextHeavy}>pay at closing</Typography>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.item} >
          <img className={classes.images} src={warranty} alt="logo" />
          <Typography className={classes.subNextHeavy}>1 year warranty</Typography>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.item} >
          <img className={classes.images} src={deposit} alt="logo" />
          <Typography className={classes.subNextHeavy}>No deposit</Typography>
          <Typography className={classes.subText2}>On projects under $10,000</Typography>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.item} >
          <img className={classes.images} src={priceMatch} alt="logo" />
          <Typography className={classes.subNextHeavy}>price match</Typography>
        </Grid>
        <Grid item xs={6} sm={4} className={`${classes.item} ${classes.marginItem}`} >
          <img className={classes.images} src={qualityPros} alt="logo" />
          <Typography className={classes.subNextHeavy}>licensed and insured</Typography>
        </Grid>
        
      </Grid>
      <Grid container className={`${classes.container} ${classes.top}`}>
        <Grid item xs={12} sm={4} className={classes.container}>
        <Button
                variant='contained'
                className={classes.button}
                endIcon={<icons.ArrowForward />}
                onClick={() => handleNextClick()} 
              >
                Let's Get Started!
              </Button>
        </Grid>
      </Grid>


    </Grid>
  )
}
export default Widget