import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'grid',
      margin: '0 auto',
      marginTop: '2rem',
      gap: theme.spacing(2),
      width: '100%',
      minHeight: '50vh',
      /* maxWidth: theme.breakpoints.values.lg, */
    },
    
  })
)
