import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'Lato',
      fontWeight: 900,
      paddingTop: '10px',
      paddingBottom: '8px'
    }
  })
)
