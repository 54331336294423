import { Autocomplete, Box, Checkbox, FormControlLabel, Link, MultilineText, SelectAddress, TextFieldLabel, Typography } from 'components/UI'
import { estimatesActions, territoriesActions } from 'ducks/actions'
import { Address } from 'ducks/types'
import { FORM_TYPE, PHONE_MASK_INPUT, canadaStates, getSelectedAsOption, getStatesOptionList, getStatesOptionListCanada, isEmpty, states, validateEmail } from 'helpers/index'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import useStyles from './styles'

interface FormData {
    firstName: string
    lastName: string
    email: string
    phone: string
    address: Address
    city: string
    zipCode: string
    state: string
    promoCode?: string
    serviceable: boolean
    describeHome: string
}

type Props = {
    title?: string
    onSubmit: (data: FormData) => void,
    titleClasses?: string
}

const ContactForm = ({ onSubmit, title, titleClasses }: Props) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState()
    const [fullAddress, setFullAddress] = useState<Address>()
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [state, setState] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [serviceable, setServiceable] = useState(false)
    const [country, setCountry] = useState('United States');
    const [describeHome, setDescribeHome] = useState('')
    const [acknowledge, setAcknowledge] = useState(true)
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        address: false,
        city: false,
        zipCode: false,
        state: false,
    })

    const dispatch = useDispatch()

    const classes = useStyles()

    const validateAddress: boolean = !isEmpty(fullAddress?.line_1) &&
        fullAddress?.line_1 !== ' ' &&
        (!!fullAddress?.zipCode || !!zipCode) ? true : false

    const handleSubmit = async () => {
        if (!firstName || !lastName || !email || !validateEmail(email) || !phone || !validateAddress) {
            if (!firstName) { setErrors((prevErrors) => ({ ...prevErrors, firstName: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, firstName: false })) }
            if (!lastName) { setErrors((prevErrors) => ({ ...prevErrors, lastName: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, lastName: false })) }
            if (!email || !validateEmail(email)) {
                setErrors((prevErrors) => ({ ...prevErrors, email: true }))
            } else { setErrors((prevErrors) => ({ ...prevErrors, email: false })) }
            if (!phone) { setErrors((prevErrors) => ({ ...prevErrors, phone: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, phone: false })) }
            if (!validateAddress) { setErrors((prevErrors) => ({ ...prevErrors, address: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, address: false })) }
            if (!city) { setErrors((prevErrors) => ({ ...prevErrors, city: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, city: false })) }
            if (!zipCode) { setErrors((prevErrors) => ({ ...prevErrors, zipCode: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, zipCode: false })) }
            if (!state) { setErrors((prevErrors) => ({ ...prevErrors, state: true })) } else { setErrors((prevErrors) => ({ ...prevErrors, state: false })) }
            return
        }

        const mergedAddress = {
            ...fullAddress,
            latitude: fullAddress?.latitude,
            longitude: fullAddress?.longitude,
            city,
            zipCode,
            state,
            country,
        }
        setErrors({
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            address: false,
            city: false,
            zipCode: false,
            state: false,
        })
        onSubmit({
            firstName,
            lastName,
            email,
            phone,
            address: mergedAddress as Address,
            city,
            zipCode,
            state,
            promoCode,
            serviceable,
            describeHome
        })
    }

    const fetchZipCodeServices = useCallback((zipCode: string) => {
        dispatch(
            territoriesActions.fetchTerritory(zipCode, (succ, terr) => {
                setServiceable(terr?.serviceable || false)
                if (succ) {
                    dispatch(
                        estimatesActions.fetchEstimatesServices(
                            {
                                formType: FORM_TYPE.CLIENT_INSPECTION_REPORT,
                                serviceable: terr?.serviceable || false,
                            },
                            () => {
                                // setApiLoading(false)
                            }
                        )
                    )
                }
            })
        )
    }, [])

    const handleAddressChange = (newAddress: Address) => {
        if (newAddress.zipCode) {
            setFullAddress(newAddress)
            setCity(newAddress.city)
            setZipCode(newAddress.zipCode)
            setState(newAddress.state)
            setCountry(newAddress.country)
            fetchZipCodeServices(newAddress.zipCode)
        }
    }

    useEffect(() => {
        if (zipCode.length > 3) {
            fetchZipCodeServices(zipCode)
        }
    }, [zipCode])

    return (
        <div className={classes.container}>
            {title && <h1 className={`${classes.title} ${titleClasses}`}>{title}</h1>}
            <div className={classes.root}>
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.firstName ? classes.inputError : ""}`} label="FIRST NAME" placeholder='Enter your first name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.lastName ? classes.inputError : ""}`} label="LAST NAME" placeholder='Enter your last name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.email ? classes.inputError : ""}`} label="EMAIL" placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <TextFieldLabel labelClasses={classes.inputLabel} className={`${classes.input} ${errors.phone ? classes.inputError : ""}`} label="PHONE" placeholder='Enter your phone' type='tel' mask={PHONE_MASK_INPUT} value={phone} onChange={(e) => setPhone(e.target.value)} />
                <SelectAddress
                    containerClass={classes.addressContainer}
                    savedAddress={address}
                    showWarnings={errors.address}
                    onChange={handleAddressChange}
                    customClass={classes.selectAddress}
                    onAddressTextChange={(text: string) => {
                        setFullAddress({ line_1: text, city: '', state: '', zipCode: '', country: '', line_2: '', county: '', latitude: 0, longitude: 0 })
                    }}
                    showUnitNumberBesideAddress={false}
                    showUnit={false}
                    placeholder="Enter your address"
                    showVertical={isMobile}
                    title={<Typography variant="h6" className={classes.selectAddressTitle}>ADDRESS</Typography>}
                    scrollOnFocus
                    showMap={false}
                    resetOnBlur={false}
                    hasCallback
                />
                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="CITY" placeholder='Enter your city' value={city} onChange={(e) => setCity(e.target.value)} />
                <Box style={{ width: isMobile ? "100%" : "48%" }}>
                    <Autocomplete
                        label='STATE'
                        onChange={(value: any) => setState(value.key)}
                        value={!isEmpty(states) && !isEmpty(state)
                            ? getSelectedAsOption(states.find(opt => opt?.abbreviation === state) || canadaStates.find(opt => opt?.abbreviation === state))
                            : null}
                        error={errors.state && !state}
                        options={country === 'Canada' ? getStatesOptionListCanada() : getStatesOptionList()}
                        placeholder='Enter your state'
                        className={classes.inputAuto}
                        labelClassName={classes.inputAutocomplete}
                    />
                </Box>
                {/* <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="STATE" placeholder='Enter your state' value={state} onChange={(e) => setState(e.target.value)} /> */}
                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label="ZIP CODE" placeholder='Enter your zip code' value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                <Box style={{ width: "100%" }}>
                    <Typography className={classes.textAreaLabel}>DESCRIBE YOUR PROJECT</Typography>
                    <MultilineText
                        value={describeHome}
                        onChange={(event: any) => {
                            setDescribeHome(event.target.value)
                        }}
                        placeholder="Enter your project description"
                        className={classes.textArea}
                    />
                </Box>
                <TextFieldLabel labelClasses={classes.inputLabel} className={classes.input} label={
                    <Typography variant='body1' className={classes.inputLabel}>
                        PROMO CODE <span style={{ fontFamily: 'Lato' }}> (OPTIONAL)</span>
                    </Typography>
                } placeholder='Enter your promo code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                <div className={`${classes.button} ${!acknowledge ? classes.disabled : ''}`} onClick={handleSubmit}>
                    <p className={classes.buttonText}>Request a Quote</p>
                </div>
                <p className={classes.disclaimer}>
                    By submitting my name and information above, I consent to receive telephone calls and/or text/SMS messages from BOSSCAT communicating offers and information related to BOSSCAT's products, services, sales and promotions, at the telephone number I enter above, even if my number is already on a do not call list. I represent that the number I provide is my number. I acknowledge that I am not required to provide consent as a condition of purchasing products or services. I also understand that standard messaging, data rates, or cellular fees may apply. I understand that if I no longer desire to receive communications, I can reply “STOP” to any text/SMS from BOSSCAT in order to opt out. I may Opt-Out at any time, without penalty or charge. More detail regarding this Policy may be found at www.bosscathome.com
                </p>
            </div>
        </div>
    )
}

export default ContactForm
