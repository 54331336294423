import { Theme as ThemeMUI, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      // padding: '8px 0'
    },
    text: {
      padding: '0',
      fontFamily: 'NextArtHeavy',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: '.5rem'
      }
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '280px',
      textAlign: 'left',
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      padding: '8px',
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600)',
        transition: '300ms all',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600)',
          }
        }
      },
      '@global': {
        'img': {
          [theme.breakpoints.down('sm')]: {
            gridArea: 'img',
            width: 55,
            marginRight: '.5rem'
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'grid',
        gridTemplateAreas: ' "img title button" "img subtitle button"'
      }
    },
    radioButton: {
      position: 'absolute',
      right: '8px',
      padding: 0,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: 'fit-content',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      },
      [theme.breakpoints.down('sm')]: {
        gridArea: 'button'
      }
    },
    title: {
      color: '#0B060F',
      fontSize: 18,
      marginBottom: '.5rem',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'title',
        marginBottom: '0',
        marginRight: '2rem',
      }
    },
    subtitle: {
      color: '#6D6A6F',
      [theme.breakpoints.down('sm')]: {
        gridArea: 'subtitle'
      }
    },
    question: {
      fontSize: '16px',
      fontFamily: 'NextArtBold',
      lineHeight: '30px',
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    accordion: {
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      borderRadius: 8,
      border: '2px solid #F5F6F7',
      '&:before': {
        content: 'none !important'
      }
    },
    button: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid',
      padding: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px'
      }
    },
    optionImg:{
      width: 120,
      [theme.breakpoints.down('sm')]: {
        width: '55px',
      }
    }
  })
)
