import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import useStyles from './styles'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'ducks/selectors'
import { Item } from 'ducks/types'
import { icons } from 'assets'
import { ImagesSelector, MultilineText } from 'components/UI'
import { estimateActions, filesActions } from 'ducks/actions'
import { isMobile } from 'react-device-detect'
import { FILE_TYPE } from 'helpers/constants'
import { imageMimeTypes } from 'helpers/files'
import { UpdateRequest } from 'api/types'
import { ParseFilesUrl } from 'helpers/filesUrlParser'
import { FILES_URL } from 'components/pages/User/Register/_test_/constants'

export interface AdditionalItemsRequestedProps {
  punchlistPlanner?: boolean
  serviceable?: boolean
  handleShowAlert?: (item: Item) => void
  handleRemove?: (estimateId: string, itemId: string, removed: boolean) => void
  estimateNotApproved?: boolean
}

const AdditionalItemsRequested: FC<AdditionalItemsRequestedProps> = ({
  estimateNotApproved,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(true)
  const estimate = useSelector(getEstimate())
  const [newItem, setNewItem] = useState(
    estimate?.properties?.clientRequestNotes || ''
  )
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUploadImages, setLoadingUploadImages] = useState(false)
  const images = estimate?.properties?.files?.filter(
    (file) => file.fileType === FILE_TYPE.MORE_ITEMS_REQUEST_IMAGE
  )

  const handleClick = () => {
    if (expanded) {
      setLoadingSave(true)
      handleSubmit(false)
    } else {
      setExpanded(true)
    }
  }

  const handleSubmit = (remove: boolean) => {
    dispatch(
      estimateActions.requestMoreItems(
        remove ? '' : newItem,
        (succ: boolean) => {
          if (remove) {
            const request: UpdateRequest =
              images?.map((image) => {
                const index = estimate?.properties?.files?.findIndex(
                  (file) => file.id === image.id
                )
                return {
                  op: 'remove',
                  path: `/properties/files/${index}`,
                }
              }) || []

            dispatch(
              estimateActions.updateEstimateByPath(
                request.reverse(),
                (succ) => {
                  setLoadingDelete(false)
                  setLoadingSave(false)
                  setLoadingDelete(false)
                }
              )
            )
          } else {
            setLoadingDelete(false)
            setLoadingSave(false)
            setLoadingUploadImages(false)
          }
        }
      )
    )
  }

  const uploadImages = (images?: File[]) => {
    setLoadingUploadImages(true)
    dispatch(
      filesActions.uploadFiles(images as File[], (uploadSucc, fileNameList) => {
        if (
          uploadSucc &&
          fileNameList &&
          fileNameList.length === images?.length
        ) {
          const imagesReq: UpdateRequest = images?.map((_image, index) => {
            const fileName = fileNameList[index]
            return {
              op: 'add',
              path: '/properties/files/-',
              value: {
                name: fileName,
                fileUrl: ParseFilesUrl({
                  baseUrl: FILES_URL,
                  avatar: fileName ?? '',
                }),
                fileType: FILE_TYPE.MORE_ITEMS_REQUEST_IMAGE,
                description: 'Request More Items',
              },
            }
          })

          dispatch(
            estimateActions.updateEstimateByPath(imagesReq, (succ) => {
              setLoadingUploadImages(false)
              // succ && handleClose()
            })
          )
        } else {
          setLoadingUploadImages(false)
        }
      })
    )
  }

  return (
    <Box className={`${classes.root} ${expanded ? classes.rootExpanded : ''}`}>
      <Grid item className={classes.header}>
        <Typography className={classes.title}>
          Additional items requested
        </Typography>
        <Typography className={classes.subtitle}>
          Our team will review your request and respond within one business day.
        </Typography>
      </Grid>
      {estimateNotApproved && (
        <Grid container className={classes.buttonsContainer}>
          <Grid item xs={6}>
            <Button
              onClick={handleClick}
              className={classes.buttonEdit}
              startIcon={
                loadingSave ? <></> : expanded ? <icons.Save /> : <icons.Edit />
              }
            >
              {loadingSave ? (
                <CircularProgress className={classes.loading} size={20} />
              ) : expanded ? (
                'Save'
              ) : (
                'Edit'
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                handleSubmit(true)
                setLoadingDelete(true)
              }}
              className={classes.buttonDelete}
              startIcon={<icons.Delete />}
            >
              {loadingDelete ? <CircularProgress size={20} /> : 'Delete'}
            </Button>
          </Grid>
        </Grid>
      )}

      <Box hidden={!expanded} className={classes.container}>
        <MultilineText
          value={newItem}
          onChange={(event: any) => {
            setNewItem(event.target.value)
          }}
          className={classes.itemsInput}
          disabled={loadingSave || loadingDelete || !estimateNotApproved}
        />
      </Box>
      <Box hidden={!expanded} className={classes.images}>
        <Typography className={classes.bold}>Images</Typography>
        {loadingUploadImages ? (
          <Box
            style={{
              minHeight: 250,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ImagesSelector
            imageFiles={images}
            // images={imagesReq || []}
            submitAction={(images: any) => {
              uploadImages(images)
            }}
            multiple
            disabled={false}
            allowedFiles={[...imageMimeTypes]}
            canUpload={estimateNotApproved}
            canDelete={false}
          />
        )}
      </Box>

      {!isMobile && (
        <Box className={classes.expandButtonContainer}>
          <Button
            onClick={() => setExpanded(!expanded)}
            className={classes.expandButton}
          >
            <Typography>
              <icons.ExpandLess
                className={expanded ? classes.iconOpened : classes.iconClosed}
              />
              {expanded ? 'Collapse' : 'Expand'}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AdditionalItemsRequested
