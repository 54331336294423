import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
        },
        Title: {
            fontSize: "18px",
            color: "#494A5A",
            marginBottom: "16px"
        },
        DocUploadWrapper: {
            padding: "16px",
            border: "2px solid #EBECEF",
            boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
            borderRadius: "8px"
        },
        Dropzone: {
            minHeight: '300px',
            width: "65vw",
            color: "var(--bosscat-blue-600)",
            [theme.breakpoints.down('md')]: {
                minHeight: '180px',
                width: "100%"

            },
            '@global': {
                '.MuiDropzoneArea-text': {
                    fontSize: 16,
                    fontFamily: 'Lato'
                }
            }
        },
        Buttons: {
            width: "100%",
            display: "flex",
            columnGap: "24px",
            justifyContent: "flex-end",
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                rowGap: "16px"
            }
        },
        ButtonSec: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FAFBFF",
            borderRadius: "8px",
            padding: "16px 32px",
            color: "var(--bosscat-blue-600)",
            cursor: "pointer",
            columnGap: "8px",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
                borderRadius: "8px",
                columnGap: "8px",
                fontSize: "18px"
            }
        },
        ButtonPrim: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--bosscat-blue-600)",
            borderRadius: "8px",
            padding: "16px",
            color: "#fff",
            minWidth: "140px",
            cursor: "pointer",
            filter: "drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                fontSize: "18px"
            }
        },
    }))
