import { FC, useContext } from 'react'
import { Box, Button, Dialog, Grid, Typography } from '../../../../../UI'
import { PurchaseModalProps } from './types'
import CloseIcon from '@material-ui/icons/Close'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import useStyles from './styles'
import GroupedOrder from './GroupedOrder'
import { DashboardContext } from '../../context.provider'

const PurchaseModal: FC<PurchaseModalProps> = ({
  open,
  setOpen,
  title,
  titleColor,
  icon,
  groupedOrders,
}) => {
  const styles = useStyles()

  const { cart } = useContext(DashboardContext)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { overflow: 'overlay', borderRadius: '16px', height: '710px' } }}
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px 4px 16px 16px', height: '100%', flexWrap: 'nowrap', width: '100%' }}
        spacing={2}
      >
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              style={{ width: '100%', gap: '4px', color: titleColor  }}
              alignItems="center"
            >
              {icon}
              <Typography className={styles.addHomeCardHeader}>
                {title}
              </Typography>
            </Box>
            <Button className={styles.closeButton} onClick={handleClose}>
              <CloseIcon style={{ width: '14px', height: '14px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid container item direction="column" spacing={3}>
          {groupedOrders.map((groupedOrder) => (
            <GroupedOrder groupedOrder={groupedOrder} />
          ))}
        </Grid>
        <Grid container item>
          <Button
            startIcon={<CartIcon style={{ width: '16px', height: '16px' }} />}
            endIcon={
              <ArrowForwardIcon style={{ width: '16px', height: '16px' }} />
            }
            variant='contained'
            className={styles.orderButton}
            fullWidth
            disabled={!cart.length}
          >
            <Typography className={styles.lgFont}>
              {`Order Items (${cart.length})`}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PurchaseModal
