import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      // width: '100%',
      // height: '88px',
      alignItems: 'center',
      backgroundColor: 'white',
      bottom: 0,
      zIndex: 10,
      width: '100%',
      justifyContent: 'flex-end',
      paddingTop: '1rem',
      [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
        maxWidth: '100%',
        flex: 1,
        position: 'sticky',
        marginBottom: '1rem',
        marginLeft: '.4rem'
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    rowTitle: {
      display: 'flex',
      flexDirection: 'row',
      // margin: '0px .5rem',
      gap: '.5rem',
      '@global': {
        '.MuiTypography-caption': {
          color: "#6D6A6F"
        }
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        width: '100%'
        // flexWrap: 'wrap'
      }
    },
    regularPrice: {
      color: '#6D6A6F',
      fontSize: 24,
      fontFamily: 'NextArtNormal',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:before': {
        content: '""',
        display: 'block',
        height: '1px',
        background: '#6D6A6F',
        width: '100%',
        position: 'absolute'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    rowButtons: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '0px 10px',
        width: '100%'
      }
    },
    requestMoreButton: {
      height: '54px',
      padding: '0 1rem',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      transition: '300ms all',
      '&:hover': {
        color: 'white',
        transition: '300ms all',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      }
    },
    submitButton: {
      height: '54px',
      padding: '0 1rem',
      color: 'var(--white-color)',
      // backgroundColor: 'var(--repairs-yellow-500)',
      backgroundColor: '#01AA59 !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      '&:hover': {
        // backgroundColor: 'var(--repairs-yellow-700) !important',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        // height: '40px'
      }
    },
    total: {
      fontFamily: 'NextArtHeavy',
      fontSize: '24px !important',
    lineHeight: '1 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    decimals: {
      fontFamily: 'NextArtBold !important',
      fontSize: '14px !important',
      marginTop: '.3rem !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    price: {
      display: 'flex',
      // gap: '.2rem'
    }
  })
)
