/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { jobsTypes, FetchJobsAction, FetchJobsStatsAction } from './types'
import { configActions, jobsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchJobs({ payload, callback }: FetchJobsAction): SagaIterator {
  let success = false
  try {
    const startDate = payload.startDate ?? undefined
    const endDate = payload.endDate ?? undefined
    const status = payload.status ?? undefined
    const start = payload.start ?? 0
    const limit = payload.limit ?? 9999
    const sortBy = payload.sortBy ?? undefined
    const sortOrder = (payload.sortOrder?.toUpperCase()) as ('ASC' | 'DESC') ?? undefined
    const search = payload.search ?? undefined

    const jobs = yield call(Punchlist.jobs.getJobs, startDate, endDate, status?.join(','), start, limit, sortBy, sortOrder, search)

    if (jobs && jobs.items) {
      const { items, ...noItems } = jobs
      yield put(jobsActions.setJobs(items))
      yield put(configActions.setConfigValue({ type: 'jobPagination', value: noItems }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the jobs'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchJobsStats({ payload, callback }: FetchJobsStatsAction): SagaIterator {
  let success = false
  try {
    const startDate = payload.startDate ?? undefined
    const endDate = payload.endDate ?? undefined
    const status = payload.status ?? undefined

    const jobStats = yield call(Punchlist.jobs.getJobsStats, startDate, endDate, status)
    if (jobStats) {
      yield put(configActions.setConfigValue({ type: 'jobStats', value: jobStats }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the job stats'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(jobsTypes.FETCH_JOBS, fetchJobs)
  yield takeLatest(jobsTypes.FETCH_JOBS_STATS, fetchJobsStats)
}
