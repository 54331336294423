import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '8px',
      padding: '8px 16px !important',
      borderRadius: '8px',
      background: 'var(--expired-color-back)',
    },
    errorMessageTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
    },
    errorMessageSubtitle: {
      padding: '4px 0px',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
    },
    addNewCard: {
      '@global': {
        '.MuiFormControlLabel-label': {
          width: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '12px',
      },
    },
  })
)
