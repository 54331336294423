import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    top: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '15px',
      justifyContent: 'center',
      marginLeft: '10px'
    },
    root: {
      // position: 'relative',
      backgroundColor: 'var(--border-color)',
      borderRadius: '8px',
      width: '90px',
      height: '8px',
      alignSelf: 'center',
      marginTop: '3px'
    },
    completed: {
      backgroundColor: 'var(--completed-color)',
      borderRadius: '8px',
      height: '8px'
    },
    progress: {
      backgroundColor: 'var(--progress-color)',
      borderRadius: '8px',
      height: '8px'
    },
    icon: {
      position: 'absolute',
      zIndex: 0,
      fontSize: 18,
      color: '#EBA900',
      justifySelf: 'center',
    }
  })
)
