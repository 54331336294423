import { FC, memo, useState } from 'react'
import { Typography, Box, SvgIcon } from '@material-ui/core'
import useStyles from './styles'
import { contactUs, icons } from 'assets'
import ContactIcon from 'assets/icons/custom/contact-image.png'
import { SideMenuContactModalProps } from './types'
import ContactModal from './ContactModal'
import ContactUsModal from '../../../../pages/Desktop/Estimates/EstimateView/ContactUsModal'
const EMAIL_ADDRESS: string = "customercare@bosscathome.com";

const SideMenuContactModal: FC<SideMenuContactModalProps> = ({ onClick, open }) => {
  const styles = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const _onOpen = () => {
    onClick()
    setOpenModal(true);
  }
  const _onClose = () => setOpenModal(false);

  const _handleOpenEmailClient = (): void => {
    window.open(`mailto:${EMAIL_ADDRESS}`);
  };

  return (
    <>
      <Box className={`${styles.container} ${open ? styles.containerOpen : styles.containerClosed}`}>
        <Box className={styles.contactButton}>
          <Box className={styles.itemContainer}>
            <img alt='contact-icon' src={contactUs} className={styles.img} />
            {open && <Typography>Contact us</Typography>}
          </Box>
          {open &&
            <>
              <Box className={`${styles.itemContainer} ${styles.hover}`} onClick={_handleOpenEmailClient}>
                <SvgIcon component={icons.Email} className={styles.icon} />
                <Typography className={styles.itemText}>Email us</Typography>
              </Box>
              <Box className={`${styles.itemContainer} ${styles.hover}`} onClick={_onOpen}>
                <SvgIcon component={icons.Phone} className={styles.icon} />
                <Typography className={styles.itemText}>Call us</Typography>
              </Box>
            </>
          }
          {/* <Box className={styles.availabilityTextContainer}>
            <Typography className={styles.availabilityText}>9am-6pm EST</Typography>
          </Box> */}
        </Box>
      </Box>
      <ContactUsModal open={openModal} setOpen={setOpenModal} />
    </>
  )
}

export default memo(SideMenuContactModal);

