import { useContext } from "react";
import { Button, Grid, SvgIcon, Typography } from "@material-ui/core"
import useStyles from './styles';

import { house, icons, prevent, giftImg, star, starWhite, supportTeam, construction } from 'assets'
import { MaintenanceModalsContext } from "../context";
import { USER_TYPE } from "helpers/constants";
import { getUser } from "ducks/selectors";
import { useSelector } from "react-redux";
import { history, PERKS } from "helpers/index";
import PerkBox from "../PerksModal/PerkBox";

const DashboardNoSubscriptions = () => {
  const classes = useStyles()
  const {
    dispatch: dispatchContext
  } = useContext(MaintenanceModalsContext)

  const push = history.usePush()
  const user = useSelector(getUser)

  const userIsHomeowner = [USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(user.clientType)

  const boxes = [
    {
      title: userIsHomeowner ? "Prevent" : "One Year Maintenance Membership",
      content: userIsHomeowner ? "Every visit, we'll perform an assessment to detect possible trouble spots before they become more severe." : "Our professionals provide the essential services your clients’ homes need to run smoothly.",
      img: prevent
    },
    {
      title: userIsHomeowner ? "Extend" : "Stay Top of Mind Year-Round",
      content: userIsHomeowner ? "We'll service your home's core systems to ensure they continue to work properly and extend their lifespan." : "At every interaction throughout their membership, your name, picture, and contact info will be front and center.",
      img: house
    },
    {
      title: userIsHomeowner ? "Relax" : "Free Welcome Gift ($150 value)",
      content: userIsHomeowner ? "Your home is in peak condition and you have professionals at the ready who can help with any home project." : "You’ll get a new home welcome package with high-end home goods to give to your buyers at closing.",
      img: house
    },
  ]

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography className={classes.headerTitle}>Get to know all membership details!</Typography>
        <Button className={classes.VipLink}
          onClick={() => {
            dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
            dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'FAQS' })
          }}>
          FAQ's
          <icons.ArrowUpward className={classes.Arrow} />
        </Button>
      </Grid>

      <Grid container item direction="column" justifyContent="center" alignItems="center">
        <Typography className={`${classes.moranga} ${classes.title}`}>
          {userIsHomeowner ? 'Put Your Home Maintenance on Autopilot for $550/year' : 'Gift a VIP Home Maintenance Membership for $550/year'}
        </Typography>
        <Typography className={`${classes.text}`}>
          {userIsHomeowner ? 'With our VIP maintenance membership, your home will never miss the seasonal services it needs to run smoothly. And, if you need a repair or remodel along the way, our Pros are ready to help.' : 'You helped your buyers find their dream home. By gifting our VIP Home Maintenance Membership, you’ll help them save money and enhance the value of their investment. Includes a Welcome Home Gift for your clients.'}
        </Typography>
      </Grid>

      <Grid item className={classes.buttonContainer}>
        <Button
          variant='contained'
          onClick={() => { push('maintenance') }}
          className={`${classes.maintenanceButton}`}
          endIcon={<img src={construction} alt="Order Maintenance button" />}>
          Click Here to Order
        </Button>
      </Grid>

      <Grid item container className={classes.boxContainer}>
        {boxes.map(box => {
          return (
            <Grid container lg={4} className={classes.box} spacing={1}>
              <Grid item>
                <img alt={box.title} src={box.img} className={classes.boxImg} />
              </Grid>
              <Grid item>
                <Typography className={classes.boxTitle}>
                  {box.title}
                </Typography>
                <Typography className={classes.boxSubtitle}>
                  {box.content}
                </Typography>
              </Grid>
            </Grid>
          )
        })}
      </Grid>

      <Grid item className={classes.perks}>
        {PERKS.map(perk =>
          <PerkBox
            img={perk.img}
            backgroundColor={perk.backgroundColor}
            title={perk.title}
            bullets={perk.bullets}
            className={classes.perk}
          />
        )}
      </Grid>

      <Grid container item className={classes.container}>
        <Grid item container lg={6} className={`${classes.box} ${classes.spacing}`} /* spacing={2} */>
          <Grid item>
            <img src={giftImg} alt="gift image" className={classes.giftImg} />
          </Grid>
          <Grid item container direction="column" justifyContent="center">
            <Typography className={classes.boxTitle}>
              Welcome Gift Set
            </Typography>
            <Typography className={classes.boxSubtitle}>
              You’ll receive a new home welcome gift set from BOSSCAT with high-end home goods.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container lg={6} className={`${classes.box} ${classes.trustpilot}`} direction="column">
          <Grid item container alignItems="center">
            <img src={star} alt="Star" className={classes.star} />
            <Typography className={` ${classes.trustpilotTitle} ${classes.bold}`}>Trustpilot</Typography>
          </Grid>
          <Grid item className={classes.starContainer}>
            <img src={starWhite} alt="Star" className={classes.starWhite} />
            <img src={starWhite} alt="Star" className={classes.starWhite} />
            <img src={starWhite} alt="Star" className={classes.starWhite} />
            <img src={starWhite} alt="Star" className={classes.starWhite} />
            <img src={starWhite} alt="Star" className={classes.starWhite} />
          </Grid>
          <Grid item className={classes.reviews}>
            <Typography>TrustScore</Typography>
            <Typography className={classes.bold}>4.8 | 388</Typography>
            <Typography>reviews</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={`${classes.box} ${classes.supportTeam}`} spacing={2}>
        <Grid container spacing={2} className={classes.supportTeamContainer}>
          <Grid item>
            <img src={supportTeam} alt="Support team" width={92} height={92} />
          </Grid>
          <Grid item className={classes.supportTeamText}>
            <Typography className={`${classes.teamTitle} ${classes.bold}`}>
              Dedicated VIP Maintenance Customer Care Team
            </Typography>
            <Typography>
              {userIsHomeowner ? 'You’ll have a dedicated Customer Care Team focused on helping you anytime you need.' : 'You’ll have a dedicated Customer Care Team focused on helping you anytime you and your clients need.'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={3}>
          <Button
            variant='contained'
            onClick={() => { push('maintenance') }}
            className={`${classes.maintenanceButton}`}
            endIcon={<img src={construction} alt="Order Maintenance button" />}>
            Click Here to Order
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.disclaimer}>
        <Typography>
          The scope of the maintenance services is intended to cover customary equipment and costs; any equipment or material that is not customary may not be covered or may require additional fees.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default DashboardNoSubscriptions