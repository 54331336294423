import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2rem 4rem',
            margin: '2rem auto',
            // backgroundColor: "rgba(15,16,44,1)",
            minHeight: '45vh',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50% !important',
            backgroundAttachment: 'scroll',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                padding: "2rem 1rem",
                margin: '2rem 0'
            }
        },
        title: {
            color: '#A5965A',
            fontSize: '3.625rem',
            fontFamily: "NextArtHeavy",
            textAlign: 'center',
            lineHeight: 1,
            margin: '3rem 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: '25px'
            }
        },
        sliderContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        tabs: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: "2rem",
            marginBottom: "3rem"
        },
        tab: {
            color: "rgba(152,152,152,1)",
            transition: 'color 0.5s ease-in-out',
            cursor: 'pointer',
        },
        tabActive: {
            color: "rgba(255,255,255,1)",
            borderBottom: '1px solid #A5965A',
            // '&::after': {
            //     display: 'block',
            //     content: '',
            //     borderBottom: 'solid 3px #A5965A',
            //     transform: 'scaleX(0)',
            //     transition: 'transform 500ms ease-in-out',
            //     transformOrigin: "0% 50%"
            // },
        },
        tabTitle: {
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontFamily: "LatoBold"
        },
        slider: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            position: 'relative',
            paddingBottom: "700px",
            width: "75%",
            margin: "1rem auto 0",

            [theme.breakpoints.up(2200)]: {
                width: '65%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingBottom: "500px",
            }
        },
        slide: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: "center",
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
            paddingLeft: "3.25rem",
            maxWidth: "1200px",
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                paddingLeft: "1rem",
            }
        },
        activeSlide: {
            opacity: 1,
        },
        slideText: {
            display: 'flex',
            flexDirection: 'column',
        },
        slideTitle: {
            fontSize: "2.25rem",
            lineHeight: "3rem",
            color: "rgba(255,255,255,1)",
            marginBottom: "2rem",
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            }
        },
        slideSubtitle: {
            fontSize: "1rem",
            lineHeight: "25px",
            color: "rgba(255,255,255,1)",
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            }
        },
        slideImgContainer: {
            marginLeft: "5rem",
            [theme.breakpoints.down('sm')]: {
                marginLeft: "0",
            }
        },
        slideImg: {
            width: "550px",
            height: "600px",
            objectFit: "cover",
            borderRadius: "16px",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
                height: "30vh",
                marginTop: "2rem"
            }
        }
    })
)
