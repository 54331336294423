import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '408px',
      borderRadius: '16px',
      textAlign: 'center',
    },
    text: {
      padding: '0 24px',
      fontFamily: 'BetterSansRegular',
      '& span': {
        fontFamily: 'BetterSansRegular',
        '&.MuiRadio-colorPrimary.Mui-checked': {
          color: 'var(--better-color)',
        },
      },
    },
    icon: {
      width: '68px',
      height: '68px',
      marginBottom: '12px',
    },
    phone: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
    },
    phoneIcon: {
      width: '21px',
      height: '21px',
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--better-color)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'BetterSansRegular',
      '&:hover': {
        color: 'var(--white-color)',
        backgroundColor: 'var(--bosscat-green-700)',
      },
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
    },
  })
)
