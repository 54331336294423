import { Box, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { giftDeliveryDate, icons, purchaseDate, renewalDate, startDate } from 'assets';
import useStyles from './styles';
import { appendHttpToUrl, dateFormatString, formatTimestamp } from '../../../../../../helpers';
import { isMobile } from 'react-device-detect';
import CustomerSuccessManagerModal from '../CustomerSuccessManagerModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions } from 'ducks/subscriptions/selectors';
import { subscriptionsActions } from 'ducks/actions';

type Props = {
    subscription: any;
}

function DashboardGiftItemAgent({ subscription }: Props) {
    const [showSupport, setShowSupport] = useState(false)
    const [CSMLoading, setCSMLoading] = useState(false)
    const classes = useStyles();
    const dispatch = useDispatch()

    const customerSuccessManager = useSelector(getSubscriptions('customerSuccessManager'))

    const { city, fullAddress, state } = subscription.propertyAddress;
    const purchaseDay = useMemo(() => formatTimestamp(subscription?.insertedOn || "", "MM/DD/YYYY"), [subscription]);
    const giftDeliveryDay = useMemo(() => subscription?.giftBox?.deliveryDate ? formatTimestamp(subscription.giftBox.deliveryDate, "MM/DD/YYYY") : "-", [subscription]);
    const startDay = useMemo(() => subscription.contractSignedOn ? formatTimestamp(subscription.contractSignedOn, "MM/DD/YYYY") : "-", [subscription]);
    const renewalDay = useMemo(() => subscription.renewals === 0 ? "-" : `${formatTimestamp(subscription?.insertedOn, "MM/DD/YYYY").slice(0, 6)}${formatTimestamp(subscription.insertedOn, "MM/DD/YYYY").split("/")[2] + 1}`, [subscription, purchaseDay])

    const fetchCSM = useCallback(() => {
        dispatch(subscriptionsActions.fetchEmployees({ role: "TERRITORY_SUCCESS_MANAGER", zipCode: subscription.propertyAddress.zipCode }, (succ => {
            if (succ) {
                setCSMLoading(false)
            }
        })))
    }, [dispatch, subscription.propertyAddress.zipCode])

    function handleCallSupport() {
        setCSMLoading(true)
        fetchCSM()
        setShowSupport(true)
    }

    return (
        <Box className={classes.Container}>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap='nowrap'
            >
                <Grid container
                    direction="column"
                >
                    <Typography className={classes.Address}>{fullAddress}</Typography>
                    <Typography className={classes.City}>{`${city}, ${state}`}</Typography>
                </Grid>
                {!isMobile && <Box onClick={handleCallSupport} className={classes.Support}>
                    <icons.Phone className={classes.Icon} />
                    <Typography className={classes.normal}>Support</Typography>
                </Box>}
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="center"
                wrap='nowrap'>
                <Typography className={classes.Name}>{`${subscription.homeowner.firstName || "Ryan"} ${subscription.homeowner.lastName || "Anderson"} ${isMobile ? "" : " - "}`}</Typography>
                {!isMobile && <a
                    href={appendHttpToUrl(`${subscription.giftBox?.trackingUrl}${subscription.giftBox?.trackingId || ''}`)}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.Tracking}
                    style={!subscription.giftBox?.trackingUrl || !subscription.giftBox?.trackingId ? { pointerEvents: "none", opacity: 0.5 } : {}}
                >
                    <icons.CardGiftcard className={classes.Icon} />
                    <Typography className={classes.TrackingText}>Gift Tracking</Typography>
                    <icons.ArrowUpward className={classes.Arrow} />
                </a>}
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="center"
                wrap='nowrap'>
                <Grid container
                    direction="row"
                    alignItems="center"
                    wrap='nowrap'>
                    <img src={purchaseDate} className={classes.DateIcon} alt="" />
                    <Grid container
                        direction="column"
                    >
                        <Typography className={classes.DateLabel}>Purchase Date</Typography>
                        <Typography className={classes.DateValue}>{purchaseDay}</Typography>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    alignItems="center"
                    wrap='nowrap'>
                    <img src={giftDeliveryDate} className={classes.DateIcon} alt="" />
                    <Grid container
                        direction="column"
                    >
                        <Typography className={classes.DateLabel}>Gift Delivery Date</Typography>
                        <Typography className={classes.DateValue}>{giftDeliveryDay}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="center"
                wrap='nowrap'>
                <Grid container
                    direction="row"
                    alignItems="center"
                    wrap='nowrap'>
                    <img src={startDate} className={classes.DateIcon} alt="" />
                    <Grid container
                        direction="column"
                    >
                        <Typography className={classes.DateLabel}>Start Date</Typography>
                        <Typography className={classes.DateValue}>{startDay}</Typography>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    alignItems="center"
                    wrap='nowrap'>
                    <img src={renewalDate} className={classes.DateIcon} alt="" />
                    <Grid container
                        direction="column"
                    >
                        <Typography className={classes.DateLabel}>Renewal Date</Typography>
                        <Typography className={classes.DateValue}>{renewalDay}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {isMobile && <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                wrap='nowrap'
                className={classes.ButtonsMobile}
            >
                <Box onClick={handleCallSupport} className={classes.Support}>
                    <icons.Phone className={classes.Icon} />
                    <Typography>Support</Typography>
                </Box>
                <a
                    href={appendHttpToUrl(`${subscription.giftBox?.trackingUrl}${subscription.giftBox?.trackingId || ''}`)}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.Support}
                    style={!subscription.giftBox?.trackingUrl || !subscription.giftBox?.trackingId ? { pointerEvents: "none", opacity: 0.5 } : {}}
                >
                    <icons.CardGiftcard className={classes.Icon} />
                    <Typography className={classes.TrackingTextMobile}>Gift Tracking</Typography>
                </a>
            </Grid>}
            <CustomerSuccessManagerModal
                loading={CSMLoading}
                open={showSupport}
                handleClose={() => setShowSupport(false)} data={{
                    picture: customerSuccessManager?.picture,
                    name: customerSuccessManager?.firstName,
                    lastName: customerSuccessManager?.lastName,
                    position: customerSuccessManager?.primaryRole,
                    phone: customerSuccessManager?.phone,
                    email: customerSuccessManager?.email[0].email
                }} />
        </Box>
    )
}

export default DashboardGiftItemAgent