
/** TYPES **/
export const discountsTypes = {
  FETCH_DISCOUNT_BANNER: 'FETCH_DISCOUNT_BANNER',
  SET_VALUE: 'SET_SUBSCRIPTIONS_VALUE',
}

export interface DiscountsValue {
  attr: keyof DiscountsType
  value: any
}


/** ACTIONS **/
export interface FetchDiscountBannerAction {
  type: typeof discountsTypes.FETCH_DISCOUNT_BANNER
  payload: null
  callback?: (succ: boolean) => void
}

export interface SetDiscountValueAction {
  type: typeof discountsTypes.SET_VALUE
  payload: DiscountsValue
}

export type DiscountsActions =
  | FetchDiscountBannerAction
  | SetDiscountValueAction

export interface DiscountBanner {
  id: string
  startTime: number
  endTime: number
  monthString: string
  discount: number
  discountCode: string
  minAmount: number
}
/** REDUCER **/
export type DiscountsType = {
  discountBanner: DiscountBanner | null
}
