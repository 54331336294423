import { ESTIMATE_PUBLIC_STATUS, JOB_PUBLIC_STATUS, RESOURCE_TYPE } from 'helpers'
import { Resource } from './saga'

/** TYPES **/
export const dashboardTypes = {
  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA'
}

/** ACTIONS **/
export interface FetchDashboardDataAction {
  type: typeof dashboardTypes.FETCH_DASHBOARD_DATA
  payload: { startDate?: number, endDate?: number }
  callback?: (succ: boolean) => void
}

export type DashboardActions =
  | FetchDashboardDataAction

export interface DashboardIndicators {
  estimates: number
  activeOrders: number
  completedJobs: number
  estimatesCount: number
  jobsCount: number
}

export interface DashboardData {
  resourceId: string
  resourceType: RESOURCE_TYPE
  title: string
  address: string
  createdOn: number
  initDate: string
  completedDate: string
  tag: ESTIMATE_PUBLIC_STATUS | JOB_PUBLIC_STATUS
  publicId: string
}

export type DashboardType = Resource[] | null
