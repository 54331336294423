import { StripeSecret } from 'api/punchlist/payments/types'
import { Payment } from 'ducks/types'
import { paymentsTypes, PaymentsActions, PaymentValue } from './types'

const actions = {
  fetchPayments: (
    payload?: string,
    callback?: (succ: boolean) => void
  ): PaymentsActions => ({
    type: paymentsTypes.FETCH_PAYMENTS,
    payload,
    callback
  }),
  setPayments: (payload: Payment[]): PaymentsActions => ({
    type: paymentsTypes.SET_PAYMENTS,
    payload
  }),
  setPaymentValue: (payload: PaymentValue): PaymentsActions => ({
    type: paymentsTypes.SET_PAYMENT_VALUE,
    payload
  }),
  fetchStripeSecret: (
    payload: StripeSecret,
    callback?: (secret?: string) => void
  ): PaymentsActions => ({
    type: paymentsTypes.FETCH_STRIPE_SECRET,
    payload,
    callback
  }),
  fetchStripeSecretSetup: (
    callback?: (secret?: string) => void
  ): PaymentsActions => ({
    type: paymentsTypes.FETCH_STRIPE_SECRET_SETUP,
    payload: null,
    callback
  })
}

export default actions
