import React, { useReducer } from 'react'
import { WorkOrdersReducer } from './reducer'
import { WorkOrdersContextType } from './types'

const initialState = {
  // GENERAL
  modalType: null,
  modalOpen: false,
}

export const MaintenanceModalsContext = React.createContext<{
  state: WorkOrdersContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})

export const MaintenanceModalsProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(WorkOrdersReducer, initialState)

  return (
    <MaintenanceModalsContext.Provider value={{ state, dispatch }}>
      {children}
    </MaintenanceModalsContext.Provider>
  )
}
