import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto',
      height: '100%'
    },
    chartRow: {
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        width: '100%',
      },
    },
    chart: {
      margin: '10px 20px 20px 20px',
      [theme.breakpoints.down('md')]: {
        margin: '10px 0px 20px',
        // width: '260px',
      }
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '58px',
      margin: '2px 0px 0px 0px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        lineHeight: '40px',
      },
    },
    percent: {
      borderRadius: 40,
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    percentMark: {
      margin: '22px 20px 2px 20px',
      padding: '8px',
      height: '16px',
      width: '16px',
      borderRadius: 40,
      [theme.breakpoints.down('lg')]: {
        margin: '0 20px 0 0'
      }
    },
    details: {
      marginTop: '50px',
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    itemPercent: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)'
    },
    number: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '58px',
      margin: '2px 0px 0px 5px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        lineHeight: '40px',
      },
    },
    dateInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--date-color)'
    },
    monthTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
      color: 'var(--head-text-color)',
      margin: '20px 10px'
    },
    itemTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    emptyStateContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    descriptionEmptyState: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      width: '364px',
    },
    descriptionEmptyStateSpecialWord: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px'
    },
    noResultDescription: {
      fontSize: '20px',
      fontFamily: 'Lato !important',
      maxWidth: 'none !important',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        whiteSpace: 'normal'
      }
    },
    noResultButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
    },
    noResultViewButton: {
      width: '45%',
      height: '38px',
      marginRight: '8px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    noResultViewButtonAlt: {
      width: '45%',
      height: '38px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--button-focus)'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    bottomSeparation: {
      marginBottom: '16px'
    },
    containerNoResultByMonth: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  })
)
