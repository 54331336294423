/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect } from 'react'
import {
  useStripe,
  useElements,
  Elements,
  CardElement,
} from '@stripe/react-stripe-js'
import { icons, reportProblem } from '../../../../../../assets'
import useStyles from './styles'
import { Box, Typography, Grid, STextField, Radio } from '../../../../../UI'
import { useDispatch } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import env from '@beam-australia/react-env'
import { paymentsActions } from '../../../../../../ducks/actions'
import { DispatchPaymentCreditCardContext } from '../context'
import { dispatchPaymentCreditCardTypes } from '../context/types'
import { isEmpty, RESOURCE_TYPE } from '../../../../../../helpers'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

const NewCard: FC = () => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const {
    dispatch: dispatchContext,
    state: {
      showWarning,
      errorMessage,
      address,
      cardHolder,
      amount,
      estimateId,
      //payIntent,
      //clientSecret,
    },
  } = useContext(DispatchPaymentCreditCardContext)

  //const validate = !isEmpty(address) && !isEmpty(cardHolder)

  const setValue = (attr: string, value: any) => {
    dispatchContext({
      type: dispatchPaymentCreditCardTypes.SET_VALUE,
      payload: { attr, value },
    })
  }

  useEffect(() => {
    if (amount > 0 && !isEmpty(estimateId)) {
      dispatch(
        paymentsActions.fetchStripeSecret(
          {
            amount,
            resourceId: estimateId,
            resourceType: RESOURCE_TYPE.ESTIMATE,
            memo: address ? `Expedite BOSSCAT Estimate: ${address}` : undefined
          },
          (secret?: string) => {
            if (secret) setValue('clientSecret', secret)
          }
        )
      )
    }
  }, [amount, estimateId])

  /*useEffect(() => {
    if (payIntent) {
      setValue('showWarning', validate)
      if (validate && stripe && elements) {
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardHolder,
            },
          },
        })
        if (payload.error) {
          setValue('errorMessage', payload.error.message)
        } else {
          ispatch(
            newEstimateActions.setNewEstimateValue({
              attr: 'paymentOption',
              value: selectedOption,
            })
          )
        }
      }
    }
  }, [payIntent])*/

  return (
    <Grid container spacing={1} className={classes.newCard}>
      <Grid item xs={12} className={classes.title}>
        <Box className={classes.iconBox}>
          <icons.CreditCard />
        </Box>
        <Typography style={{ width: '95px' }}>Credit Card:</Typography>
        <Radio
          aria-label='credit-card-radio'
          color="primary"
          disableRipple
          checked={true}
          className={classes.radio}
          classes={{ checked: classes['Mui-checked'] }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'grid', gap: '12px', padding: '8px 0' }}
      >
        <Typography>Address:</Typography>
        <STextField
          className={classes.cardHolder}
          value={address}
          error={showWarning && isEmpty(address)}
          placeholder={'Your Address'}
          onChange={(event: any) => {
            setValue('address', event.target.value)
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'grid', gap: '12px', padding: '8px 0' }}
      >
        <Typography>Card Holder:</Typography>
        value={cardHolder}
        <STextField
          className={classes.cardHolder}
          error={showWarning && isEmpty(cardHolder)}
          placeholder={'Card Holder Full Name'}
          onChange={(event: any) => {
            setValue('cardHolder', event.target.value)
          }}
        />
      </Grid>
      {stripe && elements && (
        <Grid
          item
          xs={12}
          style={{ display: 'grid', gap: '4px', padding: '8px 0' }}
        >
          <Typography>Card Info:</Typography>
          <Box className={classes.cardNumber}>
            <CardElement />
          </Box>
        </Grid>
      )}
      {errorMessage && (
        <Grid item xs={12} className={classes.errorMessage}>
          <img src={reportProblem} alt="" />
          <Typography variant="caption" style={{ paddingTop: '4px' }}>
            {errorMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

const Cards: FC = () => {
  // const { paymentMethods } = useSelector(getUser)

  return (
    <Box aria-label='cards'>
      {/*paymentMethods.map((paymentMethod, index) => (
        <Box key={index}>
          <Typography>{paymentMethod.brand}</Typography>
          <Typography>{paymentMethod.last4}</Typography>
        </Box>
      ))*/}
      <Box>
        <Elements stripe={stripePromise}>
          <NewCard />
        </Elements>
      </Box>
    </Box>
  )
}

export default Cards
