import { Box, Divider, Grid, Typography } from '@material-ui/core'
import useStyles from '../../../styles'
import HomeIcon from '@material-ui/icons/Home'
import { filledDownArrow } from 'assets/index'
import { KeyboardArrowUp } from '@material-ui/icons'
import Condition from './Condition'
import { round } from 'helpers/index'

const PropertyConditions = () => {
  const styles = useStyles()

  const conditions = [
    {
      desc: 'Overall',
      score: 2,
    },
    {
      desc: 'Interior',
      score: 2.5,
    },
    {
      desc: 'Exterior',
      score: 2.5,
    },
    {
      desc: 'Kitchen',
      score: 3.2,
    },
    {
      desc: 'House: Side',
      score: 5,
    },
  ]

  const getBackgroundColor = (score: number) => {
    if (score < 3) return 'var(--bosscat-green-600)'
    if (score < 5) return 'var(--repairs-yellow-600)'
    return 'var(--renovation-red-700)'
  }

  const verticalChart = () => {
    let score = 6
    let margin = 0
    const result = []
    while (score >= 1) {
      result.push(
        <Box
          height={margin % 10 === 0 ? '80px' : '40px'}
          left={`${margin}%`}
          bgcolor={getBackgroundColor(score)}
          color={getBackgroundColor(score)}
          borderRadius="8px"
          width="4px"
          position="absolute"
        >
          {margin % 10 === 0 && (
            <Box left="-30px" position="relative">
              <Typography className={styles.nextArtTitle}>
                {round(score, 1)}
              </Typography>
            </Box>
          )}
        </Box>
      )

      score -= 0.5
      if (margin % 10 === 0) margin += 12
      else margin += 8
    }

    return result
  }

  return (
    <Grid container item style={{ marginTop: '16px' }}>
      <Grid
        item
        container
        direction="column"
        // className={styles.containerBox}
        style={{ padding: '16px' }}
        spacing={2}
      >
        <Grid item>
          <Typography
            className={styles.title}
            style={{ fontWeight: 700, textTransform: 'uppercase' }}
          >
            Home Preservation & Maintenance
          </Typography>
        </Grid>
        <Grid item>
          <Box className={styles.scoreSelector} style={{ background: 'white' }}>
            <Box className={styles.scoreIndicator} left="50%" top="0">
              <img src={filledDownArrow} alt="filled_down_arrow" />
              <Typography
                className={styles.nextArtTitle}
                style={{ textTransform: 'uppercase' }}
              >
                SUBJECT
              </Typography>
            </Box>
            <Box className={styles.scoreIndicator} left="70%" top="0">
              <img src={filledDownArrow} alt="filled_down_arrow" />
              <Typography
                className={styles.nextArtTitle}
                style={{ textTransform: 'uppercase' }}
              >
                Neighborhood
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box className={styles.scoreSelector}>
            <HomeIcon
              className={styles.buttonIcon}
              style={{ left: '50%', position: 'absolute' }}
            />
            <Box
              display="flex"
              left="70%"
              position="absolute"
              width="16px"
              justifyContent="center"
            >
              <HomeIcon
                className={styles.buttonIcon}
                style={{ left: '-100%' }}
              />
              <HomeIcon className={styles.buttonIcon} style={{ left: '0%' }} />
              <HomeIcon
                className={styles.buttonIcon}
                style={{ left: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box className={styles.scoreSelector} style={{ background: 'white' }}>
            <Box
              className={styles.scoreIndicator}
              color="var(--bosscat-green-700)"
              left="0%"
            >
              <KeyboardArrowUp style={{ width: '10px', height: '10px' }} />
              Low
            </Box>
            <Box
              className={styles.scoreIndicator}
              color="var(--repairs-yellow-700)"
              left="50%"
            >
              <KeyboardArrowUp style={{ width: '10px', height: '10px' }} />
              Middle
            </Box>
            <Box
              className={styles.scoreIndicator}
              color="var(--renovation-red-700)"
              right="0%"
            >
              <KeyboardArrowUp style={{ width: '10px', height: '10px' }} />
              High
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Divider orientation="horizontal" />
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {conditions.map((condition) => (
            <Condition
              desc={condition.desc}
              score={condition.score}
              bgcolor={getBackgroundColor(condition.score)}
            />
          ))}
        </Grid>
        <Grid item container style={{ height: '120px' }}>
          <Grid item xs={3}></Grid>
          <Grid
            item
            container
            xs={9}
            alignItems="flex-end"
            style={{ position: 'relative' }}
          >
            {verticalChart()}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={3}></Grid>
          <Grid item xs={9} style={{ position: 'relative' }}>
            <Box
              className={styles.scoreIndicator}
              color="var(--renovation-red-700)"
              width="140px"
              right='100%'
            >
              <Typography
                className={styles.nextArtTitle}
                style={{ minWidth: 'fit-content' }}
              >
                Severe Damage
              </Typography>
              <KeyboardArrowUp className={styles.buttonIcon} />
            </Box>
            <Box
              className={styles.scoreIndicator}
              color="var(--bosscat-green-600)"
              right="-1%"
            >
              <Typography className={styles.nextArtTitle}>New</Typography>
              <KeyboardArrowUp className={styles.buttonIcon} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PropertyConditions
