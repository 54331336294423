import { authTypes } from 'ducks/auth/types'
import { Job } from 'ducks/types'
import { JobActions, JobType, jobTypes } from './types'

const initialState: JobType = null

const reducer = (state = initialState, action: JobActions): JobType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case jobTypes.SET_JOB: {
      return payload as Job
    }
    case jobTypes.CREATE_JOB: {
      return payload as Job
    }

    default:
      return state
  }
}

export default reducer
