import { FC } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { isEmpty } from '../../../../../helpers'
import { Typography, Link, Box } from '../../..'
import useStyles from './styles'
import { BottomLinkProps } from './types'

const BottomLink: FC<BottomLinkProps> = ({
  question,
  linkText,
  linkTo,
  onClick,
  href,
  target,
  icon,
  className = ""
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.center} ${className}`}>
      <Typography className={classes.question} variant="body2">
        {question}
      </Typography>
      {linkTo ? (
        <RouteLink className={classes.actionLink} to={linkTo}>
          <Box display="flex" alignItems="center" width="fit-content">
            {linkText}
            {icon ?? null}
          </Box>
        </RouteLink>
      ) : (
        <Link
          className={classes.actionLink}
          href={href || ''}
          target={target || ''}
          onClick={(event) => {
            if (onClick) onClick()
            else if (isEmpty(href)) event.preventDefault()
          }}
        >
          {linkText}
          {icon ?? null}
        </Link>
      )}
    </div>
  )
}

export default BottomLink
