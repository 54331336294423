import React, { useEffect, useRef, useState } from 'react'
import useStyles from '../styles'
import { Typography } from '@material-ui/core'
import { icons } from 'assets/index'
import { useSelector } from 'react-redux'
import { getTerritories } from 'ducks/selectors'

type Props = {}

export const CollapseQuestion = ({ question, answer }: { question: string, answer: React.ReactNode }) => {
    const [active, setActive] = useState(false);


    const classes = useStyles()
    const contentRef = useRef(null);

    useEffect(() => {
        const contentCurrent = contentRef?.current as any;
        contentCurrent.style.maxHeight = active
            ? `${contentCurrent?.scrollHeight}px`
            : "0px";
    }, [contentRef, active]);

    const toggleAccordion = () => {
        setActive(prevActive => !prevActive);
    };

    return (
        <div className={classes.bottomBorder}>
            <div onClick={toggleAccordion} className={classes.questionRow}>
                <h4 className={classes.question}>
                    {question}
                </h4>
                <icons.ChevronLeft style={{ transition: "all .3s ease-in-out", rotate: !active ? "-90deg" : "90deg", color: "#444" }} />
            </div>
            <div
                ref={contentRef}
                className={active ? `${classes.answerActive} ${classes.answer}` : `${classes.answer}`}
            >
                {answer}
            </div>
        </div>
    );
}


const Banner3 = (props: Props) => {
    const classes = useStyles()
    const territories = useSelector(getTerritories())


    const serviceCities = territories
        ?.filter((t) => t.title !== 'In-Area')
        ?.sort((a, b) =>
            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
        .filter((terr) => terr.serviceable)
        .map((terr) => {
            return (
                `${terr.title}`
            )
        }).join(', ')

    const serviceCitiesJoinedAndLastItemWithAnd = serviceCities.split(', ').join(', ').replace(/,([^,]*)$/, ' and$1')

    return (
        <div className={`${classes.maxWidth} ${classes.banner3_Container}`}>
            <div className={classes.banner3_Block1}>
                <Typography className={classes.banner3_Title}>
                    BOSSCAT Estimates
                </Typography>
                <Typography className={classes.banner3_Text}>
                    Wondering how much those needed repairs might set you back?  With BOSSCAT, there's no need to guess or call a contractor from every trade to come give you a quote. Simply fill out the form above and BOSSCAT will take the items called out in your inspection summary and give you a localized estimate on the cost of repair.
                </Typography>
            </div>
            <div>
                <Typography className={classes.banner3_Title}>
                    Frequently Asked Questions
                </Typography>
                <CollapseQuestion question='How much does a repair estimate cost?' answer={<Typography>Because you used a Pillar To Post home inspector, your estimate is free!</Typography>} />
                <CollapseQuestion question='When and how will I receive my estimate?' answer={<Typography>Your estimate will be delivered to you by email within 24 hours.</Typography>} />
                <CollapseQuestion question='Is there anything that cannot be priced from the inspection report?' answer={<Typography>The completeness of your estimate depends entirely on the depth of your inspection report (pictures, quantities, etc.).</Typography>} />
                <CollapseQuestion question='Can you complete the repairs too?' answer={<Typography>{`For customers in BOSSCAT's repair service areas, BOSSCAT can perform the repairs at the price quoted in the estimate. Current repair service areas are: ${serviceCitiesJoinedAndLastItemWithAnd}.`}</Typography>} />
            </div>
        </div>
    )
}

export default Banner3