/* eslint-disable no-useless-escape */
import {
  fall,
  obAgent,
  obHomeowner,
  obInstitutionalClient,
  spring,
  summer,
  winter,
} from 'assets'
import { PerkType } from 'components/pages/Desktop/OrderMaintenance/Dashboard/PerksModal/PerkBox/types'
import { isWeekend } from 'date-fns'
import {
  capitalize as capitalizeLodash,
  isEmpty as isEmptyLodash,
  reduce,
} from 'lodash'
import moment from 'moment'
import {
  BOSSCAT_PHONE_NUMBER,
  CONTACT_ROLE,
  ITEM_CATEGORY,
  USER_TYPE,
} from './constants'
import { UpdateAttribute, UpdateRequest } from 'api/types'

export * from './category'
export * from './constants'
export * from './files'
export * from './filesUrlParser'
export * as history from './history'
export * from './optionLists'
export * from './parsePhone'
export * from './states'

/** MASKS *******************************/
export const PHONE_MASK_INPUT = [
  /[1-9]/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PARCEL_NUMBER_MASK = /^(\d{1})(\d{1})(\d{1})(\d{3})(\d{3})$/
export const PHONE_MASK_REGEXP = /^([0-9]+(-[0-9]+)+)$/
export const PHONE_MASK_REGEXP_NO_SCOPE = /^([0-9]+([0-9]+)+)$/
/****************************************/

/** EMAILS ******************************/
export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/****************************************/

/** PASSWORDS ***************************/
export const containsLowercase = (str: string): boolean => /[a-z]/.test(str)
export const containsUppercase = (str: string): boolean => /[A-Z]/.test(str)
export const containsNumber = (str: string): boolean => /\d/.test(str)
export const containsLetter = (str: string): boolean => /[A-Za-z]/.test(str)
export const containsNumberLetter = (str: string): boolean =>
  containsNumber(str) && containsLetter(str)

export const validatePassword = (psw: string): boolean =>
  psw.length >= 6 &&
  containsUppercase(psw) &&
  containsLowercase(psw) &&
  containsNumberLetter(psw)
/****************************************/

/** OBJECTS *****************************/
export const isEmpty = (object: any): boolean => {
  if (typeof object === 'string') {
    return isEmptyLodash(object.trim())
  }
  return isEmptyLodash(object)
}

export const validatePhone = (phone: string): boolean => {
  return parseInt(phone?.replaceAll('-', ''))?.toString().length === 10 || false
}
/****************************************/

/** STRINGS *****************************/
export const capitalize = (string?: string | undefined): string => {
  return capitalizeLodash(string)
}

export const formatPhone = (
  phone: string | number | null | undefined
): string => {
  if (phone) {
    const phoneCopy = phone.toString()
    if (phoneCopy.length === 10) {
      return phoneCopy
        .substring(0, 3)
        .concat('-')
        .concat(phoneCopy.substring(3, 6))
        .concat('-')
        .concat(phoneCopy.substring(6))
    }
  }
  return ''
}

export const concatSeparator = (
  fstStr: string,
  sndStr: string,
  separator: string
): string => {
  if (!fstStr) return ''
  return fstStr?.concat(
    fstStr && !isEmpty(fstStr) && sndStr && !isEmpty(sndStr) ? separator : '',
    sndStr || ''
  )
}

export const errorTextTryingTo = (text: string): string => {
  if (!isEmpty(text))
    return 'An error occurred trying to '
      .concat(text)
      .concat(". We'll fix it soon!")
  return "An unexpected error occurred. We'll fix it soon!"
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
/****************************************/

/* DATES ********************************/
export const formatTimestamp = (
  timestamp: number | null | undefined,
  format: string
): string => {
  if (timestamp !== null && timestamp !== undefined) {
    return moment.unix(timestamp).format(format)
  }
  return ''
}

export const formatTimestampDate = (timestamp: number): Date => {
  if (timestamp !== null) {
    return new Date(timestamp * 1000)
  }
  return new Date()
}

export const formatDateToUnixTimestamp = (date?: Date): number => {
  return moment(date).unix()
}

export const dateFormatString = (
  date: string | Date,
  isString = true
): string => {
  let result = ''
  let toFormat

  if (date instanceof moment) toFormat = date
  else if (date !== null) toFormat = isString ? moment(date) : date

  if (moment(date).isValid()) {
    result = moment(toFormat).format('MM/DD/YYYY')
  }
  return result
}
/****************************************/

/** NUMBERS *****************************/
/* eslint-disable */
export const round = (value: number, exp?: number) => {
  if (value?.toString() === '0') return '0'
  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(exp || 2)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: exp || 2,
  })
  return formatter.format(Number(roundedPrice))
}
/* eslint-disable */
export const roundWithoutCents = (value: number) => {
  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(0)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  })
  return formatter.format(Number(roundedPrice))
}

/****************************************/

export const privacyLink = 'https://www.bosscathome.com/privacy/'
export const workOrdersTerms = 'https://bosscathome.com/work-order-terms/'

export const getHeaderStatus = (status: string): string => {
  const statusMap = {
    APPROVED: 'You’re all set!',
    APPROVED__SECONDARY: 'You’re all set!',
    PENDING_FURTHER: 'Thank you!',
    MISSING_DETAILS: 'Awaiting further details',
  } as { [key: string]: string }
  return statusMap[status] as string
}

export const getTextStatus = (status: string): string => {
  const statusMap = {
    APPROVED: `A BOSSCAT Success Manager will contact you soon to schedule the requested repairs. If you have any questions or need assitance, please call us at ${BOSSCAT_PHONE_NUMBER}.`,
    APPROVED__SECONDARY: `Your approval has been processed successfully. A BOSSCAT Success Manager will be in touch throughout the repair process. If you have any questions or need assitance, please call us at ${BOSSCAT_PHONE_NUMBER}.`,
    MISSING_DETAILS:
      'Please try submitting the approval again when you have the required approver contact information.',
    PENDING_FURTHER:
      'We’ve sent a copy of this request to the contact(s) provided. We’ll let you know when it’s been approved.',
  } as { [key: string]: string }
  return statusMap[status] as string
}

export const isPercentageOf = (amount: number, total: number): number => {
  return Math.floor((amount / total) * 100)
}

export const isRoundPercentageOf = (amount: number, total: number): number => {
  return Math.round((amount / total) * 100)
}

export const getTextGuarantee = (): string => {
  return 'As part of the repair process with BOSSCAT, this property will carry a 1 year workmanship guarantee for all repairs addressed by BOSSCAT, unless noted otherwise on invoice. This guarantee is transferable based upon the property, rather than ownership. This invoice serves as a transference of guarantee through closing.'
}

export const getTextUrl = (): string => {
  return `Bosscat :: https://www.bosscathome.com/ :: ${BOSSCAT_PHONE_NUMBER} :: customercare@bosscathome.com`
}

export const flattenedAddress = (place: any) => {
  const city = place.address_components.find((i: any) => {
    return i.types.includes('locality')
  })?.long_name
  const sublocality = place.address_components.find((i: any) => {
    return i.types.includes('sublocality')
  })?.long_name
  const state = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_1')
  )?.short_name
  const zipCode = place.address_components.find((i: any) =>
    i.types.includes('postal_code')
  )?.long_name
  const street_number = place.address_components.find((i: any) =>
    i.types.includes('street_number')
  )?.long_name
  const line_1 = place.address_components.find((i: any) =>
    i.types.includes('route')
  )?.long_name
  const administrative_area_level_2 = place.address_components.find((i: any) =>
    i.types.includes('administrative_area_level_2')
  )?.long_name
  const country = place.address_components.find((i: any) =>
    i.types.includes('country')
  )?.long_name
  const fullAddress = place.formatted_address
  const googleUrl = place.url
  return {
    city: city || sublocality,
    state,
    zipCode,
    line_1,
    street_number,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    formatted_address: place.formatted_address,
    county: administrative_area_level_2,
    country,
    fullAddress,
    googleUrl,
  }
}

export const formatParams = (params: any) => {
  if (!params) return ''
  const paramsFormated = Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => {
      const value = encodeURIComponent(params[key])
      return value ? `${key}=${value}` : ''
    })
    .join('&')
  return paramsFormated
}

export const sortItemsByCategories = (items: any): any => {
  const sortBy = Object.keys(ITEM_CATEGORY)
  const res = Object.entries(items).sort(
    (a: any, b: any) => sortBy.indexOf(a[0]) - sortBy.indexOf(b[0])
  )
  return Object.fromEntries(res)
}

export const addDays = (date: Date | number, days = 1, exp?: number) => {
  if (!date) return null
  const result = new Date(exp ? (date as number) * exp : date)
  result.setDate(result.getDate() + days)
  return result
}

export const updateObjectByPath: any = ({
  obj,
  path,
  value,
}: {
  obj: any
  path: string
  value: any
}) => {
  const [head, ...rest] = path.split('/')

  return {
    ...obj,
    [head]: rest.length
      ? updateObjectByPath({ obj: obj[head], path: rest.join('/'), value })
      : value,
  }
}

export const appendHttpToUrl = (url: string): string => {
  if (url.startsWith('http')) return url

  return `https://${url}`
}

export const getDifferenceBetweenObjects: any = (
  differences: any,
  acc: any = [],
  pathToConcat?: string,
  op?: 'replace' | 'remove' | 'add'
) => {
  return reduce(
    differences,
    (result: any, value: any, key: any) => {
      const concatedPath = pathToConcat ? `${pathToConcat}/${key}` : key
      if (typeof value === 'object') {
        const lastPath =
          concatedPath.split('/')[concatedPath.split('/').length - 1]
        return getDifferenceBetweenObjects(
          differences[lastPath],
          result,
          concatedPath,
          op || 'replace'
        )
      } else {
        if (!value) return result
        return result.concat({
          op: op || 'replace',
          path: `/${concatedPath}`,
          value: value,
        })
      }
    },
    [...acc]
  )
}

export const PERKS: PerkType[] = [
  {
    title: 'Spring',
    img: spring,
    backgroundColor: '#EDFDF6',
    bullets: [
      'Sink Trap Cleaning',
      'Validate Ceiling Fan Direction and Reverse as Needed',
      'Lubricate Exterior Doors',
      'Air Filter Replacement',
      'Home Health Assessment',
    ],
  },
  {
    title: 'Summer',
    img: summer,
    backgroundColor: '#FCFEF5',
    bullets: [
      'HVAC Light Maintenance ',
      'Exhaust Fan Cleaning',
      'Air Filter Replacement',
      'Home Health Assessment',
    ],
  },
  {
    title: 'Fall',
    img: fall,
    backgroundColor: '#FFFBF0',
    bullets: [
      'Smoke Detector & CO Check',
      'Dryer Vent Cleaning',
      'Gutter Cleaning',
      'Air Filter Replacement',
      'Home Health Assessment',
    ],
  },
  {
    title: 'Winter',
    img: winter,
    backgroundColor: '#FAFDFF',
    bullets: [
      'HVAC Light Maintenance',
      'Water Heater Inspection',
      'Air Filter Replacement',
      'Home Health Assessment',
    ],
  },
]

export const roleOptions = [
  {
    title: `I'm a Homeowner/Homebuyer`,
    icon: obHomeowner,
    id: USER_TYPE.HOMEOWNER,
  },
  {
    title: `I represent a Homeowner/Homebuyer`,
    icon: obAgent,
    id: USER_TYPE.BROKER,
  },
  {
    title: `I'm an Institutional Investor`,
    icon: obInstitutionalClient,
    id: USER_TYPE.INSTITUTIONAL,
  },
]

export const agentOptions = [
  {
    label: 'Real Estate Agent/Broker',
    key: USER_TYPE.BROKER,
  },
  {
    label: 'Home Inspector',
    key: USER_TYPE.INSPECTOR,
  },
  {
    label: 'Closing Coordinator',
    key: USER_TYPE.CLOSING_COORDINATOR,
  },
  {
    label: 'Property Manager',
    key: USER_TYPE.PROPERTY_MANAGER,
  },
  {
    label: 'Other Real Estate Professional',
    key: USER_TYPE.OTHER,
  },
]

export const institutionalOptions = [
  {
    label: `SFR Property Owner`,
    key: 'SFR Property Owner',
  },
  {
    label: 'iBuyer',
    key: 'iBuyer',
  },
  {
    label: 'REO Asset Manager',
    key: 'REO Asset Manager',
  },
  {
    label: 'Property Manager',
    key: 'Property Manager',
  },
  {
    label: 'Other',
    key: 'Other',
  },
]

export const pathsToHideSideMenu = [
  '/p/estimates/',
  '/p/jobs/',
  '/p/invoices/',
  'p/payments/',
  '/p/maintenance/',
  '/p/shared-estimates/',
]

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS'
  }

  return 'unknown'
}

export function calcWeekends(startDate: Date, endDate: Date) {
  if (startDate > endDate) return []
  var date = startDate
  var dates = []

  while (date < endDate) {
    if (isWeekend(date)) dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export function getNextBusinessDayIfFriday(): Date | null {
  const today = new Date()
  const dayOfWeek = today.getDay()

  // In JavaScript, Friday is represented by 5
  if (dayOfWeek === 5) {
    // Add three days to the current date to get to Monday
    today.setDate(today.getDate() + 3)
    return today
  }

  return null
}

export const getHolidaysDate = () => {
  const holidays = [
    {
      date: '2024-01-01 00:00:00',
      name: "New Year's Day",
    },
    {
      date: '2024-01-15 00:00:00',
      name: 'Martin Luther King Jr. Day',
    },
    {
      date: '2024-02-19 00:00:00',
      name: "Washington's Birthday",
    },
    {
      date: '2024-05-27 00:00:00',
      name: 'Memorial Day',
    },
    {
      date: '2024-07-04 00:00:00',
      name: 'Independence Day',
    },
    {
      date: '2024-09-02 00:00:00',
      name: 'Labor Day',
    },
    {
      date: '2024-11-28 00:00:00',
      name: 'Thanksgiving Day',
    },
    {
      date: '2024-11-29 00:00:00',
      name: 'Day after Thanksgiving Day',
    },
    {
      date: '2024-12-24 00:00:00',
      name: 'Christmas Eve',
    },
    {
      date: '2024-12-25 00:00:00',
      name: 'Christmas Day',
    },
  ]
  return holidays.map((i) => new Date(i.date))
}

export enum RESOURCES_TYPES {
  TRASH_REMOVAL = 'Trash Removal',
  INSURANCE = 'Insurance',
  TITLE = 'Title',
  STAGING = 'Staging',
  PEST_CONTROL = 'Pest Control',
  LANDSCAPING = 'Landscaping',
  CLEANING = 'Cleaning',
  RESTORATION = 'Restoration',
  SOLAR = 'Solar',
  HOME_FURNISHING_AND_DECOUR = 'Home Furnishing & Decor',
  STORAGE = 'Storage',
  FURNITURE_ASSEMBLY = 'Furniture Assembly ',
  CABINETS = 'Cabinets',
  RENTAL_CAR = 'Rental Car',
  SPA = 'Spa',
  AUTO_WARRANTY = 'Auto Warranty',
  ROOFING_GUTTERS_AND_SIDING = 'Roofing, Gutters & Siding',
  PAINTING = 'Painting',
  HOME_SECURITY = 'Home Security',
  SMART_HOME = 'Smart Home',
  HOME_INSPECTION = 'Home Inspection',
  WINDOWS_AND_DOORS = 'Windows, Doors',
  OUTDOOR_LIVING = 'Outdoor Living',
  STORAGE_AND_ORGANIZATION = 'Storage & Organization',
  BLINDS_AND_WINDOW_TREATMENTS = 'Blinds and Window Treatments',
  RECREATION = 'Recreation',
  MOVING_SERVICES = 'Moving Services',
  PHOTOGRAPHY = 'Photography',
  HOME_WARRANTY = 'Home Warranty',
  INTERIOR_DESIGN = 'Interior Design',
  TV_INTERNET_VOICE_AND_MOBILE = 'TV, Internet, Voice & Mobile',
  HOME_CHARGING_SOLUTIONS = 'Home Charging Solutions',
  UTILITY_CONCIERGE_SERVICES = 'Utility Concierge Services',
}

export enum ROLES_TYPES {
  HOMEOWNER = 'Homeowners',
  CONTRACTOR = 'Contractor',
  REALTOR = 'Realtor',
  INVERSTOR = 'Investor',
  PROPERTY_MANAGER = 'Property Manager',
}

export const RESOURCES_TYPES_OPTIONS = [
  { key: 'All Services', value: 'All Services' },
  ...Object.keys(RESOURCES_TYPES).map((key) => ({
    key,
    value: RESOURCES_TYPES[key as keyof typeof RESOURCES_TYPES],
  })),
]

export const ROLES_OPTIONS = [
  { key: 'All Roles', value: 'All Roles' },
  ...Object.keys(ROLES_TYPES).map((key) => ({
    key,
    value: ROLES_TYPES[key as keyof typeof ROLES_TYPES],
  })),
]

export const companyRoles = [
  CONTACT_ROLE.LISTING_AGENT,
  CONTACT_ROLE.BUYERS_AGENT,
  CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR,
  CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
  CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
]

export const sellerAgentRoles = [
  CONTACT_ROLE.LISTING_AGENT,
  CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
  CONTACT_ROLE.INSPECTOR,
  CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
]

export const homeownerRoles = [
  CONTACT_ROLE.HOMEOWNER,
  CONTACT_ROLE.HOME_BUYER,
  CONTACT_ROLE.HOME_SELLER,
  CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
]

export const objectToUpdateRequest = (payload: any, prefix?: string) => {
  const request: UpdateRequest = []

  for (const attr in payload) {
    const isNullValue =
      payload[attr] === null || typeof payload[attr] === 'undefined'
    const item: UpdateAttribute = {
      op: !isNullValue ? 'add' : 'remove',
      path: prefix + attr,
    }
    if (!isNullValue) {
      item.value = payload[attr]
    }
    request.push(item)
  }

  return request
}
