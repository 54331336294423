import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '70vh'
    },
    container: {
      maxWidth: '300px',
      display:  'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem'
    },
    button: {
      width: 'fit-content',
      height: '42px',
      textTransform: 'none',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
      padding: '1rem .7rem',
      minWidth: '50%',
      display: 'flex',
      backgroundColor: 'transparent',
      fontSize: '16px',
      "@global": {
        ".MuiButton-label": {
          padding: '0',
        }
      },
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    addMemberButton: {
      border: '2px solid var(--bosscat-blue-600)',
      color: 'var(--bosscat-blue-600)',
      padding: '1.3rem 1.8rem',
      "@global": {/* 
          ".MuiButton-label": {
              display: 'flex',
              justifyContent: 'space-between'
          }, */
        '.MuiSvgIcon-root': {
          marginLeft: '.5rem',
          width: '1rem'
        }
      },
    },
    title: {
      fontFamily: 'LatoNormal'
    },
    subtitle: {
      fontFamily: 'LatoNormal'
    }
  })
)
