import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            width: "100%",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                paddingBottom: "80px"
            }
        },
        ServiceContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            rowGap: "16px",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid #EBECEF",
            boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                maxWidth: "95vw",
            }
        },
        IframeLoader: {
            width: "100%",
            height: "70%",
            position: "absolute",
            zIndex: 99,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down('md')]: {
                height: "100%",
            }
        },
        Address: {
            fontSize: "24px",
            fontFamily: "LatoBold",

            [theme.breakpoints.down('md')]: {
                display: "flex",
            }
        },
        AddressIcon: {
            marginTop: "4px",
            marginRight: "4px"
        },
        Icon: {
            color: "var(--bosscat-blue-600)",
            width: "18px",
            height: "18px",
            marginRight: "10px"
        },
        City: {
            color: "#494A5A",
            fontSize: "20px",
            fontFamily: "LatoNormal",
            [theme.breakpoints.down('md')]: {
                marginBottom: "8px",
            }
        },
        Name: {
            fontFamily: "LatoNormal"
        },
        DateIcon: {
            width: "48px",
            height: "48px",
            marginRight: "8px"
        },
        ServiceWorker: {
            display: "flex",
            flexDirection: "column"
        },
        ServiceLabel: {
            fontSize: "18px"
        },
        DateValue: {
            fontSize: "16px",
            color: "#494A5A",
            fontFamily: "LatoNormal"
        },
        Support: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            backgroundColor: "#FAFBFF",
            padding: "16px",
            color: "var(--bosscat-blue-600)",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                filter: "drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))",
            },
            cursor: "pointer",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        OpenDocs: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F9FAFA",
            border: "2px solid #EBECEF",
            borderRadius: "8px",
            padding: "16px",
            width: "100%"
        },
        OpenDocsLeft: {
            display: "flex",
            alignItems: "center",
            columnGap: "9.5px"
        },
        OpenDocsIcon: {
            color: "#1A2A55"
        },
        OpenDocsText: {
            fontFamily: "LatoBold"
        },
        Arrow: {
            transform: "rotate(45deg)",
            color: "var(--bosscat-blue-600)",
        },
        FixedMobile: {
            zIndex: 99,
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            padding: "8px 16px",
            display: "grid",
            placeItems: "center",
            boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25)"
        },
        DocMissingIcon: {
            width: "14px"
        },
        DocMissingAlert: {
            display: "flex",
            alignItems: "center",
            color: "var(--hardware-haze-600)",
            marginBottom: "16px",
            columnGap: "4px",
        },
        bold: {
            fontFamily: 'Lato'
        },
        normal: {
            fontFamily: 'LatoNormal'
        },
        ServiceHistory: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
            margin: "24px 0"
        },
        ServiceHistoryText: {
            color: "#72748D",
            whiteSpace: "nowrap",
            fontFamily: "LatoNormal",
            
        },
        ServiceHistoryContainer: {
            width: '100%'
        },
        ServiceHistoryLine: {
            width: "100%",
            height: "2px",
            backgroundColor: "#72748D"
        },
        Button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--bosscat-blue-600)",
            borderRadius: "8px",
            padding: "16px",
            color: "#fff",
            minWidth: "140px",
            cursor: "pointer",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                fontSize: "18px"
            }
        },
        LeftSide: {
            // width: "50%"
        }
    }))
