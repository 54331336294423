/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newEstimateActions } from '../../../../ducks/actions'
import { NewEstimateValue } from '../../../../ducks/newEstimate/types'
import {
  getEstimatesServices,
  getNewEstimateStep,
  getNewEstimateValue,
  getNewEstimateValues,
  getPropertyDetail,
  getUser,
  getUserPrimaryEmail,
  isAuthorized,
} from '../../../../ducks/selectors'
import { HashWrapper, Navigation, PrivateLoader } from '../../../templates'
import useStyles from './styles'
import { Box, Button, Stepper, Typography } from '../../../UI'
import { contact, icons } from '../../../../assets'
import { StepperProps } from '../../../UI/CustomUI/organisms/Stepper/types'

import { Location, Assignment, Payment } from './Steps'
import Account from './Account'
import {
  BOSSCAT_PHONE_NUMBER,
  CONTACT_ROLE,
  USER_TYPE,
  history,
} from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'
import Project from './Steps/Assignment/Inspection/Project'
import Contact from './Steps/Assignment/Inspection/Contact'
import UserInformation from './Steps/UserInformation'
import { ArrowBack } from '@material-ui/icons'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'
import {
  TrackGoogleAnalyticsEvent,
  getSessionId,
} from 'helpers/google-analytics'

const Estimate = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()
  const { usePush } = history
  const query = history.useQuery()
  const push = usePush()

  const fromUrlParam = query.get('from') || ''

  const authorized = useSelector(isAuthorized)
  const activeStep = useSelector(getNewEstimateStep())
  const [pageLoading, setPageLoading] = useState(true)
  const user = useSelector(getUser)
  const userEmail = useSelector(getUserPrimaryEmail)
  const services = useSelector(getEstimatesServices)
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const nar = useSelector(getNewEstimateValue('isNar'))
  const propertyDetail = useSelector(getPropertyDetail())
  const { address, affiliate: affiliateFromRedux } = useSelector(
    getNewEstimateValues()
  )
  const activeStepProgress = useSelector(
    getNewEstimateValue('activeStepProgress')
  )

  const newEstimate = useSelector(getNewEstimateValues())
  
  const totalStepProgress = useSelector(
    getNewEstimateValue('totalStepProgress')
  )
  const contactInEstimate = estimate?.properties?.contacts?.find(
    (e: any) => e.email === userEmail
  )

  const isNar =
    user?.nar || !!new URLSearchParams(window.location.search).get('nar') || nar
  const affiliate =
    user?.affiliate ||
    (new URLSearchParams(window.location.search).get('affiliate') ?? '') ||
    affiliateFromRedux

  useEffect(() => {
    setPageLoading(false)
  }, [activeStep])

  useEffect(() => {
    /* dispatch(
      newEstimateActions.setNewEstimateStep(authorized ? (estimate?.id ? activeStep : -1) : activeStep > 0 ? activeStep : -1)
    ) */
    if (authorized) {
      // dispatch(newEstimateActions.setNewEstimateValue({ attr: 'userIsloggedIn', value: authorized }))
      if (estimate.createdBy !== user.id) {
        // If the user id doesn't match with the estimate id we clean the estimate since was created as a public user
        dispatch(newEstimateActions.eraseNewEstimate())
      }
    }

    // GA4 events
    if (!authorized) {
      TrackGoogleAnalyticsEvent({
        category: 'Estimate',
        action: 'anonymous_estimate_visit',
        options: {
          session_id: getSessionId(),
          timestamp: new Date().toISOString(),
        },
      })
    } else {
      TrackGoogleAnalyticsEvent({
        category: 'Estimate',
        action: 'authenticated_estimate_visit',
        options: {
          email: user.email[0].email,
          timestamp: new Date().toISOString(),
        },
      })
    }
  }, [authorized])

  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          isNar: clientType === CONTACT_ROLE.LISTING_AGENT ? isNar : false,
          affiliate: clientType === CONTACT_ROLE.LISTING_AGENT ? affiliate : '',
        },
      })
    )

    if (authorized) {
      dispatch(
        newEstimateActions.setNewEstimateValues({
          attrs: {
            clientType:
              user.clientType === USER_TYPE.HOMEOWNER
                ? user.clientType
                : user.clientType === USER_TYPE.INSTITUTIONAL
                ? CONTACT_ROLE.INSTITUTIONAL_INVESTOR
                : CONTACT_ROLE.LISTING_AGENT,
            affiliate,
            isNar,
          },
        })
      )
    }
  }, [user, affiliate, clientType])

  const steps: StepperProps['steps'] = [
    {
      icon: <icons.Assignment />,
      content: <Assignment />,
      disableBack: true,
    },
  ]

  if (clientType !== CONTACT_ROLE.INSTITUTIONAL_INVESTOR) {
    steps.push({
      content: <Project />,
      disableBack: true,
    })
  }

  if (
    clientType === CONTACT_ROLE.LISTING_AGENT &&
    newEstimate.represent !== CONTACT_ROLE.HOMEOWNER
  ) {
    steps.push({
      content: <Contact />,
      disableBack: true,
    })
  }

  steps.push(
    {
      content: <UserInformation />,
      disableBack: true,
    },
    {
      content: <Payment />,
    }
  )

  if (services?.length === 1 && services[0]?.cost === 0) {
    // if we have only one service and with price 0 we avoid the payment screen
    steps.pop()
  }

  const handleBackClick = () => {
    dispatch(newEstimateActions.cleanNewEstimate())
    push(`p/dashboard/${propertyDetail.id}`)
  }

  const showBackToReport =
    propertyDetail?.id &&
    propertyDetail?.address?.fullAddress === address?.fullAddress &&
    fromUrlParam === 'property'

  return (
    <HashWrapper>
      <Navigation>
        <Box className={classes.root}>
          <Box className={classes.content}>
            {showBackToReport && (
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                className={classes.backButton}
                onClick={handleBackClick}
              >
                <Typography
                  className={classes.lgFont}
                  style={{ textTransform: 'none' }}
                >
                  Back to Report
                </Typography>
              </Button>
            )}
            {activeStep === -1 ? (
              <Account />
            ) : (
              <PrivateLoader loading={pageLoading}>
                <Stepper
                  withLogo={!authorized}
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStepAction={(step: number) =>
                    dispatch(newEstimateActions.setNewEstimateStep(step))
                  }
                  saveNewValueAction={(value: NewEstimateValue) =>
                    dispatch(newEstimateActions.setNewEstimateValue(value))
                  }
                  fixButtons
                  showHeader={false}
                  showProgress
                  activeStepProgress={activeStepProgress}
                  totalStepProgress={totalStepProgress}
                  className={classes.stepper}
                />
              </PrivateLoader>
            )}
          </Box>
          {!xsScreen && (
            <Box className={classes.footer}>
              <Box className={classes.footerContent}>
                <Typography variant="body2">
                  Questions? Speak with a live Customer Care Professional
                </Typography>
                <Typography variant="body2">
                  <a href={`tel:${BOSSCAT_PHONE_NUMBER}`}>
                    {BOSSCAT_PHONE_NUMBER}
                  </a>{' '}
                  M-F, 8:00am - 6:00pm EST
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Navigation>
    </HashWrapper>
  )
}

export default Estimate
