import { Image, Link, Page, Path, Svg, Text, View } from '@react-pdf/renderer'
import styles from './style'
import { agentFooter, narPdfBanner, pdfCouple, trustpilot, trustpilotColumn } from 'assets/index'

const Page6Agent = () => {
  return (
    <Page size="A4" wrap>
      <View style={{ ...styles.page6 }}>
        <View style={{ ...styles.page6Header }}>
          <Image src={pdfCouple} style={{ width: '100%' }} />
        </View>
        <View
          style={{
            ...styles.page6Content,
            ...styles.page6ContentAgent,
            ...styles.alignCenter,
          }}
        >
          <View
            style={{
              ...styles.row,
              ...styles.gap4,
            }}
          >
            <View style={{ ...styles.w60 }}>
              <Text
                style={{
                  ...styles.blue800,
                  ...styles.latoNormal,
                  ...styles.font25,
                }}
              >
                TRUSTED BY MORE THAN
              </Text>
              <Text
                style={{
                  ...styles.blue800,
                  ...styles.latoNormal,
                  ...styles.font25,
                }}
              >
                <Text
                  style={{
                    ...styles.blue600,
                    ...styles.latoNormal,
                    ...styles.font25,
                  }}
                >
                  {`100,000 `}
                </Text>
                HOMEOWNERS,
              </Text>
              <Text
                style={{
                  ...styles.blue800,
                  ...styles.latoNormal,
                  ...styles.font25,
                }}
              >
                AND REAL ESTATE
              </Text>
              <Text
                style={{
                  ...styles.blue800,
                  ...styles.latoNormal,
                  ...styles.font25,
                  ...styles.mb4,
                }}
              >
                PROFESSIONALS
              </Text>
              <Text
                style={{
                  ...styles.latoNormal,
                  ...styles.blue800,
                  ...styles.fontSmall,
                }}
              >
                {`We take great pride in offering 
                nationwide services to Homeowners and
                Realtors, providing pre-list renovations, 
                inspection repairs, and on-demand 
                improvements.`}
              </Text>


            </View>
            <View style={{ ...styles.w40, ...styles.ml2 }}>
              <Image
                src={narPdfBanner}
                style={{
                  width: '180px',
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          ...styles.page6Footer,
          ...styles.backgroundBlue800,
          ...styles.row,
          ...styles.w100,
          ...styles.spaceBetween,
          ...styles.alignCenter,

        }}
      >
        <View style={{ padding: '20px 48px 48px 48px' }}>
          <Text
            style={{
              ...styles.white,
              ...styles.nextArtBold,
              ...styles.fontLarge,
              marginTop: '16px',
            }}
          >
            OUR CUSTOMERS LOVE BOSSCAT…
          </Text>
          <View style={{
            ...styles.row,
            ...styles.alignCenter
          }}>
            <View style={{ ...styles.w80 }}>
              <View>
                <Text
                  style={{
                    ...styles.white,
                    ...styles.latoNormal,
                    ...styles.mh2,
                    ...styles.fontSmall,
                  }}
                >
                  {` “I have received multiple quotes from BOSSCAT. All of them
            have been affordable for the buyer and the seller. When my 
            clients used BOSSCAT, the work was complete ahead of 
            schedule and with great craftsmanship.”`}
                </Text>
                <Text
                  style={{
                    ...styles.blue600,
                    ...styles.latoItalicBold,
                    ...styles.fontSmall,
                  }}
                >
                  Damon Jordan, Metro Brokers
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    ...styles.white,
                    ...styles.latoNormal,
                    ...styles.mh2,
                    ...styles.fontSmall,
                  }}
                >
                  {` "Excellent service on repairs needed on my home to prep for
              sale. They were able to get out within a day of approving the
              estimate as promised. Work was completed quickly and
              professionally. Highly recommend!"`}
                </Text>
                <Text
                  style={{
                    ...styles.blue600,
                    ...styles.latoItalicBold,
                    ...styles.fontSmall,
                  }}
                >
                  Deborah Cheek, Homeowner
                </Text>
              </View>
            </View>
            <Image
              src={trustpilotColumn}
              style={{
                width: '126px',
                height: '73px'
              }}
            />
          </View>
        </View>
      </View>
    </Page>
  )
}

export default Page6Agent
