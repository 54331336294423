import { ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'

import { formatTimestamp } from '../../../../../../helpers'
import { StatusHeaderProps } from './types'
import { useIsXsScreen } from '../../../../../../hooks'

import useStyles from './styles'

const StatusHeader = ({
    startedOn,
    completedOn,
    publicId,
    status,
    expectedCompletedOn,
    createdOn
}: StatusHeaderProps): ReactElement => {
    const classes = useStyles()
    const [xsScreen] = useIsXsScreen()

    let estimatedLabel = completedOn ? 'Completed' : 'Estimated Completion'
    let estimatedValue = formatTimestamp(completedOn || expectedCompletedOn, 'MM/DD/YYYY')

    if (!startedOn) {
        estimatedLabel = 'Approved'
        estimatedValue = formatTimestamp(createdOn, 'MM/DD/YYYY')
    }

    return (
        <Box className={classes.headerRow}>
            <Box className={xsScreen ? classes.column : classes.headerRow}>
                {startedOn &&
                    <Box className={classes.headerItem}>
                        <Typography variant='body2' className={classes.headerTextBold}> Started: </Typography>
                        <Typography variant='body2' className={classes.headerText}>{formatTimestamp(startedOn, 'MM/DD/YYYY')}</Typography>
                    </Box>}
                {(estimatedValue || !startedOn) &&
                    <Box className={classes.headerItem}>
                        <Typography variant='body2' className={classes.headerTextBold}>  {estimatedLabel}: </Typography>
                        <Typography variant='body2' className={classes.headerText}> {estimatedValue} </Typography>
                    </Box>}
            </Box>
            {!xsScreen && <div className={classes.dot} />}
            {!xsScreen && <Box className={classes.headerItem} style={{ color: 'var(--bosscat-blue-600)' }}>
                <Box className={classes.estimateBox}>
                    <Typography variant='body2' className={classes.estimateText}> {`Estimate #${publicId}`} </Typography>
                </Box>
            </Box>}
        </Box>
    )
}

export default StatusHeader
