import { PriceItem, Territory } from 'ducks/types'
import { territoriesTypes, TerritoriesActions } from './types'

const actions = {
  fetchTerritories: (
    callback?: (succ: boolean) => void,
    payload?: null | { serviceable: boolean } | undefined
  ): TerritoriesActions => ({
    type: territoriesTypes.FETCH_TERRITORIES,
    payload,
    callback,
  }),
  setTerritories: (payload: Territory[]): TerritoriesActions => ({
    type: territoriesTypes.SET_TERRITORIES,
    payload,
  }),
  fetchTerritory: (
    payload: string | { zipCode: string; source?: string },
    callback?: (succ: boolean, terr?: Territory) => void
  ): TerritoriesActions => ({
    type: territoriesTypes.FETCH_TERRITORY,
    payload,
    callback,
  }),
  setTerritory: (payload: Territory): TerritoriesActions => ({
    type: territoriesTypes.SET_TERRITORY,
    payload,
  }),
  fetchRepairItems: (
    payload: { territoryId: string; search?: string },
    callback?: (succ: boolean) => void
  ): TerritoriesActions => ({
    type: territoriesTypes.FETCH_REPAIR_ITEMS,
    payload,
    callback,
  }),
  setRepairItems: (payload: PriceItem[]): TerritoriesActions => ({
    type: territoriesTypes.SET_REPAIR_ITEMS,
    payload,
  }),
}

export default actions
