import { Button, Grid, Typography } from '@material-ui/core'
import { FC, useEffect } from 'react'
import useStyles from '../styles'
import { TextFieldLabel } from 'components/UI'
import { useStepperContext } from 'hooks/useStepperContext'
import { useSelector } from 'react-redux'
import { getApprovalValues, getUser } from 'ducks/selectors'
import { PHONE_MASK_INPUT } from 'helpers/index'

interface VerifyContactProps {}

const VerifyContact: FC<VerifyContactProps> = () => {
  const styles = useStyles()

  const { setShowWarnings, setCanGoForward, showWarnings, saveNewValue } =
    useStepperContext()

  const { homeowner } = useSelector(getApprovalValues())

  return (
    <Grid container style={{ gap: '8px' }} direction="column">
      <Grid container style={{ gap: '4px' }} direction="column">
        <Typography className={styles.title} style={{ marginBottom: 0 }}>
          📑 verify contact information
        </Typography>
        <Typography className={styles.greyTitle}>
          This information will be used to populate the service agreement.
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextFieldLabel
            label="Name"
            labelClasses={styles.latoPrimaryText}
            value={homeowner?.fullName || ''}
            error={showWarnings && !homeowner?.fullName}
            onChange={(e) => {
              saveNewValue('homeowner', {
                ...homeowner,
                fullName: e.target.value,
              })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldLabel
            label="Mobile Phone"
            labelClasses={styles.latoPrimaryText}
            value={homeowner?.phone || ''}
            error={showWarnings && !homeowner?.phone}
            mask={PHONE_MASK_INPUT}
            onChange={(e) => {
              saveNewValue('homeowner', { ...homeowner, phone: e.target.value })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VerifyContact
