import axios from '../axios'
import { ApiController } from './types'

const base = '/discoveries'

const apiController: ApiController = {
  sendDiscovery: async (request) =>
    await axios.post(base, request).then((response) => response.data),
}

export default apiController
