import { ConditionType } from 'ducks/properties/types'
import storeInfo from 'ducks/store'
import { PropertyDetail } from 'ducks/types'

export const getPropertyDetail = () => (): PropertyDetail => {
  const { propertyDetail } = storeInfo.store.getState()
  return propertyDetail
}

export const getPropertyId = () => (): string => {
  const { propertyDetail: { id } } = storeInfo.store.getState()
  return id
}

export const getPropertyInquiredItems = () => (): string[] => {
  const { propertyDetail: { inquiredItems } } = storeInfo.store.getState()
  return inquiredItems || []
}
