import { FC, useEffect, useState } from 'react'
import { Container, Box, TitleFirst, LinearProgress, Typography } from '../../../UI'
import useStyles from './styles'
import { LoaderProps } from '../types'

const RegisterLoader: FC<LoaderProps> = ({ children, loading = true, icon }) => {
  const classes = useStyles()

  const [progress, setProgress] = useState(3)

  useEffect(() => {
    setProgress(3)
  }, [loading])

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress < 25 ? 25 : prevProgress + 25)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [progress])

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.center}>
        <img src={icon} alt='' className={classes.bigIcon} />
      </Box>
      <Box className={classes.centerText}>
        <TitleFirst value='All ready ' black='to start!' />
        <Typography variant='body1' className={classes.subTitle}>Creating your account</Typography>
      </Box>
      <Box>
        <LinearProgress variant='determinate' value={progress} className={classes.loader} />
      </Box>
    </Container>
  )
}

export default RegisterLoader
