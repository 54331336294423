import { Box, Grid, Typography } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import OpacityIcon from '@material-ui/icons/Opacity'
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift'
import BookIcon from '@material-ui/icons/Book'
import useStyles from '../../../styles'
import PropertyInformationBox from '../PropertyInformation/PropertyInformationBox'
import IncentiveBox from './IncentiveBox'
import { useSelector } from 'react-redux'
import { getPropertyDetail } from 'ducks/selectors'
import { greenLeap, whiteNorthEast } from 'assets/index'

const GreenReport = () => {
  const styles = useStyles()

  const property = useSelector(getPropertyDetail())
  const {
    details: { greenReport },
  } = property

  const titles = [
    {
      icon: <FlashOnIcon className={styles.buttonIcon} />,
      titleColor: 'var(--repairs-yellow-700)',
    },
    {
      icon: <OpacityIcon className={styles.buttonIcon} />,
      titleColor: 'var(--bosscat-blue-600)',
    },
    {
      icon: <FilterTiltShiftIcon className={styles.buttonIcon} />,
      titleColor: 'var(--handyman-green-600)',
    },
    {
      icon: <BookIcon className={styles.buttonIcon} />,
      titleColor: 'var(--bosscat-grey-900)',
    },
  ]

  return (
    <Grid
      item
      container
      direction="column"
      spacing={3}
      style={{ marginTop: '0px' }}
    >
      <Grid item container justifyContent="space-between"></Grid>
      <Grid item container spacing={2}>
        <Grid item lg={3} md={6} sm={12} style={{ width: '100%' }}>
          <PropertyInformationBox
            level=""
            price={
              greenReport?.rebateAmount
                ? `$${greenReport?.rebateAmount?.toLocaleString() || 0}`
                : 'N/A'
            }
            title="REBATES"
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} style={{ width: '100%' }}>
          <PropertyInformationBox
            level=""
            price={
              greenReport?.taxIncentiveAmount
                ? `$${greenReport?.taxIncentiveAmount?.toLocaleString() || 0}`
                : 'N/A'
            }
            title="TAX INCENTIVES"
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} style={{ width: '100%' }}>
          <PropertyInformationBox
            level=""
            price={
              greenReport?.total
                ? `$${greenReport?.total?.toLocaleString() || 0}`
                : 'N/A'
            }
            title="TOTAL"
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} style={{ width: '100%' }}>
          <Box
            display="flex"
            style={{
              gap: '4px',
              height: '100%',
              minHeight: '140px',
              background:
                'linear-gradient(262deg, #01AA59 -0.14%, #016635 99.9%)',
            }}
            width="100%"
            padding={2}
            color="var(--bosscat-blue-600)"
            justifyContent="center"
            alignItems='center'
            className={styles.containerBox}
            position="relative"
          >
             <Typography
              className={styles.lgFont}
              style={{
                color: 'white',
                textTransform: 'none',
                zIndex: 10000,
              }}
              align='center'
            >
              Contact us for complete list of available <br /> Green Rebates & Tax Incentives
            </Typography>
            {/*<img
              src={whiteNorthEast}
              alt="north_east"
              style={{ width: '16px', height: '16px' }}
            /> */}

            <img
              src={greenLeap}
              alt="green leap"
              style={{
                width: '115px',
                height: '115px',
                position: 'absolute',
                left: '-30px',
                bottom: '-20px',
                opacity: 0.5,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography className={styles.lgFont} style={{ fontWeight: 900 }}>
            Summary
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={styles.lgFont} style={{ fontWeight: 400 }}>
            IncentiFind is the nation's go-to database for commercial real
            estate and home improvement incentives. We are pleased to provide
            the incentives available to your project located at{' '}
            {property?.address?.fullAddress || ' '}. This QUICK Summary report
            provides an overview of incentives offered in your property's area.
            Should your property incorporate any of the below practices or
            measures, you could be eligible to take advantage of cost-saving
            incentive programs.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography className={styles.lgFont} style={{ fontWeight: 900 }}>
            Incentives Breakdown
          </Typography>
        </Grid>
        <Grid item container>
          {greenReport?.categories?.map((category, index) => (
            <Grid item lg={3} md={6} sm={12}>
              <IncentiveBox
                icon={titles[index].icon}
                title={category.name}
                titleColor={titles[index].titleColor}
                sampleIncentive={category.sample}
                totalIncentiveCount={category.numPotentialIncentives}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GreenReport
