import { Insight, PropertyContact } from 'ducks/types'
import axios from '../axios'
import { ApiController } from './types'
import { UpdateRequest } from 'api/types'

const base = '/properties'

const apiController: ApiController = {
  getProperties: async (state, city, zipCode, archived) =>
    await axios
      .get(base, { params: { state, city, zipCode, archived } })
      .then((response) => response.data),

  getPropertyDetail: async (id: string) =>
    await axios.get(`${base}/${id}/details`).then((response) => response.data),

  getPropertyById: async (propertyId: string) =>
    await axios.get(`${base}/${propertyId}`).then((response) => response.data),

  createProperty: async (request) =>
    await axios.post(base, request).then((response) => response.data),

  updateProperty: async (propertyId: string, request) =>
    await axios
      .patch(`${base}/${propertyId}`, request)
      .then((response) => response.data),

  deleteProperty: async (propertyId: string) =>
    await axios
      .delete(`${base}/${propertyId}`)
      .then((response) => response.data),

  uploadProperties: async (request) =>
    await axios
      // .post(`${base}/upload`, request)
      .post(`${base}/upload`, request, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }) // file.type
      .then((response) => response.data),

  getPropertyContacts: async (propertyId: string) =>
    await axios
      .get(`${base}/${propertyId}/contacts`)
      .then((response) => response.data),

  createPropertyContact: async (
    propertyId: string,
    request: Partial<PropertyContact>
  ) =>
    await axios
      .post(`${base}/${propertyId}/contacts`, request)
      .then((response) => response.data),

  updatePropertyContact: async (
    propertyId: string,
    contactId: string,
    request: UpdateRequest
  ) =>
    await axios
      .patch(`${base}/${propertyId}/contacts/${contactId}`, request)
      .then((response) => response.data),

  deletePropertyContact: async (propertyId: string, contactId: string) =>
    await axios
      .delete(`${base}/${propertyId}/contacts/${contactId}`)
      .then((response) => response.data),

  updatePropertyProject: async (
    propertyId: string,
    projectName: string,
    request: Partial<Insight>
  ) =>
    await axios
      .put(`${base}/${propertyId}/projects/${projectName}`, request)
      .then((response) => response.data),

  getPropertyActivity: async (propertyId: string) =>
    await axios
      .get(`${base}/${propertyId}/activity`)
      .then((response) => response.data),

  getPropertiesActivity: async () =>
    await axios.get(`${base}/activity`).then((response) => response.data),

  requestPropertyRenovation: async (propertyId, request) =>
    await axios.post(`${base}/${propertyId}/renovations/request`, request).then((response) => response.data),

  requestPropertyRoom: async (propertyId, request) =>
    await axios.post(`${base}/${propertyId}/rooms/request`, request).then((response) => response.data),

  requestOrder: async (propertyId, item, request) =>
    await axios.post(`${base}/${propertyId}/projects/${item}/request`, request).then((response) => response.data)
}

export default apiController
