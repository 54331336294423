/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { Grid, Typography, Button, Box, Tooltip } from '../../../../../../UI'
import { useStepperContext } from '../../../../../../../hooks/useStepperContext'
import { CONTACT_ROLE, FILE_TYPE, history, ParseFilesUrl } from '../../../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNewEstimateStep,
  getNewEstimateSubstep,
  getNewEstimateValue,
  getNewEstimateValues,
  getPropertyDetail,
  isAuthorized,
} from '../../../../../../../ducks/selectors'
import { icons } from '../../../../../../../assets'
import useStyles from './styles'
import TabNavigation from '../../../../../../UI/CustomUI/organisms/TabNavigation'
import ListBuilder from './Tabs/ListBuilder/ListBuilder'
import SpeakWithUs from './Tabs/SpeakWithUs'
import UploadProjectList from './Tabs/UploadProjectList'
import {
  estimateItemsActions,
  filesActions,
  newEstimateActions,
} from '../../../../../../../ducks/actions'
import { useIsXsScreen } from '../../../../../../../hooks'
import { PrivateLoader, ScrollWrapper } from '../../../../../../templates'
import { Item, PunchlistFile } from '../../../../../../../ducks/types'
import env from '@beam-australia/react-env'

const Repairs: FC<{ goBack: () => void }> = ({ goBack }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()
  const serviceable = useSelector(getNewEstimateValue('serviceable'))
  const { setCanGoForward, setShowWarnings, goForward } = useStepperContext()
  const pushParams = history.usePushParams()

  const [loading, setLoading] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [loadingItems, setLoadingItems] = useState(false)
  const [loadingNotes, setLoadingNotes] = useState(false)

  const clientType = useSelector(getNewEstimateValue('clientType'))
  const isNotHomeowner = [CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)
  const isInstitutional = [CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType)

  const { clientNotes, items, repairListFiles } = useSelector(
    getNewEstimateValues()
  )
  const [imagesFiles, setImagesFiles] = useState<Array<File[]>>([[]])
  const [selectedTab, setSelectedTab] = useState(0)
  const activeSubStep = useSelector(getNewEstimateSubstep())
  const activeStep = useSelector(getNewEstimateStep())

  const FILES_URL = env('FILES_URL') ?? ''
  const authorized = useSelector(isAuthorized)

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  const TABS = [
    {
      label: 'List Builder',
      className: classes.tab,
      content: (
        <ListBuilder imageFiles={imagesFiles} setImagesFiles={setImagesFiles} />
      ),
    },
    {
      label: 'Upload Project List',
      className: classes.tab,
      content: <UploadProjectList />,
    },
    {
      label: 'Speak with Us',
      className: classes.tab,
      content: <SpeakWithUs />,
    },
  ]

  useEffect(
    () => setLoading(loadingImages || loadingItems || loadingNotes),
    [loadingImages, loadingItems, loadingNotes]
  )

  useEffect(() => {
    if (selectedTab === 0 && items && items.length > 0) {
      setCanGoForward(true)
    } else if (
      selectedTab === 1 &&
      repairListFiles &&
      repairListFiles.length > 0
    ) {
      setCanGoForward(true)
    } else {
      setCanGoForward(false)
    }
  }, [repairListFiles, items, selectedTab])

  const patchAndCreate = (items: Item[]) => {
    if (items) {
      if (items.some((i) => i.uploaded)) {
        dispatch(
          estimateItemsActions.updateEstimateItems(
            items?.filter(
              (i) =>
                i.uploaded &&
                !(
                  i.measurement === '' &&
                  i.inspectionReportNote === '' &&
                  i.imageFiles.length === 0
                )
            ),
            (success: boolean) => {
              if (success) {
                dispatch(
                  estimateItemsActions.createEstimateItems(
                    {
                      items: items.filter(
                        (i) =>
                          (!i.uploaded || i.uploaded === undefined) &&
                          !(
                            i.measurement === '' &&
                            i.inspectionReportNote === '' &&
                            i.imageFiles.length === 0
                          )
                      )
                    },
                    (_success: boolean) => {
                      if (_success) {
                        dispatch(
                          newEstimateActions.setNewEstimateValue({
                            attr: 'items',
                            value: items
                              .filter(
                                (i) =>
                                  !(
                                    i.measurement === '' &&
                                    i.inspectionReportNote === '' &&
                                    i.imageFiles.length === 0
                                  )
                              )
                              .map((i) => {
                                return {
                                  ...i,
                                  uploaded: true,
                                }
                              }),
                          })
                        )
                        setLoading(false)
                        setImagesFiles([[]])
                        !serviceable
                          ? goForward()
                          : dispatch(
                            newEstimateActions.setNewEstimateStep(
                              activeStep + 1
                            )
                          )
                      }
                    }
                  )
                )
              }
            }
          )
        )
      } else {
        dispatch(
          estimateItemsActions.createEstimateItems(
            {
              items: items.filter(
                (i) =>
                  !i.uploaded &&
                  !(
                    i.measurement === '' &&
                    i.inspectionReportNote === '' &&
                    i.imageFiles.length === 0
                  )
              )
            },
            (_success: boolean) => {
              if (_success) {
                dispatch(
                  newEstimateActions.setNewEstimateValue({
                    attr: 'items',
                    value: items
                      .filter(
                        (i) =>
                          !(
                            i.measurement === '' &&
                            i.inspectionReportNote === '' &&
                            i.imageFiles.length === 0
                          )
                      )
                      .map((i) => {
                        return {
                          ...i,
                          uploaded: true,
                        }
                      }),
                  })
                )
                setImagesFiles([[]])
                !serviceable
                  ? goForward()
                  : dispatch(
                    newEstimateActions.setNewEstimateStep(activeStep + 1)
                  )
                setLoading(false)
              }
            }
          )
        )
      }
    }
  }

  const handleUpload = (images: File[]) => {
    if (items) {
      if (images?.length === 0) {
        patchAndCreate(items)
      } else {
        setLoadingImages(true)
        dispatch(
          filesActions.uploadFiles(
            images as File[],
            (uploadSucc, fileNameList) => {
              if (
                uploadSucc &&
                fileNameList &&
                fileNameList.length === images.length
              ) {
                const imageFiles = images.map((_image: any, index: any) => {
                  const fileName = fileNameList[index]
                  return {
                    name: fileName,
                    fileUrl: ParseFilesUrl({
                      baseUrl: FILES_URL,
                      avatar: fileName ?? '',
                    }),
                    description: _image.name,
                    fileType: FILE_TYPE.INSPECTION_IMAGE,
                  }
                })

                const copiedItems = [...items]
                imageFiles.forEach((image) => {
                  items.forEach((item, index) => {
                    if (imagesFiles[index]) {
                      if (
                        imagesFiles[index].some(
                          (f: File) => f.name === image.description
                        ) &&
                        !copiedItems[index]?.imageFiles?.some(
                          (img: PunchlistFile) =>
                            img.description === image.description
                        )
                      ) {
                        copiedItems[index] = {
                          ...copiedItems[index],
                          imageFiles: [...copiedItems[index].imageFiles, image],
                        }
                      }
                    }
                  })
                })
                // post and put function
                patchAndCreate(copiedItems)
              }
            }
          )
        )
      }
    }
  }

  const handlePatchNote = () => {
    setLoadingNotes(true)
    dispatch(
      newEstimateActions.updateEstimate(
        {
          properties: { clientNotes: clientNotes },
        },
        (_succ) => {
          setLoadingNotes(false)
        }
      )
    )
  }

  const handleNext = () => {
    if (clientNotes !== '' && clientNotes) {
      handlePatchNote()
    }

    if (selectedTab === 0) {
      if (items && items.length > 0) {
        const itemsNotEmpty = validateEmptyItems(items)
        const allImages: File[] = []
        itemsNotEmpty.forEach((item: any, index) => {
          if (imagesFiles[index]) {
            imagesFiles[index].forEach((f: File) => {
              allImages.push(f)
            })
          }
        })
        handleUpload(allImages)
      } else {
       /*  !serviceable
          ?  */goForward()
        /* : dispatch(newEstimateActions.setNewEstimateStep(activeStep + 1)) */
      }
    }

    if (selectedTab === 1) {
      // patch repairListFiles
      if (repairListFiles && repairListFiles.length > 0) {
        setCanGoForward(true)
      }
      setLoading(true)
      if (repairListFiles) {
        dispatch(
          newEstimateActions.updateEstimate(
            {
              properties: {
                files: repairListFiles,
              },
            },
            (_succ) => {
              setLoading(false)
              if (_succ) {
                !serviceable
                  ? goForward()
                  : dispatch(
                    newEstimateActions.setNewEstimateStep(activeStep + 1)
                  )
              }
            }
          )
        )
      }
    }
  }

  const getSelectedTab = (_selectedTab: number) => {
    setSelectedTab(_selectedTab)
  }

  const validateEmptyItems = (items: Item[]): Item[] => {
    return items.filter((item: Item) => {
      return (
        item.inspectionReportNote?.trim() !== '' ||
        item.measurement?.trim() !== '' ||
        item.imageFiles?.length > 0
      )
    })
  }

  const hasItem = items?.some(
    (i) => i.measurement !== '' || i.inspectionReportNote !== ''
  )
  const emptyFiles = !repairListFiles || repairListFiles?.length === 0

  useEffect(() => {
    activeSubStep === -1 && pushParams(`?${authorized ? 'p' : 'o'}=2b`)
  }, [activeSubStep])

  useEffect(() => {
    dispatch(newEstimateActions.setNewEstimateValue({ attr: 'activeStepProgress', value: 1 }))
    dispatch(newEstimateActions.setNewEstimateValue({ attr: 'totalStepProgress', value: isNotHomeowner ? isInstitutional ? 2 : 4 : 3 }))
  }, [])

  return (
    <PrivateLoader loading={loading} compact>
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Tell us about your project
            </Typography>
            {xsScreen ? (
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: 'LatoNormal',
                  marginTop: '8px',
                }}
              >
                Build a project list, upload a list, or speak to a BOSSCAT
                expert. Please provide as much detail as you can and include
                measurements and photos/videos if applicable.
              </Typography>
            ) : (
              <Typography
                style={{
                  fontSize: '18px',
                  fontFamily: 'LatoNormal',
                  marginTop: '8px',
                }}
              >
                Build a project list, upload a list, or speak to a BOSSCAT
                expert.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} className={classes.container}>
            <ScrollWrapper>
              <TabNavigation
                tabs={TABS}
                getSelectedTab={getSelectedTab}
                variant="contained"
              />
            </ScrollWrapper>
          </Grid>

          {!loading && (
            <Grid
              container
              item
              xs={12}
              style={{ display: 'flex', marginBottom: authorized ? '48px' : "0px" }}
              justifyContent="space-between"
            >
              <Button
                type="submit"
                variant="outlined"
                size="small"
                className={classes.buttonBack}
                startIcon={<icons.ArrowBack />}
                onClick={goBack}
              >
                Back
              </Button>
              <Tooltip
                title={
                  selectedTab === 0 && !hasItem
                    ? `Specify details of your items to continue with your project`
                    : ''
                }
              >
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    className={classes.buttonNext}
                    endIcon={<icons.ArrowForward />}
                    onClick={handleNext}
                    disabled={
                      (selectedTab === 0 && !hasItem) ||
                      (selectedTab === 1 && emptyFiles) ||
                      selectedTab === 2
                    }
                  >
                    Next
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        {/*  {activeSubStep === 0 && serviceable && (
          <Project
            goBack={() =>
              dispatch(newEstimateActions.setNewEstimateStep(activeStep - 1))
            }
            goForward={() =>
              dispatch(newEstimateActions.setNewEstimateStep<(activeStep + 1))
            }
          />
        )}

        {activeSubStep === 1 && (
          <Contact
            goBack={() =>
              dispatch(newEstimateActions.setNewEstimateSubstep(activeSubStep - 1))
            }
            goForward={() => goForward()}
          />
        )} */}
      </>
    </PrivateLoader>
  )
}

export default Repairs
