import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      gap: '12px',
      alignItems: 'center',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'min-content min-content auto',
    },
    iconBox: {
      padding: '4px 12px 0',
      borderRadius: '8px',
      backgroundColor: 'var(--background-color-secondary)',
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    cardHolder: {
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      margiTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      fontFamily: 'LatoNormal',
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    cardNumber: {
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      display: 'grid',
      alignItems: 'center',
      '&:hover': { borderRadius: 8 },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '8px',
      padding: '8px 16px !important',
      borderRadius: '8px',
      background: 'var(--expired-color-back)',
    },
    newCard: {
      padding: '8px 16px 16px',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-blue-600)',
    },
    radio: {
      backgroundColor: 'transparent',
      justifyContent: 'flex-end',
      padding: '0px',
      height: '0px',
    },
  })
)
