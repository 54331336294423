export enum STATUS_BAR_STATE {
  DONE = 'DONE',
  TODO = 'TODO',
  ACTUAL = 'ACTUAL',
  HOLD = 'HOLD',
  WARNING = 'WARNING'
}

export interface StatusType {
  text: string
  state: STATUS_BAR_STATE
}

export interface StatusBarProps {
  statusList: StatusType[]
  showBetterStyle?: boolean
}
