import { createStyles, makeStyles, Theme } from "components/UI";

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '16px'
        },
        button: {
            textTransform: 'none',
            color: 'var(--white-color)',
            backgroundColor: 'var(--bosscat-blue-600)',
            borderRadius: 8,
            height: '44px',
            fontFamily: 'LatoNormal',
            margin: '16px'
        },
        expandMore: {
            display: 'flex',
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        expandMoreIcon: {
            color: 'var(--white-color)'
        },
        dialogTitle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        closeButton: {
            width: '25px',
            height: '25px'
        },
        title: {
            fontFamily: 'LatoBold',
            '@global': {
                span: {
                    fontFamily: 'LatoNormal !important',
                    fontWeight: 500,
                    color: 'var(--bosscat-blue-600)'
                }
            },
        }
    })
)