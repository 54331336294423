import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      width: '200px',
      height: '200px',
      borderRadius: '32px',
      alignItems: 'center'
      // minWidth: "200px"
    },
    image: {
      width: '100%',
      borderRadius: '32px'
    },
    actionButton: {
      backgroundColor: 'var(--profile-picture-button-bg)',
      color: theme.palette.background.default,
      display: 'flex',
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      height: '30%',
      bottom: 0,
      right: 0,
      left: 0,
      cursor: 'pointer',
      borderRadius: '0px 0px 32px 32px'
    },
    picture: {
      display: 'grid',
      width: '100%',
      height: '100%',
      borderRadius: '32px',
      justifyContent: 'center',
      objectFit: 'cover',
      alignItems: 'center',
      overflow: 'hidden',
      '& .MuiAvatar-fallback': {
        width: '100%'
      },
    },
    emptyDefaultPic: {
      objectFit: 'contain'
    },
    icon: {
      width: '100%',
      height: '70%',
      borderRadius: '32px 32px 0 0',
      backgroundColor: 'var(--background-color-secondary)',
      '& svg': {
        color: 'var(--profile-picture-button-bg)',
        /* width: 90,
        height: 90 */
      }
    },
    loading: {
      color: theme.palette.primary.contrastText,
      margin: "50px"
    },
    personIcon: {
      color: 'var(--primary-outlined-resting-border)',
      fontSize: theme.spacing(5)
    }
  })
)
