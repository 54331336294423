import { authTypes } from 'ducks/auth/types'
import { ItemsActions, ItemsType, itemsTypes } from './types'
import { Item, RepairItem } from 'ducks/types'

const initialState: ItemsType = {
  recommendedItems: []
}

const reducer = (state = initialState, action: ItemsActions): ItemsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case itemsTypes.SET_RECOMMENDED_ITEMS: {
      return {
        ...state,
        recommendedItems: payload as Partial<Item>[]
      }
    }
    default:
      return state
  }
}

export default reducer
