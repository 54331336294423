import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '164px',
      height: '48px',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: '8px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '16px 0',
        justifyContent: 'space-between',
      }
    },
    arrow: {
      margin: 0,
      height: '48px !important',
      width: '48px !important',
      '&:hover': {
        backgroundColor: 'var(--progress-color-back)'
      }
    },
    yearLabel: {
      margin: '0px 2px'
    },
    icon: {
      width: '6px',
      height: '12px',
      margin: '0px !important'
    }
  })
)
