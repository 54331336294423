import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'


export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px',
    },
    bottomLink: {
      color: 'var(--text-text-primary)',
      textDecoration: 'none'
    },
    equalHousingLogo: {
      height: '45px',
      width: '40px'
    }
  })
)
