import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      },
      padding: '8px 8px 8px 16px !important',
      [theme.breakpoints.down('md')]: {
        padding: '12px !important',
        boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
        // marginRight: '8px',
        borderRadius: '8px',
      },
    },
    dateInfo: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--date-color)',
      marginTop: '10px',
      [theme.breakpoints.down('md')]: {
        margin: '0px',
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        paddingLeft: '8px',
      },
    },
    titleAndDate: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    iconJobContainer: {
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      width: '42px',
      height: '42px',
      justifyContent: 'center',
      alignItems: 'center',
      justifySelf: 'center',
    },
    iconJob: {
      margin: '8px',
      height: '24px',
      width: '24px',
    },
    iconPay: {
      margin: '8px',
      height: '26px',
      width: '62px'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '22px',
        margin: '0px',
        overflow: 'hidden',
      },
    },
    price: {
      display: 'flex',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px',
      justifyContent: 'flex-end'
    }
  })
)
