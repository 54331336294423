import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      padding: '4px 0',
      '&::-webkit-scrollbar': {
        // display: 'none'
        height: '20px',
        width: '4px'
      },
    },
    hideScrollbar: {
      /* Hide scrollbar for Chrome, Safari and Opera */
      '&::-webkit-scrollbar': {
        // display: 'none'
      },

      /* Hide scrollbar for IE, Edge and Firefox */
      '-ms-overflow-style': 'none', /* IE and Edge */
      'scrollbar-width': 'none' /* Firefox */
    },
    list: {
      display: 'flex',
      gap: '24px',
      width: 'max-content'
    },
    image: {
      width: '92px',
      height: '92px',
      [theme.breakpoints.up('md')]: {
        width: '148px',
        height: '148px'
      }
    }
  })
)
