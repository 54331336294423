import { Document, Font, Image, Page, pdf, Text, View } from '@react-pdf/renderer'
import { formatTimestamp, round, getTextGuarantee, getTextUrl } from '../../../../../../helpers'
import logo from '../../../../../../assets/icons/custom/blue_logo.svg'
import moment from 'moment'
import Moranga from '../../../../../../assets/fonts/NEXT ART_SemiBold.otf'
import MorangaR from '../../../../../../assets/fonts/NEXT ART_Regular.otf'
import LatoBold from '../../../../../../assets/fonts/Lato-Bold.ttf'
import LatoNormal from '../../../../../../assets/fonts/Lato-Regular.ttf'

import styles from './style'

Font.register({ family: 'Moranga', src: Moranga })
Font.register({ family: 'MorangaR', src: MorangaR })
Font.register({ family: 'LatoBold', src: LatoBold })
Font.register({ family: 'LatoNormal', src: LatoNormal })
const formatDate = 'M/D/YYYY'

const FooterView = () => {
  return (
    <>
      <Text
        fixed

        style={styles.printDate}
      >
        {formatTimestamp(moment().unix(), 'MM/DD/YYYY HH:mm')}
      </Text>
      <Text
        fixed
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        style={styles.pageNumber}
      />
    </>
  )
}

const UserInfoView = ({ userInfo, imageUris }) => (
  <View
    style={[
      styles.containerHorizontal,
      styles.verticalSeparator,
      styles.justifyBetween
    ]}
  >
    <View style={[styles.containerHorizontal]}>
      <View style={styles.itemContainer}>
        {imageUris?.punchlistLogoUri && (
          <Image
            src={imageUris.punchlistLogoUri}
            style={styles.userInfoImage}
          />
        )}
      </View>
      <View style={[styles.itemContainer, styles.alignBetween]}>
        <Text style={styles.userInfoText}>{userInfo.name}</Text>
        <Text style={styles.userInfoText}>{userInfo.city}</Text>
        <Text style={styles.userInfoText}>{userInfo.address}</Text>
        <Text style={styles.userInfoBlue}>{userInfo.sent} - {userInfo.mail}</Text>
      </View>
    </View>
    <View style={styles.itemContainer} />
    <View style={styles.itemContainer} />
    <View style={styles.itemContainer} />
    <View style={[styles.itemContainer, styles.justifyCenter]}>
      <View style={styles.containerHorizontal}>

        {imageUris?.mailIconUri && (
          <Image
            src={imageUris.mailIconUri}
            style={styles.userInfoLabelImage}
          />
        )}
      </View>
    </View>
  </View>
)

const InfoView = ({ info }) => (
  <View
    style={[
      styles.containerHorizontal,
      styles.verticalSeparator,
      styles.justifyBetween
    ]}
  >
    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Invoice Prepared For:</Text>
        <Text style={styles.userInfoText}>{info.name}</Text>
        <Text style={styles.userInfoText}>{info.phone}</Text>
      </View>

      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Issue Date:</Text>
        <Text style={styles.userInfoText}>{formatTimestamp(info.createdOn, formatDate)}</Text>
      </View>

      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Due Date:</Text>
        <Text style={styles.userInfoText}>{"Due Upon Receipt"}</Text>
        {/* <Text style={styles.userInfoText}>{formatTimestamp(info.dueDate, formatDate)}</Text> */}
      </View>

      <View style={[styles.itemRight, styles.justifyCenter]}>
        <View style={[styles.containerHorizontal, styles.right]}>
          <Text style={styles.h5Title}>Payment Due:</Text>
          <Text style={styles.h5Value}>${round(info.amountDue, 2)}</Text>
        </View>
      </View>
    </View>
  </View>
)

const SummaryView = ({ summary }) => (
  <View
    style={[
      styles.container,
      styles.justifyBetween
    ]}
  >
    <Text style={styles.userInfoTitle}>Summary Information</Text>
    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Invoice Sub Total:</Text>
        <Text style={styles.userInfoText}>Sub Total (Completed):</Text>
        <Text style={styles.userInfoText}>Sub Total (Pending):</Text>
      </View>

      <View style={[styles.itemInfo, styles.right]}>
        <Text style={styles.userInfoTextRight}>${round(summary.subtotal, 2)}</Text>
        <Text style={styles.userInfoTextRight}>${round(summary.completed, 2)}</Text>
        <Text style={styles.userInfoTextRight}>${round(summary.pending, 2)}</Text>
      </View>
    </View>
    <View style={[styles.itemInfo, styles.line]} />

    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Discounts:</Text>
        <Text style={styles.userInfoText}>Taxes({round(summary.taxRate, 2)}%)</Text>
      </View>

      <View style={[styles.itemInfo, styles.right]}>
        <Text style={styles.userInfoTextRight}>$({round(summary.discounts, 2)})</Text>
        <Text style={styles.userInfoTextRight}>${round(summary.tax, 2)}</Text>
      </View>
    </View>
    <View style={[styles.itemInfo, styles.line]} />

    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Total:</Text>
      </View>

      <View style={[styles.itemInfo, styles.right]}>
        <Text style={styles.userInfoTextRight}>${round(summary.total, 2)}</Text>
      </View>
    </View>

    <Text style={[styles.userInfoTitle, styles.bigSpacing]}>Payment:</Text>
    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Total Payment Received:</Text>
      </View>

      <View style={[styles.itemInfo, styles.right]}>
        <Text style={styles.userInfoTextRight}>${summary.paymentReceived}</Text>
      </View>
    </View>
    <View style={[styles.itemInfo, styles.line]} />

    <View style={[styles.containerHorizontal, styles.justifyBetween]}>
      <View style={[styles.itemInfo, styles.alignBetween]}>
        <Text style={styles.userInfoText}>Current Balance Due:</Text>
      </View>

      <View style={[styles.itemInfo, styles.right]}>
        <Text style={styles.userInfoTextRight}>${round(summary.currentBalance, 2)}</Text>
      </View>
    </View>
    <View style={[styles.itemInfo, styles.line]} />

    <View style={[styles.containerHorizontal, styles.justifyBetween, styles.block]}>
      <View style={[styles.itemInfo, styles.alignBetween]} />
      <View style={[styles.itemEnd, styles.right]}>
        {/* <View style={styles.btn}><Text style={styles.userInfoTextBlue}>Pay ${round(summary.price, 2)}</Text></View> */}
        <Text style={styles.userInfoTextRight}>Invoice total: ${round(summary.total, 2)}</Text>
        <Text style={styles.userInfoTextRight}>Total paid: ${round(summary.paymentReceived, 2)}</Text>
        <Text style={styles.userInfoTextRed}>Outstanding Balance: ${round(summary.outstandingBalance, 2)}</Text>
      </View>
    </View>
  </View>
)

const CompleteBodyView = ({ groupedItems, userInfo, info, summary, imageUris }) => {
  return (
    <View style={styles.bodyItems}>
      <UserInfoView imageUris={imageUris} userInfo={userInfo} />
      <InfoView info={info} />
      <SummaryView summary={summary} />

      <View style={[styles.containerHorizontal, styles.justifyBetween, styles.topSeparation]}>
        <View style={[styles.itemContainer, styles.justifyCenter]}>
          <Text style={styles.userInfoTextCenter}>{getTextGuarantee()}</Text>
          <View style={[styles.itemInfo, styles.line]} />
          <Text style={styles.userInfoCenter}>{getTextUrl()}</Text>
        </View>
      </View>
    </View>
  )
}

const ItemsPdf = ({ groupedItems, userInfo, info, summary, imageUris }) => (
  <Document>
    <Page size='A4' style={styles.body}>
      <View style={styles.logoView}>
        <Image style={styles.logo} src={logo} alt='punchlist' />
      </View>
      <CompleteBodyView
        groupedItems={groupedItems}
        imageUris={imageUris}
        info={info}
        summary={summary}
        userInfo={userInfo}
      />
      <FooterView />
    </Page>
  </Document>
)

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({ groupedItems, userInfo, info, summary, imageUris }) => {
  download(
    await pdf(
      <ItemsPdf
        groupedItems={groupedItems}
        userInfo={userInfo}
        info={info}
        summary={summary}
        imageUris={imageUris}
      />
    ).toBlob(),
    `Invoice_${new Date().getTime()}`
  )
}
