import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto 0',
      [theme.breakpoints.down('sm')]: {
        margin: 'inherit',
      },
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    title: {
      fontFamily: 'LatoBold',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem',
      },
    },
    subtitle: {
      fontFamily: 'LatoNormal',
      color: 'var(--text-color)',
      margin: '.5rem 0.2rem 1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        margin: '.5rem 0',
      },
    },
    selectAddress: {
      minHeight: '275px !important',
      '@global': {
        '.MuiBox-root:first-child': {
          flex: 1,
        },
        '.MuiTypography-subtitle2': {
          fontSize: '16px',
          fontFamily: 'Lato',
        },
        '.MuiGrid-root': {
          display: 'flex !important',
          gap: '24px',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            flexGrow: '1',
            flexDirection: 'column',
            gap: '0px',
            '@global': {
              '.MuiBox-root:last-child': {
                flex: 0.45,
              },
            },
          },
        },
        '.MuiFormControl-root': {
          height: 'inherit',
          margin: 0,
          minHeight: 'inherit !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '200px !important',
      },
    },
    locationWarning: {
      display: 'flex',
      gap: theme.spacing(2),
      borderRadius: '8px',
      marginTop: '12px',
      padding: '12px 16px',
    },
    locationWarningBackground: {
      border: '2px solid ' + theme.palette.primary.light,
    },
    locationWarningText1: {
      color: theme.palette.primary.dark,
    },
    locationWarningText2: {
      fontFamily: 'LatoLight',
      fontWeight: 700,
    },
    locationWarningLink: {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    locationWarningIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px',
      },
    },
    buttonNextContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    titleLabel: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      lineHeight: '24px',
      margin: '16px 9px 0px 0px',
    },
    addressError: {
      background: '#FFF4F4',
      border: '1px solid #E01F1F',
      padding: '1rem',
      borderRadius: '8px',
      margin: '4px 8px',
      width: '100%'
    },
    addressErrorSubtitle: {
      fontSize: 16,
      fontFamily: 'LatoNormal'
    }
  })
)
