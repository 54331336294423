import { FC, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, TextFieldLabel, Grid, Autocomplete, Radio, FormControlLabel } from '../../../../../../UI'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { ShareModalProps } from '../types'
import { isEmpty, SHARE_ROLE, validateEmail } from 'helpers'
import { estimateActions } from 'ducks/actions'
import { useDispatch } from 'react-redux'
import { icons } from 'assets'

const MobileShareModal: FC<ShareModalProps> = ({ open, setOpen, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showWarnings, setShowWarnings] = useState(false)
  const [step, setStep] = useState(0)

  const isValidEmail = !isEmpty(email) && validateEmail(email)
  const isValidFirstName = !isEmpty(firstName)
  const isValidLastName = !isEmpty(lastName)

  const validate = isValidEmail && isValidFirstName && isValidLastName

  const roleOptions = SHARE_ROLE.map((role, index) => {
    return {
      key: index + 1,
      value: role.label,
      param: role.role
    }
  })
  const [role, setRole] = useState(roleOptions[0].param)

  const clear = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setRole(roleOptions[0].param)
    setShowWarnings(false)
  }

  const handleClose = () => {
    clear()
    setOpen(false)
  }

  const handleNext = () => {
    if (validate) {
      setStep(step => step + 1)
    }
    setShowWarnings(!validate)

  }

  const handleBack = () => {
    setStep(step => step - 1)
  }

  const handleSubmit = () => {
    setShowWarnings(!isValidEmail)
    if (isValidEmail) {
      setOpen(false)
      clear()
      dispatch(estimateActions.shareEstimate({
        email,
        firstName,
        lastName,
        role: role
      }))
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>

      <DialogContent>
        <Typography variant='h5' className={classes.text}>
          Share Estimate
        </Typography>
        <Typography variant='body1' className={classes.description}>
          Complete this form to share your estimate. <br />
          We will send an email with instructions to access the estimate.
        </Typography>
      </DialogContent>


      {step === 0 &&
        <DialogContent className={classes.inputsContainer}>
          <TextFieldLabel
            label='First Name'
            type='text'
            value={firstName}
            error={showWarnings && !isValidFirstName}
            onChange={(event) => { setFirstName(event.target.value) }}
            placeholder='First Name'
          />
          <TextFieldLabel
            label='Last Name'
            type='text'
            value={lastName}
            error={showWarnings && !isValidLastName}
            onChange={(event) => { setLastName(event.target.value) }}
            placeholder='Last Name'
          />
          <TextFieldLabel
            label='Email Address'
            type='email'
            value={email}
            error={showWarnings && !isValidEmail}
            onChange={(event) => { setEmail(event.target.value) }}
            placeholder='mail@email.com'
          />
        </DialogContent>
      }

      {step === 1 &&
        <DialogContent className={classes.inputsRoleContainer}>
          {roleOptions.map(roleOption =>
            <FormControlLabel
              checked={role === roleOption.param}
              value={true}
              control={
                <Radio
                  checkedIcon={
                    <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                  }
                  icon={<icons.RadioButtonUnchecked />}
                />
              }
              onChange={(e: any) => setRole(roleOption.param)}
              //className={`${styles.GiftShippingInformation__receiver} ${!homeownerReceives ? styles.GiftShippingInformation__receiver__selected : ''}`}
              label={<Typography
              //className={styles.GiftShippingInformation__receiver__title}
              >
                {roleOption.value}
              </Typography>}
            />
          )}

        </DialogContent>
      }


      {step === 0 &&
        <DialogContent className={classes.submitContainer}>
          <Button
            type='submit'
            variant='outlined'
            color='primary'
            className={classes.cancel}
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            fullWidth
            endIcon={<icons.ArrowRightAlt fontSize='large' />}
            onClick={handleNext}
          >
            Next
          </Button>
        </DialogContent>
      }

      {step === 1 &&
        <DialogContent className={classes.submitContainer}>
          <Button
            type='submit'
            variant='outlined'
            color='primary'
            className={classes.cancel}
            fullWidth
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            fullWidth
            endIcon={<icons.Share fontSize='large' />}
            onClick={handleSubmit}
          >
            Share
          </Button>
        </DialogContent>
      }

      <DialogContent />
    </Dialog>
  )
}

export default MobileShareModal
