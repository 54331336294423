import moment from 'moment';
import { addDays, formatParams } from '../../../helpers';
import axios from '../axios'
import { ApiController } from './types'
import { isEmpty } from 'lodash';

const base = '/estimates'

const apiController: ApiController = {
  getEstimates: async (startDate, endDate, status, start, limit, sortBy, sortOrder, search, shared) => {
    const endDatePlus1Day = endDate ? moment(addDays(endDate, 1, 1000)).unix() : null
    const params: any = { startDate, endDate: endDatePlus1Day, status, start, limit, sortBy, sortOrder, shared }
    if (!isEmpty(search)) params.search = search
    return await axios
      .get(base, { params })
      .then(response => response.data)
  },

  getEstimatesStats: async (startDate, endDate, status, search) => {
    const endDatePlus1Day = endDate ? moment(addDays(endDate, 1, 1000)).unix() : null
    const params: any = { startDate, endDate: endDatePlus1Day, status }
    if (!isEmpty(search)) params.search = search

    return await axios
      .get(`${base}/counts`, { params })
      .then(response => response.data)
  },

  getEstimateById: async (estimateId: string) =>
    await axios
      .get(`${base}/${estimateId}`)
      .then(response => response.data),

  getEstimateItems: async (estimateId: string) =>
    await axios
      .get(`${base}/${estimateId}/items`)
      .then(response => response.data),

  createEstimate: async (request, dontCreateAccount) =>
    await axios
      .post(`${base}${dontCreateAccount ? `?tempPassword=false` : ''}`, request)
      .then(response => response.data),

  updateEstimate: async (
    estimateId: string, request
  ) =>
    await axios
      .patch(`${base}/${estimateId}`, request)
      .then(response => response.data),

  createEstimateItem: async (estimateId: string, request) => {
    return await axios
      .post(`${base}/${estimateId}/items`, request)
      .then(response => response.data)
  },

  updateEstimateItem: async (
    estimateId: string, itemId: string, request
  ) =>
    await axios
      .patch(`${base}/${estimateId}/items/${itemId}`, request)
      .then(response => response.data),

  updateEstimateItems: async (
    estimateId: string, items
  ) => {
    return await axios
      .put(`${base}/${estimateId}/items`, items)
      .then(response => response.data)
  },

  applyPromoCode: async (estimateId: string, promoCode: string) => {
    return await axios
      .post(`${base}/${estimateId}/promo`, promoCode, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then(response => response.data)
  },

  getEstimatesServices: async (params) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}/services?${parameters}`)
      .then(response => response.data)
  },

  approval: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/approval`, request)
      .then(response => response.data),

  share: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/share`, request)
      .then(response => response.data),

  addItemsToEstimate: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/add-items`, request)
      .then(response => response.data),

  deleteEstimateItems: async (id: string, request) =>
    await axios
      .delete(`${base}/${id}/items`, {
        data: request
      })
      .then(response => response.data),

  addEstimateToNotificationList: async (request) =>
    await axios
      .post(`/expansion-request/${request?.territoryId}`, request)
      .then(response => response.data),

  requestMoreItems: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}/requestMoreItems`, request)
      .then(response => response.data),

}

export default apiController
