import { FC, useEffect, useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'
import { PaymentLoader } from '../../../../templates'
import { history } from '../../../../../helpers'
import { PaymentStatusProps } from './types'

const PaymentStatus: FC<PaymentStatusProps> = ({
  children,
  clientSecret,
  clear,
}) => {
  const path = history.getCurrentPath()
  const replace = history.useReplace()
  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState(true)
  const [succeeded, setSucceeded] = useState(false)

  useEffect(() => {
    if (!clientSecret) {
      if (clear) clear()
      setLoading(false)
    } else {
      if (!stripe || !elements) return

      stripe
        .retrievePaymentIntent(clientSecret ?? '')
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case 'succeeded':
              if (!succeeded) {
                setSucceeded(true)
                toast.success('Payment received!')
                if (clear) clear()
                setLoading(false)
                replace(path)
              }
              break

            case 'processing':
              toast.info('Payment processing.')
              break

            case 'requires_payment_method':
              toast.error('Payment failed.')
              history.useGoBack()
              break

            default:
              toast.error('Something went wrong.')
              history.useGoBack()
              break
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, clientSecret])

  return <PaymentLoader loading={loading}>{children}</PaymentLoader>
}

export default PaymentStatus
