/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Stepper,
  Typography,
} from 'components/UI'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import { StepperProps } from 'components/UI/CustomUI/organisms/Stepper/types'
import { newOrderMaintenanceActions, subscriptionsActions } from 'ducks/actions'
import { NewOrderMaintenanceType } from 'ducks/newOrderMaintenance/types'
import {
  getNewEstimateValues,
  getNewOrderMaintenanceValue,
  getNewOrderMaintenanceValues,
  getPropertyDetail,
  getSubscription,
  getTerritory,
  getUser,
  getUserPrimaryEmail,
  isAuthorized,
} from 'ducks/selectors'
import { CONTACT_ROLE, SUSCRIPTION_STATUS, USER_TYPE } from 'helpers/constants'
import {
  PHONE_MASK_REGEXP,
  PHONE_MASK_REGEXP_NO_SCOPE,
  history,
  validateEmail,
} from 'helpers/index'
import { isEmpty } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Account from './Account'
import FaqModal from './Modals/FaqModal'
import GiftSetModal from './Modals/GiftSetModal'
import PerksModal from './Modals/PerksModal'
import { NewMaintenanceModalsContext } from './Modals/context'
import GiftBox from './Steps/GiftBox'
import GiftMessage from './Steps/GiftBox/components/GiftMessage'
import GiftInformation from './Steps/GiftInformation'
import GiftSelector from './Steps/GiftInformation/components/GiftSelector'
import HomeToBeServiced from './Steps/GiftInformation/components/HomeToBeServiced'
import HomeownerInformation from './Steps/GiftInformation/components/HomeownerInformation'
import Onboarding from './Steps/Onboarding'
import Payment from './Steps/Payment'
import useStyles from './styles'
import OutOfAreaModal from './Steps/GiftInformation/components/HomeToBeServiced/OutOfAreaModal'
import { territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'
import { Navigation } from 'components/templates'
import UserInformation from './Steps/UserInformation'
import { ArrowBack } from '@material-ui/icons'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const Maintenance = () => {
  const [isVipLoading, setIsVipLoading] = useState(false)
  const [openAModal, setOpenModal] = useState(false)
  const [loadingAddress, setLoadingAddress] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()
  const authorized = useSelector(isAuthorized)

  const step = useSelector(getNewOrderMaintenanceValue('step'))
  const clientType = useSelector(getNewOrderMaintenanceValue('clientType'))
  const mobileStep = useSelector(getNewOrderMaintenanceValue('mobileStep'))
  const disableNext = useSelector(getNewOrderMaintenanceValue('disableNext'))
  const user = useSelector(getUser)
  const homeowner = useSelector(getNewOrderMaintenanceValue('homeowner'))
  const mainContact = useSelector(getNewOrderMaintenanceValue('mainContact'))
  const activeStepProgress = useSelector(
    getNewOrderMaintenanceValue('activeStepProgress')
  )
  const totalStepProgress = useSelector(
    getNewOrderMaintenanceValue('totalStepProgress')
  )
  const userMail = useSelector(getUserPrimaryEmail)
  const giftBox = useSelector(
    getNewOrderMaintenanceValue('giftBox'),
    shallowEqual
  )
  const isGift = useSelector(getNewOrderMaintenanceValue('gift'))
  const territory = useSelector(getTerritory()) || ({} as any)
  const propertyDetail = useSelector(getPropertyDetail())

  const cityIsAvailableForMaintenance = useSelector(
    territoryIsAvailableForMaintenance(territory?.id)
  )

  const {
    state: { modalType, modalOpen },
    dispatch: dispatchContext,
  } = useContext(NewMaintenanceModalsContext)

  const subscription = useSelector(getSubscription())
  const { usePush } = history

  const push = usePush()

  const orderMaintenance = useSelector(getNewOrderMaintenanceValues())
  const address = useSelector(getNewOrderMaintenanceValue('propertyAddress'))

  const updateNewOrderMaintenance = (
    attr: keyof NewOrderMaintenanceType,
    value: any
  ) => {
    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr, value })
    )
  }

  const fetchPlans = useCallback(() => {
    dispatch(
      subscriptionsActions.fetchSubscriptionsPlans((fetchSuccess, plans) => {
        if (fetchSuccess) {
          if (plans?.length) {
            const plan = plans?.[0]
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValues({
                attrs: {
                  services: plan?.services,
                  protectPlan: plan?.protectPlan,
                  title: plan?.title,
                  price: plan?.price,
                  stripeProductPriceId: plan?.stripeProductPriceId,
                  servicePeriodMonths: plan?.servicePeriodMonths,
                  promoCode: !isGift ? plan?.promoCodeForClient : null,
                },
              })
            )
          }
        }
      })
    )
  }, [dispatch])

  useEffect(() => {
    // If user is logged in we replace the mainContact with the user info
    if (user.id) {
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'mainContact',
          value: {
            ...mainContact,
            email: userMail,
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: user.phone,
            sendEstimate: true,
          },
        })
      )
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'purchaser',
          value: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: '',
            email: user.email,
            address: user.address,
            clientType:
              user.clientType === USER_TYPE.HOMEOWNER
                ? user.clientType
                : user.clientType === USER_TYPE.INSTITUTIONAL
                  ? CONTACT_ROLE.INSTITUTIONAL_INVESTOR
                  : CONTACT_ROLE.LISTING_AGENT,
          },
        })
      )
      !clientType &&
        dispatch(
          newOrderMaintenanceActions.setNewOrderMaintenanceValue({
            attr: 'clientType',
            value:
              user.clientType === USER_TYPE.HOMEOWNER
                ? user.clientType
                : user.clientType === USER_TYPE.INSTITUTIONAL
                  ? CONTACT_ROLE.INSTITUTIONAL_INVESTOR
                  : CONTACT_ROLE.LISTING_AGENT,
          })
        )
    }

    dispatch(
      newOrderMaintenanceActions.setNewOrderMaintenanceValue({
        attr: 'redirectAfterLogin',
        value: false,
      })
    )
  }, [user])

  useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  useEffect(() => {
    if (address?.zipCode && territory?.title) {
      setLoadingAddress(false)
      const showModal = address?.zipCode && !cityIsAvailableForMaintenance
      if (showModal) {
        setOpenModal(true)
      }
    }
  }, [territory])

  const steps: StepperProps['steps'] = []

  if (
    CONTACT_ROLE.LISTING_AGENT === clientType ||
    USER_TYPE.BROKER === user?.clientType
  ) {
    if (isMobile) {
      steps.push(
        {
          content: <GiftSelector />,
          nextText: 'Address',
          buttonBig: false,
          actionBack: () => {
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'step',
                value: step - 1,
              })
            )
          },
        },
        {
          content: <HomeToBeServiced />,
          nextText: 'Contact Info',
          actionBack: () => {
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'step',
                value: step - 1,
              })
            )
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'disableNext',
                value: false,
              })
            )
          },
        },
        {
          content: <HomeownerInformation />,
          nextText: 'Gift',
          actionNext: () => {
            const phoneInvalid =
              isEmpty(orderMaintenance?.homeowner?.phone?.toString()) ||
              !(
                PHONE_MASK_REGEXP.test(
                  orderMaintenance?.homeowner?.phone?.toString() as string
                ) ||
                PHONE_MASK_REGEXP_NO_SCOPE.test(
                  orderMaintenance?.homeowner?.phone?.toString() as string
                )
              )
            const email = orderMaintenance?.homeowner?.email || []

            const validate =
              !isEmpty(orderMaintenance.homeowner?.firstName) &&
              !isEmpty(orderMaintenance.homeowner?.lastName) &&
              !phoneInvalid &&
              validateEmail(email?.[0]?.email)
            if (subscription?.status !== SUSCRIPTION_STATUS.INCOMPLETE) {
              const request = {
                shippingName: `${homeowner?.firstName} ${homeowner?.lastName}`,
                shippingAddress: address,
                shippingPhone: homeowner?.phone,
                deliveryDate: giftBox?.deliveryDate,
                message: giftBox?.message,
              }

              updateNewOrderMaintenance('giftBox', {
                ...request,
              })
            }

            validate &&
              dispatch(
                subscriptionsActions.checkUserVIP(
                  {
                    email: orderMaintenance.gift
                      ? (orderMaintenance?.homeowner?.email?.[0]
                        .email as string)
                      : (user.email?.[0].email as string),
                  },
                  (succ, isVip) => {
                    if (!isVip)
                      dispatch(
                        newOrderMaintenanceActions.goForwardOrderMaintenance()
                      )
                  }
                )
              )
          },
        }
      )
    } else {
      steps.push({
        content: <GiftInformation />,
        nextText: 'Next',
        actionBack: () => {
          dispatch(
            newOrderMaintenanceActions.setNewOrderMaintenanceValue({
              attr: 'step',
              value: -1,
            })
          )
        },
        actionNext: () => {
          const email = orderMaintenance?.homeowner?.email || []
          const validateAddress: boolean =
            !isEmpty(address?.line_1) &&
              address?.line_1 !== ' ' &&
              !!address?.zipCode &&
              address.latitude &&
              address.longitude
              ? true
              : false
          const validate: boolean =
            validateAddress &&
            !isEmpty(orderMaintenance.homeowner?.firstName) &&
            !isEmpty(orderMaintenance.homeowner?.lastName) &&
            (orderMaintenance.gift
              ? orderMaintenance.homeowner?.phone
                ? PHONE_MASK_REGEXP_NO_SCOPE.test(
                  orderMaintenance.homeowner?.phone?.toString()
                ) ||
                PHONE_MASK_REGEXP.test(
                  orderMaintenance.homeowner?.phone?.toString()
                )
                : false
              : true) &&
            !isEmpty(email?.[0]?.email) &&
            validateEmail(email?.[0]?.email) &&
            cityIsAvailableForMaintenance

          setIsVipLoading(true)
          validate && dispatch(subscriptionsActions.checkUserVIP({ email: orderMaintenance.gift ? orderMaintenance?.homeowner?.email?.[0]?.email as string : (orderMaintenance?.homeowner?.email?.[0]?.email || user.email?.[0].email as string) }, (succ, isVip) => {
            if (!isVip)
              dispatch(newOrderMaintenanceActions.goForwardOrderMaintenance())
            setIsVipLoading(false)
          }))

          if (subscription?.status !== SUSCRIPTION_STATUS.INCOMPLETE) {
            const request = {
              shippingName: giftBox?.shippingName || `${homeowner?.firstName} ${homeowner?.lastName}`,
              shippingAddress: giftBox?.shippingAddress || address,
              shippingPhone: giftBox?.phone || homeowner?.phone,
              deliveryDate: giftBox?.deliveryDate || giftBox?.deliveryDate,
              message: giftBox?.message,
            }

            updateNewOrderMaintenance('giftBox', {
              ...request,
            })
          }
        },
      })
    }
  }

  if (
    orderMaintenance.gift &&
    (CONTACT_ROLE.LISTING_AGENT === clientType ||
      USER_TYPE.BROKER === user?.clientType)
  ) {
    steps.push({
      content: <GiftBox />,
      nextText: 'Next',
    })
    if (isMobile) {
      steps.push({
        content: <GiftMessage />,
        nextText: 'Next',
      })
    }
  }

  steps.push(
    {
      content: <UserInformation />,
      disableBack: true,
    },
    {
      content: <Payment />,
      disableBack: true,
    }
  )

  const handleBackClick = () => {
    dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
    push(`p/dashboard/${propertyDetail.id}`)
  }

  const showBackToReport = propertyDetail?.id && propertyDetail?.address?.fullAddress === address?.fullAddress;


  return (
    <Navigation>
      <>
        <div className={classes.content}>
          <Modal open={openAModal} setOpen={setOpenModal} size="lg">
            <OutOfAreaModal setOpen={setOpenModal} />
          </Modal>
          <Modal
            open={modalOpen && modalType === 'PERKS'}
            setOpen={() =>
              dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
            }
            size="xl"
            className={classes.modal}
            title={
              <Typography
                variant="h5"
                style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}
              >
                Four Seasons of Care List
              </Typography>
            }
          >
            <PerksModal />
          </Modal>
          <Modal
            open={modalOpen && modalType === 'FAQS'}
            setOpen={() =>
              dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
            }
            // setOpen={setOpenFaqModal}
            title={
              <Typography
                variant="h5"
                style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}
              >
                FAQ's
              </Typography>
            }
            size="xl"
            className={classes.modal}
          >
            <FaqModal />
          </Modal>
          <Modal
            open={modalOpen && modalType === 'GIFT_SET'}
            setOpen={() =>
              dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
            }
            // setOpen={setOpenFaqModal}
            title={
              <Typography
                variant="h5"
                style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}
              >
                Welcome Home Gift Items
              </Typography>
            }
            size="md"
            className={classes.modalSm}
          >
            <GiftSetModal />
          </Modal>
          {showBackToReport && (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              className={classes.backButton}
              onClick={handleBackClick}
            >
              <Typography
                className={classes.lgFont}
                style={{ textTransform: 'none' }}
              >
                Back to Report
              </Typography>
            </Button>
          )}
          {/* <div style={{ marginTop: showBackToReport ? "72px" : "24px" }}>
            <HolidayBanner />
          </div> */}
          {step === -2 ? (
            <Onboarding />
          ) : step === -1 ? (
            <Account loading={loadingAddress} />
          ) : (
            <Stepper
              withLogo={!authorized}
              steps={steps}
              activeStep={step as number}
              disableNext={disableNext}
              setActiveStepAction={(step: number) => {
                updateNewOrderMaintenance('step', step)
              }}
              absoluteClass={classes.noAbsolute}
              saveNewValueAction={(value: {
                attr: keyof NewOrderMaintenanceType
                value: any
              }) => updateNewOrderMaintenance(value.attr, value.value)}
              fixButtons
              showHeader={false}
              showProgress={!authorized}
              // logoAndProgressClass={`${!authorized ? classes.logoAndProgressNoAuth : ''}`}
              activeStepProgress={activeStepProgress}
              totalStepProgress={totalStepProgress}
              controlClassname={
                authorized ? classes.controlAuth : classes.control
              }
              className={`${classes.stepper} ${authorized ? classes.stepperAuth : ''
                }`}
            />
          )}
        </div>
      </>
    </Navigation>
  )
}

export default Maintenance
