import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'



export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      height: '80px',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'left',
        padding: '0px 200px 0 50px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 20px 0 70px',
      },
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 23px 0px 0px',
      padding: '4px 8px',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: '16px',
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31)',
    },
    titleSnd: {
      fontFamily: 'Lato',
      fontWeight: 500,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 1px 0px 0px',
      color: 'var(--input-color)',
    },
    text: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 18px 0px 0px',
      padding: '4px 0px',
      color: 'var(--input-color)',
    },
    icon: {
      height: '16px',
      color: 'var(--bosscat-blue-600)',
      margin: '0px 9px 0px 0px',
    },
    info: {
      height: '16px',
    },
    button: {
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 8,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    plannerBox: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        paddingLeft: '50px',
        paddingTop: '10px',
      },
    },
    plannerPadding: {
      justifyContent: 'space-between',
      padding: '10px 175px',
    },
    plannerEmail: {
      display: 'flex',
      alignItems: 'center',
    },
    plannerEmailSmall: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '18px',
      float: 'right',
    },
    plannerImg: {
      width: '50%',
      maxWidth: '380px',
      paddingTop: '10px',
    },
    container: {},
  })
)
