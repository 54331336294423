import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: theme.spacing(2),
      position: 'relative'
    },
    full: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      color: 'rgba(255, 255, 255, 0.8)',
      background: 'rgba(157, 157, 157, 0.5)',
      borderRadius: '8px',
      width: '24px',
      height: '24px',
      '&:hover': {
        background: 'rgba(157, 157, 157, 0.8)'
      },
    },
    imageBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '92px',
      [theme.breakpoints.up('md')]: {
        height: '148px'
      }
    },
    video: {
      width: '100%',
      height: '100%',
      maxHeight: '70vh',
      background: '#000'
    },
    preview: {
      maxWidth: 670,
      maxHeight: 470,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      border: '4px solid #F5F6F7',
      borderRadius: 8,
    },
    previewDefault: {
      width: '62px'
    },
    imageDialog: {
      maxHeight: '80vh'
    },
    actions: {
      display: 'grid',
      gap: theme.spacing(1),
      padding: theme.spacing(1)
    },
    documentCanva: {
      maxWidth: '80vw',
      width: 'auto',
      height: 'auto',
      overflow: 'hidden'
    },
    imgPreview: {
      width: 80,
      height: 80,
      objectFit: 'cover',
      borderRadius: 8,
      cursor: 'pointer'
    },
    imageSlider: {
      justifyContent: 'center',
      marginTop: '1rem'
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@global': {
        '.MuiDialog-container': {
          display: 'flex',
          alignItems: 'flex-end'
        },
        '.MuiDialog-paperWidthSm': {
          width: 720,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0
          }
        }
      }
    },
    slider: {},
    imageSliderItem: {
      maxWidth: 88,
      overflow: 'hidden'
    }
  })
)
