import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        TabContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "2px solid #EBECEF",
            borderRadius: "8px",
            overflow: "hidden",
        },
        Tab: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "8px"
        },
        HoursContainer: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
            padding: "16px",
            marginTop: "16px",
            rowGap: "16px"
        },
        Hour: {
            fontSize: "18px",
            position: "relative",
            padding: "8px",
            paddingRight: "32px",
            width: "max-content",
            margin: "auto",
            borderRadius: "8px",
        },
        HourTime: {
            position: "absolute",
            right: "9px",
            top: "8px",
            fontSize: "12px",
        },
        Selected: { background: "var(--bosscat-blue-600)", color: "#fff" }
    }))
