import { Box, Typography } from "@material-ui/core"
import { approval, estimateNotepad, peace } from "assets/index"
import useStyles from './styles'

const Renovations = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.textContainer}>
        <Typography className={classes.title}> We maintain, you enjoy! </Typography>
        <Typography className={classes.subtitle}> With our VIP maintenance membership, your home gets the services it needs to run smoothly and preserve its core systems, saving you time and money.</Typography>
      </Box>
      <Box className={classes.card__container}>
        <Box className={classes.card}>
          <img src={estimateNotepad} alt="logo" className={classes.icon} />
          <Box>
            <Typography className={classes.card__title}>
              Four service visits per year
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            We'll provide quarterly services to ensure your home is ready for the coming season.
          </Typography>
        </Box>
        <Box className={classes.card}>
          <img src={approval} alt="logo" className={classes.icon} />
          <Box>
            <Typography className={classes.card__title}>
              Health Assesments
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            Sit back and relax while our team transforms your home with guaranteed quality.
          </Typography>
        </Box>
        <Box className={classes.card}>
          <img src={peace} alt="logo" className={classes.icon} />
          <Box>
            <Typography className={classes.card__title}>
              Peace of mind
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            Spend less time worrying about maintenance and breakdowns -- and more time enjoying your home
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Renovations