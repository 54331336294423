/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { InviteNewClientRequest } from '../../../../api/punchlist/users/types'
import { adminActions } from '../../../../ducks/actions'
import { AdminType } from '../../../../ducks/admin/types'
import { getAdminClients, getConfigValue } from '../../../../ducks/selectors'
import { EMAIL_TYPE, USER_TYPE } from '../../../../helpers'
import { DesktopPage, PrivateLoader } from '../../../templates'
import { useIsXsScreen } from '../../../../hooks'
import { Tag } from '../../../UI'
import Tags from '../../../UI/CustomUI/molecules/Tags'

import { icons } from './../../../../assets/'
import AddMemberModal from './AddMemberModal'
import { Member } from './AddMemberModal/types'
import EditStatesModal from './EditStatesModal'
import NoResults from './NoResults'
import AdminItem from './AdminItem'
import useStyles from './styles'
import SuccessModal from './SuccessModal'

const Admin: FC = () => {
  const [tableLoading, setTableLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editStatesModal, setEditStatesModal] = useState(false)
  const [memberSelected, setMemberSelected] = useState<Partial<Member>>()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()

  const adminClients = useSelector(getAdminClients)
  const isAdmin = useSelector(getConfigValue('isAdmin'))
  const userType = useSelector(getConfigValue('userType'))

  const isInstitutional = userType === USER_TYPE.INSTITUTIONAL

  const fetchAdminClients = useCallback(() => {
    setPageLoading(true)
    dispatch(adminActions.fetchAdminClients((fetchSuccess) => {
      if (fetchSuccess) {
        setPageLoading(false)
      }
    }))
  }, [])

  useEffect(() => {
    fetchAdminClients()
  }, [])

  if (!isAdmin || userType === USER_TYPE.HOMEOWNER) return <Redirect to='/' />

  const handleAddMember = (member: Member, callback?: () => void) => {
    const newMember: InviteNewClientRequest = {
      email: [
        {
          emailType: EMAIL_TYPE.PRIMARY,
          email: member.email
        }
      ],
      type: 'Client',
      firstName: member.firstName,
      lastName: member.lastName,
      region: {
        type: 'STATE',
        values: member.states
      }
    }
    dispatch(
      adminActions.inviteNewClient(newMember, success => {
        if (success) {
          setSuccess(success)
          setOpen(false)
        } else {
          callback?.()
          // setOpen(false)
        }
      })
    )
  }

  const handleOpenEditStates = (member: Partial<Member>) => {
    const { email, id, states } = member
    setEditStatesModal(true)
    setMemberSelected({
      email,
      id,
      states: states || []
    })
  }

  const handleEditStatesSubmit = ({ userId, newStates }: { userId: string, newStates: string[] }) => {
    setTableLoading(true)
    dispatch(
      adminActions.updateUser(
        {
          id: userId,
          region: {
            type: 'STATE',
            values: newStates
          }
        },
        success => {
          setTableLoading(false)
          setEditStatesModal(false)
        }
      )
    )
  }

  return (
    <DesktopPage title='Admin Panel'>
      <PrivateLoader loading={pageLoading}>
        <Grid container spacing={3} justifyContent='flex-end' alignItems='center' className={classes.parent}>
          {open && <AddMemberModal open={open} setOpen={setOpen} action={handleAddMember} isInstitutional={isInstitutional} />}
          {editStatesModal && <EditStatesModal open={editStatesModal} setOpen={setEditStatesModal} email={memberSelected?.email} userId={memberSelected?.id} selectedStates={memberSelected?.states} action={handleEditStatesSubmit} />}
          {success && <SuccessModal open={success} setOpen={setSuccess} />}
          <Grid item xs={xsScreen ? 12 : 3} justifyContent='flex-end' className={classes.header}>
            <Button
              type='submit'
              variant='outlined'
              className={`${classes.button} ${classes.addMemberButton}`}
              onClick={() => setOpen(true)}
            >
              Invite new member
              <icons.Add />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            {adminClients.length > 0
              ? (
                <Grid item xs={12} className={classes.table}>
                  <PrivateLoader loading={tableLoading} building='fragment'>
                    <TableContainer
                      component={Paper}
                      className={!xsScreen ? classes.tableComponent : classes.tableOff}
                    >

                      {xsScreen &&
                        <Grid container item xs={12} spacing={3}>
                          {adminClients.length > 0
                            ? adminClients.map((adminClient: AdminType) =>
                              <AdminItem key={adminClient.id} adminClient={adminClient} handleAddMember={handleAddMember} handleOpenEditStates={handleOpenEditStates} />)
                            : null}
                        </Grid>}

                      {!xsScreen &&
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.cellHead}>
                                <Typography
                                  classes={{ root: classes.tableSortLabel }}
                                >
                                  Name
                                </Typography>
                              </TableCell>
                              {isInstitutional
                                ? (
                                  <TableCell className={classes.cellHead} align='left'>
                                    <Typography
                                      classes={{ root: classes.tableSortLabel }}
                                    >
                                      States
                                    </Typography>
                                  </TableCell>)
                                : (
                                  <TableCell className={classes.cellHead} align='left'>
                                    <Typography
                                      classes={{ root: classes.tableSortLabel }}
                                    >
                                      Email
                                    </Typography>
                                  </TableCell>)}
                              <TableCell className={classes.cellHead} align='left'>
                                <Typography
                                  classes={{ root: classes.tableSortLabel }}
                                >
                                  Status
                                </Typography>
                              </TableCell>
                              {/*  <TableCell className={classes.cellHead} align='left'>
                                                    <Typography
                                                        classes={{ root: classes.tableSortLabel }}
                                                    >
                                                        Enabled
                                                    </Typography>
                                                </TableCell> */}
                              <TableCell className={`${classes.cellHead}`} align='center'>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {adminClients.length > 0
                              ? adminClients.map((adminClient: AdminType) => {
                                const { id, firstName, email, lastName, region, registrationComplete, disabled } = adminClient
                                const status = disabled ? 'Disabled' : !registrationComplete ? 'Pending' : 'Enabled'
                                return (
                                  <TableRow key={id} className={classes.row}>
                                    <TableCell className={classes.cell}>
                                      <Typography className={classes.title}>
                                        {firstName} {lastName}
                                      </Typography>
                                      {isInstitutional && (
                                        <Typography className={classes.subtitle}>
                                          {email[0].email}
                                        </Typography>)}
                                    </TableCell>
                                    {isInstitutional
                                      ? (
                                        <TableCell
                                          className={`${classes.cell} ${classes.states}`}
                                          align='left'
                                        >
                                          {region?.values && <Tags tags={region.values} limit={4} />}
                                        </TableCell>)
                                      : (
                                        <TableCell
                                          className={`${classes.cell} ${classes.states}`}
                                          align='left'
                                        >
                                          {email[0].email}
                                        </TableCell>)}
                                    <TableCell
                                      className={`${classes.cell} ${classes.status}`}
                                      align='center'
                                      style={{ position: 'relative' }}
                                    >
                                      <Tag type={status} customSize='fitContent' />
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.cell} ${classes.actions}`}
                                      align='center'
                                    >
                                      {status === 'Pending' && (
                                        <Button
                                          type='submit'
                                          variant='outlined'
                                          onClick={() => handleAddMember({ email: email[0].email, firstName, lastName, type: 'Client' })}
                                          className={`${classes.button} ${classes.resendButton}`}
                                        >
                                          Resend invite
                                        </Button>)}
                                      {isInstitutional && (
                                        <Button
                                          type='submit'
                                          variant='outlined'
                                          onClick={() => handleOpenEditStates({ email: email[0].email, id, states: region?.values })}
                                          className={`${classes.button} ${classes.resendButton}`}
                                        >
                                          Edit States
                                        </Button>)}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              : null}
                          </TableBody>
                        </Table>}
                    </TableContainer>
                  </PrivateLoader>
                </Grid>
              )
              : <NoResults setOpen={setOpen} />}
          </Grid>
        </Grid>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default Admin
