/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useCallback, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DesktopPage,
  PrivateLoader,
  OverlayLoader
} from '../../../../templates'
import { Grid, Button, Typography, Box, Avatar } from '../../../../UI'
import { icons, calendar } from '../../../../../assets'
import useStyles from './styles'
import {
  history,
  capitalize,
  PAYMENT_RESOURCE_TYPE,
  formatTimestamp,
  round,
  ESTIMATE_PUBLIC_STATUS
} from '../../../../../helpers'
import {
  getUserFullName,
  getUserPrimaryEmail,
  getUserAddress
} from '../../../../../ducks/user/selectors'
import {
  getPaymentById,
  getEstimate,
  getJob,
  getPayments,
  getPayment
} from '../../../../../ducks/selectors'
import { jobActions, estimateActions, subscriptionActions, subscriptionsActions, paymentsActions, paymentActions } from '../../../../../ducks/actions'
import { useIsMdScreen } from '../../../../../hooks'
import { Payment } from 'ducks/types'

const PaymentDetail: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const goBack = history.useGoBack()
  const push = history.usePush()
  const pushForward = history.usePushForward()
  const formatDate = 'MMMM Do, YYYY'
  const [pageLoading, setPageLoading] = useState(true)
  const [downloadingPdf, setDownloadingPdf] = useState(false)

  const paymentId = history.getParamValues().id

  const userFullName = getUserFullName()
  const userPrimaryEmail = getUserPrimaryEmail()
  const userAddress = getUserAddress()
  const selectedPayment = useSelector(getPayment())

  const title = `${capitalize(selectedPayment?.resourceType)}: ${selectedPayment?.address
    }`
  const isEstimate =
    selectedPayment?.resourceType === PAYMENT_RESOURCE_TYPE.ESTIMATE
  const isJob = selectedPayment?.resourceType === PAYMENT_RESOURCE_TYPE.JOB
  const jobId = selectedPayment ? selectedPayment.resourceId : '0'
  const estimateId = selectedPayment ? selectedPayment.resourceId : '0'
  const estimate = useSelector(getEstimate())
  const { deliveredOn, createdOn } = estimate ?? {}
  const job = useSelector(getJob())
  const [_, currBreakpoint] = useIsMdScreen()

  const isSubscription = selectedPayment?.resourceType === PAYMENT_RESOURCE_TYPE.SUBSCRIPTION
  const subscriptionId = selectedPayment ? selectedPayment?.resourceId : '0'

  const estimates = [
    {
      color: 'var(--bosscat-blue-600)',
      type: 'REQUEST SENT:',
      date:
        deliveredOn === null ? '----' : formatTimestamp(deliveredOn, formatDate)
    },
    {
      color: '#1CCD83',
      type: 'ESTIMATE SENT:',
      date: createdOn === null ? '----' : formatTimestamp(createdOn, formatDate)
    }
  ]
  const jobs = [
    {
      color: 'var(--bosscat-blue-600)',
      type: 'JOB APPROVED:',
      date:
        job?.createdOn === null
          ? '----'
          : formatTimestamp(job?.createdOn, formatDate)
    },
    {
      color: '#FFC633',
      type: 'JOB STARTED:',
      date:
        job?.startedOn === null
          ? '----'
          : formatTimestamp(job?.startedOn, formatDate)
    },
    {
      color: '#1CCD83',
      type: 'JOB COMPLETED:',
      date:
        job?.completedOn === null
          ? '----'
          : formatTimestamp(job?.completedOn, formatDate)
    },
    {
      color: '#0E905A',
      type: 'PAYMENT RECEIVED:',
      date: formatTimestamp(selectedPayment?.createdOn, formatDate)
    }
  ]
  const subscriptions = [
    {
      color: '#0E905A',
      type: 'PAYMENT RECEIVED:',
      date: formatTimestamp(selectedPayment?.createdOn, formatDate)
    }
  ]

  const fetchJobView = useCallback(
    (loadings = true) => {
      if (loadings) setPageLoading(true)
      dispatch(
        jobActions.fetchJob(jobId, (_succ: boolean) => {
          setPageLoading(false)
          if (!_succ) {
            goBack()
          }
        })
      )
    },
    [dispatch, jobId]
  )

  const fetchEstimateView = useCallback(
    (loadings = true) => {
      if (loadings) setPageLoading(true)
      dispatch(
        estimateActions.fetchEstimate(estimateId, (_succ: boolean) => {
          setPageLoading(false)
          if (!_succ) {
            goBack()
          }
        })
      )
    },
    [dispatch, estimateId]
  )

  const fetchSubscriptionView = useCallback(
    (loadings = true) => {
      if (loadings) setPageLoading(true)
      dispatch(
        subscriptionsActions.fetchSubscriptions({ stripeSubscriptionId: subscriptionId }, (_succ: boolean) => {
          setPageLoading(false)
          if (!_succ) {
            goBack()
          }
        })
      )
    },
    [dispatch, estimateId]
  )

  useEffect(() => {
    dispatch(paymentActions.fetchPayment(paymentId))
  }, [])

  useEffect(() => {
    if (isJob && jobId) {
      fetchJobView()
    } else if (isEstimate && estimateId) {
      fetchEstimateView()
    } else if (isSubscription && subscriptionId) {
      fetchSubscriptionView()
    }
  }, [fetchJobView, fetchEstimateView, selectedPayment])

  const data = isEstimate ? estimates : isJob ? jobs : subscriptions

  const handleGoToInvoice = () => {
    if (job) {
      const id = jobId
      const invoiceId = job.properties.invoiceId
      push(`p/jobs/${id}/invoice/${invoiceId}`)
    }
  }

  const handleReceipt = async () => {
    push(`p/payments/${paymentId}/receipt/${estimateId}`)
  }

  const handleSeparatorStyle = (): string => {
    if (['xs', 'sm', 'md'].includes(currBreakpoint)) {
      return classes.lineHorizontal
    }
    return classes.line
  }

  return (
    <PrivateLoader loading={pageLoading}>
      <OverlayLoader loading={downloadingPdf}>
        <DesktopPage title='Payments'>
          <Box paddingX={2}>
            <Button
              type='submit'
              variant='outlined'
              size='small'
              className={classes.buttonReverse}
              startIcon={<icons.ChevronLeft />}
              onClick={() => goBack()}
              classes={{
                startIcon: classes.iconBack,
                label: classes.label
              }}
            >
              Back
            </Button>

            <Grid container item xs={12} className={classes.itemTitle}>
              <Box className={classes.titleBox}>
                <Typography className={classes.title}>{title}</Typography>
              </Box>
              <Box className={classes.titleContainer}>
                <Typography className={classes.title}>
                  {'$' + round(selectedPayment?.amount ?? 0, 2)}
                </Typography>
                {!isSubscription &&
                  (selectedPayment?.resourceType === PAYMENT_RESOURCE_TYPE.JOB ? (
                    <Box
                      className={classes.iconBox}
                      style={{ cursor: 'pointer' }}
                      onClick={handleGoToInvoice}
                    >
                      <Avatar
                        alt=''
                        src={calendar}
                        className={classes.iconJob}
                        variant='square'
                      />
                      <Typography className={classes.iconTitle}>
                        Invoice
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      className={classes.iconBox}
                      style={{ cursor: 'pointer' }}
                      onClick={handleReceipt}
                    >
                      <Avatar
                        alt=''
                        src={calendar}
                        className={classes.iconJob}
                        variant='square'
                      />
                      <Typography className={classes.iconTitle}>
                        Receipt
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={12}
              className={classes.paymentDataContainer}
            >
              <Grid container item xs={12} lg={5}>
                {data.map((item, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    key={index}
                    className={classes.statusDate}
                  >
                    <Box
                      className={`${index !== data.length - 1
                        ? classes.dotContent
                        : classes.dotEmpty
                        }
                      ${classes.statusContainer}`}
                    >
                      <Box>
                        <Box
                          style={{ backgroundColor: item.color }}
                          className={classes.percent}
                        />
                        {index !== data.length - 1 && (
                          <Box className={classes.dotLine} />
                        )}
                      </Box>
                      <Typography className={classes.itemType}>
                        {item.type}
                      </Typography>
                    </Box>
                    <Typography className={classes.itemDate}>
                      {item.date}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

              <Grid container item justifyContent='center' xs={12} lg={1}>
                <Box className={handleSeparatorStyle()} />
              </Grid>

              <Grid container item xs={12} lg={5}>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>SENT TO:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.detail}>
                    {userFullName}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>EMAIL:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.detail}>
                    {userPrimaryEmail}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>ADDRESS:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.detail}>
                    {userAddress}
                  </Typography>
                </Grid>
              </Grid>

              {/* ** hide for now ** <Grid container item xs={1} className={classes.itemTitle}>
                <Avatar
                  alt=''
                  src={visa}
                  className={classes.iconPay}
                  variant='square'
                />
              </Grid> */}
            </Grid>
            {!isSubscription && <Box className={classes.buttonContant}>
              <Button
                disabled={
                  isEstimate ? (estimate?.publicStatus ===
                    ESTIMATE_PUBLIC_STATUS.IN_PROGRESS) : false
                }
                type='submit'
                variant='contained'
                size='large'
                className={classes.button}
                endIcon={<icons.ChevronRight />}
                fullWidth
                onClick={() => {
                  if (isEstimate || isJob) {
                    push(
                      `p/${isEstimate ? 'estimates' : 'jobs'}/${selectedPayment?.resourceId
                      }`
                    )
                  }
                }}
              >
                {`View ${capitalize(selectedPayment?.resourceType)}`}
              </Button>
            </Box>}
          </Box>
        </DesktopPage>
      </OverlayLoader>
    </PrivateLoader>
  )
}

export default memo(PaymentDetail)
