import { Estimate } from 'ducks/types'
import { estimatesTypes, EstimatesActions, EstimateValue, FetchEstimatesServicesPayload } from './types'

const actions = {
  fetchEstimates: (
    payload: {
      startDate?: number,
      endDate?: number,
      status?: string[],
      start?: number,
      limit?: number,
      sortBy?: string,
      sortOrder?: 'asc' | 'desc',
      shared?: boolean,
      search?: string,
    },
    callback?: (succ: boolean, estimates?: Estimate[]) => void
  ): EstimatesActions => ({
    type: estimatesTypes.FETCH_ESTIMATES,
    payload,
    callback
  }),
  fetchEstimatesStats: (
    payload: {
      startDate?: number, endDate?: number,
      status?: string, start?: number,
      search?: string
    },
    callback?: (succ: boolean) => void
  ): EstimatesActions => ({
    type: estimatesTypes.FETCH_ESTIMATES_STATS,
    payload,
    callback
  }),
  setEstimates: (payload: Estimate[]): EstimatesActions => ({
    type: estimatesTypes.SET_ESTIMATES,
    payload
  }),
  setEstimateValue: (payload: EstimateValue): EstimatesActions => ({
    type: estimatesTypes.SET_ESTIMATE_VALUE,
    payload
  }),
  fetchEstimatesServices: (
    payload: FetchEstimatesServicesPayload,
    callback?: (succ: boolean) => void
  ): EstimatesActions => ({
    type: estimatesTypes.FETCH_ESTIMATES_SERVICES,
    payload,
    callback
  })
}

export default actions
