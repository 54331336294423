
import env from '@beam-australia/react-env'
import { errorTextTryingTo, formatDateToUnixTimestamp, history } from 'helpers/index'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Grid
} from '../../../UI'
import Banner1 from './components/Banner1'
import Banner2 from './components/Banner2'
import Banner3 from './components/Banner3'
import Banner4 from './components/Banner4'
import Banner5 from './components/Banner5'
import BannerEstimate from './components/BannerEstimate'
import Header from './components/Header'
import useStyles from './styles'
import { territoriesActions } from 'ducks/actions'
import { useDispatch } from 'react-redux'

const BASE_API_URL = env('PUNCHLIST_BASE_API_URL') ?? ''
const BASE_HOLYFIELD_URL = env('BASE_HOLYFIELD_URL') ?? ''

export interface PTPAddress {
  countrySubDivisionCode: string;
  city: string;
  postalCode: string;
  line1: string;
  country: string;
  inspectionReport: string;
  estimateId: string;
  reportId: string;
}

export interface EstimateInfo {
  serviceable?: boolean;
  dueOn?: number;
  deliveredOn?: number;
}

const PTP = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [address, setAddress] = useState<PTPAddress>()
  const [estimateId, setEstimateId] = useState<string>("")
  const [showSecondaryForm, setShowSecondaryForm] = useState(false)
  const [showNotifyModal, setShowNotifyModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reportId, setReportId] = useState<string>("")

  const [estimateInfo, setEstimateInfo] = useState<EstimateInfo>({})

  const dispatch = useDispatch()

  const classes = useStyles()

  const query = history.useQuery()
  const id = query.get("id")
  const urlHasALastSlash = BASE_HOLYFIELD_URL.includes("/", BASE_HOLYFIELD_URL.length - 1)
  const baseUrlHasALastSlash = BASE_API_URL.includes("/", BASE_API_URL.length - 1)

  const hitApiLandingViewed = async () => {
    try {
      const res = await fetch(`${BASE_HOLYFIELD_URL}${urlHasALastSlash ? "" : "/"}1.3/report/public/${id}/update-report-viewed`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "reportId": reportId, "viewedOn": formatDateToUnixTimestamp(new Date()) })
      })
    } catch (err) {
    }
  }

  useEffect(() => {
    if (reportId) {
      hitApiLandingViewed()
    }
  }, [reportId])

  const fetchAddress = async () => {
    try {
      const res = await fetch(`${BASE_HOLYFIELD_URL}${urlHasALastSlash ? "" : "/"}1.3/report/public/${id}`)
      const data = await res.json() as PTPAddress
      if (data?.estimateId) {
        setEstimateId(data?.estimateId)
        fetchEstimate(data?.estimateId)
      }
      setAddress(data)
      setReportId(data.reportId)
      setPageLoading(false)
    } catch (err) {
      toast.error(errorTextTryingTo("fetch address"))
    }
  }

  const fetchHeadEmail = async (email: string) => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await fetch(`${BASE_HOLYFIELD_URL}${urlHasALastSlash ? "" : "/"}1.3/report/public/${id}/contacts/${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer undefined`
        }
      })

      if (res.ok) {
        postForm({ email })
      } else {
        setLoading(false)
        setShowSecondaryForm(true)
      }
      // setLoading(false)
    } catch (err) {
      setShowSecondaryForm(true)
      setLoading(false)
    }
  }

  const fetchEstimate = async (id: string) => {
    if (id)
      try {
        const res = await fetch(`${BASE_API_URL}${baseUrlHasALastSlash ? "" : "/"}estimates/landing-page?estimateId=${id}`, {
          headers: {
            "Authorization": `Bearer undefined`
          }
        })

        const data = res.ok ? await res.json() : null
        if (data) setEstimateInfo(data)
        else toast.error(errorTextTryingTo("fetch estimate details"))
      } catch (err) {
        toast.error(errorTextTryingTo("fetch estimate details"))
      }
  }

  const postAddContact = async (formData: any) => {
    setLoading(true)
    try {
      const res = await fetch(`${BASE_HOLYFIELD_URL}${urlHasALastSlash ? "" : "/"}1.3/report/public/${id}/contacts`, {
        method: "POST",
        body: JSON.stringify({ ...formData }),
        headers: {
          "Content-Type": "application/json"
        }
      })
      if (res.status === 200) {
        postForm({ ...formData })
      }
      else toast.error(errorTextTryingTo("add a contact"))
    } catch (err) {
      toast.error(errorTextTryingTo("add a contact"))
    }
  }

  const postForm = async (formData: any) => {
    setLoading(true)
    try {
      const res = await fetch(`${BASE_HOLYFIELD_URL}${urlHasALastSlash ? "" : "/"}1.3/report/public/${id}/submit`, {
        method: "POST",
        body: JSON.stringify({ ...formData }),
        headers: {
          "Content-Type": "application/json"
        }
      })
      const data = await res.json()
      if (res.status === 200) {
        if (data.estimateId) {
          setEstimateId(data.estimateId)
          fetchEstimate(data.estimateId)
          fetchAddress()
        }
        toast.success("Request submitted successfully!")
      } else {
        toast.error(errorTextTryingTo("submit your request"))
      }
      setTimeout(() => {
        setLoading(false)
      }, 1500)
    } catch (err) {
      toast.error(errorTextTryingTo("submit your request"))
      setLoading(false)
    }
  }


  useEffect(() => {
    if (id) {
      fetchAddress()
    }
  }, [id])

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories(undefined, { serviceable: true }))
  }, [])

  useEffect(() => {
    if (estimateId) {
      setLoading(false)
    }
  }, [estimateId])

  return (
    <Grid className={classes.root}>
      <Header />
      {pageLoading ?
        <div>Loading...</div>
        :
        estimateId ?
          <BannerEstimate
            estimateInfo={estimateInfo}
            address={address}
            showNotifyModal={showNotifyModal}
            setShowNotifyModal={setShowNotifyModal}
            estimateId={estimateId}
          />
          :
          <Banner1
            showSecondaryForm={showSecondaryForm}
            setShowSecondaryForm={setShowSecondaryForm}
            id={address?.inspectionReport || ""}
            address={address}
            handleCheckEmail={fetchHeadEmail}
            handleSubmit={postAddContact}
            loading={loading}
          />
      }
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Banner5 />
    </Grid>
  )
}
export default PTP
