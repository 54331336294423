import axios from '../axios';
import { ApiController } from './types';

const base = '/home-mart'

const apiController: ApiController = {
  getPartners: async (active) => {
    const params: any = { active }

    return await axios
      .get(base, { params })
      .then(response => response.data)
  },
  getTags: async () => {
    return await axios
      .get(`${base}/tags`)
      .then(response => response.data)
  },
  getCustomerTypes: async () => {
    return await axios
      .get(`${base}/customer-types`)
      .then(response => response.data)
  },
}

export default apiController
