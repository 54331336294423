import { Box } from 'components/UI'
import useStyles from './styles'
import { FC, useState } from 'react'
import { OPTIONS } from '../..'
import ContactInformation from './options/ContactInformation'
import Initial from './options/Initial'
import { useDispatch, useSelector } from 'react-redux'
import { clientQuestionsActions } from 'ducks/actions'
import { COMMUNICATION_METHODS } from 'ducks/clientQuestions/types'
import { getEstimate, getUser } from 'ducks/selectors'
import SuccessModal from 'components/UI/CustomUI/molecules/SuccessModal'
import { email } from 'assets/index'
import moment from 'moment'

interface AskAQuestionProps {
  setActiveOption: (option: OPTIONS) => void
  setOpen: (open: boolean) => void
}

export enum SUB_OPTIONS {
  initial = 'initial',
  contactInformation = 'contactInformation',
  success = 'success'
}

const AskAQuestion: FC<AskAQuestionProps> = ({ setActiveOption, setOpen }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [activeSubOption, setActiveSubOption] = useState<SUB_OPTIONS>(SUB_OPTIONS.initial)
  const [question, setQuestion] = useState('')
  const [selectedContactMethod, setSelectedContactMethod] = useState<COMMUNICATION_METHODS>()
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)

  const handleClick = (subOption: SUB_OPTIONS) => {
    if (subOption === SUB_OPTIONS.initial) setActiveOption(OPTIONS.initial)
    setActiveSubOption(subOption)
  }

  const onSubmit = () => {
    selectedContactMethod && estimate?.id && dispatch(clientQuestionsActions.createClientQuestion({
      question,
      communication_method: selectedContactMethod,
      estimate_contact_id: user.id,
      estimate_id: estimate?.id,
      requested_date: moment(new Date())?.format('MM/DD/YYYY'),
    }, (succ) => {
      setActiveSubOption(SUB_OPTIONS.success)
    }))
  }

  const options = [
    {
      content: <Initial setSubActiveOption={handleClick} setQuestion={setQuestion} question={question} />,
      id: 'initial'
    },
    {
      content: <ContactInformation setSelectedContactMethod={setSelectedContactMethod} selectedContactMethod={selectedContactMethod} setSubActiveOption={(option: SUB_OPTIONS) => setActiveSubOption(option)} onSubmit={onSubmit} />,
      id: 'contactInformation'
    },
    {
      content:
        <SuccessModal
          img={email}
          title="your question has been submitted!"
          subtitle="Our team will contact you soon!"
          onClose={() => {
            setOpen(false)
            setActiveOption(OPTIONS.initial)
          }}
        />,
      id: 'success'
    },
  ]
  const activeOptionIndex = options.findIndex(option => option.id === activeSubOption)

  const content = options[activeOptionIndex]?.content ?? 'Nothing here..'

  return (
    <Box width={"100%"} display="flex" flexDirection="column" gridGap="16px" className={styles.root}>
      {content}
    </Box>
  )
}
export default AskAQuestion
