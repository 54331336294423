/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from 'api'
import { InviteNewClientRequest } from 'api/punchlist/users/types'
import { UpdateRequest } from 'api/types'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { adminActions } from 'ducks/actions'
import { UpdateUserAction, UserType } from 'ducks/user/types'
import * as Sentry from '@sentry/react'

import {
  FetchAdminClientsAction,
  adminTypes,
  InviteNewClientAction
} from './types'

export function* fetchAdminClients({ callback }: FetchAdminClientsAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.users.getAdminClients)
    if (data.length > 0) {
      yield put(adminActions.setAdminClients(data))
    }
    success = true
    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('load your organization members'))
    yield call(callback, false)
  }
}

export function* inviteNewClient({ payload, callback }: InviteNewClientAction): SagaIterator {
  let success = false

  const newClientRequest: InviteNewClientRequest = {
    email: payload?.email,
    firstName: payload?.firstName,
    lastName: payload?.lastName,
    type: 'Client',
    region: payload?.region
  }

  try {
    yield call(Punchlist.users.inviteNewClient, newClientRequest)
    success = true
    yield put(adminActions.fetchAdminClients((fetchSuccess) => {
      if (fetchSuccess) {
      }
    }))

    yield call(callback, success)
  } catch (err) {
    Sentry.captureException(err);
    yield call(toast.error, 'A user already exists with the provided email and cannot be modified.')
    yield call(callback, false)
  }
}

export function* updateUser({ payload, callback }: UpdateUserAction): SagaIterator {
  let success = false

  try {
    const userId: any = payload.id
    const request: UpdateRequest = []

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof UserType]
      })
    }

    const data = yield call(Punchlist.users.updateUser, userId, request)

    if (!isEmpty(data)) {
      yield put(adminActions.fetchAdminClients((fetchSuccess) => {
        if (fetchSuccess) {
          success = true
        }
      }))
    }
    yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update user info'))
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(adminTypes.INVITE_CLIENT, inviteNewClient)
  yield takeLatest(adminTypes.FETCH_ADMIN_CLIENTS, fetchAdminClients)
  yield takeLatest(adminTypes.UPDATE_USER, updateUser)
}
