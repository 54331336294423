import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '550px',
      borderRadius: '16px',
      width: '100%'
    },
    container: {
      padding: '8px 24px'
    },
    rootBroker: {
      maxWidth: '400px'
    },
    header: {
      padding: 0,
      overflowX: 'hidden'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontSize: 24,
      margin: '1rem 0'
    },
    footer: {
      background: 'var(--background-color-secondary)',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      '@global': {
        '.Mui-disabled': {
          background: 'var(--border-color)'
        }
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      margin: '1rem 0'
    },
    button: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
      margin: '.6rem 0',
      padding: '1rem 2rem'
    },
    buttonClose: {
      textTransform: 'inherit',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
      fontSize: 16,
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'transparent'
    },
    buttonSubmit: {
      textTransform: 'inherit',
      fontFamily: 'LatoNormal',
      fontSize: 16,
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'var(--bosscat-blue-600)',
      color: 'var(--white-color)',
      '@global': {
        '.MuiSvgIcon-root': {
          marginLeft: '.3rem',
          width: '1rem',
          transform: 'translateY(-1px)'
        }
      }
    },
    states: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      '@global': {
        '.MuiButtonBase-root': {
          height: 'fit-content !important'
        }
      }
    },
    loading: {
      width: '100%',
      marginTop: '-24px',
      position: 'absolute',
      height: '100%',
      display: 'flex',
      zIndex: 99999,
      background: '#ffffff9e'
    }
  })
)
