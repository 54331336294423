/* eslint-disable react-hooks/exhaustive-deps */
import { logo } from 'assets/index'
import { getNewOrderMaintenanceValue, isAuthorized } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import { Box } from '../../../../UI'
import UtmParamsWrapper from '../../../Auth/UtmParamsWrapper'
import Work from './Work'
import useStyles from './styles'

const Account = ({ loading }: { loading: boolean }) => {
  const classes = useStyles()
  const authorized = useSelector(isAuthorized)
  const activeStepProgress = useSelector(getNewOrderMaintenanceValue('activeStepProgress'))
  const totalStepProgress = useSelector(getNewOrderMaintenanceValue('totalStepProgress'))

  return (
    <Box className={classes.root} aria-label='estimate-account'>
      <Box className={classes.content}>
        <div className={classes.logoAndProgress}>
          {!authorized && <img src={logo} alt="" style={{ width: '160px' }} />}
          {!authorized && totalStepProgress && activeStepProgress && <div className={classes.progressContainer}>
            <div className={classes.progressBarContainer}>
              <div className={classes.progressBarFill} style={{ width: `${(activeStepProgress / totalStepProgress) * 100}%` }} />
            </div>
            <p className={classes.progressSteps}>{`${activeStepProgress}/${totalStepProgress}`}</p>
          </div>
          }
        </div>
        <UtmParamsWrapper>
          <Work loading={loading} />
        </UtmParamsWrapper>

      </Box>
    </Box>
  )
}

export default Account
