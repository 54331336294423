import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        container: {
            flexDirection: 'column',
            gap: '1rem'
        },
        title: {
            fontSize: 18
        },
        role: {
            fontFamily: 'LatoNormal',
            fontSize: 14,
            textDecoration: 'inherit'
        },
        buttonShare: {
            color: '#CC9300',
            background: '#FFFBF0',
            borderRadius: '100%',
            width: '48px',
            height: '48px',
            minWidth: '48px'
        },
        buttonShareIcon: {
            width: '12px'
        },
        small: {
            color: 'var(--bosscat-blue-600)',
            fontSize: 12,
            fontFamily: 'LatoNormal',
            textAlign: 'end'
        },
        actions: {
            justifyContent: 'flex-end',
            gap: '.5rem'
        },
        contactListContainer: {
            alignItems: 'center'
        }
    }))
