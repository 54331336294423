import { authTypes } from 'ducks/auth/types'
import {
  NewInspectionActions,
  NewInspectionType,
  NewInspectionValue,
  NewInspectionValues,
  newInspectionTypes,
} from './types'

const initialState: NewInspectionType = {
  //TODO estado inicial
  step: -1, // first step
  substep: -1, // first step
  clientType: '',
  mainContact: {},
  requester: {},
  address: {},
  homeSize: 0,
  preferredDate: 0,
  occupancyStatus: '',
  affiliate: '',
  inspectionStatus: '',
  id: '',


}

const reducer = (
  state = initialState,
  action: NewInspectionActions
): NewInspectionType => {
  const { type, payload } = action
  switch (type) {
    /*  case authTypes.LOGIN_REQUEST: {
       return initialState
     } */
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }
    case newInspectionTypes.CLEAN: {
      return {
        ...initialState,
      }
    }
    case newInspectionTypes.ERASE: {
      return {
        ...initialState,
      }
    }
    case newInspectionTypes.SET_STEP: {
      return {
        ...state,
        step: payload as number,
      }
    }
    case newInspectionTypes.SET_VALUE: {
      const { attr, value } = payload as NewInspectionValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case newInspectionTypes.SET_VALUES: {
      const { attrs } = payload as NewInspectionValues
      return {
        ...state,
        ...attrs,
      }
    }

    case newInspectionTypes.SET_SUBSTEP: {
      return {
        ...state,
        substep: payload as number,
      }
    }

    default:
      return state
  }
}

export default reducer
