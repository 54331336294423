import { PropertyContact } from 'ducks/types'

/** TYPES **/
export const propertyContactType = {
  FETCH_PROPERTY_CONTACTS: 'FETCH_PROPERTY_CONTACTS',
  CREATE_PROPERTY_CONTACT: 'CREATE_PROPERTY_CONTACT',
  UPDATE_PROPERTY_CONTACT: 'UPDATE_PROPERTY_CONTACT',
  DELETE_PROPERTY_CONTACT: 'DELETE_PROPERTY_CONTACT',
  SET_PROPERTY_CONTACTS: 'SET_PROPERTY_CONTACTS',
}

/** ACTIONS **/
export interface FetchPropertyContactsAction {
  type: typeof propertyContactType.FETCH_PROPERTY_CONTACTS
  payload: { id: string }
  callback?: (succ: boolean) => void
}

export interface CreatePropertyContactAction {
  type: typeof propertyContactType.CREATE_PROPERTY_CONTACT
  payload: { id: string; request: Partial<PropertyContact> }
  callback?: (succ: boolean) => void
}

export interface UpdatePropertyContactAction {
  type: typeof propertyContactType.UPDATE_PROPERTY_CONTACT
  payload: {
    propertyId: string
    contactId: string
    request: Partial<PropertyContact>
  }
  callback?: (succ: boolean) => void
}

export interface DeletePropertyContactAction {
  type: typeof propertyContactType.UPDATE_PROPERTY_CONTACT
  payload: { propertyId: string; contactId: string }
  callback?: (succ: boolean) => void
}

export interface SetPropertyContactsAction {
  type: typeof propertyContactType.SET_PROPERTY_CONTACTS
  payload: PropertyContactsType
}

export type PropertyContactsActions =
  | FetchPropertyContactsAction
  | CreatePropertyContactAction
  | UpdatePropertyContactAction
  | DeletePropertyContactAction
  | SetPropertyContactsAction

/** REDUCER **/
export type PropertyContactsType = PropertyContact[]
