import { Button } from '@material-ui/core'
import { FC } from 'react'
import { DownloadButtonProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { pdf, pdfBetter } from '../../../../../assets'

const DownloadButton: FC<DownloadButtonProps> = ({
  icon = undefined, text, onClick, color = 'blue', className, disabled, showBetterStyle = false
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  return (
    <Button
      type='submit'
      size='large'
      className={`${classes.button} ${className || ''} ${classes[color]}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ?? <img alt='' src={showBetterStyle ? pdfBetter : pdf} />}
      {text && <span className={classes.text}>{text}</span>}
    </Button>
  )
}

export default DownloadButton
