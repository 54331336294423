import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { TextFieldLabel } from "components/UI"
import { newOrderMaintenanceActions, promosActions } from "ducks/actions"
import { getNewOrderMaintenanceValue } from "ducks/selectors"
import { isEmpty } from "helpers/index"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from './styles'

import { icons } from 'assets'

const PromotionalCode = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const promoCode = useSelector(getNewOrderMaintenanceValue('promoCode'))
  const promo = useSelector(getNewOrderMaintenanceValue('promo'))
  const stripeProductPriceId = useSelector(getNewOrderMaintenanceValue('stripeProductPriceId'))

  const [loading, setLoading] = useState(false)

  const [promoSuccess, setPromoSuccess] = useState(!isEmpty(promo))
  const [showPromoInput, setShowPromoInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  useEffect(() => {
    stripeProductPriceId && handlePromo()
  }, [stripeProductPriceId])

  const handlePromo = () => {
    setLoading(true)
    setErrorMessage(null)

    promoCode && dispatch(promosActions.getPromo({
      promoCode,
      stripeProductPriceId
    }, (succ, promo) => {
      setLoading(false)
      if (succ && promo) {
        setPromoSuccess(true)
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promo', value: promo }))
        // dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'priceWithoutDiscount', value: price }))
        // dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'price', value: price - promo.amount }))
      } else {
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promo', value: null }))
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promoCode', value: null }))
        setErrorMessage('Wrong promo code. Check your code to apply it.')
      }
    }))
    setLoading(false)
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item container className={`${classes.promoCodeContainer}`} spacing={2}>
        {promoSuccess ?
          <Grid style={{ padding: 0 }} item container>
            <icons.CheckCircle className={classes.promoSuccessIcon} />
            <Typography className={classes.promoSuccessText}>Promo Code applied</Typography>
          </Grid>
          : showPromoInput ? (
            <>
              <Grid item className={classes.promoCode}>
                <TextFieldLabel
                  label='Promo Code'
                  type='text'
                  value={promoCode}
                  error={!isEmpty(errorMessage)}
                  onChange={(event: any) => {
                    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'promoCode', value: event.target.value }))
                  }}
                  placeholder='Enter your Promo Code'
                  autoComplete='off'
                /*  onBlur={e => {
                   isEmpty(promo) && dispatch(orderMaintenanceActions.setOrderMaintenanceValue({ attr: 'promoCode', value: null }))
                 }} */
                />
                <Typography className={classes.showPromoCode} style={{ marginTop: "0.5rem" }} onClick={() => setShowPromoInput(false)}>Hide Promo Code</Typography>
              </Grid>
              <Grid item>
                <Button className={classes.button} variant="contained" onClick={handlePromo} disabled={isEmpty(promoCode)}>
                  {loading ? <CircularProgress size="1.5rem" className={classes.spinner} /> : 'Apply'}
                </Button>
              </Grid>
            </>) : <Typography className={classes.showPromoCode} onClick={() => setShowPromoInput(true)}>Enter Promo Code</Typography>}
      </Grid>
      {errorMessage &&
        <Grid item>
          <Typography className={classes.errorMessage}>{errorMessage}</Typography>
        </Grid>
      }
    </Grid>
  )
}

export default PromotionalCode