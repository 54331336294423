import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from '../styles'
import {
    review,
    estimateComplete,
    itemsPriced,
    reportSent
} from '../../../../../assets'

type Props = {}

const Banner2 = (props: Props) => {
    const classes = useStyles()

    return (
        <div className={classes.banner2}>
            <div className={classes.maxWidth}>
                <Grid container className={`${classes.container} ${classes.top}`}>
                    <Grid item xs={12}>
                        <Typography className={classes.nextHeavyHardware}>What happens after you fill out the form?</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.top}>
                    <Grid item xs={12} sm={12} md={3} className={classes.container} >
                        <div style={{ height: "150px" }}>
                            <img className={classes.images} src={reportSent} alt="logo" />
                        </div>
                        <Typography className={classes.subNextHeavy}>Report Sent</Typography>
                        <Typography className={classes.subText}>Your Pillar To Post inspection report is submitted to BOSSCAT.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className={classes.container}>
                        <div style={{ height: "150px" }}>
                            <img className={classes.images} src={itemsPriced} alt="logo" />
                        </div>
                        <Typography className={classes.subNextHeavy}>Items Priced</Typography>
                        <Typography className={classes.subText}>Summary items from your report are priced by real humans.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className={`${classes.container} ${classes.top}`}>
                        <div style={{ height: "150px" }}>
                            <img className={classes.images} src={estimateComplete} alt="logo" />
                        </div>
                        <Typography className={classes.subNextHeavy}>Estimate Complete</Typography>
                        <Typography className={classes.subText}>After the estimate is completed, a link to it is sent to your email.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className={`${classes.container} ${classes.top}`}>
                        <div style={{ height: "150px" }}>
                            <img className={classes.images} src={review} alt="logo" />
                        </div>
                        <Typography className={classes.subNextHeavy}>Review</Typography>
                        <Typography className={classes.subText}>Review your estimate and build your repair list.</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Banner2