import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      gap: theme.spacing(4),
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center'
    },
    animation: {
      display: 'flex',
      justifyContent: 'center'
    },
    loader: {
      width: '100%',
      margin: 'auto',
      borderRadius: '16px',
      maxWidth: '280px',
      height: '8px',
      color: 'var(--bosscat-blue-600)',
      [theme.breakpoints.up('md')]: {
        maxWidth: '240px'
      }
    }
  })
)
