import { Grid, Typography } from '@material-ui/core'
import { DatePicker } from 'components/UI'
import { getApprovalValues } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC } from 'react'
import moment from 'moment'
import { useStepperContext } from 'hooks/useStepperContext'
import BooleanCheckbox from 'components/UI/CustomUI/molecules/BooleanCheckbox'

export interface PropertyStatusProps {
  isHomeowner: boolean
}

const PropertyStatus: FC<PropertyStatusProps> = ({ isHomeowner }) => {
  const classes = useStyles()
  const { showWarnings, saveNewValue } = useStepperContext()

  const {
    closingDate,
    preferredStartDate,
    propertyUnderContract,
    completedBeforeClosing,
    propertyToBeSold,
    propertyListed,
  } = useSelector(getApprovalValues())

  return (
    <>
      <Typography className={classes.title} style={{ padding: '0' }}>
        📁 {isHomeowner ? 'PLEASE CONFIRM OR UPDATE' : 'PROPERTY STATUS'}
      </Typography>
      <Grid container spacing={2}>
        {isHomeowner && (
          <Grid item xs={12} lg={6}>
            <Typography variant="body1" className={classes.latoPrimaryText}>
              Are you selling or preparing to sell your home?
            </Typography>
            <BooleanCheckbox
              onChange={(ev) => {
                if (ev.target.value === 'false') {
                  saveNewValue('closingDate', undefined)
                  saveNewValue('completedBeforeClosing', undefined)
                  saveNewValue('propertyUnderContract', undefined)
                  saveNewValue('paymentOption', 'pay_at_completion')
                  saveNewValue('agentAsHomeownerRepresentative', null)
                }
                saveNewValue('propertyToBeSold', ev.target.value === 'true')
              }}
              value={propertyToBeSold}
              error={showWarnings && typeof propertyToBeSold === 'undefined'}
            />
          </Grid>
        )}
        {(propertyToBeSold || !isHomeowner) && (
          <>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" className={classes.latoPrimaryText}>
                Is the property under contract?
              </Typography>
              <BooleanCheckbox
                onChange={(ev) => {
                  if (ev.target.value === 'false') {
                    saveNewValue('closingDate', undefined)
                    saveNewValue('completedBeforeClosing', undefined)
                  } else {
                    saveNewValue('propertyListed', undefined)
                  }
                  saveNewValue(
                    'propertyUnderContract',
                    ev.target.value === 'true'
                  )
                }}
                value={propertyUnderContract}
                error={
                  showWarnings && typeof propertyUnderContract === 'undefined'
                }
              />
            </Grid>
            {propertyUnderContract !== undefined &&
              propertyUnderContract !== null &&
              (!propertyUnderContract && isHomeowner ? (
                <Grid item xs={12} lg={6}>
                  <Typography
                    variant="body1"
                    className={classes.latoPrimaryText}
                  >
                    Is the property currently listed?
                  </Typography>
                  <BooleanCheckbox
                    onChange={(ev) => {
                      saveNewValue('propertyListed', ev.target.value === 'true')
                    }}
                    value={propertyListed}
                    error={
                      showWarnings && typeof propertyListed === 'undefined'
                    }
                  />
                </Grid>
              ) : (
                propertyUnderContract && (
                  <Grid item xs={12} lg={6}>
                    <Typography className={classes.latoPrimaryText}>
                      What is the closing date?
                    </Typography>
                    <Grid>
                      <DatePicker
                        error={
                          showWarnings && typeof closingDate === 'undefined'
                        }
                        minDate={
                          preferredStartDate
                            ? moment(preferredStartDate).toDate()
                            : new Date()
                        }
                        onChange={(date: Date) =>
                          saveNewValue('closingDate', date.valueOf() / 1000)
                        }
                        value={
                          closingDate
                            ? moment(closingDate * 1000).toDate()
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                )
              ))}

            {isHomeowner &&
              propertyUnderContract !== undefined &&
              propertyUnderContract !== null &&
              propertyUnderContract && (
                <Grid item xs={12} lg={6}>
                  <Typography
                    variant="body1"
                    className={classes.latoPrimaryText}
                  >
                    Will the repairs be completed prior to the closing date?
                  </Typography>
                  <BooleanCheckbox
                    onChange={(ev) => {
                      saveNewValue(
                        'completedBeforeClosing',
                        ev.target.value === 'true'
                      )
                    }}
                    value={completedBeforeClosing}
                    error={
                      showWarnings &&
                      typeof completedBeforeClosing === 'undefined'
                    }
                  />
                </Grid>
              )}
          </>
        )}
      </Grid>
    </>
  )
}

export default PropertyStatus
