import { Box, Button, Divider, Typography } from "@material-ui/core"
import { getEstimate } from "ducks/selectors"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from './styles'

import { emptyCart } from "assets/index"
import { icons } from 'assets'

export interface EmptyCartProps {
  // price: string
  serviceable?: boolean
  onClick?: () => void
}

const EmptyCart: FC<EmptyCartProps> = ({ serviceable, onClick }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={emptyCart} alt="Empty Cart" />
      <Box className={classes.container}>
        <Typography className={classes.title}>Hey!</Typography>
        <Typography className={classes.subtitle}>Let’s add some items to your cart to proceed.</Typography>
        <Button
          className={classes.button}
          onClick={() => onClick && onClick()}
          endIcon={<icons.ArrowForward />}
        >
          Select Items
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyCart