import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    testimonials: {
      padding: '5rem',
      display: 'flex'
    },
    testimonialTitle: {
      color: '#A5965A',
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'center'
    },
    testimonialSubtitle: {
      textAlign: 'center',
      fontSize: '16px'
    },
    perksContainer: {
      // display: 'grid',
      // gridTemplateColumns: 'repeat(2, 1fr)',
      // gap: '1.5rem',
      padding: '2rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      }
    },
    disclaimer: {
      padding: '0 2rem 2rem',
      fontFamily: "LatoBold"
    }
  })
)