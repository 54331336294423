import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: "4rem"
      },
      // alignItems: 'center',
      // justifyContent: 'centerootr',
    },
    content: {
      // display: 'grid',
      margin: '0 auto',
      marginTop: '2rem',
      marginBottom: "2rem",
      // gap: theme.spacing(2),
      width: '100%',
      // minHeight: '50vh',
      /* maxWidth: theme.breakpoints.values.lg, */
    },
    banner: {
      position: 'relative',
      display: 'flex',
      gap: '0.5rem',
      borderRadius: '0.5rem',
      padding: '1rem',
      background:
        'linear-gradient(149deg, #423C24 0%, #635A36 53.65%, #847848 100%)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      overflow: 'hidden',
      marginBottom: '1rem',
    },
    bannerText: {
      fontWeight: 'bold',
      fontSize: 18,
      color: 'white',
    },
    bannerTextFAQ: {
      fontWeight: 'bold',
      fontSize: 18,
      color: '#FFC531',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      transform: 'rotate(45deg)',
      fontSize: '1.5rem',
    },
    iconBanner: {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    title: {
      fontFamily: 'NextArtHeavy',
      color: '#A5965A',
      textTransform: 'uppercase',
      marginBottom: '1.5rem',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important',
        },
      },
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem',
        marginBottom: '.5rem',
      },
    },
    sideImageContainer: {
      borderRadius: '0.5rem',
      overflow: 'hidden',
      maxHeight: '200px',
    },
    sideImage: {
      width: '100%',
    },
    sideTitle: {
      lineHeight: '1rem',
      marginTop: '1rem',
      color: '#0B060F',
      fontFamily: 'Lato',
      fontSize: '1.125rem',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important',
        },
      },
    },
    sideLink: {
      // lineHeight: '1rem',
      color: '#2F7BF7',
      fontWeight: 'bold',
      fontSize: '1.125rem',
      paddingBottom: 5,
    },
    card: {
      position: 'relative',
      borderRadius: '0.5rem',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      padding: '0.5rem',
      minHeight: '12.5rem',
    },
    cardTitle: {
      textTransform: 'uppercase',
      fontFamily: 'NextArtHeavy',
      fontSize: '1rem',
      fontWeight: 900,
      lineHeight: '1.5rem',
    },
    cardIcon: {
      position: 'absolute',
      bottom: -5,
      right: 0,
    },
    fixedButton: {
      [theme.breakpoints.down('sm')]: {
        position: "fixed",
        bottom: "0rem",
        padding: "1rem",
        width: "100%",
        left: 0,
        background: "var(--white-color)",
      }
    },
    buttonNext: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoBold',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        fontFamily: 'LatoBold',
        fontWeight: 'normal',
        fontSize: '18px',
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    }
  })
)
