import { useState, forwardRef } from 'react'
import {
  Typography,
  STextField,
  materialLab,
  Popper,
  PopperProps,
  Button
} from '../../..'
import useStyles from './styles'
import { AutocompleteProps } from './types'
import { useClassName } from '../../../../../hooks'

const AutocompleteSearch = forwardRef(
  (
    {
      label,
      options,
      optionCount,
      value,
      error = false,
      placeholder = '',
      onChange,
      valueInput,
      valueChange,
      addOption,
      popupIcon,
      noOptionText,
      renderOption,
      defaultFilter,
      onBlur,
      onKeyDown
    }: AutocompleteProps,
    ref
  ) => {
    const classes = useStyles()
    const className = useClassName()
    const [input, setInput] = useState('')

    const handleInputChange = (value: string) => {
      if (valueChange) {
        valueChange(value)
      }
      setInput(value)
    }

    const PopperMy = (props: JSX.IntrinsicAttributes & PopperProps) => {
      return (
        <Popper
          {...props}
          style={{ width: '300px' }}
          className={classes.popper}
          placement='bottom-start'
        />
      )
    }

    const handleEnterKey = (key: any) => {
      if (addOption && onKeyDown) {
        onKeyDown(key, () => addOption(input))
      }
    }

    return (
      <>
        <Typography variant='body1'>{label}</Typography>
        <materialLab.Autocomplete
          ref={ref}
          options={options}
          id='autocomplete-search'
          getOptionLabel={option => option.value}
          renderOption={(option, state) =>
            renderOption
              ? renderOption(option, state)
              : <Typography>{option.value}</Typography>}
          filterOptions={
            !defaultFilter
              ? (options, state) => {
                return options
                  .map((option: { value: string }) => {
                    if (
                      state.inputValue
                        .toLowerCase()
                        .split('')
                        .map(letter =>
                          option.value.toLowerCase().includes(letter)
                        )
                    ) {
                      return option
                    }
                    return null
                  })
                  .filter(i => i !== null)
              }
              : undefined
          }
          renderInput={params => (
            <STextField
              {...params}
              variant='standard'
              label=''
              // error={error}
              placeholder={placeholder}
              onKeyDown={handleEnterKey}
              className={className(
                classes.textfield,
                error ? classes.inputError : ''
              )}
              value={valueInput}
              onChange={event => handleInputChange(event.target.value)}
            />
          )}
          classes={{
            root: classes.root,
            endAdornment: classes.endAdornment,
            clearIndicatorDirty: classes.clearIndicator,
            popupIndicator: classes.popupIndicator,
            popupIndicatorOpen: className(
              classes.popupIndicator,
              popupIcon !== undefined ? classes.popupIndicatorOpen : ''
            ),
            option: classes.option
          }}
          value={value}
          getOptionSelected={(option, value) => {
            if(value.key === '') return true;
            return option.key === value.key; // Compare values using a key
        }}
          // getOptionSelected={(option) => {
          //   return options.length !== optionCount || option.value === value.value
          // }}
          onChange={(_event, value) => {
            onChange(value)
          }}
          PopperComponent={PopperMy}
          popupIcon={popupIcon}
          noOptionsText={
            noOptionText
              ? (
                undefined
              )
              : (
                <Button
                  size='small'
                  onMouseDown={event =>
                    addOption ? addOption(input) : undefined}
                >
                  <Typography variant='caption' className={classes.search}>
                    {' '}
                    Repair not listed? Click here to add your repair{' '}
                  </Typography>
                </Button>
              )
          }
          onBlur={onBlur}
        />
      </>
    )
  }
)

export default AutocompleteSearch
