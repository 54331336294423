import { FC } from 'react'
import { HomeTeamCardProps } from './types'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Mail'
import useStyles from './styles'
import { HOMETEAM_ROLES } from 'helpers/constants'
import { Delete, Edit } from '@material-ui/icons'
import { TrustPilot } from 'assets/index'

const HomeTeamCard: FC<HomeTeamCardProps> = ({
  isSmall,
  profile,
  showButton,
  onEdit,
  onDelete,
  isBosscat,
}) => {
  const styles = useStyles()

  const getRoleColor = (role: string) => {
    if (role === 'Real Estate Agent') {
      return 'var(--bosscat-blue-800)'
    } else if (role === 'Loan Officer') {
      return 'var(--bosscat-green-700)'
    } else if (role === 'Property Inspector') {
      return 'var(--handyman-green-700)'
    } else if (role === 'Insurance Agent') {
      return 'var(--repairs-yellow-800)'
    } else {
      return 'var(--text-text-info)'
    }
  }

  const handleEmailClick = () => {
    window.open(`mailto:${profile.email}`);
  }

  return (
    <Grid container item>
      <Grid
        container
        item
        className={
          isSmall ? styles.smallCardContainer : styles.largeCardContainer
        }
        style={{ backgroundColor: isBosscat ? '#F5F6F7' : 'white', gap: isBosscat ? '16px' : '0' }}
      >
        <Grid
          container
          item
          xl={isBosscat ? 6 : 12}
          lg={12}
          spacing={isSmall ? 1 : 2}
          className={styles.cardContainer}
        >
          <Grid
            item
            container
            style={{ width: 'fit-content', position: 'relative' }}
          >
            {profile.imageUrl ? (
              <img
                src={profile.imageUrl}
                alt="home team"
                className={isSmall ? styles.smallPhoto : styles.largePhoto}
                style={{ objectFit: 'cover' }}
              />
            ) : (
              <PersonIcon
                className={isSmall ? styles.smallPhoto : styles.largePhoto}
              />
            )}
            {showButton && (
              <Box className={styles.buttomWrapper}>
                <Button
                  className={styles.iconButton}
                  onClick={() => onEdit && onEdit(profile)}
                >
                  <Edit className={styles.largeIcon} />
                </Button>
                <Button
                  className={styles.iconButton}
                  style={{ color: '#FF5031' }}
                  onClick={() => onDelete && onDelete(profile)}
                >
                  <Delete className={styles.largeIcon} />
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item container direction="column" style={{ flexGrow: 1 }}>
            <Grid
              item
              style={{
                marginBottom: isSmall ? '0px' : '8px',
                marginTop: isSmall ? '8px' : '0px',
              }}
            >
              <Typography
                className={isSmall ? styles.mdFont : styles.xlFont}
                style={{ textTransform: isSmall ? 'none' : 'uppercase' }}
              >
                {`${profile.firstName} ${profile.lastName}`}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: isSmall ? '8px' : '4px' }}>
              <Typography
                className={isSmall ? styles.xsFont : styles.lgFont}
                style={{
                  color: getRoleColor(
                    HOMETEAM_ROLES.find((role) => role.key === profile.role)
                      ?.value || ''
                  ),
                }}
              >
                {
                  HOMETEAM_ROLES.find((role) => role.key === profile.role)
                    ?.value || profile.role
                }
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ gap: '4px', marginBottom: isSmall ? '0px' : '16px' }}
            >
              <Grid item>
                <AccountBalanceIcon
                  className={isSmall ? styles.smallIcon : styles.largeIcon}
                />
              </Grid>
              <Typography className={isSmall ? styles.xsFont : styles.smFont}>
                {profile.companyName}
              </Typography>
            </Grid>
            <Grid
              item
              container
              className={isSmall ? styles.iconLabel : styles.contactContainer}
              style={{ marginBottom: isSmall ? '0px' : '16px' }}
            >
              <Grid item>
                <PhoneIcon
                  className={isSmall ? styles.smallIcon : styles.largeIcon}
                />
              </Grid>
              <Grid item>
                <Typography className={isSmall ? styles.xsFont : styles.smFont}>
                  {profile.phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={isSmall ? styles.iconLabel : styles.contactContainer}
              style={{ flexWrap: 'nowrap', cursor: 'pointer' }}
              onClick={handleEmailClick}
            >
              <Grid item>
                <MailIcon
                  className={isSmall ? styles.smallIcon : styles.largeIcon}
                />
              </Grid>
              <Grid item>
                <Typography className={isSmall ? styles.xsFont : styles.smFont} style={{ lineBreak: 'anywhere' }}>
                  {profile.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isBosscat && (
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            xl={6}
            lg={12}
          >
            <Grid item>
              <Typography
                className={styles.nextArtLgFont}
                style={{ textAlign: 'center' }}
              >
                My career in real estate spans 12 years and my passion is
                helping homeowners create their dream home.
              </Typography>
            </Grid>
            <Grid item>
              <Box display='flex' justifyContent='center'>
                <img src={TrustPilot} alt='trustpilot' />
              </Box>
              {/* <div
                className="trustpilot-widget"
                data-locale="en-US"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="5ddc1603e77eed000124e2c3"
                data-style-height="150px"
                data-style-width="100%"
                data-theme="light"
                style={{ height: '120px' }}
              >
                <a
                  href="https://www.trustpilot.com/review/punchlistusa.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Trustpilot
                </a>
              </div> */}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default HomeTeamCard
