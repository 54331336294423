import { FC, useState } from 'react'
import { icons, east } from 'assets'
import { useIsXsScreen } from '../../../../../hooks'
import { Typography, Button, Avatar, SvgIcon, Box, Popover } from '../../..'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { BottomBarProps } from './types'
import { useSelector } from 'react-redux'
import {
  getEstimate,
  getItemsInCart,
  getUserIsHomeowner,
} from 'ducks/selectors'

import { isMobile } from 'react-device-detect'
import TotalPrice from 'components/pages/Desktop/Estimates/EstimateViewV2/Cart/components/TotalPrice'
import { CONTACT_ROLE, ESTIMATE_PUBLIC_STATUS } from 'helpers/constants'

const BottomBarEstimate: FC<BottomBarProps> = ({
  disabledForward,
  onRequestMoreItems,
  onOpenCart,
  rmForward = false,
  price,
  showButtons,
  showBetterStyle = false,
  showApproveButton = false,
  isPunchlistPlanner = false,
  shared = false,
  ptp = false,
  serviceable,
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const itemsInCart = useSelector(getItemsInCart())
  const estimate = useSelector(getEstimate())
  const isHomeowner = getUserIsHomeowner()

  const agent = estimate?.properties?.contacts?.find(
    (c: any) =>
      ![
        CONTACT_ROLE.HOMEOWNER,
        CONTACT_ROLE.HOME_BUYER,
        CONTACT_ROLE.HOME_SELLER,
      ].includes(c.role)
  )
  const estimateNotApproved =
    !shared && estimate?.publicStatus !== ESTIMATE_PUBLIC_STATUS.APPROVED
  const estimateIsOutOfArea = !estimate?.properties.territory?.serviceable

  return (
    <div className={classes.row}>
      {isMobile && (
        <TotalPrice
          serviceable={serviceable}
          price={price}
          showRegularPrice={!isMobile}
          ptp={ptp}
          style={{ alignItems: 'flex-start' }}
        />
      )}
      {showButtons && (
        <div className={classes.rowButtons}>
          {!estimate?.properties.clientRequestNotes && !isMobile && estimateNotApproved && !estimateIsOutOfArea && (
            <Button
              type="submit"
              variant="contained"
              size="small"
              className={classes.requestMoreButton}
              fullWidth
              onClick={onRequestMoreItems}
              startIcon={<SvgIcon component={icons.ListAlt} />}
            >
              <Typography> Request More Items</Typography>
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.selectedItems}
            fullWidth
            onClick={onOpenCart}
            startIcon={
              serviceable ? <SvgIcon component={icons.ShoppingCart} /> : <></>
            }
            endIcon={
              isMobile ? (
                <SvgIcon fontSize="small" component={icons.ArrowForward} />
              ) : (
                <></>
              )
            }
          >
            <Typography>
              {!serviceable ||
                (isHomeowner && agent?.approvalType === 'APPROVED')
                ? 'See Selected Items'
                : 'View Cart'}
              {!serviceable && <br />} ({itemsInCart.length}
              {!isMobile ? ' items' : ''})
            </Typography>
          </Button>
        </div>
      )}
    </div>
  )
}

export default BottomBarEstimate
