import { Checkbox, Grid, Typography } from '@material-ui/core'
import { Autocomplete, DatePicker } from 'components/UI'
import {
  getApprovalValues,
  getEstimate,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC } from 'react'
import moment from 'moment'
import { useStepperContext } from 'hooks/useStepperContext'
import { getContactRoleOpt } from 'helpers/optionLists'
import { CONTACT_ROLE } from 'helpers/constants'
import { isEmpty } from 'helpers/index'
import { addBusinessDays } from 'date-fns'

export interface SchedulingProps {
  isHomeowner: boolean
}

const Scheduling: FC<SchedulingProps> = ({ isHomeowner }) => {
  const classes = useStyles()
  const estimate = useSelector(getEstimate())
  const { showWarnings, saveNewValue } = useStepperContext()
  const userEmail = useSelector(getUserPrimaryEmail)

  const { preferredCompletedOn, preferredStartDate, role } = useSelector(
    getApprovalValues()
  )

  const handlepreferredStartDate = (date: Date) => {
    saveNewValue('preferredStartDate', date.valueOf() / 1000)

    if (preferredCompletedOn && date.valueOf() > preferredCompletedOn) {
      saveNewValue('preferredCompletedOn', date.valueOf() / 1000)
    }
  }

  const handleFlexible = (
    type: 'preferredStartDate' | 'preferredCompletedOn'
  ) => {
    const value =
      type === 'preferredStartDate' ? preferredStartDate : preferredCompletedOn
    saveNewValue(type, value === null ? undefined : null)
  }

  const estimateHomeowners = estimate?.properties?.contacts?.filter((c) =>
    [
      CONTACT_ROLE.HOMEOWNER,
      CONTACT_ROLE.HOME_BUYER,
      CONTACT_ROLE.HOME_SELLER,
    ].includes(c.role as CONTACT_ROLE)
  )

  const showHomeownerOptions =
    !estimateHomeowners ||
    estimateHomeowners?.length <= 0 ||
    estimateHomeowners?.some((c) => c.email === userEmail)

  // if the agent is the homeowner we show all the options, if the estimate already have a homeowner we don't show the homeowner's options

  const rolesOpt = showHomeownerOptions
    ? getContactRoleOpt()
    : getContactRoleOpt().filter(
        (role) =>
          role.key !== CONTACT_ROLE.HOMEOWNER &&
          role.key !== CONTACT_ROLE.HOME_BUYER &&
          role.key !== CONTACT_ROLE.HOME_SELLER &&
          role.key !== CONTACT_ROLE.AGENT_HOMEOWNER
      )

  return (
    <>
      <Typography className={classes.title}>📆 scheduling</Typography>
      {!isHomeowner && (
        <Grid item xs={12} lg={4}>
          <Typography
            variant="body2"
            className={classes.question}
            style={{ marginTop: '24px' }}
          >
            What is your role in this transaction?
          </Typography>
          <Autocomplete
            label=""
            error={showWarnings && isEmpty(role)}
            value={rolesOpt.find((o) => o.key === role)}
            options={rolesOpt}
            onChange={(value: any) => {
              saveNewValue('role', value.key)
            }}
          />
        </Grid>
      )}
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography className={classes.latoPrimaryText}>
            What is your <Typography className={`${classes.latoPrimaryText} ${classes.boldUnderline}`}> preferred</Typography> start date for this
            project?
          </Typography>
          <Grid item xs={12} lg={8}>
            <DatePicker
              minDate={addBusinessDays(new Date(), 2)}
              error={showWarnings && typeof preferredStartDate === 'undefined'}
              onChange={handlepreferredStartDate}
              value={
                preferredStartDate
                  ? moment(preferredStartDate * 1000).toDate()
                  : null
              }
              disableHolidays
              disableSameDay
              disableWeekends
            />
            <Grid item className={classes.checkboxContainer}>
              <Checkbox
                checked={preferredStartDate === null}
                onChange={() => handleFlexible('preferredStartDate')}
                name="flexible"
                color="primary"
                className={classes.checkbox}
              />
              <Typography className={classes.consentText}>
                I'm flexible
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography className={classes.latoPrimaryText}>
            When do you need this project completed?
          </Typography>
          <Grid item xs={12} lg={8}>
            <DatePicker
              error={
                showWarnings && typeof preferredCompletedOn === 'undefined'
              }
              minDate={
                preferredStartDate
                  ? moment(preferredStartDate * 1000).toDate()
                  : addBusinessDays(new Date(), 3)
              }
              onChange={(date: Date) =>
                saveNewValue('preferredCompletedOn', date.valueOf() / 1000)
              }
              value={
                preferredCompletedOn
                  ? moment(preferredCompletedOn * 1000).toDate()
                  : null
              }
              disableHolidays
              disableSameDay
              disableWeekends
              //showPopUpCalendar={showCalendarClose}
            />
            <Grid item className={classes.checkboxContainer}>
              <Checkbox
                checked={preferredCompletedOn === null}
                onChange={() => handleFlexible('preferredCompletedOn')}
                name="flexibleClosing"
                color="primary"
                className={classes.checkbox}
              />
              <Typography className={classes.consentText}>
                I'm flexible
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Scheduling
