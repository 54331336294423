import { Box, CircularProgress } from '@material-ui/core';
import { generatePdf } from 'components/pages/Desktop/Estimates/EstimateViewV2/ItemsPdf';
import { DesktopPage } from 'components/templates';
import useQRCodesGenerator from 'hooks/useQRCodeGenerator';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react';
import checkData from './check.json';
import useStyles from './styles';

function EstimatePdf() {
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();
    const dataFromParams = React.useMemo(() => {
        const windowAny = window as any;
        const params = new URLSearchParams(windowAny.location.search);
        const data = params.get('data');
        return JSON.parse(atob(data as string));
    }, []);


    const [qrCode] = useQRCodesGenerator(`${window.location.origin}/p/estimates/${dataFromParams?.userInfo?.estimateId}`)
    const [tutorialVideoQrCode] = useQRCodesGenerator(`https://www.youtube.com/watch?v=5237HyFN44M`)

    useEffect(() => {
        if (dataFromParams && qrCode && tutorialVideoQrCode) {
            generatePdf({
                groupedItems: dataFromParams?.groupedItems,
                evaluateItems: dataFromParams?.evaluateItems,
                userInfo: dataFromParams?.userInfo,
                imageUris: {
                    qrCode, //qrcode here
                    tutorialVideoQrCode,
                },
                marketingImg: dataFromParams?.marketingImg,
                estimate: dataFromParams?.estimate
            }).then(() => {
                setLoading(false)
            });
        }
    }, [dataFromParams, qrCode, tutorialVideoQrCode]);


    return (
        <Box width={"100%"} display="flex" gridGap="16px">
            {loading ? <DesktopPage>
                <Box className={classes.Container} style={{ opacity: 1 }} >
                    <CircularProgress size={40} />
                </Box>
            </DesktopPage> : <DesktopPage>
                <Box className={classes.Container} style={{ opacity: 1 }} >
                    <Lottie
                        animationData={checkData}
                        loop={false}
                        height={'50px'}
                        width={'50px'}
                    />
                    <p>PDF Downloaded Successfully!</p>
                </Box>
            </DesktopPage>}
        </Box>
    )
}

export default EstimatePdf