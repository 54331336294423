import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { getDiscountsValue, getEstimate } from "ducks/selectors"
import { useDispatch, useSelector } from "react-redux"
import useStyles from './styles'
import { icons, vipMedal } from 'assets'
import { useState } from "react"
import { estimateActions } from "ducks/actions"

const DiscountBanner = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const discountBanner = useSelector(getDiscountsValue('discountBanner'))
  const estimate = useSelector(getEstimate())

  const discountVal = discountBanner?.discount * 100
  if (!discountVal) return null

  const promoApplied = discountBanner?.discountCode === estimate?.promotionId

  const handlePromo = () => {
    setLoading(true)
    discountBanner?.discountCode && dispatch(estimateActions.applyPromoCode(discountBanner.discountCode, (succ) => {
      setLoading(false)
    }))
  }

  return (
    <Box className={classes.root}>
      <img src={vipMedal} alt="Vip Medal" className={classes.vipMedal} />
      {discountBanner?.description && <Typography className={classes.title}>{discountBanner?.description}</Typography>}
      {loading ?
        <Box height={40} style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress size="1.4rem" />
        </Box>
        : <Button
          onClick={handlePromo} style={{
            color: promoApplied ? 'var(--green-700)' : '#FFC531'
          }} className={classes.link}
          endIcon={
            promoApplied ?
              <icons.CheckCircle className={classes.linkIcon} />
              :
              <icons.ShoppingCart className={classes.linkIcon} />
          }>
          {promoApplied ? 'Code Applied' : 'Apply Code'}
        </Button>}
    </Box>
  )
}

export default DiscountBanner