import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    monthController: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '20px 0',
    },
    monthControllerLabel: {
      width: '60%',
      height: '40px',
      display: 'flex',
      backgroundColor: 'var(--bosscat-blue-600)',
      color: 'var(--white-color)',
      padding: '12px',
      borderRadius: '8px',
      justifyContent: 'center',
    },
    monthControllerLabelText: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '18.75px',
    },
    monthControllerButton: {
      width: '40px',
      height: '40px',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--progress-color-back)',
      cursor: 'pointer',
    },
    chartRow: {
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        width: '100%',
      },
    },
    button: {
      height: '27px',
      minWidth: '72px',
      margin: '1px',
      backgroundColor: 'var(--progress-color-back)',
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-600) !important',
        color: 'var(--white-color)',
        '& p': {
          color: 'var(--white-color)',
        }
      },
    },
    buttonSelected: {
      height: '27px',
      minWidth: '72px',
      margin: '1px',
      backgroundColor: 'var(--bosscat-blue-600)',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
        color: 'var(--white-color)',
        '& p': {
          color: 'var(--white-color)',
        }
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important',
      }
    },
    month: {
      height: '23px',
      width: '65px',
      color: 'var(--text-color)',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      margin: '2px 0px',
      fontFamily: 'LatoLight',
      '&:hover': {
        color: 'var(--white-color)'
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    monthSelected: {
      color: 'var(--white-color)',
      margin: '2px'
    },
  })
)
