import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
    createStyles({
        tag: {
            backgroundColor: 'var(--input-color)',
            color: 'var(--white-color)',
            boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
            borderRadius: '8px',
            "@global": {
                ".MuiTypography-root": {
                    fontFamily: 'Lato',
                    padding: '0px 8px'
                }
            },
            margin: '2px 4px'
        },
        tagMore: {
            backgroundColor: 'var(--bosscat-blue-600)',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            height: 'fit-content',
            flexWrap: 'wrap'
        }
    })
)
