import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, Typography } from "components/UI";
import { useState } from "react";
import { estimateNote, icons } from "assets";
import { Counter } from "components/UI/CustomUI";
import useStyles from './styles'

export default function NewEstimateModal() {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    const closeDialog = () => setOpen(false)
    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
            onClose={() => setOpen(false)}
            classes={{ paper: classes.root }}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton onClick={closeDialog} className={classes.closeButton} ><icons.Close /></IconButton></DialogTitle>
            <DialogContent>
                <Box display='flex' alignItems='center' justifyContent='center' marginBottom='16px'>
                    <img src={estimateNote} alt='Notepad' />
                </Box>
                <Typography align='center' variant='h4' className={classes.title}>
                    Your estimate is <span>Ready!!</span>
                </Typography>
                <Typography align='center' variant='subtitle2'>
                    Here are some tips on viewing your estimate.
                </Typography>
                <Box display='flex' flexDirection='column' marginY='8px' alignItems='center'>
                    <Box flex='1'>
                        <Typography variant='body2'>
                            1. For best utilization of your estimate, please update quantities for each item as needed. You can change the quantity for each repair item by clicking on these buttons.
                        </Typography>
                    </Box>
                    <Box flex='1' alignItems='flex-end' style={{ zoom: 0.7 }}>
                        <Counter
                            initialValue={3}
                            color={'primary'}
                        />
                    </Box>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box >
                        <Typography variant='body2'>
                            2. To see detailed descriptions and photos, click on the blue button.
                        </Typography>
                    </Box>
                    <Box marginLeft='5px'>
                        <Box flex='0.1' bgcolor='var(--bosscat-blue-600)' className={classes.expandMore}>
                            <icons.ExpandMore className={classes.expandMoreIcon} />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.button}
                    variant='contained'
                    fullWidth={true}
                    onClick={closeDialog}>
                    View Estimate
                </Button>
            </DialogActions>
        </Dialog>
    )
}