import { Address, Estimate, Item, PaymentOption } from 'ducks/types'

/** TYPES**/
export const newRepairListTypes = {
  CLEAN: 'CLEAN_NEW_REPAIR_LIST',
  SET_STEP: 'SET_NEW_REPAIR_LIST_STEP',
  SET_VALUE: 'SET_NEW_REPAIR_LIST_VALUE',
  SET_VALUES: 'SET_NEW_REPAIR_LIST_VALUES',
  CREATE_REPAIR_LIST: 'CREATE_REPAIR_LIST',
  UPDATE_REPAIR_LIST: 'UPDATE_REPAIR_LIST'
}

/** ACTIONS**/
export interface CleanNewRepairListAction {
  type: typeof newRepairListTypes.CLEAN
  payload: null
}

export interface NewRepairListSetStepAction {
  type: typeof newRepairListTypes.SET_STEP
  payload: number
}

export interface NewRepairListValue {
  attr: keyof NewRepairListType
  value: any
}

export interface NewRepairListSetValueAction {
  type: typeof newRepairListTypes.SET_VALUE
  payload: NewRepairListValue
}

export interface NewRepairListValues {
  attrs: Partial<NewRepairListType>
}

export interface NewRepairListSetValuesAction {
  type: typeof newRepairListTypes.SET_VALUES
  payload: NewRepairListValues
}

export interface CreateRepairListAction {
  type: typeof newRepairListTypes.CREATE_REPAIR_LIST
  payload: PaymentOption
  callback: (succ: boolean, estimateId?: string) => void
}

export interface UpdateRepairListAction {
  type: typeof newRepairListTypes.UPDATE_REPAIR_LIST
  payload: PaymentOption
  callback: (succ: boolean, estimateId?: string) => void
}

export type NewRepairListActions =
  | CleanNewRepairListAction
  | NewRepairListSetStepAction
  | NewRepairListSetValueAction
  | NewRepairListSetValuesAction
  | CreateRepairListAction
  | UpdateRepairListAction

/** REDUCER **/
export interface NewRepairListType {
  step: number
  estimate: Partial<Estimate>
  paymentOptions: PaymentOption[] | null
  paymentOption: PaymentOption | null
  clientSecret: string
  isExpedite: boolean

  address: Address | Partial<Address>
  items: Array<Partial<Item>>
}
