import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import useStyles from './styles'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import {
  ESTIMATE_VIEW,
  ITEM_CATEGORY,
  ITEM_CATEGORY_FOR_FILTERS,
  ITEM_PRIORITY,
  ITEM_STATUS,
  sortItemsByCategories,
} from 'helpers/index'
import { useSelector } from 'react-redux'
import { getEstimateItemsGroupBy } from 'ducks/selectors'
import EstimateItem from '../EstimateItem'
import { Item } from 'ducks/types'
import { icons } from 'assets'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import HomeConsultationModal from '../HomeConsultationModal'
import EstimateItemsContainer from '../EstimateItemsContainer'
import { isMobile } from 'react-device-detect'

export interface NeedMoreInformationProps {
  estimateNotApproved?: boolean
  showBetterStyle?: boolean
  punchlistPlanner?: boolean
  serviceable?: boolean
  handleShowAlert?: (item: Item) => void
  handleRemove?: (estimateId: string, itemId: string, removed: boolean) => void
  fetchEstimateView: (loadings: boolean) => void
  selectedFilt?: ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY
}

const NeedMoreInformation: FC<NeedMoreInformationProps> = ({
  estimateNotApproved,
  showBetterStyle,
  punchlistPlanner,
  handleShowAlert,
  handleRemove,
  serviceable,
  selectedFilt,
  fetchEstimateView,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(true)
  const [selectedItemsForConsultation, setSelectedItemsForConsultation] =
    useState<Item[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const groupedItems = sortItemsByCategories(
    useSelector(
      getEstimateItemsGroupBy(
        ESTIMATE_VIEW.TYPE,
        false,
        selectedFilt !== ITEM_CATEGORY_FOR_FILTERS.ALL
          ? selectedFilt
          : undefined
      )
    )
  )

  const evaluateItems = groupedItems[ITEM_CATEGORY.EVALUATE]

  const handleClickConsultation = (item: Item) => {
    if (
      selectedItemsForConsultation.some(
        (selectedItem) => selectedItem.itemId === item.itemId
      )
    ) {
      setSelectedItemsForConsultation(
        selectedItemsForConsultation.filter(
          (selectedItem) => selectedItem.itemId !== item.itemId
        )
      )
    } else {
      setSelectedItemsForConsultation([...selectedItemsForConsultation, item])
    }
  }

  const openHomeConsultation = () => {
    if (selectedItemsForConsultation.length > 0) {
      setModalOpen(true)
    }
  }
  if (!evaluateItems || evaluateItems.length < 1) return <></>

  return (
    <Box /* className={classes.root} */>
      <Modal
        open={modalOpen}
        setOpen={() => {
          // setSelectedItemsForConsultation([])
          setModalOpen(!modalOpen)
        }}
        className={classes.modal}
      >
        <HomeConsultationModal
          selectedItemsForConsultation={selectedItemsForConsultation}
          onSuccess={() => {
            fetchEstimateView(false)
            setSelectedItemsForConsultation([])
          }}
        />
      </Modal>

      <Box hidden={!open} className={classes.container}>
        <EstimateItemsContainer
          groupedItems={evaluateItems}
          title={
            <Grid item>
              <Typography className={classes.title}>
                need more information {isMobile && `(${evaluateItems.length})`}
              </Typography>
              <Typography className={classes.subtitle}>
                Additional Information is needed to provide a precise estimate.
                Please consult with a professional in your area.
              </Typography>
            </Grid>
          }
          action={
            serviceable && estimateNotApproved ? (
              <Grid item>
                <Button
                  onClick={openHomeConsultation}
                  className={`${classes.button} ${classes.blueButton}`}
                  disabled={selectedItemsForConsultation.length < 1}
                >
                  Request In-Home Consultation (
                  {selectedItemsForConsultation.length})
                </Button>
              </Grid>
            ) : (
              <></>
            )
          }
          actionsAtBottom={isMobile}
          className={classes.root}
          showCollapse
        >
          {evaluateItems?.map((item: Item, index: number) => {
            const itemIsAdded = selectedItemsForConsultation.some(
              (selectedItem) => selectedItem.itemId === item.itemId
            )
            return (
              <Fragment key={item.itemId}>
                <EstimateItem
                  item={item}
                  // showAlert={() => handleShowAlert && handleShowAlert(item)}
                  isPunchlistPlanner={punchlistPlanner}
                  className={`${classes.evaluateItemContainer} ${
                    item.consultationRequestedDate
                      ? classes.itemContainerRequested
                      : ''
                  }`}
                  listItemDirection="row-reverse"
                  actions={
                    serviceable && estimateNotApproved ? (
                      item.consultationRequestedDate ? (
                        <Typography className={classes.requestedDate}>
                          Requested for: {item.consultationRequestedDate}
                        </Typography>
                      ) : (
                        <Button
                          className={`${classes.button} ${
                            itemIsAdded ? classes.buttonRemove : ''
                          }`}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            event.stopPropagation()
                            handleClickConsultation(item)
                          }}
                        >
                          {isMobile ? (
                            itemIsAdded ? (
                              <icons.Check className={classes.iconMobile} />
                            ) : (
                              <icons.Add className={classes.iconMobile} />
                            )
                          ) : itemIsAdded ? (
                            'Remove'
                          ) : (
                            'Add for Consultation'
                          )}
                        </Button>
                      )
                    ) : (
                      <Box></Box>
                    )
                  }
                />
              </Fragment>
            )
          })}
        </EstimateItemsContainer>
      </Box>
    </Box>
  )
}

export default NeedMoreInformation
