import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: '100px',
      width: 100,
      height: 160,
      marginTop: '-180px',
      textAlign: 'center',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '58px',
      color: 'var(--head-text-color)',
      [theme.breakpoints.down('sm')]: {
        height: 80,
        fontSize: '14px',
        lineHeight: '22px',
        marginLeft: '30px',
        marginTop: '-90px'
      }
    },
    titleRange: {
      marginLeft: "75px",
      marginTop: "-180px",
      // whiteSpace: "break-spaces",
      fontSize: "16px",
      width: "150px",
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        marginTop: "-125px",
        marginLeft: "40px",
      }
    }
  })
)
