import { CONTACT_ROLE, FORM_TYPE, REPAIR_TIMELINE } from 'helpers'
import {
  Address,
  Estimate,
  EstimateContact,
  PaymentOption,
  PunchlistFile,
} from '../types'
import { FetchEstimatesServicesPayload } from 'ducks/estimates/types'
import { UpdateRequest } from 'api/types'

/** TYPES**/
export const newEstimateTypes = {
  CLEAN: 'CLEAN_NEW_ESTIMATE',
  ERASE: 'ERASE_NEW_ESTIMATE',
  SET_STEP: 'SET_NEW_ESTIMATE_STEP',
  SET_SUBSTEP: 'SET_NEW_ESTIMATE_SET_SUBSTEP',
  SET_VALUE: 'SET_NEW_ESTIMATE_VALUE',
  SET_VALUES: 'SET_NEW_ESTIMATE_VALUES',
  CREATE_ESTIMATE: 'CREATE_ESTIMATE',
  CREATE_ESTIMATE_V2: 'CREATE_ESTIMATE_V2',
  UPDATE_ESTIMATE_PAYMENT_OPTION: 'UPDATE_ESTIMATE_PAYMENT_OPTION',
  UPDATE_ESTIMATE: 'UPDATE_ESTIMATE',
  UPDATE_ESTIMATE_V3: 'UPDATE_ESTIMATE_V3',
  UPDATE_ESTIMATE_ADDRESS: 'UPDATE_ESTIMATE_ADDRESS',
  REPLACE_CONTACT_IN_ESTIMATE: 'REPLACE_CONTACT_IN_ESTIMATE',
  DELETE_VALUE_IN_ESTIMATE: 'DELETE_VALUE_IN_ESTIMATE',
  NEW_FETCH_ESTIMATES_SERVICES: 'NEW_FETCH_ESTIMATES_SERVICES',
  UPDATE_NEW_ESTIMATE_BY_PATH: 'UPDATE_NEW_ESTIMATE_BY_PATH',
}

/** ACTIONS**/
export interface CleanNewEstimateAction {
  type: typeof newEstimateTypes.CLEAN
  payload: null
}

export interface NewEstimateSetStepAction {
  type: typeof newEstimateTypes.SET_STEP
  payload: number
}

export interface NewEstimateValue {
  attr: keyof NewEstimateType
  value: any
}

export interface NewEstimateSetValueAction {
  type: typeof newEstimateTypes.SET_VALUE
  payload: NewEstimateValue
}

export interface NewEstimateValues {
  attrs: Partial<NewEstimateType>
}

export interface NewEstimateSetValuesAction {
  type: typeof newEstimateTypes.SET_VALUES
  payload: NewEstimateValues
}

export interface CreateEstimateAction {
  type: typeof newEstimateTypes.CREATE_ESTIMATE
  payload: PaymentOption | null
  callback: (succ: boolean, estimateId?: string) => void
}

export interface CreateEstimateV2Action {
  type: typeof newEstimateTypes.CREATE_ESTIMATE_V2
  payload?: Partial<Estimate>
  callback: (succ: boolean, estimateId?: string) => void
}

export interface UpdateEstimatePaymentOtionAction {
  type: typeof newEstimateTypes.UPDATE_ESTIMATE_PAYMENT_OPTION
  payload: PaymentOption | null
  callback: (succ: boolean, estimateId?: string) => void
}

export interface UpdateEstimateAction {
  type: typeof newEstimateTypes.UPDATE_ESTIMATE
  payload: Partial<Estimate> | null
  callback: (succ: boolean, estimate?: Estimate) => void
}

export interface UpdateNewEstimateByPathAction {
  type: typeof newEstimateTypes.UPDATE_NEW_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback: (succ: boolean, estimate?: Estimate) => void
}

export interface UpdateEstimateV3Action {
  type: typeof newEstimateTypes.UPDATE_ESTIMATE
  payload: Array<any> | null
  callback: (succ: boolean, estimateId?: string) => void
}

export interface UpdateEstimateAddress {
  type: typeof newEstimateTypes.UPDATE_ESTIMATE_ADDRESS
  payload: Address | null
  callback: (succ: boolean, estimateId?: string) => void
}

export interface ReplaceValueInEstimateAction {
  type: typeof newEstimateTypes.REPLACE_CONTACT_IN_ESTIMATE
  payload: ReplaceInterface
  callback: (succ: boolean, estimateId?: string) => void
}

export interface DeleteValueInEstimateAction {
  type: typeof newEstimateTypes.DELETE_VALUE_IN_ESTIMATE
  payload: string
  callback: (succ: boolean, estimateId?: string) => void
}
export interface FetchEstimatesServicesAction {
  type: typeof newEstimateTypes.NEW_FETCH_ESTIMATES_SERVICES
  payload: FetchEstimatesServicesPayload
  callback?: (succ: boolean, services: Array<any>) => void
}

export interface UpdateNewEstimateByPathAction {
  type: typeof newEstimateTypes.UPDATE_NEW_ESTIMATE_BY_PATH
  payload: UpdateRequest
  callback: (succ: boolean, estimate?: Estimate) => void
}

export interface ReplaceInterface {
  path: string
  value: any
}

export type NewEstimateActions =
  | CleanNewEstimateAction
  | NewEstimateSetStepAction
  | NewEstimateSetValueAction
  | NewEstimateSetValuesAction
  | CreateEstimateAction
  | CreateEstimateV2Action
  | UpdateEstimatePaymentOtionAction
  | UpdateEstimateAction
  | UpdateEstimateV3Action
  | UpdateEstimateAddress
  | ReplaceValueInEstimateAction
  | DeleteValueInEstimateAction
  | FetchEstimatesServicesAction
  | UpdateNewEstimateByPathAction
/** REDUCER **/
export interface NewEstimateType {
  step: number
  substep: number
  estimate: Partial<Estimate>
  paymentOptions: PaymentOption[] | null
  paymentOption: PaymentOption | null
  clientSecret: string
  isExpedite: boolean
  clientType: string
  serviceable: boolean
  redirectAfterLogin: boolean
  showWelcome: boolean
  inspectionConsent?: boolean

  mainContact: Partial<EstimateContact>
  address: Address | Partial<Address>
  additionalEmails: string
  startDate?: number | null
  closingDate?: number
  clientNotes: string
  repairTime: REPAIR_TIMELINE | ''
  inspectionFiles: null | PunchlistFile[]
  addendumFiles: null | PunchlistFile[]
  repairListFiles: null | PunchlistFile[]
  signature?: string
  remember?: string
  flexible?: string
  responsible?: string
  company?: string
  phoneCompany?: string
  mailCompany?: string
  name?: string
  phone?: string
  mail?: string
  role?: string
  isNar?: boolean | null
  affiliate?: string
  items?: any[]
  otherRequestsList?: string
  projectFiles?: any[]
  otherRequestsUpload?: string
  formType?: FORM_TYPE
  contacts?: Partial<EstimateContact>
  uploaded?: number
  userIsloggedIn?: boolean
  repairTimeline?: REPAIR_TIMELINE
  propertyToBeSold?: boolean
  isUnderContract?: boolean
  represent?: CONTACT_ROLE
  preferredCommunicationPhone: boolean
  preferredCommunicationText: boolean
  preferredCommunicationMail: boolean
  activeStepProgress?: number
  totalStepProgress?: number
}
