/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import {
  estimateTypes,
  FetchEstimateAction,
  AddEstimateToNotificationListAction,
  ApprovalEstimateAction,
  ShareEstimateAction,
  UpdateEstimateByPathAction,
  ApplyPromoCodeAction,
  AddItemsToEstimateAction,
  RequestMoreItemsAction,
  UpdateEstimatePropertiesAction,
} from './types'
import { estimateActions } from 'ducks/actions'
import { getEstimate } from './selectors'
import * as Sentry from '@sentry/react'
import { UpdateAttribute, UpdateRequest } from 'api/types'
import { EstimateProperties } from 'ducks/types'

export function* fetchEstimate({
  payload,
  callback,
}: FetchEstimateAction): SagaIterator {
  let success = false
  try {
    const estimate = yield call(Punchlist.estimates.getEstimateById, payload)

    if (estimate) {
      yield put(estimateActions.setEstimate(estimate))
    }
    success = true
    if (callback) yield call(callback, success, estimate)
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* shareEstimate({
  payload,
  callback,
}: ShareEstimateAction): SagaIterator {
  try {
    const { id } = yield select(getEstimate())
    yield call(Punchlist.estimates.share, id, payload)
    yield call(toast.success, 'The estimate was shared successfully!')
    if (callback) yield call(callback, true)
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('get the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateByPath({
  payload,
  callback,
}: UpdateEstimateByPathAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    if (estimateId) {
      const estimate = yield call(
        Punchlist.estimates.updateEstimate,
        estimateId,
        payload
      )

      if (!isEmpty(estimate)) {
        yield put(estimateActions.setEstimate(estimate))
        if (callback) yield call(callback, true, estimate)
      }
    } else {
      if (callback) yield call(callback, false)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('update the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateEstimateProperties({
  payload,
  callback,
}: UpdateEstimatePropertiesAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    const request: UpdateRequest = []

    for (const attr in payload) {
      const isNullValue =
        payload[attr as keyof EstimateProperties] === null ||
        typeof payload[attr as keyof EstimateProperties] === 'undefined'
      const item: UpdateAttribute = {
        op: !isNullValue ? 'add' : 'remove',
        path: '/properties/' + attr,
      }
      if (!isNullValue) {
        item.value = payload[attr as keyof EstimateProperties]
      }
      request.push(item)
    }

    const estimate = yield call(
      Punchlist.estimates.updateEstimate,
      estimateId,
      request
    )
    if (!isEmpty(estimate)) {
      yield put(estimateActions.setEstimate(estimate))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('update estimate info'))
    yield call(callback, false)
  }
}

export function* approval({
  payload,
  callback,
}: ApprovalEstimateAction): SagaIterator {
  let success = false
  try {
    const res = yield call(
      Punchlist.estimates.approval,
      payload.id as string,
      payload
    )
    if (res) {
      success = true
    }
    if (callback)
      yield call(
        callback,
        success,
        res.approvalStatus,
        res?.properties?.payAtClose
      )
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('approve the estimate'))
    if (callback) yield call(callback, false, '', false)
  }
}
export function* applyPromoCode({
  payload,
  callback,
}: ApplyPromoCodeAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    const estimate = yield call(
      Punchlist.estimates.applyPromoCode,
      estimateId,
      payload
    )

    if (!isEmpty(estimate)) {
      yield put(estimateActions.setEstimate(estimate))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('get the promo code'))
    if (callback) yield call(callback, false)
  }
}

export function* addItemsToEstimate({
  payload,
  callback,
}: AddItemsToEstimateAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    const items = yield call(
      Punchlist.estimates.addItemsToEstimate,
      estimateId,
      payload
    )
    // const estimate = yield call(Punchlist.estimates.addItemsToEstimate, estimateId, payload)

    if (!isEmpty(items)) {
      // yield put(estimateActions.setEstimate(estimate))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('add items to the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* removeItemsFromEstimate({
  payload,
  callback,
}: AddItemsToEstimateAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    const items = yield call(
      Punchlist.estimates.deleteEstimateItems,
      estimateId,
      payload
    )

    if (!isEmpty(items)) {
      // yield put(estimateActions.setEstimate(estimate))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('add items to the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* addEstimateToNotificationList({
  callback,
  payload,
}: AddEstimateToNotificationListAction): SagaIterator {
  try {
    yield call(Punchlist.estimates.addEstimateToNotificationList, payload)
    if (callback) yield call(callback, true)
  } catch (error) {
    Sentry.captureException(error)
    yield call(
      toast.error,
      errorTextTryingTo('add the estimate to the notification list')
    )
    if (callback) yield call(callback, false)
  }
}

export function* requestMoreItems({
  payload,
  callback,
}: RequestMoreItemsAction): SagaIterator {
  try {
    const { id: estimateId } = yield select(getEstimate())

    const estimate = yield call(
      Punchlist.estimates.requestMoreItems,
      estimateId,
      { requestNotesUpdate: payload }
    )

    if (!isEmpty(estimate)) {
      yield put(estimateActions.setEstimate(estimate))
      if (callback) yield call(callback, true)
    }
  } catch (error) {
    Sentry.captureException(error)
    yield call(toast.error, errorTextTryingTo('add items to the estimate'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(estimateTypes.FETCH_ESTIMATE, fetchEstimate)
  yield takeLatest(estimateTypes.APPROVAL, approval)
  yield takeLatest(estimateTypes.SHARE_ESTIMATE, shareEstimate)
  yield takeLatest(estimateTypes.UPDATE_ESTIMATE_BY_PATH, updateEstimateByPath)
  yield takeLatest(
    estimateTypes.UPDATE_ESTIMATE_PROPERTIES,
    updateEstimateProperties
  )
  yield takeLatest(estimateTypes.APPLY_PROMO_CODE, applyPromoCode)
  yield takeLatest(estimateTypes.ADD_ITEMS, addItemsToEstimate)
  yield takeLatest(estimateTypes.REMOVE_ITEMS, removeItemsFromEstimate)
  yield takeLatest(
    estimateTypes.ADD_ESTIMATE_TO_NOTIFICATION_LIST,
    addEstimateToNotificationList
  )
  yield takeLatest(estimateTypes.REQUEST_MORE_ITEMS, requestMoreItems)
}
