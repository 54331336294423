import { Partner } from 'ducks/types'
import { HomeMartActions, homemartTypes } from './types'

const actions = {
  fetchPartners: (
    payload: {
      active?: boolean,
    },
    callback?: (succ: boolean) => void
  ): HomeMartActions => ({
    type: homemartTypes.FETCH_PARTNERS,
    payload,
    callback
  }),
  setPartners: (payload: Partner[]): HomeMartActions => ({
    type: homemartTypes.SET_PARTNERS,
    payload
  }),
  fetchTags: (
    callback?: (succ: boolean) => void
  ): HomeMartActions => ({
    type: homemartTypes.FETCH_TAGS,
    payload: null,
    callback
  }),
  fetchCustomerTypes: (
    callback?: (succ: boolean) => void
  ): HomeMartActions => ({
    type: homemartTypes.FETCH_CUSTOMER_TYPES,
    payload: null,
    callback
  }),
  setTags: (payload: Partner[]): HomeMartActions => ({
    type: homemartTypes.SET_TAGS,
    payload
  }),
  setCustomerTypes: (payload: Partner[]): HomeMartActions => ({
    type: homemartTypes.SET_CUSTOMER_TYPES,
    payload
  })
}

export default actions
