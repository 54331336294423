import axios from '../axios'
import { ApiController } from './types'

const base = '/orders'

const apiController: ApiController = {
  getOrderById: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}`)
      .then(response => response.data),
}

export default apiController
