import { Box, Button, SvgIcon, Typography } from "@material-ui/core";
import BottomSheet from "components/UI/CustomUI/molecules/BottomSheet";
import { userActions } from "ducks/actions";
import { getUser } from "ducks/selectors";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useStyles from './styles'
import { vipMedal, icons } from 'assets'
import { history } from "helpers/index";

import Lottie from 'lottie-react'
import confetti from 'assets/lotties/confetti.json'

import { isMobile } from "react-device-detect";

const WelcomeVIPModal = () => {
  const [animationCompleted, setAnimationCompleted] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()
  const push = history.usePush()

  const [openFirstTimeModal, setOpenFirstTimeModal] = useState(true)

  const handleCloseFirstTimeModal = () => {
    // localStorage.setItem("welcomeViewed", "true")
    dispatch(userActions.updateUser({
      protectPlanIsNew: false
    }, () => { }))
    setOpenFirstTimeModal(false)
  }

  return (
    <Box>
      {!animationCompleted &&
        <Box style={{ position: 'absolute', zIndex: 9999, top: isMobile ? '40%' : 'inherit', width: '100%', left: 0 }}>
          <Lottie
            animationData={confetti}
            loop={false}
            onComplete={() => setAnimationCompleted(true)}
            height={isMobile ? '100%' : '50%'}
            width={isMobile ? '100%' : '50%'}
          />
        </Box>
      }
      <BottomSheet
        title={""}
        open={openFirstTimeModal}
        onClose={handleCloseFirstTimeModal}>
        <Box className={classes.FirstTimeWrapper}>
          <img className={classes.FirstTimeMedal} src={vipMedal} alt="" />
          <Typography className={classes.FirstTimeTitle}>Welcome to the BOSSCAT Maintenance Program!</Typography>
          <Typography className={classes.FirstTimeSubtitle}>You'll receive four maintenance service visits over the next year.</Typography>
          <Button
            variant='contained'
            onClick={() => { push('p/maintenance'); handleCloseFirstTimeModal() }}
            className={classes.button}
            style={{ width: "fit-content", padding: "1.7rem", marginTop: "1rem" }}
            endIcon={<SvgIcon component={icons.ArrowDownward} className={classes.arrow} />}>
            View Dashboard
          </Button>
        </Box>
      </BottomSheet>
    </Box>
  )
}

export default WelcomeVIPModal