import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      // alignItems: 'center',
      justifyContent: 'center'
    },
    bold: {
      fontFamily: 'NextArtHeavy !important',
      textTransform: 'uppercase',
    },
    content: {
      maxWidth: theme.breakpoints.values.lg,
      margin: 'auto 0px',
      height: '100%',
      paddingRight: '24px'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    containerFormMax: {
      //maxWidth: '660px',
      width: '100%'
    },
    containerForm: {
      width: '100%'
    },
    center: {
      backgroundColor: '#FFFFFF',
      padding: '0 20px'
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: 28,
      color: "#A5965A",
      width: '100%',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important'
        }
      },
      margin: '0.8rem 0 0.8rem 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px'
      }
    },
    subtitle: {
      fontFamily: 'Lato !important',
      margin: '0.8rem 0',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    titleContact: {
      fontFamily: 'LatoBold',
      fontSize: '24px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    },
    textBlack: {
      fontFamily: 'Lato !important',
      fontWeight: 700,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '20px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
        fontSize: '18px !important'
      }
    },
    text: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem'
      }
    },
    text2: {
      fontFamily: 'LatoNormal !important',
      fontWeight: 400,
      marginTop: '0.8rem',
      marginBottom: '0.8rem',
      fontSize: '16px !important',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
        fontSize: '16px !important'
      }
    },
    bottom: {
      marginBottom: '0.8rem'
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonAdd: {
      margin: '16px 0px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      border: '1px solid var(--bosscat-blue-600)',
      padding: '.2rem 1rem'
    },
    buttonRemove: {
      margin: '16px 0px',
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: '#E01F1F;',
      backgroundColor: '#FFF4F4',
      borderRadius: 8,
      border: '1px solid #FFF4F4',
      textAlign: 'right',
      '&:hover': {
        color: '#E01F1F',
        backgroundColor: '#FFF4F4',
        border: '1px solid #EB5151'
      }
    },
    check: {
      flex: 1,
      paddingRight: '16px',
      margin: '0px 5px !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      '& .MuiFormControlLabel-root .MuiIconButton-root.Mui-checked': {
        border: '2px solid #2F7BF7',
      },
    },
    selected: {
      alignItems: 'center',
      borderColor: '#2F7BF7'
    },
    checkConsent: {
      fontFamily: 'LatoNormal'
    },
    consent: {
      marginTop: '8px',
      flexWrap: 'nowrap'
    },
    homeOwnerConsent: {
      margin: '1rem 0 2rem'
    },
    homeOwnerPreferredMethod: {
      margin: '3rem 0'
    },
    noContactsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    noContactsImage: {
      marginBottom: '.5rem',
    },
    noContactsSubtitle: {
      width: '20%',
      fontWeight: 400,
      textAlign: 'center',
    },
    /*     noContactsImageRelative:{
          '&::before': {
            content: '""',
            backgroundImage: 'url(/no-contacts-arrow.svg)',
            display: 'block',
            position: 'absolute',
            height: 60,
            marginTop: -60
          }
        }, */
    noContactsArrow: {

    },
    buttonsContainer: {
      /* position: 'absolute',
      bottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        position: 'static',
      } */
    },
  })
)
