/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */

import { SagaIterator } from 'redux-saga'
import { all, AllEffect } from 'redux-saga/effects'

import authSaga from './auth/saga'
import dashboardSaga from './dashboard/saga'
import estimateSaga from './estimate/saga'
import estimateItemsSaga from './estimateItems/saga'
import estimatesSaga from './estimates/saga'
import feedbackSaga from './feedback/saga'
import filesSaga from './files/saga'
import orderSaga from './order/saga'
import jobSaga from './job/saga'
import jobItemsSaga from './jobItems/saga'
import jobsSaga from './jobs/saga'
import newEstimateSaga from './newEstimate/saga'
import newInspectionSaga from './newInspection/saga'
import newRepairListSaga from './newRepairList/saga'
import paymentsSaga from './payments/saga'
import paymentSaga from './payment/saga'
import propertiesSaga from './properties/saga'
import propertyDetailSaga from './propertyDetail/saga'
import propertyContactsSaga from './propertyContacts/saga'
import propertyActivitySaga from './propertyActivity/saga'
import territoriesSaga from './territories/saga'
import userSaga from './user/saga'
import invoiceSaga from './invoice/saga'
import adminSaga from './admin/saga'
import orderMaintenanceSaga from './orderMaintenance/saga'
import subscriptionSaga from './subscription/saga'
import subscriptionsSaga from './subscriptions/saga'
import docusignSaga from './docusign/saga'
import promosSaga from './promos/saga'
import discoverySaga from './discovery/saga'
import clientQuestionsSaga from './clientQuestions/saga'
import discountsSaga from './discounts/saga'
import itemsSaga from './items/saga'
import campaignsSaga from './campaigns/saga'
import homemartSaga from './homemart/saga'


const rootSaga = function* rootSaga(): Generator<AllEffect<SagaIterator<any>>, void, unknown> {
  yield all([
    authSaga(),
    dashboardSaga(),
    estimateSaga(),
    estimateItemsSaga(),
    estimatesSaga(),
    feedbackSaga(),
    filesSaga(),
    jobSaga(),
    jobItemsSaga(),
    jobsSaga(),
    orderSaga(),
    newEstimateSaga(),
    newInspectionSaga(),
    newRepairListSaga(),
    paymentsSaga(),
    paymentSaga(),
    propertiesSaga(),
    propertyDetailSaga(),
    propertyContactsSaga(),
    propertyActivitySaga(),
    territoriesSaga(),
    userSaga(),
    invoiceSaga(),
    adminSaga(),
    orderMaintenanceSaga(),
    subscriptionSaga(),
    subscriptionsSaga(),
    docusignSaga(),
    promosSaga(),
    discoverySaga(),
    clientQuestionsSaga(),
    discountsSaga(),
    itemsSaga(),
    campaignsSaga(),
    homemartSaga(),
  ])
}

export default rootSaga
