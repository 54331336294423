import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    hammer: {
      width: '20px',
      height: '20px'
    },
    afterRepair: {
      background: 'var(--background-color-secondary)',
      borderRadius: '0 0 16px 16px',
      margin: '8px',
      padding: '8px 12px !important'
    },
    afterRepairTitle: {
      display: 'flex',
      gap: '8px',
      borderRadius: '0 0 16px 16px',
      alignItems: 'center'
    },
    afterRepairFontFamily: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
    },
    titleText: {
      fontFamily: 'NextArtBold',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '150%',
    },
    subTitleText: {
      fontFamily: 'NextArtBold',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '150%',
    },
    text: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '150%',
      color: 'var(--input-text)'
    },
    options: {
      display: 'flex',
      gap: '16px',
      float: 'right'
    },
    buttonApprove: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      '&:hover': {
        color: 'var(--white-color)',
        backgroundColor: 'rgba(14, 144, 90, 1)'
      }
    },
    buttonDecline: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: theme.palette.error.dark,
      backgroundColor: 'rgba(255, 244, 244, 1)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '160px'
      },
      '&:hover': {
        color: theme.palette.error.dark,
        backgroundColor: 'rgba(235, 81, 81, 0.5)'
      }
    },
    titleContainer: {
      gap: "1rem",
      width: 'fit-content'
    },
    price: {
      color: 'var(--input-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
    }
  })
)
