/* eslint-disable */
import { useHistory, useLocation, useParams } from 'react-router-dom'

export function usePush(): (path: string) => void {
  const history = useHistory()
  return (path: string) => {
    history?.push('/'.concat(path))
  }
}

export function usePushParams(): (param: string) => void {
  const { push: pushHistory } = useHistory()
  return (param: string) => {
    pushHistory({
      search: param
    })
  }
}

export function usePushForward(): (path: string) => void {
  const { push: pushHistory } = useHistory()
  const location = useLocation()
  return (path: string) => {
    pushHistory(location.pathname.concat('/'.concat(path)))
  }
}

export function useGoBack(): () => void {
  const { goBack: goBackHistory } = useHistory()
  return () => {
    goBackHistory()
  }
}

export function useReplace(): (path: string) => void {
  const { replace: replaceHistory } = useHistory()
  return (path: string) => {
    replaceHistory(path)
  }
}

export function useReplaceLast(): (path: string) => void {
  const { replace: replaceHistory } = useHistory()
  const location = useLocation()
  const splitedPath = location.pathname.split('/')
  splitedPath.pop()
  return (path: string) => {
    replaceHistory(splitedPath.join('/').concat('/', path))
  }
}

export function useReplaceBack(): () => void {
  const { replace: replaceHistory } = useHistory()
  const location = useLocation()
  const splitedPath = location.pathname.split('/')
  splitedPath.pop()
  return () => {
    replaceHistory(splitedPath.join('/'))
  }
}

export function getCurrentPath(): string {
  const location = useLocation()
  return location.pathname
}

type ParamType = {
  page: string
  id: string
  subId: string
  subPage: string
  section: string
}

export function getParamValues(): ParamType {
  return useParams<ParamType>()
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export const removeHashfromURL = (): void => {
  window.history.replaceState(
    '',
    'replace',
    window.location.href.substr(0, window.location.href.indexOf('#'))
  )
}
