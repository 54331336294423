import { FC } from 'react'
import { Grid, Typography, Tag, Button } from '../../../../UI'
import { ItemProps } from './types'
import useStyles from './styles'
import { history, formatTimestamp, ESTIMATE_PUBLIC_STATUS } from '../../../../../helpers'
import { useClassName } from '../../../../../hooks'

const EstimateItem: FC<ItemProps> = ({ estimate }) => {
  const classes = useStyles()
  const className = useClassName()
  const pushForward = history.usePushForward()
  const { id, createdOn, deliveredOn, approvedOn, dueOn, publicStatus, properties } = estimate
  const { line_1, city, state } = properties?.address || {}

  return (
    <Grid item className={classes.container} key={id}>
      <Grid item className={classes.cell} xs={12}>
        <Typography className={classes.title}>
          {`Ref: ${properties.publicId}`}
        </Typography>
      </Grid>
      <Grid item className={classes.cell} xs={12}>
        <Typography className={classes.title}>
          {line_1}
        </Typography>
        <Typography className={classes.description}>
          {`${city}, ${state}`}
        </Typography>
      </Grid>
      <Grid item className={`${classes.cell} ${classes.status}`}>
        <Tag type={publicStatus} variation='body1' />
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Initiated:
        </Typography>
        <Typography className={classes.mediumValue}>
          {formatTimestamp(createdOn, 'MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Completed:
        </Typography>
        <Typography className={classes.title}>
          {deliveredOn ? formatTimestamp(deliveredOn, 'MM/DD/YYYY') : formatTimestamp(dueOn, 'MM/DD/YYYY')}
        </Typography>
        {deliveredOn && (
          <Typography
            className={className(classes.title, classes.expectedDeliveryLabel)}
          >
            (Expected)
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Approved:
        </Typography>
        <Typography className={classes.mediumValue}>
          {approvedOn ? formatTimestamp(approvedOn, 'MM/DD/YYYY') : '-'}
        </Typography>
      </Grid>

      <Grid item className={classes.itemContainer} xs={12}>
        <Button
          type='submit'
          variant='contained'
          disabled={publicStatus === ESTIMATE_PUBLIC_STATUS.IN_PROGRESS}
          className={classes.button}
          onClick={() => { pushForward(`${id}`) }}
        >
          View
        </Button>
      </Grid>
    </Grid>
  )
}

export default EstimateItem
