import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from '../../../../styles'

const IncentiveBox = ({
  icon,
  title,
  titleColor,
  totalIncentiveCount,
  sampleIncentive,
}: {
  icon: React.ReactNode
  title: string
  titleColor: string
  totalIncentiveCount: number
  sampleIncentive: string
}) => {
  const styles = useStyles()

  return (
    <Grid item container style={{ padding: '16px' }} spacing={2} direction='column'>
      <Grid item container style={{ color: titleColor, gap: '4px' }} alignItems='center'>
        {icon}
        <Typography className={styles.nextArtTitle}>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className={styles.lgFont}><b>Total Incentive Count: </b>{totalIncentiveCount}</Typography>
      </Grid>
      <Grid item>
        <Typography className={styles.lgFont}><b>Sample Incentive:</b></Typography>
        <Typography className={styles.lgFont} style={{ fontStyle: 'italic', fontWeight: 400 }}>{sampleIncentive}</Typography>
      </Grid>
    </Grid>
  )
}

export default IncentiveBox
