export interface DispatchContextType {
  showWarning: boolean
  errorMessage: string | undefined
  address: string
  cardHolder: string
  clientSecret: string
  estimateId: string
  amount: number
  payIntent: boolean
}

export interface DispatchValue {
  attr: keyof DispatchContextType
  value: any
}

export const dispatchPaymentCreditCardTypes = {
  SET_VALUE: 'SET_VALUE',
}
