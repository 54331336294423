import { authTypes } from 'ducks/auth/types'
import { Payment } from 'ducks/types'
import { paymentTypes, PaymentType, PaymentActions, PaymentValue } from './types'

const initialState: PaymentType = {} as PaymentType

const reducer = (state = initialState, action: PaymentActions): PaymentType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case paymentTypes.SET_PAYMENT: {
      return payload as Payment
    }

    default:
      return state
  }
}

export default reducer
