import { authTypes } from 'ducks/auth/types'
import {
  newRepairListTypes,
  NewRepairListType,
  NewRepairListActions,
  NewRepairListValue,
  NewRepairListValues
} from './types'

const initialState: NewRepairListType = {
  step: 0, // first step
  estimate: {},
  paymentOptions: [],
  paymentOption: null,
  clientSecret: '',
  isExpedite: false,

  address: {},
  items: []
}

const reducer = (state = initialState, action: NewRepairListActions): NewRepairListType => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case newRepairListTypes.CLEAN: {
      return {
        ...initialState,
        estimate: state.estimate,
        paymentOptions: state.paymentOptions,
        paymentOption: state.paymentOption,
        clientSecret: state.clientSecret,
        isExpedite: state.isExpedite
      }
    }

    case newRepairListTypes.SET_STEP: {
      return {
        ...state,
        step: payload as number
      }
    }
    case newRepairListTypes.SET_VALUE: {
      const { attr, value } = payload as NewRepairListValue
      return {
        ...state,
        [attr]: value
      }
    }
    case newRepairListTypes.SET_VALUES: {
      const { attrs } = payload as NewRepairListValues
      return {
        ...state,
        ...attrs
      }
    }

    default:
      return state
  }
}

export default reducer
