import { PriceItem, Territory } from 'ducks/types'

/** TYPES **/
export const territoriesTypes = {
  FETCH_TERRITORIES: 'FETCH_TERRITORIES',
  SET_TERRITORIES: 'SET_TERRITORIES',
  FETCH_TERRITORY: 'FETCH_TERRITORY',
  SET_TERRITORY: 'SET_TERRITORY',
  FETCH_REPAIR_ITEMS: 'FETCH_REPAIR_ITEMS',
  SET_REPAIR_ITEMS: 'SET_REPAIR_ITEMS',
}

/** ACTIONS **/
interface TerritoryServiceable {
  serviceable: boolean
}
export interface FetchTerritoriesAction {
  type: typeof territoriesTypes.FETCH_TERRITORIES
  payload: TerritoryServiceable | undefined | null
  callback?: (succ: boolean) => void
}

export interface SetTerritoriesAction {
  type: typeof territoriesTypes.SET_TERRITORIES
  payload: Territory[]
}

export interface FetchTerritoryAction {
  type: typeof territoriesTypes.FETCH_TERRITORY
  payload: string | { zipCode: string, source?: string }
  callback?: (succ: boolean, terr?: Territory) => void
}

export interface SetTerritoryAction {
  type: typeof territoriesTypes.SET_TERRITORY
  payload: Territory
}

export interface FetchRepairItemsAction {
  type: typeof territoriesTypes.FETCH_REPAIR_ITEMS
  payload: { territoryId: string; search?: string }
  callback?: (succ: boolean) => void
}

export interface SetRepairItemsAction {
  type: typeof territoriesTypes.FETCH_REPAIR_ITEMS
  payload: PriceItem[]
}

export type TerritoriesActions =
  | FetchTerritoriesAction
  | SetTerritoriesAction
  | FetchTerritoryAction
  | SetTerritoryAction
  | FetchRepairItemsAction
  | SetRepairItemsAction

/** REDUCER **/
export interface TerritoriesType {
  territories: Territory[]
  territory: Territory | null
  repairItems: PriceItem[]
}
