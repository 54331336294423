import { ConditionType } from 'ducks/properties/types'
import { Insight, PropertyDetail } from 'ducks/types'

/** TYPES **/
export const propertyDetailType = {
  FETCH_PROPERTY_DETAIL: 'FETCH_PROPERTY_DETAIL',
  ORDER_INSIGHTS: 'ORDER_INSIGHTS',
  SET_PROPERTY_DETAIL: 'SET_PROPERTY_DETAIL',
  RESET_PROPERTY_DETAIL: 'RESET_PROPERTY_DETAIL',
  SET_PROPERTY_INQUIRED_ITEMS: 'SET_PROPERTY_INQUIRED_ITEMS',
}

/** ACTIONS **/
export interface FetchPropertyDetailAction {
  type: typeof propertyDetailType.FETCH_PROPERTY_DETAIL
  payload: { id: string }
  callback?: (succ: boolean) => void
}

export interface OrderInsightsAction {
  type: typeof propertyDetailType.ORDER_INSIGHTS
  payload: { propertyId: string, item: string, request: Insight }
  callback?: (succ: boolean) => void
}

export interface SetPropertyDetailAction {
  type: typeof propertyDetailType.SET_PROPERTY_DETAIL
  payload: Partial<PropertyDetailType>
}

export interface ResetSetPropertyDetailAction {
  type: typeof propertyDetailType.RESET_PROPERTY_DETAIL
  payload: Partial<PropertyDetailType>
}

export interface SetPropertyInquiredItemsAction {
  type: typeof propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS
  payload: string[]
}

export type PropertyDetailsActions =
  | FetchPropertyDetailAction
  | OrderInsightsAction
  | SetPropertyDetailAction
  | ResetSetPropertyDetailAction
  | SetPropertyInquiredItemsAction

/** REDUCER **/
export type PropertyDetailType = PropertyDetail
