import { useState, FC } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Button, Box, TextFieldLabel, TitleFirst, Avatar, BottomLink } from '../../../UI'
import useStyles from './styles'
import { validateEmail, isEmpty, history } from '../../../../helpers'
import { authActions } from '../../../../ducks/actions'
import { PunchlistLoader } from '../../../templates'
import { send } from '../../../../assets'

const Reset: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [emailToSend, setEmailToSend] = useState('')
  const [loading, setLoading] = useState(false)
  const [sendEmailError, setSendEmailError] = useState(false)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const [emailSent, setEmailSent] = useState('')
  const [submit, setSubmit] = useState(false)

  const query = history.useQuery()
  const queryParams = query.toString()


  const handleAccept = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setSubmit(true)
    setSendEmailError(false)
    setSendEmailSuccess(false)
    if (validateEmail(emailToSend)) {
      setLoading(true)
      dispatch(authActions.changePasswordRequest({
        email: emailToSend,
        callback: (success: boolean) => {
          setLoading(false)
          if (success) {
            setEmailSent(emailToSend)
            setSendEmailSuccess(true)
          } else {
            setSendEmailError(true)
            setSubmit(false)
          }
        }
      }))
    }
  }

  return (
    <PunchlistLoader loading={loading}>
      <TitleFirst value='Forgot your password?' />
      <Typography className={classes.text} style={{ marginTop: '.3rem' }}>
        We'll send you an email with a link to
      </Typography>
      <Typography className={classes.text}>
        reset it.
      </Typography>
      <Box className={classes.center}>
        <form className={classes.form} autoComplete='off'>
          <TextFieldLabel
            label='Email:'
            type='email'
            value={emailToSend}
            error={submit && (isEmpty(emailToSend) || !validateEmail(emailToSend))}
            onChange={(event) => { if (!loading) { setEmailToSend(event.target.value) } }}
            placeholder='Enter your email here'
            className={classes.pass}
          />
          <Box marginTop={1}>
            {sendEmailError && (
              <Typography variant='body2' color='error'>
                An error occurred, please verify you entered the correct email address.
              </Typography>
            )}
            {sendEmailSuccess && (
              <Typography variant='body2' className={classes.successText}>
                Thank you, If a BOSSCAT account exists for <span>{emailSent}</span>, please allow up to 10 minutes for the email to arrive.
              </Typography>
            )}
          </Box>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={(event) => { if (!loading) handleAccept(event) }}
            endIcon={<Avatar alt='' src={send} className={classes.icon} />}
            fullWidth
          >
            Reset My Password
          </Button>
          <BottomLink
            question='Back to'
            linkText='Sign in here'
            linkTo={`/signin${queryParams ? `?${queryParams}` : ''}`}
          />
        </form>
      </Box>
    </PunchlistLoader >
  )
}

export default Reset
