import { useEffect } from "react"
import ReactGA from 'react-ga4'

import { history } from "helpers/index"

export default function useGoogleAnalytics() {
    const currentPath = history.getCurrentPath()
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: currentPath });
    }, [currentPath])
}