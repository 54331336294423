import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      width: '100%',
      height: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      inputColor: '#FFFFFF',
      textDecorationColor: '#FFFFFF',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: 'var(--placeholder-color)'
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    root: {
      width: '100%'
    },
    clearIndicator: {
      display: 'none'
    },
    endAdornment: {
      top: 0
    },
    popupIndicator: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    popupIndicatorOpen: {
      transform: 'rotate(0deg) !important'
    },
    popper: {
      margin: '6px 0 0 -24px',
      width: '450px !important',
      zIndex: 99999,
      [theme.breakpoints.down('lg')]: {
        width: '85% !important',
        left: '24px',
        // zIndex: 220
      }
    },
    option: {
      '&[data-focus="true"]': {
        backgroundColor: 'var(--row-focus)',
        borderColor: 'transparent'
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'var(--row-focus)',
        borderColor: 'transparent'
      }
    },
    search: {
      textTransform: 'none'
    },
    inputError: {
      borderColor: 'var(--expired-color) !important'
    }
  })
)
