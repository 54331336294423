import React from 'react'
import useStyles from '../styles'
import { Typography } from '@material-ui/core';
import { estimateCircle } from '../../../../../assets'

type Props = {}

const Banner5 = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={`${classes.banner5} ${classes.maxWidth}`}>
            <div className={classes.padding}>
                <img src={estimateCircle} className={classes.banner5_Img} alt='Estimate circle' />
            </div>
            <div className={classes.padding}>
                <Typography className={classes.banner5_Title}>Pillar To Post and BOSSCAT make the home inspection process stress and worry free!</Typography>
                <Typography className={classes.banner5_Text}>Submit your inspection report and get an estimate for the repairs items.</Typography>
            </div>
        </div>
    )
}

export default Banner5