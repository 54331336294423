import { Box, Grid, Typography } from '@material-ui/core'
import { icons } from 'assets'
import { Autocomplete } from 'components/UI'
import { PrivateLoader } from 'components/templates'
import { homemartActions } from 'ducks/actions'
import { getCustomerTypesForDropdown, getPartners, getTags, getTagsForDropdown, getUserPrimaryEmail } from 'ducks/selectors'
import { ROLES_OPTIONS, getMobileOperatingSystem, history } from 'helpers/index'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HomeMartCard from './HomeMartCard'
import useStyles from './styles'
import { logoSmall } from 'assets/index'
import { TrackGoogleAnalyticsEvent } from 'helpers/google-analytics'
type Props = {}

const HomeMart = (props: Props) => {
    const dispatch = useDispatch()
    const homemartPartners = useSelector(getPartners())
    const tags = useSelector(getTagsForDropdown)
    const customerTypes = useSelector(getCustomerTypesForDropdown)
    const [pageLoading, setPageLoading] = useState(!homemartPartners)
    const [loadingOptions, setLoadingOptions] = useState(true)

    const classes = useStyles()
    const push = history.usePush()

    const selectorRef = useRef<HTMLDivElement>(null)

    const [xsScreen] = useIsXsScreen()
    const [mdScreen] = useIsMdScreen()
    const [smScreen] = useIsSmScreen()

    const userOS = getMobileOperatingSystem()
    const userPrimaryEmail = useSelector(getUserPrimaryEmail)

    const isSmallScreen = xsScreen || smScreen || mdScreen

    const [openButtons, setOpenButtons] = useState(!isSmallScreen)
    const [roleSelected, setRoleSelected] = useState<string | number>("All Roles")
    const [resourceTypeSelected, setResourceTypeSelected] = useState<string | number>("All Services")
    const [autocompleteWidth, setAutocompleteWidth] = useState<string>("37.5vw")
    const fetchPartners = useCallback(() => {
        dispatch(homemartActions.fetchPartners({ active: true }, (success) => {
            setPageLoading(false)
        }))
    }, [dispatch])

    const fetchOptions = useCallback(() => {
        dispatch(homemartActions.fetchTags((success) => {
            dispatch(homemartActions.fetchCustomerTypes((success) => {
                setLoadingOptions(false)
            }))
        }))
    }, [dispatch])
    
    const handleGoToEstimate = () => {
        push('estimate')
    }

    const handleGoToMaintenance = () => {
        push('maintenance')
    }

    const handleGoToRenovation = () => {
        push('estimate')
    }

    useEffect(() => {
        fetchPartners()
        fetchOptions()
    }, [fetchPartners])

    useEffect(() => {
        if (homemartPartners) {
            setPageLoading(false)
        }
    }, [homemartPartners])

    useLayoutEffect(() => {
        setAutocompleteWidth(selectorRef.current?.offsetWidth ? `${selectorRef.current?.offsetWidth}px` : "37.5vw")
    }, [])

    const handleOpenButtons = () => {
        if (!isSmallScreen) return setOpenButtons(true);
        setOpenButtons(prevOpenButtons => !prevOpenButtons)
    }

    useEffect(() => {
        TrackGoogleAnalyticsEvent({
            category: "HomeMart",
            action: "homemart_page_do",
            options: {
                email: userPrimaryEmail,
            }
        })
    }, [userPrimaryEmail])

    return (
        <PrivateLoader loading={pageLoading}>
            <div className={classes.container}>
                <Grid container item>
                    <Box className={classes.portfolioView}>
                        <Typography className={classes.active}>
                            HOMEMART
                        </Typography>
                    </Box>
                </Grid>
                {isSmallScreen && (
                    <Box className={classes.downloadAppButtonContainer}>
                        <a
                            href={
                                userOS === 'iOS'
                                    ? 'https://apps.apple.com/us/app/bosscat/id1671886793'
                                    : 'https://play.google.com/store/apps/details?id=com.punchlistusa.robinson&pli=1'
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.link}>
                                <img src={logoSmall} alt="logo" />
                                <p>
                                    Download BOSSCAT App
                                </p>
                                <icons.ArrowForward />
                            </div>
                        </a>
                    </Box>
                )}
                <div className={classes.body}>
                    <div className={classes.buttons}>
                        <div className={classes.buttonsTitle} onClick={handleOpenButtons}>
                            {isSmallScreen && <div style={{ width: "24px" }}></div>}
                            <Typography style={{ fontFamily: "LatoBold", fontSize: "18px", marginRight: "4px" }}>
                                Order Now
                            </Typography>
                            <icons.ArrowForward style={openButtons && isSmallScreen ? { transform: "rotate(90deg)", transition: 'transform 250ms ease-in-out' } : { transform: "rotate(0deg)", transition: 'transform 250ms ease-in-out' }} />
                        </div>
                        {openButtons && <>
                            <div className={classes.blackButton} onClick={handleGoToEstimate}>
                                <Typography style={{ fontFamily: "LatoBold" }}>
                                    Estimate
                                </Typography>
                            </div>
                            <div className={classes.blackButton} onClick={handleGoToMaintenance}>
                                <Typography style={{ fontFamily: "LatoBold" }}>
                                    Maintenance
                                </Typography>
                            </div>
                            <div className={classes.blackButton} onClick={handleGoToRenovation}>
                                <Typography style={{ fontFamily: "LatoBold" }}>
                                    Renovation
                                </Typography>
                            </div>
                        </>}
                    </div>
                    <div className={classes.filters}>
                        <Typography style={{ fontFamily: "LatoBlack", marginBottom: "8px" }}>Explore Exclusive Offers for BOSSCAT Customers - Your passport to savings from our partners.</Typography>
                        <Typography>The links below are to external websites that are not provided or maintained by BOSSCAT.</Typography>
                        <div className={classes.selectors}>
                            {!loadingOptions &&
                                <> <div ref={selectorRef} className={classes.selector}>
                                    <Typography style={{ fontFamily: "LatoBlack", marginBottom: "4px" }}>Who are you?</Typography>
                                    <Autocomplete
                                        popperStyles={{ marginLeft: -22.5 }}
                                        popperWidth={isSmallScreen ? "90vw" : autocompleteWidth}
                                        className={classes.autocompleteContainer}
                                        labelClassName={classes.autocompleteLabel}
                                        options={customerTypes}
                                        onChange={(e) => setRoleSelected(e?.key)}
                                        value={customerTypes.find((resource) => resource?.key === roleSelected)}
                                    />
                                </div>
                                    <div className={classes.selector}>
                                        <Typography style={{ fontFamily: "LatoBlack", marginBottom: "4px" }}>What are you looking for?</Typography>
                                        <Autocomplete popperStyles={{ marginLeft: -22.5 }} popperWidth={isSmallScreen ? "90vw" : autocompleteWidth} className={classes.autocompleteContainer} labelClassName={classes.autocompleteLabel} options={tags} onChange={(e) => setResourceTypeSelected(e?.key)} value={tags.find((resource) => resource?.key === resourceTypeSelected)} />
                                    </div>
                                </>}
                        </div>
                    </div>
                    <div className={classes.partners}>
                        {homemartPartners && homemartPartners.map((partner, index) => {
                            const canShowRole = roleSelected?.toString().includes("All ") ? true : partner.customerTypes.find(role => role?.id === roleSelected)
                            const canShowResource = resourceTypeSelected?.toString().includes("All ") ? true : (partner.tags.find(resource => resource.id === resourceTypeSelected?.toString()))

                            if (!canShowRole || !canShowResource) {
                                return <></>
                            }
                            return (
                                <HomeMartCard key={index} partner={partner} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </PrivateLoader>
    )
}

export default HomeMart