import { FC, useCallback, useState, useEffect, memo } from 'react'
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import { Item } from '../../../../../../../../../ducks/types'
import useStyles from '../styles'
import { useIsXsScreen } from '../../../../../../../../../hooks'
import { MultilineText } from '../../../../../../../../UI'
import { getNewEstimateValues } from '../../../../../../../../../ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from '../../../../../../../../../assets'
import { MemoItemProps } from '../types'
import { newEstimateActions } from '../../../../../../../../../ducks/actions'
import ItemRow from './ItemRow'
import { PrivateLoader } from '../../../../../../../../templates'
import ItemInputs from './ItemInputs'

const myPropsAreEqual = (prevProps: MemoItemProps, nextProps: MemoItemProps) =>
  prevProps.index === nextProps.index &&
  prevProps.measurement === nextProps.measurement &&
  prevProps.images === nextProps.images &&
  JSON.stringify(prevProps.htmlFiles) === JSON.stringify(nextProps.htmlFiles) &&
  prevProps.inspectionReportNote === nextProps.inspectionReportNote &&
  prevProps._onChangeNote === nextProps._onChangeNote &&
  prevProps._onChangeImages === nextProps._onChangeImages &&
  prevProps.totalItems === nextProps.totalItems &&
  prevProps.imagesUpdater === nextProps.imagesUpdater &&
  prevProps.deleteEnabled === nextProps.deleteEnabled

const MemoizedItem = memo((props: MemoItemProps) => {
  const {
    index,
    inspectionReportNote,
    measurement,
    images,
    _onChangeNote,
    _onChangeMeasurement,
    _onChangeImages,
    htmlFiles,
    _onDeleteItems,
    _onChangeImagesFiles,
    totalItems,
    imagesUpdater,
    deleteEnabled,
  } = props

  return (
    <ItemRow
      index={index}
      measurement={measurement}
      inspectionReportNote={inspectionReportNote}
      onChangeNote={_onChangeNote}
      onChangeMeasurement={_onChangeMeasurement}
      onChangeImages={_onChangeImages}
      images={images}
      htmlFiles={htmlFiles}
      onDeleteItem={_onDeleteItems}
      onDeleteImagesFiles={_onChangeImagesFiles}
      totalItems={totalItems}
      imagesUpdater={imagesUpdater}
      deleteEnabled={deleteEnabled}
    />
  )
}, myPropsAreEqual)

const MemoInputs = memo((props: MemoItemProps) => {
  const {
    index,
    inspectionReportNote,
    measurement,
    _onChangeNote,
    _onChangeMeasurement,
    _onChangeImages,
    htmlFiles,
    _onDeleteItems,
    _onAddItem,
    _onChangeImagesFiles,
    images,
    totalItems,
    imagesUpdater,
    deleteEnabled,
  } = props

  return (
    <ItemInputs
      index={index}
      measurement={measurement}
      inspectionReportNote={inspectionReportNote}
      onChangeNote={_onChangeNote}
      onChangeMeasurement={_onChangeMeasurement}
      onChangeImages={_onChangeImages}
      htmlFiles={htmlFiles}
      onDeleteItem={_onDeleteItems}
      onAddItem={_onAddItem}
      images={images}
      onDeleteImagesFiles={_onChangeImagesFiles}
      totalItems={totalItems}
      imagesUpdater={imagesUpdater}
      deleteEnabled={deleteEnabled}
    />
  )
}, myPropsAreEqual)

interface ListBuilderProps {
  imageFiles: File[][]
  setImagesFiles: React.Dispatch<React.SetStateAction<File[][]>>
}

const ListBuilder: FC<ListBuilderProps> = (listBuilderProps) => {
  const { setImagesFiles, imageFiles } = listBuilderProps
  const classes = useStyles()
  const [xsScreen] = useIsXsScreen()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const estimate = useSelector(getNewEstimateValues())
  const clientNotes = estimate?.clientNotes
  const reduxItems = estimate?.items

  const [items, setItems] = useState<Partial<Item>[]>(
    reduxItems
      ? reduxItems.length > 0
        ? [...reduxItems]
        : [
          ...reduxItems,
          {
            inspectionReportNote: '',
            measurement: '',
            imageFiles: [],
            editable: true,
            htmlFiles: [],
          },
        ]
      : [
        {
          inspectionReportNote: '',
          measurement: '',
          imageFiles: [],
          editable: true,
          htmlFiles: [],
        },
      ]
  )

  const [totalItems, setTotalItems] = useState(items.length)

  useEffect(() => {
    setTotalItems(items.length)
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'items',
        value: items,
      })
    )
  }, [items])

  const handleAddItem = () => {
    setTotalItems(items.length + 1)
    const copiedImages: File[][] = [...imageFiles]
    copiedImages[items.length] = []
    setImagesFiles(copiedImages)
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'items',
        value: [
          ...items,
          {
            inspectionReportNote: '',
            measurement: '',
            imageFiles: [],
            editable: true,
          },
        ],
      })
    )
    setItems([
      ...items,
      {
        inspectionReportNote: '',
        measurement: '',
        imageFiles: [],
        editable: true,
      },
    ])
  }

  const handleChangeComments = (event: any) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'clientNotes',
        value: event.target.value,
      })
    )
  }

  useEffect(() => {
    if (items.length === 0) {
      handleAddItem()
    }
  }, [items])

  const onChangeNote = useCallback(
    (note: string, index: number) => {
      const copiedItems = [...items]
      copiedItems[index].inspectionReportNote = note
      setItems(copiedItems)
    },
    [items]
  )

  const onChangeMeasurement = useCallback(
    (measurement: string, index: number) => {
      const copiedItems = [...items]
      copiedItems[index].measurement = measurement
      setItems(copiedItems)
    },
    [totalItems, items]
  )

  const onChangeImages = useCallback(
    (images: any, index: number, callback: () => void) => {
      if (images) {
        const copiedImages = [...imageFiles]
        copiedImages[index] = images
        setImagesFiles(copiedImages)
        callback()
      }
    },
    [imageFiles]
  )

  const onChangeImagesFiles = useCallback(
    (indexToDelete: number, indexItem: number) => {
      const copiedItems = [...items]
      copiedItems[indexItem].imageFiles = items[indexItem]?.imageFiles?.filter(
        (img: any, idx: number) => idx !== indexToDelete
      )
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'items',
          value: copiedItems,
        })
      )
    },
    [imageFiles]
  )

  const onDeleteItem = useCallback(
    (index: number) => {
      const copiedItems: Partial<Item>[] = []
      const copiedImages: File[][] = []
      items.forEach((i, _index) => {
        if (_index !== index) {
          copiedItems.push(i)
        }
      })
      imageFiles.forEach((images, _index) => {
        if (_index !== index) {
          copiedImages.push(images)
        }
      })
      setImagesFiles(copiedImages)
      setItems(copiedItems)
      setTotalItems(totalItems - 1)
    },
    [totalItems, items, imageFiles]
  )

  const onAddItem = useCallback(() => {
    setTotalItems(items.length + 1)
    setItems([
      ...items,
      {
        inspectionReportNote: '',
        measurement: '',
        imageFiles: [],
        editable: true,
      },
    ])
  }, [items])

  return (
    <>
      {!xsScreen && (
        <Typography style={{ fontSize: 18, fontFamily: 'LatoBlack', color: "#0B060F", marginBottom: "16px" }}>
          Please provide as much detail as you can and include measurements and
          photos/videos if applicable.
        </Typography>
      )}
      <Box className={classes.tableContainer}>
        <PrivateLoader loading={loading} compact building="fragment">
          {!xsScreen ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellHead}>#</TableCell>
                  <TableCell className={classes.cellHead}>
                    Description
                  </TableCell>
                  <TableCell className={classes.cellHead}>
                    Measurements
                  </TableCell>
                  <TableCell className={classes.cellHead}>
                    Upload photos/videos
                  </TableCell>
                  <TableCell align="center" className={classes.cellHead}>
                    <icons.Delete />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item: any, index: any) => {
                  return (
                    <MemoizedItem
                      key={index}
                      index={index}
                      images={item?.imageFiles}
                      inspectionReportNote={item?.inspectionReportNote}
                      measurement={item?.measurement}
                      htmlFiles={imageFiles[index]}
                      _onChangeNote={onChangeNote}
                      _onChangeMeasurement={onChangeMeasurement}
                      _onChangeImages={onChangeImages}
                      _onDeleteItems={onDeleteItem}
                      imagesUpdater={imageFiles}
                      _onChangeImagesFiles={(idx: number) =>
                        onChangeImagesFiles(idx, index)
                      }
                      totalItems={totalItems}
                      deleteEnabled={items.length > 1}
                    />
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <Box>
              {items.map((item: any, index: any) => {
                return (
                  <MemoInputs
                    key={index}
                    index={index}
                    images={item?.imageFiles}
                    inspectionReportNote={item?.inspectionReportNote}
                    measurement={item?.measurement}
                    htmlFiles={imageFiles[index]}
                    _onChangeNote={onChangeNote}
                    _onChangeMeasurement={onChangeMeasurement}
                    _onChangeImages={onChangeImages}
                    _onDeleteItems={onDeleteItem}
                    _onAddItem={onAddItem}
                    imagesUpdater={imageFiles}
                    _onChangeImagesFiles={(idx: number) =>
                      onChangeImagesFiles(idx, index)
                    }
                    totalItems={totalItems}
                    deleteEnabled={items.length > 1}
                  />
                )
              })}
            </Box>
          )}
        </PrivateLoader>
      </Box>

      {!xsScreen && (
        <Grid
          item
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
        >
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.addItem}
            endIcon={<icons.Add />}
            onClick={handleAddItem}
          >
            Add Item
          </Button>
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingBottom: '2rem' }}>
        <Typography variant="body1" className={classes.label}>
          Other requests and/or comments (optional):
        </Typography>
        <MultilineText
          value={clientNotes}
          onChange={handleChangeComments}
          placeholder="Let us know any other comment you have."
        />
      </Grid>
    </>
  )
}

export default ListBuilder
