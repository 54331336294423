import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

type Param = string
type Routes = Record<string, React.ComponentType>

export type UseRouterReturnType = [string, React.ComponentType]

const useRouter = (routes: Routes, param: Param, pageName?: string): UseRouterReturnType => {
  const params = useParams<Record<string, string>>()
  const routeName = pageName ?? params[param]
  let Route: UseRouterReturnType[1]

  if (routeName in routes || (routeName === undefined && 'default' in routes)) {
    Route = routes[routeName ?? 'default']
  } else Route = () => <Redirect from='*' to='/' />

  return [routeName, Route]
}

export default useRouter
