import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      position: 'relative'
    },
    logo: {
      width: '200px',
      position: 'absolute',
      margin: theme.spacing(3)
    },
    logoAlt: {
      position: 'absolute',
      margin: theme.spacing(3),
      zIndex: 10,
      color: 'white',
      maxWidth: '250px'
    },
    animationBox: {
      background: 'var(--background-color-secondary)',
      justifyContent: 'center'
    },
    randomAnimationBox: {
      // background: theme.palette.text.primary,
      position: 'relative',
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '75px',
    },
    childrenBox: {
      padding: '0 24px'
    },
    loginTexture: {
      position: 'fixed',
      maxWidth: '150px',
      width: '20vw',
      zIndex: -1,
      bottom: 0,
      right: 0,
      [theme.breakpoints.down('md')]: {
        width: '15vw'
      },
      [theme.breakpoints.down('xs')]: {
        width: '20vw'
      }
    },
    center: {
      display: 'grid',
      alignItems: 'center',
      alignContent: 'center'
    },
  }))
