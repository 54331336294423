import { Grid } from '@material-ui/core'
import useStyles from './styles'
import { FC } from 'react'
import { TextFieldLabel } from 'components/UI'
import { EstimateContact } from 'ducks/types'
import {
  PHONE_MASK_INPUT,
  isEmpty,
  validateEmail,
  validatePhone,
} from 'helpers/index'
import { useStepperContext } from 'hooks/useStepperContext'

export interface ContactProps {
  contact?: Partial<EstimateContact>
  onChange?: (key: keyof EstimateContact, val: string | number) => void
  style?: any
  error?: string
}

const Contact: FC<ContactProps> = ({ contact, onChange, style, error }) => {
  const classes = useStyles()

  const { showWarnings } = useStepperContext()

  return (
    <>
      <Grid item className={classes.contact} style={style}>
        <Grid container item spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextFieldLabel
              label="First Name"
              value={contact?.firstName || ''}
              error={showWarnings && !contact?.firstName}
              onChange={(event: any) =>
                onChange && onChange('firstName', event.target.value)
              }
              placeholder="Homeowner Name"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldLabel
              label="Last Name"
              value={contact?.lastName || ''}
              error={showWarnings && !contact?.lastName}
              onChange={(event: any) =>
                onChange && onChange('lastName', event.target.value)
              }
              placeholder="Homeowner Last Name"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldLabel
              label="Phone"
              value={contact?.phone || ''}
              onChange={(event: any) =>
                onChange && onChange('phone', event.target.value)
              }
              placeholder="555-555-1234"
              mask={PHONE_MASK_INPUT}
              type="tel"
              error={showWarnings && !validatePhone(contact?.phone || '')}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldLabel
              // className={classes.signature}
              label="Email"
              value={contact?.email || ''}
              error={
                showWarnings &&
                (!contact?.email || !validateEmail(contact?.email))
              }
              onChange={(event: any) =>
                onChange && onChange('email', event.target.value)
              }
              placeholder="email@mail.com"
              // disabled={!isEmpty(contact?.id)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {error && <span className={classes.error}>{error}</span>}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Contact
