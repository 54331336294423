import { FC } from 'react'
import { Container, Box, TitleFirst, LinearProgress } from '../../../UI'
import useStyles from './styles'
import { calendar } from '../../../../assets'
import { LoaderProps } from '../types'

const PaymentLoader: FC<LoaderProps> = ({ children, loading = true, building = 'page' }) => {
  const classes = useStyles()

  const isPayment = building === 'page'

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.center}>
        <img src={calendar} alt='' className={classes.icon} />
      </Box>
      <TitleFirst value={isPayment ? 'Payment' : 'Uploading...'} black={isPayment ? 'in process...' : ''} />
      <LinearProgress className={classes.loader} />
    </Container>
  )
}

export default PaymentLoader
