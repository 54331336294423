import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '../../../'
import { useClassName } from '../../../../../hooks'
import useStyles from './styles'
import { ReceiptProps } from './types'
import UpgradeTierModal from './UpgradeTierModal'
import { ScrollWrapper } from '../../../../templates'
import {
  Address,
  Estimate,
  EstimateContact,
  EstimateProperties,
  PaymentOption,
} from '../../../../../ducks/types'
import {
  BOSSCAT_PHONE_NUMBER,
  concatSeparator,
  formatPhone,
  formatTimestamp,
  RESOURCE_TYPE,
  round,
} from '../../../../../helpers'
import { configActions, paymentsActions } from '../../../../../ducks/actions'
import { isEmpty } from 'lodash'
import PaymentModal from '../PaymentModal'

const Receipt: FC<ReceiptProps> = ({
  className,
  estimate,
  paymentOption,
  expediteOption,
  onExpediteSuccess,
  saveExpediteInfo,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const classNames = useClassName()
  const formatDate = 'MMMM Do, YYYY'
  const formatDate2 = 'M/D/YYYY'

  const [clientSecret, setClientSecret] = useState('')
  const [expeditePrice, setExpeditePrice] = useState(0)

  const {
    id: estimateId,
    createdOn,
    dueOn,
    properties,
    deliveryServiceCost,
  } = estimate as Estimate
  const { address, contacts, closingDate, discount, clientNotes } =
    (properties ?? {}) as EstimateProperties
  const {
    line_1: street,
    line_2: unit,
    city,
    state,
    zipCode,
  } = (address ?? {}) as Address
  const {
    fullName: yourFullName,
    email: yourEmail,
    phone: yourPhone,
  } = (contacts && contacts.length > 0 ? contacts[0] : {}) as EstimateContact
  const {
    fullName: clientFullName,
    email: clientEmail,
    phone: clientPhone,
  } = (
    contacts && contacts.length > 1 ? contacts[contacts.length - 1] : {}
  ) as EstimateContact

  const { subtitle } = (paymentOption || { subtitle: 'Order' }) as PaymentOption
  const showExpedite = expediteOption !== null

  const stopOverlay = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: false })
    )
  }

  const handleClose = () => {
    stopOverlay()
    setClientSecret('')
    setExpeditePrice(0)
  }

  const handleExpediteSuccess = () => {
    stopOverlay()
    if (onExpediteSuccess) onExpediteSuccess()
  }

  const handleExpedite = () => {
    if (expediteOption) {
      if (expediteOption.price > 0) {
        dispatch(
          configActions.setConfigValue({ type: 'overlayLoading', value: true })
        )
        setExpeditePrice(expediteOption.price)
        dispatch(
          paymentsActions.fetchStripeSecret(
            {
              amount: expediteOption.price,
              resourceId: estimateId,
              resourceType: RESOURCE_TYPE.ESTIMATE,
            },
            (secret?: string) => {
              if (secret && !isEmpty(secret)) {
                setClientSecret(secret)
                if (saveExpediteInfo) saveExpediteInfo(secret)
              } else stopOverlay()
            }
          )
        )
      } else {
        handleExpediteSuccess()
      }
    }
  }

  return (
    <ScrollWrapper>
      <Box
        className={classNames(classes.root, className || '')}
        style={showExpedite ? { gap: '56px' } : {}}
      >
        <Box
          className={classes.headerContainer}
          style={showExpedite ? { padding: '20px 0 68px' } : {}}
        >
          <Typography className={classes.headerTitle}>
            Order Confirmation
          </Typography>
          <Typography className={classes.headerDescription}>
            Thank you for submitting your request!
          </Typography>
          <Typography className={classes.headerDescription}>
            Our team is getting to work building your estimate. We will send it
            to your inbox when it’s ready to go!
          </Typography>

          <Box className={classes.estimatedTimeContainer}>
            <Box className={classes.estimatedTimeItem}>
              <Typography className={classes.estimatedTimeLabel}>
                Request Date:
              </Typography>
              <Box className={classes.estimatedTime}>
                <Typography
                  className={classNames(
                    classes.estimatedTimeLabel,
                    classes.estimatedTimeDate
                  )}
                >
                  {formatTimestamp(createdOn, formatDate)}
                </Typography>
              </Box>
            </Box>
            <Box className={classNames(classes.dashedSeparator)} />
            <Box className={classes.estimatedTimeItem}>
              <Typography className={classes.estimatedTimeLabel}>
                Expected Delivery Date:
              </Typography>
              <Box
                className={classNames(
                  classes.estimatedTime,
                  classes.borderBlue
                )}
              >
                <Typography
                  variant="subtitle1"
                  className={classNames(
                    classes.estimatedTimeLabel,
                    classes.estimatedTimeDate
                  )}
                >
                  {formatTimestamp(dueOn, formatDate)}
                  {/* {formatTimestamp(deliveredOn, formatDate)} */}
                </Typography>
              </Box>
            </Box>
          </Box>

          <PaymentModal
            open={
              showExpedite &&
              !isEmpty(clientSecret) &&
              expeditePrice > 0 &&
              !isEmpty(estimateId)
            }
            setOpen={handleClose}
            resourceId={estimateId}
            clientSecret={clientSecret}
            price={expeditePrice}
            onSuccess={handleExpediteSuccess}
            returnUrl={`${window.location.href}`}
          />
          {showExpedite ? (
            <UpgradeTierModal
              option={expediteOption as PaymentOption}
              handleSubmit={handleExpedite}
            />
          ) : null}
        </Box>

        <Box className={classes.sectionsRoot}>
          <Box className={classes.horizontalContainer}>
            <Box className={classes.horizontalBox}>
              <Typography className={classes.sectionTitle} variant="h2">
                Property Info
              </Typography>
              <Box className={classes.separator} />
              <Typography> {concatSeparator(street, unit, ', ')} </Typography>
              <Typography>
                {' '}
                {city.concat(', ', state, ' ', zipCode)}{' '}
              </Typography>
            </Box>

            <Box
              className={`${classes.horizontalBoxRight} ${classes.horizontalBox}`}
            >
              <Typography className={classes.sectionTitle} variant="h2">
                Your Info
              </Typography>
              <Box className={classes.separator} />
              <Typography>{yourFullName}</Typography>
              <Typography>{yourEmail}</Typography>
              <Typography>{formatPhone(yourPhone)}</Typography>
            </Box>
          </Box>

          <Box className={classes.horizontalContainer}>
            <Box className={classes.horizontalBox}>
              <Typography className={classes.sectionTitle} variant="h2">
                Transaction Info
              </Typography>
              <Box className={classes.separator} />
              <Typography> {concatSeparator(street, unit, ', ')} </Typography>
              <Typography>
                {' '}
                {city.concat(', ', state, ' ', zipCode)}{' '}
              </Typography>
            </Box>

            {clientEmail && (
              <Box
                className={`${classes.horizontalBoxRight} ${classes.horizontalBox}`}
              >
                <Typography className={classes.sectionTitle} variant="h2">
                  Client Info
                </Typography>
                <Box className={classes.separator} />
                <Box className={classes.textRight}>
                  <Typography>{clientFullName}</Typography>
                  <Typography>{clientEmail}</Typography>
                  <Typography>{formatPhone(clientPhone)}</Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            className={classNames(
              classes.horizontalContainer,
              classes.sectionInfo,
              classes.closingDate
            )}
          >
            <Box>
              <Typography>
                {`Closing Date: ${closingDate ? formatTimestamp(closingDate / 1000, formatDate2) : '-'}`}
              </Typography>
              <Typography>{`Notes: ${clientNotes && clientNotes !== '' ? clientNotes : '-'}`}</Typography>
            </Box>
            {/*  <Box className={classes.textRight}>
              <Typography>{otherParties.fullName}</Typography>
              <Typography>{otherParties.email}</Typography>
              <Typography>{otherParties.phone}</Typography>
            </Box> */}
          </Box>

          <Box className={`${classes.centerContainer} ${classes.orderTotal}`}>
            <Typography className={classes.sectionTitle}>
              Order Total
            </Typography>
          </Box>
          <Box className={classes.separator} />

          <Box
            className={classNames(
              classes.horizontalContainer,
              classes.sectionInfo
            )}
          >
            <Box>
              <Typography className={classes.textSpacing}>
                {subtitle}
              </Typography>
              <Typography className={classes.textSpacing}>Discount:</Typography>
              <Typography className={classes.textSpacing}>Tax:</Typography>
            </Box>

            <Box className={classes.textRight}>
              <Typography className={classes.textSpacing}>
                {deliveryServiceCost
                  ? `$ ${round(deliveryServiceCost, 2)}`
                  : '--'}
              </Typography>
              <Typography className={classes.textSpacing}>
                {discount ? `$ ${round(discount, 2)}` : '--'}
              </Typography>
              {/* Estimates payment does not include tax
              <Typography className={classes.textSpacing}>
                {taxAmount ? `$ ${round(taxAmount, 2)}` : '--'}
              </Typography> */}
            </Box>
          </Box>

          <Box
            className={classNames(classes.separator, classes.greyColorBorder)}
          />
          <Box
            className={classNames(
              classes.horizontalContainer,
              classes.sectionInfo,
              classes.total
            )}
          >
            <Typography className={classes.textSpacing}>TOTAL:</Typography>
            <Typography className={classes.textSpacing}>
              {`$ ${round(
                (deliveryServiceCost ?? 0) - (discount ?? 0),
                2
              )}`}
            </Typography>
          </Box>
        </Box>

        <Box className={classNames(classes.footer)}>
          <Box className={classes.centerContainer}>
            <Typography>{BOSSCAT_PHONE_NUMBER}</Typography>
          </Box>
          <Box className={classes.centerContainer}>
            <Typography>customercare@bosscathome.com</Typography>
          </Box>
        </Box>
      </Box>
    </ScrollWrapper>
  )
}

export default Receipt
