export interface WorkOrdersContextType {
    // MODAL
    modalType: MODAL_TYPE | null
    modalOpen: boolean
}

export const workOdersTypes = {
    SET_MODAL_TYPE: 'SET_MODAL_TYPE',
    SET_MODAL_OPEN: 'SET_MODAL_OPEN',
}

export enum MODAL_TYPE {
    FAQ = 'FAQS',
    PERKS = 'PERKS',
    GIFT_SET = 'GIFT_SET',
}
