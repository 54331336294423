import { Promo } from 'ducks/promos/types'
import {
  Subscription
} from '../types'

/** TYPES**/
export const orderMaintenanceTypes = {
  SET_VALUE: 'SET_NEW_ORDER_MAINTENANCE_VALUE',
  SET_VALUES: 'SET_NEW_ORDER_MAINTENANCE_VALUES',
  GO_BACK: 'GO_BACK_ORDER_MAINTENANCE_STEP',
  GO_FORWARD: 'GO_FORWARD_ORDER_MAINTENANCE_STEP',
  CLEAR: 'CLEAR_ORDER_MAINTENANCE',
  SET_ORDER_MAINTENANCE: 'SET_ORDER_MAINTENANCE',
}

export interface OrderMaintenanceValue {
  attr: keyof OrderMaintenanceType
  value: any
}

/** ACTIONS**/

export interface OrderMaintenanceSetValueAction {
  type: typeof orderMaintenanceTypes.SET_VALUE
  payload: OrderMaintenanceValue
}

export interface OrderMaintenanceGoBackAction {
  type: typeof orderMaintenanceTypes.GO_BACK
  payload: null
}

export interface OrderMaintenanceValues {
  attrs: Partial<OrderMaintenanceType>
}

export interface OrderMaintenanceSetValuesAction {
  type: typeof orderMaintenanceTypes.SET_VALUES
  payload: OrderMaintenanceValues
}

export interface SetOrderMaintenance {
  type: typeof orderMaintenanceTypes.SET_VALUES
  payload: OrderMaintenanceType
}


export interface ClearOrderMaintenanceAction {
  type: typeof orderMaintenanceTypes.SET_VALUES
  payload: null
}


export interface ReplaceInterface {
  path: string,
  value: any
}

export type OrderMaintenanceActions =
  | OrderMaintenanceGoBackAction
  | OrderMaintenanceSetValueAction
  | OrderMaintenanceSetValuesAction
  | ClearOrderMaintenanceAction
  | OrderMaintenanceSetValuesAction
  | SetOrderMaintenance

/** REDUCER **/
export interface OrderMaintenanceType extends Partial<Subscription> {
  step?: number,
  mobileStep?: number
  contractSigned?: true | null
  promoCode?: string | null
  promo?: Promo | null
  homeownerReceives?: boolean
  priceLoading?: boolean
}
