import React, { useState } from 'react'
import { Box, Typography, UploadDocuments } from '../../../../../UI';
import BottomSheet from 'components/UI/CustomUI/molecules/BottomSheet';
import useStyles from './styles'
import { FILE_TYPE } from 'helpers/constants';
import { applicationMimeTypes } from 'helpers/files';
import { SubscriptionsPlan } from 'ducks/types';
import { useDispatch } from 'react-redux';
import { filesActions, subscriptionActions, subscriptionsActions } from 'ducks/actions';
import { isEmpty, ParseFilesUrl } from 'helpers/index';
import { FILES_URL } from 'components/pages/User/Register/_test_/constants';
import { fetchSubscriptions } from 'ducks/subscriptions/saga';

type Props = {
    open: boolean;
    handleClose: () => void;
    subscription: SubscriptionsPlan
}

function HomeInspectionModal({ open, handleClose, subscription }: Props) {
    const classes = useStyles();
    const [uploading, setUploading] = useState(false)
    const [currentFile, setCurrentFile] = useState<any>()
    const dispatch = useDispatch()


    function handleUploadDoc() {
        if (uploading) return;
        dispatch(subscriptionActions.updateSubscription({
            subscriptionId: subscription.id, request: [{
                op: "add", path: "/files/-", value: {
                    ...currentFile[0]
                }
            }]
        }, (succ: boolean) => {
            if (succ) {
                dispatch(subscriptionsActions.fetchSubscriptions({}))
                handleClose()
            }
        }))
    }

    const handleUploadFileChange = (
        files: null | File[],
        callback: () => void
    ) => {
        setUploading(true)
        if (files) {
            dispatch(
                filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
                    if (uploadSucc && fileNameList) {
                        const newFiles = fileNameList.map((fileName, index) => {
                            if (!isEmpty(fileName)) {
                                return {
                                    name: fileName,
                                    fileName,
                                    fileUrl: ParseFilesUrl({
                                        baseUrl: FILES_URL,
                                        avatar: fileName ?? ''
                                    }),
                                    fileType: FILE_TYPE.INSPECTION_REPORT
                                }
                            }
                        })
                        setUploading(false)
                        setCurrentFile(newFiles)
                    }
                    if (callback) callback()
                })
            )
        } else {
            if (callback) callback()
        }
        setUploading(false)
    }


    return (
        <BottomSheet
            title={"Home Inspection Report"}
            open={open}
            onClose={handleClose}>
            <Box className={classes.Container}>
                <Typography className={classes.Title}>{`Upload Home Inspection Report`}</Typography>
                <UploadDocuments
                    files={currentFile}
                    dropzoneText='Upload Inspection PDF'
                    filesLimit={1}
                    onChange={
                        !uploading
                            ? (files, callback) =>
                                handleUploadFileChange(
                                    files,
                                    callback
                                )
                            : () => { }
                    }
                    className={classes.Dropzone}
                    handleDelete={() => setCurrentFile(undefined)}
                    allowedFiles={applicationMimeTypes}
                    sx={{ minHeight: '180px !important' }}
                />
                <Box className={classes.Buttons}>
                    <Box onClick={handleClose} className={classes.ButtonSec}>
                        <Typography>Cancel</Typography>
                    </Box>
                    <Box onClick={handleUploadDoc} className={classes.ButtonPrim}>
                        <Typography>Upload Report</Typography>
                    </Box>
                </Box>
            </Box>
        </BottomSheet>
    )
}

export default HomeInspectionModal