import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100%',
      margin: '20px',
      alignItems: 'baseline',
      backgroundColor: 'var(--white-color)',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    detail: {
      margin: '4px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      color: 'var(--date-color)',
      [theme.breakpoints.down('sm')]: {
        margin: '0px'
      }
    },
    smDetail: {
      textAlign: 'center',
      margin: '4px 90px',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      color: 'var(--date-color)',
      [theme.breakpoints.down('sm')]: {
        margin: '1rem 0'
      }
    },
    body2: {
      margin: '4px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      color: 'var(--head-text-color)',

      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        marginBottom: '8px',
        fontFamily: 'Lato'
      }
    },
    body2R: {
      margin: '4px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      color: 'var(--head-text-color)',
      textAlign: 'right',

      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        marginBottom: '8px'
      }
    },
    body2Red: {
      margin: '4px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      color: '#CA0000',
      textAlign: 'right',

      [theme.breakpoints.down('sm')]: {
        margin: '0px'
      }
    },
    right: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        width: '100%',
      }
    },
    h6: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      color: 'var(--head-text-color)',
      margin: '4px 20px',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        fontFamily: 'Lato'
      }
    },
    h5Title: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      backgroundColor: 'white',
      color: 'var(--date-color)',
      margin: '4px 8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        margin: 0
      }
    },
    h5Value: {
      fontFamily: 'Lato',
      color: 'var(--head-text-color)',
      backgroundColor: 'white',
      margin: '4px 8px 4px 0px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '1rem',
        height: 'fit-content',
        margin: 0
      }
    },
    download: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
        height: 'fit-content',
        width: 'fit-content',
        justifyContent: 'flex-end',
        minWidth: 'fit-content',
        marginLeft: '1rem',
        '@global': {
          '.MuiButton-label': {
            paddingTop: 0,
            width: 'fit-content',
          }
        }
      }
    },
    box: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex'
      }
    },
    white: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      height: '74px',
      borderRadius: '12px',
      padding: '8px',
      margin: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
        margin: 0,
        padding: 0,
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '24px'
      }
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      }
    },
    date: {
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        padding: '8px',
        margin: '8px 0',
        justifyContent: 'flex-start',
        gap: '1rem'
      },
      '@global': {
        '.MuiGrid-root': {
          [theme.breakpoints.down('sm')]: {
            width: 'fit-content',
            flexBasis: 'inherit'
          }
        }
      }
    },
    button: {
      margin: '0px 20px',
      width: '248px',
      height: '38px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8
    },
    data: {
      margin: '4px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      color: 'var(--head-text-color)'
    },
    line: {
      height: '2px',
      width: '100%',
      backgroundColor: 'var(--border-color)',
      margin: '6px 20px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    endLine: {
      height: '2px',
      backgroundColor: 'var(--border-color)',
      margin: '6px 20px'
    },
    radio: {
      margin: '0px',
      '@global': {
        '.MuiButtonBase-root': {
          [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginRight: '4px'
          }
        }
      }
    },
    radioValue: {
      margin: '15px 20px',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      color: 'var(--head-text-color)',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    field: {
      height: '30px',
      width: '248px',
      margin: '0px 15px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        width: '150px',
        marginTop: '.5rem'
      },
      '@global': {
        '.MuiInputBase-root': {
          [theme.breakpoints.down('sm')]: {
            marginRight: '0 !important'
          }
        }
      }
    },
    footer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        margin: '1rem 0',
      },
      '@global': {
        '.MuiButton-root': {
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: '100%'
          },
        }
      },
    },
    title: {
      marginBottom: '2rem'
    }
  })
)
