import { CONTACT_ROLE } from "helpers/constants"

/** TYPES **/
export const campaignsTypes = {
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
}

export interface CampaignsValue {
  attr: keyof CampaignsType
  value: any
}

export interface FetchCampaignsPayload {
  estimateType?: string
  affiliate?: string
}

/** ACTIONS **/
export interface FetchCampaignsAction {
  type: typeof campaignsTypes.FETCH_CAMPAIGNS
  payload: FetchCampaignsPayload
  callback?: (succ: boolean) => void
}

export interface SetCampaignsValueAction {
  type: typeof campaignsTypes.SET_CAMPAIGNS
  payload: CampaignsType
}

export type CampaignsActions =
  | FetchCampaignsAction
  | SetCampaignsValueAction

export interface Campaign {
  id: string
  affiliate: string
  campaign: string
  createdOn: string
  description: string
  estimateType: string
  contactRole: CONTACT_ROLE
  imageUrl: string
  startDate: string
  endDate: string
}
/** REDUCER **/
export type CampaignsType = Campaign[] | null
