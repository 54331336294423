import { FC, useState } from 'react'
import clsx from 'clsx'
import { Grid, Typography, Box } from '../../../../../UI'
import { JobItemsProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { icons } from '../../../../../../assets'
import JobItem from '../JobItem'
import { JOB_ITEM_PUBLIC_STATUS, round } from '../../../../../../helpers'

const JobItems: FC<JobItemsProps> = ({ children, status, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const totalPrice = children.reduce((prev, cur) => prev + (cur?.status !== 'REJECTED' ? cur.totalPrice : 0), 0)

  const displayStatus = status === 'Needs Attention' ? `${status} : $${round(totalPrice, 2)}` : status

  const [open, setOpen] = useState(true)

  let titleClass = ''
  switch (status) {
    case JOB_ITEM_PUBLIC_STATUS.NEEDS_ATTENTION: titleClass = classes.needsAttention; break
    case JOB_ITEM_PUBLIC_STATUS.HOLD: titleClass = classes.hold; break
    case JOB_ITEM_PUBLIC_STATUS.SCHEDULED: titleClass = classes.scheduled; break
    case JOB_ITEM_PUBLIC_STATUS.DISPATCHED: titleClass = classes.dispatched; break
    case JOB_ITEM_PUBLIC_STATUS.IN_PROGRESS: titleClass = classes.inProgress; break
    case JOB_ITEM_PUBLIC_STATUS.COMPLETED: titleClass = classes.completed; break
  }

  if (children && children.length > 0) {
    return (
      <Box className={classes.itemsBox}>
        <Box
          className={clsx(classes.title, titleClass)}
          onClick={() => setOpen(!open)}
        >
          <Typography className={classes.itemTitle}>
            {displayStatus}
            {open
              ? <icons.KeyboardArrowUp className={classes.icon} />
              : <icons.KeyboardArrowDown className={classes.icon} />}
          </Typography>
        </Box>
        <Box hidden={!open}>
          <Grid container spacing={5} className={classes.items}>
            {children.map((item, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <JobItem showBetterStyle={showBetterStyle}>{item}</JobItem>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
    )
  }
  return null
}

export default JobItems
