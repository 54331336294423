import { PaymentOption } from 'ducks/types'
import {
  NewInspectionActions,
  NewInspectionValue,
  NewInspectionValues,
  newInspectionTypes
} from './types'

const actions = {
  cleanNewInspection: (): NewInspectionActions => ({
    type: newInspectionTypes.CLEAN,
    payload: null
  }),
  eraseNewInspection: (): NewInspectionActions => ({
    type: newInspectionTypes.ERASE,
    payload: null
  }),
  setNewInspectionStep: (payload: number): NewInspectionActions => ({
    type: newInspectionTypes.SET_STEP,
    payload
  }),
  setNewInspectionSubstep: (payload: number): NewInspectionActions => ({
    type: newInspectionTypes.SET_SUBSTEP,
    payload
  }),
  setNewInspectionValue: (payload: NewInspectionValue): NewInspectionActions => ({
    type: newInspectionTypes.SET_VALUE,
    payload
  }),
  setNewInspectionValues: (payload: NewInspectionValues): NewInspectionActions => ({
    type: newInspectionTypes.SET_VALUES,
    payload
  }),
  createInspection: (
    payload: PaymentOption | null,
    callback: (succ: boolean, inspectionId?: string) => void
  ): NewInspectionActions => ({
    type: newInspectionTypes.CREATE_INSPECTION,
    payload,
    callback
  }),
  
}

export default actions
