import { useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../../../styles'
import classes from './styles.module.scss'
import PropertyInformationBox from './PropertyInformationBox'
import {
  Assignment,
  Map,
  LocalHotel,
  Bathtub,
  AspectRatio,
  Casino,
  Pool,
  MonetizationOn,
  House,
  Book,
  NightsStaySharp,
  KeyboardArrowRight,
} from '@material-ui/icons'
import { questionMark, sampleHouse, tempHome } from 'assets/index'
import { useSelector } from 'react-redux'
import { getPropertyDetail } from 'ducks/selectors'
import { PARCEL_NUMBER_MASK, round } from 'helpers/index'
import CostTypeRadio from 'components/UI/CustomUI/molecules/CostTypeRadio'
import ImageTooltip from 'components/UI/CustomUI/molecules/ImageTooltip'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'
import OrderNowModal from '../../../Modals/OrderNowModal'

const PropertyInformation = () => {
  const styles = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const property = useSelector(getPropertyDetail())
  const { details } = property
  const information = details?.information

  const [costType, setCostType] = useState<string>('Good')
  const [orderNowOpen, setOrderNowOpen] = useState<boolean>(false)

  const propertyDetails = [
    {
      icon: <Assignment className={styles.buttonIcon} />,
      field: 'Property Type:',
      value: information?.propertyType || '-',
    },
    {
      icon: <Assignment className={styles.buttonIcon} />,
      field: 'Assessed Year:',
      value: information?.assessedYear || 0,
    },
    {
      icon: <Map className={styles.buttonIcon} />,
      field: 'County:',
      value: property?.address?.county || '-',
    },
    {
      icon: <MonetizationOn className={styles.buttonIcon} />,
      field: 'Assessed Value:',
      value: `$${information?.assessedValue?.toLocaleString() || 0}`,
    },
    {
      icon: <LocalHotel className={styles.buttonIcon} />,
      field: 'Bedrooms:',
      value: information?.bedrooms || 0,
    },
    {
      icon: <House className={styles.buttonIcon} />,
      field: 'Year built:',
      value: information?.yearBuilt || 0,
    },
    {
      icon: <Bathtub className={styles.buttonIcon} />,
      field: 'Bathrooms:',
      value: information?.bathrooms || 0,
    },
    {
      icon: <Book className={styles.buttonIcon} />,
      field: 'Parcel Number:',
      value: information?.parcelNumber
        ? `${round(parseFloat(information?.parcelNumber), 2)}`
        : '',
    },
    {
      icon: <AspectRatio className={styles.buttonIcon} />,
      field: 'Living area:',
      value: information?.livingArea?.toLocaleString() || 0,
    },
    {
      icon: <NightsStaySharp className={styles.buttonIcon} />,
      field: 'Floors:',
      value: information?.floors || 0,
    },
    {
      icon: <Casino className={styles.buttonIcon} />,
      field: 'Land area:',
      value: information?.lotSize?.toLocaleString() || 0,
    },
    {
      icon: <House className={styles.buttonIcon} />,
      field: 'Neighborhood:',
      value: information?.neighborhood || '',
    },
    {
      icon: <Pool className={styles.buttonIcon} />,
      field: 'Pool:',
      value: information?.pool ? 'YES' : 'NO',
    },
  ]

  const handleOrderNowClick = () => {
    setOrderNowOpen(true)
  }

  return (
    <Grid
      container
      item
      direction="column"
      spacing={2}
      style={{ marginTop: '24px' }}
    >
      <Grid container item spacing={2}>
        <Grid item md={12} lg={4} style={{ width: '100%' }}>
          <PropertyInformationBox
            level=""
            price={information?.marketValue ? `$${information?.marketValue?.toLocaleString()}` : '-'}
            title="As-is Market Value"
            titleIcon={<ImageTooltip icon={<img src={questionMark} alt='question' />} description='Automated valuation model (AVM) generated assuming fair-market value for subject property' />}
          />
        </Grid>
        <Grid item md={12} lg={4} direction="column" justifyContent="space-between" style={{ width: '100%' }}>
          <PropertyInformationBox
            level={
              <Box display='flex' style={{ gap: '4px' }}>
                <CostTypeRadio costType={costType} setCostType={setCostType} />
                <ImageTooltip icon={<img src={questionMark} alt='question' />} description="Automated valuation model (AVM) generated based on potential renovation projects" />
              </Box>
            }
            price={
              (property?.details as any)[
                `enhancedCondition${costType}`
              ] ? `$${(property?.details as any)[
                `enhancedCondition${costType}`
              ].toLocaleString()}` : '-'
            }
            title="Enhanced Condition Estimate"
          />
        </Grid>
        <Grid item md={12} lg={4} direction="column" justifyContent="space-between" style={{ width: '100%' }}>
          <PropertyInformationBox
            level=""
            price={`Up to ${Math.floor((property?.details?.roi || 0) * 100)}%`}
            title="Return on Investment (ROI)"
            titleIcon={<ImageTooltip icon={<img src={questionMark} alt='question' />} description='Estimated return on investment for renovation projects expressed as a percentage' />}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2} style={{ marginTop: '8px' }}>
        <Grid item lg={12} xl={6}>
          <Box
            height="100%"
            className={styles.containerBox}
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxHeight="260px"
            overflow="hidden"
          >
            <img
              src={property?.imageUrl || tempHome}
              alt="home img"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
        
        {isSmallScreen && (
          <Grid item container>
            <Button
              endIcon={<KeyboardArrowRight style={{ width: '16px', height: '16px' }} />}
              style={{ 
                backgroundColor: 'var(--bosscat-grey-1000)',
                height: '60px',
                borderRadius: '8px',
                color: 'white'
              }}
              onClick={handleOrderNowClick}
              fullWidth
            >
              <Typography className={styles.lgFont} style={{ textTransform: 'none' }}>Order Now</Typography>
            </Button>
          </Grid>
        )}
        <Grid
          item
          container
          lg={12} xl={6}
          style={{ height: 'min-content', marginTop: '4px' }}
        >
          <Grid
            item
            container
            className={styles.containerBox}
            style={{ padding: '16px' }}
            spacing={1}
          >
            {propertyDetails.map((detail) => (
              <Grid
                item
                container
                md={6}
                xs={12}
                style={{ gap: '8px' }}
                alignItems="center"
              >
                <Box display="flex" style={{ gap: '8px', flexWrap: 'nowrap' }} alignItems="center">
                  {detail.icon}
                  <Typography
                    className={styles.lgFont}
                    style={{ fontWeight: 900 }}
                  >
                    {detail.field}
                  </Typography>
                </Box>
                <Typography
                  className={styles.lgFont}
                  style={{ fontWeight: 900 }}
                >
                  <Typography
                    component="span"
                    className={styles.lgFont}
                    style={{ fontWeight: 400 }}
                  >{` ${detail.value}`}</Typography>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <OrderNowModal
        open={orderNowOpen}
        setOpen={setOrderNowOpen}
      />
    </Grid>
  )
}

export default PropertyInformation
