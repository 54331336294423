import { FC, useState } from 'react'
import {
  Dialog, STextField, Button, DialogContent, Typography,
  FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Modal, Grid, TextField, Box
} from '../../../../../UI'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { FeedbackModalProps } from './types'
import { USER_TYPE, isEmpty } from 'helpers/index'
import { icons } from '../../../../../../assets'
import { REFERRAL_TYPE } from 'ducks/types'
import { getNewOrderMaintenanceValue, getUser } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

const marketingOptions = [
  'Social Media',
  'Email from BOSSCAT',
  'Event',
  'Billboard',
  'BOSSCAT Van',
  'Online Search Result',
  'News Article',
  'Other'
]

const FeedbackModal: FC<FeedbackModalProps> = ({ open, setOpen, onSubmit, showBetterStyle = false, titleToShow }) => {
  let options = [
    { key: REFERRAL_TYPE.EMPLOYEE, label: 'From someone at BOSSCAT' },
    { key: REFERRAL_TYPE.MARKETING, label: 'BOSSCAT Marketing' },
    { key: REFERRAL_TYPE.OTHER, label: 'Other' },
  ]

  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles
  const [optionSelected, setOptionSelected] = useState<REFERRAL_TYPE>()
  const [details, setDetails] = useState('')
  const [showEmployee, setShowEmployee] = useState(false)
  const [showMarketing, setShowMarketing] = useState(false)
  const [otherText, setOtherText] = useState('')

  const user = useSelector(getUser)
  const clientType = useSelector(getNewOrderMaintenanceValue("clientType"))

  const validate: boolean = !isEmpty(optionSelected) || ((showEmployee || showMarketing) && !isEmpty(details))

  const handleChange = (event: any) => {
    setDetails("")
    setOptionSelected(event.target.value)
  }

  const handleChangeMarketing = (event: any) => {
    setDetails(event.target.value)
    setOtherText("")
  }

  const handleClose = () => {
    setOptionSelected(options[0].key)
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(optionSelected ?? '', otherText || details)
    handleClose()
  }

  const handleOnSubmit = () => {
    if (validate) {
      switch (optionSelected) {
        case "EMPLOYEE":
        case "OTHER":
          !showEmployee ? setShowEmployee(true) : handleSubmit()
          break;
        case "MARKETING":
          !showMarketing ? setShowMarketing(true) : handleSubmit()
          break;
        default:
          handleSubmit()
          break;
      }
    }
  }

  if (![USER_TYPE.HOMEOWNER].includes((user.clientType || clientType))) {
    const indexToInsert = 1;
    const newOptions = [...options.slice(0, indexToInsert), { key: REFERRAL_TYPE.NAR, label: 'From NAR' }, ...options.slice(indexToInsert)]
    options = newOptions;
  }

  const handleGoBack = () => {
    setShowEmployee(false)
    setShowMarketing(false)
  }

  const title = showEmployee ? optionSelected === "OTHER" ? 'OTHER' : 'Bosscat Employee name' : titleToShow ? titleToShow : 'how did you hear about bosscat?'

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="feedback-modal"
      aria-describedby="feedback-modal-description"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={classes.modal}
    >
      <Grid
        className={classes.root}
      >
        <DialogContent style={isMobile ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        } : { display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h6' className={classes.text}>
            {title}
          </Typography>
          <IconButton
            style={{ backgroundColor: '#F5F6F7' }}
            className={classes.close}
            onClick={handleClose}
          >
            <icons.Close />
          </IconButton>
        </DialogContent>
        <DialogContent>
          <FormControl component='fieldset' style={{ width: '100%' }} className={`${classes.radioButtonContainer} ${showMarketing ? classes.radioButtonContainerFlex : ''}`}>
            {!showEmployee && !showMarketing && <RadioGroup value={optionSelected} onChange={handleChange}>
              {options.map((option, index) =>
                <FormControlLabel style={optionSelected === option.key ? { borderColor: "var(--bosscat-blue-600)" } : {}} key={index} value={option.key} control={<Radio color='primary' />} label={option.label} className={classes.text} />
              )}
            </RadioGroup>}
            {showEmployee &&
              <TextField
                type="text"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                className={classes.detailsInput}
              />
            }
            {showMarketing &&
              <>
                <Grid
                  item
                  container
                  spacing={2}
                  style={{ /* gap: '1rem',  */marginTop: '.5rem' }}
                >
                  {/*  <RadioGroup value={details} onChange={handleChangeMarketing}> */}
                  {marketingOptions.map((option, index) =>
                    <Grid item xs={6}>
                      <FormControlLabel
                        style={details === option ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                        key={index}
                        value={option}
                        control={<Radio
                          checked={details === option}
                          onChange={handleChangeMarketing}
                          color='primary' />
                        }
                        label={option}
                        className={classes.text}
                      /></Grid>
                  )}
                </Grid>
                {(details === 'Other' || !isEmpty(otherText)) &&
                  <Box className={classes.otherInput}> <TextField
                    type="text"
                    value={otherText}
                    onChange={(e) => {
                      setOtherText(e.target.value)
                    }}
                  />
                  </Box>
                }
              </>
            }
          </FormControl>
        </DialogContent>
        <DialogContent style={{ display: 'flex', gap: '1rem' }}>
          {(showEmployee || showMarketing) &&
            <Button
              type='submit'
              variant='outlined'
              color='primary'
              startIcon={<icons.ArrowBack />}
              className={classes.backButton}
              fullWidth
              onClick={handleGoBack}
            >
              Back
            </Button>
          }
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            fullWidth
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
        </DialogContent>
        <DialogContent />
      </Grid>
    </Modal>
  )
}

export default FeedbackModal
