import storeInfo from 'ducks/store'
import { JOB_ITEM_PUBLIC_STATUS, JOB_VIEW } from 'helpers'
import { Item } from 'ducks/types'

export const getJobItems = () => (): Item[] | [] => {
  const { jobItems } = storeInfo.store.getState()
  return jobItems
}

export const getJobItemsGroupBy = (view: JOB_VIEW) => (): any => {
  const { jobItems } = storeInfo.store.getState()

  if (view === JOB_VIEW.CATEGORY) {
    const groupByCategory = (jobItems || []).reduce((group: any, item: Item) => {
      const { category } = item
      group[category] = group[category] ?? []
      group[category].push(item)
      return group
    }, {})

    return groupByCategory
  }

  if (view === JOB_VIEW.STATUS) {
    const groupByStatus: {
      [JOB_ITEM_PUBLIC_STATUS.NEEDS_ATTENTION]?: Item[], [JOB_ITEM_PUBLIC_STATUS.HOLD]?: Item[],
      [JOB_ITEM_PUBLIC_STATUS.SCHEDULED]?: Item[], [JOB_ITEM_PUBLIC_STATUS.DISPATCHED]?: Item[],
      [JOB_ITEM_PUBLIC_STATUS.IN_PROGRESS]?: Item[], [JOB_ITEM_PUBLIC_STATUS.COMPLETED]?: Item[],
    } = {}

    const needsAttention = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.NEEDS_ATTENTION)
    const hold = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.HOLD)
    const scheduled = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.SCHEDULED)
    const dispatched = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.DISPATCHED)
    const inProgress = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.IN_PROGRESS)
    const completed = jobItems.filter((item: Item) => item.publicJobStatus === JOB_ITEM_PUBLIC_STATUS.COMPLETED)

    if (needsAttention.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.NEEDS_ATTENTION] = needsAttention
    if (hold.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.HOLD] = hold
    if (scheduled.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.SCHEDULED] = scheduled
    if (dispatched.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.DISPATCHED] = dispatched
    if (inProgress.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.IN_PROGRESS] = inProgress
    if (completed.length > 0) groupByStatus[JOB_ITEM_PUBLIC_STATUS.COMPLETED] = completed

    return groupByStatus
  }

  return { All: jobItems }
}
