import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '840px',
      borderRadius: '32px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
      }
    },
    title: {
      fontSize: '24px',
      //padding: '0 4px 0 24px',
      fontFamily: 'NextArtBold',
      fontWeight: 500,
    },
    input: {
    },
    inputTitle: {
      fontWeight: 900,
    },
    check: {
      flex: 1,
      paddingRight: '16px',
      margin: '0 !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      minWidth: '146px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
      }
    },
    title2: {
      fontSize: '30px',
      padding: '0 24px 0 4px',
      fontFamily: 'Lato',
      fontWeight: 400,
      color: 'var(--bosscat-blue-600) !important'
    },
    text: {
      fontSize: '20px',
      padding: '0 20px',
      fontFamily: 'LatoNormal',
      color: "#1A2A55"
    },
    image: {
      width: '192px',
      height: '192px',
      marginBottom: '12px'
    },
    submit: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'BetterSansRegular',
      '&:hover': {
        color: 'var(--progress-color-back) !important',
        backgroundColor: 'var(--button-focus-color)'
      }
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
      justifyContent: 'flex-end'
    },
    buttonClose: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: '#FAFBFF',
      borderRadius: 8,
      height: 'fit-content',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      width: 'fit-content',
      padding: '.7rem 1.5rem',
      '&:hover': {
        color: 'var(--bosscat-blue-600)',
        backgroundColor: '#FAFBFF'
      }
    },
    buttonAdd: {
      textTransform: 'none',
      width: 'fit-content',
      color: '#FFFFFF',
      borderRadius: 8,
      height: 'fit-content',
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      padding: '.7rem 1.5rem',
      fontWeight: 400,
      backgroundColor: 'var(--bosscat-blue-600)',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#EB5151'
      }
    },

  })
)
