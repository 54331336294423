import { createStyles, makeStyles, Theme } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: "#FCFCFC",
      padding: '16px',
      borderRadius: '12px',
      boxShadow: " 0px 0px 1px 0px #0919314F",
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        width: "calc(100% - 32px)",
        marginLeft: "16px",
      }
    },
    image: {
      borderRadius: '12px',
      width: '146px',
      height: '126px',
      objectFit: 'cover',
      marginRight: '20px',
    },
    verticalContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      }
    },
    horizontalContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      fontFamily: 'NextArtHeavy',
      fontSize: '16px',
      color: "#0B060F",
      margin: 'auto 0px',
      marginRight: "16px"
    },
    userType: {
      backgroundColor: "#EAF2FE",
      borderRadius: '16px',
      color: "#2662C6",
      fontFamily: 'Lato',
      fontSize: '12px',
      padding: "4px 8px"
    },
    separator: {
      width: 4,
      height: 4,
      backgroundColor: theme.palette.grey[700],
      margin: '0px 12px'
    },
    data: {
      fontFamily: 'LatoLight',
      fontSize: '16px',
      color: theme.palette.grey[700],
    },
    icons: {
      fontSize: '20px',
      color: theme.palette.grey[700],
      marginRight: '10px',
    },
    menuItemIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      paddingRight: theme.spacing(1),
      transform: 'translateY(-2px)'
    },
    avatar: {
      margin: '0 0 0 auto',
      borderRadius: '8px',
      width: theme.spacing(5),
      height: theme.spacing(5),
      objectFit: 'cover'
    },
    icon: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    personIcon: {
      color: theme.palette.background.default,

      fontSize: theme.spacing(5)
    },
    loading: {
      color: theme.palette.primary.contrastText
    },
    dataRow: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      }
    }
  })
)
