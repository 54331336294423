import { Divider, Grid, Typography } from '@material-ui/core'
import { FC } from 'react'
import useStyles from '../styles'
import { round } from 'helpers/index'

interface ProjectCostsProps {
  totalProjectCost: number
  discountPercents: number
  depositDue: number
  totalWithDiscount: number
  isPayAtClose: boolean
  taxRate: number
  taxAmount: number
}

const ProjectCosts: FC<ProjectCostsProps> = ({
  totalProjectCost,
  discountPercents,
  depositDue,
  totalWithDiscount,
  isPayAtClose,
  taxRate,
  taxAmount,
}) => {
  const styles = useStyles()

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography className={styles.title}>💵 PROJECT costs</Typography>
      </Grid>
      <Grid item container spacing={1} direction="column">
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ width: '40%' }}
        >
          <Grid item>
            <Typography
              className={styles.latoPrimaryText}
              style={{ fontWeight: 700 }}
            >
              Total Project Cost
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={styles.latoPrimaryText}
              style={{ fontWeight: 400 }}
            >{`$${round(totalProjectCost)}`}</Typography>
          </Grid>
        </Grid>
        {discountPercents !== 0 && (
          <>
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ width: '40%' }}
            >
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 700 }}
                >{`Discount (${discountPercents}%)`}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 400 }}
                >{`$${round(
                  (totalProjectCost / 100) * discountPercents
                )}`}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ width: '40%' }}
            >
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 700 }}
                >
                  Taxes ({round((taxRate || 0) * 100, 2)}%):
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 400 }}
                >{`$${round(taxAmount, 2)}`}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: 'var(--bosscat-grey-400)',
                  height: '2px',
                }}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              style={{ width: '40%' }}
            >
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 700 }}
                >
                  Project Cost After Discount
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={styles.latoPrimaryText}
                  style={{ fontWeight: 700 }}
                >{`$${round(totalWithDiscount)}`}</Typography>
              </Grid>
            </Grid>
          </>
        )}
        {!isPayAtClose && (
          <Grid item>
            <Divider
              orientation="horizontal"
              style={{
                backgroundColor: 'var(--bosscat-grey-400)',
                height: '2px',
              }}
            />
          </Grid>
        )}
      </Grid>
      {!isPayAtClose && (
        <Grid item style={{ width: '40%' }}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography
                className={styles.latoPrimaryText}
                style={{ fontWeight: 700 }}
              >
                <i>{`Deposit Due (${depositDue}%)`}</i>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={styles.latoPrimaryText}
                style={{ fontWeight: 700 }}
              >{`$${round(
                (totalWithDiscount / 100) * depositDue
              )}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ProjectCosts
