import { Promo, PromoActions, promoTypes } from './types'

const actions = {
  getPromo: (
    payload: {
      promoCode: string,
      stripeProductPriceId: string
    },
    callback?: (succ: boolean, promo: Promo | null) => void
  ): PromoActions => ({
    type: promoTypes.GET_PROMO,
    payload,
    callback
  }),
}

export default actions
