import { authTypes } from 'ducks/auth/types'
import {
  ApprovalActions,
  ApprovalType,
  ApprovalValue,
  ApprovalValues,
  approvalTypes,
} from './types'

const initialState: ApprovalType = {
  propertyUnderContract: false,
  schedulerSelect: null,
  paymentOption: 'pay_at_close',

  approvalStep: 0,
  agentIsHomeowner: false,
}

const reducer = (
  state = initialState,
  action: ApprovalActions
): ApprovalType => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }
    case approvalTypes.CLEAN: {
      return {
        ...initialState,
      }
    }
    case approvalTypes.SET_VALUE: {
      const { attr, value } = payload as ApprovalValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case approvalTypes.SET_VALUES: {
      const { attrs } = payload as ApprovalValues
      return {
        ...state,
        ...attrs,
      }
    }

    default:
      return state
  }
}

export default reducer
