import { useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { Receipt, Box } from '../../../../UI'
import { history, isEmpty } from '../../../../../helpers'
import { getEstimate } from '../../../../../ducks/selectors'
import { Estimate } from '../../../../../ducks/types'

const ReceiptScreen: FC = () => {
  const replace = history.useReplace()

  const estimate = useSelector(getEstimate()) as Partial<Estimate>
  const paymentOption = null
  const expediteOption = null

  useEffect(() => { if (isEmpty(estimate)) replace('/') }, [estimate, replace])

  if (isEmpty(estimate)) return <div />

  return (
    <Box>
      <Receipt
        estimate={estimate}
        paymentOption={paymentOption}
        expediteOption={expediteOption}
      />
    </Box>
  )
}
export default ReceiptScreen
