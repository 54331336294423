export const imageMimeTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp']
export const videoMimeTypes = ['video/webm', 'video/ogg', 'video/mp4', 'video/mov', 'video/avi', 'video/wmv', 'video/mkv', 'video/flv', 'video/3gp', 'video/mpeg', 'video/quicktime']
export const applicationMimeTypes = ['application/pdf']
export const csvMimeTypes = ['.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values']
export const selectableFileTypes = [...applicationMimeTypes, ...imageMimeTypes, ...csvMimeTypes, ...videoMimeTypes]

export const getFileExtension = (file: File): string => {
  try {
    return file.type.split('/')[1]
  } catch (_error) {
    return ''
  }
}

export const dataURItoFile = (dataUri: string, name: string): File => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUri.split(',')[1])

  // separate out the mime component
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([new Blob([ab], { type: mimeString })], name, { type: mimeString })
}

export const downloadURI = (uri: string, name: string): void => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}


export const getCompressedImageUrl = (url: string) => {
  if (url.includes("cloudinary")) {
    return url.replace("/upload/", "/upload//w_70,c_scale/");
  } else {
    let resizedUrl = url.replace("/raw", "");
    let begin = resizedUrl.substring(0, resizedUrl.lastIndexOf("/"));
    let end = resizedUrl.substring(resizedUrl.lastIndexOf("/"));
    return begin + "/90" + end;
  }
}