import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      borderRadius: '12px',
      padding: '6px',
      border: '3px solid ' + theme.palette.primary.light
    },
    barHold: {
      width: '100%',
      maxWidth: '500px',
      border: '3px solid rgba(60, 44, 164, 1)'
    },
    barWarning: {
      width: '100%',
      maxWidth: '500px',
      border: '3px solid rgba(202, 0, 0, 1)'
    },
    status: {
      display: 'flex'
    },
    statusFull: {
      width: '100%',
      textAlign: 'center'
    },
    text: {
      borderRadius: '8px',
      padding: '6px 16px 4px'
    },
    dot: {
      width: '4px',
      height: '4px',
      borderRadius: '25px',
      alignSelf: 'center',
      margin: '0 12px',
      background: 'var(--bosscat-blue-600)'
    },
    done: {
      color: '#1C4A94'
    },
    actual: {
      // color: 'var(--bosscat-blue-600)',
      color: '#2662C6',
      backgroundColor: 'rgba(250, 251, 255, 1)',
      fontFamily: 'LatoBold'
    },
    hold: {
      color: 'rgba(60, 44, 164, 1)',
      backgroundColor: 'rgba(241, 240, 255, 1)'
    },
    warning: {
      color: 'rgba(202, 0, 0, 1)',
      backgroundColor: 'rgba(255, 244, 244, 1)'
    }
  })
)
