import { FC, useState } from 'react'
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone'
import { Box, Typography, Button, CircularProgress, Snackbar } from '../../../../UI'
import useStyles from './styles'
import { UploadDocumentsProps } from './types'
import { upload, file as fileIcon } from '../../../../../assets'
import {
  imageMimeTypes,
  applicationMimeTypes
} from '../../../../../helpers/files'
import ActionButton from '../../atoms/ActionButton'
import { useClassName } from '../../../../../hooks'
import { Alert } from '@material-ui/lab'

const allowedFilesExtensions = [...imageMimeTypes, ...applicationMimeTypes]

const UploadDocuments: FC<UploadDocumentsProps> = ({
  dropzoneText = 'Upload File',
  files,
  onChange,
  allowedFiles = allowedFilesExtensions,
  error,
  handleDelete,
  className: customClassName,
  filesLimit = 99,
  sx
}) => {
  const classes = useStyles()
  const className = useClassName()
  const inputId = `contained-button-file-${dropzoneText
    .toLowerCase()
    .split(' ')
    .join('-')}`

  // const [files, setFiles] = useState<null | File[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploading, setUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    show: false,
    fileName: ''
  })

  const handleAddFile = (newFiles: FileObject[]) => {
    // const toAdd = newFiles ? newFiles.map(newFile => newFile.file) : []
    // setFiles([...files ?? [], ...toAdd ?? []])
    setUploading(true)
    onChange(
      newFiles.map(i => i.file),
      () => {
        setUploading(false)
      }
    )
  }

  const handleChange = async (event: any): Promise<void> => {
    const newFiles = [...event.target.files] as File[]
    event.target.value = null

    const newFilesToAdd = newFiles.filter(file => {
      if (allowedFiles.includes(file.type)) {
        return file
      }
      else {
        setErrorMessage({
          show: true,
          fileName: file.name
        })
        return null
      }
    })
    handleAddFile(await Promise.all(
      [...newFilesToAdd].map(async file => {
        const data = await file.arrayBuffer()
        return { file, data } as FileObject
      })
    ))
  }

  const handleDeleteFile = (fileName: string) => {
    if (files && files.length > 1) {
      const copy = [...files].filter(file => file.name !== fileName)
      handleDelete(copy)
    } else {
      handleDelete([])
    }
  }

  return (
    <Box data-testid='upload-documents' className={className(customClassName ?? '', classes.upload, error ? classes.error : '')} sx={sx}>
      {uploading
        ? (
          <Box
            display='flex'
            height='30vh'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress data-testid="circular-progress" />
          </Box>
        )
        : files && files.length > 0
          ? (
            <>
              {errorMessage.show &&
                <Snackbar
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  open={true}
                  autoHideDuration={6000}
                  onClose={() => setErrorMessage({
                    show: false,
                    fileName: ''
                  })}
                  style={{ width: "100%" }}
                >
                  <Alert variant='filled' severity="error">File {errorMessage.fileName} was rejected. File type not supported. </Alert>
                </Snackbar>
              }
              <input
                data-testid="file-uploader"
                accept={allowedFiles.join(', ')}
                style={{ display: 'none' }}
                id={inputId}
                type='file'
                multiple
                onChange={handleChange}
              />
              <Box className={classes.buttonBox}>
                <label htmlFor={inputId}>
                  <Button
                    variant='outlined'
                    className={classes.button}
                    size='large'
                    // onClick={() => { }}
                    fullWidth
                    endIcon={<img src={upload} className={classes.image} alt='' />}
                    component='span'
                  >
                    {dropzoneText}
                  </Button>
                </label>
              </Box>
              <Box className={classes.filesBox}>
                {files.map((file, index) => {
                  return (
                    <Box key={index} className={classes.file}>
                      <img src={fileIcon} alt='' />
                      <Typography variant='body1' className={classes.name}>{file.name}</Typography>
                      <Box className={classes.delete}>
                        <ActionButton
                          id={`${file.name}-delete`}
                          backgroundColor='secondary'
                          icon='delete'
                          onClick={() => handleDeleteFile(file.name)}
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </>
          )
          : (
            <DropzoneAreaBase
              // @ts-ignore
              Icon={() => <img src={upload} alt='' />}
              dropzoneClass={classes.dropzone}
              previewGridClasses={{ container: classes.dropzoneTextContainer }}
              dropzoneText={dropzoneText}
              maxFileSize={200000000}
              acceptedFiles={allowedFiles}
              onAdd={handleAddFile}
              filesLimit={filesLimit}
            />
          )}
    </Box>
  )
}

export default UploadDocuments
