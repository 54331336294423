import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100%',
      gridAutoRows: 'min-content min-content auto',
      [theme.breakpoints.down('lg')]: {
        padding: '1rem'
      }
    },
    progressBox: {
      width: '100%',
      maxWidth: '315px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '360px'
      }
    },
    progress: {
      background: 'rgba(39, 186, 45, 0.08)',
      height: 8,
      borderRadius: 4
    },
    header: {
      display: 'grid',
      gap: '16px',
      width: '100%',
      padding: '16px',
      [theme.breakpoints.up('sm')]: {
        gridAutoFlow: 'column',
        gridAutoColumns: 'auto min-content',
        padding: '16px 0'
      }
    },
    headerColumn: {
      display: 'flex',
      gap: '16px',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '16px',
      alignItems: 'center'
    },
    headerItem: {
      display: 'flex',
      gap: '4px',
      flexDirection: 'row'
    },
    headerTextBold: {
      fontFamily: 'LatoNormal',
      fontWeight: 700
    },
    headerText: {
      fontFamily: 'LatoNormal',
      fontWeight: 400
    },
    estimateBox: {
      display: 'flex',
      gap: '16px',
      flexDirection: 'row',
      color: 'var(--bosscat-blue-600)'
    },
    estimateText: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      background: '#FAFBFF',
      borderRadius: '16px',
      padding: '8px 16px',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31)'
    },
    icon: {
      width: '24px',
      height: '24px'
    },
    dot: {
      width: '4px',
      height: '4px',
      borderRadius: '25px',
      alignSelf: 'center',
      background: 'var(--bosscat-blue-600)'
    },
    combo: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      textTransform: 'none',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31)',
      '&:hover': {
        backgroundColor: 'var(--row-background)',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 1px 0px 2px rgba(26, 42, 85, 0.31), 0px 1px 3px rgba(26, 42, 85, 0.31)'
      },
      '& .MuiButton-label': {
        paddingTop: 0
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%'
      }
    },
    comboSelected: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      textTransform: 'none',
      borderRadius: '8px',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-600)',
        boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 1px 2px rgba(26, 42, 85, 0.31), -1px 1px 2px rgba(26, 42, 85, 0.31)'
      },
      '& .MuiButton-label': {
        paddingTop: 0
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%'
      }
    },
    iconCombo: {
      color: 'var(--head-text-color)'
    },
    iconSelected: {
      color: 'var(--white-color)'
    },
    subtitle: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--head-text-color)'
    },
    subtitleSelected: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--white-color)'
    },
    estimateButton: {
      display: 'flex',
      justifyContent: 'center',
      gap: '8px',
      marginLeft: '16px',
      alignItems: 'center',
      cursor: 'pointer',
      flexDirection: 'column',
      backgroundColor: 'rgba(249, 250, 250, 1)',
      padding: '8px 8px 4px',
      borderRadius: '8px'
    },
    logo: {
      width: '46px',
      // height: '36px'
    },
    address: {
      color: 'var(--input-color)',
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      fontSize: '28px',
      lineHeight: '150%',
      paddingLeft: "50px",
      [theme.breakpoints.down('lg')]: {
        // fontSize: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        paddingLeft: '8px'
      }
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      // height: '80px',
      alignItems: 'center',
      paddingLeft: '50px',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '24px',
        fontSize: '24px',
      },
    },
    titleContainer: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        padding: '1rem',
        alignItems: 'center'
      }
    },
    addressBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    description: {
      color: 'var(--input-color)',
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      fontSize: '28px',
      lineHeight: '150%',
      [theme.breakpoints.down('lg')]: {
        // fontSize: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem'
      }
    },
    text: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 700,
      borderRadius: '8px',
      padding: '6px 16px 4px',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'rgba(250, 251, 255, 1)',
      textAlign: 'center'
    },
    total: {
      fontSize: '18px',
      backgroundColor: 'var(--background-color-secondary)',
      textAlign: 'center',
      padding: '8px'
    },
    button: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '2rem 0'
    },
    downloadButtonMobile: {
      height: '100% !important'
    },
    totalPrice: {
      fontFamily: 'NextArtBold',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '150%',
      color: 'var(--input-text)'
    }
  })
)
