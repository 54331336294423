import {
  CheckboxProps as MUICheckboxProps,
  Grid,
  Checkbox as MUICheckbox,
  Typography,
} from '@material-ui/core'
import { FC } from 'react'
import useStyles from './styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface CheckboxProps extends MUICheckboxProps {
  text?: string | React.ReactNode
  checked?: boolean
  onChange?: () => void
  error?: boolean
  flexDirection?: 'row-reverse' | 'row' | 'column'
  justifyContent?: 'flex-start' | 'space-between' | 'flex-end'
  containerStyle?: CSSProperties
  borderColor?: string
  background?: string
}

const Checkbox: FC<CheckboxProps> = ({
  text,
  checked,
  onChange,
  error,
  flexDirection = 'row',
  justifyContent = 'flex-start',
  style,
  containerStyle,
  borderColor,
  background = 'FFF',
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      className={classes.checkboxContainer}
      style={{
        borderColor:
          error && !checked ? 'var(--border-border-error-light)' : '#99DDBD',
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        border: `2px solid ${borderColor}`,
        backgroundColor: background,
        ...containerStyle,
      }}
    >
      <MUICheckbox
        checked={checked}
        onChange={onChange}
        name="consent"
        color="primary"
        style={{
          color: error && !checked ? 'red' : 'primary',
          padding: 0,
          height: '24px',
          ...style,
        }}
      />
      {typeof text === 'object' ? (
        text
      ) : (
        <Typography className={classes.text}>{text}</Typography>
      )}
    </Grid>
  )
}

export default Checkbox
