import { Order } from 'ducks/types'
import { OrderActions, orderTypes } from './types'

const actions = {
  fetchOrder: (
    payload: string,
    callback?: (succ: boolean) => void
  ): OrderActions => ({
    type: orderTypes.FETCH_ORDER,
    payload,
    callback
  }),
  setOrder: (payload: Order | null): OrderActions => ({
    type: orderTypes.SET_ORDER,
    payload
  }),
}

export default actions
