import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    headerLogos: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.5rem',
      "@global": {
        'img': {
          'padding': '0 1rem',
          '&:first-child': {
            borderRight: '2px solid var(--border-color)'
          }
        }
      }
    },
    header: {
      minHeight: '90vh',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50% !important',
      backgroundAttachment: 'scroll',
      color: 'white',
      padding: '0 4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
        minHeight: '60vh',
        // justifyContent: 'flex-start',
      }
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: '3.5rem',
      lineHeight: 1.2,
      width: '50%',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        width: '100%',
      }
    },
    subtitle: {
      fontSize: '24px',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        // fontSize: '32px',
        width: '100%',
      }
    },
    logo: {
      maxWidth: '180px'
    },
    footer: {
      backgroundColor: 'var(--bosscat-blue-600)',
      color: 'white',
      fontFamily: 'NextArt',
      fontSize: '24px',
      textAlign: 'center',
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      }
    },
    trustpilot: {
      position: 'absolute',
      maxWidth: '300px',
      top: '2.5rem',
      right: '2.5rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })
)
