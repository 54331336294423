import { FC } from 'react'
import { Grid, Typography, Box, Avatar } from '../../../../UI'
import { ItemProps } from './types'
import useStyles from './styles'
import { calendar, job } from '../../../../../assets'
import { capitalize, formatTimestampDate, history, round } from '../../../../../helpers'
import { format } from 'date-fns'
import { useClassName } from '../../../../../hooks'

const PaymentItem: FC<ItemProps> = ({ item, index, className }) => {
  const classes = useStyles()
  const classNames = useClassName()
  const pushForward = history.usePushForward()

  const showDetail = () => {
    pushForward(`${item.id}`)
  }

  return (
    <Grid
      container
      item
      xs={12}
      key={index}
      className={classNames(classes.root, className ?? '')}
      onClick={() => showDetail()}
    >
      <Grid container xs={1} justify='center'>
        <Box className={classes.iconJobContainer}>
          <Avatar
            alt=''
            src={item.resourceType === 'JOB' ? job : calendar}
            className={classes.iconJob}
            variant='square'
          />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title} noWrap>
            {`${capitalize(item.resourceType)}: ${item.address}`}
          </Typography>
          <Typography className={classes.dateInfo}>
            {format(formatTimestampDate(item.createdOn), 'MM/dd/yyyy')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography className={classes.price}>{'$' + round(item.amount, 2)}</Typography>
      </Grid>
    </Grid>
  )
}

export default PaymentItem
