import { Button, Grid, Typography } from "@material-ui/core"

import useStyles from './styles'
import { icons } from 'assets'
import { estimateActions } from "ducks/actions"
import { useDispatch, useSelector } from "react-redux"
import { getEstimate } from "ducks/selectors"
import { ESTIMATE_STATUS, formatTimestamp } from "helpers/index"
import { EstimateContact } from "ducks/types"

const ContactsListTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  // const user = useSelector(getUser)

  // const isAgent = [USER_TYPE.BROKER, USER_TYPE.INSPECTOR, USER_TYPE.INSTITUTIONAL].includes(user.clientType)

  const handleShare = (contact: Partial<EstimateContact>) => {
    dispatch(estimateActions.shareEstimate(contact, (succ) => {
    }))
  }

  return (
    <Grid container spacing={1} className={classes.container}>
      {estimate?.properties.contacts?.map((contact, index) => {
        // const role = getContactRoleOpt()?.find(contactOption => contactOption?.key === contact?.role)
        return (
          <Grid item container key={index} className={classes.contactListContainer}>
            <Grid item xs={10}>
              <Typography className={classes.title}>{contact?.firstName} {contact?.lastName} </Typography>
              <Typography variant="body2" className={classes.role}>{contact?.email} </Typography>
            </Grid>
            <Grid container item xs={2} className={classes.actions}>
              <Button
                className={classes.buttonShare}
                onClick={() => handleShare(contact)}
                disabled={estimate.status === ESTIMATE_STATUS.APPROVED}
              >
                <icons.Share className={classes.buttonShareIcon} />
              </Button>
              {contact?.insertedOn &&
                <Typography className={classes.small}>Shared on: {formatTimestamp(contact?.insertedOn, 'MMM D, YYYY')}</Typography>
              }
            </Grid>
          </Grid>
        )
      }
      )}
    </Grid >
  )
}

export default ContactsListTab