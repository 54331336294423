import { DocuSignActions, docuSignTypes, GetAccessTokenParams, GetUserInfoParams } from './types'

const actions = {
  getAccessToken: (
    payload: GetAccessTokenParams,
    callback?: (succ: boolean, accessToken?: string) => void
  ): DocuSignActions => ({
    type: docuSignTypes.GET_ACCESS_TOKEN,
    payload,
    callback
  }),
  getUserInfo: (
    payload: GetUserInfoParams,
    callback?: (succ: boolean, userInfo?: any) => void
  ): DocuSignActions => ({
    type: docuSignTypes.GET_USER_INFO,
    payload,
    callback
  }),

}

export default actions
