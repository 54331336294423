import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        SuccessContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            maxWidth: "50vw",
            margin: "auto",
            height: "90vh",
            [theme.breakpoints.down('md')]: {
                maxWidth: "95vw",
                padding: "0"
            },
        },
        MessageContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        Title: {
            [theme.breakpoints.up('md')]: {
                fontSize: "42px",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "24px",
            },
        },
        Subtitle: {
            fontFamily: "LatoNormal",
            marginTop: "8px",
            [theme.breakpoints.up('md')]: {
                fontSize: "20px",
                lineHeight: "30px"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
                padding: "0 16px"
            },
        },
        Image: {
            width: "160px",
            height: "140px",
            marginBottom: "8px",
            [theme.breakpoints.up('md')]: {
                // display: "block",
            },
            [theme.breakpoints.down('md')]: {
                // display: "none",
                marginBottom: "24px"
            },
        },
        GiftInfoContainer: {
            width: "100%",
            padding: "16px",
            borderRadius: "16px",
            backgroundColor: "#F9FAFA",
            display: "flex",
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                alignItems: "center"
            },
        },
        InfoItem: {
            display: "flex",
            alignItems: "center"
        },
        GiftImage: {
            width: "160px",
            height: "160px",
            borderRadius: "8px",
            objectFit: "cover",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            },
        },
        InfoName: {
            [theme.breakpoints.up('md')]: {
                fontSize: "32px",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "20px",
            },
        },
        InfoLabel: {
            color: "#494A5A",
            [theme.breakpoints.up('md')]: {
                fontSize: "20px",
                fontFamily: "LatoBold",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
                fontFamily: "Lato",
            },
        },
        InfoValue: {
            color: "#494A5A",
            fontFamily: "LatoNormal",
            marginLeft: "4px",
            [theme.breakpoints.up('md')]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
            },
        },
        InfoWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: "16px",
            paddingBottom: "16px",
            [theme.breakpoints.down('md')]: {
                alignItems: "center",
                marginLeft: "0px",
                marginTop: "16px",
            },
        },
        Button: {
            backgroundColor: "#FAFBFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            color: "var(--bosscat-blue-600)",
            borderRadius: "8px",
            padding: "16px",
            marginTop: "24px",
            [theme.breakpoints.down('md')]: {
                marginTop: 0,
                width: "100%",
                backgroundColor: "var(--bosscat-blue-600)",
                color: "#fff"
            }
        },
        Arrow: {
            marginLeft: "8px"
        },
        NextButton: {
            [theme.breakpoints.down('md')]: {
                position: "fixed",
                zIndex: 99,
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#fff",
                padding: "8px 16px",
                display: "grid",
                placeItems: "center",
                boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25)"
            },
        },
        modal: {

        }
    }))
