/* import {
  Box, Button, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, Grid, IconButton, Typography
} from '@mui/material' */
import { ModalProps } from './types'

import useStyles from './styles'
import { useClassName } from 'hooks/useClassName'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { icons } from 'assets'

const Modal = ({
  open,
  setOpen,
  title,
  actions,
  className = '',
  children,
  size = 'sm',
  fullWidth = false,
  fullScreen = false,
  onClose = () => {},
  showClose = true,
  center = false,
  sx = {},
  loading = false,
  dialogContentSx,
}: ModalProps) => {
  const classes = useStyles()
  const classNameHook = useClassName()

  const handleClose = () => {
    if (onClose) onClose()
    setOpen(false)
  }

  return (
    <Dialog
      classes={{
        container: classNameHook(
          fullWidth ? classes.fullWidth : '',
          fullScreen ? classes.fullScreen : ''
        ),
        paper: classNameHook(
          fullWidth ? classes.fullWidth : '',
          fullScreen ? classes.fullScreen : ''
        ),
      }}
      // sx={[...(Array.isArray(sx) ? sx : [sx]), { textAlign: center ? 'center' : 'left' }]}
      onClose={handleClose}
      open={open}
      className={`${classes.container} ${className}  ${classes[size]}`}
    >
      {(title || showClose) && (
        <DialogTitle>
          <Grid className={classes.header}>
            {typeof title === 'string' ? (
              <Typography variant="h5" className={classes.Modal__title}>
                {title}
              </Typography>
            ) : (
              title
            )}
            {showClose ? (
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                className={classes.close}
                onClick={handleClose}
              >
                <icons.Close />
              </IconButton>
            ) : null}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent style={{ display: 'grid', ...dialogContentSx }}>
        {!loading ? (
          children
        ) : (
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.container}>
          <Grid container spacing={1}>
            {actions.map((action, index) => {
              return (
                <Grid key={index} item xs={6}>
                  <Button
                    key={index}
                    variant={action.variant}
                    className={classes.button}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >
                    {action.loading ? (
                      <CircularProgress color="primary" size={'1.4rem'} />
                    ) : (
                      <>
                        {action.textButton}
                        {action.icon && action.icon}
                      </>
                    )}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
