import { FC } from 'react'
import { TablePagination, useTheme, IconButton } from '../../..'
import { PaginationProps, TablePaginationActionsProps } from './types'
import useStyles from './styles'
import { icons } from '../../../../../assets'

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <icons.LastPage /> : <icons.FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <icons.KeyboardArrowRight /> : <icons.KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <icons.KeyboardArrowLeft /> : <icons.KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <icons.FirstPage /> : <icons.LastPage />}
      </IconButton>
    </div>
  )
}

const Pagination: FC<PaginationProps> = ({
  count, colspan, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage
}) => {
  const classes = useStyles()

  return (
    <TablePagination
      rowsPerPageOptions={[5, 15, 25, 50, { label: 'All', value: 999999 }]}
      colSpan={colspan}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      className={classes.pagination}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  )
}

export default Pagination
