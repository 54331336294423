import { Job } from 'ducks/types'
import { jobsTypes, JobsActions } from './types'

const actions = {
  fetchJobs: (
    payload: {
      startDate?: number,
      endDate?: number,
      status?: string[],
      start?: number,
      limit?: number,
      sortBy?: string,
      sortOrder?: 'asc' | 'desc',
      search?: string
    },
    callback?: (succ: boolean) => void
  ): JobsActions => ({
    type: jobsTypes.FETCH_JOBS,
    payload,
    callback
  }),
  fetchJobsStats: (
    payload: {
      startDate?: number, endDate?: number,
      status?: string, start?: number,
      search?: string
    },
    callback?: (succ: boolean) => void
  ): JobsActions => ({
    type: jobsTypes.FETCH_JOBS_STATS,
    payload,
    callback
  }),
  setJobs: (payload: Job[]): JobsActions => ({
    type: jobsTypes.SET_JOBS,
    payload
  })
}

export default actions
