import { FC } from 'react'
import { Grid, Typography, Tag, Button, Progress } from '../../../../UI'
import { ItemProps } from './types'
import useStyles from './styles'
import { history, JOB_PAYMENT_STATUS, formatTimestamp, round } from '../../../../../helpers'
import { useClassName } from '../../../../../hooks'

const JobItem: FC<ItemProps> = ({ job }) => {
  const classes = useStyles()
  const className = useClassName()
  const pushForward = history.usePushForward()
  const { id, startedOn, completedOn, publicStatus,createdOn, percentItemsComplete, properties, paymentStatus } = job
  const { invoiceId, totalValue, address } = properties || {}
  const { line_1, city, state } = address || {}

  return (
    <Grid item className={classes.container} key={id} spacing={3}>
      <Grid item className={classes.cell} xs={12}>
        <Typography className={classes.title}>
          {`Ref: ${properties.publicId}`}
        </Typography>
      </Grid>
      <Grid item className={classes.cell} xs={12}>
        <Typography className={classes.title}>
          {line_1}
        </Typography>
        <Typography className={classes.description}>
          {`${city}, ${state}`}
        </Typography>
      </Grid>
      <Grid item className={`${classes.cell} ${classes.status}`}>
        <Tag type={publicStatus} variation='body1' />
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}> Progress: </Typography>
        <Progress className={className(classes.noMargin, classes.alignment)} value={percentItemsComplete} />
      </Grid>

      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Approved:
        </Typography>
        <Typography className={classes.mediumValue}>
          {formatTimestamp(createdOn, 'MM/DD/YYYY')}
        </Typography>
      </Grid>


      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Initiated:
        </Typography>
        <Typography className={classes.mediumValue}>
          {formatTimestamp(startedOn, 'MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Completed:
        </Typography>
        <Typography className={classes.mediumValue}>
          {formatTimestamp(completedOn, 'MM/DD/YYYY') === '' ? '-' : formatTimestamp(completedOn, 'MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Typography className={classes.medium}>
          Cost:
        </Typography>
        <Typography className={classes.mediumValue}>
          {`$${round(totalValue ?? 0, 2)}`}
        </Typography>
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Button
          type='submit'
          variant='contained'
          className={classes.buttonView}
          onClick={() => { pushForward(`${id}`) }}
        >
          View
        </Button>
      </Grid>
      <Grid item className={classes.itemContainer} xs={12}>
        <Button
          type='submit'
          variant='contained'
          className={`${classes.button} ${paymentStatus === JOB_PAYMENT_STATUS.PAID ? classes.buttonPaid : ''}`}
          onClick={() => { pushForward(`${id}/invoice/${invoiceId}`) }}
          disabled={invoiceId === null}
        >
          {paymentStatus === JOB_PAYMENT_STATUS.PAID ? 'View Invoice' : 'Pay'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default JobItem
