import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 20px'
      }
    },
    button: {
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      textTransform: 'none',
      fontFamily: 'LatoNormal'
    }
  })
)
