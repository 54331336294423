import { CONTACT_ROLE, USER_TYPE } from 'helpers/constants'
import { Estimate, EstimateContact, EstimateProperties } from '../types'

/** TYPES**/
export const approvalTypes = {
  CLEAN: 'CLEAN_APPROVAL',
  SET_STEP: 'SET_APPROVAL_STEP',
  SET_VALUE: 'SET_APPROVAL_VALUE',
  SET_VALUES: 'SET_APPROVAL_VALUES',
  APPROVE_ESTIMATE: 'APPROVE_ESTIMATE',
  CREATE_ESTIMATE_V2: 'CREATE_ESTIMATE_V2',
}

/** ACTIONS**/
export interface CleanApprovalAction {
  type: typeof approvalTypes.CLEAN
  payload: null
}

export interface ApprovalStepAction {
  type: typeof approvalTypes.SET_STEP
  payload: number
}

export interface ApprovalValue {
  attr: keyof ApprovalType
  value: any
}

export interface ApprovalValueAction {
  type: typeof approvalTypes.SET_VALUE
  payload: ApprovalValue
}

export interface ApprovalValues {
  attrs: Partial<ApprovalType>
}

export interface NewApprovalSetValuesAction {
  type: typeof approvalTypes.SET_VALUES
  payload: ApprovalValues
}

export type ApprovalActions =
  | CleanApprovalAction
  | ApprovalStepAction
  | ApprovalValueAction
  | NewApprovalSetValuesAction

/** REDUCER **/
export interface ApprovalType extends Partial<EstimateProperties> {
  consentListingAgreement?: boolean
  consentTerms?: boolean
  role?: CONTACT_ROLE | USER_TYPE
  // docuSigned?: boolean

  homeowner?: Partial<EstimateContact>
  secondHomeowner?: Partial<EstimateContact>
  listingAgent?: Partial<EstimateContact>
  
  homeownersEmailAlreadyExists?: boolean

  continueWithoutInfo?: boolean

  listingAgreement?: boolean
  isOverInstallmentThreshold?: boolean
  schedulerSelect?: 'me' | 'other' | 'homeowner' | null
  signOk?: boolean

  promoCode?: string
  propertyUnderContract?: boolean
  companyContact?: Partial<EstimateContact>
  scheduler?: Partial<EstimateContact>
  paymentOption?: 'pay_at_close' | 'pay_at_completion'
  agentIsHomeowner?: boolean
  paymentMethod?: 'ach' | 'credit_card'
  agentAsHomeownerRepresentative?: 'myself' | 'listing_agent' | 'no_selected' | null
}
