import { Box, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './styles'
import {
    onlineEstimates,
    peopleRushing,
    supportTeams
} from 'assets/index'
type Props = {}

const Improvements = (props: Props) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Goodbye Home Improvement Hassles</Typography>
            <Typography className={classes.subtitle}>No more wasted time or uncertainty! With Bosscat, you’ll get prompt pricing and guaranteed quality services for any project. It’s the one-stop solution trusted by over 100,000 homeowners and Realtors.</Typography>
            <Box className={classes.banners}>
                <Box className={classes.banner}>
                    <img src={onlineEstimates} alt="Online estimates" className={classes.bannerImg} />
                    <Typography className={classes.bannerTitle}>ONLINE ESTIMATES</Typography>
                    <Typography className={classes.bannerSubtitle}>Just upload your inspection report or repair list through our website or app.</Typography>
                </Box>
                <Box className={classes.banner}>
                    <img src={peopleRushing} alt="Online estimates" className={classes.bannerImg} />
                    <Typography className={classes.bannerTitle}>PROS FOR ANY PROJECT</Typography>
                    <Typography className={classes.bannerSubtitle}>Schedule your project online and our licensed and insured team will be ready.</Typography>
                </Box>
                <Box className={classes.banner}>
                    <img src={supportTeams} alt="Online estimates" className={classes.bannerImg} />
                    <Typography className={classes.bannerTitle}>PROJECT MANAGEMENT</Typography>
                    <Typography className={classes.bannerSubtitle}>No more coordinating with contractors! Your dedicated Success Manager will coordinate your project from start to finish.</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Improvements