import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2)
    },
    caption: {
      display: 'grid',
      padding: '8px 0 0 16px'
    },
    roleSelectorContainer: {
      margin: '8px 0px 16px'
    },
  })
)
