/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PrivateLoader } from '../../../../../../../templates'
import {
  Typography,
  Grid,
  TextFieldLabel,
  Button,
  Checkbox,
  FormControlLabel,
} from '../../../../../../../UI'
import {
  isEmpty,
  CONTACT_ROLE,
  validateEmail,
  PHONE_MASK_INPUT,
  history,
  PHONE_MASK_REGEXP,
  ESTIMATE_STATUS,
  USER_TYPE,
  APPROVAL_TYPE,
} from '../../../../../../../../helpers'
import {
  getEstimatesServices,
  getNewEstimateValue,
  getNewEstimateValues,
  getUser,
  getUserPrimaryEmail,
  isAuthorized,
} from '../../../../../../../../ducks/selectors'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import {
  estimateActions,
  newEstimateActions,
} from '../../../../../../../../ducks/actions'
import { icons, noContacts } from '../../../../../../../../assets'
import useStyles from './styles'
import { EstimateContact } from '../../../../../../../../ducks/types'
import { debounce } from 'lodash'

import ModalRemoveContact from './ModalRemoveContact'
import { NewEstimateType } from 'ducks/newEstimate/types'
import ContactCard from './ContactCard'
import ModalAddContact from './ModalAddContact'
import { UpdateRequest } from 'api/types'

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const authorized = useSelector(isAuthorized)

  const push = history.usePush()
  const dispatch = useDispatch()
  const { showWarnings, setCanGoForward, setShowWarnings, goBack, goForward } =
    useStepperContext()
  const { id: estimateId } = history.getParamValues()

  const clientType = useSelector(getNewEstimateValue('clientType'))

  const estimate = useSelector(getNewEstimateValue('estimate'))
  const mainContact = useSelector(getNewEstimateValue('mainContact'))
  const user = useSelector(getUser)
  // const userEmail = useSelector(getUserPrimaryEmail)
  const services = useSelector(getEstimatesServices)

  const isOther = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)

  const contacts = useSelector(getNewEstimateValue('contacts'))
  /*   const contactsFromEstimate = estimate?.properties?.contacts?.filter(
    (c: EstimateContact) => c.email !== userEmail
  ) */

  const contactsFromEstimate = estimate?.properties?.contacts?.filter(
    (c: EstimateContact) => !c.isRequester
  )

  const [refresh, setRefresh] = useState(false)
  const [consent, setConsent] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openAddContactModal, setOpenAddContactModal] = useState(false)
  const [indexContact, setIndexContact] = useState<number | null>(null)
  const pushParams = history.usePushParams()
  const contactRequiredFromEstimateIndex =
    estimate?.properties?.contacts?.findIndex(
      (contact: any) =>
        (contact.role === CONTACT_ROLE.HOMEOWNER ||
          contact.role === CONTACT_ROLE.HOME_BUYER) &&
        contact.approvalType === APPROVAL_TYPE.REQUIRED
    )

  const [contactToEdit, setContactToEdit] =
    useState<Partial<EstimateContact> | null>()

  const contactToEditInEstimateIndex =
    estimate?.properties?.contacts?.findIndex(
      (contact: EstimateContact) => contact.id === contactToEdit?.id
    )

  const {
    preferredCommunicationPhone,
    preferredCommunicationMail,
    preferredCommunicationText,
  } = useSelector(getNewEstimateValues())

  const [validate, setValidate] = useState(false)
  const showPaymentScreen = services?.length > 1 || services[0]?.cost > 0
  const isHomeOwnerRegistered =
    user.registrationComplete && clientType === USER_TYPE.HOMEOWNER
  const [newContacts, setNewContacts] = useState<any>(
    contactsFromEstimate || []
  )

  const firstHomeOwnerIndex = estimate?.properties?.contacts?.findIndex(
    (contact: EstimateContact) =>
      contact.role === CONTACT_ROLE.HOMEOWNER ||
      contact.role === CONTACT_ROLE.HOME_BUYER
  )

  const { saveNewValue } = useStepperContext()

  const isValid = () => {
    if (isOther) {
      return contactsFromEstimate?.length > 0
    } else if (isHomeOwnerRegistered) {
      return (
        consent &&
        (preferredCommunicationPhone ||
          preferredCommunicationMail ||
          preferredCommunicationText)
      )
    } else {
      return (
        !isEmpty(newContacts[0]?.firstName) &&
        !isEmpty(newContacts[0]?.lastName) &&
        !isEmpty(newContacts[0]?.email) &&
        validateEmail(newContacts[0]?.email || '') &&
        !isEmpty(newContacts[0]?.phone) &&
        PHONE_MASK_REGEXP.test(newContacts[0]?.phone || '') &&
        consent &&
        (preferredCommunicationPhone ||
          preferredCommunicationMail ||
          preferredCommunicationText)
      )
    }
  }

  const fetchEstimate = useCallback(
    () => {
      dispatch(
        estimateActions.fetchEstimate(
          estimate?.id,
          (succ: boolean, estimate) => {
            if (succ) {
            } else {
              push('p/estimates')
            }
          }
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, estimateId]
  )

  useEffect(() => {
    authorized && fetchEstimate()
  }, [fetchEstimate])

  const nexStep = (estimateId: string) => {
    // If we only have one service and costs $0 we avoid the calendar screen
    if (services?.length === 1 && services[0]?.cost === 0) {
      if (!isOther) {
        push(`estimate/${estimateId}`)
      } else {
        goForward()
      }
    } else {
      goForward()
    }
  }

  const updateEstimate = () => {
    if (validate) {
      setLoading(true)
      let contactValue = null

      if (isOther || !showPaymentScreen) {
        const contactRequiredFromEstimate =
          estimate?.properties.contacts?.findIndex(
            (contact: any) =>
              (contact.role === CONTACT_ROLE.HOMEOWNER ||
                contact.role === CONTACT_ROLE.HOME_BUYER) &&
              contact.approvalType === APPROVAL_TYPE.REQUIRED
          )
        if (isOther) {
          const payload: UpdateRequest = []

          if (contactRequiredFromEstimateIndex > -1) {
            payload.push({
              op: 'replace',
              path: `/properties/contacts/${contactRequiredFromEstimate}/approvalType`,
              value: APPROVAL_TYPE.NOT_NEEDED,
            })
          }
          if (firstHomeOwnerIndex !== -1) {
            // Make 1st homeowner REQUIRED and the rest NOT_NEEDED
            payload.push({
              op: 'replace',
              path: `/properties/contacts/${firstHomeOwnerIndex}/approvalType`,
              value: APPROVAL_TYPE.REQUIRED,
            })
          }
          if (payload.length) {
            dispatch(
              newEstimateActions.updateNewEstimateByPath(
                payload,
                (succ, estimateId) => {
                  if (succ && estimateId) {
                    if (!showPaymentScreen) {
                      setLoading(true)
                      nexStep(estimate.id)
                    } else {
                      setLoading(false)
                      nexStep(estimate.id)
                    }
                  }
                }
              )
            )
          } else {
            if (!showPaymentScreen) {
              setLoading(true)
              nexStep(estimate.id)
            } else {
              setLoading(false)
              nexStep(estimate.id)
            }
          }
        } else {
          if (!showPaymentScreen) {
            // update estimate with deliveryServiceId
            setLoading(true)
            const preferredCommunicationMethods = []
            if (preferredCommunicationPhone)
              preferredCommunicationMethods.push('PHONE')
            if (preferredCommunicationMail)
              preferredCommunicationMethods.push('EMAIL')
            if (preferredCommunicationText)
              preferredCommunicationMethods.push('TEXT')
            const contacts = estimate.properties.contacts
              ? estimate.properties.contacts
              : [{}]
            contactValue = [
              {
                ...contacts[0],
                firstName: newContacts[0].firstName,
                lastName: newContacts[0].lastName,
                email: newContacts[0].email,
                phone: newContacts[0].phone,
                preferredCommunicationMethods,
              },
            ]

            dispatch(
              newEstimateActions.replaceValueInEstimate(
                {
                  path: '/properties/contacts',
                  value: contactValue,
                },
                (success, estimateId) => {
                  if (success && estimateId) {
                    dispatch(
                      newEstimateActions.updateEstimate(
                        {
                          dueOn: services[0].date,
                          properties: { totalValue: services[0].cost },
                          deliveryServiceId: services[0].id,
                          status: ESTIMATE_STATUS.NEW,
                        },
                        (success, estimate) => {
                          if (success && estimate?.id) {
                            nexStep(estimate.id)
                          }
                          setLoading(false)
                        }
                      )
                    )
                  }
                }
              )
            )
          } else {
            setLoading(false)
            nexStep(estimate.id)
          }
        }
      } else {
        const preferredCommunicationMethods = []
        if (preferredCommunicationPhone)
          preferredCommunicationMethods.push('PHONE')
        if (preferredCommunicationMail)
          preferredCommunicationMethods.push('EMAIL')
        if (preferredCommunicationText)
          preferredCommunicationMethods.push('TEXT')
        const contacts = estimate.properties.contacts
          ? estimate.properties.contacts
          : [{}]
        contactValue = [
          {
            ...contacts[0],
            firstName: newContacts[0].firstName,
            lastName: newContacts[0].lastName,
            email: newContacts[0].email,
            phone: newContacts[0].phone,
            preferredCommunicationMethods,
          },
        ]

        dispatch(
          newEstimateActions.replaceValueInEstimate(
            {
              path: '/properties/contacts',
              value: contactValue,
            },
            (success, estimateId) => {
              setLoading(false)
              if (success && estimateId) {
                nexStep(estimateId)
              }
            }
          )
        )
      }
    } else setShowWarnings(true)
  }

  const handleSubmit = (newContact: Partial<EstimateContact>) => {
    setLoading(true)
    const newContactInEstimateIndex = estimate.properties.contacts.findIndex(
      (contact: EstimateContact) => contact.id === newContact.id
    )
    const newContactInEstimate = estimate.properties.contacts.find(
      (contact: EstimateContact) => contact.id === newContact.id
    )
    if (
      newContact.id &&
      newContactInEstimate &&
      newContactInEstimate?.email === newContact?.email
    ) {
      const payload: UpdateRequest = [
        {
          op: 'replace',
          path: `/properties/contacts/${newContactInEstimateIndex}/firstName`,
          value: newContact.firstName,
        },
        {
          op: 'replace',
          path: `/properties/contacts/${newContactInEstimateIndex}/lastName`,
          value: newContact.lastName,
        },
        {
          op: 'replace',
          path: `/properties/contacts/${newContactInEstimateIndex}/phone`,
          value: newContact.phone,
        },
        {
          op: 'replace',
          path: `/properties/contacts/${newContactInEstimateIndex}/role`,
          value: newContact.role,
        },
      ]
      if (newContact.role === CONTACT_ROLE.OTHER) {
        payload.push({
          op: 'replace',
          path: `/properties/contacts/${newContactInEstimateIndex}/approvalType`,
          value: APPROVAL_TYPE.NOT_NEEDED,
        })
      }
      dispatch(
        newEstimateActions.updateNewEstimateByPath(payload, (suc) => {
          setLoading(false)
        })
      )
    } else {
      const payload: UpdateRequest = []
      if (
        newContactInEstimate &&
        newContactInEstimate?.email !== newContact?.email
      ) {
        delete newContact.id
        delete newContact.contactId
        payload.push({
          op: 'remove',
          path: `/properties/contacts/${newContactInEstimateIndex}`,
        })
      }
      payload.push({
        op: 'add',
        path: '/properties/contacts/-',
        value: newContact,
      })
      dispatch(
        newEstimateActions.updateNewEstimateByPath(
          payload,
          (succ, estimate) => {
            setLoading(false)
            dispatch(
              newEstimateActions.setNewEstimateValue({
                attr: 'contacts',
                value: estimate?.properties.contacts || [],
              })
            )
          }
        )
      )
    }

    /*  const contactsToPatch = [...newContacts]
    if (contactToEdit) {
      contactsToPatch[indexContact || 0] = newContact
    } else {
      contactsToPatch.push(newContact)
    }
    setNewContacts(contactsToPatch)
    if (isOther) {
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'contacts',
          value: contactsToPatch,
        })
      )
    }
    setOpenAddContactModal(false) */
  }

  const handleChange = (
    newValue: string,
    index: number,
    attr: keyof EstimateContact
  ) => {
    const items = newContacts
    const item = { ...newContacts[index], [attr]: newValue }
    items[index] = item
    setNewContacts(items)
    setRefresh(!refresh)
    onItemValueChanged(items)
  }

  const changeItemValue = useCallback((contacts: any) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'contacts',
        value: contacts,
      })
    )
  }, [])

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 500),
    [changeItemValue]
  )

  const updateNewEstimate = useCallback(
    (attr: keyof NewEstimateType, value: any) => {
      saveNewValue(attr, value)
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr,
          value,
        })
      )
    },
    [saveNewValue]
  )

  /* 
  useEffect(() => {
    if (contactsFromEstimate) {
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'contacts',
          value: contactsFromEstimate,
        })
      )
    }
  }, []) */

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }, [validate, isValid])

  useEffect(() => {
    !isOther &&
      setNewContacts([
        ...newContacts,
        {
          firstName: mainContact.firstName,
          lastName: mainContact.lastName ?? '',
          email: mainContact.email,
          phone: '',
          sendEstimate: true,
        },
      ])
  }, [])

  const handleRemoveContact = (): void => {
    if (contactToEditInEstimateIndex > -1) {
      setLoading(true)
      dispatch(
        newEstimateActions.updateNewEstimateByPath(
          [
            {
              op: 'remove',
              path: `/properties/contacts/${contactToEditInEstimateIndex}`,
            },
          ],
          (succ, estimate) => {
            dispatch(
              newEstimateActions.setNewEstimateValue({
                attr: 'contacts',
                value: estimate?.properties.contacts || [],
              })
            )
            setLoading(false)
            succ && removeContactUi()
          }
        )
      )
    } else {
      removeContactUi()
    }
  }

  const removeContactUi = () => {
    const items = newContacts
    items.splice(indexContact as number, 1)
    setNewContacts(items)
    setRefresh(!refresh)
    onItemValueChanged(items)
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }

  const handleSelectContact = (contact: Partial<EstimateContact>): void => {
    const item = contact
    const indexToRemove = contactsFromEstimate.findIndex(
      (c: EstimateContact) => c.id === contact.id
    )
    setContactToEdit(item)
    setIndexContact(indexToRemove)
  }

  useEffect(() => {
    pushParams(`?${authorized ? 'p' : 'o'}=4`)
  }, [])

  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'activeStepProgress',
        value: 3,
      })
    )
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'totalStepProgress',
        value: 4,
      })
    )
  }, [])

  return (
    <PrivateLoader loading={loading} building="fragment">
      <>
        <Grid container item spacing={2} className={classes.root}>
          <Grid
            container
            item
            direction="column"
            xs={12}
            className={classes.container}
          >
            <Typography variant="h4" className={classes.title}>
              your clients’ information
            </Typography>

            <Grid
              item
              className={
                isOther ? classes.containerForm : classes.containerFormMax
              }
            >
              {!isOther && (
                <Typography variant="subtitle1" className={classes.text}>
                  Please provide your contact information so that we can send
                  the estimate to you and get in touch if we have any questions.
                </Typography>
              )}

              {isOther && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBlack}>
                    <span>
                      Please provide information about your client to ensure an
                      easy and seamless experience for your client.
                    </span>
                  </Typography>
                  <Typography variant="subtitle1" className={classes.text2}>
                    In order to protect your client’s data, your client will not
                    be able to view, edit, approve, or contact us about an
                    estimate unless they are listed in the estimate request. We
                    will not spam or sell/share any personal information.
                  </Typography>
                </Grid>
              )}

              {isOther && (
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.bold}>
                      Your Client's information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      className={classes.buttonAdd}
                      endIcon={<icons.Add />}
                      onClick={() => {
                        setContactToEdit(null)
                        setIndexContact(contacts?.length + 1 || 1)
                        setOpenAddContactModal(true)
                      }}
                    >
                      Add Contact
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item container spacing={2} xs={12}>
                {contactsFromEstimate &&
                  !isHomeOwnerRegistered &&
                  contactsFromEstimate?.length > 0 &&
                  contactsFromEstimate?.map((elem: any, index: number) =>
                    isOther ? (
                      <Grid item xs={12} lg={6}>
                        <ContactCard
                          handleChange={handleChange}
                          index={index}
                          contact={elem}
                          showWarnings={showWarnings}
                          key={index}
                          onEdit={() => {
                            handleSelectContact(elem)
                            setOpenAddContactModal(true)
                            /*  setContactToEdit(elem)
                          setIndexContact(index)
                          setOpenAddContactModal(true) */
                          }}
                          onDelete={() => {
                            handleSelectContact(elem)
                            setOpenDeleteModal(true)
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item container spacing={2}>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="First Name:"
                              type="text"
                              value={
                                contactsFromEstimate[index].firstName || ''
                              }
                              error={
                                showWarnings &&
                                isEmpty(contactsFromEstimate[index].firstName)
                              }
                              onChange={(event) =>
                                handleChange(
                                  event.target.value,
                                  index,
                                  'firstName'
                                )
                              }
                              placeholder="First Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="Last Name:"
                              type="text"
                              value={contactsFromEstimate[index].lastName || ''}
                              error={
                                showWarnings &&
                                isEmpty(contactsFromEstimate[index].lastName)
                              }
                              onChange={(event) =>
                                handleChange(
                                  event.target.value,
                                  index,
                                  'lastName'
                                )
                              }
                              placeholder="Last Name"
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          spacing={2}
                          style={{
                            marginTop: '0.8rem',
                            marginBottom: '0.8rem',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="Email:"
                              type="email"
                              value={contactsFromEstimate[index].email || ''}
                              error={
                                showWarnings &&
                                (isEmpty(contactsFromEstimate[index].email) ||
                                  !validateEmail(
                                    contactsFromEstimate[index].email || ''
                                  ))
                              }
                              onChange={(event) =>
                                handleChange(event.target.value, index, 'email')
                              }
                              placeholder="Email"
                              disabled={
                                !isOther &&
                                !isEmpty(contactsFromEstimate[index].email) &&
                                authorized
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="Mobile Phone Number:"
                              type="tel"
                              value={contactsFromEstimate[index].phone || ''}
                              mask={PHONE_MASK_INPUT}
                              error={
                                showWarnings &&
                                !contactsFromEstimate[index].phone
                              }
                              onChange={(event) =>
                                handleChange(event.target.value, index, 'phone')
                              }
                              placeholder="555-555-1234"
                            />
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                {contactsFromEstimate?.length <= 0 && isOther && (
                  <Grid item xs={12} className={classes.noContactsContainer}>
                    {/* <Box className={classes.noContactsImageRelative}> */}
                    {/*    <img src={noContactsArrow} alt="no contacts" className={classes.noContactsArrow} /> */}
                    <img
                      src={noContacts}
                      alt="no contacts"
                      className={classes.noContactsImage}
                    />
                    {/* </Box> */}
                    <Typography className={classes.bold}>
                      {mainContact?.firstName}!
                    </Typography>
                    <Typography className={classes.noContactsSubtitle}>
                      Let’s add your client's contact information
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {!isOther && !isHomeOwnerRegistered && (
                <Grid container item xs={12} className={classes.consent}>
                  <Checkbox
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                    name="consent"
                    color="primary"
                    style={{
                      color: showWarnings && !consent ? 'red' : 'primary',
                    }}
                  />
                  <Typography variant="body1" className={classes.checkConsent}>
                    I consent to receive SMS messages from BOSSCAT regarding my
                    estimate and related services.
                  </Typography>
                </Grid>
              )}

              {!isOther && (
                <Grid
                  item
                  xs={12}
                  className={`${
                    isHomeOwnerRegistered && classes.homeOwnerPreferredMethod
                  }`}
                >
                  <Typography variant="body1" className={`${classes.subtitle}`}>
                    What is your preferred method of communication? Check all
                    that apply.
                  </Typography>
                  <Grid item container xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationPhone}
                          onChange={() => {
                            updateNewEstimate(
                              'preferredCommunicationPhone',
                              !preferredCommunicationPhone
                            )
                          }}
                          name="preferredPhone"
                          color="primary"
                        />
                      }
                      label={<Typography variant="body2">Phone</Typography>}
                      className={classes.check}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationMail}
                          onChange={() => {
                            updateNewEstimate(
                              'preferredCommunicationMail',
                              !preferredCommunicationMail
                            )
                          }}
                          name="preferredMail"
                          color="primary"
                        />
                      }
                      label={<Typography variant="body2">Email</Typography>}
                      className={classes.check}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationText}
                          onChange={() => {
                            updateNewEstimate(
                              'preferredCommunicationText',
                              !preferredCommunicationText
                            )
                          }}
                          name="preferredText"
                          color="primary"
                        />
                      }
                      label={<Typography variant="body2">Text</Typography>}
                      className={classes.check}
                    />
                  </Grid>
                </Grid>
              )}
              {isHomeOwnerRegistered && (
                <Grid container item xs={12} className={classes.consent}>
                  <Checkbox
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                    name="consent"
                    color="primary"
                    style={{
                      color: showWarnings && !consent ? 'red' : 'primary',
                    }}
                  />
                  <Typography
                    variant="body1"
                    className={`${classes.checkConsent} ${classes.homeOwnerConsent}`}
                  >
                    I consent to receive SMS messages from BOSSCAT regarding my
                    estimate and related services.
                  </Typography>
                </Grid>
              )}
              <Grid
                className={classes.buttonsContainer}
                container
                item
                xs={12}
                style={{ display: 'flex', marginTop: '1rem', width: '100%' }}
                justifyContent="space-between"
              >
                <Button
                  type="submit"
                  variant="outlined"
                  size="small"
                  className={classes.buttonBack}
                  startIcon={<icons.ArrowBack />}
                  onClick={goBack}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className={classes.buttonNext}
                  endIcon={<icons.ArrowForward />}
                  disabled={!validate}
                  onClick={updateEstimate}
                >
                  {isOther ? 'Good to Go!' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openDeleteModal && (
          <ModalRemoveContact
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onRemoveContact={handleRemoveContact}
            contactToDelete={contactToEdit}
            index={indexContact || 0}
          />
        )}

        {openAddContactModal && (
          <ModalAddContact
            open={openAddContactModal}
            setOpen={setOpenAddContactModal}
            onAddContact={handleSubmit}
            contactToEdit={contactToEdit}
            index={indexContact}
            contactsEmails={estimate?.properties?.contacts?.map(
              (contact: Partial<EstimateContact>) => contact?.email
            )}
          />
        )}
      </>
    </PrivateLoader>
  )
}

export default Contact
