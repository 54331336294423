/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { itemsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'
import { FetchRecommendedItemsAction, itemsTypes } from './types'
import { getEstimate } from 'ducks/selectors'

export function* fetchRecommendedItems({ payload, callback }: FetchRecommendedItemsAction): SagaIterator {
  let success = false

  try {
    const { id } = yield select(getEstimate())

    const recommendedItems = yield call(Punchlist.items.getRecommendedItems, payload, id)

    if (recommendedItems) {
      yield put(itemsActions.setRecommendedItems(recommendedItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the recommended items'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(itemsTypes.FETCH_RECOMMENDED_ITEMS, fetchRecommendedItems)
}
