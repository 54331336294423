import { Box, Stepper } from 'components/UI'
import Step1Agent from './Step1/Step1'
import { APPROVAL_TYPE, CONTACT_ROLE, history } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalStep,
  getApprovalValues,
  getEstimate,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { approvalActions, configActions, estimateActions } from 'ducks/actions'
import { ApprovalValue } from 'ducks/approval/types'
import Step2Agent from './Step2/Step2'
import Step3Agent from './Step3/Step3'
import Step4Agent from './Step4/Step4'
import { StepType } from 'components/UI/CustomUI/organisms/Stepper/types'
import { useEffect } from 'react'
import ContactBanner from '../../components/ContactBanner'
import UnclickableStep from '../../components/UnclickeableStep'

const AgentStepper = () => {
  const dispatch = useDispatch()
  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)
  const activeStep = useSelector(getApprovalStep)
  const { paymentOption } = useSelector(getApprovalValues())
  const showBanner = !!estimate?.properties.contacts?.find(
    (contact) => contact.approvalFlowInProgress && contact.email !== userEmail
  )
  const steps: Array<StepType> = [
    {
      content: (
        <UnclickableStep>
          <Step1Agent />
        </UnclickableStep>
      ),
    },
    {
      content: <Step2Agent />,
      disableBack: true,
    },
  ]

  useEffect(() => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: false })
    )
  }, [])

  if (paymentOption === 'pay_at_close') {
    steps.push({
      content: <Step3Agent />,
      disableBack: true,
    })
    steps.push({
      content: <Step4Agent />,
      disableBack: true,
    })
  }

  return (
    <>
      <ContactBanner />
      <Stepper
        steps={steps}
        activeStep={showBanner ? 0 : activeStep}
        setActiveStepAction={(step: number) =>
          dispatch(approvalActions.setApprovalStep(step))
        }
        saveNewValueAction={(value: ApprovalValue) =>
          dispatch(approvalActions.setApprovalValue(value))
        }
        //className={classes.stepper}
      />
    </>
  )
}

export default AgentStepper
