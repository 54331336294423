import { Theme, createStyles, makeStyles } from '../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      gridTemplateColumns: 'min-content auto',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto'
      }
    }
  })
)
