/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { orderTypes, FetchOrderAction } from './types'
import { orderActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchOrder({ payload, callback }: FetchOrderAction): SagaIterator {
  let success = false
  try {
    const order = yield call(Punchlist.orders.getOrderById, payload)

    if (order) {
      yield put(orderActions.setOrder(order))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the order'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(orderTypes.FETCH_ORDER, fetchOrder)
}
