import { FC } from 'react'
import { SharedEstimateIndicatorProps } from './types'
import useStyles from './styles'
import { Typography, Box, SvgIcon } from '@material-ui/core'
import { icons } from '../../../../../../assets'


const SharedEstimateIndicator: FC<SharedEstimateIndicatorProps> = ({ sharedByEmail, sharedByName }) => {
  const styles = useStyles()
  return (
    <Box className={styles.container}>
      <Box className={styles.indicator}>
        <SvgIcon component={icons.AssignmentInd} className={styles.icon} />
        <Typography className={styles.name}>{sharedByName}</Typography>
        <Typography className={styles.text}>{`(${sharedByEmail}) shared this Estimate`}</Typography>
      </Box>
    </Box>
  )
}

export default SharedEstimateIndicator;

