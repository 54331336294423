import { EMAIL_TYPE, PAYMENT_METHOD_BRAND, PAYMENT_METHOD_TYPE, USER_TYPE } from 'helpers'
import { Region } from 'ducks/admin/types'
import { Address, Territory } from 'ducks/types'

/** TYPES **/
export const userTypes = {
  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  SET_VALUE: 'SET_USER_VALUE',
  SET_VALUES: 'SET_USER_VALUES',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  FETCH_USER_HEAD: 'FETCH_USER_HEAD'
}

/** ACTIONS **/
export interface FetchCurrentUserAction {
  type: typeof userTypes.FETCH_CURRENT_USER
  payload: null
  callback: (succ: boolean) => void
}

export interface CreateUserAction {
  type: typeof userTypes.CREATE_USER
  payload: any
  callback: (succ: boolean) => void
}

export interface UpdateUserAction {
  type: typeof userTypes.UPDATE_USER
  payload: Partial<UserType>
  callback: (succ: boolean) => void
}

export interface UpdateUserListPayload {
  attr: keyof UserType
  opp?: 'add' | 'removeAll' | 'replace'
  list?: any[]
  showError?: boolean
}

export interface UpdateUserListAction {
  type: typeof userTypes.UPDATE_USER_LIST
  payload: UpdateUserListPayload
  callback: (succ: boolean) => void
}

export interface FetchUserHeadAction {
  type: typeof userTypes.FETCH_USER_HEAD
  payload: FetchUserHeadParams
  callback?: (succ: boolean) => void
}

export interface FetchUserHeadParams {
  email: string,
  userType: string
}

export interface UserValue {
  attr: keyof UserType
  value: any
}

export interface UserSetValueAction {
  type: typeof userTypes.SET_VALUE
  payload: UserValue
}

export interface UserValues {
  attrs: Partial<UserType>
}

export interface UserSetValuesAction {
  type: typeof userTypes.SET_VALUES
  payload: UserValues
}

export type UserActions =
  | FetchCurrentUserAction
  | CreateUserAction
  | UpdateUserAction
  | UpdateUserListAction
  | UserSetValueAction
  | UserSetValuesAction
  | FetchUserHeadAction

/** REDUCER **/
export interface Email {
  emailType: EMAIL_TYPE
  email: string
}

export interface PaymentMethod {
  type: PAYMENT_METHOD_TYPE,
  brand: PAYMENT_METHOD_BRAND,
  expirationMonth: number,
  expirationYear: number,
  last4: number,
  stripeId: string,
  id?: string,
  createdOn: number,
  isDefault: boolean
}

export interface UserType {
  id: string
  email: Email[]

  firstName: string
  middleName: string
  lastName: string
  phone: number | null
  tosAccepted: boolean
  nar: boolean | null
  narId: string | null
  affiliate: string | null
  companyName: string
  address: Address
  picture: string
  protectPlanIsNew?: boolean | null

  registrationComplete: boolean
  type: string
  clientType: USER_TYPE
  paymentMethods: PaymentMethod[]
  customerId: string

  userProperties: Partial<UserReferrals>
  region?: Region

  territory?: Territory
  onProtectPlanNotificationList?: boolean
  institutionalRole?: string | null
  oneClickApproval?: boolean

}


export interface UserReferrals {
  source?: string
  campaign?: string
  content?: string
  medium?: string
}
