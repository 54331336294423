/* eslint-disable array-callback-return */
import { FC, useCallback, useEffect, useState } from 'react'
import env from '@beam-australia/react-env'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  DatePicker,
  Autocomplete,
  UploadDocuments,
  MultilineText
} from '../../../../../UI'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {
  formatTimestampDate,
  isEmpty,
  REPAIR_TIMELINE,
  FILE_TYPE,
  ParseFilesUrl
} from '../../../../../../helpers'
import useStyles from './styles'
import { getNewEstimateValues } from '../../../../../../ducks/selectors'
import { NewEstimateType } from '../../../../../../ducks/newEstimate/types'
import { filesActions } from '../../../../../../ducks/actions'
import moment from 'moment'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import { PunchlistFile } from '../../../../../../ducks/types'
import { useIsXsScreen } from '../../../../../../hooks'

const FILES_URL = env('FILES_URL') ?? ''

const repairTimes = [
  { key: REPAIR_TIMELINE.PRELISTING, value: 'Prelisting' },
  { key: REPAIR_TIMELINE.ASAP, value: 'ASAP' },
  { key: REPAIR_TIMELINE.BEFORE_CLOSE, value: 'Before Close' },
  { key: REPAIR_TIMELINE.AFTER_CLOSE, value: 'After Close' },
  { key: REPAIR_TIMELINE.NEVER, value: 'Never' }
]

const Assignment: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [uploadingFile, setUploadingFile] = useState<boolean>(false)
  const [xsScreen] = useIsXsScreen()
  const {
    showWarnings,
    saveNewValue,
    setCanGoForward,
    setShowWarnings
  } = useStepperContext()

  const {
    repairTime,
    clientNotes,
    closingDate,
    inspectionFiles,
    repairListFiles
  } = useSelector(getNewEstimateValues())


  const validate: boolean =
    closingDate !== 0 &&
    !isEmpty(repairTime) &&
    (inspectionFiles !== null || repairListFiles !== null)

  const updateNewEstimate = useCallback(
    (attr: keyof NewEstimateType, value: any) => {
      saveNewValue(attr, value)
    },
    [saveNewValue]
  )

  const handleSelect = (date: Date) => {
    updateNewEstimate('closingDate', moment(date).unix())
  }

  const handleInspectionChange = (
    files: null | File[],
    callback: () => void
  ) => {
    setUploadingFile(true)
    const oldFiles = inspectionFiles ? [...inspectionFiles] : []
    if (files) {
      dispatch(
        filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? ''
                  }),
                  description: `Estimate Inspection - ${index + 1}`,
                  fileType: FILE_TYPE.INSPECTION_REPORT
                }
              }
            })
            updateNewEstimate('inspectionFiles', [...oldFiles, ...newFiles])
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
      updateNewEstimate('inspectionFiles', [...oldFiles])
    }
    setUploadingFile(false)
  }

  const handleRepairListChange = (
    files: null | File[],
    callback: () => void
  ) => {
    setUploadingFile(true)
    const oldFiles = repairListFiles ? [...repairListFiles] : []
    if (files) {
      dispatch(
        filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? ''
                  }),
                  description: `Estimate RepairList - ${index + 1}`,
                  fileType: FILE_TYPE.INSPECTION_REPORT
                }
              }
            })
            updateNewEstimate('repairListFiles', [...oldFiles, ...newFiles])
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
      updateNewEstimate('repairListFiles', [...oldFiles])
    }
    setUploadingFile(false)
  }

  const handleDeleteInspectionFile = (newFiles: PunchlistFile[] | null) => {
    updateNewEstimate('inspectionFiles', newFiles)
  }

  const handleDeleteRepairFile = (newFiles: PunchlistFile[] | null) => {
    updateNewEstimate('repairListFiles', newFiles)
  }

  useEffect(() => {
    setCanGoForward(validate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate, inspectionFiles, repairListFiles])

  useEffect(() => {
    if (!closingDate)
      updateNewEstimate('closingDate', moment(Date.now()).unix())
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionFiles, repairListFiles])

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ paddingTop: '8px' }}
        className={classes.container}
      >
        <Grid container item xs={12} xl={6} spacing={1} style={{ marginRight: xsScreen ? 20 : 'inherit' }}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Upload Home Inspection</Typography>
            <Typography variant='caption' color='textSecondary'>
              A full home inspection is required for an estimate. If you have
              additional images, you can upload them as well.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'> Estimated closing date: </Typography>
            <DatePicker
              onChange={handleSelect}
              value={
                closingDate ? formatTimestampDate(closingDate) : new Date()
              }
              minDate={new Date()}
            />
            <Autocomplete
              titleClassName={classes.topSeparation}
              label='How soon do you need repairs completed?'
              value={
                repairTime
                  ? repairTimes.find(r => r.key === repairTime)
                  : undefined
              }
              options={repairTimes}
              error={showWarnings && !repairTime}
              placeholder='Please select...'
              onChange={(value: any) => {
                updateNewEstimate('repairTime', value.key)
              }}
            />
          </Grid>
        </Grid>
        {xsScreen &&
          <Grid container item xs={12} xl={6} spacing={2} style={{ marginRight: xsScreen ? 20 : 'inherit' }}>
            <Grid item xs={12}>
              <UploadDocuments
                error={showWarnings && (!inspectionFiles || !repairListFiles)}
                files={inspectionFiles}
                dropzoneText='Upload Inspection'
                onChange={!uploadingFile ? handleInspectionChange : () => { }}
                handleDelete={handleDeleteInspectionFile}
              />
            </Grid>
          </Grid>
        }
        <Grid container item xs={12} xl={6} spacing={2} style={{ marginRight: xsScreen ? 20 : 'inherit' }}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Repair List</Typography>
            <Typography variant='caption' color='textSecondary'>
              For more accurate pricing and faster turnaround, please provide us
              with a concise repair list.
            </Typography>
          </Grid>
          {!xsScreen &&
            <Grid item xs={12} style={{ height: '196px' }}>
              <Typography variant='body1'>
                Anything else we need to know before preparing your estimate?
              </Typography>
              <MultilineText
                value={clientNotes}
                onChange={(event: any) => {
                  updateNewEstimate('clientNotes', event.target.value)
                }}
                placeholder='Add repair items/notes here for our team to review'
              />
            </Grid>
          }
        </Grid>
        {!xsScreen &&
          <Grid container item xs={12} xl={6} spacing={2}>
            <Grid item xs={12}>
              <UploadDocuments
                error={showWarnings && (!inspectionFiles || !repairListFiles)}
                files={inspectionFiles}
                dropzoneText='Upload Inspection'
                onChange={!uploadingFile ? handleInspectionChange : () => { }}
                handleDelete={handleDeleteInspectionFile}
              />
            </Grid>
          </Grid>
        }
        <Grid container item xs={12} xl={6} spacing={2} style={{ marginRight: xsScreen ? 20 : 'inherit' }}>
          <Grid item xs={12}>
            <UploadDocuments
              error={showWarnings && (!inspectionFiles || !repairListFiles)}
              files={repairListFiles}
              dropzoneText='Upload Repair List'
              onChange={!uploadingFile ? handleRepairListChange : () => { }}
              handleDelete={handleDeleteRepairFile}
            />
          </Grid>
        </Grid>
        {xsScreen &&
          <Grid item xs={12} style={{ height: '196px', marginRight: xsScreen ? 20 : 'inherit' }} >
            <Typography variant='body1'>
              Anything else we need to know before preparing your estimate?
            </Typography>
            <MultilineText
              value={clientNotes}
              onChange={(event: any) => {
                updateNewEstimate('clientNotes', event.target.value)
              }}
              placeholder='Add repair items/notes here for our team to review'
              className={classes.multiline}
            />
          </Grid>
        }
      </Grid>
    </>
  )
}

export default Assignment
