import React from 'react'
import useStyles from '../styles'
import { bosscatHomeServicesLogo } from '../../../../../assets'

type Props = {}

const Header = (props: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.header}>
            <div className={`${classes.headerInfo} ${classes.maxWidth}`}>
                <img className={classes.headerLogo} src={bosscatHomeServicesLogo} alt="bosscat logo" />
            </div>
        </div>
    )
}

export default Header