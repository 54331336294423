import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.Mui-focused $notchedOutline': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&:hover $notchedOutline': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    focused: { borderRadius: 8, borderColor: 'var(--border-color)', borderWidth: '1px' },
    notchedOutline: { borderRadius: 8, borderColor: 'var(--border-color)', borderWidth: '2px' },
    input: {
      '&::placeholder': {
        color: 'var(--text-text-tertiary) !important',
        opacity: 1,
      },
      fontFamily: 'LatoLight',
      fontSize: '16px',
      color: 'var(--input-color)',
    }
  })
)
