import { Theme as ThemeMUI } from '@material-ui/core'
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    Question: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      lineHeight: '30px',
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    Answer: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      lineHeight: '30px',
      fontWeight: 400,
      wordBreak: 'break-word',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: "left"
      },
    },
    Extra: {
      fontSize: '16px',
      lineHeight: '28px',
      fontFamily: 'LatoNormal'
    }
  })
)
