import { FC } from 'react'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import useStyles from './styles'
import { InquireModalProps } from './types'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'

const InquireModal: FC<InquireModalProps> = ({ open, setOpen }) => {
  const styles = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} className={styles.modal} PaperProps={{ style: { padding: '8px' } }}>
      <DialogContent>
        <Typography align='center'>
          BOSSCAT Home Specialist will reach out to you soon to schedule your home renovation project
        </Typography>
      </DialogContent>
      <Button variant='contained' color='primary' style={{ marginTop: '16px' }} onClick={handleClose}>OK</Button>
    </Dialog>
  )
}

export default InquireModal
