import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      lineHeight: '24px',
      margin: '16px 9px 0px 0px'
    },
    comboSM: {
      width: '100%',
      height: '48px',
      marginTop: '4px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    comboUnit: {
      width: '100%',
      minHeight: '110px',
      marginTop: '2px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    submit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    icon: {
      width: '14px',
      height: '14px',
      alignSelf: 'center',
    },
    addressSearcherIcon: {
      color: theme.palette.grey[400],
      cursor: 'default'
    },
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      marign: '4px 0px !important',
      position: 'relative'
    },
    pointerIcon: {
      width: '48px',
      borderRadius: '100%',
      paddingRight: '8px'
    },
    mainDirection: {
      fontSize: '16px',
      fontFamily: 'Lato',
      paddingRight: '4px',
      width: '90%'
    }
  })
)
