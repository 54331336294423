import { FC, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  Grid,
  SelectAddress,
  TextFieldLabel,
  Typography,
} from '../../../../../UI'
import { AddHomeCardModalProps } from './types'
import PlaceIcon from '@material-ui/icons/Place'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'

import useStyles from './styles'
import { Address } from 'ducks/types'
import { useDispatch } from 'react-redux'
import { propertiesActions } from 'ducks/actions'

const AddHomeCardModal: FC<AddHomeCardModalProps> = ({ open, setOpen, setShowDuplicatedModal }) => {
  const dispatch = useDispatch()

  const [address, setAddress] = useState<Address>()
  const [loading, setLoading] = useState<boolean>(false)
  const [unitNumber, setUnitNumber] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  const styles = useStyles()

  const handleClose = () => {
    setUnitNumber('')
    setOpen(false)
  }

  const handleAddressChange = (newAddress: Address) => {
    setAddress(newAddress)
  }

  const handleAddProperty = () => {
    if (!address?.line_1 || !address.fullAddress) {
      setShowError(true)
      return
    }

    setShowError(false)

    setLoading(true)
    dispatch(
      propertiesActions.createProperty(
        { address: { ...address, line_2: unitNumber } as Address },
        (succ: boolean, isDuplicated?: boolean) => {
          dispatch(propertiesActions.fetchProperties({}))
          setLoading(false)
          handleClose()
          if (isDuplicated) {
            setShowDuplicatedModal(true)
          }
        }
      )
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      PaperProps={{ style: { borderRadius: '16px' } }}
      fullWidth
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px', gap: '16px' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            style={{ width: '100%', gap: '4px' }}
            alignItems="center"
          >
            <PlaceIcon style={{ width: '20px', height: '20px' }} />
            <Typography className={styles.addHomeCardHeader}>
              PROPERTY ADDRESS
            </Typography>
          </Box>
          <Button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon style={{ width: '14px', height: '14px' }} />
          </Button>
        </Box>
        {/* <SearchInput
          value=""
          onChange={() => {}}
          placeholder="Write property address here..."
        /> */}
        <SelectAddress
          showWarnings={false}
          showUnit={false}
          showMap={false}
          xl={12}
          title={<></>}
          onChange={handleAddressChange}
          placeholder="Address"
        />
        {showError && (
          <Typography
            className={styles.latoSmFont}
            style={{ color: 'var(--error-color)' }}
          >
            Please input address.
          </Typography>
        )}
        <TextFieldLabel
          value={unitNumber}
          onChange={(e) => setUnitNumber(e.target.value)}
          placeholder="Unit Number (optional)"
          InputProps={{ style: { paddingLeft: '8px' } }}
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<SaveIcon style={{ width: '16px', height: '16px' }} />}
          onClick={handleAddProperty}
          disabled={loading}
        >
          Add Property
        </Button>
        <Typography className={styles.desc} style={{ textAlign: 'center' }}>
          This new property will be added to your <br />{' '}
          <b>HomeBase portfolio view</b>
        </Typography>
      </Grid>
    </Dialog>
  )
}

export default AddHomeCardModal
