import { Address, EstimateContact, Subscription } from 'ducks/types'
import { SubscriptionActions, subscriptionTypes, UpdateSubscriptionParams } from './types'

type NotificationListContact = Partial<EstimateContact> & NotificationListContactExtends
interface NotificationListContactExtends {
  userProperties: {
    source: string | null;
    medium: string | null;
    campaign: string | null;
    content: string | null;
  }
  address: Address
}

const actions = {
  fetchSubscription: (
    payload: string,
    callback?: (succ: boolean) => void
  ): SubscriptionActions => ({
    type: subscriptionTypes.FETCH_SUBSCRIPTION,
    payload,
    callback
  }),
  setSubscription: (payload: Subscription): SubscriptionActions => ({
    type: subscriptionTypes.SET_SUBSCRIPTION,
    payload
  }),
  postSubscription: (payload: Partial<Subscription>, callback: (succ: boolean, clientSecret: string | null) => void): SubscriptionActions => ({
    type: subscriptionTypes.CREATE_SUBSCRIPTION,
    payload,
    callback
  }),
  addSubscriptionToNotificationList: (callback: (succ: boolean) => void, payload?: NotificationListContact,
  ): SubscriptionActions => ({
    type: subscriptionTypes.ADD_SUBSCRIPTION_TO_NOTIFICATION_LIST,
    payload,
    callback
  }),
  createSubscription: (
    payload: Partial<Subscription>,
    callback?: (succ: boolean, clientSecret: string | null) => void
  ): SubscriptionActions => ({
    type: subscriptionTypes.CREATE_SUBSCRIPTION,
    payload,
    callback
  }),
  clearSubscription: (): SubscriptionActions => ({
    type: subscriptionTypes.CLEAR_SUBSCRIPTION,
    payload: undefined
  }),
  updateSubscription: (
    payload: UpdateSubscriptionParams,
    callback?: (succ: boolean, subscription?: Subscription) => void
  ): SubscriptionActions => ({
    type: subscriptionTypes.UPDATE_SUBSCRIPTION,
    payload,
    callback
  }),
}

export default actions
