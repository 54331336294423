import { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useStyles from '../../../styles'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import EcoIcon from '@material-ui/icons/Eco'
import CheckIcon from '@material-ui/icons/Check'
import { Table } from 'components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyDetail, getPropertyInquiredItems, getTerritory } from 'ducks/selectors'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import { ConditionType } from 'ducks/properties/types'
import InquireModal from '../../../Modals/InquireModal'
import { useIsXlScreen } from 'hooks/useMediaQuery'
import ConditionCostByRoomRow from '../../../RowComponents/ConditionCostByRoomRow'
import ImageTooltip from 'components/UI/CustomUI/molecules/ImageTooltip'
import { questionMark } from 'assets/index'

const ConditionCostByRoom = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  // const [inquiredItems, setInquiredItems] = useState<string[]>([])

  const inquiredItems = useSelector(getPropertyInquiredItems())

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const [xlScreen] = useIsXlScreen()

  const property = useSelector(getPropertyDetail())
  const territory = useSelector(getTerritory())
  const {
    details: { roomCosts },
  } = property

  const handleInquire = (condition: ConditionType) => {
    dispatch(propertiesActions.requestPropertyRoom({ propertyId: property?.id, request: condition }, () => {

    }))
    dispatch(propertyDetailActions.setPropertyInquiredItems([...inquiredItems, condition.room]))
    // setInquiredItems([...inquiredItems, condition.room])
  }

  return (
    <Grid
      item
      container
      direction="column"
      style={{ marginTop: '24px' }}
      spacing={2}
    >
      {/* <Grid item container>
        <Box
          className={styles.containerBox}
          padding="8px"
          width="100%"
          bgcolor="var(--bosscat-green-100)"
          display="flex"
          flexDirection="column"
          style={{ gap: '8px' }}
        >
          <Box display="flex" color="var(--bosscat-green-600)">
            <EcoIcon style={{ width: '24px', height: '24px' }} />
            <Typography className={styles.lgFont}>
              = Green Rebate and Tax Incentives
            </Typography>
          </Box>
          <Typography className={styles.title}>
            Items with this indicator means you’ll have incentives and/or green
            rebates.
          </Typography>
        </Box>
      </Grid> */}
      <Grid item container>
        {xlScreen ? (
          <Box className={styles.containerBox} width="100%">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.cellHead} align='center' style={{ borderTopLeftRadius: '8px' }}>Room Type</TableCell>
                <TableCell className={styles.cellHead} align='center'>
                  <Box display='flex' alignItems='center' width='100%' justifyContent='center' style={{ gap: '8px' }}>
                    Project Count
                    <ImageTooltip icon={<img src={questionMark} alt='question' />} description="Number of renovation / repair projects per room type" />
                  </Box>
                </TableCell>
                <TableCell
                  className={styles.cellHead}
                  colSpan={3}
                  style={{ padding: 0 }}
                >
                  <TableCell
                    className={styles.rowCellHead}
                    style={{ width: '100%' }}
                  >
                    Room Type Cost
                  </TableCell>
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <TableCell
                      className={styles.rowCellHead}
                      style={{ flexGrow: 1 }}
                    >
                      <Box
                        className={styles.costTypeOptionContainer}
                        color="var(--bosscat-blue-600)"
                      >
                        <Typography
                          className={styles.lgFont}
                          style={{ lineHeight: '28px' }}
                        >
                          Good
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      className={styles.rowCellHead}
                      style={{ flexGrow: 1 }}
                    >
                      <Box
                        className={styles.costTypeOptionContainer}
                        color="var(--bosscat-green-600)"
                      >
                        <Typography
                          className={styles.lgFont}
                          style={{ lineHeight: '28px' }}
                        >
                          Better
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      className={styles.rowCellHead}
                      style={{ flexGrow: 1 }}
                    >
                      <Box
                        className={styles.costTypeOptionContainer}
                        color="var(--repairs-yellow-600)"
                      >
                        <Typography
                          className={styles.lgFont}
                          style={{ lineHeight: '28px' }}
                        >
                          Best
                        </Typography>
                      </Box>
                    </TableCell>
                  </Box>
                </TableCell>
                {territory?.serviceable && (
                  <TableCell
                    className={styles.cellHead}
                    style={{ textAlign: 'center', width: '150px', borderTopRightRadius: '8px' }}
                  >
                    Consultation Request
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {roomCosts?.rooms?.map((condition, index) => (
                <TableRow>
                  <TableCell
                    className={styles.cell}
                    style={{ fontWeight: 700 }}
                  >
                    <Box className={styles.iconLabel}>
                      {condition.room}
                      {/* {condition.hasIcon && <img src={greenLeap} alt='green leap' />} */}
                    </Box>
                  </TableCell>
                  <TableCell className={styles.cell} align='center'>
                    {condition.projectCount}
                  </TableCell>
                  <TableCell
                    className={styles.cell}
                    align='center'
                  >{`$${condition.costGood?.toLocaleString()}`}</TableCell>
                  <TableCell
                    className={styles.cell}
                    align='center'
                  >{`$${condition.costBetter?.toLocaleString()}`}</TableCell>
                  <TableCell
                    className={styles.cell}
                    align='center'
                  >{`$${condition.costBest?.toLocaleString()}`}</TableCell>
                  {territory?.serviceable && (
                    <TableCell className={styles.cell}>
                      <Button
                        startIcon={
                          inquiredItems.includes(condition.room) ? (
                            <CheckIcon className={styles.buttonIcon} />
                          ) : (
                            <></>
                          )
                        }
                        variant="contained"
                        className={
                          inquiredItems.includes(condition.room)
                            ? styles.inquiredButton
                            : styles.inquireButton
                        }
                        style={{
                          height: '44px',
                        }}
                        fullWidth
                        onClick={() => {
                          if (!inquiredItems.includes(condition.room)) {
                            setModalOpen(true)
                            handleInquire(condition)
                          }
                        }}
                      >
                        <Typography
                          className={styles.lgFont}
                          style={{
                            textTransform: 'none',
                          }}
                        >
                          {inquiredItems.includes(condition.room)
                            ? ''
                            : 'Inquire'}
                        </Typography>
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              <TableRow>
                <TableCell className={styles.cellHead} style={{ borderBottomLeftRadius: '8px' }}>Total</TableCell>
                <TableCell className={styles.cellHead}></TableCell>
                <TableCell className={styles.cellHead} align='center'>
                  {`$${roomCosts?.totalCostGood?.toLocaleString()}`}
                </TableCell>
                <TableCell className={styles.cellHead} align='center'>
                  {`$${roomCosts?.totalCostBetter?.toLocaleString()}`}
                </TableCell>
                <TableCell className={styles.cellHead} align='center'>
                  {`$${roomCosts?.totalCostBest?.toLocaleString()}`}
                </TableCell>
                {territory?.serviceable && (
                  <TableCell className={styles.cellHead} style={{ borderBottomRightRadius: '8px' }}></TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        ) : (
          <Grid container item direction="column" style={{ gap: '16px' }}>
            {roomCosts?.rooms?.map((conditon) => (
              <ConditionCostByRoomRow
                condition={conditon}
                setModalOpen={setModalOpen}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <InquireModal open={modalOpen} setOpen={setModalOpen} />
    </Grid>
  )
}

export default ConditionCostByRoom
