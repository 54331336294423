import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '12px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left'
      }
    },
    title1: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      fontSize: '24px',
      margin: '5px 8px 5px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 4px 5px 0px',
        fontSize: '18px',
        minWidth: '90px'
      }
    },
    title2: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '24px',
      margin: '5px 0px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        minWidth: '230px'
      }
    },
    noServiceable: {
      display: 'grid',
      gap: '6px',
      padding: '16px 6%',
      color: 'rgb(104, 35, 38)',
      background: 'rgb(242, 216, 218)',
      marginTop: '16px',
      borderRadius: '6px',
      textAlign: 'center'
    },
    textAddress: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '10px 5px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2rem'
      }
    },
    estimate: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      margin: '15px 0px 0px 0px',
      '& > span': {
        paddingRight: '6px'
      }
    },
    estimates: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      margin: '0px 0px 10px 0px'
    }
  })
)
