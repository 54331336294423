import React, { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configActions } from '../../../../ducks/actions'
import {
  getError,
  getNewEstimateData,
  getNewEstimateValue,
  getUserId,
  getUserPrimaryEmail,
} from '../../../../ducks/selectors'
import ErrorPage from '../../Pages/ErrorPage'
import InitializeGoogleAnalytics from 'helpers/google-analytics'
import { hotjar } from 'react-hotjar'
import env from '@beam-australia/react-env'

const HOTJAR_ID = env('HOTJAR_ID') ?? ''

const MainWrapper: FC = ({ children }) => {
  const dispatch = useDispatch()
  const error = useSelector(getError())
  const userId = useSelector(getUserId)
  const userEmail = useSelector(getUserPrimaryEmail)
  const newEstimateEmail = useSelector(
    getNewEstimateValue('mainContact')
  )?.email

  const email =
    userEmail !== 'No email registered' ? userEmail : newEstimateEmail

  useEffect(() => {
    if (!navigator.onLine) {
      dispatch(configActions.setError({ show: true, type: 'no_internet' }))
    } else {
      dispatch(configActions.setError({ show: false, type: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    InitializeGoogleAnalytics()
  }, [])

  useEffect(() => {
    if(HOTJAR_ID === '') return
    hotjar.initialize({
      id: Number(HOTJAR_ID),
      sv: 6,
    })

    if (hotjar.initialized()) {
      hotjar.identify(userId, {
        email: email,
      })
    }
  }, [userId])

  window.addEventListener('online', () => {
    dispatch(configActions.setError({ show: false, type: '' }))
  })

  window.addEventListener('offline', () => {
    dispatch(configActions.setError({ show: true, type: 'no_internet' }))
  })

  return <main>{error.show ? <ErrorPage type={error.type} /> : children}</main>
}

export default MainWrapper
