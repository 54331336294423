import env from '@beam-australia/react-env'
import { EMAIL_TYPE } from "helpers"
import { USER_TYPE } from "helpers"

export const FILES_URL = env('FILES_URL') ?? ''
export const fullAddress = "18243 Wellington Ln, Rowland Heights, CA 91748, USA";
export const firstNamePlaceholder = "Enter your name"
export const lastNamePlaceholder = "Enter your last name";
export const phonePlaceholder = "123-123-1234";

export const outputValues = [
  '18243 Wellington Ln',
  'Rowland Heights',
  'CA - California',
  91748,
  'United States',
]

export const personDefaultState = {
  user: {
    id: "635c4d6753504d5f1be00e11",
    email: [
        {
            id: null,
            emailType: "PRIMARY",
            email: "ryan.test1@test.com",
            name: null,
            description: null
        }
    ],
    firstName: "",
    middleName: "",
    lastName: "",
    phone: null,
    tosAccepted: false,
    nar: null,
    narId: "",
    affiliate: null,
    companyName: "",
    address: {
        city: "",
        state: "",
        line_1: "",
        line_2: "",
        zipCode: "",
        latitude: 0,
        longitude: 0,
        country: "",
        county: ""
    },
    picture: "4dcbc05f-fab2-4ce5-9557-5db5ecbefb8b.png",
    registrationComplete: false,
    type: "Client",
    clientType: "HOMEOWNER",
    paymentMethods: [],
    customerId: "cus_MhPAuCiIDvschq",
    userProperties: {}
  }
}


export const personInputValues = [
  {
    placeholder: "Enter your name",
    value: personDefaultState.user.firstName
  },
  {
    placeholder: "Enter your last name",
    value: personDefaultState.user.lastName
  },
  {
    placeholder: "123-123-1234",
    value: personDefaultState.user.phone,
  },
  {
    placeholder: "valid@email.com",
    value: personDefaultState.user.email.find((e) => e.emailType === EMAIL_TYPE.PRIMARY)?.email ?? ''
  },
]

export const emptyNewEstimate = {
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
  }
}

export const existNewEstimate = {
  address: {
    street: '8392 Thomas Drive',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90022',
    latitude: 34.052235,
    longitude: -118.243683,
  }
}

export const emptyUser = {
  address: {
    city: "",
    state: "",
    line_1: "",
    line_2: "",
    zipCode: "",
    latitude: 0,
    longitude: 0,
    country: "",
    county: ""
  },
  clientType: USER_TYPE.HOMEOWNER
}

export const existUser = {
  address: {
    city: "Rowland Heights",
    state: "CA",
    line_1: "18243 Wellington Ln",
    fullAddress: "18243 Wellington Ln",
    line_2: "",
    zipCode: "91748",
    latitude: 0,
    longitude: 0,
    country: "United States",
    county: ""
  },
  email: [
    {
        id: null,
        emailType: "PRIMARY",
        email: "ryan.test1@test.com",
        name: null,
        description: null
    }
  ],
  firstName: "A",
  middleName: "B",
  lastName: "C",
  phone: 4444444444,
  tosAccepted: true,
  nar: null,
  narId: "",
  clientType: USER_TYPE.HOMEOWNER
}

export const locationInputValues = [
  {
    title: "Address:",
    placeholder: "Enter your address",
  },
  {
    title: "City:",
    placeholder: "Enter your city",
  },
  {
    title: "State:",
    placeholder: "Select your state",
  },
  {
    title: "ZIP Code:",
    placeholder: "Enter your ZIP code",
  },
  {
    title: "Country:",
    placeholder: "Select your country",
  },
]

export const predictLocation = [
  {
    "description": "18243 Wellington Ln, Rowland Heights, CA 91748, USA",
    "matched_substrings": [
      {
        "length": 5,
        "offset": 0
      },
      {
        "length": 13,
        "offset": 6
      },
      {
        "length": 15,
        "offset": 21
      },
      {
        "length": 2,
        "offset": 38
      },
      {
        "length": 5,
        "offset": 41
      },
      {
        "length": 3,
        "offset": 48
      }
    ],
    "place_id": "ChIJnVsozqQqw4AR00uKH5AT4sA",
    "reference": "ChIJnVsozqQqw4AR00uKH5AT4sA",
    "structured_formatting": {
      "main_text": "18243 Wellington Ln",
      "main_text_matched_substrings": [
        {
          "length": 5,
          "offset": 0
        },
        {
          "length": 13,
          "offset": 6
        }
      ],
      "secondary_text": "Rowland Heights, CA 91748, USA",
      "secondary_text_matched_substrings": [
        {
          "length": 15,
          "offset": 0
        },
        {
          "length": 2,
          "offset": 17
        },
        {
          "length": 5,
          "offset": 20
        },
        {
          "length": 3,
          "offset": 27
        }
      ]
    },
    "terms": [
      {
          "offset": 0,
          "value": "18243"
      },
      {
          "offset": 6,
          "value": "Wellington Ln"
      },
      {
          "offset": 21,
          "value": "Rowland Heights"
      },
      {
          "offset": 38,
          "value": "CA"
      },
      {
          "offset": 41,
          "value": "91748"
      },
      {
          "offset": 48,
          "value": "USA"
      }
    ],
    "types": [
        "premise",
        "geocode"
    ]
  }
]

export const detailLocation = {
  "address_components": [
      {
        "long_name": "18243",
        "short_name": "18243",
        "types": [
          "street_number"
        ]
      },
      {
        "long_name": "Wellington Lane",
        "short_name": "Wellington Ln",
        "types": [
          "route"
        ]
      },
      {
        "long_name": "Rowland Heights",
        "short_name": "Rowland Heights",
        "types": [
          "locality",
          "political"
        ]
      },
      {
        "long_name": "Los Angeles County",
        "short_name": "Los Angeles County",
        "types": [
          "administrative_area_level_2",
          "political"
        ]
      },
      {
        "long_name": "California",
        "short_name": "CA",
        "types": [
          "administrative_area_level_1",
          "political"
        ]
      },
      {
        "long_name": "United States",
        "short_name": "US",
        "types": [
          "country",
          "political"
        ]
      },
      {
        "long_name": "91748",
        "short_name": "91748",
        "types": [
          "postal_code"
        ]
      },
    {
      "long_name": "5125",
      "short_name": "5125",
      "types": [
        "postal_code_suffix"
      ]
    }
  ],
  "adr_address": "<span class=\"street-address\">18243 Wellington Ln</span>, <span class=\"locality\">Rowland Heights</span>, <span class=\"region\">CA</span> <span class=\"postal-code\">91748-5125</span>, <span class=\"country-name\">USA</span>",
  "formatted_address": "18243 Wellington Ln, Rowland Heights, CA 91748, USA",
  "geometry": {
    "location": {
      "lat": () => 33.9578316,
      "lng": () => -117.9137095
    },
    "viewport": {
      "south": 33.9563616697085,
      "west": -117.9150751802915,
      "north": 33.9590596302915,
      "east": -117.9123772197085
    }
  },
  "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
  "icon_background_color": "#7B9EB0",
  "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
  "name": "18243 Wellington Ln",
  "place_id": "ChIJnVsozqQqw4AR00uKH5AT4sA",
  "reference": "ChIJnVsozqQqw4AR00uKH5AT4sA",
  "types": [
      "premise"
  ],
  "url": "https://maps.google.com/?q=18243+Wellington+Ln,+Rowland+Heights,+CA+91748,+USA&ftid=0x80c32aa4ce285b9d:0xc0e213901f8a4bd3",
  "utc_offset": -420,
  "vicinity": "Rowland Heights",
  "html_attributions": [],
  "utc_offset_minutes": -420
}