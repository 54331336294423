import { createContext, useContext } from 'react'

const stepperDefaultValue = {
    goBack: () => { },
    goForward: () => { },
    setCanGoForward: (_canGoForward: boolean) => { },
    showWarnings: false,
    setShowWarnings: (_showWarnings: boolean) => { },
    saveNewValue: (_attr: any, _value: any) => { }
}

export const StepperContext = createContext(stepperDefaultValue)

export const useStepperContext = () => useContext(StepperContext)
