import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '12px',
      [theme.breakpoints.down('xl')]: {
        gap: '16px'
      },
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    forward: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    back: {
      width: '220px',
      height: '56px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      borderRadius: 8,
      margin: '5px',
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    icon: {
      width: '15px',
      height: '15px'
    }
  })
)
