import { authTypes } from 'ducks/auth/types'
import { Item } from 'ducks/types'
import { EstimateItemValues, EstimateItemsActions, estimateItemsTypes, EstimateItemsType } from './types'

const initialState: EstimateItemsType = []

const reducer = (state = initialState, action: EstimateItemsActions): EstimateItemsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case estimateItemsTypes.SET_ESTIMATE_ITEMS: {
      return payload as Item[]
    }

    case estimateItemsTypes.SET_ESTIMATE_ITEM_VALUES: {
      const { itemId, attrs } = payload as EstimateItemValues

      const itemIndex = state.findIndex((item: Item) => {
        return item.itemId === itemId
      })

      if (itemIndex !== -1) {
        const itemCopy = state[itemIndex]
        const itemsCopy = [...state]
        itemsCopy[itemIndex] = { ...itemCopy, ...attrs }
        return itemsCopy
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
