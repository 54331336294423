/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newInspectionActions } from '../../../../ducks/actions'
import { NewInspectionValue } from '../../../../ducks/newInspection/types'
import {
  getNewInspectionStep,
  getNewInspectionValue,
  getUser,
  isAuthorized
} from '../../../../ducks/selectors'
import { Box, Stepper, Typography } from '../../../UI'
import { StepperProps } from '../../../UI/CustomUI/organisms/Stepper/types'
import { HashWrapper, PrivateLoader } from '../../../templates'
import useStyles from './styles'

import { BOSSCAT_PHONE_NUMBER, CONTACT_ROLE, USER_TYPE } from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'
import Account from './Account'
import Project from './Steps/Assignment/Inspection/Project'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const Inspection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()

  const authorized = useSelector(isAuthorized)
  const activeStep = useSelector(getNewInspectionStep())
  const [pageLoading, setPageLoading] = useState(true)
  const user = useSelector(getUser)
  const clientType = useSelector(getNewInspectionValue('clientType'))
  const activeStepProgress = useSelector(getNewInspectionValue('activeStepProgress'))
  const totalStepProgress = useSelector(getNewInspectionValue('totalStepProgress'))


  const affiliate = user?.affiliate || (new URLSearchParams(window.location.search).get('affiliate') ?? '')

  useEffect(() => {
    setPageLoading(false)
  }, [activeStep])


  useEffect(() => {
    dispatch(
      newInspectionActions.setNewInspectionValues({
        attrs: {
          affiliate: (clientType === CONTACT_ROLE.LISTING_AGENT) ? affiliate : '',
        }
      })
    )

    if (authorized) {
      dispatch(
        newInspectionActions.setNewInspectionValues({
          attrs: {
            clientType: user.clientType === USER_TYPE.HOMEOWNER
              ? user.clientType
              : CONTACT_ROLE.LISTING_AGENT,
            affiliate,
          }
        })
      )
    }
  }, [user, affiliate, clientType])

  const steps: StepperProps['steps'] = [
    {
      content: <Project />,

    },

  ]



  return (
    <HashWrapper>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <div style={{ marginTop: "24px" }}>
            <HolidayBanner />
          </div>
          {activeStep === -1
            ? (
              <Account />
            )
            : (
              <PrivateLoader loading={pageLoading}>
                <Stepper
                  withLogo={!authorized}
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStepAction={(step: number) =>
                    dispatch(newInspectionActions.setNewInspectionStep(step))}
                  saveNewValueAction={(value: NewInspectionValue) =>
                    dispatch(newInspectionActions.setNewInspectionValue(value))}
                  fixButtons
                  showProgress
                  activeStepProgress={activeStepProgress}
                  totalStepProgress={totalStepProgress}
                  showHeader={false}
                  className={classes.stepper}
                />
              </PrivateLoader>
            )}
        </Box>
        {!xsScreen && <Box className={classes.footer}>
          <Box className={classes.footerContent}>
            <Typography variant='body2'>
              Questions? Speak with a live Customer Care Professional
            </Typography>
            <Typography variant='body2'>
              <a href={`tel:${BOSSCAT_PHONE_NUMBER}`}>{BOSSCAT_PHONE_NUMBER}</a> M-F, 8:00am - 6:00pm
              EST
            </Typography>
          </Box>
        </Box>}
      </Box>
    </HashWrapper>
  )
}

export default Inspection
