import { Box, Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../styles'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import { Estimate, Job } from 'ducks/types'
import moment from 'moment'
import { ArrowForward } from '@material-ui/icons'

const EntryView = ({ item, isEstimate }: { item: Job | Estimate, isEstimate: boolean }) => {
  const styles = useStyles()

  const handleView = (id: string) => {
    window.open(`/p/${isEstimate ? 'estimates' : 'jobs'}/${id}`)
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Box className={styles.groupedOrderItem}>
          <Box>
            <Typography
              className={`${styles.desc} ${styles.nextArtHeavyFont}`}
              style={{ color: 'var(--bosscat-black-600)' }}
            >
              {item.properties.address?.fullAddress || ''}
            </Typography>
            <Typography
              className={`${styles.desc} ${styles.nextArtHeavyFont}`}
              style={{ color: 'var(--bosscat-black-600)' }}
            >
              Requested On:{' '}
              <Typography
                className={styles.desc}
                component="span"
                style={{ color: 'var(--bosscat-black-400)' }}
              >
                {`${moment(item.createdOn * 1000).format('MM-DD-YYYY')}`}
              </Typography>
              <Typography
                className={styles.desc}
                component="span"
                style={{ color: 'var(--bosscat-black-400)', marginLeft: '8px' }}
              >
                <b>{`#${item.properties.publicId}`}</b>
              </Typography>
            </Typography>
          </Box>
          <Button
            className={styles.addCartButton}
            endIcon={isEstimate && item.publicStatus === 'In Progress' ? <></> : <ArrowForward style={{ width: '16px', height: '16px' }} />}
            disabled={isEstimate ? item.publicStatus === 'In Progress' ? true : false : false}
            onClick={() => handleView(item.id)}
          >
            <Typography className={styles.lgFont}>{isEstimate && item.publicStatus === 'In Progress' ? 'In Progress' : 'View'}</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default EntryView
