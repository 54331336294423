import storeInfo from 'ducks/store'
import { concatSeparator } from 'helpers'
import { Job } from 'ducks/types'
import { JobType } from './types'

export const getJob = () => (): JobType => {
  const { job } = storeInfo.store.getState()
  return job
}

export const getJobAddress = (): string => {
  const { job } = storeInfo.store.getState()
  const { state, city, line_1: street1 } = (job !== null && (job as Job)?.properties?.address) || {}
  const address = concatSeparator(street1 || '', concatSeparator(city || '', state || '', ', '), ' - ')
  return address || 'No address'
}
