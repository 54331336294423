/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { Dialog, Button, DialogContent, Typography, Box, Grid, STextField } from '../../../../UI'
import useStyles from './styles'
import { NewCardFormProps, NewCardModalProps } from './types'
import { reportProblem } from '../../../../../assets'
import env from '@beam-australia/react-env'
import { loadStripe, SetupIntent } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js'
import { Checkbox, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { paymentsActions, userActions } from '../../../../../ducks/actions'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

const NewCardForm: FC<NewCardFormProps> = ({ setOpen }) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [cardHolder, setCardHolder] = useState('')
  const [isDefault, setAsDefault] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [clientSecret, setClientSecret] = useState<string>('')

  useEffect(() => {
    dispatch(paymentsActions.fetchStripeSecretSetup((secret?: string) => {
      if (secret) setClientSecret(secret)
    }))
  }, [])

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!stripe || !elements || loading || !cardHolder || !clientSecret) {
      setShowWarning(true)
      return
    } else setShowWarning(false)

    setErrorMessage(undefined)
    setLoading(true)

    const { error, setupIntent } = await
      stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement) as any,
          billing_details: { name: cardHolder },
        }
      })

    if (error) {
      setErrorMessage(error.message)
      setLoading(false)
    } else if (setupIntent) {
      const { payment_method } = setupIntent as SetupIntent
      dispatch(userActions.updateUserList({
        attr: 'paymentMethods', opp: 'add', list: [{
          isDefault, stripeId: payment_method
        }]
      }, (updateSucc) => {
        if (updateSucc) {
          setOpen(false)
          dispatch(userActions.fetchCurrentUser(() => {
            setLoading(false)
          }))
        } else setLoading(false)
      }))
    } else setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      {stripe && elements && (
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ display: 'grid', gap: '12px', padding: '12px 0' }}>
            <Typography>Card Holder:</Typography>
            <STextField
              className={classes.cardHolder}
              value={cardHolder}
              error={showWarning && !cardHolder}
              placeholder={'Card Holder Full Name'}
              onChange={(event: any) => { setCardHolder(event.target.value) }}
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'grid', gap: '4px', padding: '0' }}>
            <Typography>Card Info:</Typography>
            <Box className={classes.cardNumber}>
              <CardElement />
            </Box>
          </Grid>
          {errorMessage && (
            <Grid item xs={12} className={classes.errorMessage}>
              <img src={reportProblem} alt='' />
              <Typography variant='caption' style={{ paddingTop: '4px' }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
          <Grid container item xs={12} style={{ alignItems: 'center' }}>
            <Checkbox
              checked={isDefault}
              onChange={() => setAsDefault(!isDefault)}
              name='consent'
              color='primary'
              style={{ height: 'auto' }}
            />
            <Typography variant='caption'>
              Set this method as Default Payment Method
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              fullWidth
            >
              {loading
                ? <CircularProgress className={classes.spinner} size={24} />
                : `New Credit Card`}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.close}
              fullWidth
              disabled={loading}
              onClick={() => { if (!loading) setOpen(false) }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  )
}

const NewCardModal: FC<NewCardModalProps> = (
  { open, setOpen }
) => {
  const classes = useStyles()

  return (
    <Dialog open={open} classes={{ paper: classes.rootModal }}>
      <DialogContent className={classes.header}>
        <Box className={classes.headerText}>
          <Typography>New Payment Method</Typography>
        </Box>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Elements stripe={stripePromise}>
          <NewCardForm setOpen={setOpen} />
        </Elements>
      </DialogContent>
    </Dialog>
  )
}

export default NewCardModal