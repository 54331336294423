import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      width: '100%',
      maxWidth: '800px',
      [theme.breakpoints.down('sm')]: {
        '@global': {
          '.MuiTypography-body1': {
            fontSize: '18px',
          }
        },
      }
    },
    buttonBack: {
      width: '185px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    brokerTitle: {
      margin: '2rem 0 1rem',
      [theme.breakpoints.down('sm')]: {
        margin: '1rem 0 0.5rem',
        fontFamily: 'LatoNormal',
        weight: 500,
        fontSize: '24px !important'
      }
    },
    modal: {
      maxWidth: '650px',
      maxHeight: '650px',
      width: '100%',
      height: '100%'
    },
    modalContent: {
      gridAutoRows: 'min-content auto',
      display: 'grid',
      //height: '325px',
      [theme.breakpoints.down('sm')]: {
        //height: '425px',
      }
    },
    phoneInput: {
      width: '100%',
      paddingTop: 16
    },
    modalContentOpen: {
      '@global': {
        '>.MuiGrid-root': {
          width: '100%',
          maxWidth: '100%',
          '@global': {
            '>.MuiBox-root': {
              [theme.breakpoints.down('sm')]: {
                width: '68%'
              }
            }
          }
        }
      }
    },
    grid: {
      display: 'grid',
      gap: '12px',
      paddingTop: '12px',
      gridTemplateColumns: 'repeat(10, auto)',
    },
    gridOpen: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        padding: '0px 0px 30px'
      }
    },
    grid2: {
      display: 'grid',
      gap: '12px',
      paddingTop: '12px',
      gridTemplateColumns: 'repeat(8, auto)'
    },
    pictureItem: {
      gridColumn: 'span 2',
      gridRow: 'span 2',
      padding: '8px 0px !important',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 10'
      }
    },
    topInputItems: {
      gridColumn: 'span 4',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 10'
      }
    },
    middleInputItem: {
      gridColumn: 'span 9',
      width: '100%',
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    middleButtonItem: {
      gridColumn: 'span 1',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '16px'
    },
    middleButtonItemOpen: {
      /*       [theme.breakpoints.down('sm')]: {
              position: 'absolute',
              right: 0
            } */
    },
    bottomInputItems: {
      gridColumn: 'span 5',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 10'
      }
    },
    submitContainer: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      textTransform: 'none',
      color: 'var(--white-color)',
      width: '180px',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    googleMapsButton: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      minWidth: '115px',
      width: '100%'
    },
    title: {
      fontSize: '18px',
      // fontWeight: 700,
      textAlign: "center",
      marginTop: '.5rem',
      [theme.breakpoints.down('md')]: {
      }
    },
    iconMap: {
      width: '16px',
      [theme.breakpoints.down('sm')]: {
        width: '14px',
      }
    },
    inputAutocomplete: {
      fontWeight: 900
    },
    radioContainer: {
      gap: '1rem',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    options: {
      padding: '16px 0',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    radio: {
      textTransform: 'capitalize',
      width: '50%',
      padding: '0 8px !important',
      height: '54px',
      "@global": {
        '.MuiFormControlLabel-label': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    },
    flags: {
      width: 20,
      height: 20,
      marginRight: '6px'
    }
  })
)
