import { Box, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import giftImg from 'assets/icons/maintenance/gift.jpg'
import ImageBadge from 'components/UI/CustomUI/molecules/ImageBadge'
import GiftMessage from './components/GiftMessage'
import { isMobile } from 'react-device-detect'
import GiftShippingInformation from './components/GiftShippingInformation'
import { PrivateLoader } from 'components/templates'
import { newOrderMaintenanceActions, orderMaintenanceActions, subscriptionsActions } from 'ducks/actions'

import styles from './styles.module.scss'
import { useStepperContext } from 'hooks/useStepperContext'
import { getOrderMaintenanceValue, isAuthorized } from 'ducks/selectors'

const GiftBox = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { setShowWarnings } = useStepperContext()
  const giftBox = useSelector(getOrderMaintenanceValue('giftBox'))
  const authorized = useSelector(isAuthorized)

  const handleHideStepperButtons = () => {
    const buttons = document.getElementById('stepper-controls')
    if (buttons) {
      if (loading) {
        buttons.style.display = 'none'
      } else {
        buttons.style.display = 'grid'
      }
    }
  }

  useEffect(() => {
    dispatch(
      subscriptionsActions.fetchSubscriptionsDeliveryDate((succ: boolean) => {
        if (succ) {
          setLoading(false)
        }
      })
    )
  }, [])

  useEffect(() => {
    return () => {
      setShowWarnings(false)
    }
  }, [])

  useEffect(() => {
    handleHideStepperButtons()
  }, [loading])

  useEffect(() => {
    if (!giftBox?.message) {
      dispatch(
        newOrderMaintenanceActions.setNewOrderMaintenanceValue({
          attr: 'giftBox',
          value: {
            ...giftBox,
            message:
              'Dear Client,\nThank you for allowing me to serve you in buying your dream home. To protect your investment and make homeownership easier, I am gifting you a one-year membership to a home maintenance program. With this membership, BOSSCAT will take care of your routine maintenance services, and you’ll be able to spend more time enjoying your new home!',
          },
        })
      )
    }
  }, [])

  useEffect(() => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "activeStepProgress", value: 3 }))
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "totalStepProgress", value: 5 }))
  }, [])

  return (

    <PrivateLoader loading={loading}>
      <Box>
        <Grid container spacing={2} className={styles.GiftBox__container}>
          <Grid
            item
            container
            xs={12}
            md={8}
            className={styles.GiftBox__imageContainer}
          >
            <Box style={{ width: '100%' }}>
              <Box style={{ width: '100%' }}>
                <Typography
                  style={{
                    fontFamily: 'NextArtHeavy',
                    color: '#A5965A',
                    fontSize: '1.75rem',
                    marginBottom: '1rem',
                  }}
                >
                  The gift set
                </Typography>
              </Box>
              <Grid container item className={styles.GiftBox__image}>
                <Grid xs={12} item>
                  <GiftShippingInformation />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid xs={12} md={4} item>
            {!isMobile && <GiftMessage />}
          </Grid>
        </Grid>
      </Box>
    </PrivateLoader>
  )
}

export default GiftBox
