import storeInfo from 'ducks/store'
import { concatSeparator } from 'helpers'
import { NewInspectionType } from './types'

export const getNewInspectionStep = () => (): any => {
  const { newInspection } = storeInfo.store.getState() || {}
  return newInspection?.step || 0
}

export const getNewInspectionValue = (type: string) => (): any => {
  const { newInspection } = storeInfo.store.getState()
  if (!newInspection) return null
  return newInspection[type as keyof NewInspectionType] || null
}

export const getNewInspectionValues = () => (): NewInspectionType => {
  const { newInspection } = storeInfo.store.getState()
  return newInspection
}

export const getNewInspectionAddress = (): string => {
  const { newInspection } = storeInfo.store.getState()
  const { city, line_1: street1 } = newInspection.address
  const address = concatSeparator(city ?? '', street1 ?? '', ', ')
  return address || 'No address'
}

export const getNewInspectionFullAddress = (): string => {
  const { newInspection } = storeInfo.store.getState()
  const { city, line_1: street1, state, zipCode } = newInspection.address
  let address = concatSeparator(street1 ?? '', city ?? '', ', ')
  address = concatSeparator(address, state + ' ' + zipCode, ', ')
  return concatSeparator(address, 'USA', ', ') || 'No address'
}

export const getNewInspectionSubstep = () => (): any => {
  const {
    newInspection: { substep },
  } = storeInfo.store.getState()
  return substep || 0
}

