import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
    createStyles({
        itemFirst: {
            margin: '15px',
            paddingRight: '30px',
            [theme.breakpoints.down('sm')]: {
                margin: '15px 0px',
                paddingRight: '0px'
            }
        },
        item: {
            margin: '15px',
            [theme.breakpoints.down('sm')]: {
                margin: '15px 0px'
            }
        },
        label: {
            fontFamily: 'LatoBlack !important',
            fontSize: '18px',
            marginBottom: "8px",
            color: "#0B060F"
        }
    })
)
