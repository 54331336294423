import { authTypes } from 'ducks/auth/types'
import { Partner } from 'ducks/types'
import { homemartTypes, HomeMartActions, HomeMartType, Tag } from './types'


const initialState: HomeMartType = {
  partners: [],
  tags: [],
  customerTypes: []
}


const reducer = (state = initialState, action: HomeMartActions): HomeMartType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case homemartTypes.SET_PARTNERS: {
      return {
        ...state,
        partners: payload as Partner[]
      }
    }

    case homemartTypes.SET_TAGS: {
      return {
        ...state,
        tags: payload as Tag[]
      }
    }

    case homemartTypes.SET_CUSTOMER_TYPES: {
      return {
        ...state,
        customerTypes: payload as Tag[]
      }
    }

    default:
      return state
  }
}

export default reducer
