import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from '../../../../../styles'
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  ArrowForward,
} from '@material-ui/icons'
import { newJob } from 'assets/index'
import { PropertyActivityActiveData } from 'ducks/types'
import moment from 'moment'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'

const JobStep = ({
  activeData,
  show,
  setShow,
}: {
  activeData?: PropertyActivityActiveData
  show?: boolean
  setShow?: (value: boolean) => void
}) => {
  const styles = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen

  return (
    <Grid item container spacing={2} direction="column">
      <Grid
        item
        container
        spacing={isSmallScreen ? 1 : 2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <img
            src={newJob}
            style={{ width: isSmallScreen ? '2.5em' : '3em' }}
            alt="estimate"
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography
            className={styles.nextArtTitle}
            style={{ fontWeight: 700, width: 'max-content', fontSize: isSmallScreen ? '14px' : '16px' }}
          >
            JOB STARTED!
          </Typography>
          <Typography className={styles.title} style={{ width: 'max-content', fontSize: isSmallScreen ? '14px' : '16px' }}>
            {`Started on: ${activeData?.jobStartedOn ? moment(activeData?.jobStartedOn * 1000).format("MMMM DD, YYYY") : ''}`}
          </Typography>
        </Grid>
        {!isSmallScreen && (
          <Grid item style={{ marginRight: '84px' }}>
            <Button
              variant="outlined"
              className={`${styles.Tab} ${styles.primaryOutlinedButton}`}
              style={{ width:  '160px', margin: 0 }}
              endIcon={<ArrowForward style={{ width: '14px', height: '14px' }} />}
              onClick={() => window.open(`/p/jobs/${activeData?.estimateId}`, '_blank')}
              disabled={!activeData?.approvedOn}
            >
              Go to Job
            </Button>
          </Grid>
        )}
        {/* <Grid item>
          <IconButton onClick={() => setShow && setShow(!show)} disabled={!activeData?.estimateId}>
            {!show ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Grid> */}
      </Grid>
      {isSmallScreen && (
        <Grid item>
          <Button
            variant="outlined"
            className={`${styles.Tab} ${styles.primaryOutlinedButton}`}
            style={{ width: 'calc(100%)', margin: 0 }}
            endIcon={<ArrowForward style={{ width: '14px', height: '14px' }} />}
            onClick={() => window.open(`/p/jobs/${activeData?.estimateId}`, '_blank')}
            disabled={!activeData?.approvedOn}
          >
            Go to Job
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default JobStep
