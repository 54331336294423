import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '4px 12px',
      borderRadius: 12,
      border: '2px solid #EBECEF',
      position: 'relative',
      justifyContent: 'center',
      margin: '8px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: '8px 0px'
      }
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center'
    },
    left: {
      justifyContent: 'left'
    },
    horizontalContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    headerContainer: {
      height: 44,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        flexDirection: 'column'
      }
    },
    title: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      width: '50%',
      [theme.breakpoints.down('lg')]: {
        width: '40%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    description: {
      fontSize: 16,
      fontWeight: 400
    },
    pr23: {
      paddingRight: 23
    },
    pr10: {
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        width: '80%'
      }
    },
    buttonsContainer: {
      width: 72,
      height: 32,
      position: 'absolute',
      right: 6,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        marginTop: '10px'
      }
    },
    image: {
      margin: '6px 0px 4px 0px'
    }
  })
)
