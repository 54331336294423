import storeInfo from 'ducks/store'
import { ApprovalType } from './types'
import { Estimate, EstimateContact } from 'ducks/types'
import { getUserIsHomeowner } from 'ducks/selectors'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  DOCUSING_CONTRACT_TYPE,
  ESTIMATE_PUBLIC_STATUS,
} from 'helpers/constants'
import env from '@beam-australia/react-env'
import { companyRoles, homeownerRoles } from 'helpers/index'
import { EstimateType } from 'ducks/estimate/types'

const DOCUSIGN_APPROVAL_PAY_AT_CLOSE =
  env('DOCUSIGN_APPROVAL_PAY_AT_CLOSE') ?? ''
const DOCUSIGN_APPROVAL_PAY_ON_COMPLETION_UNDER_THRESHOLD =
  env('DOCUSIGN_APPROVAL_PAY_ON_COMPLETION_UNDER_THRESHOLD') ?? ''
const DOCUSIGN_APPROVAL_PAY_ON_COMPLETION =
  env('DOCUSIGN_APPROVAL_PAY_ON_COMPLETION') ?? ''
const DOCUSIGN_APPROVAL_REPAIRS_AFTER_CLOSING_DATE =
  env('DOCUSIGN_APPROVAL_REPAIRS_AFTER_CLOSING_DATE') ?? ''

export const getApprovalStep = (): any => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()

  return estimate?.properties?.approvalStep || 0
}

export const getapprovalValue = (type: string) => (): any => {
  const { approval } = storeInfo.store.getState()
  if (!approval) return null
  return approval[type as keyof ApprovalType] || null
}

export const getApprovalValues = () => (): ApprovalType => {
  const { approval } = storeInfo.store.getState()
  return approval || {}
}

export const getContractType = () => {
  const isHomeowner = getUserIsHomeowner()
  const { approval, estimate }: { estimate: Estimate; approval: ApprovalType } =
    storeInfo.store.getState()

  const agentIsHomeowner =
    !isHomeowner &&
    approval?.role &&
    homeownerRoles.includes(approval?.role as CONTACT_ROLE)
  if (
    isHomeowner ||
    agentIsHomeowner ||
    approval.agentAsHomeownerRepresentative === 'myself'
  ) {
    //HOMEOWNER
    if (approval?.paymentOption === 'pay_at_close') {
      return DOCUSING_CONTRACT_TYPE.APPROVAL_PAY_AT_CLOSE
    } else if (!approval?.completedBeforeClosing && approval.closingDate) {
      return DOCUSING_CONTRACT_TYPE.APPROVAL_REPAIRS_AFTER_CLOSING_DATE
    } else if (approval?.isOverInstallmentThreshold) {
      return DOCUSING_CONTRACT_TYPE.APPROVAL_PAY_ON_COMPLETION
    } else {
      return DOCUSING_CONTRACT_TYPE.APPROVAL_PAY_ON_COMPLETION_UNDER_THRESHOLD
    }
  } else {
    //AGENT
    return DOCUSING_CONTRACT_TYPE.APPROVAL_LISTING_ACKNOWLEDGEMENT
  }
}

export const getDocusignEnv = () => {
  const contractType = getContractType()

  return env(`DOCUSIGN_${contractType}`) ?? ''
}

export const showApprove = (userEmail: string, estimateFromParams?: Estimate) => (): boolean | null => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  const estimateToUse = estimateFromParams || estimate
  const estimateHasOneRequiredHomeowner = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.role === CONTACT_ROLE.HOMEOWNER &&
      contact.approvalType === APPROVAL_TYPE.REQUIRED
  )
  const userIsAgentInEstimate = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.email === userEmail && companyRoles.includes(contact.role)
  )
  const userIsHomeownerInEstimate = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.email === userEmail && homeownerRoles.includes(contact.role)
  )
  const userIsAgentHomeownerInEstimate = estimateToUse?.properties?.contacts?.find(
    (contact: any) =>
      contact.email === userEmail &&
      contact.role === CONTACT_ROLE.AGENT_HOMEOWNER
  )
  const userIsRequiredHomeownerInEstimate =
    estimateToUse?.properties?.contacts?.find(
      (contact: any) =>
        contact.email === userEmail &&
        homeownerRoles.includes(contact.role) &&
        contact.approvalType === APPROVAL_TYPE.REQUIRED
    )

  const disableButton = userIsAgentHomeownerInEstimate
    ? false
    : (estimateHasOneRequiredHomeowner &&
      !userIsRequiredHomeownerInEstimate &&
      !userIsAgentInEstimate) ||
    (!userIsAgentInEstimate && !userIsHomeownerInEstimate)

  return !disableButton
  /* const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  const contactInEstimate =
    estimate?.properties?.contacts?.find((c) => c.email === userEmail) ?? null

  return (
    (estimate?.approvalStatus === 'PENDING_FURTHER' ||
      estimate?.approvalStatus === 'NOT_APPROVED' ||
      estimate?.approvalStatus === 'MISSING_DETAILS') &&
    estimate.publicStatus !== ESTIMATE_PUBLIC_STATUS.EXPIRED &&
    contactInEstimate?.approvalType !== APPROVAL_TYPE.APPROVED &&
    (estimate?.properties?.totalValue || 0) >
    (estimate?.properties?.minimum || 0)
  ) */
}
