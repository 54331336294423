import { useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, Box, TextFieldLabel, TitleFirst, Avatar, CircularProgress } from '../../../UI'
import useStyles from './styles'
import { validateEmail, isEmpty, EMAIL_TYPE } from '../../../../helpers'
import { authActions } from '../../../../ducks/actions'
import { send } from '../../../../assets'
import { getUser } from '../../../../ducks/selectors'

const Reset: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const primaryEmail = user.email.find(e => e.emailType === EMAIL_TYPE.PRIMARY)?.email ?? ''

  const [emailToSend, setEmailToSend] = useState(primaryEmail)
  const [loading, setLoading] = useState(false)
  const [sendEmailError, setSendEmailError] = useState(false)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const [submit, setSubmit] = useState(false)

  const handleAccept = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setSubmit(true)
    setSendEmailError(false)
    setSendEmailSuccess(false)
    if (validateEmail(emailToSend)) {
      setLoading(true)
      dispatch(authActions.changePasswordRequest({
        email: emailToSend,
        callback: (success: boolean) => {
          setLoading(false)
          if (success) setSendEmailSuccess(true)
          else {
            setSendEmailError(true)
            setSubmit(false)
          }
        }
      }))
    }
  }

  return (
    <Box className={classes.root}>
      <TitleFirst value='Change your password!' />
      <Typography variant='h6' className={classes.text}>
        We'll send you an email with a link to
      </Typography>
      <Typography variant='h6' className={classes.text}>
        change it.
      </Typography>
      <Box className={classes.center}>
        <form className={classes.form} autoComplete='off'>
          <TextFieldLabel
            label='Email:'
            type='email'
            disabled
            value={emailToSend}
            error={submit && (isEmpty(emailToSend) || !validateEmail(emailToSend))}
            onChange={(event) => { if (!loading) { setEmailToSend(event.target.value) } }}
            placeholder='Enter your email here'
            className={classes.pass}
          />
          <Box style={{ padding: '0 10%' }}>
            {sendEmailError && (
              <Typography variant='body2' color='error'>
                An error occurred, please verify you entered the correct email address.
              </Typography>
            )}
            {sendEmailSuccess && (
              <Typography variant='body2' color='secondary'>
                Thank you, instructions to change your password have been sent to your email.
              </Typography>
            )}
          </Box>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={(event) => { if (!loading) handleAccept(event) }}
            endIcon={!loading && <Avatar alt='' src={send} className={classes.icon} />}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Change My Password'}
          </Button>
        </form>
      </Box>
    </Box>
  )
}

export default Reset
