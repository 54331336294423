import { FC, useState } from 'react'
import {
  Dialog, STextField, Button, DialogContent, Typography,
  FormControl, RadioGroup, FormControlLabel, Radio
} from '../../../../../UI'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { FeedbackModalProps } from './types'
import clsx from 'clsx'
import { isEmpty } from '../../../../../../helpers'

const options = [
  'Too expensive',
  'I don’t think is really needed right now',
  'I can order this later',
  'Other'
]

const FeedbackModal: FC<FeedbackModalProps> = ({ open, setOpen, onSubmit, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const [submit, setSubmit] = useState(false)
  const [optionSelected, setOptionSelected] = useState<string | null>(null)
  const [comment, setComment] = useState('')

  const validate: boolean =
    (!isEmpty(optionSelected) && optionSelected !== 'Other') ||
    (!isEmpty(comment) && optionSelected === 'Other')

  const handleChange = (event: any) => {
    setOptionSelected(event.target.value)
  }

  const handleClose = () => {
    setOptionSelected(null)
    setComment('')
    setOpen(false)
  }

  const handleOnSubmit = () => {
    setSubmit(true)
    if (validate) {
      onSubmit(optionSelected ?? '', comment)
      handleClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant='h6' className={classes.text}>
          Would be awesome to know your feedback about the declined item
        </Typography>
      </DialogContent>
      <DialogContent>
        <FormControl component='fieldset' className={classes.formControl}>
          <RadioGroup value={optionSelected} onChange={handleChange}>
            {options.map((option, index) =>
              <FormControlLabel key={index} value={option} control={<Radio color='primary' />} label={option} className={classes.text} />
            )}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      {optionSelected === 'Other' && (
        <DialogContent>
          <STextField
            className={clsx(classes.input, submit && isEmpty(comment) ? classes.errorColor : '')}
            value={comment}
            disabled={optionSelected !== 'Other'}
            onChange={(event) => setComment(event.target.value)}
            placeholder='Let us know why...'
            error={submit && isEmpty(comment)}
          />
        </DialogContent>
      )}
      <DialogContent>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
          fullWidth
          onClick={handleOnSubmit}
        >
          Submit Feedback
        </Button>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default FeedbackModal
