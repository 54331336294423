import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '800px',
      borderRadius: '16px',
      width: '100%',
    },
    rootBroker:{
      maxWidth: '400px',
    },
    header: {
      padding: 0,
      overflowX: 'hidden',
    },
    container: {
      padding: '8px 24px',
    },
    title: {
      fontFamily: 'LatoNormal',
      fontSize: 24,
      margin: '1rem 0'
    },
    tbody: {
      fontSize: 14,
      fontFamily: 'LatoNormal',
      color: 'var(--input-color)',
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '16px',
      padding: '8px 16px',
      borderRadius: '8px',
      background: 'var(--expired-color-back)'
    },
    errorColor: { borderColor: 'var(--error-color)' },
    errorTopColor: { borderTopColor: 'var(--error-color)' },
    errorBottomColor: { borderBottomColor: 'var(--error-color)' },
    errorLeftColor: { borderLeftColor: 'var(--error-color)' },
    errorRightColor: { borderRightColor: 'var(--error-color)' },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    dropzone: {
      minHeight: '200px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px'
      },
      '@global': {
        '.MuiDropzoneArea-text': {
          fontSize: 16
        }
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      margin: '1rem 0',
    },
    button: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
      margin: '.6rem 0',
      padding: '1rem 2rem'
    },
    buttonCancel: {
      textTransform: 'inherit',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
    },
    boxes: {
      display: 'flex',
      gap: '1rem',
      margin: '1rem 0'
    },
    brokerBoxes: {
      flexDirection: 'column',
    },
    box: {
      flex: '1',
      '@global': {
        '.MuiTextField-root': {
          minWidth: 'fit-content',
          height: 'fit-content'
        },/* 
        '.MuiInputBase-root': {
          height: 'fit-content'
        },
        '.MuiAutocomplete-inputRoot': {
          paddingRight: 0
        },
        '.MuiAutocomplete-endAdornment': {
          position: 'static'
        } */
      }
    },
    disclousure: {
      fontFamily: 'LatoLight',
      color: 'var(--date-color)',
      padding: 8
    },
    footer: {
      background: 'var(--background-color-secondary)',
      padding: '8px 24px',
      display: 'flex',
      gap: '1rem',
      '@global': {
        '.Mui-disabled': {
          background: 'var(--border-color)',
        }
      }
    },
    buttonClose: {
      textTransform: 'inherit',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
      fontSize: 16,
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'transparent',
    },
    buttonSubmit: {
      textTransform: 'inherit',
      fontFamily: 'LatoNormal',
      fontSize: 16,
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'var(--bosscat-blue-600)',
      color: 'var(--white-color)',
      '@global': {
        '.MuiSvgIcon-root': {
          marginLeft: '.3rem',
          width: '1rem',
          transform: 'translateY(-1px)'
        }
      }
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    footerBroker: {
      flexDirection: 'column-reverse',
      padding: '15px 24px'
    },
    loading:{
      width: '100%',
      position: 'absolute',
      height: '100%',
      display: 'flex',
      zIndex: 99999,
      background: '#ffffff9e'
    }

  })
)
