import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
      [theme.breakpoints.down('lg')]: {
        marginLeft: 10,
      },
    },
    pagination: {
      '@global': {
        '.MuiTablePagination-spacer': {
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
        '.MuiToolbar-root': {
          [theme.breakpoints.down('md')]: {
            // width: '90vw',
            overflow: 'hidden',
          },
        },
        '.MuiTablePagination-input': {
          [theme.breakpoints.down('lg')]: {
            marginRight: 10,
          },
        },
        '.MuiTablePagination-toolbar': {
          [theme.breakpoints.down('lg')]: {
            paddingRight: 0,
          },
        },
        '.MuiIconButton-root': {
          [theme.breakpoints.down('lg')]: {
            padding: 0,
          },
        },
      },
    },
  })
)
