import { FC, useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import useStyles from './styles'
import { houseInfrastructure, icons } from 'assets'

interface SuccessModalProps {
  img?: string
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  onClose?: () => void
}

const SuccessModal: FC<SuccessModalProps> = ({ img = houseInfrastructure, title = "", subtitle = "", onClose }) => {
  const styles = useStyles()

  /*   useEffect(() => {
      const timer = setTimeout(() => {
        onClose && onClose()
      }, 4000);
      return () => clearTimeout(timer);
    }, [onClose]); */

  return (
    <Box width={"100%"} display="flex" flexDirection="column" gridGap="16px" className={styles.root}>
      <img src={img} className={styles.icon} alt="email" />
      <Typography variant="h5" className={styles.title}>
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
      <Box className={styles.shoppingBox} onClick={onClose}>
        <icons.ShoppingCart htmlColor="#018847" style={{ width: '13px' }} />
        <Typography>You can continue to add items to your cart and place an order.</Typography>
        <icons.ArrowForward htmlColor="#018847" style={{ width: '13px' }} />
      </Box>
    </Box>
  )
}

export default SuccessModal