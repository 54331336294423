import { USER_TYPE } from 'helpers'
import { authTypes } from 'ducks/auth/types'
import {
  userTypes,
  UserType,
  UserActions,
  UserValue,
  UserValues
} from './types'

const initialState: UserType = {
  id: '',
  email: [],

  firstName: '',
  middleName: '',
  lastName: '',
  phone: null,
  tosAccepted: false,
  nar: false,
  narId: null,
  affiliate: '',
  companyName: '',

  address: {
    city: '',
    state: '',
    line_1: '',
    line_2: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
    county: '',
    country: ''
  },
  picture: '',

  registrationComplete: false,
  type: '',
  clientType: USER_TYPE.HOMEOWNER,
  paymentMethods: [],
  customerId: '',

  userProperties: {}
}

const reducer = (state = initialState, action: UserActions): UserType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return {
        ...initialState,
        userProperties: state.userProperties
      }
    }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case userTypes.SET_VALUE: {
      const { attr, value } = payload as UserValue
      return {
        ...state,
        [attr]: value
      }
    }
    case userTypes.SET_VALUES: {
      const { attrs } = payload as UserValues
      return {
        ...state,
        ...attrs as UserType
      }
    }
    default:
      return state
  }
}

export default reducer
