import { FC, useEffect, useState } from 'react'
import useStyles from './styles'
import { LoginAnimationProps } from './types'
import {
  loginAnimRepairs,
  loginAnimEstimates,
  loginAnimRenovations,
  loginAnimInspections
} from '../../../../../assets'

// TODO: improve fade in/out animation

const LoginAnimation: FC<LoginAnimationProps> = ({ timeout = 3000 }) => {
  const classes = useStyles()

  const [animation, setAnimation] = useState(0)

  const getAnimation = () => {
    switch (animation) {
      case 0: return loginAnimRepairs
      case 1: return loginAnimEstimates
      case 2: return loginAnimRenovations
      case 3: return loginAnimInspections
    }
  }

  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted)
        setAnimation((animation + 1) % 4)
    }, timeout)
    return () => { isMounted = false }
  }, [animation, timeout])

  return (
    <img data-testid='login-animation' src={getAnimation()} alt='' className={classes.image} />
  )
}

export default LoginAnimation
