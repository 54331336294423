import { FORM_TYPE } from 'helpers'
import { Estimate } from 'ducks/types'

/** TYPES **/
export const estimatesTypes = {
  FETCH_ESTIMATES: 'FETCH_ESTIMATES',
  FETCH_ESTIMATES_STATS: 'FETCH_ESTIMATES_STATS',
  SET_ESTIMATES: 'SET_ESTIMATES',
  SET_ESTIMATE_VALUE: 'SET_ESTIMATE_VALUE',
  FETCH_ESTIMATES_SERVICES: 'FETCH_ESTIMATES_SERVICES'
}

/** ACTIONS **/
export interface FetchEstimatesAction {
  type: typeof estimatesTypes.FETCH_ESTIMATES
  payload: {
    startDate?: number,
    endDate?: number,
    status?: string[],
    start?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
    shared?: boolean,
    search?: string
  }
  callback?: (succ: boolean, estimates?: Estimate[]) => void
}

export interface FetchEstimatesStatsAction {
  type: typeof estimatesTypes.FETCH_ESTIMATES_STATS
  payload: { startDate?: number, endDate?: number, status?: string, search?: string }
  callback?: (succ: boolean) => void
}

export interface SetEstimatesAction {
  type: typeof estimatesTypes.SET_ESTIMATES
  payload: Estimate[]
}

export interface EstimateValue {
  estimateId: string
  attr: keyof Estimate
  value: any
}

export interface SetEstimateValueAction {
  type: typeof estimatesTypes.SET_ESTIMATE_VALUE
  payload: EstimateValue
}

export interface FetchEstimatesServicesAction {
  type: typeof estimatesTypes.FETCH_ESTIMATES_SERVICES
  payload: FetchEstimatesServicesPayload
  callback?: (succ: boolean) => void
}

export interface FetchEstimatesServicesPayload {
  serviceable: boolean,
  formType: FORM_TYPE,
  isVip?: boolean,
  isNar?: boolean | null,
  affiliate?: string | null,
}

export type EstimatesActions =
  | FetchEstimatesAction
  | FetchEstimatesStatsAction
  | SetEstimatesAction
  | SetEstimateValueAction
  | FetchEstimatesServicesAction

export interface EstimatesIndicators {
  total: number
  inProgress: number
  expired: number
  completed: number
  approved: number
}

/** REDUCER **/
export interface EstimatesType extends Array<Estimate> { }
