import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content min-content auto',
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        display: 'block',
        width: '100%'
      }
    },
    tableOff: {
      position: 'relative',
      borderStyle: 'solid',
      borderWidth: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none'
    },
    button: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
      padding: '1rem .7rem',
      minWidth: '50%',
      display: 'flex',
      backgroundColor: 'transparent',
      fontSize: '16px',
      "@global": {
        ".MuiButton-label": {
          padding: '0',
        }
      },
      '&:hover': {
        backgroundColor: 'transparent'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 48
      }
    },
    buttonPaid: {
      backgroundColor: 'var(--button-secondary)',
      '&:hover': {
        backgroundColor: 'var(--button-secondary-hover)'
      }
    },
    buttonView: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'var(--button-focus-color)'
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 48
      }
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '16px'
    },
    medium: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: 'var(--date-color)'
    },
    mediumValue: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '14px',
      color: 'var(--head-text-color)'
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px',
      color: 'var(--text-color)'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0
    },
    itemContainer: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          marginRight: '.7rem !important'
        }
      }
    },
    container: {
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid #eeeeee',
        borderRadius: '12px',
        padding: '0.6rem',
        margin: '.5rem 0',
        boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',

      },
      '& >.MuiGrid-item': {
        [theme.breakpoints.down('lg')]: {
          padding: '.2rem',
          width: '100%'
        }
      }
    },
    status: {
      textTransform: 'uppercase',
      fontSize: '16px'
    },
    noMargin: {
      margin: '0px !important'
    },
    alignment: {
      align: 'left',
      width: '120px'
    },
    expectedDeliveryLabel: {
      color: 'var(--expired-color)'
    },
    resendButton: {
      backgroundColor: 'transparent',
      color: 'var(--bosscat-blue-600)',
      border: '2px solid var(--bosscat-blue-600)'
    },
  })
)
