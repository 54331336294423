/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { Punchlist } from 'api'
import { discountsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'
import { FetchDiscountBannerAction, discountsTypes } from './types'

export function* fetchDiscountBanner({ payload, callback }: FetchDiscountBannerAction): SagaIterator {
  let success = false
  try {
    const discountBanner = yield call(Punchlist.discounts.getDiscountBanner)

    if (discountBanner) {
      yield put(discountsActions.setValue({ attr: 'discountBanner', value: discountBanner }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    // yield call(toast.error, errorTextTryingTo('get the discount banner'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(discountsTypes.FETCH_DISCOUNT_BANNER, fetchDiscountBanner)
}
