import { Item, RepairItem } from 'ducks/types'

/** TYPES **/
export const estimateItemsTypes = {
  FETCH_ESTIMATE_ITEMS: 'FETCH_ESTIMATE_ITEMS',
  SET_ESTIMATE_ITEMS: 'SET_ESTIMATE_ITEMS',
  SET_ESTIMATE_ITEM_VALUES: 'SET_ESTIMATE_ITEM_VALUES',
  UPDATE_ESTIMATE_ITEM: 'UPDATE_ESTIMATE_ITEM',
  UPDATE_ESTIMATE_ITEMS: 'UPDATE_ESTIMATE_ITEMS_1',
  CREATE_ESTIMATE_ITEMS: 'CREATE_ESTIMATE_ITEMS'
}

/** ACTIONS **/
export interface FetchEstimateItemsAction {
  type: typeof estimateItemsTypes.FETCH_ESTIMATE_ITEMS
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetEstimateItemsAction {
  type: typeof estimateItemsTypes.SET_ESTIMATE_ITEMS
  payload: Item[]
}

export interface EstimateItemValues {
  itemId: string
  attrs: Partial<Item>
}

export interface SetEstimateItemValuesAction {
  type: typeof estimateItemsTypes.SET_ESTIMATE_ITEM_VALUES
  payload: EstimateItemValues
}

export interface UpdateEstimateItemPayload {
  estimateId: string
  itemId: string
  partialItem: Partial<Item>
}

export interface UpdateEstimateItemsPayload {
  estimateId: string
  items: Item[]
}

export interface UpdateEstimateItemAction {
  type: typeof estimateItemsTypes.UPDATE_ESTIMATE_ITEM
  payload: UpdateEstimateItemPayload
  callback?: (succ: boolean) => void
}

export interface CreateEstimateItemsAction {
  type: typeof estimateItemsTypes.CREATE_ESTIMATE_ITEMS,
  payload: {
    items: Partial<Item>[],
    estimateId?: string
  },
  callback: (succ: boolean) => void
}

export interface UpdateEstimateItemsAction {
  type: typeof estimateItemsTypes.CREATE_ESTIMATE_ITEMS,
  payload: Partial<Item>[],
  callback: (succ: boolean) => void
}

export type EstimateItemsActions =
  | FetchEstimateItemsAction
  | SetEstimateItemsAction
  | SetEstimateItemValuesAction
  | UpdateEstimateItemAction
  | CreateEstimateItemsAction
  | UpdateEstimateItemsAction

/** REDUCER **/
export interface EstimateItemsType extends Array<Item> { }
