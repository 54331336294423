import { Invoice } from 'ducks/types'
import { InvoiceActions, invoiceTypes } from './types'

const actions = {
  fetchInvoice: (
    payload: string,
    callback?: (succ: boolean) => void
  ): InvoiceActions => ({
    type: invoiceTypes.FETCH_INVOICE,
    payload,
    callback
  }),
  setInvoice: (payload: Invoice): InvoiceActions => ({
    type: invoiceTypes.SET_INVOICE,
    payload
  })
}

export default actions
