import { Job } from 'ducks/types'
import { JobActions, jobTypes } from './types'

const actions = {
  fetchJob: (
    payload: string,
    callback?: (succ: boolean) => void
  ): JobActions => ({
    type: jobTypes.FETCH_JOB,
    payload,
    callback
  }),
  setJob: (payload: Job): JobActions => ({
    type: jobTypes.SET_JOB,
    payload
  }),
  postJob: (payload: Partial<Job>, callback: (succ: boolean) => void): JobActions => ({
    type: jobTypes.CREATE_JOB,
    payload,
    callback
  })
}

export default actions
