import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center !important',
    width: '100% !important',
    '> div': {
      minHeight: '0px !important',
    },
    '@global': {
      '.MuiDialog-container': {
        margin: 'auto !important',
      },
      '.MuiPaper-root': {
        borderRadius: '16px'
      },
      '.MuiDialog-paperWidthSm': {
        maxWidth: 'inherit !important'
      }
    },
  },

  xs: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '400px !important'
      }
    }
  },
  sm: {},
  md: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '650px !important'
      }
    }
  },
  lg: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '850px !important'
      }
    }
  },
  xl: {
    '@global': {
      '.MuiDialog-container': {
        maxWidth: '1200px !important'
      }
    }
  },
  button: {
    width: "100%",
  },
  fullWidth: {
    maxWidth: '100% !important'
  },
  fullScreen: {
    maxWidth: '100% !important',
    height: '100% !important'
  },
  Modal__title: {
    padding: '12px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    position: 'relative',
    minHeight: '50px',
  },
  close: {
    /*   position: 'absolute',
      right: '0', */
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'auto'
    }
  }
}))
