import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      gap: theme.spacing(4),
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center'
    },
    animation: {
      display: 'flex',
      justifyContent: 'center'
    },
    loader: {
      width: '100%',
      margin: 'auto',
      borderRadius: '16px',
      maxWidth: '280px',
      height: '8px',
      color: 'var(--bosscat-blue-600)',
      [theme.breakpoints.up('md')]: {
        maxWidth: '240px'
      }
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    centerText: {
      textAlign: 'center'
    },
    bigIcon: {
      width: '220px',
      height: '220px',
      [theme.breakpoints.down('md')]: {
        width: '120px',
        height: '120px'
      }
    },
    subTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '30px',
      color: 'var(--head-text-color)',
      margin: '8px',
      marginBottom: '0px'
    },
  })
)
