import { FC, useCallback, useState } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Grid,
  Button,
  ProfilePicture,
  TextFieldLabel,
  Autocomplete,
  CircularProgress,
  Radio,
  Typography,
} from '../../../../UI'
import { toast } from 'react-toastify'
import { getUser } from '../../../../../ducks/selectors'
import {
  canadaStates,
  EMAIL_TYPE,
  formatPhone,
  getCountriesOptionList,
  getCountrySelectedAsOption,
  getSelectedAsOption,
  getStatesOptionList,
  getStatesOptionListCanada,
  isEmpty,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  states,
  USER_TYPE,
} from '../../../../../helpers'
import useStyles from './styles'
import { filesActions, userActions } from '../../../../../ducks/actions'
import { narAgent } from '../../../../../assets'

const UserInfo: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const address = user.address
  const primaryEmail =
    user.email.find((e) => e.emailType === EMAIL_TYPE.PRIMARY)?.email ?? ''
  const userPhone = formatPhone(user.phone)

  const [updating, setUpdating] = useState(false)
  const [showWarnings, setShowWarnings] = useState(false)

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [phone, setPhone] = useState(userPhone)
  const [nar, setNar] = useState(user.nar ?? false)
  const [memberId, setMemberId] = useState(user.narId)

  const [street, setStreet] = useState(address?.line_1)
  const [city, setCity] = useState(address?.city)
  const [state, setState] = useState(address?.state)
  const [zipCode, setZipCode] = useState(address?.zipCode)
  const [country, setCountry] = useState(address?.country)

  const [uploading, setUploading] = useState(false)
  const [picture, setPicture] = useState(user?.picture)

  const subOptions = [
    { text: '', title: 'Yes', id: true },
    { text: '', title: 'No', id: false },
  ]

  const zipCodeMaxLength = country === 'Canada' ? 8 : 6

  const changesToApply: boolean =
    firstName?.trim() !== user?.firstName ||
    lastName?.trim() !== user?.lastName ||
    phone?.trim() !== userPhone ||
    street?.trim() !== address?.line_1 ||
    city?.trim() !== address?.city ||
    state?.trim() !== address?.state ||
    zipCode?.trim() !== address?.zipCode ||
    country !== address?.country ||
    nar !== user.nar ||
    memberId !== user.narId

  const validate: boolean =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    PHONE_MASK_REGEXP.test(phone) &&
    !isEmpty(street) &&
    !isEmpty(city) &&
    !isEmpty(state) &&
    !isEmpty(zipCode) &&
    ((country === 'Canada' && zipCode.length <= 7) ||
      (country !== 'Canada' && zipCode.length === 5))

  const handleUpload = (image: File | null): void => {
    if (image) {
      setUploading(true)
      setPicture('')
      dispatch(
        filesActions.uploadFile(image, (uploadSucc, fileName) => {
          if (uploadSucc && !isEmpty(fileName)) {
            dispatch(
              userActions.updateUser(
                {
                  picture: fileName,
                },
                (success) => {
                  const newPicture = success
                    ? (fileName as string)
                    : user.picture
                  setPicture(newPicture)
                  setTimeout(() => {
                    setUploading(false)
                    const customEvent = new CustomEvent(
                      'profilePictureChange',
                      {
                        detail: { newPicture },
                      }
                    )
                    document.dispatchEvent(customEvent)
                  }, 5000)
                }
              )
            )
          } else {
            setPicture(user.picture)
            setUploading(false)
          }
        })
      )
    }
  }

  const handleSubmit = () => {
    if (validate) {
      setShowWarnings(false)
      setUpdating(true)

      dispatch(
        userActions.updateUser(
          {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phone: parseInt(phone?.replaceAll('-', '')),
            nar: nar ?? false,
            narId: nar ? memberId : '',
            address: {
              line_1: street.trim(),
              line_2: '',
              city: city.trim(),
              state: state.trim(),
              zipCode: zipCode.trim(),
              longitude: 0,
              latitude: 0,
              country,
              county: address.county,
            },
          },
          (success) => {
            if (success) {
              toast.success('Profile updated!')
            }
            setUpdating(false)
          }
        )
      )
    } else {
      setShowWarnings(true)
    }
  }

  const isValidZIPCode = useCallback(() => {
    if (country === 'Canada') {
      return zipCode.length <= 7
    } else {
      return zipCode.length === 5
    }
  }, [zipCode, country])

  const isValidCountry = useCallback(() => {
    return country === 'United States' || country === 'Canada'
  }, [country])

  const isValidInput = useCallback(() => {
    if (country === 'Canada') {
      return zipCode.length <= 7
    } else {
      return zipCode.length === 5
    }
  }, [zipCode, country])

  const handleChangeCountry = (option: any) => {
    if (country !== option.value) {
      setCountry(option.value)
      /* setZipCode("")
      setState("")
      setCity("") */
    }
  }

  const handleSelectNar = (idx: any): void => {
    setNar(idx)
  }

  return (
    <Box>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item className={classes.pictureItem}>
          <ProfilePicture
            avatar={picture}
            onUpload={handleUpload}
            uploading={uploading}
          />
        </Grid>
        <Grid item className={classes.topInputItems}>
          <TextFieldLabel
            label="First Name:"
            error={showWarnings && isEmpty(firstName.trim())}
            onChange={(ev) => {
              setFirstName(ev.target.value)
            }}
            value={firstName}
          />
        </Grid>
        <Grid item className={classes.topInputItems}>
          <TextFieldLabel
            label="Last Name:"
            error={showWarnings && isEmpty(lastName.trim())}
            onChange={(ev) => {
              setLastName(ev.target.value)
            }}
            value={lastName}
          />
        </Grid>
        <Grid item className={classes.topInputItems}>
          <TextFieldLabel
            label="Phone:"
            mask={PHONE_MASK_INPUT}
            error={
              showWarnings && (isEmpty(phone) || !PHONE_MASK_REGEXP.test(phone))
            }
            onChange={(ev) => {
              setPhone(ev.target.value)
            }}
            value={phone}
          />
        </Grid>
        <Grid item className={classes.topInputItems}>
          <TextFieldLabel label="Email:" value={primaryEmail} disabled />
        </Grid>
        <Grid item className={classes.middleInputItem}>
          <TextFieldLabel
            label="Address:"
            onChange={(ev) => {
              setStreet(ev.target.value)
            }}
            error={showWarnings && isEmpty(street?.trim())}
            value={street}
          />
        </Grid>
        <Grid item className={classes.bottomInputItems}>
          <TextFieldLabel
            label="City:"
            onChange={(ev) => {
              setCity(ev.target.value)
            }}
            error={showWarnings && isEmpty(city.trim())}
            value={city}
          />
        </Grid>
        <Grid item className={classes.bottomInputItems}>
          <Autocomplete
            label="State:"
            onChange={(value: any) => {
              setState(value.key)
            }}
            value={
              !isEmpty(states) && !isEmpty(state)
                ? getSelectedAsOption(
                    states.find((opt) => opt?.abbreviation === state) ||
                      canadaStates.find((opt) => opt?.abbreviation === state)
                  )
                : null
            }
            error={showWarnings && !state}
            options={
              country !== 'Canada'
                ? getStatesOptionList()
                : getStatesOptionListCanada()
            }
          />
        </Grid>
        <Grid item className={classes.bottomInputItems}>
          <TextFieldLabel
            type={country !== 'Canada' ? 'number' : undefined}
            label="ZIP Code:"
            error={showWarnings && (isEmpty(zipCode) || !isValidZIPCode())}
            onChange={(ev) => {
              setZipCode(
                ev.target.value.length < zipCodeMaxLength
                  ? ev.target.value
                  : zipCode
              )
            }}
            value={zipCode}
          />
        </Grid>
        <Grid item className={classes.bottomInputItems}>
          <Autocomplete
            label="Country:"
            onChange={handleChangeCountry}
            value={country ? getCountrySelectedAsOption(country) : null}
            error={showWarnings && !isValidCountry()}
            options={getCountriesOptionList()}
            placeholder="Select your country"
          />
          {/* <TextFieldLabel
            error={false}
            label='Country:'
            onChange={(event)=> setCountry(event.target.value)}
            value={country}
          /> */}
        </Grid>
      </Grid>

      {/*      {user.clientType === USER_TYPE.BROKER && <Grid container spacing={1} className={classes.narContent}>
        <Grid item xs={3} className={classes.container}>
          <img src={narAgent} alt='NSR Agent' style={{ width: '20px' }} />
          <Typography className={classes.subtitle}>Are you a NAR Agent?</Typography>
        </Grid>
        <Grid item xs={11} className={clsx(classes.line, classes.container, showWarnings && nar === null ? classes.error : classes.borderWarning)}>
          {subOptions.map((item, index) => (
            <Grid container item xs={2} key={index}
              className={clsx(classes.line, classes.content, nar === item.id ? classes.selected : classes.border)}
              onClick={() => handleSelectNar(item.id)}>
              <Grid item>
                <Box className={classes.titleContainer}>
                  <Radio
                    color="primary"
                    disableRipple
                    checked={nar === item.id}
                    style={{ backgroundColor: 'transparent' }}
                    classes={{ checked: classes['Mui-checked'] }}
                  />
                  <Typography variant='body1' className={classes.title}>{item.title}</Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
          {nar && <Grid item className={classes.line}>
            <TextFieldLabel
              label=''
              onChange={(ev) => { setMemberId(ev.target.value) }}
              value={memberId ?? ''}
              placeholder='NAR Member ID'
              className={classes.line}
            /></Grid>}
        </Grid>
      </Grid>} */}

      <Box className={classes.submitContainer}>
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={!changesToApply}
        >
          {updating ? (
            <CircularProgress className={classes.spinner} size={24} />
          ) : (
            'Save Changes'
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default UserInfo
