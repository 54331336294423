import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            rowGap: "16px",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid #EBECEF",
            boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
            [theme.breakpoints.down('md')]: {
                maxWidth: "100vw",
                paddingBottom: "24px"
            },
        },
        Address: {
            fontSize: "20px",
            fontFamily: 'Lato'
        },
        Icon: {
            color: "var(--bosscat-blue-600)",
            width: "1rem",
            marginRight: "10px"
        },
        City: {
            color: "#494A5A",
            fontSize: "16px",
            fontFamily: "LatoNormal"
        },
        Name: {
            fontFamily: "LatoNormal"
        },
        DateIcon: {
            width: "48px",
            height: "48px",
            marginRight: "8px",
            [theme.breakpoints.down('md')]: {
                width: "32px",
                height: "32px",
            },
        },
        DateLabel: {
            fontSize: "16px",
            fontFamily: 'Lato',
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
            },
        },
        DateValue: {
            fontSize: "16px",
            color: "#494A5A",
            fontFamily: "LatoNormal",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
            },
        },
        normal: {
            fontFamily: 'LatoNormal'
        },
        ButtonsMobile: {
            "@media screen and (max-width: 350px)": {
                flexWrap: "wrap",
                rowGap: "16px"
            },
        },
        Support: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            backgroundColor: "#FAFBFF",
            padding: "16px",
            color: "var(--bosscat-blue-600)",
            cursor: "pointer",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
                margin: "0px 4px",
                textDecoration: "none"
            },
        },
        TrackingText: {
            color: "var(--bosscat-blue-600)",
            textDecoration: "underline",
            fontFamily: 'Lato'
        },
        TrackingTextMobile: {
            color: "var(--bosscat-blue-600)",
            whiteSpace: "nowrap",
        },
        Tracking: {
            width: "auto",
            marginLeft: "4px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center"
        },
        Arrow: {
            transform: "rotate(45deg)",
            color: "var(--bosscat-blue-600)",
            width: '1rem'
        }
    }))
