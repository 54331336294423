import { authTypes } from 'ducks/auth/types'
import { Order } from 'ducks/types'
import { OrderActions, OrderType, orderTypes } from './types'

const initialState: OrderType = null

const reducer = (state = initialState, action: OrderActions): OrderType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case orderTypes.SET_ORDER: {
      return payload as Order
    }

    default:
      return state
  }
}

export default reducer
