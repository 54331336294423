import { FetchEmployeesParams } from 'api/punchlist/employees/types'
import { SubscriptionsPlan } from 'ducks/types'
import { CustomerSuccessManagerType, CheckUserVipParams, FetchSubscriptionsParams, RequestScheduleServiceParams, SubscriptionsActions, subscriptionsTypes, SubscriptionsValue, UpdateSubscriptionPlanParams } from './types'

const actions = {
  fetchSubscriptions: (
    payload: FetchSubscriptionsParams,
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_SUBSCRIPTIONS,
    payload,
    callback
  }),
  fetchEmployees: (
    payload: FetchEmployeesParams,
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_EMPLOYEES,
    payload,
    callback
  }),
  fetchSubscriptionsPlans: (
    callback?: (succ: boolean, plans: SubscriptionsPlan[] | null) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_SUBSCRIPTIONS_PLANS,
    payload: null,
    callback
  }),
  fetchSubscriptionsDeliveryDate: (
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.FETCH_SUBSCRIPTIONS_DELIVERY_DATE,
    payload: null,
    callback
  }),
  checkUserVIP: (
    payload: CheckUserVipParams,
    callback?: (succ: boolean, isVip?: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.CHECK_USER_VIP,
    payload,
    callback
  }),
  requestScheduleServicePlan: (
    payload: RequestScheduleServiceParams,
    callback?: (succ: boolean) => void
  ): SubscriptionsActions => ({
    type: subscriptionsTypes.REQUEST_SCHEDULE_SERVICE,
    payload,
    callback
  }),
  setSubscriptionsValue: (payload: SubscriptionsValue): SubscriptionsActions => ({
    type: subscriptionsTypes.SET_SUBSCRIPTIONS_VALUE,
    payload
  }),
  setCSMValue: (payload: CustomerSuccessManagerType): SubscriptionsActions => ({
    type: subscriptionsTypes.SET_CSM_VALUE,
    payload
  }),
}

export default actions
