import { Payment } from 'ducks/types'
import { paymentTypes, PaymentActions } from './types'

const actions = {
  fetchPayment: (
    payload?: string,
    callback?: (succ: boolean) => void
  ): PaymentActions => ({
    type: paymentTypes.FETCH_PAYMENT,
    payload,
    callback
  }),
  setPayment: (payload: Payment): PaymentActions => ({
    type: paymentTypes.SET_PAYMENT,
    payload
  }),
}

export default actions
