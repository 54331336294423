import { FC, useEffect, useState } from 'react'
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Box,
  TextFieldLabel,
  Grid,
  FormControlLabel,
  Checkbox,
} from '../../../../../../../../UI'
import useStyles from './styles'
import RemoveContactModalProps from './types'
import {
  CONTACT_ROLE,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  isEmpty,
  validateEmail,
} from 'helpers/index'
import { getNewEstimateValue, isAuthorized } from 'ducks/selectors'
import { useSelector } from 'react-redux'

const ModalAddContact: FC<RemoveContactModalProps> = ({
  open,
  setOpen,
  onAddContact,
  contactToEdit,
  index,
  contactsEmails,
}) => {
  const classes = useStyles()
  const contacts = useSelector(getNewEstimateValue('contacts'))

  const [showWarnings, setShowWarnings] = useState(false)
  const [newContact, setNewContact] = useState({
    ...contactToEdit,
    firstName: contactToEdit?.firstName || '',
    lastName: contactToEdit?.lastName || '',
    email: contactToEdit?.email || '',
    phone: contactToEdit?.phone || '',
    role: contactToEdit?.role || CONTACT_ROLE.HOMEOWNER,
    index: contactToEdit?.index || contacts?.length || 0,
    sendEstimate: true,
  })
  const clientType = useSelector(getNewEstimateValue('clientType'))
  const isOther = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)
  const authorized = useSelector(isAuthorized)

  const validate =
    !isEmpty(newContact.firstName) &&
    !isEmpty(newContact.lastName) &&
    !isEmpty(newContact.email) &&
    validateEmail(newContact.email || '') &&
    !isEmpty(newContact.phone) &&
    PHONE_MASK_REGEXP.test(newContact.phone || '') &&
    !isEmpty(newContact.role) &&
    (contactToEdit || !contactsEmails?.includes(newContact.email))

  const handleClose = () => {
    setOpen(false)
    //onSubmit()
  }

  const handleAddContact = () => {
    if (validate) {
      onAddContact(newContact)
      handleClose()
    } else {
      setShowWarnings(true)
    }
  }
  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography className={classes.title}>
          Client #{index || 0 + 1}
        </Typography>
      </DialogContent>

      <DialogContent>
        <Grid container direction="column" style={{ gap: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel
                label="First Name"
                type="text"
                value={newContact.firstName || ''}
                error={showWarnings && isEmpty(newContact.firstName)}
                onChange={(event) =>
                  setNewContact({
                    ...newContact,
                    firstName: event.target.value,
                  })
                }
                placeholder="First Name"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel
                label="Last Name"
                type="text"
                value={newContact.lastName || ''}
                error={showWarnings && isEmpty(newContact.lastName)}
                onChange={(event) =>
                  setNewContact({ ...newContact, lastName: event.target.value })
                }
                placeholder="Last Name"
                className={classes.input}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextFieldLabel
                label="Email"
                type="email"
                value={newContact.email || ''}
                error={
                  showWarnings &&
                  (isEmpty(newContact.email) ||
                    !validateEmail(newContact.email || ''))
                }
                onChange={(event) => {
                  setNewContact({ ...newContact, email: event.target.value })
                }}
                placeholder="Email"
                disabled={!isOther && !isEmpty(newContact.email) && authorized}
              />
              {!contactToEdit &&
                contactsEmails &&
                contactsEmails?.includes(newContact.email) && (
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="body2" style={{ color: 'red' }}>
                        This email already exist in the estimate contacts
                      </Typography>
                    </Box>
                  </Grid>
                )}
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextFieldLabel
                label="Mobile Phone Number"
                type="tel"
                value={newContact.phone || ''}
                mask={PHONE_MASK_INPUT}
                error={showWarnings && isEmpty(newContact.phone)}
                onChange={(event) =>
                  setNewContact({ ...newContact, phone: event.target.value })
                }
                placeholder="555-555-1234"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body1" className={classes.inputTitle}>
                Role
              </Typography>
            </Grid>
            <Grid item container style={{ gap: '1rem', marginTop: '.5rem' }}>
              <FormControlLabel
                style={
                  newContact.role === CONTACT_ROLE.HOMEOWNER
                    ? { borderColor: 'var(--bosscat-blue-600)' }
                    : {}
                }
                control={
                  <Checkbox
                    checked={newContact.role === CONTACT_ROLE.HOMEOWNER}
                    onChange={() =>
                      setNewContact({
                        ...newContact,
                        role: CONTACT_ROLE.HOMEOWNER,
                      })
                    }
                    name="remember"
                    color="primary"
                  />
                }
                value={CONTACT_ROLE.HOMEOWNER}
                label={<Typography variant="body2">Homeowner</Typography>}
                className={classes.check}
              />
              <FormControlLabel
                style={
                  newContact.role === CONTACT_ROLE.HOME_BUYER
                    ? { borderColor: 'var(--bosscat-blue-600)' }
                    : {}
                }
                control={
                  <Checkbox
                    checked={newContact.role === CONTACT_ROLE.HOME_BUYER}
                    onChange={() =>
                      setNewContact({
                        ...newContact,
                        role: CONTACT_ROLE.HOME_BUYER,
                      })
                    }
                    name="remember"
                    color="primary"
                  />
                }
                value={CONTACT_ROLE.HOME_BUYER}
                label={<Typography variant="body2">Homebuyer</Typography>}
                className={classes.check}
              />
              <FormControlLabel
                style={
                  newContact.role === CONTACT_ROLE.OTHER
                    ? { borderColor: 'var(--bosscat-blue-600)' }
                    : {}
                }
                control={
                  <Checkbox
                    checked={newContact.role === CONTACT_ROLE.OTHER}
                    onChange={() =>
                      setNewContact({ ...newContact, role: CONTACT_ROLE.OTHER })
                    }
                    name="remember"
                    color="primary"
                  />
                }
                value={CONTACT_ROLE.OTHER}
                label={<Typography variant="body2">Other</Typography>}
                className={classes.check}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent className={classes.submitContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.buttonClose}
          fullWidth
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.buttonAdd}
          fullWidth
          onClick={handleAddContact}
        >
          {contactToEdit ? 'Save Contact' : 'Add Contact'}
        </Button>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default ModalAddContact
