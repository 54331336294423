import { Box, Grid, Typography } from '@material-ui/core'
import { coupleComputer, faqQC, homeEntrance, melloHomeBg } from 'assets/index'
import { newOrderMaintenanceActions, subscriptionsActions } from 'ducks/actions'
import { CONTACT_ROLE } from 'helpers/constants'
import { history } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import Banner from './components/Banner'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import MellohomeHeader from './components/Header'
import Renovations from './components/Renovations'
import useStyles from './styles'
import { getNewOrderMaintenanceValue, getTerritory } from 'ducks/selectors'
import { territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'
import { useCallback, useEffect, useState } from 'react'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import OutOfAreaModal from './components/OutOfAreaModal'
import { PERKS } from 'helpers/index'
import PerkBox from 'components/pages/Desktop/OrderMaintenance/Dashboard/PerksModal/PerkBox'
import UtmParamsWrapper from 'components/pages/Auth/UtmParamsWrapper'
import { MaintenanceCardSeason } from '../../Maintenance/Steps/Onboarding'
import {
  FallIcon,
  SpringIcon,
  SummerIcon,
  WinterIcon,
} from '../../Maintenance/Steps/Onboarding/components/icons'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const MellohomeLandingEstimate = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const [openAModal, setOpenModal] = useState(false)
  const query = history.useQuery()
  const source = query.get('utm_source') || query.get('utmsource')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')
  const affiliate = query.get('affiliate')
  const address = useSelector(getNewOrderMaintenanceValue('propertyAddress'))

  const territory = useSelector(getTerritory())

  const cityIsAvailableForMaintenance = useSelector(
    territoryIsAvailableForMaintenance(territory?.id)
  )

  const fetchPlans = useCallback(() => {
    dispatch(
      subscriptionsActions.fetchSubscriptionsPlans((fetchSuccess, plans) => {
        if (fetchSuccess) {
          if (plans?.length) {
            const plan = plans?.[0]
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValues({
                attrs: {
                  services: plan?.services,
                  protectPlan: plan?.protectPlan,
                  title: plan?.title,
                  price: plan?.price,
                  stripeProductPriceId: plan?.stripeProductPriceId,
                  servicePeriodMonths: plan?.servicePeriodMonths,
                },
              })
            )
          }
        }
      })
    )
  }, [dispatch])

  useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  const handleSubmitForm = (data: any) => {
    if (data?.zipCode && territory?.title) {
      const showModal = data?.zipCode && !cityIsAvailableForMaintenance
      if (showModal) {
        dispatch(
          newOrderMaintenanceActions.setNewOrderMaintenanceValue({
            attr: 'propertyAddress',
            value: data.address,
          })
        )
        return setOpenModal(true)
      }
    }

    if (!cityIsAvailableForMaintenance) return
    dispatch(
      subscriptionsActions.checkUserVIP(
        { email: data.email },
        (succ, isVip) => {
          if (succ) {
            if (isVip) {
            } else {
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'marketingProperties',
                  value: {
                    source: source || '',
                    campaign,
                    medium,
                    content,
                    promo: data.promoCode,
                  },
                })
              )
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'propertyAddress',
                  value: data.address,
                })
              )
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'homeowner',
                  value: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: '',
                    email: [
                      {
                        id: null,
                        emailType: 'PRIMARY',
                        email: data.email,
                        name: null,
                        description: null,
                      },
                    ],
                    clientType: CONTACT_ROLE.HOMEOWNER,
                  },
                })
              )
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'purchaser',
                  value: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: '',
                    email: [
                      {
                        id: null,
                        emailType: 'PRIMARY',
                        email: data.email,
                        name: null,
                        description: null,
                      },
                    ],
                    address: data.address,
                    clientType: CONTACT_ROLE.HOMEOWNER,
                    affiliate: affiliate || '',
                  },
                })
              )
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'giftBox',
                  value: {
                    shippingName: `${data.firstName} ${data.lastName}`,
                    shippingAddress: data.address,
                    shippingPhone: '',
                  },
                })
              )

              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'step',
                  value: 0,
                })
              )
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'mainContact',
                  value: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: data.phone,
                    clientType: CONTACT_ROLE.HOMEOWNER,
                    role: CONTACT_ROLE.HOMEOWNER,
                  },
                })
              )
              push('maintenance')
            }
          }
        }
      )
    )
  }

  return (
    <UtmParamsWrapper>
      <Modal open={openAModal} setOpen={setOpenModal} size="lg">
        <OutOfAreaModal setOpen={setOpenModal} />
      </Modal>
      <MellohomeHeader />
      {/* <div style={{ width: "100%", paddingInline: "24px", marginTop: "24px" }}>
        <HolidayBanner />
      </div> */}
      <ContactForm
        title="Purchase Home Maintenance Membership"
        onSubmit={handleSubmitForm}
      />
      <Renovations />
      <Box className={classes.perksContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MaintenanceCardSeason
              title="spring"
              data={
                PERKS.find((perk) => perk.title === 'Spring')?.bullets?.map(
                  (bullet) => bullet
                ) || []
              }
              backgroundColor="#E6F7EE"
              icon={<SpringIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaintenanceCardSeason
              title="summer"
              data={
                PERKS.find((perk) => perk.title === 'Summer')?.bullets?.map(
                  (bullet) => bullet
                ) || []
              }
              backgroundColor="#FFF9EA"
              icon={<SummerIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaintenanceCardSeason
              title="fall"
              data={
                PERKS.find((perk) => perk.title === 'Fall')?.bullets?.map(
                  (bullet) => bullet
                ) || []
              }
              backgroundColor="#F6F5EF"
              icon={<FallIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaintenanceCardSeason
              title="winter"
              data={
                PERKS.find((perk) => perk.title === 'Winter')?.bullets?.map(
                  (bullet) => bullet
                ) || []
              }
              backgroundColor="#EAF2FE"
              icon={<WinterIcon />}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography className={classes.disclaimer}>
        The scope of the maintenance services is intended to cover customary
        equipment and costs; any equipment or material that is not customary may
        not be covered or may require additional fees.
      </Typography>
      {/* <Banner
        title={
          <Typography >
            Special gift from mellohome when you sign up for a home
            maintenance membership
          </Typography>
        }
        imgSrc={melloHomeBg}
        padding="5rem"
        direction="row"
      /> */}
      <Footer />
    </UtmParamsWrapper>
  )
}

export default MellohomeLandingEstimate
