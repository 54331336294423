import { Typography } from '@material-ui/core'
import { icons } from 'assets'
import { Partner } from 'ducks/types'
import useStyles from './styles'
import React from 'react'
import { Tag } from 'components/UI'
import { TrackGoogleAnalyticsEvent } from 'helpers/google-analytics'

import { useSelector } from 'react-redux'
import { getUser, getUserPrimaryEmail } from 'ducks/selectors'


type Props = {
    partner: Partner
}

const HomeMartCard = ({ partner }: Props) => {
    const [cardHover, setCardHover] = React.useState(false)
    const classes = useStyles()
    const user = useSelector(getUser)

    const userPrimaryEmail = useSelector(getUserPrimaryEmail)

    const handleClick = () => {
        TrackGoogleAnalyticsEvent({
            category: "HomeMart",
            action: "homemart_partner_access_link",
            options: {
                email: userPrimaryEmail,
                partnerId: partner.id,
                partnerName: partner.readableCompanyName
            }
        })
    }

    return (
        <div className={classes.container} onMouseEnter={() => setCardHover(true)} onMouseLeave={() => setCardHover(false)}>
            <div className={classes.body}>
                <div className={classes.imageContainer}>
                    <img src={partner.titleImage} alt="Partner" className={`${classes.image} ${cardHover ? classes.imgHover : ''}`} />
                    {partner.discountVerbiage && <Tag type={'Neutral'} className={classes.tagContainer} >
                        <Typography>{partner.discountVerbiage}</Typography>
                    </Tag>}
                </div>
                <Typography className={classes.title}>
                    {partner.title}
                </Typography>
                <Typography className={classes.description}>
                    {partner.description}
                </Typography>

            </div>
            <div className={classes.footer}>
                <div>
                    <Typography>
                        Offered by:
                    </Typography>
                    <img className={classes.logo} src={partner.logo} alt="partner logo" />
                </div>
                <a className={classes.actionButton} href={partner.actionUrl} target="_blank" rel="noreferrer" onClick={
                    () => handleClick()
                }>
                    <Typography>
                        {partner.actionVerbiage}
                    </Typography>
                    <icons.ArrowForward />
                </a>
            </div>
        </div>
    )
}

export default HomeMartCard
