import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    imagePicker: {
      width: 96,
      height: 96,
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      borderWidth: 2,
      border: `2px solid #F5F6F7`,
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      boxSizing: 'content-box',
      flexDirection: 'column',
    },
    clickable: {
      cursor: 'pointer',
    },
    marginRight: {
      marginRight: 8,
    },
    icon: {
      fontSize: 30,
      color: theme.palette.grey[400],
    },
    hiddenInput: {
      display: 'none',
    },
    image: {
      display: 'flex',
      objectFit: 'cover',
      width: 96,
      height: 96,
      borderRadius: 8,
    },
    deleteButton: {
      position: 'absolute',
      top: 4,
      right: 4,
      width: 20,
      height: 20,
      backgroundColor: 'rgba(225, 225, 225, .5)',
      color: '#fff',
      borderRadius: 2,
      cursor: 'pointer',
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
    emptyText: {
      color: '#9D9B9F',
      fontFamily: 'LatoNormal',
      fontSize: 14,
    },
  })
)
