import { authTypes } from 'ducks/auth/types'
import { Subscription } from 'ducks/types'
import { SubscriptionActions, SubscriptionType, subscriptionTypes } from './types'

const initialState: SubscriptionType = null

const reducer = (state = initialState, action: SubscriptionActions): SubscriptionType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case subscriptionTypes.SET_SUBSCRIPTION: {
      return payload as Subscription
    }
    case subscriptionTypes.CREATE_SUBSCRIPTION: {
      return payload as Subscription
    }
    case subscriptionTypes.CLEAR_SUBSCRIPTION: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
