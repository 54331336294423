import { ClientQuestion, ClientQuestionsActions, clientQuestionsTypes } from "./types"

const actions = {
  createClientQuestion: (
    payload: ClientQuestion,
    callback?: (succ: boolean) => void
  ): ClientQuestionsActions => ({
    type: clientQuestionsTypes.CREATE_CLIENT_QUESTION,
    payload,
    callback
  })
}

export default actions
