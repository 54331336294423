import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '2px solid #CC9E27',
      padding: '.3rem .5rem .3rem 1rem',
      borderRadius: '8px',
      backgroundColor: '#FFF9EA',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    
    title: {
      fontFamily: 'LatoBold',
      fontSize: 18,
    },
    subtitle: {
      fontSize: 18,
    },
    button: {
      //   width: '185px',
      //   height: '58px',
      padding: '0 .8rem',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoBold',
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        // width: '135px',
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-800)',
      },
    },
  })
)
