import { Grid, Typography } from '@material-ui/core'
import { useStepperContext } from 'hooks/useStepperContext'
import { useSelector } from 'react-redux'
import { getApprovalValues } from 'ducks/selectors'
import useStyles from '../../styles'
import { TextFieldLabel } from 'components/UI'
import { PHONE_MASK_INPUT, validateEmail, validatePhone } from 'helpers/index'

const ListingAgentInformation = () => {
  const classes = useStyles()

  const { showWarnings, saveNewValue } = useStepperContext()
  const { listingAgent } = useSelector(getApprovalValues())

  return (
    <Grid
      style={{
        background: '#FCFCFC',
        borderRadius: '8px',
        padding: '1rem',
        boxShadow:
          '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      }}
    >
      <Typography className={classes.title}>listing agent</Typography>
      <Typography style={{marginBottom: '1rem'}}>
        In order to pay at close, we will first need to ask your listing agent
        to provide us the listing agreement and sign the agent acknowledgment.
        Please provide your listing agent’s contact information.
      </Typography>
      <Grid item container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextFieldLabel
            label="Company Name"
            value={listingAgent?.companyName || ''}
            error={showWarnings && !listingAgent?.companyName}
            onChange={(ev) =>
              saveNewValue('listingAgent', {
                ...listingAgent,
                companyName: ev.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="First Name"
            value={listingAgent?.firstName || ''}
            error={showWarnings && !listingAgent?.firstName}
            onChange={(ev) =>
              saveNewValue('listingAgent', {
                ...listingAgent,
                firstName: ev.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Last Name"
            value={listingAgent?.lastName || ''}
            error={showWarnings && !listingAgent?.lastName}
            onChange={(ev) =>
              saveNewValue('listingAgent', {
                ...listingAgent,
                lastName: ev.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Email"
            value={listingAgent?.email || ''}
            error={showWarnings && !validateEmail(listingAgent?.email || '')}
            onChange={(ev) =>
              saveNewValue('listingAgent', {
                ...listingAgent,
                email: ev.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Phone Number"
            value={listingAgent?.phone || ''}
            error={showWarnings && !validatePhone(listingAgent?.phone || '')}
            onChange={(ev) => {
              saveNewValue('listingAgent', {
                ...listingAgent,
                phone: ev.target.value,
              })
            }}
            mask={PHONE_MASK_INPUT}
          />
        </Grid>

        
      </Grid>
    </Grid>
  )
}
export default ListingAgentInformation