import { FC } from 'react'
import { PaymentOption } from '../../../../../ducks/types'
import Days from '../../atoms/Days'
import useStyles from './styles'
import { PaymentCalendarProps } from './types'

const PaymentCalendar: FC<PaymentCalendarProps> = ({ days, onClick }) => {
  const classes = useStyles()
  
  return (
    <div className={classes.row}>
      {days.map((item: PaymentOption, index: number) => {
        return (
          <Days
            key={index}
            title={item.title}
            date={item.day}
            subtitle={item.subtitle}
            enable={item.enable}
            best={item.best}
            price={item.price}
            onClickPay={
              item.enable && onClick ? () => onClick(item) : undefined
            }
          />
        )
      })}
    </div>
  )
}

export default PaymentCalendar
