import { formatTimestampDate, isPercentageOf, months, PAYMENT_RESOURCE_TYPE } from 'helpers'
import storeInfo from 'ducks/store'
import { Payment } from 'ducks/types'

const getTotal = (payments: Payment[]): number => {
  let total = 0
  const paymentsCopy = [...payments]
  paymentsCopy.forEach(payment => { total = total + payment.amount })
  return total
}

export const getPayments = () => (): Payment[] => {
  const { payments } = storeInfo.store.getState()
  if (!payments || !payments.length) return []
  return payments
}

export const getPaymentsByMonth = () => (): Payment[][] => {
  const { payments } = storeInfo.store.getState()
  if (!payments || !payments.length) return []

  const paymentsByMonth: Payment[][] = []

  months.forEach((_month, index) => {
    paymentsByMonth.push(payments.filter(payment => formatTimestampDate(payment.createdOn).getMonth() === index))
  })

  return paymentsByMonth
}

export const getPaymentById = (paymentId: string) => (): Payment | null => {
  const { payments } = storeInfo.store.getState()
  return payments.find((payment: Payment) => payment.id === paymentId) || null
}

export const getVerticalChartData = (): any => {
  const { payments } = storeInfo.store.getState()
  const paymentsByMonth: Payment[][] = []
  months.forEach((_month, index) => {
    paymentsByMonth.push(payments.filter(payment => formatTimestampDate(payment.createdOn).getMonth() === index))
  })

  const monthsPaymentAmount: number[] = []
  paymentsByMonth.forEach(payments => {
    const total = getTotal(payments)
    monthsPaymentAmount.push(total)
  })

  return {
    labels: months,
    datasets: [{ label: '', data: monthsPaymentAmount, backgroundColor: '#1CCD83', hoverOffset: 1 }]
  }
}

export const getPieChartData = (month: number) => (): any => {
  const { payments } = storeInfo.store.getState()
  const paymentsByMonth: Payment[][] = []
  months.forEach((_month, index) => {
    paymentsByMonth.push(payments.filter(payment => formatTimestampDate(payment.createdOn).getMonth() === index))
  })

  const newInfoChartByMonth = [{ price: 0, type: 'Jobs' }, { price: 0, type: 'Estimates' }]

  paymentsByMonth.forEach((payments, index) => {
    if (month === index) {
      payments.forEach(payment => {
        if (payment.resourceType === PAYMENT_RESOURCE_TYPE.ESTIMATE) {
          newInfoChartByMonth[1].price += payment.amount
        } else if (payment.resourceType === PAYMENT_RESOURCE_TYPE.JOB) {
          newInfoChartByMonth[0].price += payment.amount
        }
      })
    }
  })

  const chartPercentaje = [0, 0]
  const totalAmountOnThisMonth = newInfoChartByMonth[0].price + newInfoChartByMonth[1].price
  chartPercentaje[0] = isPercentageOf(newInfoChartByMonth[0].price, totalAmountOnThisMonth)
  chartPercentaje[1] = isPercentageOf(newInfoChartByMonth[1].price, totalAmountOnThisMonth)

  return {
    labels: [],
    datasets: [{ label: '', backgroundColor: ['#FFC633', 'var(--bosscat-blue-600)'], hoverOffset: 1, data: chartPercentaje }]
  }
}

export const getInfoChartByMonth = (month: number) => (): any => {
  const { payments } = storeInfo.store.getState()
  const paymentsByMonth: Payment[][] = []
  months.forEach((_month, index) => {
    paymentsByMonth.push(payments.filter(payment => formatTimestampDate(payment.createdOn).getMonth() === index))
  })

  const newInfoChartByMonth = [{ price: 0, type: 'Jobs' }, { price: 0, type: 'Estimates' }]

  paymentsByMonth.forEach((payments, index) => {
    if (month === index) {
      payments.forEach(payment => {
        if (payment.resourceType === PAYMENT_RESOURCE_TYPE.ESTIMATE) {
          newInfoChartByMonth[1].price += payment.amount
        } else if (payment.resourceType === PAYMENT_RESOURCE_TYPE.JOB) {
          newInfoChartByMonth[0].price += payment.amount
        }
      })
    }
  })

  const chartPercentaje = [0, 0]
  const totalAmountOnThisMonth = newInfoChartByMonth[0].price + newInfoChartByMonth[1].price
  chartPercentaje[0] = isPercentageOf(newInfoChartByMonth[0].price, totalAmountOnThisMonth)
  chartPercentaje[1] = isPercentageOf(newInfoChartByMonth[1].price, totalAmountOnThisMonth)

  return newInfoChartByMonth
}
