import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    rowItem: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'var(--row-background)',
      padding: '16px',
      marginTop: '2px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 8px'
      }
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginRight: '100px'
      }
    },
    col: {
      display: 'flex',
      flexDirection: 'column'
    },
    boxRepair: {
      width: '100%',
      height: '56px',
      margin: '-6px 20px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '-6px 8px 0px 0px'
      }
    },
    comboRepair: {
      width: '100%',
      height: '56px',
      margin: '6px 0',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    comboQuantity: {
      width: '165px',
      height: '56px',
      padding: '0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    tip: {
      color: 'var(--gray-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      margin: '8px 0px 16px 24px',
      justifySelf: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '8px 44px'
      }
    },
    title: {
      color: 'var(--input-color)',
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '8px 0px',
      justifySelf: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '12px 0px 8px 0px'
      }
    },
    icon: {
      width: '15px',
      height: '15px'
    },
    requestedRepairNote: {
      width: '100%',
      height: '80px',
      padding: '0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    line2: {
      display: 'flex',
      flexDirection: 'column',
      margin: '6px 20px 6px 20px',
      width: '2px',
      backgroundColor: 'var(--line-color)'
    },
    add: {
      width: '220px',
      height: '44px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '44px 0px 0px auto',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('lg')]: {
        width: '135px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '20px 0px 0px 0px'
      }
    },
    close: {
      width: '220px',
      height: '44px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      margin: '16px 0px',
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('lg')]: {
        width: '135px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '16px 0px'
      }
    },
    inputError: {
      borderColor: 'var(--expired-color) !important'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    searcherIcon: {
      color: theme.palette.grey[400],
      cursor: 'default'
    }
  })
)
