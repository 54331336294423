import { FC, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { DesktopPages, PagesViews, UserPages } from '../components/pages'
import {
  Navigation,
  OverlayLoader,
  PunchlistLoader
} from '../components/templates'
import {
  authActions,
  configActions,
  newEstimateActions
} from '../ducks/actions'
import {
  getConfigValue,
  getNewEstimateValue,
  getNewOrderMaintenanceValue,
  isAuthorized,
  isRegisterCompleted
} from '../ducks/selectors'
import { history } from '../helpers'
import { RoutesType } from './types'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'


const PrivateRoute: FC<RoutesType> = ({ location, children, showMenu = true }) => {
  const dispatch = useDispatch()
  const replace = history.useReplace()
  const authorized = useSelector(isAuthorized)
  const registerCompleted = useSelector(isRegisterCompleted)
  const overlayLoading = useSelector(getConfigValue('overlayLoading'))
  const isNar = useSelector(getNewEstimateValue('isNar'))
  const affiliate = useSelector(getNewEstimateValue('affiliate'))
  const loc = useLocation()
  const redirectPath = useSelector(getConfigValue('redirectPath'))

  useGoogleAnalytics()

  const redirectNewEstimate = useSelector(
    getNewEstimateValue('redirectAfterLogin')
  )

  const redirectNewMaintenance = useSelector(
    getNewOrderMaintenanceValue('redirectAfterLogin')
  )

  const pathname = location?.pathname
  const isRegister = pathname === '/u/register'
  /*
    const Router = isDesktop ? PrivateDesktopRouter() : PrivateUserRouter()
  
    useEffect(() => {
      dispatch(
        configActions.setConfigValue({ type: 'overlayLoading', value: false })
      )
    }, [dispatch])
   */
  if (!authorized) {

    if (loc?.pathname && loc?.pathname !== '/u/register') {
      dispatch(configActions.setConfigValue({ type: 'redirectPath', value: loc?.pathname }))
    }
    dispatch(authActions.logoutRequest({ callback: (success) => { }, }))
    return <Redirect to='/login' from={loc.pathname} />
  }

  if (authorized) {
    // add userIsLoggedIn to newEstimate so we can clean the state when user was logged in and token expired
    dispatch(newEstimateActions.setNewEstimateValue({ attr: 'userIsloggedIn', value: authorized }))
  }

  if (redirectNewEstimate) {
    return <Redirect to={`/estimate?${isNar ? 'nar=true' : ''}${affiliate ? `affiliate=${affiliate}` : ''} `} />
  }
  if (redirectNewMaintenance) {
    return <Redirect to={`/maintenance`} />
  }
  if (registerCompleted && isRegister) {
    // If the client was following a link, after registering lets send them back to that link
    if (redirectPath && redirectPath !== "/p/docusignSuccess") {
      return <Redirect to={redirectPath} />
    }
    return <Redirect to='/' />
  }
  if (!registerCompleted && !isRegister && loc?.pathname !== '/u/register') {
    return <Redirect to="/u/register" />
  }

  if (showMenu) {
    return (
      <Suspense fallback={<PunchlistLoader />}>
        <Navigation showEstimateOptions={registerCompleted}>
          <OverlayLoader loading={overlayLoading}>
            {children}
          </OverlayLoader>
        </Navigation>
      </Suspense>
    )
  } else {
    return (
      <Suspense fallback={<PunchlistLoader />}>
        <OverlayLoader loading={overlayLoading}>
          {children}
        </OverlayLoader>
      </Suspense>
    )
  }
}

export default PrivateRoute
