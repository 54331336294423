import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import moment from 'moment'
import { orderBy } from 'lodash'

import {
  Grid,
  Box,
  Typography,
  Button,
  VerticalChart,
  PieChart,
  ZeroResultView,
  LowerMonthController,
  UpperMonthController
} from 'components/UI'
import { DesktopPage, PrivateLoader } from 'components/templates'

import {
  formatTimestampDate,
  getPaymentsTopLevelOptions,
  months,
  round
} from 'helpers'

import { configActions, paymentsActions } from 'ducks/actions'
import {
  getConfigValue,
  getPaymentsByMonth,
  getVerticalChartData,
  getPieChartData,
  getInfoChartByMonth
} from 'ducks/selectors'
import { Payment } from 'ducks/types'

import PaymentItem from './PaymentItem'
import PaymentHeader from './PaymentHeader'
import { useIsMdScreen } from 'hooks'

import useStyles from './styles'

const Payments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const thisYear = parseInt(moment().format('YYYY'))

  const statusOptions = getPaymentsTopLevelOptions()

  const paymentsYear = useSelector(getConfigValue('paymentsYear'))
  const paymentsFilter = useSelector(getConfigValue('paymentsFilter'))
  const initialYear = paymentsYear ?? (thisYear as number)
  const initialFilter = paymentsFilter ?? statusOptions[0]

  const [pageLoading, setPageLoading] = useState(!paymentsYear)
  const [dataLoading, setDataLoading] = useState(false)
  const [year, setYear] = useState<number>(initialYear)
  const [status, setStatus] = useState<any>(initialFilter)
  const [month, setMonth] = useState(0)

  const paymentsByMonth = useSelector(getPaymentsByMonth())
  const chartData = useSelector(getVerticalChartData)
  const [verticalChartData, setVerticalChartData] = useState(chartData)
  const pieChartData = useSelector(getPieChartData(month))
  const infoChartByMonth = useSelector(getInfoChartByMonth(month))

  const currBreakpoint = useIsMdScreen()[1]

  const { push } = useHistory()

  const getTotal = (payments: Payment[]): string => {
    let total = 0
    const paymentsCopy = [...payments]
    paymentsCopy.forEach(payment => {
      total = total + payment.amount
    })
    return round(Number(total), 2)
  }

  const fetchPayments = useCallback(() => {
    setDataLoading(true)
    dispatch(
      paymentsActions.fetchPayments(year.toString(), (_succ: boolean) => {
        setPageLoading(false)
        setDataLoading(false)
      })
    )
  }, [dispatch, year])

  useEffect(() => {
    fetchPayments()
    dispatch(
      configActions.setConfigValue({
        type: 'paymentsYear',
        value: year
      })
    )
  }, [dispatch, fetchPayments, year])

  useEffect(() => {
    dispatch(
      configActions.setConfigValue({
        type: 'paymentsFilter',
        value: status
      })
    )
  }, [dispatch, status])

  useEffect(() => {
    setVerticalChartData(chartData)
  }, [chartData])

  const changeChartData = useCallback(
    data => {
      setVerticalChartData(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currBreakpoint]
  )

  // * Filter by year - labels of months in the charts
  useEffect(() => {
    if (
      // * Small screens change labels of months on the chart to numbers
      ['xs', 'sm'].includes(currBreakpoint) &&
      verticalChartData.labels[0] !== '01'
    ) {
      const copy = { ...verticalChartData }
      copy.labels = copy.labels.map((_: any, index: number) => {
        if ((index + 1).toString().length === 1) {
          return `0${index + 1}`
        }
        return `${index + 1}`
      })
      changeChartData(copy)
    } else if (
      // * Large screens change labels of months on the chart to names
      ['md', 'lg', 'xl'].includes(currBreakpoint) &&
      verticalChartData.labels[0] !== months[0]
    ) {
      const copy = { ...verticalChartData }
      copy.labels = copy.labels.map((_: any, index: number) => months[index])
      changeChartData(copy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currBreakpoint])

  const getNoResultTitleByYear = () => {
    return 'Your payment history'
  }

  const getNoResultDescriptionByYear = () => {
    return (
      <p className={classes.descriptionEmptyState}>
        Here you’ll find all payments you’ve made.
        <br />
        Let's start by requesting your first{' '}
        <b className={classes.descriptionEmptyStateSpecialWord}>Inspection Report Estimate </b>
        <br />
        or{' '}
        <b className={classes.descriptionEmptyStateSpecialWord}>Project List Estimate.</b>
      </p>
    )
  }

  return (
    <DesktopPage title='Payments'>
      <Box paddingX={2} display="flex" justifyContent="center" height="100%">
        <PrivateLoader loading={pageLoading || dataLoading}>
          <Grid container spacing={1}>
            <PaymentHeader
              setStatus={setStatus}
              setYear={setYear}
              status={status}
              statusOptions={statusOptions}
              year={year}
            />
            <>
              {status.value === 'By Month' && (
                <>
                  {['md', 'lg', 'xl'].includes(currBreakpoint) && (
                    <UpperMonthController month={month} setMonth={setMonth} />
                  )}

                  {paymentsByMonth[month]?.length > 1 && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      className={classes.chartRow}
                    >
                      <Grid item xs={10} md={3}>
                        <Box className={classes.chart}>
                          <PieChart data={pieChartData} title='' />
                        </Box>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        className={classes.details}
                      >
                        {infoChartByMonth &&
                          infoChartByMonth.map(
                            (
                              item: { price: number; type: string },
                              index: number
                            ) => (
                              <Grid
                                container
                                item
                                xs={12}
                                key={index}
                                className={classes.percent}
                              >
                                <Box
                                  className={classes.percentMark}
                                  style={{
                                    backgroundColor:
                                      pieChartData.datasets[0].backgroundColor[
                                      index
                                      ]
                                  }}
                                />
                                <Typography className={classes.itemType}>
                                  {item.type + ': '}
                                </Typography>
                                <Typography className={classes.number}>
                                  {'$' + round(item.price, 2)}
                                </Typography>
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Grid>
                  )}

                  {['xs', 'sm'].includes(currBreakpoint) && (
                    <LowerMonthController month={month} setMonth={setMonth} />
                  )}

                  <Grid container item xs={12}>
                    <Typography className={classes.itemType}>
                      Transaction History
                    </Typography>
                    {paymentsByMonth[month] &&
                      paymentsByMonth[month].map((payment, index) => {
                        return (
                          <PaymentItem
                            key={index}
                            item={payment}
                            index={index}
                            className={
                              index < paymentsByMonth[month].length - 1
                                ? classes.bottomSeparation
                                : undefined
                            }
                          />
                        )
                      })}
                  </Grid>
                  {!paymentsByMonth[month]?.length && (
                    <Box className={classes.containerNoResultByMonth}>
                      <ZeroResultView
                        title={getNoResultTitleByYear()}
                        description={getNoResultDescriptionByYear()}
                        descriptionClassName={classes.noResultDescription}
                        customButtonRender={
                          <Box className={classes.noResultButtonsContainer}>
                            <Button
                              variant='contained'
                              type='submit'
                              className={classes.noResultViewButton}
                              onClick={() => push('estimate')}
                            >
                              Get an Estimate
                            </Button>
                          </Box>
                        }
                      />
                    </Box>
                  )}
                </>
              )}

              {status.value === 'By Year' && (
                <>
                  <Grid item xs={12}>
                    <Box className={classes.chart}>
                      <VerticalChart data={verticalChartData} title='' />
                    </Box>
                  </Grid>

                  <Grid container item xs={12}>
                    <Typography className={classes.itemType}>
                      Transaction History
                    </Typography>
                    {paymentsByMonth &&
                      paymentsByMonth.reverse().map((payments, index) => {
                        return payments.length > 0
                          ? (
                            <Grid container item xs={12} key={index}>
                              <Grid item xs={12} className={classes.itemTitle}>
                                <Typography className={classes.monthTitle}>
                                  {months[
                                    formatTimestampDate(
                                      payments[0].createdOn
                                    ).getMonth()
                                  ] +
                                    ' ' +
                                    year}
                                </Typography>
                                <Typography className={classes.monthTitle}>
                                  {'$ ' + getTotal(payments)}
                                </Typography>
                              </Grid>
                              {payments &&
                                orderBy(payments, 'createdOn', 'desc').map(
                                  (payment, index) => (
                                    <PaymentItem
                                      key={index}
                                      item={payment}
                                      index={index}
                                      className={
                                        index < payments.length - 1
                                          ? classes.bottomSeparation
                                          : undefined
                                      }
                                    />
                                  )
                                )}
                            </Grid>
                          )
                          : null
                      })}
                  </Grid>
                  {paymentsByMonth.length < 1 && (
                    <Box className={classes.emptyStateContainer}>
                      <ZeroResultView
                        title={getNoResultTitleByYear()}
                        description={getNoResultDescriptionByYear()}
                        descriptionClassName={classes.noResultDescription}
                        customButtonRender={
                          <Box className={classes.noResultButtonsContainer}>
                            <Button
                              variant='contained'
                              type='submit'
                              className={classes.noResultViewButton}
                              onClick={() => push('/estimate')}
                            >
                              Get an Estimate
                            </Button>
                            {/* <Button
                                variant='outlined'
                                type='submit'
                                className={classes.noResultViewButtonAlt}
                                onClick={() => push('p/repairList')}
                              >
                                Project List Estimate
                              </Button> */}
                          </Box>
                        }
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          </Grid>
        </PrivateLoader>
      </Box>
    </DesktopPage>
  )
}

export default Payments
