import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    selectedBestCostTypeOption: {
      backgroundColor: 'var(--repairs-yellow-600)',
      color: 'black',
    },
    selectedBetterCostTypeOption: {
      backgroundColor: 'var(--bosscat-green-100)',
      color: 'var(--text-text-success)',
    },
    selectedGoodCostTypeOption: {
      backgroundColor: 'var(--bosscat-blue-100)',
      color: 'var(--text-text-info)',
    },
    unSelectedCostTypeOption: {
      backgroundColor: 'white',
      color: 'black',
    },
    description: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      fontSize: '12px',
    },
    containerBox: {
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      boxShadow:
        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      overflow: 'hidden',
      margin: 0,
    },
  })
)
