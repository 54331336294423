import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '392px',
      borderRadius: '16px',
      textAlign: 'center'
    },
    submitContainer: {
      backgroundColor: theme.palette.grey[100],
      position: 'relative',
      width: '100%',
      height: '146px',
      padding: '16px',
      marginTop: '10px'
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--delete-button-color)',
      borderRadius: 8,
      width: '344px',
      height: '50px',
      left: 16,
      fontFamily: 'LatoNormal',
      position: 'absolute'
    },
    cancel: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      border: '3px solid',
      width: '344px',
      height: '50px',
      left: 16,
      fontFamily: 'LatoNormal',
      position: 'absolute',
      marginTop: '60px',
      '&:hover': {
        border: '3px solid'
      }
    },
    logo: {
      margin: '32px auto 0px auto',
    },
    title: {
      fontFamily: 'Lato',
      fontSize: '20px',
      margin: '0px 35px',
    },
    description: {
      fontFamily: 'LatoLight',
      fontSize: '18px',
      fontWeight: 400,
      margin: '4px 10px'
    },
    descriptionBold: {
      fontFamily: 'LatoBold',
      fontSize: '18px',
      fontWeight: 400,
      margin: '4px 35px'
    },
    moreInformation: {
      fontFamily: 'LatoLight',
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 24
    }
  })
)
