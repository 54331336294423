import { Grid, Typography } from '@material-ui/core'
import { TextFieldLabel } from 'components/UI'
import {
  getNewOrderMaintenanceValue,
  getOrderMaintenanceValues,
} from 'ducks/selectors'
import {
  isEmpty,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  PHONE_MASK_REGEXP_NO_SCOPE,
  validateEmail,
} from 'helpers/index'
import { useStepperContext } from 'hooks/useStepperContext'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import styles from './styles.module.scss'

const HomeownerInformation = () => {
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))
  const { showWarnings, saveNewValue, setCanGoForward, setShowWarnings } =
    useStepperContext()
  const orderMaintenance = useSelector(getOrderMaintenanceValues())
  const email = orderMaintenance?.homeowner?.email || []

  const phoneInvalid =
    isEmpty(orderMaintenance?.homeowner?.phone?.toString()) ||
    !(
      PHONE_MASK_REGEXP.test(
        orderMaintenance?.homeowner?.phone?.toString() as string
      ) ||
      PHONE_MASK_REGEXP_NO_SCOPE.test(
        orderMaintenance?.homeowner?.phone?.toString() as string
      )
    )

  const validate =
    !isEmpty(orderMaintenance.homeowner?.firstName) &&
    !isEmpty(orderMaintenance.homeowner?.lastName) &&
    !phoneInvalid &&
    validateEmail(email[0]?.email)

  useEffect(() => setShowWarnings(false), [])

  useEffect(() => {
    isMobile && setCanGoForward(validate)
  }, [validate])

  useEffect(() => {
    return () => {
      setShowWarnings(false)
    }
  }, [])

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: '1rem' }}
      className={styles.HomeownerInformation}
    >
      {/* <Grid item>
        <Typography variant="h5">Homeowner Information</Typography>
      </Grid> */}

      {(gift || isMobile) && (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          className={styles.HomeownerFields}
        >
          <Grid item lg={6} xs={12}>
            <TextFieldLabel
              label={gift ? "Homeowner First Name" : "First Name"}
              type="text"
              value={orderMaintenance.homeowner?.firstName || ''}
              error={
                showWarnings &&
                isEmpty(orderMaintenance.homeowner?.firstName?.trim())
              }
              onChange={(event) =>
                saveNewValue('homeowner', {
                  ...orderMaintenance.homeowner,
                  firstName: event.target.value,
                })
              }
              placeholder={gift ? "Homeowner First Name" : "First Name"}
              autoComplete="off"
              shouldScrollOnError
            />
          </Grid>
          <Grid item lg={6} xs={12} className={styles.lastName}>
            <TextFieldLabel
              label={gift ? "Homeowner Last Name" : "Last Name"}
              type="text"
              value={orderMaintenance.homeowner?.lastName || ''}
              error={
                showWarnings &&
                isEmpty(orderMaintenance.homeowner?.lastName?.trim())
              }
              onChange={(event) =>
                saveNewValue('homeowner', {
                  ...orderMaintenance.homeowner,
                  lastName: event.target.value,
                })
              }
              placeholder={gift ? "Homeowner Last Name" : "Last Name"}
              autoComplete="off"
              shouldScrollOnError
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextFieldLabel
              label={gift ? "Homeowner Phone" : "Phone"}
              type="tel"
              value={orderMaintenance.homeowner?.phone?.toString() || ''}
              mask={PHONE_MASK_INPUT}
              error={showWarnings && phoneInvalid}
              onChange={(event) =>
                saveNewValue('homeowner', {
                  ...orderMaintenance.homeowner,
                  phone: Number(event.target.value?.replaceAll('-', '')),
                })
              }
              placeholder="555-555-5555"
              autoComplete="off"
              shouldScrollOnError
            />
          </Grid>
          <Grid item lg={6} xs={12} className={styles.email}>
            <TextFieldLabel
              label={gift ? "Homeowner Email" : "Email"}
              type="email"
              value={email[0]?.email || ''}
              error={
                showWarnings &&
                (isEmpty(email[0]?.email) || !validateEmail(email[0]?.email))
              }
              onChange={(event) =>
                saveNewValue('homeowner', {
                  ...orderMaintenance.homeowner,
                  email: [{ email: event.target.value, emailType: 'PRIMARY' }],
                })
              }
              placeholder="mail@email.com"
              autoComplete="off"
              shouldScrollOnError
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default HomeownerInformation
