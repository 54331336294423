import { Box, Tooltip, Typography } from '@material-ui/core'
import React from 'react'

const ImageTooltip = ({ description, icon }: { description: string, icon: React.ReactNode }) => {
  return (
    <Tooltip title={<Typography style={{ fontSize: '12px' }}>{description}</Typography>}>
      <Box style={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='center'>
        {icon}
      </Box>
    </Tooltip>
  )
}

export default ImageTooltip