import { authTypes } from 'ducks/auth/types'
import { Property } from 'ducks/types'
import { PropertyDetailType, PropertyDetailsActions, propertyDetailType } from './types'
import { ConditionType } from 'ducks/properties/types'

const initialState: PropertyDetailType = {} as PropertyDetailType

const reducer = (state = initialState, action: PropertyDetailsActions): PropertyDetailType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case propertyDetailType.SET_PROPERTY_DETAIL: {
      return { ...state, ...payload }
    }

    case propertyDetailType.RESET_PROPERTY_DETAIL: {
      return initialState
    }

    case propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS: {
      return { ...state, inquiredItems: payload as string[] }
    }

    default:
      return state
  }
}

export default reducer
