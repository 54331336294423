import { createContext, FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BottomBar, UserDetails, Box } from '../../../UI'
import { DesktopPage, PrivateLoader, ScrollWrapper } from '../../../templates'
import useStyles from './styles'

import Property from './Steps/Property'
import Repairs from './Steps/Repairs'
import RepairsMobile from './Steps/RepairsMobile'
import Payment from './Steps/Payment'
import {
  getConfigValue,
  getNewRepairListStep,
  getUser,
  getUserFullName
} from '../../../../ducks/selectors'
import { newRepairListActions } from '../../../../ducks/actions'
import { parsePhoneFromNumToStr, USER_TYPE } from '../../../../helpers'
import { useIsXsScreen } from '../../../../hooks'

const INSTITUTIONAL = USER_TYPE.INSTITUTIONAL

const repairListDefaultValue = {
  setCanGoForward: (_canGoForward: boolean) => { },
  showWarnings: false,
  setShowWarnings: (_showWarnings: boolean) => { },
  saveNewValue: (_attr: any, _value: any) => { }
}

export const RepairListContext = createContext(repairListDefaultValue)

const RepairList: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [xsScreen] = useIsXsScreen()
  const [hidde, setHidde] = useState(false)

  const userType = useSelector(getConfigValue('userType'))
  const activeStep = useSelector(getNewRepairListStep())
  const userFullName = useSelector(getUserFullName)
  const user = useSelector(getUser)
  // const userAddress = useSelector(getUserAddress)
  // const userPhone = useSelector(getUserPhone)

  const [canGoForward, setCanGoForward] = useState(false)
  const [showWarnings, setShowWarnings] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => { setPageLoading(false) }, [activeStep])

  const setNewRepairListStep = (step: number) => {
    dispatch(newRepairListActions.setNewRepairListStep(step))
  }

  const handleForward = () => {
    if (canGoForward) setNewRepairListStep(activeStep + 1)
    else setShowWarnings(true)
  }
  const handleBack = () => {
    setNewRepairListStep(activeStep - 1)
  }

  const saveNewValue = (attr: any, value: any) => {
    dispatch(newRepairListActions.setNewRepairListValue({ attr, value }))
  }

  return (
    <DesktopPage title='Repair List' absoluteClass={classes.root}>
      <PrivateLoader loading={pageLoading}>
        <>
          <Box className={hidde ? classes.hidde : classes.show}>
            <UserDetails
              primary={userFullName}
              secondary={
                userType === INSTITUTIONAL
                  ? [userType]
                  : [userType, user.email[0].email, user.phone ? parsePhoneFromNumToStr(user.phone) : '']
              }
            />
          </Box>
          <ScrollWrapper
            absoluteStyle={{
              display: 'grid',
              gridAutoRows: 'auto min-content',
              height: '100%',
              paddingTop: !hidde ? '0px' : '100px'
            }}
          >
            <RepairListContext.Provider
              value={{
                setCanGoForward,
                showWarnings,
                setShowWarnings,
                saveNewValue
              }}
            >
              {activeStep === 0 && <Property />}
              {activeStep === 1 && !xsScreen && <Repairs />}
              {activeStep === 1 && xsScreen && <RepairsMobile setHidde={(value) => setHidde(value)} />}
              {activeStep === 2 && <Payment />}
              <Box className={hidde ? classes.hidde : classes.show}>
                <BottomBar
                  hideBack={activeStep === 0}
                  hideFordward={activeStep === 2}
                  onBack={handleBack}
                  onFordward={handleForward}
                />
              </Box>
            </RepairListContext.Provider>
          </ScrollWrapper>
        </>
      </PrivateLoader>
    </DesktopPage>
  )
}

export default RepairList
