import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    containerLeft: {
      width: "26%",
      height: "100%",
      borderRight: '2px solid var(--border-color)',
      position: 'sticky',
      top: 0,
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100%"
      }
    },
    cartTitleContainer: {
      display: 'flex'
    },
    headerSticky: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: 'var(--white-color)'
    },
    cartTitle: {
      fontFamily: 'NextArt'
    },
    cartHeader: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem'
      }
    },
    cartIcon: {
      [theme.breakpoints.down('sm')]: {
        marginRight: '.4rem'
      }
    },
    relativeContainer: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: '6rem'
      }
    },
    requestMoreButton: {
      height: '54px',
      padding: '0 .5rem',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      transition: '300ms all',
      '&:hover': {
        color: 'white',
        transition: '300ms all',
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },

      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'fit-content',
        height: '48px',
        borderRadius: 32,
        color: '#0B060F'
      }
    },
    containerRight: {
      width: "80%",
      height: "100%",
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '.5rem',
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    modal: {
      '@global': {
        '.MuiPaper-root': {
          width: '100%',
          height: '100%'
        },
        '.MuiButtonBase-root': {
          padding: '0 !important',
          display: 'flex',
          position: 'inherit',
          alignItems: 'flex-start'
        },
        '.MuiIconButton-root': {
          padding: '0 !important',
        }
      }
    },
    cartModal: {},

    list: {
      margin: '20px 0px'
    },
    subHeader: {
      backgroundColor: 'var(--better-light-color)',
      borderRadius: '8px',
      position: 'static'
    },
    listItem: {
      justifyContent: 'space-between'
    },
    item: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    },
    itemType: {
      fontFamily: 'BetterSansBold',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    goldBanner: {
      marginTop: '1rem'
    },
    itemEnd: {
      color: 'var(--better-bold-color)',
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      alignSelf: 'flex-end',
      maxWidth: '100px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    categoryTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '58px',
      color: 'var(--head-text-color)',
      marginRight: '10px'
    },
    title: {
      fontFamily: 'NextArtHeavy',
    },
    categoryContainer: {
      padding: '.5rem 0'
    },
    categoryHeader: {
      display: 'flex',

    },
    price: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '58px',
      color: 'var(--better-bold-color)'
    },
    arrowBack: {
      position: 'absolute',
      left: '-1rem'
    },
    icon: {
      color: 'var(--better-color)',
      margin: '18px 8px',
      width: '22px',
      height: '22px'
    },
    expand: {
      color: 'var(--white-color)'
    },
    expandBox: {
      width: '26px',
      height: '26px',
      borderRadius: '4px',
      backgroundColor: 'var(--bosscat-blue-600)',
      justifyContent: 'center',
      alignItems: 'center'
    },
    chart: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '300px',
      margin: '10px 20px 20px 80px',
      [theme.breakpoints.down('lg')]: {
        margin: '10px 20px 20px 20px'
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 0px 5px',
        maxHeight: '160px',
        maxWidth: '160px'
      }
    },
    titleChart: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '30px',
      color: 'var(--better-bold-color)',
      marginLeft: '5px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px'
      }
    },
    detail: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px',
      color: 'var(--better-color)',
      backgroundColor: 'var(--row-background)'
    },
    button: {
      height: '33px',
      width: '80px',
      minWidth: '80px',
      margin: '0px 2px',
      textTransform: 'none',
      border: '1px solid var(--better-color)',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      }
    },
    buttonContent: {
      justifyContent: 'flex-end'
    },
    percentContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    percent: {
      display: 'flex',
      margin: '2px 12px',
      padding: '8px',
      height: '40px',
      width: '48px',
      borderRadius: 8,
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '2px 5px',
        height: '32px',
        width: '40px'
      }
    },
    itemPercent: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    number: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      marginLeft: '4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    marks: {
      position: 'fixed',
      bottom: '-48px',
      right: '-8px',
      transform: 'rotate(125deg)',
      color: 'var(--text-color)',
      width: '128px',
      display: 'flex'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 10,
      backgroundColor: 'rgba(24, 24, 24, .4)'
    },
    addressBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '10px',
      borderColor: 'var(--head-text-color)',
      border: '1px solid var(--head-text-color)',
      backgroundColor: 'var(--background-color-secondary)',
      margin: '0px 12px'
    },
    total: {
      fontSize: '18px',
      padding: '8px'
    },
    text: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 700,
      borderRadius: '8px',
      padding: '6px 16px 4px',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'rgba(250, 251, 255, 1)',
      textAlign: 'center'
    },
    alert: {
      width: '100%',
      height: 'auto',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '16px 16px 0px 0px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      bottom: 0,
      zIndex: 12
    },
    back: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(26, 42, 85, 0.6)',
      position: 'sticky',
      bottom: 0,
      zIndex: 11
    },
    info: {
      width: '248px',
      height: '168px',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      top: 0,
      zIndex: 11
    },
    evaluateDisclamerBox: {
      padding: '12px 16px',
      margin: '0.25rem',
      borderRadius: '6px',
      color: 'var(--punchlist-red)',
      border: '1px solid var(--punchlist-red)'
    },
    evaluateDisclamerIcon: {
      float: 'left',
      height: '20px',
      marginRight: '6px'
    },
    evaluateDisclamerText: {
      fontSize: '13px',
      fontStyle: 'italic',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      lineHeight: '21px',
      color: 'var(--punchlist-red)'
    },
    evaluateContainer: {

    },
    buttonAdd: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      }
    },
    itemDetailModal: {

    },
    estimateValid: {
      width: '100%',
      display: 'block',
      textAlign: 'center'
    },
  })
)
