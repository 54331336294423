import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

const textStyles = {
  padding: '0',
  fontSize: 18,
  lineHeight: 1.2,
  fontWeight: 400,
  display: 'inline'
}
export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '1rem',
      display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'space-between',
      backgroundColor: '#FFF9EA',
      border: '2px solid #FFE8AD',
      // marginBottom: '10px',
      borderRadius: '8px',
      maxWidth: '93vw',
      // margin: '10px auto 0',
      padding: '1rem',
      gap: '.5rem',
      color: '#0B060F',
    },
    text: {
      ...textStyles,
      '@global': {
        '.MuiTypography-root': {
          ...textStyles
        },

      }
    },
    warning: {
      padding: '3px 0px',
      color: '#CC9E27',
      fontSize: '1.5rem',
    }
  })
)
