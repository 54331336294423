import { PaymentOption } from 'ducks/types'
import {
  NewRepairListValue,
  NewRepairListValues,
  newRepairListTypes,
  NewRepairListActions
} from './types'

const actions = {
  cleanNewRepairList: (): NewRepairListActions => ({
    type: newRepairListTypes.CLEAN,
    payload: null
  }),
  setNewRepairListStep: (payload: number): NewRepairListActions => ({
    type: newRepairListTypes.SET_STEP,
    payload
  }),
  setNewRepairListValue: (payload: NewRepairListValue): NewRepairListActions => ({
    type: newRepairListTypes.SET_VALUE,
    payload
  }),
  setNewRepairListValues: (payload: NewRepairListValues): NewRepairListActions => ({
    type: newRepairListTypes.SET_VALUES,
    payload
  }),
  createRepairList: (
    payload: PaymentOption,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewRepairListActions => ({
    type: newRepairListTypes.CREATE_REPAIR_LIST,
    payload,
    callback
  }),
  updateRepairList: (
    payload: PaymentOption | null,
    callback: (succ: boolean, estimateId?: string) => void
  ): NewRepairListActions => ({
    type: newRepairListTypes.UPDATE_REPAIR_LIST,
    payload,
    callback
  })
}

export default actions
