/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { blueLogo, icons } from '../../../../../../assets'
import useStyles from './styles'
import {
  Box,
  Typography,
  Grid,
  TextFieldLabel,
  BottomLink,
  Button,
  PasswordLabel
} from '../../../../../UI'
import { getNewEstimateValue } from '../../../../../../ducks/selectors'
import {
  AUTH0_CODES,
  isEmpty,
  parsePhoneFromNumToStr,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  history,
  validateEmail
} from '../../../../../../helpers'
import {
  authActions,
  newEstimateActions
} from '../../../../../../ducks/actions'
import { Auth0Error } from '../../../../../../ducks/auth/types'
import { PunchlistLoader } from '../../../../../templates'

const Register: FC<{ goBack: () => void }> = ({ goBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const mainContact = useSelector(getNewEstimateValue('mainContact'))

  const estimate = useSelector(getNewEstimateValue('estimate'))

  const [firstName, setFirstName] = useState(mainContact.firstName || estimate?.properties?.contacts[0].firstName)
  const [lastName, setLastName] = useState(mainContact.lastName || estimate?.properties?.contacts[0].lastName)
  const [phone, setPhone] = useState(mainContact.phone || estimate?.properties?.contacts[0].phone)
  const [email, setEmail] = useState(mainContact.email || estimate?.properties?.contacts[0].email)
  const [password, setPassword] = useState('')
  // const [showWarnings, setShowWarnings] = useState(false)

  const [emailTaken, setEmailTaken] = useState('')
  const [emailWarning, setEmailWarning] = useState('')
  const [passwordWarning, setPasswordWarning] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [passwordPolicy, setPasswordPolicy] = useState('')
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [validate, setValidate] = useState(false)
  const validatePhone = !isEmpty(phone) && parseInt(phone?.replaceAll('-', ''))?.toString().length === 10

  const isValid = () => {
    return !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(email) &&
    validateEmail(email) &&
    validatePhone &&
    // !isEmpty(phone) &&
    // PHONE_MASK_REGEXP.test(phone) &&
    !isEmpty(password)
  }
  // const validate =
  //   !isEmpty(firstName) &&
  //   !isEmpty(lastName) &&
  //   !isEmpty(email) &&
  //   validateEmail(email) &&
  //   !isEmpty(phone) &&
  //   PHONE_MASK_REGEXP.test(phone) &&
  //   !isEmpty(password)

  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value }
      })
    )
  }

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
  }, [mainContact, phone, email, password, firstName, lastName])

  useEffect(() => {
    if (submit) {
      if (errorCode === AUTH0_CODES.INVALID_SIGNUP && emailTaken === email) {
        setEmailWarning('An account already exists for this email address.')
      } else {
        setEmailWarning('')
      }
    }
  }, [submit, email, emailTaken, errorCode])

  useEffect(() => {
    if (submit) {
      if (errorCode === AUTH0_CODES.INVALID_PASSWORD) {
        setPasswordWarning(
          'Password is too weak, please choose a stronger password'
        )
      } else {
        setPasswordWarning('')
      }
    }
  }, [submit, password, errorCode, passwordPolicy])

  const noHandledErrorCode = (): boolean => {
    return (
      !isEmpty(errorCode) &&
      errorCode !== AUTH0_CODES.INVALID_SIGNUP &&
      errorCode !== AUTH0_CODES.INVALID_PASSWORD
    )
  }

  const handleSubmit = (event: React.MouseEvent<EventTarget>): void => {
    event.preventDefault()
    setSubmit(true)
    if (validate) {
      setLoading(true)
      // turn on welcome alert afeter registration
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'showWelcome',
          value: true
        })
      )

      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr: 'estimate',
          value: { ...estimate, shouldUpdateRole: true }
        })
      )

      dispatch(
        authActions.signupRequest({
          email,
          password,
          additionalSignUpFields: null,
          callback: (success: boolean, error?: Auth0Error) => {
            if (success) {
              dispatch(
                authActions.loginRequest({
                  email,
                  password,
                  remember: true,
                  callback: () => {
                    setLoading(false)
                    replace('/')
                  }
                })
              )
            } else {
              setErrorCode(error?.code ?? '')
              setEmailTaken(email)
              setPasswordPolicy(error?.policy ?? '')
              setLoading(false)
            }
          }
        })
      )
    }
  }

  return (
    <PunchlistLoader loading={loading}>
      <form className={classes.root}>
        <img src={blueLogo} className={classes.logo} alt='BOSSCAT Logo' />
        <Typography className={classes.header}>
          Great work! Just set a secure password for your{' '}
          <span>Bossfolio Account</span>
        </Typography>
        <Typography className={classes.desc}>
          You’ll need your account to view your Estimate.
        </Typography>
        <Grid xs={12} container item spacing={2} className={classes.inputs}>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldLabel
              label='First Name:'
              type='text'
              id='firstName'
              value={firstName}
              // error={showWarnings && isEmpty(firstName)}
              onChange={(event) => {
                setFirstName(event.target.value)
                updateMainContact('firstName', event.target.value)
              }}
              placeholder='First Name'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldLabel
              label='Last Name:'
              type='text'
              id='lastName'
              value={lastName}
              // error={showWarnings && isEmpty(lastName)}
              onChange={(event) => {
                setLastName(event.target.value)
                updateMainContact('lastName', event.target.value)
              }}
              placeholder='Last Name'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldLabel
              label='Email:'
              type='email'
              id='email'
              value={email}
              // error={showWarnings && isEmpty(email)}
              error={submit && !isEmpty(emailWarning)}
              helperText={submit && emailWarning}
              onChange={(event) => {
                setEmail(event.target.value)
                updateMainContact('email', event.target.value)
              }}
              placeholder='Email'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldLabel
              label='Mobile Phone Number:'
              type='tel'
              value={phone}
              // error={showWarnings && (isEmpty(phone) || !PHONE_MASK_REGEXP.test(phone))}
              mask={PHONE_MASK_INPUT}
              onChange={(event) => {
                setPhone(event.target.value)
              }}
              // onChange={(event) => {
              //   setPhone(event.target.value)
              //   parseInt(event?.target?.value?.replaceAll('-', ''))
              // }}
              placeholder='555-555-1234'
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PasswordLabel
              label='Password:'
              value={password}
              // error={showWarnings && isEmpty(password)}
              error={submit && !isEmpty(passwordWarning)}
              helperText={submit ? passwordWarning : 'Enter a secure password.'}
              onChange={(event) => setPassword(event.target.value)}
              placeholder='Password'
            />
          </Grid>
          <Grid item xs={12}>
            {!isEmpty(passwordPolicy) && (
              <Typography
                variant='body2'
                color='error'
                style={{ whiteSpace: 'pre-wrap', padding: '0 16px' }}
              >
                {passwordPolicy}
              </Typography>
            )}
            {submit && noHandledErrorCode() && (
              <Typography
                variant='body2'
                color='error'
                style={{ padding: '0 16px' }}
              >
                An error occurred while trying to sign up, please try again.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box px={2} className={classes.row}>
          <Button
            variant='contained'
            size='large'
            className={classes.back}
            startIcon={<icons.ArrowBack className={classes.icon} />}
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            className={classes.submit}
            onClick={handleSubmit}
            endIcon={<icons.ArrowForward className={classes.icon} />}
            disabled={!validate}
          >
            Create Account
          </Button>
        </Box>
        <BottomLink
          question='Already have an account?'
          linkText='Log in here'
          linkTo='/login'
        />
      </form>
    </PunchlistLoader>
  )
}

export default Register
