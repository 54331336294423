/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, RESOURCE_TYPE } from 'helpers'
import { Punchlist } from 'api'
import { dashboardTypes, FetchDashboardDataAction } from './types'
import { dashboardActions, estimatesActions, jobsActions } from 'ducks/actions'
import { Estimate, Job } from 'ducks/types'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

const LIMIT = 10
export interface Resource {
  type: RESOURCE_TYPE
  data: Estimate | Job
}

const getResources = (estimates?: Estimate[], jobs?: Job[]): any[] => {
  const resources: Resource[] = []
  estimates?.map(estimate => resources.push({ type: RESOURCE_TYPE.ESTIMATE, data: estimate }))
  jobs?.map(job => resources.push({ type: RESOURCE_TYPE.JOB, data: job }))

  const limit = resources.length < LIMIT ? resources.length : LIMIT
  return _.orderBy(resources, ['data.createdOn'], ['desc']).slice(0, limit) as Resource[]
}

export function* fetchDashboardData({ payload, callback }: FetchDashboardDataAction): SagaIterator {
  let success = false
  try {
    const startDate = payload.startDate ?? undefined
    const endDate = payload.endDate ?? undefined
    const start = 0
    const limit = 10
    const sortBy = 'createdOn'
    const sortOrder = 'DESC'

    yield put(estimatesActions.setEstimates([]))
    yield put(jobsActions.setJobs([]))

    const estimates = yield call(Punchlist.estimates.getEstimates, startDate, endDate, undefined, start, limit, sortBy, sortOrder)
    const jobs = yield call(Punchlist.jobs.getJobs, startDate, endDate, undefined, start, limit, sortBy, sortOrder)

    if (estimates?.items || jobs?.items) {
      const { items: estimateItems } = estimates
      const { items: jobItems } = jobs
      const resources = getResources(estimateItems, jobItems)
      yield put(dashboardActions.setResources(resources))
    }

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the dashboard data'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(dashboardTypes.FETCH_DASHBOARD_DATA, fetchDashboardData)
}
