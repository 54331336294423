import { FC } from 'react'
import { Button, Typography, Box } from '../../../../'
import { popupHeaderMarks } from '../../../../../../assets'
import { UpgradeTierModalProps } from './types'
import useStyles from './styles'
import { formatTimestamp } from '../../../../../../helpers'
import moment from 'moment'

const UpgradeTierModal: FC<UpgradeTierModalProps> = ({ handleSubmit, option }) => {
  const classes = useStyles()

  const { subtitle, days } = option

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Typography className={classes.headerText}>Get it Faster!</Typography>
        <img alt='' src={popupHeaderMarks} className={classes.headerMarks} />
      </Box>
      <Box className={classes.bodyContainer}>
        <Box>
          <Typography>{`${subtitle}`}</Typography>
          <Typography>
            Delivery: {formatTimestamp(moment().add(days, 'days').unix(), 'MMMM, Do YYYY')}
          </Typography>
        </Box>
        <Button
          onClick={handleSubmit}
          className={classes.button}
          variant='contained'
          type='submit'
        >
          Expedite
        </Button>
      </Box>
    </Box>
  )
}

export default UpgradeTierModal
