import { FC, useEffect, useState } from 'react'
import { Container, Box, TitleFirst, LinearProgress, LoginAnimation } from '../../../UI'
import useStyles from './styles'
import { LoaderProps } from '../types'

const PublicLoader: FC<LoaderProps> = ({ children, loading = true, compact = false }) => {
  const classes = useStyles()

  const [progress, setProgress] = useState(3)

  useEffect(() => {
    setProgress(3)
  }, [loading])

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress < 25 ? 25 : prevProgress + 25)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [progress])

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Container className={classes.root}>
      {
        !compact && <Box>
          <TitleFirst value='Everything you need,' />
          <TitleFirst black='under one roof' />
        </Box>
      }
      <Box className={classes.animation}>
        <LoginAnimation timeout={2000} />
      </Box>
      <Box>
        <LinearProgress data-testid="linear-progress" variant='determinate' value={progress} className={classes.loader} />
      </Box>
    </Container>
  )
}

export default PublicLoader
