import { FC, memo } from 'react'

import { Grid, Autocomplete, YearPicker } from '../../../../UI'
import { PaymentHeaderProps } from './types'
import useStyles from './styles'

const PaymentHeader: FC<PaymentHeaderProps> = ({
  setStatus,
  setYear,
  status,
  statusOptions,
  year
}) => {
  const classes = useStyles()

  return (
    <Grid container item xs={12} className={classes.date}>
      <Grid item xs={12} md={7} lg={4}>
        <Autocomplete
          label=''
          value={status}
          options={statusOptions}
          onChange={(value: any) => {
            setStatus(value)
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3} className={classes.year}>
        <YearPicker
          value={year}
          onChange={(value: any) => {
            setYear(value)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default memo(PaymentHeader)
