import { FC, useState } from 'react'
import { FormControl, IconButton, FormHelperText, InputAdornment, TextField } from '../../..'
import { icons } from '../../../../../assets'
import { PasswordProps } from './types'
import theme from '../../../MaterialUI/theme'

const Password: FC<PasswordProps> = ({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  fullWidth = true,
  whiteBackground
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  return (
    <FormControl variant='outlined' size='small' fullWidth={fullWidth} error={error}>
      <TextField
        id="current-password"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        label="Password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <icons.Visibility
                  style={{
                    color: showPassword
                      ? 'var(--teal-700)'
                      : theme.palette.grey[400]
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
      /> 
      <FormHelperText error={error} style={{fontFamily: 'LatoBold'}}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Password
