import storeInfo from 'ducks/store'
import { orderBy as SortBy } from 'lodash'
import { JOB_PUBLIC_STATUS } from 'helpers'
import { Job } from 'ducks/types'
import { JobsIndicators } from './types'

export const getJobs = (
  orderBy?: string,
  order?: 'asc' | 'desc',
  status?: JOB_PUBLIC_STATUS
) => (): Job[] => {
  const { jobs } = storeInfo.store.getState()
  if (!jobs || !jobs.length) return []
  if (status) return jobs.filter(jobs => jobs.publicStatus === status) || []
  if (orderBy && order) return SortBy(jobs, orderBy, order) as Job[]
  return jobs
}

export const getJobsIndicators = () => (): JobsIndicators => {
  const { config: { jobPagination, jobStats } } = storeInfo.store.getState()

  if (!jobPagination || !jobStats) {
    return { total: 0, upcoming: 0, inProgress: 0, attention: 0, completed: 0 }
  }

  return {
    total: jobPagination?.total ?? 0,
    upcoming:
      (jobStats.find(stat => stat.status === 'Scheduling')?.count ?? 0) +
      (jobStats.find(stat => stat.status === 'Dispatched')?.count ?? 0) +
      (jobStats.find(stat => stat.status === 'Hold')?.count ?? 0),
    inProgress: jobStats.find(stat => stat.status === 'In Progress')?.count ?? 0,
    attention: jobStats.find(stat => stat.status === 'Needs Attention')?.count ?? 0,
    completed: jobStats.find(stat => stat.status === 'Completed')?.count ?? 0
  }
}
