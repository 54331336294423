import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from '../../../../../styles'
import {
  InsertDriveFile,
  KeyboardArrowUp,
  KeyboardArrowDown,
  ArrowForward,
} from '@material-ui/icons'
import { newEstimate } from 'assets/index'
import { PropertyActivityActiveData } from 'ducks/types'
import moment from 'moment'
import {
  useIsMdScreen,
  useIsSmScreen,
  useIsXsScreen,
} from 'hooks/useMediaQuery'

const EstimateStep = ({
  activeData,
  show,
  setShow,
}: {
  activeData: PropertyActivityActiveData
  show: boolean
  setShow: (value: boolean) => void
}) => {
  const styles = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen

  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        spacing={isSmallScreen ? 1 : 2}
        alignItems="center"
        justifyContent="space-between"
        style={{ flexWrap: 'nowrap', overflow: 'scroll' }}
      >
        <Grid item>
          <img
            src={newEstimate}
            style={{ width: isSmallScreen ? '2.5em' : '3em' }}
            alt="estimate"
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography
            className={styles.nextArtTitle}
            style={{ fontWeight: 700, width: 'max-content', fontSize: isSmallScreen ? '14px' : '16px' }}
          >
            {`ESTIMATE #${activeData?.publicId || ''}`}
          </Typography>
          <Typography className={styles.title} style={{ width: 'max-content', fontSize: isSmallScreen ? '14px' : '16px' }}>
            {`Created on: ${
              activeData?.estimateCreatedOn
                ? moment(activeData?.estimateCreatedOn * 1000).format(
                    'MMMM DD, YYYY'
                  )
                : ''
            }`}
          </Typography>
        </Grid>
        {!isSmallScreen && (
          <Grid item>
            <Button
              variant="outlined"
              className={`${styles.Tab} ${styles.primaryOutlinedButton}`}
              style={{ width: '160px', margin: 0 }}
              endIcon={
                <ArrowForward style={{ width: '14px', height: '14px' }} />
              }
              onClick={() =>
                window.open(`/p/estimates/${activeData?.estimateId}`, '_blank')
              }
              disabled={!activeData?.estimateDeliveredOn}
            >
              Go to Estimate
            </Button>
          </Grid>
        )}
        <Grid item>
          <IconButton
            onClick={() => {
              setShow(!show)
            }}
            style={{ padding: isSmallScreen ? 0 : '12px' }}
            disabled={!activeData?.estimateId}
          >
            {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {isSmallScreen && (
        <Grid item>
          <Button
            variant="outlined"
            className={`${styles.Tab} ${styles.primaryOutlinedButton}`}
            style={{ width: 'calc(100% - 20px)', margin: 0 }}
            endIcon={<ArrowForward style={{ width: '14px', height: '14px' }} />}
            onClick={() =>
              window.open(`/p/estimates/${activeData?.estimateId}`, '_blank')
            }
            disabled={!activeData?.estimateDeliveredOn}
          >
            Go to Estimate
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default EstimateStep
