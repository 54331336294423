import { Box, Typography } from "@material-ui/core"
import { TrustPilot, bosscatHomeServicesLogo, logo, mellohome, mellohomeBg, trustpilot } from "assets/index"
import useStyles from './styles'
import Banner from "../Banner"

const MellohomeHeader = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.headerLogos}>
        <img src={bosscatHomeServicesLogo} className={classes.logo} alt='' />
        <img src={mellohome} className={classes.logo} alt='' />
      </Box>
      <Banner
        title={
          <>
            <Typography style={{ color: "var(--bosscat-black-600)" }}> YOUR DREAM </Typography><Typography style={{ color: "var(--bosscat-black-600)" }}> HOME AWAITS </Typography>
          </>
        }
        subtitle={
          <>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>Repair and renovate your home with</Typography>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>BOSSCAT, the one-stop solution for home</Typography>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>improvement projects.</Typography>
          </>
        }
        // subtitle="Repair and renovate your home with BOSSCAT, the one-stop solution for home improvement projects."
        imgSrc={mellohomeBg}
        badgeSrc={TrustPilot}
        padding="6rem 8rem"
      />
      <Box className={classes.footer}>RECEIVE EXCLUSIVE VIP SERVICE FOR MELLOHOME CUSTOMERS</Box>
      {/* <img src={mellohomeBg} alt="mellohome" /> */}
    </Box>
  )
}

export default MellohomeHeader