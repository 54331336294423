import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

export interface PriceProps {
  smallCents?: boolean
  price?: string
  totalClassName?: string,
  decimalsClassName?: string
}

const Price = ({ smallCents = true, price, totalClassName = "", decimalsClassName = "" }: PriceProps) => {
  const classes = useStyles()
  const decimals = price?.split('.')[price?.split('.').length - 1]
  const priceWithoutDecimals = price?.split('.')[0]

  return (
    <Grid className={classes.price}>
      <Typography className={`${classes.total} ${totalClassName}`}>
        {priceWithoutDecimals}
      </Typography>
      {price?.includes('.') &&
        <Typography className={`${smallCents ? `${decimalsClassName} ${classes.decimals}` : `${classes.total} ${totalClassName}`}`}>
          .{decimals}
        </Typography>
      }
    </Grid>
  )
}

export default Price