import { Box, IconButton, TableCell, TableRow, Typography } from '@material-ui/core'
import { memo, FC } from 'react'

import { icons } from '../../../../../../../../../assets'
import { PunchlistFile } from '../../../../../../../../../ducks/types'
import { ImagesSelector, STextField } from '../../../../../../../../UI'
import { imageMimeTypes, applicationMimeTypes, videoMimeTypes } from '../../../../../../../../../helpers/files'
import useStyles from '../styles'

interface ItemRowProps {
    index: number,
    inspectionReportNote: string,
    measurement: string,
    htmlFiles: File[],
    images: PunchlistFile[]
    onChangeMeasurement: (measure: string, index: number) => void,
    onChangeNote: (note: string, index: number) => void,
    onChangeImages: (images: any, index: number, callback: () => void) => void
    onDeleteImagesFiles: (index: number) => void
    onDeleteItem: (index: number) => void
    totalItems: number,
    imagesUpdater: any,
    deleteEnabled: boolean
}

interface MemoizedImagesProps {
    htmlFiles: File[],
    images: PunchlistFile[],
    onDeleteImageFilesActions: (index: number) => void,
    submitAction: (images: any) => void,
    totalItems: number,
    imagesUpdater: any
}

const filesExtensions = [...imageMimeTypes, ...applicationMimeTypes, ...videoMimeTypes]

const MemoizedImages = memo((props: MemoizedImagesProps) => {
    const { htmlFiles, submitAction, images, onDeleteImageFilesActions } = props
    return (
        <Box display='flex' aria-label='memoized-images'>
            <ImagesSelector
                imageFiles={images}
                images={htmlFiles as unknown as File[] || []}
                deleteImageFilesAction={onDeleteImageFilesActions}
                submitAction={submitAction}
                multiple
                disabled={false}
                allowedFiles={filesExtensions}
                canUpload
            />
        </Box>

    )
}, (beforeProps: MemoizedImagesProps, nextProps: MemoizedImagesProps) => JSON.stringify(beforeProps) === JSON.stringify(nextProps))

const ItemRow: FC<ItemRowProps> = ({
    index,
    inspectionReportNote,
    measurement,
    htmlFiles,
    images,
    onChangeMeasurement,
    onChangeNote,
    onChangeImages,
    onDeleteItem,
    onDeleteImagesFiles,
    totalItems,
    imagesUpdater,
    deleteEnabled
}: ItemRowProps) => {
    const classes = useStyles()

    return (
        <TableRow data-testid='list-builder-row' key={index} className={classes.row}>
            <TableCell align='center' className={classes.cell} style={{ background: 'var(--background-color-secondary)' }}>
                <Typography align='center' className={classes.title}>
                    {index + 1}
                </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
                <STextField
                    value={inspectionReportNote}
                    error={!inspectionReportNote}
                    placeholder='Example: Paint 4 walls and ceiling. (I’ll provide paint.) The room has 3 windows, a closet, and a door. Window and door frames don’t need painting.'
                    onChange={(event: any) => {
                        onChangeNote(event.target.value, index)
                    }}
                />
            </TableCell>
            <TableCell className={classes.cell}>
                <STextField
                    // className={classes.signature}
                    value={measurement}
                    error={!measurement}
                    placeholder='Room size: 15’ x 19’5” x 8’ ceiling height'
                    onChange={(event: any) => {
                        onChangeMeasurement(event.target.value, index)
                    }}
                />
            </TableCell>
            <TableCell className={classes.cell}>
                <MemoizedImages
                    images={images}
                    htmlFiles={htmlFiles as unknown as File[] || []}
                    submitAction={(images: any) => {
                        onChangeImages(images, index, () => { })
                    }}
                    onDeleteImageFilesActions={onDeleteImagesFiles}
                    totalItems={totalItems}
                    imagesUpdater={imagesUpdater}
                />
            </TableCell>
            <TableCell align='center' className={classes.cell}>
                {deleteEnabled
                    ? <IconButton aria-label='delete-button' onClick={() => { onDeleteItem(index) }}>
                        <icons.Delete />
                    </IconButton>
                    : <Box className={classes.space} />}
            </TableCell>
        </TableRow>
    )
}
export default ItemRow
