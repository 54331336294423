import { FEEDBACK_TYPE } from 'helpers'

/** TYPES **/
export const feedbackTypes = {
  SEND_FEEDBACK: 'SEND_FEEDBACK'
}

export interface Rating {
  description?: string
  score: number
}

export interface Feedback {
  userId: string
  resourceId: string
  resourceType: FEEDBACK_TYPE
  ratings: Rating[]
  comments?: string
}

/** ACTIONS **/
export interface SendFeedbackAction {
  type: typeof feedbackTypes.SEND_FEEDBACK
  payload: Feedback
  callback?: (succ: boolean) => void
}

export type FeedbackActions =
  | SendFeedbackAction
