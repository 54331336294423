// eslint-disable-next-line
import * as React from 'react'

import { ChartProps } from './types'
import { Bar } from 'react-chartjs-2'
import useStyles from './styles'

const VerticalChart = (props: ChartProps): JSX.Element => {
  const classes = useStyles()

  const options = {
    responsive: true,
    barThickness: 20,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        display: false,
        grid: {
          display: false
        }
      }
    }
  }

  return (
    <div>
      <Bar options={options} data={props.data} className={classes.chart} />
    </div>
  )
}
export default VerticalChart
