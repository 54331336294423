import { Partner } from 'ducks/types'

/** TYPES **/
export const homemartTypes = {
  FETCH_PARTNERS: 'FETCH_PARTNERS',
  SET_PARTNERS: 'SET_PARTNERS',
  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_CUSTOMER_TYPES: 'FETCH_CUSTOMER_TYPES',
  SET_TAGS: 'SET_TAGS',
  SET_CUSTOMER_TYPES: 'SET_CUSTOMER_TYPES',
}

/** ACTIONS **/
export interface FetchPartnersAction {
  type: typeof homemartTypes.FETCH_PARTNERS
  payload: {
    active?: boolean,
  }
  callback?: (succ: boolean) => void
}


export interface SetPartnersAction {
  type: typeof homemartTypes.SET_PARTNERS
  payload: Partner[]
}

export interface FetchTagsAction {
  type: typeof homemartTypes.FETCH_TAGS
  payload: null
  callback?: (succ: boolean) => void
}

export interface SetTagsAction {
  type: typeof homemartTypes.SET_TAGS
  payload: Partner[]
}

export interface SetCustomerTypesAction {
  type: typeof homemartTypes.SET_CUSTOMER_TYPES
  payload: Partner[]
}


export type HomeMartActions =
  | FetchPartnersAction
  | SetPartnersAction
  | FetchTagsAction
  | SetTagsAction

/** REDUCER **/
export type Tag = {
  id: string,
  name: string
}

export interface HomeMartType {
  partners: Partner[]
  tags: Tag[]
  customerTypes: Tag[]
}

// export interface HomeMartType extends Array<Partner> { }
