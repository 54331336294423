/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { propertyContactType, FetchPropertyContactsAction, CreatePropertyContactAction, UpdatePropertyContactAction, DeletePropertyContactAction } from './types'
import * as Sentry from '@sentry/react'
import { propertyContactActions } from 'ducks/actions'
import { PropertyContact } from 'ducks/types'
import { UpdateRequest } from 'api/types'

export function* fetchPropertyContacts({ payload, callback }: FetchPropertyContactsAction): SagaIterator {
  let success = false
  try {
    const propertyContact = yield call(Punchlist.properties.getPropertyContacts, payload.id)

    if (propertyContact) yield put(propertyContactActions.setPropertyContact(propertyContact))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the property Contact'))
    if (callback) yield call(callback, false)
  }
}

export function* createPropertyContact({ payload, callback }: CreatePropertyContactAction): SagaIterator {
  let success = false
  try {
    const propertyContact = yield call(Punchlist.properties.createPropertyContact, payload.id, payload.request)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create a property Contact'))
    if (callback) yield call(callback, false)
  }
}

export function* updatePropertyContact({ payload, callback }: UpdatePropertyContactAction): SagaIterator {
  let success = false
  try {
    const request: UpdateRequest = []

    for (const attr in payload.request) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload.request[attr as keyof PropertyContact]
      })
    }
    const propertyContact = yield call(Punchlist.properties.updatePropertyContact, payload.propertyId, payload.contactId, request)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update a property Contact'))
    if (callback) yield call(callback, false)
  }
}

export function* deletePropertyContact({ payload, callback }: DeletePropertyContactAction): SagaIterator {
  let success = false
  try {
    const propertyContact = yield call(Punchlist.properties.deletePropertyContact, payload.propertyId, payload.contactId)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('delete a property Contact'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(propertyContactType.FETCH_PROPERTY_CONTACTS, fetchPropertyContacts)
  yield takeLatest(propertyContactType.CREATE_PROPERTY_CONTACT, createPropertyContact)
  yield takeLatest(propertyContactType.UPDATE_PROPERTY_CONTACT, updatePropertyContact)
  yield takeLatest(propertyContactType.DELETE_PROPERTY_CONTACT, deletePropertyContact)
}
