import { useState, useEffect, useCallback, FC } from 'react'
import { useDispatch } from 'react-redux'
import { history, isEmpty } from '../../../../helpers'
import { PunchlistLoader } from '../..'
import { authActions, configActions, userActions } from '../../../../ducks/actions'
import { TemplateProps } from './types'
import * as Sentry from '@sentry/react'

const Template: FC<TemplateProps> = ({ children, error, setError }) => {
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const [hash, setHash] = useState<string | null>(null)
  const [hashParsed, setHashParsed] = useState(false)

  const [loading, setLoading] = useState(true)

  const setPublicLoading = useCallback((value: boolean) => {
    dispatch(configActions.setConfigValue({
      type: 'publicLoading', value
    }))
  }, [dispatch])

  const hashFlags = useCallback((start: boolean): void => {
    setPublicLoading(start)
    setLoading(start)
    setHashParsed(true)
    setError && setError(!start)
  }, [setPublicLoading, setError])



  useEffect(() => {
    if (!isEmpty(hash) && !hashParsed) {
      hashFlags(true)
      dispatch(authActions.parseHashRequest({
        hash: hash as string,
        callback: (hashSuccess) => {
          if (hashSuccess) {
            dispatch(userActions.fetchCurrentUser((fetchSuccess) => {
              if (fetchSuccess) {
                setPublicLoading(false)
                replace('/')
              } else {
                dispatch(userActions.createUser(null, (createSuccess) => {
                  if (createSuccess) {
                    setPublicLoading(false)
                    // replace('/')
                  } else {
                    hashFlags(false)
                  }
                }))
              }
            }))
          } else hashFlags(false)
        }
      }))
    }
  }, [hash, hashParsed, hashFlags, replace, dispatch, setPublicLoading])

  useEffect(() => {
    if (hash === null) setHash(window.location.hash)
    else setLoading(false)
  }, [hash])

  return (
    <PunchlistLoader loading={loading || (!isEmpty(hash) && !error)}>
      {children}
    </PunchlistLoader>
  )
}

export default Template
