import { FC } from 'react'
import { Typography, Box } from '../../..'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { StatusBarProps, STATUS_BAR_STATE } from './types'
import clsx from 'clsx'

const StatusBar: FC<StatusBarProps> = ({ statusList, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  let barClass = classes.status
  if (statusList.length === 1) {
    switch (statusList[0].state) {
      case STATUS_BAR_STATE.HOLD: barClass = classes.barHold; break
      case STATUS_BAR_STATE.WARNING: barClass = classes.barWarning; break
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.bar, barClass)}>
        {statusList.map((status, index) => {
          let statusClass = classes.status
          if (statusList.length === 1) {
            statusClass = classes.statusFull
          }

          let stateClass = classes.text
          switch (status.state) {
            case STATUS_BAR_STATE.DONE: stateClass = classes.done; break
            case STATUS_BAR_STATE.TODO: stateClass = classes.text; break
            case STATUS_BAR_STATE.ACTUAL: stateClass = classes.actual; break
            case STATUS_BAR_STATE.HOLD: stateClass = classes.hold; break
            case STATUS_BAR_STATE.WARNING: stateClass = classes.warning; break
          }

          return (
            <Box key={index} className={clsx(classes.status, statusClass)}>
              <Typography
                variant='caption'
                className={clsx(classes.text, stateClass, statusClass)}
              >
                {status.text}
              </Typography>
              {index + 1 < statusList.length ? <div className={classes.dot} /> : ''}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default StatusBar
