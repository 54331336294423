import { Box } from 'components/UI'
import useStyles from './styles'
import { FC, useState } from 'react'
import { OPTIONS } from '../..'
import General from './options/General'
import Initial from './options/Initial'
import Calendly from './options/Calendly'

interface SpeakWithExpertProps {
  setActiveOption: (option: OPTIONS) => void
  setOpen: (open: boolean) => void
}

export enum SUB_OPTIONS {
  initial = 'initial',
  general = 'general',
  expert = 'expert',
}

const SpeakWithExpert: FC<SpeakWithExpertProps> = ({ setActiveOption, setOpen }) => {
  const styles = useStyles()
  const [activeSubOption, setActiveSubOption] = useState<SUB_OPTIONS>(SUB_OPTIONS.initial)

  const handleClick = (subOption: SUB_OPTIONS) => {
    if (subOption === SUB_OPTIONS.initial) setActiveOption(OPTIONS.initial)
    setActiveSubOption(subOption)
  }

  const options = [
    {
      content: <Initial setSubActiveOption={handleClick} />,
      id: 'initial'
    },
    {
      content: <General setSubActiveOption={(option: SUB_OPTIONS) => setActiveSubOption(option)} />,
      id: 'general'
    },
    {
      content: <Calendly setOpen={setOpen} setSubActiveOption={(option: SUB_OPTIONS) => setActiveSubOption(option)} />,
      title: 'speak with an expert',
      id: 'expert'
    },
  ]
  const activeOptionIndex = options.findIndex(option => option.id === activeSubOption)

  const content = options[activeOptionIndex]?.content ?? 'Nothing here..'

  return (
    <Box width={"100%"} display="flex" flexDirection="column" gridGap="16px" className={styles.root}>
      {content}
    </Box>
  )
}
export default SpeakWithExpert
