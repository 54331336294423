import storeInfo from 'ducks/store'
import { Estimate, EstimateContact, EstimateProperties } from 'ducks/types'
import { EstimateType } from './types'

export const getEstimate = () => (): EstimateType => {
  const { estimate } = storeInfo.store.getState()
  return estimate
}

export const isEstimateServiceable = () => (): boolean => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  return estimate?.properties?.territory?.serviceable || false
}

export const getEstimateProperties = () => (): Partial<EstimateProperties> => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  return estimate?.properties
}

export const getContactInEstimate = (userEmail: string) => (): Partial<EstimateContact> | null => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  return estimate?.properties?.contacts?.find(c => c.email === userEmail) ?? null
}

export const getContactIndexInEstimate = (userEmail: string) => () => {
  const { estimate }: { estimate: Estimate } = storeInfo.store.getState()
  return estimate?.properties?.contacts?.findIndex(c => c.email === userEmail)
}