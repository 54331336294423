import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    expired: {
      color: 'var(--expired-color)',
      backgroundColor: 'var(--expired-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      borderRadius: '8px',
      width: 'fit-content !important',
    },
    completed: {
      color: 'var(--completed-color)',
      backgroundColor: 'var(--completed-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      borderRadius: '8px',
      width: 'fit-content !important',
    },
    progress: {
      color: 'var(--bosscat-blue-700)',
      backgroundColor: 'var(--progress-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px'
    },
    scheduling: {
      color: 'var(--hardware-haze-600)',
      backgroundColor: 'var(--scheduling-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px'
    },
    hold: {
      color: 'var(--hold-color)',
      backgroundColor: 'var(--hold-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px'
    },
    dispatched: {
      color: 'var(--dispatched-color)',
      backgroundColor: 'var(--dispatched-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px'
    },
    approved: {
      color: 'var(--bosscat-blue-700)',
      backgroundColor: 'var(--bosscat-blue-100)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px'
    },
    neutral: {
      color: 'var(--bosscat-black-500)',
      backgroundColor: 'var(--progress-color-back)',
      padding: '2px 7.5px',
      textAlign: 'center',
      width: 'fit-content !important',
      borderRadius: '8px',
    },
    fitContent: {
      width: 'fit-content',
      padding: '.2rem 2rem'
    },
    shadow: {
      boxShadow: '0px 1px 1px rgba(9, 25, 49, 0.24), 0px 0px 1px rgba(9, 25, 49, 0.31)'
    },
    large: {
      width: '100%'
    },
    medium: {
      width: '70%'
    },
    small: {
      width: '50%'
    },
    inherit: {
      width: 'inherit'
    }
  })
)
