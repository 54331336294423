import axios from '../axios';
import { ApiController } from './types';

const base = '/inspections'

const apiController: ApiController = {
    createInspection: async (request) =>
    await axios
      .post(base, request)
      .then(response => response.data),
}

export default apiController