/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import * as Sentry from '@sentry/react'
import { Punchlist } from 'api'
import { homemartActions } from 'ducks/actions'
import { errorTextTryingTo } from 'helpers'
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { FetchPartnersAction, homemartTypes } from './types'
//import { PARTNERS_MOCK } from './reducer'

export function* fetchPartners({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const active = payload.active ?? false

    const partners = yield call(Punchlist.homemart.getPartners, active)

    yield put(homemartActions.setPartners(partners))

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the partners'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchTags({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const tags = yield call(Punchlist.homemart.getTags)
    yield put(homemartActions.setTags(tags))

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the tags'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchCustomerTypes({ payload, callback }: FetchPartnersAction): SagaIterator {
  let success = false
  try {
    const customerTypes = yield call(Punchlist.homemart.getCustomerTypes)
    yield put(homemartActions.setCustomerTypes(customerTypes))

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the tags'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(homemartTypes.FETCH_PARTNERS, fetchPartners)
  yield takeLatest(homemartTypes.FETCH_TAGS, fetchTags)
  yield takeLatest(homemartTypes.FETCH_CUSTOMER_TYPES, fetchCustomerTypes)
}
