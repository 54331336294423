import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 0px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
      padding: 16,
      backgroundColor: 'white',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'inherit'
      }
    },
    listItem: {
      display: 'flex',
      minHeight: '58px',
      justifyContent: 'space-between !important',
      width: 'inherit'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '30%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    body2: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '18px',
      color: 'var(--head-text-color)',

      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        marginBottom: '8px',
        fontFamily: 'Lato'
      }
    },
    price: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '18px',
      color: 'var(--head-text-color)',
      marginLeft: '1rem'
    }
  })
)
