import { authTypes } from 'ducks/auth/types'
import { Item } from 'ducks/types'
import { JobItemValues, JobItemsActions, jobItemsTypes, JobItemsType } from './types'

const initialState: JobItemsType = []

const reducer = (state = initialState, action: JobItemsActions): JobItemsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case jobItemsTypes.SET_JOB_ITEMS: {
      return payload as Item[]
    }

    case jobItemsTypes.SET_JOB_ITEM_VALUES: {
      const { itemId, attrs } = payload as JobItemValues

      const itemIndex = state.findIndex((item: Item) => {
        return item.itemId === itemId
      })

      if (itemIndex !== -1) {
        const itemCopy = state[itemIndex]
        const itemsCopy = [...state]
        itemsCopy[itemIndex] = { ...itemCopy, ...attrs }
        return itemsCopy
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
