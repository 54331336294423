import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: "24px",
            [theme.breakpoints.down('md')]: {
                marginLeft: "0px",
                marginTop: "24px"
            }
        },
        Title: {
            fontSize: "20px",
            fontFamily: "Lato",
            marginBottom: "24px",
            [theme.breakpoints.down('md')]: {
                display: "none"
            }
        },
        DocContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "24px",
            [theme.breakpoints.down('md')]: {
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginBottom: "16px",
                "&:not(:last-child)": {
                    paddingBottom: "32px",
                    borderBottom: "2px solid #EBECEF",
                }
            }
        },
        DocTitle: {
            fontSize: "18px",
            fontFamily: "Lato",
            marginBottom: "8px"
        },
        DocInfo: {
            display: "flex",
            flexDirection: "column"
        },
        DocSubtitle: {
            fontSize: "16px",
            color: "#494A5A",
            fontFamily: "LatoNormal"
        },
        DocIcon: {
            width: "60px",
            height: "60px",
            marginRight: "16px"
        },
        Buttons: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1,
            [theme.breakpoints.down('md')]: {
                marginTop: "16px",
                minWidth: "100%"
            }
        },
        ButtonPrim: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--bosscat-blue-600)",
            borderRadius: "8px",
            padding: "16px",
            color: "#fff",
            minWidth: "140px",
            cursor: "pointer",
            filter: "drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                fontSize: "18px"
            }
        },
        ButtonSec: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FAFBFF",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            color: "var(--bosscat-blue-600)",
            marginLeft: "16px",
            cursor: "pointer",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
                borderRadius: "8px",
                columnGap: "8px",
                fontSize: "18px"
            }
        },
    }))
