import { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useStyles from '../../../styles'
import CheckIcon from '@material-ui/icons/Check'
import { Table } from 'components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertyDetail, getTerritory } from 'ducks/selectors'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import { history } from 'helpers/index'
import DateFnsUtils from '@date-io/moment'
import { Insight } from 'ducks/types'
import InquireModal from '../../../Modals/InquireModal'
import { useIsXlScreen } from 'hooks/useMediaQuery'
import SystemInsightRow from '../../../RowComponents/SystemInsightRow'

const SystemInsights = () => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [xlScreen] = useIsXlScreen()

  const [costType, setCostType] = useState<string>('Good')
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(null)
  const [selectedItem, setSelectedItem] = useState<string>('')
  const [dateUpdating, setDateUpdating] = useState<boolean>(false)
  const [ordering, setOrdering] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const costTypeOptions = [
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Better',
      value: 'Better',
    },
    {
      label: 'Best',
      value: 'Best',
    },
  ]

  const { id: propertyId } = history.getParamValues()

  const property = useSelector(getPropertyDetail())
  const territory = useSelector(getTerritory())

  const {
    details: { insights: systemInsights },
  } = property

  const handleOptionChange = (newOption: string) => {
    setCostType(newOption)
  }

  const handleInstallDateChange = (
    date: MaterialUiPickersDate,
    insight: Insight
  ) => {
    setDateUpdating(true)
    dispatch(
      propertiesActions.updatePropertyProject(
        {
          propertyId,
          projectName: insight.item,
          request: {
            ...insight,
            installDate: `${((date?.month() || 0) + 1)
              .toString()
              .padStart(2, '0')}-${date?.year()}`,
          },
        },
        (succ) => {
          dispatch(
            propertyDetailActions.fetchPropertyDetail(
              { id: propertyId },
              (succ) => {
                setDateUpdating(false)
              }
            )
          )
        }
      )
    )
  }

  const getOptionStyle = (option: string) => {
    if (option === 'Good') {
      return styles.selectedGoodCostTypeOption
    } else if (option === 'Better') {
      return styles.selectedBetterCostTypeOption
    } else {
      return styles.selectedBestCostTypeOption
    }
  }

  const getDate = (date: string): Date => {
    if (!date) return new Date()
    const month = date.split('-')[0]
    const year = date.split('-')[1]
    const newDate = new Date()
    newDate.setFullYear(parseInt(year))
    newDate.setMonth(parseInt(month) - 1)

    return newDate
  }

  const handleInquire = (insight: Insight) => {
    // setInquiredItems([...inquiredItems, insight.item])
    setOrdering(true)

    const { requested, ...request } = insight

    dispatch(
      propertyDetailActions.orderInsights(
        { propertyId, item: insight.item, request: request as Insight },
        (succ) => {
          dispatch(
            propertyDetailActions.fetchPropertyDetail(
              { id: propertyId },
              (suc) => {
                setOrdering(false)
              }
            )
          )
        }
      )
    )
  }

  const shouldHighlight = (insight: Insight) => {
    const expireDate = new Date(getDate(insight.replacementDate))
    const currentDate = new Date()
    const closeToExpire =
      expireDate.getMonth() +
      expireDate.getFullYear() * 12 -
      (currentDate.getMonth() + currentDate.getFullYear() * 12)
    return closeToExpire <= 3
  }

  return (
    <Grid
      item
      container
      direction="column"
      style={{ marginTop: '24px', cursor: ordering ? 'wait' : '' }}
      spacing={2}
      className={`${dateUpdating ? styles.cursorLoading : ''}`}
    >
      {/* <Grid item container>
        <Box
          className={styles.containerBox}
          padding="8px"
          width="100%"
          bgcolor="var(--bosscat-green-100)"
          display="flex"
          flexDirection="column"
          style={{ gap: '8px' }}
        >
          <Box display="flex" color="var(--bosscat-green-600)">
            <EcoIcon style={{ width: '24px', height: '24px' }} />
            <Typography className={styles.lgFont}>
              = Green Rebate and Tax Incentives
            </Typography>
          </Box>
          <Typography className={styles.title}>
            Items with this indicator means you’ll have incentives and/or green
            rebates.
          </Typography>
        </Box>
      </Grid> */}
      <Grid item container>
        {xlScreen ? (
          <Box className={styles.containerBox} width="100%">
            <Table style={{ borderCollapse: 'separate' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={styles.cellHead}
                    align="center"
                    style={{ borderTopLeftRadius: '8px' }}
                  >
                    Category / Item
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Install Date
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Life Expectancy
                    <br /> (Years)
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Replacement Date
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Next Maintenance
                    <br /> (Coming Soon)
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Maintenance Cost
                    <br /> (Coming Soon)
                  </TableCell>
                  <TableCell className={styles.cellHead} align="center">
                    Replacement Cost
                    <Box
                      className={styles.containerBox}
                      width="100%"
                      marginTop="4px"
                    >
                      <Grid container>
                        {costTypeOptions.map((option) => (
                          <Grid
                            item
                            onClick={() => handleOptionChange(option.value)}
                            xs
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={
                              option.value === costType
                                ? getOptionStyle(option.value)
                                : styles.unSelectedCostTypeOption
                            }
                          >
                            <Typography className={styles.description}>
                              {option.label}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </TableCell>
                  {territory?.serviceable && (
                    <TableCell
                      className={styles.cellHead}
                      style={{ borderTopRightRadius: '8px' }}
                      align="center"
                    >
                      Service Request
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {systemInsights?.map((insight, index) => (
                  <TableRow>
                    <TableCell
                      className={styles.cell}
                      style={{
                        fontWeight: 700,
                        borderBottomLeftRadius:
                          index === systemInsights.length - 1 ? '8px' : 0,
                      }}
                    >
                      <Box className={styles.iconLabel}>
                        {insight.item}
                        {/* {insight.hasIcon && <img src={greenLeap} alt='green leap' />} */}
                      </Box>
                    </TableCell>
                    <TableCell
                      className={styles.cell}
                      align="center"
                      style={{ maxWidth: '12rem' }}
                    >
                      {/* {insight.installDate || '-'} */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form className={styles.calendarContainer} noValidate>
                          <KeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/yyyy"
                            views={['year', 'month']}
                            value={
                              insight.item === selectedItem
                                ? selectedDate
                                : insight.installDate
                                ? getDate(insight.installDate)
                                : null
                            } // Set your initial date value here
                            onChange={(date) => {
                              setSelectedDate(date)
                              setSelectedItem(insight.item)
                            }}
                            onClose={() =>
                              handleInstallDateChange(selectedDate, insight)
                            }
                            className={styles.textField}
                            InputAdornmentProps={{ position: 'start' }}
                            autoOk
                          />
                        </form>
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {insight.lifeExpectancy || '-'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={styles.cell}
                      style={{
                        color: shouldHighlight(insight)
                          ? 'var(--renovation-red-600)'
                          : 'var(--bosscat-black-600)',
                      }}
                    >
                      {insight.replacementDate}
                    </TableCell>
                    {/* <TableCell className={styles.cell} style={{ color: insight.nextMaintenanceColor ? insight.nextMaintenanceColor : 'var(--bosscat-black-600)' }}>{insight.nextMaintenance}</TableCell> */}
                    {/* <TableCell className={styles.cell}>
                    {insight.replacementDate || '-'}
                  </TableCell> */}
                    <TableCell className={styles.cell} align="center">
                      {insight.nextMaintenance || '-'}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">
                      {insight.costMaintenance
                        ? `$${insight.costMaintenance || 0}`
                        : '-'}
                    </TableCell>
                    <TableCell className={styles.cell} align="center">{`$${
                      (insight as any)[
                        `costReplacement${costType}`
                      ]?.toLocaleString() || 0
                    }`}</TableCell>
                    {territory?.serviceable && (
                      <TableCell
                        className={styles.cell}
                        style={{
                          borderBottomRightRadius:
                            index === systemInsights.length - 1 ? '8px' : 0,
                        }}
                      >
                        <Button
                          startIcon={
                            insight.requested ? (
                              <CheckIcon className={styles.buttonIcon} />
                            ) : (
                              <></>
                            )
                          }
                          variant="contained"
                          className={
                            insight.requested
                              ? styles.inquiredButton
                              : styles.inquireButton
                          }
                          style={{
                            height: '44px',
                            cursor: ordering ? 'wait' : 'pointer',
                          }}
                          fullWidth
                          onClick={() => {
                            if (!insight.requested) {
                              setModalOpen(true)
                              handleInquire(insight)
                            }
                          }}
                        >
                          <Typography
                            className={styles.lgFont}
                            style={{
                              textTransform: 'none',
                            }}
                          >
                            {insight.requested ? '' : 'Inquire'}
                          </Typography>
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <InquireModal open={modalOpen} setOpen={setModalOpen} />
          </Box>
        ) : (
          <Grid container item direction="column" style={{ gap: '16px' }}>
            {systemInsights?.map((insight) => (
              <SystemInsightRow
                insight={insight}
                selectedDate={selectedDate}
                selectedItem={selectedItem}
                setDateUpdating={setDateUpdating}
                setModalOpen={setModalOpen}
                setSelectedDate={setSelectedDate}
                setSelectedItem={setSelectedItem}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default SystemInsights
