/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { DocuSign } from 'api'
import { docuSignTypes, DocuSignActions } from './types'
import * as Sentry from '@sentry/react'

export function* getAccessToken({ payload, callback }: DocuSignActions): SagaIterator {
  let success = false
  try {
    const accessToken = yield call(DocuSign.getAccessToken, payload)
    if (accessToken) {
      success = true;
    }
    if (callback) yield call(callback, success, accessToken)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export function* getUserInfo({ payload, callback }: DocuSignActions): SagaIterator {
  let success = false
  try {
    const userInfo = yield call(DocuSign.getUserInfo, payload)
    if (userInfo) {
      success = true;
    }
    if (callback) yield call(callback, success, userInfo)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(docuSignTypes.GET_ACCESS_TOKEN, getAccessToken)
  yield takeLatest(docuSignTypes.GET_USER_INFO, getUserInfo)
}
