import { useEffect, FC, useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ScrollWrapper } from '../../../../templates'
import {
  Typography,
  Box,
  CreateRepair,
  Summary,
  RepairItem,
  Grid
} from '../../../../UI'
import {
  getNewRepairListAddress,
  getNewRepairListValue,
  getTerritory,
  getRepairItems
} from '../../../../../ducks/selectors'
import { icons, noItem } from '../../../../../assets'
import { FILE_TYPE, isEmpty, ParseFilesUrl } from '../../../../../helpers'
import { RepairListContext } from '..'
import { SummaryType } from '../types'
import useStyles from '../styles'
import { Item, PunchlistFile } from '../../../../../ducks/types'
import { filesActions, territoriesActions } from '../../../../../ducks/actions'
import env from '@beam-australia/react-env'
import { OptionType } from '../../../../UI/CustomUI/molecules/AutocompleteSearch/types'

const FILES_URL = env('FILES_URL') ?? ''

const RepairsStep: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const context = useContext(RepairListContext)

  const { saveNewValue, setCanGoForward, setShowWarnings } = context
  const savedItems = useSelector(getNewRepairListValue('items'))
  const territory = useSelector(getTerritory())
  const repairItems = useSelector(getRepairItems())
  const repairListAddress = useSelector(getNewRepairListAddress)

  const [title, setTitle] = useState('')
  const [selected, setSelected] = useState<OptionType | null>(null)
  const [options, setOptions] = useState<OptionType[]>([])

  const [requestedRepairNote, setRequestedRepairNote] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [images, setImages] = useState<Array<File>>([])

  const [countItems, setCountItems] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [errorRepair, setErrorRepair] = useState(false)

  const [items, setItems] = useState<any>(savedItems)
  const [summary, setSummary] = useState<SummaryType[]>([])

  const validate: Boolean =
    !isEmpty(title) && !isEmpty(requestedRepairNote) && quantity !== 0
  useEffect(() => {
    setCanGoForward(items.length > 0)
    saveNewValue('items', items)
    setCountItems(
      items.reduce((acc: number, obj: Item) => {
        return acc + obj.quantity
      }, 0)
    )
    setSummary(
      items.map((item: Item) => {
        return { name: item.title, count: item.quantity }
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (territory) {
      dispatch(
        territoriesActions.fetchRepairItems({
          territoryId: territory.id,
          search: title
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  useEffect(() => {
    if (repairItems.length > 0) {
      setOptions(
        repairItems.map(item => ({
          ...item,
          value: item.publicTitle,
          key: item.id
        }))
      )
    }
  }, [repairItems])

  const clearCreate = () => {
    setTitle('')
    setRequestedRepairNote('')
    setQuantity(0)
    setImages([])
    setErrorRepair(false)
    setUploading(false)
    setSelected(null)
  }

  const addNewRepair = (imageFiles: PunchlistFile[] = []) => {
    const newItems = [...items]
    const newItem: Partial<OptionType> = {
      ...selected,
      title: selected?.value,
      requestedRepairNote,
      quantity: Number(quantity),
      imageFiles
    }
    const { value, key, id, publicTitle, ...rest } = newItem
    newItems.push(rest)
    clearCreate()
    setItems(newItems)
  }

  const uploadImages = () => {
    dispatch(
      filesActions.uploadFiles(images as File[], (uploadSucc, fileNameList) => {
        if (
          uploadSucc &&
          fileNameList &&
          fileNameList.length === images.length
        ) {
          const imageFiles = images.map((_image, index) => {
            const fileName = fileNameList[index]
            return {
              name: fileName,
              fileUrl: ParseFilesUrl({
                baseUrl: FILES_URL,
                avatar: fileName ?? ''
              }),
              description: title,
              fileType: FILE_TYPE.INSPECTION_IMAGE
            }
          })
          addNewRepair(imageFiles as PunchlistFile[])
        } else setUploading(false)
      })
    )
  }

  const handleAdd = () => {
    if (!validate) setErrorRepair(true)
    else {
      setUploading(true)
      if (images) uploadImages()
      else addNewRepair()
    }
  }

  const handleDelete = (index: number) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  const handleChange = (item: Partial<Item>, index: number) => {
    const newItems = [...items]
    newItems[index] = item
    setItems(newItems)
  }

  const handleInput = (value: any) => {
    setTitle(value)
    if (territory) {
      dispatch(
        territoriesActions.fetchRepairItems({
          territoryId: territory.id,
          search: value
        })
      )
    }
  }

  const handleSelect = (value: any) => {
    setSelected(value)
    setTitle(value.value)
    setQuantity(1)
  }

  const handleAddRepairItem = (value: string) => {
    const newOption = {
      value: value,
      key: Math.floor(Math.random() * 100 + 1)
    }
    setOptions([...options, newOption])
    setQuantity(1)
    setTitle(newOption.value)
    setSelected(newOption)
  }

  const handleEnterKeyDown = (key: any, callback: () => void) => {
    if (key.key === 'Enter') {
      callback()
    }
  }

  return (
    <Box
      className={classes.column}
      style={{ display: 'grid', gridAutoRows: 'min-content min-content auto' }}
    >
      <Box className={classes.row}>
        <icons.House className={classes.iconHouse} />
        <Typography variant='body2' className={classes.textAddress}>
          {repairListAddress}
        </Typography>
      </Box>

      <CreateRepair
        title={title}
        options={options}
        selectedOption={selected}
        onSelect={handleSelect}
        requestedRepairNote={requestedRepairNote}
        quantity={quantity}
        images={images}
        uploading={uploading}
        onChangeTitle={handleInput}
        onChangeRequestedRepairNote={event =>
          setRequestedRepairNote(event.target.value)}
        onChangeQuantity={event => setQuantity(event.target.value)}
        onAddImages={event => setImages(event)}
        onAdd={() => handleAdd()}
        errorRepair={errorRepair}
        addRepairItem={handleAddRepairItem}
        onKeyDown={handleEnterKeyDown}
      />

      {!items.length
        ? (
          <Box className={classes.rowImage}>
            {' '}
            <img src={noItem} className={classes.image} alt='' />{' '}
          </Box>
        )
        : (
          <Grid container>
            <Grid item xs={12} md={4}>
              <Summary repairList={summary} count={countItems} />
            </Grid>
            <Grid item xs={12} md={8} className={classes.items}>
              <ScrollWrapper>
                {items.map((item: any, index: number) => {
                  return (
                    <RepairItem
                      key={index}
                      title={item.title}
                      requestedRepairNote={item.requestedRepairNote}
                      quantity={item.quantity}
                      imageFiles={item.imageFiles}
                      onDelete={() => handleDelete(index)}
                      onChange={(item: Partial<Item>) =>
                        handleChange(item, index)}
                    />
                  )
                })}
              </ScrollWrapper>
            </Grid>
          </Grid>
        )}
    </Box>
  )
}

export default RepairsStep
