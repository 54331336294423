import { Box, Typography } from '@material-ui/core'
import { reportGmailErrored } from 'assets/index'
import useStyles from '../../styles'

const ErrorBanner = () => {
  const styles = useStyles()

  return (
    <Box
      padding="8px"
      display="flex"
      borderRadius="8px"
      border="2px solid var(--border-border-error-light)"
      bgcolor="var(--renovation-red-100)"
      style={{ gap: '8px' }}
      alignItems='center'
    >
      <img src={reportGmailErrored} alt='error' />
      <Typography className={styles.lgFont} style={{ fontWeight: 400 }}>Fill all the information to add a new member</Typography>
    </Box>
  )
}

export default ErrorBanner
