import { FC, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useRouter from '../hooks/useRouter'
import { getConfigValue, getNewEstimateData, getNewEstimateValue, isAuthorized } from '../ducks/selectors'
import { OverlayLoader, PunchlistLoader } from '../components/templates'
import { history } from '../helpers'
import { RoutesType } from './types'
import { Pages, PagesViews } from '../components/pages/PublicPages'
import { authActions } from '../ducks/actions'

const PublicRouter = (): React.ComponentType => {
  const { id } = history.getParamValues()
  const pathname = history.getCurrentPath()
  const page = pathname.split('/')[1]

  const [, Router] = useRouter(id ? PagesViews : Pages, page, page)
  return Router
}

const PublicRoutes: FC<RoutesType> = ({ location, children }) => {
  const dispatch = useDispatch()
  const userIsloggedIn = useSelector(getNewEstimateValue('userIsloggedIn'))
  const overlayLoading = useSelector(getConfigValue('overlayLoading'))
  const authorized = useSelector(isAuthorized)

  if (!authorized && userIsloggedIn) {
    // clean persisted estimate data when auth0 token expired
    dispatch(
      authActions.logoutRequest({
        callback: (success) => {
          // if (success) replace('/')
        },
      })
    )
  }

  return (
    <Suspense fallback={<PunchlistLoader />}>
      <OverlayLoader loading={overlayLoading}>
        {children}
      </OverlayLoader>
    </Suspense>
  )
}

export default PublicRoutes
