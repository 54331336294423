import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../../styles'
import { Condition, Insight } from 'ducks/types'
import CostTypeRadio from 'components/UI/CustomUI/molecules/CostTypeRadio'
import { useState } from 'react'
import { Check } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import { history } from 'helpers/index'
import DateFnsUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ConditionType } from 'ducks/properties/types'
import {
  getPropertyDetail,
  getPropertyInquiredItems,
  getTerritory,
} from 'ducks/selectors'

const ConditionCostByRoomRow = ({
  condition,
  setModalOpen,
}: {
  condition: ConditionType
  setModalOpen: (open: boolean) => void
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const property = useSelector(getPropertyDetail())
  const inquiredItems = useSelector(getPropertyInquiredItems())
  const territory = useSelector(getTerritory())

  const handleInquire = (condition: ConditionType) => {
    dispatch(
      propertiesActions.requestPropertyRoom(
        { propertyId: property?.id, request: condition },
        () => {}
      )
    )
    dispatch(
      propertyDetailActions.setPropertyInquiredItems([
        ...inquiredItems,
        condition.room,
      ])
    )
    // setInquiredItems([...inquiredItems, condition.room])
  }

  const getDate = (date: string): Date => {
    if (!date) return new Date()
    const month = date.split('-')[0]
    const year = date.split('-')[1]
    const newDate = new Date()
    newDate.setFullYear(parseInt(year))
    newDate.setMonth(parseInt(month) - 1)

    return newDate
  }

  const props = [
    {
      title: (
        <Typography
          className={styles.latoSmFont}
          style={{ color: 'var(--text-text-info)' }}
        >
          Good
        </Typography>
      ),
      component: (
        <Typography className={styles.title}>
          {`$${condition.costGood?.toLocaleString()}`}
        </Typography>
      ),
    },
    {
      title: (
        <Typography
          className={styles.latoSmFont}
          style={{ color: 'var(--text-text-success)' }}
        >
          Better
        </Typography>
      ),
      component: (
        <Typography className={styles.title}>
          {`$${condition.costBetter?.toLocaleString()}`}
        </Typography>
      ),
    },
    {
      title: (
        <Typography
          className={styles.latoSmFont}
          style={{ color: 'var(--text-text-warning)' }}
        >
          Best
        </Typography>
      ),
      component: (
        <Typography className={styles.title}>
          {`$${condition.costGood?.toLocaleString()}`}
        </Typography>
      ),
    },
  ]

  return (
    <Grid
      container
      item
      className={styles.containerBox}
      direction="column"
      style={{ padding: '12px', gap: '8px' }}
    >
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          style={{ width: 'fit-content' }}
        >
          <Grid item>
            <Typography className={styles.lgFont}>
              {condition.room || ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={styles.latoSmFont}
              style={{ color: 'var(--text-text-tertiary)' }}
            >
              Project Count:
              <Typography style={{ color: 'var(--text-text-primary)' }}>
                {condition.projectCount}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {territory?.serviceable && (
          <Grid item>
            <Button
              startIcon={
                inquiredItems.includes(condition.room) ? (
                  <Check className={styles.buttonIcon} />
                ) : (
                  <></>
                )
              }
              variant="contained"
              className={
                inquiredItems.includes(condition.room)
                  ? styles.inquiredButton
                  : styles.inquireButton
              }
              style={{
                height: '44px',
              }}
              fullWidth
              onClick={() => {
                if (!inquiredItems.includes(condition.room)) {
                  setModalOpen(true)
                  handleInquire(condition)
                }
              }}
            >
              <Typography
                className={styles.lgFont}
                style={{
                  textTransform: 'none',
                }}
              >
                {inquiredItems.includes(condition.room) ? '' : 'Inquire'}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item>
        {props.map((prop) => (
          <Grid item xs={4}>
            {prop.title}
            {prop.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default ConditionCostByRoomRow
