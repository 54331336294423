import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 1,
      width: '673px',
      height: 'auto',
      [theme.breakpoints.down('lg')]: {
        width: '480px',
      },
      [theme.breakpoints.down('md')]: {
        width: '320px',
      },
      [theme.breakpoints.down('xl')]: {
        width: 'fit-content',
        minWidth: '320px',
      },
      marginTop: '10px',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      paddingBottom: 20,
    },
    date: {
      width: '100% !important',
    },
    day: {
      fontSize: 16,
    },
    selectedDay: {
      color: 'var(--white-color)',
    },
    unselectedDay: {
      color: 'var(--black-color)',
    },
    today: {
      borderRadius: 15,
      width: '40%',
      height: 3,
      position: 'absolute',
      top: 20,
    },
    todaySelected: {
      backgroundColor: 'var(--white-color)',
    },
    todayUnselected: {
      backgroundColor: 'var(--bosscat-blue-600)',
    },
    comboParent: {
      width: '100%',
      height: '54px',
      // marginTop: '8px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      cursor: 'pointer',
      background: 'white',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '@global': {
        '.MuiInputBase-input': {
          cursor: 'pointer',
        },
      },
    },
    bar: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '56px',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: '8px',
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      padding: '2px',
      color: '#5B5D71',
      textTransform: 'none',
    },
    // OVERRIDES CALENDAR
    month: {
      padding: 0,
      width: '100%',
      left: -5,
    },
    days: {
      height: '100%',
    },
    dayToday: {
      display: 'flex',
      flexDirection: 'column',
    },
    dayNumber: {
      //display: 'flex',
      flexDirection: 'column',
      //height: 22
    },
    dateRangePickerWrapper: {
      display: 'flex',
      width: '100%',
    },
    calendarWrapper: {
      width: '100%',
      height: '100%',
    },
  })
)
