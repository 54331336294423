import storeInfo from 'ducks/store'
import { PriceItem, Territory } from 'ducks/types'

export const getRepairItems = () => (): PriceItem[] => {
  const {
    territories: { repairItems },
  } = storeInfo.store.getState()
  if (!repairItems || !repairItems.length) return []
  return repairItems
}

export const getTerritory = () => (): Territory | null => {
  const {
    territories,
  } = storeInfo.store.getState()
  const territory = territories?.territory || null
  return territory || null
}

export const getTerritories = () => (): Territory[] => {
  const {
    territories,
  } = storeInfo.store.getState()
  return territories?.territories || []
}
