import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { FC, useCallback, useState } from 'react'
import useStyles from './../../styles'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate } from 'ducks/selectors'
import { icons } from 'assets'
import { useStepperContext } from 'hooks/useStepperContext'

const HomeownershipConfirmation: FC = () => {
  const styles = useStyles()
  const estimate = useSelector(getEstimate())

  const { saveNewValue } = useStepperContext()

  const [success, setSuccess] = useState(
    estimate?.properties?.homeownershipConfirmation
  )

  const address = estimate?.properties?.fullAddress || ''

  const handleSubmit = useCallback(() => {
    saveNewValue('homeownershipConfirmation', true)
    setSuccess(true)
  }, [saveNewValue])

  return (
    <Grid
      className={styles.grayBox}
      style={{
        boxShadow:
          '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
        margin: '.1rem',
      }}
    >
      <Typography className={styles.title}>
        🏠 homeownership confirmation
      </Typography>
      <Typography style={{ display: 'inline', fontSize: '18px' }}>
        I confirm that I am the legal owner of:
        <Typography
          style={{ display: 'inline', marginLeft: '.2rem', fontSize: '18px' }}
          className={styles.bold}
        >
          {address}
        </Typography>
      </Typography>
      <Button
        className={`${styles.buttonSubmit} ${styles.buttonConfirm} ${
          success ? styles.buttonSuccess : ''
        }`}
        startIcon={<icons.CheckCircle style={{ width: '.9rem' }} />}
        onClick={handleSubmit}
        disabled={success}
      >
        <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {success ? 'Confirmed' : 'Confirm'}
        </Typography>
      </Button>
    </Grid>
  )
}
export default HomeownershipConfirmation
