import { Box, Grid } from "components/UI"
import ImageBadge from "components/UI/CustomUI/molecules/ImageBadge";
import { useState } from "react"

import useStyles from './styles';
import giftImg from 'assets/icons/maintenance/gift.jpg'
import { USER_TYPE } from "helpers/constants";
import { getOrderMaintenanceValue, getUser } from "ducks/selectors";
import { useSelector } from "react-redux";

const GiftSetModal = () => {
  const user = useSelector(getUser)
  const gift = useSelector(getOrderMaintenanceValue('gift'))

  const classes = useStyles()
  const userIsHomeowner = [USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(user.clientType)


  const showHomeownerText = userIsHomeowner || !gift

  const items = [
    'Two BOSSCAT branded Yeti® ramblers',
    'Musee® Candle',
    'BOSSCAT branded Moleskine® home journal',
    'BOSSCAT executive pen',
    'Overview of seasonal services with QR code to access the VIP Home Maintenance Membership Dashboard',
  ]

  if (!showHomeownerText) {
    items.push('Personalized message from the gift giver (optional)')
  }

  return (
    <Grid
      className={classes.container}>
      <ImageBadge
        img={giftImg}
        alt="Gift box image"
        maxHeight={276} />
      <ul>
        {items.map(item => <li className={classes.item}>{item}</li>)}
      </ul>
    </Grid>
  )
}
export default GiftSetModal