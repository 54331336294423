import { authTypes } from 'ducks/auth/types'
import { Resource } from './saga'
import { DashboardActions, DashboardType, dashboardTypes } from './types'

const initialState: DashboardType = null

const reducer = (state = initialState, action: DashboardActions): any => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case dashboardTypes.SET_DASHBOARD_DATA: {
      return payload as Resource[]
    }

    default:
      return state
  }
}

export default reducer
