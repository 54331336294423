/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import env from '@beam-australia/react-env'
import { useClassName } from '../../../../../../hooks'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {
  Box,
  PaymentStatus,
  Typography,
  Button,
  PaymentModal,
} from '../../../../../UI'
import {
  formatTimestamp,
  history,
  isEmpty,
  RESOURCE_TYPE,
  round,
} from '../../../../../../helpers'
import {
  getEstimate,
  getNewEstimateData,
  getNewEstimateExpediteOption,
  getNewEstimateValue,
  getPaymentOptions,
  isAuthorized,
  isRegisterCompleted,
} from '../../../../../../ducks/selectors'
import { blueLogo, computer, streamlinedOnline, logo, success, icons } from '../../../../../../assets'
import useStyles from './styles'
import { Estimate } from '../../../../../../ducks/types'
import {
  configActions,
  newEstimateActions,
  paymentsActions,
} from '../../../../../../ducks/actions'
import { toast } from 'react-toastify'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)


const formatDate = 'MMMM Do, YYYY'

const Details: FC<{
  setPage: (page: 'success' | 'expedite' | 'register') => void
}> = ({ setPage }) => {
  const classes = useStyles()
  const classNames = useClassName()
  const dispatch = useDispatch()
  const authorized = useSelector(isAuthorized)


  const clientSecretIntent =
    new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    ) ?? undefined

  const _estimate = useSelector(getEstimate())
  const newEstimate = useSelector(getNewEstimateData())

  const estimateData = _estimate || newEstimate

  const estimate = estimateData
  const registerCompleted = useSelector(isRegisterCompleted)

  const {
    createdOn,
    dueOn,
    properties,
    id: estimateId,
  } = (estimate as Estimate) || {}
  const { publicId } = (properties as any) || {}
  const push = history.usePush()

  const paymentOptions = useSelector(getPaymentOptions())
  const paymentOption = paymentOptions.find(paymentOption => paymentOption.id === estimate?.deliveryServiceId)
  const expediteOption = useSelector(getNewEstimateExpediteOption(paymentOption, paymentOptions))
  const savedSecret = useSelector(getNewEstimateValue('clientSecret'))
  const isExpedite = useSelector(getNewEstimateValue('isExpedite'))

  const expeditePrice =
    (expediteOption?.price ?? 0) - (paymentOption?.price ?? 0)

  const showExpedite = expediteOption !== null && paymentOption !== null && expeditePrice > 0

  const [clientSecret, setClientSecret] = useState(savedSecret)

  const handleExpediteSuccess = useCallback(() => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    expediteOption && dispatch(
      newEstimateActions.updateEstimatePaymentOption(
        expediteOption,
        (success, estimateId) => {
          if (success && estimateId) {
            toast.success('Estimate Updated!')
            dispatch(
              newEstimateActions.setNewEstimateValues({
                attrs: {
                  clientSecret: '',
                  isExpedite: false,
                  paymentOption: expediteOption,
                },
              })
            )
          }
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
        }
      )
    )
  }, [dispatch, expediteOption])

  useEffect(() => {
    if (isExpedite && clientSecretIntent === clientSecret) {
      handleExpediteSuccess()
    }
  }, [clientSecret, handleExpediteSuccess, isExpedite])

  const handleSaveExpediteInfo = (clientSecret: string) => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: { clientSecret, isExpedite: true },
      })
    )
  }

  const setOverlay = (overlay: boolean) => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: overlay })
    )
  }

  const handleExpedite = () => {
    if (expediteOption) {
      if (expediteOption.price > 0) {
        setOverlay(true)
        dispatch(
          paymentsActions.fetchStripeSecret(
            {
              amount: expeditePrice,
              resourceId: estimateId,
              resourceType: RESOURCE_TYPE.ESTIMATE,
              memo: properties?.address?.line_1 ? `Expedite BOSSCAT Estimate: ${properties?.address?.line_1}` : undefined
            },
            (secret?: string) => {
              if (secret && !isEmpty(secret)) {
                setClientSecret(secret)
                handleSaveExpediteInfo(secret)
              } else setOverlay(false)
            }
          )
        )
      }
    }
  }

  const handleClose = () => {
    setOverlay(false)
    setClientSecret('')
  }



  if (isEmpty(estimate)) return <div />

  const handleCloseModal = () => {
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          isExpedite: false,
        },
      })
    )
  }

  return (
    <Elements
      aria-label='success-details'
      stripe={stripePromise}
      options={{ clientSecret: clientSecretIntent ?? undefined }}
    >
      <PaymentStatus
        clientSecret={clientSecretIntent ?? ''}
        clear={() => dispatch(newEstimateActions.cleanNewEstimate())}
      >
        <Box className={classes.root}>
          <a href="https://www.bosscathome.com/" target="_blank" rel="noreferrer">
            <img src={logo} className={classes.logo} alt="BOSSCAT Logo" />
          </a>
          <img src={success} className={classes.success} alt="success" />
          <Typography className={classes.nextHeavyHardware}>
            YOUR ORDER IS CONFIRMED!
          </Typography>
          <Typography className={classes.header}>
            Thanks for submitting your {' '}
            <span className={classes.bold}>{`Estimate Request #${publicId}.`}</span>
          </Typography>
          <Typography className={classes.desc}>
            We’ll get started on your estimate right away
          </Typography>
          <Typography className={classes.desc}>
            and let you know when it’s ready!
          </Typography>
          <Box className={classes.estimatedTimeContainer}>
            <Box className={classes.estimatedTimeItem}>
              <Typography className={classes.estimatedTimeLabel}>
                Estimate Delivery Date:
              </Typography>
              <Box
                className={classNames(
                  classes.estimatedTime,
                  classes.borderBlue
                )}
              >
                <Typography
                  variant="subtitle1"
                  className={classNames(
                    classes.estimatedTimeLabel,
                    classes.estimatedTimeDate,
                    classes.bold
                  )}
                >
                  {formatTimestamp(dueOn || null, formatDate)}
                </Typography>
              </Box>
            </Box>
          </Box>
          {showExpedite && (
            <PaymentModal
              open={
                showExpedite &&
                !isEmpty(clientSecret) &&
                expeditePrice > 0 &&
                !isEmpty(estimateId) && isExpedite
              }
              setOpen={handleClose}
              resourceId={estimateId}
              address={properties?.address?.fullAddress}
              clientSecret={clientSecret}
              price={expeditePrice}
              onSuccess={handleExpediteSuccess}
              returnUrl={`${window.location.href}`}
              handleCloseModal={handleCloseModal}
            />
          )}
          {showExpedite && (
            <Box className={classes.expedite}>
              <Typography className={classes.sooner}>
                Need it sooner?
              </Typography>
              <Typography>
                Expedite your Estimate and get it in 1 business day!
              </Typography>
              <Button
                variant="contained"
                className={classes.expediteButton}
                onClick={handleExpedite}
              //onClick={() => setPage('expedite')}
              >
                Expedite for ${round(expeditePrice, 2)}
              </Button>
            </Box>
          )}
          {/* commented because user will login before
          {authorized && registerCompleted && (
            <Box className={classes.letsLogin}>
              <Box className={classes.grid}>
                <Typography className={classNames(classes.nextArt, classes.bold) }>LET’S LOGIN!</Typography>
                <Typography className={classes.desc2}>Let’s login into the portal to see all your orders.</Typography>
                <Button
                variant='contained'
                className={classNames(classes.button, classes.bold)}
                endIcon={<icons.ArrowForward />}
                onClick={() => push('/login')}
              >
                Go to BOSSCAT Portal
              </Button>
              </Box>
              
              </Box>
          )} */}
          {authorized && !registerCompleted && (
            <Box className={classes.completeProfile}>
              <img alt="add new member icon" width={72} src={computer} />
              <Box>
                <Typography className={classes.ask}>Great work! Now it’s time to finish creating your BOSSCAT Profile</Typography>
                <Typography style={{ fontSize: '14px !important', lineHeight: '1.2' }} className={classes.desc2}>Complete your profile to see your Estimate when it is ready. Just 2 simple steps!</Typography>
              </Box>
              <Button
                variant="contained"
                className={classes.createButton}
                onClick={() => push('u/register')}
              >Complete my Profile</Button>
            </Box>
          )}
          {!authorized && (
            <Box className={classes.createAccount}>
              {/* <img className={classes.icon} src={streamlinedOnline} alt="" /> */}
              <Typography variant="body1" className={classes.createAccountAsk}>
                We’ve created an account for you in the bosscat portal
              </Typography>
              <Typography className={classes.createAccountDesc1}>
                Check your Email for your Password.
              </Typography>
              <Typography className={classes.createAccountDesc2}>
                You will need to log in to your account to view the Estimate.
              </Typography>
              <Button
                variant="contained"
                className={classes.createAccountCreateButton}
                onClick={() => {
                  // setPage('register')
                  push('login')
                }}
                endIcon={<ArrowForwardIcon style={{ width: '14px', height: '14px' }} />}
              >
                Go to BOSSCAT Portal
              </Button>
            </Box>
          )}
        </Box>
      </PaymentStatus>
    </Elements >
  )
}
export default Details
