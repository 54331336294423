import { FC, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  YearPicker,
} from '../../../../../UI'
import { DatePickModalProps } from './types'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from '../../styles'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MONTH_OPTIONS, YEAR_OPTIONS } from 'helpers/constants'
import { Insight } from 'ducks/types'

const DatePickModal: FC<DatePickModalProps> = ({ open, setOpen, item, updateDate }) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [year, setYear] = useState<any>(null)
  const [month, setMonth] = useState<any>(null)

  const { push } = useHistory()

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveClick = () => {
    if (year && month) {
      updateDate({ year: year.key, month: month.key }, item as Insight)
    }
    handleClose()
  }

  const handleYearChange = (value: any) => {
    setYear(value)
  }

  const handleMonthChange = (value: any) => {
    setMonth(value)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ padding: 0 }}
      PaperProps={{
        style: {
          borderRadius: '16px 16px 0 0',
          width: '100vw',
          maxWidth: '100vw',
          margin: 0,
          position: 'fixed',
          bottom: 0,
        },
      }}
      fullWidth
    >
      <Grid container direction="column" style={{ padding: '16px' }}>
        <Box display="flex" justifyContent="flex-end">
          <Button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon style={{ width: '14px', height: '14px' }} />
          </Button>
        </Box>
        <Grid
          item
          container
          style={{
            gap: '16px',
            borderRadius: '0 0 8px 8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'center'
          }}
        >
          <Grid item>
            <Typography className={styles.lgFont} style={{ fontWeight: 700 }}>
              {item?.item}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={styles.lgFont} style={{ fontWeight: 400 }}>
              Install Date
            </Typography>
          </Grid>
          <Grid item container>
            <Typography className={styles.lgFont} style={{ fontWeight: 700 }}>Month</Typography>
            <Autocomplete
              options={MONTH_OPTIONS}
              onChange={handleMonthChange}
              value={month}
              inputProps={{ style: { width: '100%' }, inputMode: "none", readOnly: true }}
              className={styles.dateSelect}
              placeholder='e.g. January...'
            />
          </Grid>
          <Grid item container>
            <Typography className={styles.lgFont} style={{ fontWeight: 700 }}>Year</Typography>
            <Autocomplete
              options={YEAR_OPTIONS}
              onChange={handleYearChange}
              value={year}
              inputProps={{ style: { width: '100%' }, inputMode: "none", readOnly: true }}
              className={styles.dateSelect}
              placeholder='e.g. 2012...'
            />
          </Grid>
          <Grid item container>
            <Button
              style={{
                backgroundColor: 'var(--bosscat-grey-1000)',
                height: '60px',
                borderRadius: '8px',
                color: 'white'
              }}
              onClick={handleSaveClick}
              fullWidth
            >
              <Typography className={styles.lgFont} style={{ textTransform: 'none' }}>Save</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DatePickModal
