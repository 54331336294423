import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    comboUnit: {
      width: '100%',
      minHeight: '110px',
      marginTop: '2px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    inputError: {
      borderColor: 'var(--expired-color) !important'
    },
    button: {
      // width: '180px',
      height: '40px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      lineHeight: 1,
      border: '3px solid',
      [theme.breakpoints.down('lg')]: {
        width: '120px',
        height: '60px'
      }
    },
    repairOption: {
      margin: '0 16px 0 8px',
      padding: '16px',
      alignItems: 'center',
      backgroundColor: 'rgba(249, 250, 250, 1)',
      borderRadius: 8
    },
    container: {
      "@global": {
        ".MuiGrid-item": {
          alignItems: 'flex-end'
        }
      }
    },
    topSeparation: {
      marginTop: '16px'
    },
    multiline: {
      width: '95%'
    }
  })
)
