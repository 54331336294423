import { Job } from 'ducks/types'

/** TYPES **/
export const jobTypes = {
  FETCH_JOB: 'FETCH_JOB',
  SET_JOB: 'SET_JOB',
  CREATE_JOB: 'CREATE_JOB'
}

/** ACTIONS **/
export interface FetchJobAction {
  type: typeof jobTypes.FETCH_JOB
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetJobAction {
  type: typeof jobTypes.SET_JOB
  payload: Job
}

export interface CreateJobAction {
  type: typeof jobTypes.CREATE_JOB
  payload: Partial<Job>
  callback?: (succ: boolean) => void
}

export type JobActions =
  | FetchJobAction
  | SetJobAction
  | CreateJobAction

/** REDUCER **/
export type JobType = Job | null
