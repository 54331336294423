import { Image, Page, Text, View } from '@react-pdf/renderer'
import styles from './style'
import { bosscatWarranty } from 'assets/index'

const ItemView = ({ item }) => {
  return (
    <View
      style={{
        ...styles.bodyItem,
        backgroundColor: 'white',
        ...styles.evaluateItem,
      }}
    >
      <View style={[styles.containerHorizontal, styles.justifyBetween]}>
        <View
          style={{
            ...styles.row,
            ...styles.justifyBetween,
            ...styles.mv1,
          }}
        >
          {item.inspectionReportReference && (
            <Text
              style={{
                ...styles.itemText,
                ...styles.green700,
                ...styles.latoNormal,
                ...styles.mr1,
              }}
            >
              {item.inspectionReportReference}
            </Text>
          )}
          <Text
            style={{
              ...styles.itemText,
            }}
            render={() => `${item.title}`}
          />
          <Text
            style={{
              ...styles.ml1,
              ...styles.itemText,
              ...styles.grayColor,
              ...styles.latoNormal,
            }}
          >
            (Qty: {item.quantity})
          </Text>
        </View>
      </View>
      {/* {item?.disclaimer ? (
        <View
          style={[
            styles.containerHorizontal,
            styles.content,
            styles.mv1,
            styles.w90,
          ]}
        >
          <Text
            style={[styles.itemText, styles.mr1]}
            render={() => 'Disclaimer: '}
          />
          <Text style={[styles.itemText]}>{item.disclaimer}</Text>
        </View>
      ) : null} */}
    </View>
  )
}

const PageHeader = ({ userInfo }) => {
  return (
    <View
      style={{
        ...styles.white,
        ...styles.fontXLarge,
        ...styles.nextArtSemiBold,
        ...styles.textCenter,
        ...styles.page3Header,
      }}
    >
      <Text style={{ ...styles.mb6 }}>YOUR BOSSCAT ESTIMATE FOR</Text>
      <Text
        style={{
          ...styles.white,
          ...styles.fontMedium,
          ...styles.latoBold,
          ...styles.textCenter,
        }}
      >
        {userInfo?.address?.fullAddress}
      </Text>
    </View>
  )
}

const Page4 = ({ userInfo, items }) => {
  if (items?.length === 0 || !items) return <></>
  return (
    <Page size="A4" wrap>
      <View style={{ ...styles.backgroundBlue900, ...styles.page4 }}>
        <PageHeader userInfo={userInfo} />

        <View style={styles.bodyItems}>
          <View style={styles.evaluateBodyItems}>
            <Text
              style={{
                ...styles.nextArtBold,
                ...styles.fontSmall,
                fontWeight: 'black'
              }}
            >
              Items that Need More Information
            </Text>
            <Text
              style={{
                ...styles.latoNormal,
                ...styles.fontXSmall,
                ...styles.mh1,
              }}
            >
              Additional Information is needed to provide a precise estimate. Please consult with a professional in your area.
            </Text>
            <View>
              {items?.map((item, indx) => (
                <View key={indx}>
                  <ItemView item={item} />
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          ...styles.bosscatWarrantyFooter,
          ...styles.row,
          ...styles.containerHorizontal,
        }}
      >
        <Image
          src={bosscatWarranty}
          style={{ ...styles.bosscatWarranty, ...styles.mr4 }}
          alt="1 year warranty"
        />

        <View
          style={
            {
              // height: '100%',
              // justifyContent: 'space-between',
              // alignItems: 'space-between',
            }
          }
        >
          <Text
            style={{
              ...styles.fontLarger,
              ...styles.nextArtSemiBold,
              ...styles.white,
              ...styles.mb2,
            }}
          >
            QUALITY GUARANTEED
          </Text>
          <Text
            style={{
              ...styles.latoNormal,
              ...styles.white,
              fontSize: '14px',
            }}
          >
            Because we are a one-stop solution for any home project, you can
          </Text>
          <Text
            style={{
              fontSize: '14px',
              ...styles.latoNormal,
              ...styles.white,
            }}
          >
            count on us to deliver consistent results, regardless of the
          </Text>
          <Text
            style={{
              fontSize: '14px',
              ...styles.latoNormal,
              ...styles.white,
            }}
          >
            circumstances, backed by our one-year labor warranty.
          </Text>
        </View>
      </View>
    </Page>
  )
}

export default Page4
