import storeInfo from 'ducks/store'
import { Partner } from 'ducks/types'
import { Tag } from './types'

export const getPartners = (
  active?: boolean
) => (): Partner[] => {
  // return PARTNERS_MOCK;
  const { homemart: { partners } } = storeInfo.store.getState()
  if (!partners || !partners.length) return []
  if (active) return partners.filter((partners: any) => partners.active === active) || []
  return partners
}

export const getTags = (): Tag[] => {
  // return PARTNERS_MOCK;
  const { homemart: { tags } } = storeInfo.store.getState()
  if (!tags || !tags.length) return []
  return tags
}

export const getTagsForDropdown = (): any[] => {
  // return PARTNERS_MOCK;
  const { homemart: { tags } } = storeInfo.store.getState()
  if (!tags || !tags.length) return []
  return [{ key: "All Services", value: "All Services", id: "All Services" }, ...tags.map((tag: Tag) => ({ ...tag, value: tag.name, key: tag.id }))]
}

export const getCustomerTypesForDropdown = (): any[] => {
  // return PARTNERS_MOCK;
  const { homemart: { customerTypes } } = storeInfo.store.getState()
  if (!customerTypes || !customerTypes.length) return []
  return [{ key: "All Roles", value: "All Roles", id: "All Roles" }, ...customerTypes.map((tag: Tag) => ({ ...tag, value: tag.name, key: tag.id }))]
}
