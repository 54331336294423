import { Grid } from '../../../../../../../UI'
import useStyles from './../../../styles'
import PaymentScreen from '../../../components/PaymentScreen'
import { useStepperContext } from 'hooks/useStepperContext'
import { useEffect } from 'react'

const Step3SecondaryHomeowner = () => {
  const classes = useStyles()
  const { setShowWarnings, setCanGoForward } = useStepperContext()

  useEffect(() => {
    setShowWarnings(false)
    setCanGoForward(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid className={classes.container} spacing={2}>
      <PaymentScreen />
    </Grid>
  )
}

export default Step3SecondaryHomeowner
