import { FC } from 'react'
import { TextFieldProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'
import { icons } from '../../../../../assets'

const Progress: FC<TextFieldProps> = ({ value, className }) => {
  const classes = useStyles()
  const classNames = useClassName()

  const getTitleColor = (): string => {
    if (value >= 60) {
      return classes.completed
    } else {
      return classes.progress
    }
  }

  return (
    <div className={classNames(classes.top, className ?? '')}>
      {value === 0 ? <icons.ReportProblem className={classes.icon} /> : null}
      <div className={classes.root}>
        <div style={{ width: value }} className={classNames(getTitleColor())} />
      </div>
    </div>
  )
}

export default Progress
