import storeInfo from 'ducks/store'
import {
  formatTimestamp,
  JOB_PUBLIC_STATUS,
  RESOURCE_TYPE,
} from 'helpers'
import { Estimate, Job } from 'ducks/types'
import { DashboardData, DashboardIndicators } from './types'

export interface Resource {
  type: RESOURCE_TYPE
  data: Estimate | Job
}

export const getDashboardData = () => (): DashboardData[] => {
  const { dashboard } = storeInfo.store.getState() || {}

  const response = dashboard?.map((resource: Resource) => {
    const { type, data } = resource

    if (!data || !data.properties) {
      return {}
    }

    const {
      line_1: street,
      line_2: unit,
      city,
      state,
    } = data.properties.address || {}
    let completedDate = '-'
    if (type === RESOURCE_TYPE.ESTIMATE) {
      completedDate = (data as Estimate).deliveredOn
        ? formatTimestamp((data as Estimate).deliveredOn, 'MM/DD/YYYY')
        : '-'
    }
    if (type === RESOURCE_TYPE.JOB) {
      completedDate = (data as Job).completedOn
        ? formatTimestamp((data as Job).completedOn, 'MM/DD/YYYY')
        : '-'
    }

    return {
      resourceId: data.id,
      resourceType: type,
      title: street?.concat(' ', unit ?? ''),
      address: city?.concat(', ', state ?? ''),
      createdOn: data.createdOn,
      initDate: formatTimestamp(data.createdOn, 'MM/DD/YYYY'),
      completedDate,
      tag: data.publicStatus,
      publicId: data.properties.publicId
    }
  })

  return response
}

export const getDashboardIndicators = () => (): DashboardIndicators => {
  const { dashboard } = storeInfo.store.getState() || {}

  const estimates = dashboard
    ?.filter((resource: Resource) => resource.type === RESOURCE_TYPE.ESTIMATE)
    .map((resource: Resource) => {
      return resource.data
    })
  const jobs = dashboard
    ?.filter((resource: Resource) => resource.type === RESOURCE_TYPE.JOB)
    .map((resource: Resource) => {
      return resource.data
    })

  const totalEstimates = estimates?.length
  const totalJobs = jobs?.length ?? 0
  const completedJobs = jobs?.filter(
    (job: Job) => job.publicStatus === JOB_PUBLIC_STATUS.COMPLETED
  ).length

  return {
    estimates: totalEstimates,
    activeOrders: totalJobs - completedJobs,
    completedJobs,
    estimatesCount: totalEstimates,
    jobsCount: totalJobs,
  }
}

export const getDashboardChartData = (): any => {
  const { dashboard } = storeInfo.store.getState() || {}

  const estimatesTotal = dashboard?.filter(
    (resource: Resource) => resource.type === RESOURCE_TYPE.ESTIMATE
  ).length
  const jobsTotal = dashboard?.filter(
    (resource: Resource) => resource.type === RESOURCE_TYPE.JOB
  ).length

  return {
    labels: ['Estimates', 'Jobs'],
    datasets: [
      {
        label: '',
        backgroundColor: ['#2F7BF7', '#FFC633'],
        labelColor: ['#FFF', '#1A2A55'],
        hoverOffset: 1,
        data: [estimatesTotal, jobsTotal],
      },
    ],
  }
}

export const getDashboardChartInfo = (): any => {
  const { dashboard } = storeInfo.store.getState() || {}

  const estimatesTotal = dashboard?.filter(
    (resource: Resource) => resource.type === RESOURCE_TYPE.ESTIMATE
  ).length
  const jobsTotal = dashboard?.filter(
    (resource: Resource) => resource.type === RESOURCE_TYPE.JOB
  ).length

  return [
    { type: 'Estimates', count: estimatesTotal },
    { type: 'Jobs', count: jobsTotal },
  ]
}
