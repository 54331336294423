import {
  dispatchPaymentCreditCardTypes,
  DispatchValue,
  DispatchContextType,
} from './types'

export const DispatchReducer = (state: DispatchContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case dispatchPaymentCreditCardTypes.SET_VALUE: {
      const { attr, value } = payload as DispatchValue
      return {
        ...(state as unknown as DispatchContextType),
        [attr]: value,
      }
    }
    default:
      return state
  }
}
