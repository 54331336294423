import React, { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stepper } from '../../../UI'
import useStyles from './styles'
import { authActions, configActions, userActions } from '../../../../ducks/actions'
import { HashWrapper } from '../../../templates'
import UtmParamsWrapper from '../UtmParamsWrapper'
import { StepperProps } from 'components/UI/CustomUI/organisms/Stepper/types'
import UserInformation from './steps/UserInformation'
import { UserType } from '../../../../ducks/user/types'
import { getConfigValue } from '../../../../ducks/selectors'
import Password from './steps/Password'

const Register: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const registerActiveStep = useSelector(getConfigValue('registerActiveStep')) || 0

  useEffect(() => {
    //need to clean mainContact if user goes to login or signup to avoid old data from the estimate
    dispatch(authActions.logoutRequest())
  }, [])

  const steps: StepperProps['steps'] = [
    {
      content: <UserInformation />,
      disableBack: true,
    },
    {
      content: <Password />,
      disableBack: true,
    },
  ]

  const updateNewUser = (attr: keyof UserType, value: any) => {
    dispatch(userActions.setUserValue({ attr, value }))
  }

  return (
    <HashWrapper>
      <UtmParamsWrapper>
        <Box className={classes.root}>
          <Box className={classes.content}>
            <Stepper
              withLogo={true}
              steps={steps}
              activeStep={registerActiveStep}
              setActiveStepAction={(step: number) =>
                dispatch(configActions.setConfigValue({ type: 'registerActiveStep', value: registerActiveStep + 1 }))}
              saveNewValueAction={(value: { attr: keyof UserType, value: any }) => updateNewUser(value.attr, value.value)}
              showHeader={false}
              className={classes.stepper}
              fixButtons
            />
          </Box>
        </Box>
      </UtmParamsWrapper>
    </HashWrapper>
  )
}

export default Register