import { Box, Button, Typography } from '@material-ui/core'
import useStyles from '../../styles'
import { Email } from '@material-ui/icons'
import {
  getApprovalValues,
  getEstimate,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useSelector } from 'react-redux'
import { CONTACT_ROLE } from 'helpers/constants'

const ContactBanner = () => {
  const styles = useStyles()

  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)

  const showBanner = !!estimate?.properties.contacts?.find(
    (contact) => contact.approvalFlowInProgress && contact.email !== userEmail
  )
  const { agentIsHomeowner, role } = useSelector(getApprovalValues())

  const isHomeowner = agentIsHomeowner || getUserIsHomeowner()

  return showBanner ? (
    <Box
      display="flex"
      flexDirection="column"
      padding="16px"
      style={{
        gap: '8px',
        border: '1px solid #FFE8AD',
        backgroundColor: '#FFF9EA',
        borderRadius: '8px',
      }}
    >
      <Typography className={styles.bannerTitle}>
        ℹ️ your {isHomeowner ? 'agent' : 'client'} has started the ordering
        process but has not completed it
      </Typography>
      {!isHomeowner && (
        <Typography className={styles.consentText}>
          Please contact your client and ask them to complete the order. Thank
          you.
        </Typography>
      )}
      {/*  {!isHomeowner && (
        <Button
          className={styles.blueSubmitButton}
          style={{ marginTop: '8px' }}
          startIcon={<Email style={{ width: '16px', height: '16px' }} />}
        >
          <Typography className={styles.consentText} style={{ color: 'white' }}>
            Send Reminder to Homeowner
          </Typography>
        </Button>
      )} */}
    </Box>
  ) : (
    <></>
  )
}

export default ContactBanner
