import { FC } from 'react'
import { Calendar } from 'react-date-range'
import { SingleDatePickerProps } from './types'
import useStyles from '../styles'

export const SingleDatePicker: FC<SingleDatePickerProps> = ({
  onChange,
  value,
  minDate,
  maxDate, 
  disabledDates
}) => {
  const classes = useStyles()

  const handleChange = (date: Date) => {
    onChange(date)
  }

  return (
    <Calendar
      date={value instanceof Date ? value : undefined}
      onChange={handleChange}
      className={classes.date}
      classNames={{
        month: classes.month,
        months: classes.month,
        days: classes.days,
        dayToday: classes.dayToday,
        dayNumber: classes.dayNumber,
        dateRangePickerWrapper: classes.dateRangePickerWrapper,
        calendarWrapper: classes.calendarWrapper
      }}
      minDate={minDate}
      disabledDates={disabledDates}
      maxDate={maxDate}
    />
  )
}
