import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto',
      [theme.breakpoints.down('sm')]: {
        padding: '12px !important',
      },
      '@global': {
        '.MuiGrid-item': {
          paddingRight: 0,
        },
      },
    },
    list: {
      margin: '20px 0px',
    },
    subHeader: {
      backgroundColor: 'var(--progress-color-back)',
    },
    listItem: {
      justifyContent: 'space-between',
    },
    item: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 0px',
    },
    itemEnd: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      alignSelf: 'flex-end',
      maxWidth: '100px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      //color: 'var(--head-text-color)',
      fontFamily: 'NextArtHeavy',
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
    noMargin: { marginBottom: '0' },
    price: {
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '58px',
      color: 'var(--head-text-color)',
    },
    icon: {
      color: 'var(--head-text-color)',
      margin: '18px 8px',
    },
    expand: {
      color: 'var(--white-color)',
    },
    expandBox: {
      width: '26px',
      height: '26px',
      borderRadius: '4px',
      backgroundColor: 'var(--bosscat-blue-600)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    chart: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '300px',
      margin: '10px 20px 20px 80px',
      [theme.breakpoints.down('lg')]: {
        margin: '10px 20px 20px 20px',
      },
    },
    titleChart: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '30px',
      color: 'var(--head-text-color)',
    },
    detail: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--row-background)',
    },
    bannerTitle: {
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '32px',
    },
    blueSubmitButton: {
      backgroundColor: '#2F7BF7',
      color: 'white',
      textTransform: 'none',
      width: 'fit-content',
      padding: '16px',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)',
      },
    },
    button: {
      height: '33px',
      width: '80px',
      minWidth: '80px',
      margin: '0px 2px',
      textTransform: 'none',
      border: '1px solid var(--bosscat-blue-600)',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)',
      },
    },
    buttonContent: {
      justifyContent: 'flex-end',
    },
    percent: {
      margin: '2px 20px',
      padding: '8px',
      height: '40px',
      width: '48px',
      borderRadius: 8,
    },
    itemPercent: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)',
    },
    number: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 5px',
    },
    info: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      alignSelf: 'center',
      margin: '8px 22px',
    },
    termsBtn: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      alignSelf: 'center',
    },
    buttonReverse: {
      width: '236px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '2px solid',
      margin: '0px 12px',
    },
    box: {
      width: '260px',
      height: '100px',
      backgroundColor: 'var(--progress-color-back)',
    },
    stepper: {
      '@global': {
        '>.MuiBox-root:first-child': {
          paddingLeft: '16px',
        },
      },
    },
    greenBox: {
      background: '#E6F7EE',
      border: '1px solid #99DDBD',
      borderRadius: '8px',
      padding: '1rem',
    },
    blueBox: {
      background: '#EAF2FE',
      borderRadius: '8px',
      padding: '1rem',
      border: '1px solid #ACCAFC',
    },
    yellowBox: {
      background: '#FFF9EA',
      borderRadius: '8px',
      padding: '1rem',
    },
    grayBox: {
      background: '#FCFCFC',
      borderRadius: '8px',
      padding: '1rem',
    },
    listingDisclaimer: {
      marginTop: '1rem',
    },
    consentText: {
      color: 'black',
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    container: {
      flexDirection: 'column',
      display: 'flex',
      gap: '32px',
      position: 'relative',
      margin: '.2rem',
      // minHeight: '50vh',
      '@global': {
        '>.MuiGrid-root': {
          flexBasis: 'auto',
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: '8px',
        // marginRight: 24,
      },
    },
    latoPrimaryText: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      fontSize: '18px',
      marginRight: '8px',
      lineHeight: '24px',
      paddingTop: '5px',
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    formRadioButton: {
      width: '100%',
      minHeight: '60px',
      padding: '4px 8px',
      '@global': {
        '.MuiTypography-root': {
          lineHeight: 1.3,
        },
      },
    },
    signature: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '@global': {
        '.MuiInput-underline.Mui-error:after': {
          border: '0px',
        },
      },
    },
    errorField: {
      '&.MuiFormControl-root': {
        border: '2px solid rgba(244, 67, 54, 1)',
        borderRadius: 8,
      },
    },
    cellphone: {
      '@global': {
        '.MuiTypography-body1': {
          fontSize: '14px',
          size: '14px',
        },
      },
    },
    consentPayAtClose: {
      flexWrap: 'nowrap',
      gap: '4px',
      marginTop: 32,
    },
    paymentMethod: {
      border: '2px solid #EBECEF',
      margin: '.5rem 0',
      padding: '1rem',
      alignItems: 'flex-start',
    },
    spacedItems: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px',
      borderRadius: '8px',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--row-focus)',
      },
      '&:active': {
        backgroundColor: 'var(--row-active)',
      },
    },
    newCardFormContainer: {
      flex: 1,
      display: 'flex',
      cursor: 'pointer',
    },
    addNewCard: {
      margin: '1rem 0',
      padding: '0 .5rem',
      display: 'flex',
      alignItems: 'center',
      '@global': {
        '.MuiFormControlLabel-label': {
          width: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        // marginLeft: '12px',
      },
    },
    'Mui-checked': {
      color: 'var(--bosscat-blue-600) !important',
    },
    buttonsContainer: {
      position: 'fixed',
      bottom: '3rem',
      pointerEvents: 'none',
      maxWidth: '70vw',
      // marginTop: '5rem',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        // flexWrap: "nowrap",
        maxWidth: '100vw',
        left: 0,
        bottom: '0rem',
        backgroundColor: '#fff',
        paddingInline: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },
    },
    buttonSubmit: {
      backgroundColor: '#01AA59',
      color: 'white',
      // margin: '2rem',
      borderRadius: '8px',
      // fontSize: '18px',
      textTransform: 'inherit',
      fontFamily: 'LatoBold',
      padding: '.7rem 1rem',
      // minWidth: '180px',
      pointerEvents: 'fill',
      // marginRight: '0 !important',
      // filter:
      //   'drop-shadow(0px 3px 5px rgba(26, 42, 85, 0.2)) drop-shadow(0px 0px 1px rgba(26, 42, 85, 0.31))',
      '&:hover': {
        backgroundColor: '#0E905A',
      },
      '@global': {
        '.MuiButton-label': {
          padding: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important',
      },
    },
    buttonSubmitIcon: {
      width: '1rem',
    },
    buttonBack: {
      backgroundColor: '#FCFCFC',
      // color: 'var(--bosscat-blue-600)',
      // margin: '2rem',
      borderRadius: '8px',
      // fontSize: '18px',
      textTransform: 'inherit',
      // fontFamily: 'LatoBold',
      padding: '.7rem 1rem',
      minWidth: '180px',
      pointerEvents: 'fill',
      // marginLeft: '0 !important',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      '@global': {
        '.MuiButton-label': {
          padding: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important',
        minWidth: '130px',
      },
    },
    orderSummaryContainer: {
      backgroundColor: '#F9FAFA',
      padding: '1rem',
      borderRadius: '8px',
    },
    orderSummaryTitle: {
      color: '#494A5A',
      fontFamily: 'LatoNormal',
    },
    bold: {
      fontFamily: 'LatoBold',
    },
    plan: {
      fontSize: '18px',
    },
    padding: {
      padding: '1rem 0',
    },
    gift: {
      color: '#6E5BE8',
    },
    promoCode: {
      flex: 1,
    },
    giftIcon: {
      fill: '#6E5BE8',
      marginRight: '.5rem',
      width: '1rem',
    },
    subscriptionPrice: {
      color: 'var(--head-text-color)',
    },
    question: {
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: '24px',
    },
    subscriptionPriceHasPromo: {
      fontFamily: 'LatoNormal',
      textDecoration: 'line-through',
    },
    promoPrice: {
      color: 'var(--bosscat-blue-600)',
    },
    spinner: {
      margin: '1rem',
      display: 'flex',
      justifyContent: 'center',
    },
    whiteSpinner: {
      '@global': {
        '.MuiCircularProgress-circle': {
          color: 'white',
        },
      },
    },
    divider: {
      backgroundColor: '#EFF0F1',
      height: 2,
      margin: '12px 0',
    },
    cardLogoContainer: {
      display: 'flex',
      width: '64px',
      height: '32px',
      padding: '8px 7px',
      marginRight: '16px',
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[100],
    },
    cardLogo: {
      width: 34,
    },
    addNew: {
      // border: '2px solid #EBECEF',
      // padding: '0 1rem',
      height: 'fit-content',
      padding: 0,
      borderRadius: 0,
      background: 'transparent',
      textAlign: 'left',
      justifyContent: 'flex-start',
      color: 'var(--bosscat-black-600)',
      fontSize: '18px',
      textTransform: 'inherit',
      fontFamily: 'LatoLight',
      lineHeight: '1',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    creditCardIcon: {
      width: '1rem',
    },
    rootContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        flexWrap: 'nowrap',
      },
    },
    disclaimer: {
      fontSize: 12,
      color: '#6D6A6F',
    },
    bankUsModal: {
      '@global': {
        '.MuiDialogContent-root': {
          padding: '16px',
        },
      },
    },
    buttonText: {
      textTransform: 'capitalize',
    },
    formLabel: {
      flex: 1,
      padding: '0 0 0 8px',
      margin: '0 !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      minWidth: '146px',
      width: '100%',
      background: 'white',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    consent: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      // alignSelf: 'stretch',
      borderRadius: '8px',
      border: '2px solid #99DDBD',
      padding: '16px 16px 16px 12px !important',
      backgroundColor: '#E6F7EE',
      // flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    buttonConfirm: {
      display: 'flex',
      padding: '0 34px',
      marginTop: '1rem',
    },
    buttonSuccess: {
      '&.Mui-disabled': {
        backgroundColor: 'white !important',
        color: 'var(--bosscat-green-600)',
        '&:hover': {
          backgroundColor: 'white',
          color: 'var(--bosscat-green-600)',
        },
      },
    },
    textField: {
      border: '2px solid #ebecef',
      width: '100%',
      padding: '4px 8px',
      borderRadius: '8px',
      minHeight: '60px',
      display: 'flex',
      justifyContent: 'center',
      '@global': {
        '.MuiInput-underline:before': {
          display: 'none',
        },
        '.MuiInput-underline:after': {
          display: 'none',
        },
        '.MuiInputBase-input': {
          height: 'fit-content',
          padding: 0,
          color: 'var(--input-color)',
          fontSize: '16px',
          fontFamily: 'LatoLight',
        },
      },
    },
    selectedCard: {
      borderColor: 'var(--bosscat-blue-600)',
      padding: '8px',
    },
    customHeight: {
      height: "28px"
    },
    card: {
      padding: "8px",
    }
  })
)
