import { Box, Typography } from "@material-ui/core"
import { TrustPilot, bosscatHomeServicesLogo, mellohomeBg } from "assets/index"
import useStyles from './styles'
import Banner from "../Banner"

const DiptHeader = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.headerLogos}>
        <img src={bosscatHomeServicesLogo} className={`${classes.logo} ${classes.logoHeight}`} alt='' />
        <img src="https://images.squarespace-cdn.com/content/v1/59f72ac64c0dbf163ec24337/c1605e29-78ba-47c6-b66f-16efe69ed069/Dipt_2.jpg" className={classes.logo} alt='' />
      </Box>
      <Banner
        title={
          <>
            <Typography style={{ color: "var(--bosscat-black-600)" }}> YOUR DREAM </Typography><Typography style={{ color: "var(--bosscat-black-600)" }}> HOME AWAITS </Typography>
          </>
        }
        subtitle={
          <>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>Repair and renovate your home with</Typography>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>BOSSCAT, the one-stop solution for home</Typography>
            <Typography style={{ fontSize: "20px", color: "var(--bosscat-black-600)" }}>improvement projects.</Typography>
          </>
        }
        // subtitle="Repair and renovate your home with BOSSCAT, the one-stop solution for home improvement projects."
        imgSrc={mellohomeBg}
        badgeSrc={TrustPilot}
        padding="6rem 8rem"
      />
      <Box className={classes.footer}>RECEIVE EXCLUSIVE VIP SERVICE FOR DIPT CUSTOMERS</Box>
      {/* <img src={mellohomeBg} alt="mellohome" /> */}
    </Box>
  )
}

export default DiptHeader