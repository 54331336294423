import { Box, Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../../styles'
import { Insight } from 'ducks/types'
import CostTypeRadio from 'components/UI/CustomUI/molecules/CostTypeRadio'
import { useState } from 'react'
import { CalendarToday, Check } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import { history } from 'helpers/index'
import DateFnsUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getTerritory } from 'ducks/selectors'
import DatePickModal from '../../Modals/DatePickModal'

const SystemInsightRow = ({
  insight,
  setModalOpen,
  selectedItem,
  selectedDate,
  setSelectedDate,
  setSelectedItem,
  setDateUpdating,
}: {
  insight: Insight
  setModalOpen: (open: boolean) => void
  selectedItem: string
  selectedDate: MaterialUiPickersDate
  setSelectedDate: (newValue: any) => void
  setSelectedItem: (newValue: any) => void
  setDateUpdating: (newValue: any) => void
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [costType, setCostType] = useState<string>('Good')
  const [ordering, setOrdering] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalItem, setModalItem] = useState<Insight | null>(null)

  const territory = useSelector(getTerritory())

  const { id: propertyId } = history.getParamValues()

  const handleInstallDateChange = (
    date: any,
    insight: Insight
  ) => {
    setDateUpdating(true)
    dispatch(
      propertiesActions.updatePropertyProject(
        {
          propertyId,
          projectName: insight.item,
          request: {
            ...insight,
            installDate: `${((date?.month))
              .toString()
              .padStart(2, '0')}-${date?.year}`,
          },
        },
        (succ) => {
          dispatch(
            propertyDetailActions.fetchPropertyDetail(
              { id: propertyId },
              (succ) => {
                setDateUpdating(false)
              }
            )
          )
        }
      )
    )
  }

  const getDate = (date: string): Date => {
    if (!date) return new Date()
    const month = date.split('-')[0]
    const year = date.split('-')[1]
    const newDate = new Date()
    newDate.setFullYear(parseInt(year))
    newDate.setMonth(parseInt(month) - 1)

    return newDate
  }

  const handleModalOpen = (item: Insight) => {
    setShowModal(true)
    setModalItem(item)
  }

  const props = [
    {
      title: 'Install Date',
      component: (
        <Box display="flex" alignItems="center" style={{ gap: '4px' }} onClick={() => handleModalOpen(insight)}>
          <CalendarToday style={{ width: '18px', height: '18px' }} />
          <Typography className={styles.title}>
            {insight.item === selectedItem
              ? selectedDate
              : insight.installDate || 'Select Date'
            }
          </Typography>
        </Box>
      ),
    },
    {
      title: 'Life Expectancy',
      component: (
        <Typography className={styles.title}>
          {insight.lifeExpectancy}
        </Typography>
      ),
    },
    {
      title: 'Replacement Date',
      component: (
        <Typography
          className={styles.title}
          style={{
            color:
              new Date(getDate(insight.replacementDate)).getTime() <
                new Date().getTime()
                ? 'var(--renovation-red-600)'
                : 'var(--bosscat-black-600)',
          }}
        >
          {insight.replacementDate}
        </Typography>
      ),
    },
    {
      title: 'Next Maintenance',
      component: (
        <Typography className={styles.title}>
          {insight.nextMaintenance || '-'}
        </Typography>
      ),
    },
    {
      title: 'Cost Maintenance',
      component: (
        <Typography className={styles.title}>
          {insight.costMaintenance ? `$${insight.costMaintenance || 0}` : '-'}
        </Typography>
      ),
    },
    {
      title: 'Replacement Cost',
      component: (
        <Typography className={styles.title}>
          <b>
            {`$${(insight as any)[
              `costReplacement${costType}`
            ]?.toLocaleString() || 0
              }`}
          </b>
        </Typography>
      ),
    },
  ]

  const handleInquire = (insight: Insight) => {
    // setInquiredItems([...inquiredItems, insight.item])
    setOrdering(true)

    const { requested, ...request } = insight

    dispatch(
      propertyDetailActions.orderInsights(
        { propertyId, item: insight.item, request: request as Insight },
        (succ) => {
          dispatch(
            propertyDetailActions.fetchPropertyDetail(
              { id: propertyId },
              (suc) => {
                setOrdering(false)
              }
            )
          )
        }
      )
    )
  }

  return (
    <Grid
      container
      item
      className={styles.containerBox}
      direction="column"
      style={{ padding: '12px', gap: '8px' }}
    >
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          style={{ width: 'fit-content' }}
        >
          <Grid item>
            <Typography className={styles.lgFont}>
              {insight.item || ''}
            </Typography>
          </Grid>
          <Grid item>
            <CostTypeRadio costType={costType} setCostType={setCostType} />
          </Grid>
        </Grid>
        {territory?.serviceable && (
          <Grid item>
            <Button
              startIcon={
                insight.requested ? (
                  <Check className={styles.buttonIcon} />
                ) : (
                  <></>
                )
              }
              variant="contained"
              className={
                insight.requested ? styles.inquiredButton : styles.inquireButton
              }
              style={{
                height: '52px',
                cursor: ordering ? 'wait' : 'pointer',
              }}
              fullWidth
              onClick={() => {
                if (!insight.requested) {
                  setModalOpen(true)
                  handleInquire(insight)
                }
              }}
            >
              <Typography
                className={styles.lgFont}
                style={{
                  textTransform: 'none',
                }}
              >
                {insight.requested ? '' : 'Inquire'}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item>
        {props.map((prop) => (
          <Grid item xs={4}>
            <Typography
              className={styles.latoSmFont}
              style={{ color: 'var(--text-text-tertiary)' }}
            >
              {prop.title}
            </Typography>
            {prop.component}
          </Grid>
        ))}
      </Grid>
      <DatePickModal open={showModal} setOpen={setShowModal} item={modalItem} updateDate={handleInstallDateChange} />
    </Grid>
  )
}

export default SystemInsightRow
