import { FC, useState } from 'react'
import { Props } from './types'
import useStylesBetter from './stylesBetter'
import { icons } from '../../../../../assets'
import {
  Typography,
  Box,
  Grid,
  Button,
  Switch,
  Tabs,
  Tab,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  Radio,
} from '@material-ui/core'
import { ESTIMATE_VIEW } from '../../../../../helpers'
import { useIsXsScreen } from '../../../../../hooks'
import { TabPanel } from '@material-ui/lab'
import Autocomplete from '../Autocomplete'
import ListIcon from '@material-ui/icons/List'
import { isMobile } from 'react-device-detect'
import Modal from '../Modal'
import FormRadioButton from '../../atoms/FormRadioButton'
import { useSelector } from 'react-redux'
import { getSideMenuOpen } from 'ducks/selectors'

const TYPE = ESTIMATE_VIEW.TYPE
const PRIORITY = ESTIMATE_VIEW.PRIORITY
const APPROVED = ESTIMATE_VIEW.APPROVED

const TypeViewsV2: FC<Props> = ({
  currentTab,
  handleSubmit,
  tabs,
  selectedType,
  totalCount,
  getIcon,
  selectedFilt,
  setSelectedFilt,
}) => {
  const stylesBetter = useStylesBetter()
  const classes = stylesBetter
  const [openFilters, setOpenFilters] = useState(false)

  const sideMenuOpen = useSelector(getSideMenuOpen())
  const evaluateTab = tabs.find((tab) => tab.title === 'EVALUATE')

  const filterOptions = [
    {
      key: TYPE,
      value: 'By Type',
    },
    {
      key: PRIORITY,
      value: 'By Priority',
    },
  ]

  const [filter, setFilter] = useState(
    filterOptions.find((option) => option.key === selectedType)
  )

  const handleFilterChange = (value: any) => {
    setFilter(value)
    handleSubmit(value.key)
  }

  return (
    <Box className={classes.root}>
      <Modal
        open={openFilters}
        setOpen={() => setOpenFilters(!openFilters)}
        size="xl"
        className={classes.filtersModal}
        title={
          <Typography className={classes.filtersModalTitle}>view by</Typography>
        }
      >
        {filterOptions.map(f =>
          <FormRadioButton
            label={
              <Typography
                style={{ color: 'var(--text-text-primary)' }}
              >
                {f.value}
              </Typography>
            }
            value={f.value}
            control={<Radio color="primary" />}
            onClick={() => handleFilterChange(f)}
            checked={filter?.value === f.value}
          />
        )}
      </Modal>
      <Box className={classes.container}>
        <Box>
          <Tabs
            variant="scrollable"
            scrollButtons="on"
            TabIndicatorProps={{
              style: {
                display: 'none'
              },
            }}
            value={currentTab}
            // onChange={(e) => onChange(e.target.value)}
            className={classes.Tabs}
            TabScrollButtonProps={{ style: { height: '40px', display: isMobile ? 'none' : 'inherit' } }}
            style={{ height: '40px', maxWidth: sideMenuOpen ? "44.5vw" : "54vw" }}
          >
            <Tab
              className={selectedFilt === 'All' ? classes.selected : classes.unSelected}
              onClick={() => setSelectedFilt('All')}
              label={
                <Box
                  display="flex"
                  style={{ gap: '8px' }}
                  alignItems="center"
                  color={selectedFilt === 'All' ? 'white' : 'var(--bosscat-black-600)'}
                >
                  <ListIcon style={{ width: '14px', height: '14px' }} color="inherit" />
                  <Typography className={classes.midFont}>{`All Items (${totalCount})`}</Typography>
                </Box>
              }
            />
            {evaluateTab && (
              <Tab
                className={selectedFilt === 'EVALUATE' ? classes.selected : classes.unSelected}
                onClick={() => setSelectedFilt('EVALUATE')}
                style={{ marginLeft: '8px' }}
                label={
                  <Box
                    display="flex"
                    style={{ gap: '8px' }}
                    alignItems="center"
                    color={selectedFilt === 'EVALUATE' ? 'white' : 'var(--bosscat-black-600)'}
                  >
                    {/* <ListIcon style={{ width: '14px', height: '14px' }} color="inherit" /> */}
                    <icons.Info htmlColor='#FF5031' style={{
                      width: '14px', height: '14px'
                    }} />
                    <Typography className={classes.midFont}>{`Need More Information (${evaluateTab.count})`}</Typography>
                  </Box>
                }
              />
            )}
            {tabs.map((tab) => {
              const title = tab.title?.toLowerCase()?.replace('_', ' ')
              if (tab.title === 'EVALUATE') return <></>
              return (
                <Tab
                  className={selectedFilt === tab.title ? classes.selected : classes.unSelected}
                  onClick={() => setSelectedFilt(tab.title)}
                  style={{ marginLeft: '8px' }}
                  label={
                    <Box
                      display="flex"
                      style={{ gap: '8px' }}
                      alignItems="center"
                      color={
                        selectedFilt === tab.title
                          ? 'white'
                          : 'var(--bosscat-black-600)'
                      }
                    >
                      {getIcon(tab.title)}
                      <Typography
                        className={classes.midFont}
                        style={{ color: 'var(--bosscat-black-600)' }}
                      >{`${title} (${tab.count})`}</Typography>
                    </Box>
                  }
                />
              )
            })}
          </Tabs>
        </Box>
        {isMobile ?
          <IconButton
            style={{ backgroundColor: '#FCFCFC' }}
            className={classes.filterButton}
            onClick={() => setOpenFilters(true)}
          >
            <icons.FilterList />
          </IconButton>
          :
          <Box className={classes.autocompleteContainer}>
            <Autocomplete
              options={filterOptions}
              value={filter}
              // onChange={(value) => {console}}
              onChange={handleFilterChange}
              className={classes.autocomplete}
              // popperWidth="160px"
              popperStyles={{
                width: '160px',
                position: 'absolute',
                left: '1rem !important',
              }}
            />
          </Box>
        }
      </Box>
    </Box>
  )
}

export default TypeViewsV2
