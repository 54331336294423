import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "3rem 0 0 0",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
        marginTop: '2rem'
      }
    },
    textContainer: {
      width: '100%',
      backgroundColor: "#F5F5F5",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: "1rem 0"
    },
    title: {
      fontFamily: 'NextArtHeavy',
      color: '#A5965A',
      fontSize: '42px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      }
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '24px',
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      }
      // padding: '1rem 4rem',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1.5rem',
    },
    card__container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      gap: '5rem',
      marginTop: '4rem',
      padding: '0 3rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
        gap: '3rem'
      }
    },
    card__title: {
      textTransform: 'uppercase',
      fontFamily: 'NextArtHeavy',
      fontSize: '24px',
      textAlign: 'center',
    },
    card__subtitle: {
      textAlign: 'center',

    },
    icon: {
      width: '130px'
    }
  })
)
