import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      maxWidth: '650px'
    },
    logo: {
      margin: '20px',
      width: '250px'
    },
    header: {
      fontFamily: 'LatoNormal',
      fontSize: '22px',
      paddingBottom: '12px',
      '& > span': {
        color: 'var(--bosscat-blue-600)'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    desc: {
      fontFamily: 'LatoNormal',
      maxWidth: '360px',
      fontSize: '16px',
      lineHeight: '24px',
      paddingBottom: '12px',
      color: 'rgba(73, 74, 90, 1)'
    },
    title: {
      fontFamily: 'LatoBold',
      '@global': {
        span: {
          fontFamily: 'LatoNormal !important'
        }
      },
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem'
      }
    },
    inputs: {
      paddingLeft: '16px'
    },
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      width: '100%',
      padding: '24px 0 24px 24px',
      gap: '8px',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gap: '0px',
        padding: '24px 24px 24px 24px'
      }
    },
    submit: {
      margin: theme.spacing(1, 0),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        width: '160px',
        margin: 0,
        padding: 0
      }
    },
    back: {
      margin: theme.spacing(1, 0),
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        width: '160px',
        margin: 0,
        padding: 0
      }
    },
    icon: {
      margin: '0 0 2px 0',
      width: '16px',
      height: '16px'
    }
  })
)
