import { createStyles, makeStyles, Theme as ThemeMUI } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    container: {
      padding: '1rem',
      borderRadius: 8,
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: 22,
      margin: '1rem 0'
    },
    list: {
      paddingTop: '0',
      fontSize: 18,
    },
    listItem: {
      fontFamily: 'LatoNormal'
    },
    paragraph: {
      fontFamily: 'LatoNormal',
      fontSize: 14
    }
  })
)
