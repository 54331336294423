import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import env from '@beam-australia/react-env'
import * as Sentry from '@sentry/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Integrations } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'

import Routes from './router'
import storeInfo from './ducks/store'
import MomentUtils from '@date-io/moment'

import { ThemeProvider, CssBaseline, defaultTheme } from './components/UI'
import { pickers } from './components/UI/MaterialUI/index'

import './index.css'
import 'normalize.css'
import MainWrapper from './components/templates/Wrappers/MainWrapper'

const SENTRY_DSN = env('SENTRY_DSN') ?? ''
const ENVIRONMENT = env('ENVIRONMENT') ?? ''
const isProd = env('NODE_ENV') === 'production'

const tracesRate = env('SENTRY_TRACES_RATE')
const SENTRY_TRACES_RATE = tracesRate ? parseInt(tracesRate) : 0

if (isProd) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: ENVIRONMENT,
    tracesSampleRate: SENTRY_TRACES_RATE,
    release: env('SENTRY_RELEASE'),
  })
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={storeInfo.store}>
    <PersistGate loading={null} persistor={storeInfo.persistor}>
      <ThemeProvider theme={defaultTheme}>
        <pickers.MuiPickersUtilsProvider utils={MomentUtils}>
          <MainWrapper>
            <ToastContainer
              theme="colored"
              position="top-right"
              autoClose={4000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover
            />
            <CssBaseline />
            <Routes />
          </MainWrapper>
        </pickers.MuiPickersUtilsProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
