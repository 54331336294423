import { FC, useEffect, useRef, useState } from 'react'
import { icons, upload2 } from '../../../../../assets'
import { Box, Typography, Avatar, CircularProgress } from '../../..'
import { ProfilePictureProps } from './types'
import useStyles from './styles'

import env from '@beam-australia/react-env'
import { ParseFilesUrl } from '../../../../../helpers'

const FILES_URL = env('FILES_URL') ?? ''

const ProfilePicture: FC<ProfilePictureProps> = ({
  avatar,
  onUpload,
  uploading = false,
  width = "200px",
  height = '200px',
  defaultEmptyIcon
}) => {
  const classes = useStyles()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const hasAvatar: Boolean = avatar !== ''

  const [loading, setLoading] = useState(true)
  const [attempts, setAttempts] = useState(5)
  const [error, setError] = useState(false)
  const [attempting, setAttempting] = useState(false)
  const [showFileTypeError, setShowFileTypeError] = useState(false)
  const [tempPicture, setTempPicture] = useState('')

  useEffect(() => {
    setLoading(false)
  }, [])

  const selectFile = (e: any) => {
    e.stopPropagation()
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleUpload = (event: any): void => {
    const image = event.target.files[0]
    setTempPicture(URL.createObjectURL(image))
    if (onUpload && (
      image?.type === "image/jpeg" ||
      image?.type === "image/jpg" ||
      image?.type === "image/png"
    )) {
      setAttempts(5)
      setShowFileTypeError(false)
      onUpload(image, URL.createObjectURL(image))
    } else if (image) {
      setShowFileTypeError(true)
    }
  }

  const handleError = () => {
    if (attempts > 0 && avatar) {
      setAttempting(true)
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setTempPicture('')
      setAttempting(false)
      setError(true)
      setLoading(false)
    }
  }

  const getPicture = (): JSX.Element => {

    if (uploading || loading) {
      return (
        <>
          {!tempPicture ?
            <Box className={classes.picture}>
              <CircularProgress data-testid="circular-loading" color="inherit" className={classes.loading} />
            </Box>
            :
            <img
              onError={handleError}
              alt=""
              src={tempPicture}
              className={classes.picture}
            />}
        </>
      )
    }

    if (hasAvatar && !error) {
      return (
        <Box className={classes.picture}>
          <img
            onError={handleError}
            alt=""
            src={tempPicture || ParseFilesUrl({ baseUrl: FILES_URL, avatar })}
            className={classes.picture}
          />
        </Box>
      )
    }

    return (
      <Avatar alt="" className={classes.icon}>
        {!defaultEmptyIcon ?
          <icons.Person data-testid="default-avatar" fontSize="large" className={classes.personIcon} style={{ width: width, height: height }} />
          : <img
            alt="empty-img"
            src={defaultEmptyIcon}
            className={`${classes.picture} ${classes.emptyDefaultPic}`}
          />
        }
      </Avatar>
    )
  }


  return (
    <>
      <div className={classes.root} onClick={onUpload && selectFile} style={{ width: width, height: height, cursor: onUpload ? 'pointer' : 'inherit' }}>
        {getPicture()}
        {(attempting || (!uploading && !loading)) && onUpload && (
          <Box className={classes.actionButton} onClick={selectFile}>
            {hasAvatar ? <icons.CameraAlt /> : <img src={upload2} alt="" />}
            <Typography>{hasAvatar ? 'Change Photo' : 'Upload Photo'}</Typography>
          </Box>
        )}

        {onUpload && <input
          data-testid="image-upload"
          accept="image/*"
          style={{ display: 'none' }}
          ref={(ref) => (ref ? (inputRef.current = ref) : null)}
          type="file"
          onChange={handleUpload}
        />}
      </div>
      {showFileTypeError && <Typography color="error" style={{ fontSize: '14px', paddingTop: '1rem', opacity: showFileTypeError ? 1 : 0 }}>Please select a JPG, JPEG or PNG file</Typography>}
    </>
  )
}

export default ProfilePicture
