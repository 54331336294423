import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'baseline',
      color: theme.palette.primary.dark
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      // lineHeight: '58px',
      fontSize: '30px',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px'
      }
    },
    titleBlack: {
      fontFamily: 'LatoBold',
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      }
    }
  })
)
