/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import { jobItemsTypes, FetchJobItemsAction, UpdateJobItemAction } from './types'
import { jobItemsActions } from 'ducks/actions'
import { UpdateRequest } from 'api/types'
import { Item } from 'ducks/types'
import * as Sentry from '@sentry/react'

export function* fetchJobItems({ payload, callback }: FetchJobItemsAction): SagaIterator {
  let success = false
  try {
    const jobItems = yield call(Punchlist.jobs.getJobItems, payload)

    if (jobItems) {
      yield put(jobItemsActions.setJobItems(jobItems))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the job items'))
    if (callback) yield call(callback, false)
  }
}

export function* updateJobItem({ payload, callback }: UpdateJobItemAction): SagaIterator {
  let success = false

  try {
    const { jobId, itemId, partialItem } = payload
    const request: UpdateRequest = []

    for (const attr in partialItem) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: partialItem[attr as keyof Item]
      })
    }

    const jobItem = yield call(Punchlist.jobs.updateJobItem, jobId, itemId, request)

    if (!isEmpty(jobItem)) {
      yield put(jobItemsActions.setJobItemValues({ itemId, attrs: jobItem }))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the job item'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(jobItemsTypes.FETCH_JOB_ITEMS, fetchJobItems)
  yield takeLatest(jobItemsTypes.UPDATE_JOB_ITEM, updateJobItem)
}
