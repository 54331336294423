import { giftImg, icons } from 'assets'
import {
  Box,
  DatePicker,
  FormControlLabel,
  Grid,
  Radio,
  SelectAddress,
  TextFieldLabel,
  Typography,
} from 'components/UI'
import { getNewOrderMaintenanceValue, getUser } from 'ducks/selectors'
import {
  calcWeekends,
  formatTimestampDate,
  getHolidaysDate,
  getNextBusinessDayIfFriday,
  isEmpty,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  PHONE_MASK_REGEXP_NO_SCOPE,
} from 'helpers/index'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getSubscriptionsValue } from 'ducks/subscriptions/selectors'
import { Address } from 'ducks/types'
import { useStepperContext } from 'hooks/useStepperContext'
import moment from 'moment'
import styles from './styles.module.scss'
import ImageBadge from 'components/UI/CustomUI/molecules/ImageBadge'
import { newOrderMaintenanceActions } from 'ducks/actions'
import { addDays } from 'date-fns'

const GiftShippingInformation = () => {
  const dispatch = useDispatch()
  const { showWarnings, setShowWarnings, setCanGoForward, saveNewValue } =
    useStepperContext()
  const giftBox = useSelector(getNewOrderMaintenanceValue('giftBox'), shallowEqual)
  const homeowner = useSelector(getNewOrderMaintenanceValue('homeowner'))
  const purchaser = useSelector(getNewOrderMaintenanceValue('purchaser'))
  const homeownerReceives = useSelector(
    getNewOrderMaintenanceValue('homeownerReceives')
  )
  const propertyAddress = useSelector(
    getNewOrderMaintenanceValue('propertyAddress')
  )
  const user = useSelector(getUser)

  const deliveryDate = useSelector(getSubscriptionsValue('deliveryDate'))

  const handleChangeIsAGift = (val: string | boolean) => {
    let request = {}

    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'homeownerReceives', value: val === 'true' }))

    if (val === 'true') {
      request = {
        shippingName: `${homeowner?.firstName} ${homeowner?.lastName}`,
        shippingAddress: propertyAddress,
        shippingPhone: homeowner?.phone,
      }
    } else {
      request = {
        shippingName: ``,
        shippingAddress: purchaser?.address || user?.address,
        shippingPhone: purchaser?.phone || user?.phone,
      }
    }
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({
      attr: 'giftBox', value: {
        ...giftBox,
        ...request,
      }
    }))
  }

  const handleChange = (newAddress: Address) => {
    newAddress.zipCode &&
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'giftBox', value: { ...giftBox, shippingAddress: newAddress } }))
  }

  const handleStartDate = (date: Date) => {
    if (deliveryDate && date.valueOf() > deliveryDate) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'giftBox', value: { ...giftBox, deliveryDate: moment(date).unix() } }))
    }
  }

  const validateAddress: boolean =
    !isEmpty(giftBox?.shippingAddress?.line_1) &&
    giftBox?.shippingAddress?.line_1 !== ' ' &&
    !!giftBox?.shippingAddress?.zipCode &&
    giftBox?.shippingAddress?.latitude &&
    giftBox?.shippingAddress?.longitude

  const validate: boolean =
    !isEmpty(validateAddress.toString()) &&
    !isEmpty(giftBox?.shippingName) &&
    !isEmpty(giftBox?.deliveryDate?.toString())

  const holidays = getHolidaysDate()
  const nextDay = addDays(new Date(), 1)
  const weekends = calcWeekends(new Date(), addDays(new Date(), 400))

  const nextBusinessDayIfFriday = getNextBusinessDayIfFriday()

  const disabledDates = [...weekends, ...holidays, new Date(), nextDay]

  if (nextBusinessDayIfFriday) {
    disabledDates.push(nextBusinessDayIfFriday)
  }


  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  useEffect(() => setShowWarnings(false), [])

  return (
    <Box className={styles.GiftShippingInformation}>
      <Box className={styles.Content}>
        <Grid className={styles.leftContent}>
          <Grid>
            {/* <icons.CardGiftcard
            className={styles.GiftShippingInformation__title__icon}
          /> */}
            <Typography className={styles.GiftShippingInformation__title__text}>
              🎁 Where would you like this gift set shipped?
            </Typography>
          </Grid>
          <Grid>
            <FormControlLabel
              checked={homeownerReceives}
              value={true}
              control={
                <Radio
                  checkedIcon={
                    <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                  }
                  icon={<icons.RadioButtonUnchecked />}
                />
              }
              onChange={(e: any) => handleChangeIsAGift(e.target.value)}
              className={`${styles.GiftShippingInformation__receiver} ${homeownerReceives
                ? styles.GiftShippingInformation__receiver__selected
                : ''
                }`}
              label={
                <Box>
                  <Typography
                    className={styles.GiftShippingInformation__receiver__title}
                  >
                    To the homeowner
                  </Typography>
                  <Typography
                    variant="caption"
                    className={styles.caption}
                  >
                    Gift recipient will receive it at their address
                  </Typography>
                </Box>
              }
            />
            <Box mt={2}>
              <FormControlLabel
                checked={!homeownerReceives}
                value={false}
                control={
                  <Radio
                    checkedIcon={
                      <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                    }
                    icon={<icons.RadioButtonUnchecked />}
                  />
                }
                onChange={(e: any) => handleChangeIsAGift(e.target.value)}
                className={`${styles.GiftShippingInformation__receiver} ${!homeownerReceives
                  ? styles.GiftShippingInformation__receiver__selected
                  : ''
                  }`}
                label={
                  <Box>
                    <Typography
                      className={styles.GiftShippingInformation__receiver__title}
                    >
                      To me
                    </Typography>
                    <Typography variant="caption" className={styles.caption}>
                      You'll receive it at your address
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={styles.GiftShippingInformation__image__container}>
          <ImageBadge img={giftImg} alt="Gift box image" maxHeight={270} />
        </Grid>
      </Box>
      <Grid
        container
        item
        lg={12}
        xs={12}
        spacing={3}
        className={styles.GiftShippingInformation__input__container}
      >
        <Grid item xs={12} className={styles.inputContainer}>
          <Grid className={styles.inputContainerFirst}>
            <Typography className={styles.addressLabel}>
              Target Gift Delivery Date
            </Typography>
            <DatePicker
              minDate={formatTimestampDate(deliveryDate)}
              error={
                showWarnings && typeof giftBox?.deliveryDate === 'undefined'
              }
              onChange={handleStartDate}
              value={
                giftBox?.deliveryDate
                  ? formatTimestampDate(giftBox.deliveryDate)
                  : null
              }
              className={styles.GiftShippingInformation__datePicker}
              shouldScrollOnError
              disabledDates={disabledDates}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.inputContainer}>
          <SelectAddress
            title={<Typography variant="subtitle2" className={styles.addressLabel}>Shipping Address</Typography>}
            showWarnings={showWarnings && !validateAddress}
            savedAddress={giftBox?.shippingAddress}
            onChange={handleChange}
            showUnitNumberBesideAddress
            placeholder="Enter Home Address"
            showMap={false}
            customClass={styles.GiftShippingInformation__SelectAddress}
          />
        </Grid>
      </Grid>
      {!homeownerReceives && <Grid
        container
        item
        lg={12}
        xs={12}
        spacing={3}
        className={styles.GiftShippingInformation__input__container}
      >
        <Grid item xs={12}>
          <TextFieldLabel
            label="Your Name"
            type="text"
            value={giftBox?.shippingName || ''}
            error={showWarnings && !giftBox?.shippingName}
            onChange={(event) =>
              saveNewValue('giftBox', {
                ...giftBox,
                shippingName: event.target.value,
              })
            }
            autoComplete="off"
            placeholder="Enter your name"
            className={styles.GiftShippingInformation__input}
            shouldScrollOnError
          />
        </Grid>
      </Grid>}
    </Box>
  )
}

export default GiftShippingInformation
