import React, { ChangeEvent, useEffect, useState } from 'react'
import useStyles from './styles'
import {
  Avatar,
  BottomLink,
  Box,
  Button,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextFieldLabel,
  Typography,
} from '../../../../../UI'
import { isEmpty } from 'lodash'
import {
  CONTACT_ROLE,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
  PHONE_MASK_REGEXP_NO_SCOPE,
  USER_TYPE,
  history,
  validateEmail,
} from '../../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
  arrow,
  obAgent,
  obHomeowner,
  obInstitutionalClient,
  icons,
} from '../../../../../../assets'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import {
  getConfigValue,
  getNewEstimateValues,
  getUser,
} from '../../../../../../ducks/selectors'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { authActions } from 'ducks/actions'

const Company = () => {
  const dispatch = useDispatch()
  const query = history.useQuery()
  const classes = useStyles()

  const user = useSelector(getUser)
  const newEstimate = useSelector(getNewEstimateValues())

  const isInstitutional = [USER_TYPE.INSTITUTIONAL].includes(user.clientType)

  const { estimate } = newEstimate
  const contacts = estimate.properties?.contacts
    ? estimate.properties.contacts
    : []

  const [phone, setPhone] = useState(
    user.phone
      ? user.phone?.toString()
      : contacts[0]?.phone
      ? contacts[0].phone
      : ''
  )

  const { showWarnings, saveNewValue, setShowWarnings, setCanGoForward } =
    useStepperContext()

  const validate: boolean =
    (PHONE_MASK_REGEXP_NO_SCOPE.test(phone) || PHONE_MASK_REGEXP.test(phone)) &&
    !isEmpty(user.companyName)

  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  useEffect(() => {
    setPhone(
      user.phone ? '' + user.phone : contacts[0]?.phone ? contacts[0].phone : ''
    )
  }, [])

  return (
    <>
      <Grid item>
        <Typography variant="h5" className={classes.title}>
          Great, {user.firstName}!
        </Typography>
        <Typography align="center"> You’re almost done!</Typography>
      </Grid>
      <Grid item>
        <LinearProgress variant="determinate" value={70} />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Company Name"
            error={showWarnings && isEmpty(user.companyName.trim())}
            onChange={(ev) => {
              saveNewValue('companyName', ev.target.value)
            }}
            value={user.companyName}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Company Zip Code"
            error={showWarnings && isEmpty(user.companyName.trim())}
            onChange={(ev) => {
              saveNewValue('address', { zipCode: ev.target.value })
            }}
            value={user.address.zipCode || ''}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextFieldLabel
            label="Your Mobile Phone Number"
            onChange={(ev) => {
              setPhone(ev.target.value)
              saveNewValue(
                'phone',
                parseInt(ev.target.value?.replaceAll('-', ''))
              )
            }}
            mask={PHONE_MASK_INPUT}
            value={phone}
            error={
              showWarnings &&
              (isEmpty(phone) ||
                !(
                  PHONE_MASK_REGEXP.test(phone) ||
                  PHONE_MASK_REGEXP_NO_SCOPE.test(phone)
                ))
            }
            placeholder="123-123-1234"
          />
        </Grid>
        {/* {!isInstitutional && <Grid item xs={12} lg={6}>
          <Typography className={classes.label}>
            Are you a NAR member?
          </Typography>
          <RadioGroup
            value={user.nar || false}
            onChange={(e: ChangeEvent<HTMLInputElement>) => saveNewValue('nar', e.target.value === 'true')}
            className={classes.radioContainer}
          >
            <FormRadioButton
              style={user.nar === true ? { borderColor: "var(--bosscat-blue-600)" } : {}}
              value={true}
              control={<Radio color='primary' />}
              label={<Typography variant='caption'>Yes</Typography>} className={classes.radio}
            />
            <FormRadioButton
              style={user.nar === false ? { borderColor: "var(--bosscat-blue-600)" } : {}}
              value={false}
              control={<Radio color='primary' />}
              label={<Typography variant='caption'>No</Typography>} className={classes.radio}
            />
          </RadioGroup>
        </Grid>} */}
        <Grid item>
          <BottomLink
            question="Already have an account?"
            linkText="Sign in here"
            className={classes.marginBottom}
            onClick={() => dispatch(authActions.logoutRequest())}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Company
