import { Item, RepairItem } from 'ducks/types'
import { ItemsActions, itemsTypes } from './types'

const actions = {
  fetchRecommendedItems: (
    payload: string,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.FETCH_RECOMMENDED_ITEMS,
    payload,
    callback
  }),
  setRecommendedItems: (payload: Partial<Item>[]): ItemsActions => ({
    type: itemsTypes.SET_RECOMMENDED_ITEMS,
    payload
  })
}

export default actions
