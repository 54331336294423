/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { propertyDetailType, FetchPropertyDetailAction, OrderInsightsAction } from './types'
import * as Sentry from '@sentry/react'
import { propertyDetailActions } from 'ducks/actions'

export function* fetchPropertyDetailAction({ payload, callback }: FetchPropertyDetailAction): SagaIterator {
  let success = false
  try {
    const propertyDetail = yield call(Punchlist.properties.getPropertyDetail, payload.id)

    if (propertyDetail) yield put(propertyDetailActions.setPropertyDetail(propertyDetail))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the property detail'))
    if (callback) yield call(callback, false)
  }
}

export function* orderInsights({ payload, callback }: OrderInsightsAction): SagaIterator {
  let success = false
  try {
    yield call(Punchlist.properties.requestOrder, payload.propertyId, payload.item, payload.request)

    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('order insights'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(propertyDetailType.FETCH_PROPERTY_DETAIL, fetchPropertyDetailAction)
  yield takeLatest(propertyDetailType.ORDER_INSIGHTS, orderInsights)
}
