import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        tableContainer: {
            [theme.breakpoints.down('lg')]: {
                width: '100vw'
            }
        },
        header: {
            display: 'flex',
        },
        table: {
            paddingRight: '0',
            [theme.breakpoints.down('lg')]: {
                paddingRight: '0px'
            }
        },
        tableComponent: {
            position: 'relative',
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: theme.palette.grey[200],
            borderRadius: 12
        },
        tableOff: {
            position: 'relative',
            borderStyle: 'solid',
            borderWidth: 0,
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
            '@global': {
                '> .MuiGrid-root': {
                    width: '100%',
                    margin: 'auto'
                }
            }
        },
        pageChange: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        cellHead: {
            backgroundColor: 'var(--background-color-secondary)',
            color: 'var(--head-text-color)',
            borderWidth: 0,
            fontFamily: 'Lato',
            fontSize: '16px'
        },
        tableSortLabel: {
            height: 'inherit !important',
            color: 'var(--head-text-color) !important',
            '&:hover': {
                color: 'var(--head-text-color)'
            }
        },
        row: {
            '&:hover': {
                backgroundColor: 'var(--row-focus)'
            }
        },
        cell: {
            fontFamily: 'LatoNormal',
            fontSize: 14,
            borderWidth: 0,
            padding: '.5rem 16px',
        },
        title: {
            fontFamily: 'LatoNormal',
            fontSize: '16px',
            lineHeight: '16px',
            marginBottom: '.3rem'
        },
        subtitle: {
            fontFamily: 'LatoNormal',
            fontSize: '12px',
        },
        states: {
            display: 'flex',
            gap: '.4rem',
            maxWidth: '400px',
            flexWrap: 'wrap'
        },
        description: {
            fontFamily: 'LatoNormal',
            fontWeight: 'normal',
            fontSize: '12px'
        },
        status: {
            textTransform: 'uppercase',
            fontSize: '16px'
        },
        actions: {
            display: 'flex',
            justifyContent: 'center',
            gap: '.4rem'
        },
        button: {
            width: 'fit-content',
            height: '42px',
            textTransform: 'none',
            borderRadius: 8,
            fontFamily: 'LatoNormal',
            padding: '1rem .7rem',
            minWidth: '50%',
            display: 'flex',
            backgroundColor: 'transparent',
            fontSize: '16px',
            "@global": {
                ".MuiButton-label": {
                    padding: '0',
                }
            },
            '&:hover': {
                backgroundColor: 'transparent'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '0px 5px'
            }
        },
        addMemberButton: {
            border: '2px solid var(--bosscat-blue-600)',
            color: 'var(--bosscat-blue-600)',
            padding: '1.3rem 1.8rem',
            "@global": {/* 
                ".MuiButton-label": {
                    display: 'flex',
                    justifyContent: 'space-between'
                }, */
                '.MuiSvgIcon-root': {
                    marginLeft: '.5rem',
                    width: '1rem'
                }
            },
        },
        deleteButton: {
            backgroundColor: 'var(--delete-button-color)',
            color: 'var(--white-color)',
            "@global": {
                '.MuiSvgIcon-root': {
                    marginLeft: '.5rem',
                    width: '1rem'
                }
            },
            '&:hover': {
                backgroundColor: 'var(--delete-button-color)'
            }
        },
        resendButton: {
            backgroundColor: 'transparent',
            color: 'var(--bosscat-blue-600)',
            border: '2px solid var(--bosscat-blue-600)',

        },
        switch: {
            width: '28px !important',
            height: '22px !important',
            padding: '2px 0px !important',
            // margin: '6px 5px',
            alignSelf: 'center',
            display: 'flex !important',
            '&:active': {
                '& .MuiSwitch-thumb': {
                    width: 15
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(9px)'
                }
            },
            '& .MuiSwitch-switchBase': {
                padding: 2,
                top: -16,
                '&.Mui-checked': {
                    transform: 'translateX(12px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: 'var(--bosscat-blue-600)'
                    }
                }
            },
            '& .MuiSwitch-thumb': {
                boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                width: 12,
                height: 12,
                borderRadius: 6,
                transition: theme.transitions.create(['width'], {
                    duration: 200
                })
            },
            '& .MuiSwitch-track': {
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: 'rgba(0,0,0,.25)',
                boxSizing: 'border-box'
            }
        },
        loading: {
            width: '100%',
            position: 'absolute',
            height: '100%',
            display: 'flex',
            zIndex: 99999,
            background: '#ffffff9e'
        },
        parent: {
            padding: '0px 16px !important'
        },
    })
)
