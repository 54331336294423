import { InviteNewClientRequest } from 'api/punchlist/users/types'
import {
  adminTypes,
  AdminActions,
  AdminType,
} from './types'

const actions = {
  fetchAdminClients: (
    callback: (succ: boolean) => void
  ): AdminActions => ({
    type: adminTypes.FETCH_ADMIN_CLIENTS,
    payload: null,
    callback
  }),
  setAdminClients: (payload: AdminType[]): AdminActions => ({
    type: adminTypes.SET_ADMIN_CLIENTS,
    payload
  }),
  inviteNewClient: (
    payload: InviteNewClientRequest,
    callback: (succ: boolean) => void
  ): AdminActions => ({
    type: adminTypes.INVITE_CLIENT,
    payload,
    callback
  }),
  updateUser: (
    payload: Partial<AdminType>,
    callback: (succ: boolean) => void
  ): AdminActions => ({
    type: adminTypes.UPDATE_USER,
    payload,
    callback
  }),
}

export default actions
