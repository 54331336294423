import { Box, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './styles'
import renovate from './img/renovate.png'
import repair from './img/repair.jpg'
import { melloHomeBgDark } from 'assets/index'
type Props = {}

const ServicesSlider = (props: Props) => {
    const [activeTab, setActiveTab] = React.useState(0)
    const classes = useStyles()
    return (
        <div className={classes.root} style={{ backgroundImage: `url(${melloHomeBgDark})` }}>
            <Typography className={classes.title}>
                FOR ANY POINT IN YOUR <br /> HOMEOWNERSHIP JOURNEY
            </Typography>
            <Box className={classes.sliderContainer}>
                <Box className={classes.tabs}>
                    <Box onClick={() => setActiveTab(0)} className={`${classes.tab} ${activeTab === 0 ? classes.tabActive : ''}`}>
                        <Typography className={classes.tabTitle}>REPAIRS</Typography>
                    </Box>
                    <Box onClick={() => setActiveTab(1)} className={`${classes.tab} ${activeTab === 1 ? classes.tabActive : ''}`}>
                        <Typography className={classes.tabTitle}>RENOVATIONS</Typography>
                    </Box>
                </Box>
                <Box className={classes.slider}>
                    <Box className={`${classes.slide} ${activeTab === 0 ? classes.activeSlide : ''}`}>
                        <Box className={classes.slideText}>
                            <Typography className={classes.slideTitle}>REPAIRS</Typography>
                            <Typography className={classes.slideSubtitle}>Whether before you list, after the inspection, or anytime in your homeownership journey, count on us for quality fixes that preserve your home’s value</Typography>
                        </Box>
                        <Box className={classes.slideImgContainer}>
                            <img src={repair} alt="Repairs" className={classes.slideImg} />
                        </Box>
                    </Box>
                    <Box className={`${classes.slide} ${activeTab === 1 ? classes.activeSlide : ''}`}>
                        <Box className={classes.slideText}>
                            <Typography className={classes.slideTitle}>RENOVATIONS</Typography>
                            <Typography className={classes.slideSubtitle}>Partner with our expert team to quickly and easily make your dream home a reality.</Typography>
                        </Box>
                        <Box className={classes.slideImgContainer}>
                            <img src={renovate} alt="Renovations" className={classes.slideImg} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ServicesSlider