import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../UI/MaterialUI';


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            position: "fixed",
            background: "#fff",
            zIndex: 999999999,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "17px 0px",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
        }
    }))
