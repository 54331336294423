/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { Punchlist } from 'api'
import * as Sentry from '@sentry/react'
import { DiscoveryActions, discoveryTypes } from './types'

export function* sendDiscovery({ payload, callback }: DiscoveryActions): SagaIterator {
  let success = false
  try {
    const feedback = yield call(Punchlist.discovery.sendDiscovery, payload)
    if (feedback) { success = true }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(discoveryTypes.SEND_DISCOVERY, sendDiscovery)
}
