import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        tab: {
            width: '100% !important',
            '@global': {
                '.MuiButtonBase-root ': {
                    width: '100%'
                }
            }
        }
    }))
