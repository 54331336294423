/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, ESTIMATE_STATUS, FORM_TYPE, ITEM_CATEGORY, ITEM_STATUS } from 'helpers'
import { Punchlist } from 'api'
import { CreateRepairListAction, NewRepairListType, newRepairListTypes, UpdateRepairListAction } from './types'
import { getNewRepairListValues } from './selectors'
import { Address, Estimate, Item } from 'ducks/types'
import { isEmpty } from 'lodash'
import { getUserId, getUser } from 'ducks/user/selectors'
import { newRepairListActions } from 'ducks/actions'
import { APPROVAL_TYPE, CONTACT_ROLE } from 'helpers/constants'
import { UpdateRequest } from 'api/types'
import * as Sentry from '@sentry/react'

export function* createRepairList({ payload, callback }: CreateRepairListAction): SagaIterator {
  let success = true
  let estimate = null
  try {
    const userId = yield select(getUserId)
    const mainContact = yield select(getUser)
    const values: NewRepairListType = yield select(getNewRepairListValues())

    const { price, date } = payload

    const newRepairList: Partial<Estimate> = {
      status: ESTIMATE_STATUS.NOT_SUBMITTED,
      formType: FORM_TYPE.CLIENT_REPAIR_LIST,
      deliveryServiceId: values.paymentOption?.id,
      dueOn: date,
      properties: {
        userId: userId,
        address: values.address as Address,
        totalValue: price,
        contacts: [{
          approvalType: APPROVAL_TYPE.NOT_NEEDED,
          email: mainContact.email[0].email,
          firstName: mainContact.firstName,
          fullName: `${mainContact.firstName} ${mainContact.lastName}`,
          id: mainContact.id,
          isPayer: false,
          lastName: mainContact.lastName,
          phone: mainContact.phone,
          role: CONTACT_ROLE.OTHER,
          sendEstimate: true
        }]
      }
    }

    const repairList = yield call(Punchlist.estimates.createEstimate, newRepairList)

    if (repairList && !isEmpty(repairList.id)) {
      for (const index in values.items) {
        if (success) {
          try {
            const itemAdded = yield call(
              Punchlist.estimates.createEstimateItem,
              repairList.id as string,
              {
                ...values.items[parseInt(index)],
                status: ITEM_STATUS.NEW,
                category: values.items[parseInt(index)].category ?? ITEM_CATEGORY.UNCATEGORIZED
              } as Partial<Item>)

            if (!itemAdded || isEmpty(itemAdded.itemId)) {
              success = false
              yield call(toast.error, errorTextTryingTo('add an item to the repair list'))
            }
          } catch (error) {
            success = false
            Sentry.captureException(error);
            yield call(toast.error, errorTextTryingTo('add an item to the repair list'))
          }
        }
      }
    } else success = false

    if (success) {
      const request: UpdateRequest = []
      request.push({ op: 'add', path: '/status', value: ESTIMATE_STATUS.NEW })

      estimate = yield call(Punchlist.estimates.updateEstimate, repairList.id, request)

      if (estimate && !isEmpty(estimate.id)) {
        yield put(newRepairListActions.setNewRepairListValue({ attr: 'estimate', value: estimate }))
      } else success = false
    }

    if (!success) yield call(toast.error, errorTextTryingTo('create a repair list'))
    if (callback) yield call(callback, success, estimate?.id ?? null)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create a repair list'))
    if (callback) yield call(callback, false)
  }
}

export function* updateRepairList({ payload, callback }: UpdateRepairListAction): SagaIterator {
  try {
    const values: NewRepairListType = yield select(getNewRepairListValues())
    const estimateId = values.estimate.id

    if (estimateId) {
      const request: UpdateRequest = []
      request.push({ op: 'add', path: '/deliveryServiceId', value: payload?.id })
      request.push({ op: 'add', path: '/dueOn', value: payload?.date })
      request.push({ op: 'add', path: '/expedited', value: true })

      const estimate = yield call(Punchlist.estimates.updateEstimate, estimateId, request)

      if (estimate && !isEmpty(estimate.id)) {
        yield put(newRepairListActions.setNewRepairListValue({ attr: 'estimate', value: estimate }))
        yield put(newRepairListActions.setNewRepairListValue({ attr: 'paymentOption', value: payload }))
        if (callback) yield call(callback, true, estimate.id)
      }
    }

    if (callback) yield call(callback, false)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('update the repair list'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(newRepairListTypes.CREATE_REPAIR_LIST, createRepairList)
  yield takeLatest(newRepairListTypes.UPDATE_REPAIR_LIST, updateRepairList)
}
