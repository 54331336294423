import { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Grid,
  Stepper,
  Typography,
  Step,
  StepLabel,
  StepContent,
  Tabs,
  Tab,
} from '@material-ui/core'
import HouseIcon from '@material-ui/icons/House'
import EcoIcon from '@material-ui/icons/Eco'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BuildIcon from '@material-ui/icons/Build'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import useStyles from '../../../styles'
import {
  construction,
  consultationDiscuss,
  green600Construction,
} from 'assets/index'
import HomeTeam from './HomeTeam'
import EstimateStep from './Steps/Estimate'
import EstimateStepContent from './StepContents/Estimate'
import JobStep from './Steps/Job'
import JobCompleteStep from './Steps/JobComplete'
import PaymentStep from './Steps/Payment'
import { TabPanel } from 'components/pages/Desktop/OrderMaintenance/Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { propertyActivityActions } from 'ducks/actions'
import { history } from 'helpers/index'
import { getPropertyActivity, getPropertyDetail } from 'ducks/selectors'
import { PropertyActivityActiveData } from 'ducks/types'
import DashboardValueComponent from 'components/UI/CustomUI/atoms/DashboardValueComponent'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'

interface PropertyActivityItemProps {
  data: PropertyActivityActiveData
}

const PropertyActivityItem = ({ data }: PropertyActivityItemProps) => {
  const styles = useStyles()
  
  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const [showContents, setShowContents] = useState<{ [key: string]: boolean }>(
    {}
  )

  const initSteps = [
    {
      key: 'estimate',
      step: (
        <EstimateStep
          activeData={data || ({} as PropertyActivityActiveData)}
          show={!!showContents['estimate']}
          setShow={(value) => {
            setShowContents({ ...showContents, estimate: value })
          }}
        />
      ),
      content: (
        <EstimateStepContent
          activeData={data || ({} as PropertyActivityActiveData)}
        />
      ),
    },
    {
      key: 'job',
      step: (
        <JobStep
          activeData={data}
          show={!!showContents['job']}
          setShow={(value) => {
            setShowContents({ ...showContents, estimate: value })
          }}
        />
      ),
      // content: <EstimateStepContent />,
    },
    {
      step: <JobCompleteStep activeData={data} />,
      // content: <EstimateStepContent />,
    },
    {
      step: <PaymentStep activeData={data} />,
      // content: <EstimateStepContent />,
    },
  ]

  const activeStep = useMemo(() => {
    let active = 0
    if (data?.estimateId) {
      active = 0
    }
    if (data?.jobId) {
      active = 1
    }
    if (data?.jobStartedOn) {
      active = 2
    }
    if (data?.jobCompletedOn) {
      active = 3
    }
    return active
  }, [data])

  return <Stepper
    activeStep={activeStep}
    orientation="vertical"
    style={{ width: '100%', marginTop: '16px', padding: isSmallScreen ? 0 : '24px' }}
    className={isSmallScreen ? '' : styles.containerBox}
  >
    {initSteps.map(({ key, step, content }, index) => (
      <Step
        active={index <= activeStep}
        key={index}>
        <StepLabel>{step}</StepLabel>
        {showContents[key as string] && (
          <StepContent TransitionProps={{ unmountOnExit: false, enter: true, exit: false }}>{content}</StepContent>
        )}
      </Step>
    ))}
  </Stepper>
}

const PropertyActivity = ({ handleChangeTab }: { handleChangeTab: (tab: number) => void }) => {
  const dispatch = useDispatch()

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const styles = useStyles()

  const propertyActivity = useSelector(getPropertyActivity())
  const propertyDetails = useSelector(getPropertyDetail())

  const { id: propertyId } = history.getParamValues()

  const [currentTab, setCurrentTab] = useState<number>(0)

  useEffect(() => {
    dispatch(propertyActivityActions.fetchPropertyActivity({ id: propertyId }))
  }, [])

  const propertySnapshots = [
    {
      icon: <EcoIcon className={styles.buttonIcon} />,
      desc: 'green rebates & tax incentives',
      color: 'var(--bosscat-green-600)',
      count: propertyDetails.details?.greenReport?.numPotentialIncentives || 0,
      clickable: !!propertyDetails?.details?.greenReport?.categories?.length,
    },
    // {
    //   icon: (
    //     <img
    //       src={construction}
    //       alt="need maintenance"
    //       className={styles.buttonIcon}
    //     />
    //   ),
    //   desc: 'need maintenance',
    //   color: 'var(--hardware-haze-700)',
    //   count: propertyActivity?.totalDueForMaintenance || 0,
    // },
    {
      icon: (
        <img
          src={green600Construction}
          alt="need maintenance"
          className={styles.buttonIcon}
        />
      ),
      desc: 'repairs',
      color: 'var(--handyman-green-600)',
      count: propertyActivity?.totalDueForReplacement || 0,
    },
    {
      icon: <AssignmentIcon className={styles.buttonIcon} />,
      desc: 'active estimates',
      color: 'var(--bosscat-blue-600)',
      count: propertyActivity?.totalActiveEstimates || 0,
    },
    {
      icon: <BuildIcon className={styles.buttonIcon} />,
      desc: 'active jobs',
      color: 'var(--bosscat-blue-600)',
      count: propertyActivity?.totalActiveJobs || 0,
    },
    {
      icon: <CheckCircleIcon className={styles.buttonIcon} />,
      desc: 'completed jobs',
      color: 'var(--bosscat-green-700)',
      count: propertyActivity?.totalCompletedJobs || 0,
    },
  ]

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCurrentTab(newValue)
  }

  const handleSnapShotClick = (desc: string) => {
    if (desc === 'green rebates & tax incentives') {
      handleChangeTab(2)
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      spacing={3}
      style={{ marginTop: '4px' }}
    >
      <Grid item container spacing={2}>
        <Grid item container xs={12}>
          <Grid
            item
            container
            style={{ marginTop: '8px' }}
            spacing={2}
          >
            {propertySnapshots.map((propertySnapshot, index) => (
              <Grid item xs={12} md>
                <DashboardValueComponent
                  label={propertySnapshot.desc}
                  labelColor={propertySnapshot.color}
                  showArrow={propertySnapshot.clickable || false}
                  clickable={propertySnapshot.clickable || false}
                  value={propertySnapshot.count}
                  onClick={() => handleSnapShotClick(propertySnapshot.desc)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction='column'>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'var(--bosscat-blue-900)',
              zIndex: '-1',
              height: '100%',
              borderRadius: '8px',
              width: '150px',
            },
          }}
          value={currentTab}
          onChange={handleTabChange}
          className={styles.Tabs}
          style={{ width: 'fit-content', maxWidth: '100%' }}
          TabScrollButtonProps={{ style: { height: '44px' } }}
        >
          <Tab
            className={styles.Tab}
            style={{ width: '150px' }}
            label="Active"
          />
          <Tab
            className={styles.Tab}
            style={{ width: '150px' }}
            label="Job History"
          />
        </Tabs>
        <TabPanel value={currentTab} index={0} style={{ width: '100%' }}>
          <Grid item container>
            {propertyActivity?.activeData?.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="100%"
                height="225px"
              >
                <img src={consultationDiscuss} alt="empty state" />
                <Typography
                  style={{
                    width: '300px',
                    textAlign: 'center',
                    color: 'var(--text-text-primary)',
                  }}
                  className={`${styles.lgFont} ${styles.noTransform}`}
                >
                  No Active Jobs <br /> Our Home Specialists are Ready
                </Typography>
              </Box>
            ) :
              propertyActivity?.activeData?.map((data, index) => (
                <PropertyActivityItem
                  key={index}
                  data={data}
                />
              ))
            }
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1} style={{ width: isSmallScreen ? 'fit-content' : '100%' }}>
          <Grid item container>
            {propertyActivity?.historyData?.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="100%"
                height="225px"
              >
                <img src={consultationDiscuss} alt="empty state" />
                <Typography
                  style={{
                    width: '300px',
                    textAlign: 'center',
                    color: 'var(--text-text-primary)',
                  }}
                  className={`${styles.lgFont} ${styles.noTransform}`}
                >
                  No Active Jobs <br /> Our Home Specialists are Ready
                </Typography>
              </Box>
            ) :
              propertyActivity?.historyData?.map(
                (data, index) => (
                  <PropertyActivityItem
                    key={index}
                    data={data}
                  />
                ))
            }
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default PropertyActivity
