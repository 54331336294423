import storeInfo from 'ducks/store'
import { Subscription } from 'ducks/types'
import { isEmpty } from 'helpers/index'
import { OrderMaintenanceType } from './types'

export const getOrderMaintenanceStep = () => (): any => {
  const { newEstimate } = storeInfo.store.getState() || {}
  return newEstimate?.step || 0
}

export const getOrderMaintenanceValue = (type: keyof OrderMaintenanceType) => (): any => {
  const { orderMaintenance } = storeInfo.store.getState()
  // if (!orderMaintenance) return null
  return orderMaintenance[type as keyof OrderMaintenanceType]
}

export const getOrderMaintenanceValues = () => (): OrderMaintenanceType => {
  const { orderMaintenance } = storeInfo.store.getState()
  return orderMaintenance
}

export const getOrderMaintenanceToCreate = () => (): Partial<Subscription> => {
  const { orderMaintenance } = storeInfo.store.getState()
  const { step, mobileStep, contractSigned, promo, homeownerReceives, ...rest } = orderMaintenance
  if (isEmpty(promo)) {
    rest.promoCode = null
  }
  return Object.fromEntries(Object.entries(rest)?.filter(([_, v]) => v));
}