import moment from 'moment'
import { addDays, formatParams } from '../../../helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/subscriptions'

const apiController: ApiController = {
  getSubscriptions: async (params) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`)
      .then(response => response.data)
  },

  createSubscription: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),

  getSubscription: async (subscriptionId: string) =>
    await axios
      .get(`${base}/${subscriptionId}`)
      .then(response => response.data),

  checkVip: async (email: string) =>
    await axios
      .get(`users/${email}/is-vip`)
      .then(response => response.data),

  getSubscriptionsPlans: async () =>
    await axios
      .get(`${base}/plans`)
      .then(response => response.data),

  getSubscriptionsDeliveryDate: async () =>
    await axios
      .get(`${base}/delivery-date`)
      .then(response => response.data),

  updateSubscription: async (subscriptionId: string, request) =>
    await axios
      .patch(`${base}/${subscriptionId}`, request)
      .then(response => response.data),

  requestScheduleService: async (subscriptionId: string, request) =>
    await axios
      .post(`${base}/${subscriptionId}/request`, request)
      .then(response => response.data),

  addSubscriptionToNotificationList: async (userInfo) =>
    await axios
      .post(`${base}/plans/notification-list`, userInfo)
      .then(response => response.data),

  initializeSubscription: async (request) =>
    await axios
      .post(`${base}/initialization`, request)
      .then(response => response.data),

  referralSubscription: async (subscriptionId: string, request) =>
    await axios
      .post(`${base}/${subscriptionId}/referral`, request)
      .then(response => response.data),
}

export default apiController
