import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    // BASE BUTTON'S STYLES
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      color: theme.palette.background.default,
      borderRadius: 6
    },
    bgWhite: {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer'
    },
    bgGrey: {
      backgroundColor: theme.palette.grey[400]
    },
    // ICON CONTAINER'S STYLE
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 28,
      height: 27,
      borderRadius: 4
    },
    bgError: {
      backgroundColor: theme.palette.secondary.light
    },
    bgPrimary: {
      backgroundColor: 'var(--bosscat-blue-600)'
    },
    bgBetter: {
      backgroundColor: 'var(--better-color)'
    },
    bgDisabled: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[400]
    },
    icon: {
      fontSize: 20
    }
  })
)
