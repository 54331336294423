import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '16px',
      alignItems: 'center'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    headerItem: {
      display: 'flex',
      gap: '4px',
      flexDirection: 'row'
    },
    headerTextBold: {
      fontFamily: 'LatoNormal',
      fontWeight: 700
    },
    headerText: {
      fontFamily: 'LatoNormal',
      fontWeight: 400
    },
    dot: {
      width: '4px',
      height: '4px',
      borderRadius: '25px',
      alignSelf: 'center',
      background: 'var(--bosscat-blue-600)'
    },
    estimateBox: {
      display: 'flex',
      gap: '16px',
      flexDirection: 'row',
      color: 'var(--bosscat-blue-600)'
    },
    estimateText: {
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      background: '#FAFBFF',
      borderRadius: '16px',
      padding: '8px 16px',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31)'
    }
  }
  ))
