import { ChangeEvent, useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PrivateLoader } from 'components/templates'
import {
  Typography,
  Grid,
  TextFieldLabel,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  RadioGroup,
  Radio,
  Link,
} from 'components/UI'
import {
  isEmpty,
  CONTACT_ROLE,
  validateEmail,
  PHONE_MASK_INPUT,
  history,
  PHONE_MASK_REGEXP,
  ESTIMATE_STATUS,
  USER_TYPE,
  FORM_TYPE,
} from 'helpers'
import {
  getEstimatesServices,
  getNewEstimateValue,
  getNewEstimateValues,
  getUser,
  isAuthorized,
} from 'ducks/selectors'
import { useStepperContext } from 'hooks/useStepperContext'
import {
  estimatesActions,
  newEstimateActions,
  userActions,
} from 'ducks/actions'
import { icons, createAccountLoading } from 'assets'
import useStyles from './styles'
import { EstimateContact } from 'ducks/types'
import ArrowOutwardIcon from '@material-ui/icons/ArrowUpwardRounded'

import { NewEstimateType } from 'ducks/newEstimate/types'
import { UserType } from 'ducks/user/types'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'

const UserInformation = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const push = history.usePush()
  const dispatch = useDispatch()
  const { showWarnings, setCanGoForward, setShowWarnings, goBack, goForward } =
    useStepperContext()

  const clientType = useSelector(getNewEstimateValue('clientType'))
  const isNar = useSelector(getNewEstimateValue('isNar'))
  
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const mainContact = useSelector(getNewEstimateValue('mainContact'))
  const user = useSelector(getUser)
  const nar = mainContact.nar || user?.nar || isNar || false

  const services = useSelector(getEstimatesServices)

  const isOther = [
    CONTACT_ROLE.LISTING_AGENT,
    CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  ].includes(clientType)
  const isInstitutional = [CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(
    clientType
  )
  const isAgent = [CONTACT_ROLE.LISTING_AGENT].includes(clientType)

  const [consent, setConsent] = useState(true)
  const [terms, setTerms] = useState(true)
  const [phone, setPhone] = useState(
    mainContact?.phone?.toString() || user?.phone?.toString()
  )

  const pushParams = history.usePushParams()

  const {
    preferredCommunicationPhone,
    preferredCommunicationMail,
    preferredCommunicationText,
  } = useSelector(getNewEstimateValues())

  const [validate, setValidate] = useState(false)
  const showPaymentScreen = services?.length > 1 || services[0]?.cost > 0
  //const isHomeOwnerRegistered = mainContact.registrationComplete && clientType === USER_TYPE.HOMEOWNER
  const [newContacts, setNewContacts] = useState<Partial<EstimateContact>[]>([])
  const validatePhone =
    !isEmpty(phone) &&
    parseInt(phone?.replaceAll('-', ''))?.toString().length === 10

  const { saveNewValue } = useStepperContext()

  const authorized = useSelector(isAuthorized)

  //const isValid = contacts && contacts?.length > 0
  const isValid = () => {
    if (authorized)
      return (
        consent &&
        terms &&
        (preferredCommunicationPhone ||
          preferredCommunicationMail ||
          preferredCommunicationText)
      )
    if (isOther) {
      return (
        consent &&
        terms &&
        validatePhone &&
        !isEmpty(mainContact?.companyName?.trim()) &&
        !isEmpty(mainContact?.address?.zipCode) &&
        (preferredCommunicationPhone ||
          preferredCommunicationMail ||
          preferredCommunicationText)
      )
    } else {
      return (
        consent &&
        terms &&
        validatePhone &&
        (preferredCommunicationPhone ||
          preferredCommunicationMail ||
          preferredCommunicationText)
      )
    }
  }

  const nextStep = (estimateId: string, finish?: boolean) => {
    // If we only have one service and costs $0 we avoid the calendar screen
    if ((services?.length === 1 && services[0]?.cost === 0) || finish) {
      push(`estimate/${estimateId}`)
    } else {
      goForward()
    }
  }

  const finishFreeEstimate = (finish?: boolean) => {
    if (!services || services.length === 0) setLoading(false)
    dispatch(
      newEstimateActions.updateEstimate(
        {
          dueOn: services[0].date,
          properties: { totalValue: services[0].cost },
          deliveryServiceId: services[0].id,
          status: ESTIMATE_STATUS.NEW,
        },
        (success, estimate) => {
          setLoading(false)
          if (success && estimate?.id) {
            nextStep(estimate.id, finish)
          }
        }
      )
    )
  }

  useEffect(() => {

    dispatch(
      newEstimateActions.fetchEstimatesServices({
        formType: estimate.formType,
        serviceable: estimate?.serviceable || false,
        isNar: nar || false,
        affiliate: estimate?.properties?.affiliate,
      })
    )
  }, [])

  const updateEstimate = () => {
    if (validate) {
      setLoading(true)
      updateMainContact('phone', parseInt(phone?.replaceAll('-', '')))
      const preferredCommunicationMethods = []

      if (preferredCommunicationPhone)
        preferredCommunicationMethods.push('PHONE')
      if (preferredCommunicationMail)
        preferredCommunicationMethods.push('EMAIL')
      if (preferredCommunicationText) preferredCommunicationMethods.push('TEXT')
      const contacts = estimate.properties.contacts
        ? estimate.properties.contacts
        : [{}]
      const userContactIndex = contacts.findIndex(
        (contact: any) => contact.email === user?.email?.[0]?.email
      )

      dispatch(
        newEstimateActions.replaceValueInEstimate(
          {
            path: `/properties/contacts/${
              userContactIndex === -1 || !userContactIndex
                ? 0
                : userContactIndex
            }/preferredCommunicationMethods`,
            value: preferredCommunicationMethods,
          },
          (success, estimateId) => {
            if (success && estimateId) {
              dispatch(
                newEstimateActions.replaceValueInEstimate(
                  {
                    path: `/properties/contacts/${
                      userContactIndex === -1 || !userContactIndex
                        ? 0
                        : userContactIndex
                    }/phone`,
                    value: parseInt(phone?.replaceAll('-', '')),
                  },
                  (success, estimateId) => {
                    if (success && estimateId) {
                      if (isOther) {
                        dispatch(
                          newEstimateActions.replaceValueInEstimate(
                            {
                              path: `/properties/affiliateType`,
                              value: isAgent ? 'BROKER' : 'INSTITUTIONAL',
                            },
                            (success, estimateId) => {
                              if (success && estimateId) {
                                if (isAgent) {
                                  dispatch(
                                    newEstimateActions.replaceValueInEstimate(
                                      {
                                        path: `/properties/nar`,
                                        value: nar || false,
                                      },
                                      (success, estimateId) => {
                                        if (success && estimateId) {
                                          dispatch(
                                            newEstimateActions.fetchEstimatesServices(
                                              {
                                                formType: estimate.formType,
                                                serviceable:
                                                  estimate?.serviceable ||
                                                  false,
                                                isNar: nar || false,
                                                affiliate:
                                                  estimate?.properties
                                                    ?.affiliate,
                                              },
                                              (success, services) => {
                                                const showPaymentScreenNew =
                                                  services?.length > 1 ||
                                                  services[0]?.cost > 0
                                                if (success) {
                                                  if (!showPaymentScreenNew) {
                                                    finishFreeEstimate(true)
                                                  } else {
                                                    setLoading(false)
                                                    nextStep(estimateId)
                                                  }
                                                }
                                              }
                                            )
                                          )
                                        }
                                      }
                                    )
                                  )
                                } else {
                                  if (!showPaymentScreen) {
                                    finishFreeEstimate()
                                  } else {
                                    setLoading(false)
                                    nextStep(estimateId)
                                  }
                                }
                              }
                            }
                          )
                        )
                      } else {
                        if (!showPaymentScreen) {
                          finishFreeEstimate()
                        } else {
                          setLoading(false)
                          nextStep(estimateId)
                        }
                      }
                    }
                  }
                )
              )
            }
          }
        )
      )
    } else setShowWarnings(true)
  }

  /*   const updateUser = (attr: keyof UserType, newValue: any) => {
      dispatch(userActions.setUserValue({
        attr,
        value: newValue
      }))
    } */

  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value },
      })
    )
  }

  const updateNewEstimate = useCallback(
    (attr: keyof NewEstimateType, value: any) => {
      saveNewValue(attr, value)
      dispatch(
        newEstimateActions.setNewEstimateValue({
          attr,
          value,
        })
      )
    },
    [saveNewValue]
  )

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }, [
    mainContact,
    phone,
    consent,
    terms,
    preferredCommunicationMail,
    preferredCommunicationPhone,
    preferredCommunicationText,
  ])

  useEffect(() => {
    setShowWarnings(false)
  }, [])

  useEffect(() => {
    const validate = isValid()
    setValidate(validate)
    setCanGoForward(validate)
  }, [
    consent,
    terms,
    preferredCommunicationPhone,
    preferredCommunicationMail,
    preferredCommunicationText,
    newContacts,
  ])

  useEffect(() => {
    !isOther &&
      setNewContacts([
        ...newContacts,
        {
          firstName: mainContact.firstName,
          lastName: mainContact.lastName ?? '',
          email: mainContact.email,
          phone: '',
          sendEstimate: true,
        },
      ])
  }, [])

  useEffect(() => {
    pushParams(`?${authorized ? 'p' : 'o'}=4`)
  }, [])

  useEffect(() => {
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'activeStepProgress',
        value: isOther ? (isAgent ? 4 : 2) : 3,
      })
    )
    dispatch(
      newEstimateActions.setNewEstimateValue({
        attr: 'totalStepProgress',
        value: isOther ? (isAgent ? 4 : 2) : 3,
      })
    )
  }, [])

  return (
    <PrivateLoader loading={loading} building="fragment">
      <Grid container item spacing={2} className={classes.root}>
        <Grid
          container
          item
          direction="column"
          xs={12}
          className={classes.container}
        >
          <Typography variant="h4" className={classes.title}>
            {mainContact.firstName &&
              `${mainContact.firstName}, you're almost done!`}
          </Typography>

          <Grid
            item
            className={
              isOther ? classes.containerForm : classes.containerFormMax
            }
          >
            {!authorized && (
              <>
                <Typography variant="subtitle1" className={classes.text}>
                  Please provide your contact information in case we have
                  questions about your estimate request.
                </Typography>

                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className={classes.questionsContainer}
                >
                  <>
                    <Grid
                      item
                      container
                      spacing={2}
                      style={
                        isInstitutional
                          ? { flexWrap: 'nowrap', alignItems: 'center' }
                          : {}
                      }
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldLabel
                          label="Your Mobile Phone Number"
                          type="tel"
                          value={phone?.toString() || ''}
                          mask={PHONE_MASK_INPUT}
                          error={showWarnings && !validatePhone}
                          onChange={(event) => {
                            setPhone(event.target.value)
                            updateMainContact(
                              'phone',
                              parseInt(event.target.value?.replaceAll('-', ''))
                            )
                          }}
                          placeholder="555-555-1234"
                        />
                      </Grid>
                      {isInstitutional && (
                        <Grid
                          item
                          container
                          spacing={2}
                          style={{
                            marginTop: '0.8rem',
                            marginBottom: '0.8rem',
                          }}
                        >
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="Company Name"
                              error={
                                showWarnings &&
                                isEmpty(mainContact.companyName.trim())
                              }
                              onChange={(ev) => {
                                updateMainContact(
                                  'companyName',
                                  ev.target.value
                                )
                              }}
                              value={mainContact.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextFieldLabel
                              label="Company Zip Code"
                              error={
                                showWarnings &&
                                isEmpty(mainContact?.companyName?.trim())
                              }
                              onChange={(ev) => {
                                updateMainContact('address', {
                                  zipCode: ev.target.value,
                                })
                              }}
                              type="number"
                              value={mainContact?.address?.zipCode || ''}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {isAgent && (
                      <Grid
                        item
                        container
                        spacing={2}
                        style={{ marginTop: '0.8rem', marginBottom: '0.8rem' }}
                      >
                        <Grid item xs={12} sm={12} md={6}>
                          <TextFieldLabel
                            label="Company Name"
                            error={
                              showWarnings &&
                              isEmpty(mainContact.companyName.trim())
                            }
                            onChange={(ev) => {
                              updateMainContact('companyName', ev.target.value)
                            }}
                            value={mainContact.companyName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextFieldLabel
                            label="Company Zip Code"
                            error={
                              showWarnings &&
                              isEmpty(mainContact?.companyName?.trim())
                            }
                            onChange={(ev) => {
                              updateMainContact('address', {
                                zipCode: ev.target.value,
                              })
                            }}
                            type="number"
                            value={mainContact?.address?.zipCode || ''}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography variant="body1" className={`${classes.subtitle}`}>
                What is your preferred method of communication? Check all that
                apply.
              </Typography>
              <Grid item container xs={12}>
                <FormControlLabel
                  style={
                    preferredCommunicationPhone
                      ? { borderColor: 'var(--bosscat-blue-600)' }
                      : {}
                  }
                  control={
                    <Checkbox
                      checked={preferredCommunicationPhone}
                      onChange={() => {
                        updateNewEstimate(
                          'preferredCommunicationPhone',
                          !preferredCommunicationPhone
                        )
                      }}
                      name="preferredPhone"
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">Phone</Typography>}
                  className={classes.check}
                />
                <FormControlLabel
                  style={
                    preferredCommunicationMail
                      ? { borderColor: 'var(--bosscat-blue-600)' }
                      : {}
                  }
                  control={
                    <Checkbox
                      checked={preferredCommunicationMail}
                      onChange={() => {
                        updateNewEstimate(
                          'preferredCommunicationMail',
                          !preferredCommunicationMail
                        )
                      }}
                      name="preferredMail"
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">Email</Typography>}
                  className={classes.check}
                />
                <FormControlLabel
                  style={
                    preferredCommunicationText
                      ? { borderColor: 'var(--bosscat-blue-600)' }
                      : {}
                  }
                  control={
                    <Checkbox
                      checked={preferredCommunicationText}
                      onChange={() => {
                        updateNewEstimate(
                          'preferredCommunicationText',
                          !preferredCommunicationText
                        )
                      }}
                      name="preferredText"
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">Text</Typography>}
                  className={classes.check}
                />
              </Grid>
            </Grid>
            <Box className={classes.buttonsCheck}>
              <Grid container item xs={12} className={classes.consent}>
                <Checkbox
                  checked={consent}
                  onChange={() => setConsent(!consent)}
                  name="consent"
                  color="primary"
                  style={{
                    color: showWarnings && !consent ? 'red' : 'primary',
                  }}
                />
                <Typography
                  variant="body1"
                  className={`${classes.checkConsent} ${classes.homeOwnerConsent}`}
                >
                  I consent to receive SMS messages from BOSSCAT regarding my
                  estimate and related services.
                </Typography>
              </Grid>
              <Grid container item xs={12} className={classes.legalContainer}>
                <Checkbox
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                  name="terms"
                  color="primary"
                  style={{
                    color: showWarnings && !terms ? 'red' : 'primary',
                  }}
                />
                <Box className={classes.acceptText}>
                  <Typography
                    variant="body1"
                    className={`${classes.checkTerms}`}
                  >
                    I accept
                  </Typography>
                  <Link
                    target="_blank"
                    href="https://bosscathome.com/terms/"
                    className={classes.legalLink}
                  >
                    BOSSCAT Legal Terms & Conditions{' '}
                    <ArrowOutwardIcon
                      style={{ color: '#2F7BF7', transform: 'rotate(45deg)' }}
                    />
                  </Link>
                </Box>
              </Grid>
            </Box>
            {!authorized && clientType !== CONTACT_ROLE.LISTING_AGENT && (
              <Box className={classes.accountContainer}>
                <img
                  src={createAccountLoading}
                  alt="BOSSCAT loading"
                  style={{ width: '48px', height: '48px' }}
                />
                <Typography className={classes.accountCopy}>
                  A BOSSCAT account will be created, and a temporary password
                  will be sent to your email.
                </Typography>
              </Box>
            )}
            {/* <Grid item container>
              <Grid item>
                <img alt="temporary acount" src={temporaryAcount} />
              </Grid>
              <Grid item>
                <Typography>A BOSSCAT account will be created, and a temporary password will be sent to your email.</Typography>
              </Grid>
            </Grid> */}
            <Grid
              className={classes.buttonsContainer}
              container
              item
              xs={12}
              style={{ display: 'flex', marginTop: '1rem', width: '100%' }}
              justifyContent="space-between"
            >
              <Button
                type="submit"
                variant="outlined"
                size="small"
                className={classes.buttonBack}
                startIcon={<icons.ArrowBack />}
                onClick={goBack}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                className={classes.buttonNext}
                endIcon={<icons.ArrowForward />}
                disabled={!validate}
                onClick={updateEstimate}
              >
                Good to Go!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default UserInformation
