import { FC, useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from '../../../../../UI'
import { DeleteHomeTeamMemberModalProps } from './types'
import PlaceIcon from '@material-ui/icons/Place'
import CloseIcon from '@material-ui/icons/Close'
import { Delete } from '@material-ui/icons'

import useStyles from './styles'
import { PropertyContact } from 'ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import HomeTeamCard from 'components/UI/CustomUI/molecules/HomeTeamCard'
import { propertyContactActions } from 'ducks/actions'
import { history } from 'helpers/index'

const DeleteHomeTeamMemberModal: FC<DeleteHomeTeamMemberModalProps> = ({
  open,
  setOpen,
  profile,
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const { id: propertyId } = history.getParamValues()

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteMember = () => {
    setLoading(true)
    dispatch(
      propertyContactActions.deletePropertyContact(
        { propertyId, contactId: profile?.id || '' },
        (succ) => {
          dispatch(
            propertyContactActions.fetchPropertyContacts(
              { id: propertyId },
              (succ) => {
                setLoading(false)
                handleClose()
              }
            )
          )
        }
      )
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      PaperProps={{
        style: {
          maxWidth: '620px',
          overflow: 'hidden',
        },
      }}
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px' }}
        spacing={2}
      >
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              style={{ width: '100%', gap: '4px' }}
              alignItems="center"
            >
              <PlaceIcon style={{ width: '20px', height: '20px' }} />
              <Typography className={styles.addHomeCardHeader}>
                DELETE HOME TEAM MEMBER
              </Typography>
            </Box>
            <Button className={styles.closeButton} onClick={handleClose}>
              <CloseIcon style={{ width: '14px', height: '14px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Typography className={styles.lgFont} style={{ fontWeight: 400 }}>
            Are you sure to delete this member? This action can’t be undone.
          </Typography>
        </Grid>
        <Grid item>
          <HomeTeamCard
            isSmall={false}
            profile={profile as PropertyContact}
            showButton={false}
          />
        </Grid>
        <Grid item>
          <Button
            className={styles.deleteButton}
            onClick={handleDeleteMember}
            startIcon={<Delete className={styles.icon} />}
            fullWidth
          >
            Delete Member
            {loading ? (
              <CircularProgress
                style={{ marginLeft: '4px', width: '18px', height: '18px' }}
              />
            ) : (
              <></>
            )}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteHomeTeamMemberModal
