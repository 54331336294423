import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      padding: '16px',
      flexFlow: 'row',
      borderWidth: '2px',
      boxSizing: 'border-box',
      gap: '16px',
      borderRadius: '16px',
      borderStyle: 'solid',
    },
    title: {
      fontFamily: 'LatoBlack, Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    content: {
      marginLeft: 0,
      gap: '16px',
      flexWrap: 'nowrap'
    },
    description: {
      fontFamily: 'LatoBlack, Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    button: {
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important',
      },
    },
  })
)
