import { Item, RepairItem } from "ducks/types"

/** TYPES **/
export const itemsTypes = {
  FETCH_RECOMMENDED_ITEMS: 'FETCH_RECOMMENDED_ITEMS',
  SET_RECOMMENDED_ITEMS: 'SET_RECOMMENDED_ITEMS',
}

/** ACTIONS **/
export interface FetchRecommendedItemsAction {
  type: typeof itemsTypes.FETCH_RECOMMENDED_ITEMS
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetRecommendedItemsAction {
  type: typeof itemsTypes.SET_RECOMMENDED_ITEMS
  payload: Partial<Item>[]
}

export type ItemsActions =
  | FetchRecommendedItemsAction
  | SetRecommendedItemsAction

/** REDUCER **/
export interface ItemsType {
  recommendedItems: Partial<Item>[] | null
}
