import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import { Avatar, BottomLink, Box, Button, Grid, LinearProgress, Radio, TextFieldLabel, Typography } from '../../../../../UI'
import { isEmpty } from 'lodash'
import { CONTACT_ROLE, USER_TYPE, history, roleOptions, validateEmail } from '../../../../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, configActions, userActions } from '../../../../../../ducks/actions'
import { arrow, obAgent, obHomeowner, obInstitutionalClient, icons } from '../../../../../../assets'
import { useStepperContext } from '../../../../../../hooks/useStepperContext'
import { getConfigValue, getNewEstimateValue, getUser } from '../../../../../../ducks/selectors'
import clsx from 'clsx'


const Role = () => {
  const classes = useStyles()

  const query = history.useQuery()
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const { saveNewValue, setShowWarnings, setCanGoForward } = useStepperContext()
  const mainContact = useSelector(getNewEstimateValue('mainContact'))

  const [workType, setworkType] = useState(user.clientType || mainContact?.clientType)
  // const [nar, setNar] = useState(user.nar ?? false)

  const handleSelect = (idx: any): void => {
    setworkType(idx)
    saveNewValue('clientType', idx)
    setShowWarnings(false)

    if (idx !== USER_TYPE.BROKER) {
      // setNar(false)
      saveNewValue('nar', false)
    }
  }

  useEffect(() => {
    if (user?.nar || !isEmpty(user?.affiliate)) {
      setworkType(USER_TYPE.BROKER)
      // user?.nar && setNar(true)
    }
  }, [user?.nar])

  useEffect(() => {
    setCanGoForward(!isEmpty(workType))
  }, [workType])

  return (
    <>
      <Grid item>
        <Typography variant="h5" className={classes.title}>tell us more about you</Typography>
      </Grid>
      <Grid>
        <LinearProgress variant="determinate" value={!isEmpty(workType) ? 30 : 0} />
        <Typography className={classes.subtitle}>Your role</Typography>
      </Grid>
      <Grid spacing={2} className={classes.options}>
        {roleOptions.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            className={clsx(
              classes.option,
              workType === item.id && classes.selected
            )}
            onClick={() => handleSelect(item.id)}
          >
            <Box className={classes.content}>
              <Avatar
                alt=""
                src={item.icon}
                className={classes.iconJob}
                variant="square"
              />
              <Box className={classes.details}>
                <Typography variant="body1">
                  {item.title}
                </Typography>
              </Box>
            </Box>
            <Radio
              color="primary"
              disableRipple
              checked={workType === item.id}
              style={{ backgroundColor: 'transparent' }}
            // classes={{ checked: classes['Mui-checked'] }}
            />
          </Grid>
        ))}
        <BottomLink
          question='Already have an account?'
          linkText='Sign in here'
          onClick={() => dispatch(authActions.logoutRequest())}
        />
      </Grid>
    </>
  )
}

export default Role