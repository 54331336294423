import { Box, Link, Typography } from '@material-ui/core'
import useStyles from './styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { territoriesActions } from 'ducks/actions'
import { getTerritories } from 'ducks/selectors'
import { logoSub1, icons } from 'assets/index'

const Footer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const territories = useSelector(getTerritories())

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories())
  }, [])

  return (
    <footer className={classes.root}>
      {/* <Box className={classes.content}>
        <Box className={classes.item}>
          <Typography className={classes.title}>Our Services</Typography>
          <Typography className={classes.subtitle}>Repairs</Typography>
          <Typography className={classes.subtitle}>Renovations</Typography>
          <Typography className={classes.subtitle}>Maintenance</Typography>
        </Box>
        <Box className={classes.item}>
          <Typography className={classes.title}>Our Services Areas</Typography>
          <Box className={classes.territoriesContainer}>
            {
              territories.map(territory => <Typography className={classes.subtitle}>{territory?.title}</Typography>)
            }
          </Box>
        </Box>
        <Box className={classes.item}>
          <Typography className={classes.title}>Resources</Typography>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/"
            rel="noreferrer"
          >
            Blog
          </Link>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/"
            rel="noreferrer"
          >
            News
          </Link>
        </Box>
        <Box className={classes.item}>
          <Typography className={classes.title}>All Things Bosscat</Typography>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/terms/"
            rel="noreferrer"
          >
            Our Story
          </Link>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/"
            rel="noreferrer"
          >
            Careers
          </Link>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/"
            rel="noreferrer"
          >
            Become a Pro
          </Link>
          <Link
            target="_blank"
            className={classes.subtitle}
            href="https://bosscathome.com/"
            rel="noreferrer"
          >
            Contact Us
          </Link>
        </Box>
      </Box> */}
      <Box className={classes.copies}>
        <Box className={classes.logoCopyRights}>
          <img src={logoSub1} alt="bosscatLogo" className={classes.logo} />
          <Typography>Copyright Bosscat 2023</Typography>
        </Box>
        <Typography variant='caption' className={classes.caption}>Bosscat is not an affiliate of mellohome, or its corporate parents, affiliates, and partners. mellohome is not responsible for the content of any third-party website or any link referenced herein. mellohome may earn a fee for purchases made through links in this website. To learn about how mellohome protects your data, view our <Link
          target="_blank"
          className={classes.link}
          href="https://www.mellohome.com/privacy-policy"
          rel="noreferrer"
        >
          Privacy Policy
        </Link>
        </Typography>
        {/* <Box className={classes.socialMedia}>
          <icons.YouTube />
          <icons.Facebook />
          <icons.LinkedIn />
          <icons.Twitter />
        </Box> */}
        <Box className={classes.socialMedia}>
          <Link
            target="_blank"
            className={classes.link}
            href="https://bosscathome.com/terms/"
            rel="noreferrer"
          >
            Terms
          </Link>
          <Link
            target="_blank"
            className={classes.link}
            href="https://bosscathome.com/privacy/"
            rel="noreferrer"
          >
            Privacy Policy
          </Link>
        </Box>
      </Box>
    </footer>
  )
}

export default Footer