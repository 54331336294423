import zIndex from '@material-ui/core/styles/zIndex'
import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // position: 'relative',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    textBase: {
      color: theme.palette.background.default,
      fontSize: 32,
      [theme.breakpoints.down('md')]: {
        fontSize: 26
      },
      fontWeight: 400,
      margin: '12px 32px',
      zIndex: 1,
    },
    Lato: {
      fontFamily: 'Lato'
    },
    moranga: {
      fontWeight: 400,
      fontFamily: 'moranga'
    },
    bottomMarks: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    },
    image: {
      display: 'flex',
      width: '400px',
      zIndex: 1,
      borderRadius: 8,
      border: '2px solid white'
    },
    descTitle: {
      textAlign: 'center',
      fontSize: '20px !important',
      lineHeight: '150% !important',
      fontFamily: 'NextArtNormal',
      fontWeight: 900,
      textTransform: 'uppercase',
      color: 'var(--hardware-haze-400)'
    },
    descDetail: {
      fontSize: '18px',
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '150%',
      textAlign: 'center',
      width: '400px',
      marginTop: '8px',
    }
  })
)
