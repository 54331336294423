import { workOdersTypes, WorkOrdersContextType } from './types'

export const WorkOrdersReducer = (state: WorkOrdersContextType, action: any) => {
  const { type, payload } = action

  switch (type) {
    case workOdersTypes.SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: payload
      }
    }
    case workOdersTypes.SET_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: payload
      }
    }
    default:
      return state
  }
}
