import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 0px',
      width: '100%',
      padding: '.2rem'
    },
    label: {
      minWidth: '86px'
    },
    rightSeparation: {
      marginRight: '8px'
    },
    itemTitle: {
      gridArea: 'itemTitle',
      alignItems: 'flex-start !important'
    },
    header: {
      width: '100%',
      justifyContent: 'space-between'
    },
    qtyInput: {
      display: 'flex',
      width: '50px',
      height: 24,
      textAlign: 'center',
      marginRight: 0,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: theme.palette.grey[200],
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      "&:focus": {
        outline: 'none'
      }
    },
    ref: {
      color: '#847848',
      fontFamily: 'NextArtHeavy',
      marginRight: '.2rem'
    },
    quantityTitle: {
      fontSize: 14,
      color: '#6D6A6F',
      display: 'flex',
      fontWeight: 900,
      gap: '.3rem',
      // marginBottom: '.4rem',
      // position: 'absolute',
      left: '.5rem',
      bottom: '.3rem',
      alignItems: 'center',
      '@global': {
        '.MuiTypography-root': {
          fontSize: 14,
        }
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
      }
    },
    listItem: {
      display: 'flex',
      justifyContent: 'flex-start !important',
      alignItems: 'flex-start',
      flexDirection: 'row',
      gap: '.5rem',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      height: 'fit-content',
      padding: '0.35rem !important',
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      '&:before': {
        display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse'
      }        // margin: '.5rem 0 0'
    },
    img: {
      color: '#9D9B9F',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },
    imgIcon: {
      fontSize: '1rem'
    },
    button: {
      color: '#2F7BF7',
      textTransform: 'none',
      fontWeight: 700,
      backgroundColor: '#FCFCFC',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      borderRadius: 8,
      height: 40,
    },
    item: {
      color: 'var(--head-text-color)',
      // fontFamily: 'NextArtBold',
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '24px'
    },
    itemDesc: {
      color: 'var(--input-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      margin: '1px 0px 0px 2px'
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '5px 0px 0px 0px'
    },
    itemEnd: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      alignSelf: 'flex-end',
      marginRight: '12px'
      // maxWidth: '100px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      // gap: '2px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start'
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    price: {
      fontFamily: 'NextArtBold',
      // fontSize: '16px',
      color: '#0B060F',
      gridArea: 'price',
      justifySelf: 'flex-end',
      whiteSpace: 'nowrap'
    },
    /* price: {
      fontFamily: 'NextArtBold',
      fontSize: '16px',
      color: '#0B060F',
      gridArea: 'price',
      justifySelf: 'flex-end',
      whiteSpace: 'nowrap'
    }, */
    icon: {
      // color: 'var(--head-text-color)',
      // margin: '18px 8px',
      fontSize: '1rem'
    },
    expand: {
      fontSize: '1rem',
      backgroundColor: '#F5F6F7',
      borderRadius: 2,
      width: '1.5rem',
      height: '1.5rem'
    },
    /*  expandBox: {
       display: 'flex',
       width: '26px',
       height: '26px',
       borderRadius: '4px',
       backgroundColor: 'var(--bosscat-blue-600)',
       justifyContent: 'center',
       alignItems: 'center',
       '&:hover': {
         backgroundColor: 'var(--button-focus-color)',
         borderColor: 'var(--button-focus-color)',
         boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'
       }
     }, */
    itemPercent: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--white-color)'
    },
    descr: {
      // fontFamily: 'LatoNormal',
      fontSize: 14,
      marginTop: '.3rem'
      // margin: '4px 10px 0px 10px'
    },
    container: {
      width: '100%',
      // padding: '.5rem',
      // backgroundColor: 'var(--background-color-secondary)',
      zIndex: 9,
      // border: '2px solid #F5F6F7',
      // borderRadius: 8,
      // boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 0px rgba(26, 42, 85, 0.31)',
      // borderTopLeftRadius: '0px',
      // borderTopRightRadius: '0px',
      borderTop: 0,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
      }
    },
    image: {
      margin: '6px 0px 4px 0px'
    },
    buttonRemove: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--delete-button-color)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--expired-color)'
      },
    },
    buttonAdd: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-green-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '54px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700)'
      },
    },
    buttonClose: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'white',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: '#1A2A55'
      }
    },
    buttonContent: {
      justifyContent: 'flex-end'
    },
    containerBox: {
      display: 'flex',
      width: 'fit-content',
      flexDirection: 'column',
      margin: '.5rem 0'
    },
    italic: {
      fontStyle: 'italic',
    },
    title: {
      fontStyle: 'inherit',
    },
    alert: {
      position: 'absolute',
      // zIndex: 1,
      // flex: 1, justifyContent: 'flex-end', // bottom: 0,
      width: '100%',
      height: 'auto',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--border-color)',
      borderRadius: '16px 16px 0px 0px',
      padding: '20px',

      display: 'flex',
      flexDirection: 'column',
      // position: 'sticky',
      bottom: 0,
      zIndex: 9
    },
    bold: {
      fontWeight: 900,
      display: 'inline'
    },
    flexStart: {
      alignItems: 'flex-start'
    },
    contentItem: {
      display: 'flex',
      gap: '.5rem',

      '@global': {
        '.MuiTypography-root': {
          fontSize: 16,
          display: 'inline',
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          }
        }
      }
    },
    content: {
      margin: "1rem 0",
      gap: '.5rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0'
      }
    },
    actionButton: {
      minHeight: 40,
      gap: '.5rem',
      gridArea: 'button',
      justifySelf: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    left: {},
    right: {},
    inspectionTitle: {
      fontFamily: 'NextArtBold',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    inspectionImg: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    inspectionTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem'
    },
    itemImage: {
      width: 41
    },
    quantityContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: '.3rem',
      gap: '.3rem',
    },
    itemHeader: {
      gap: '0.5rem',
      height: 'fit-content',
      display: 'flex',
      // padding: '0.35rem !important',
      justifyContent: 'space-between !important',
      width: '100%',
      alignItems: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        boxShadow: 'none',
        gridTemplateAreas: "'itemTitle price' 'button button'",
        // gridTemplateColumns: '75%',
      },
    },
    itemContainer: {
      width: '100%',
      alignItems: 'flex-start'
    },
    expandIcon: {
      marginTop: '.3rem'
    },
    alignCenter: {
      alignItems: 'center'
    },
    itemHeaderLeft: {
      width: '77% !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      }
    }
  })
)
