/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { FC, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
} from 'components/UI'
import useStyles from './styles'
import { AddressWarningModalProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from 'assets'
import { getNewEstimateStep, getNewEstimateSubstep, getNewEstimateValue } from 'ducks/selectors'
import { newEstimateActions } from 'ducks/actions'

const AddressWarningModal: FC<AddressWarningModalProps> = ({ open, setOpen, address, goForward, goBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const mainContact = useSelector(getNewEstimateValue('mainContact'))

  const handleClose = () => {
    setOpen(false)
  }

  const handleGoBack = () => {
    goBack()
    // serviceable ? dispatch(newEstimateActions.setNewEstimateSubstep(activeSubStep + 1)) : dispatch(newEstimateActions.setNewEstimateStep(activeStep + 1))
  }
  const updateEstimate = () => {
    dispatch(
      newEstimateActions.updateEstimateAddress(address,
        (success, _estimateId) => {
          if (success) {
            goForward()
          }
        })
    )
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          className={classes.close}
          onClick={handleClose}
        >
          <icons.Close />
        </IconButton>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Box>
          <Typography className={classes.title}>Hey, {mainContact.firstName}!</Typography>
          <Typography className={classes.text} data-testid="modal-title">
            You started building a project list before changing the address. The new address that you entered is in an area where we are not yet offering repair and renovation services, and therefore we’re unable to provide you with an estimate from a project list.
          </Typography>
          <Typography className={classes.text} data-testid="modal-warning">
            With the new address, you can still upload an inspection report to receive an estimate. If you proceed with the new address, you will be directed to an inspection report upload page and will not have access to the project list request page.
          </Typography>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            type='submit'
            variant='outlined'
            size='small'
            className={classes.buttonBack}
            onClick={handleGoBack}
          >
            Go Back to the Original Address
          </Button>
          <Button
            type='submit'
            variant='contained'
            size='small'
            className={classes.buttonNext}
            onClick={updateEstimate}
          >
            Proceed with the New Address
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddressWarningModal
