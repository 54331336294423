import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12,
      height: '120px',
      paddingTop: 5,
      zIndex: 2
    },
    primaryColor: {
      color: theme.palette.primary.main
    },
    secondaryColor: {
      color: theme.palette.primary.light
    },
    informationColor: {
      color: theme.palette.primary.light
    },
    successColor: {
      color: 'var(--bosscat-green-600)'
    },
    warningColor: {
      color: theme.palette.warning.dark
    },
    errorColor: {
      color: theme.palette.error.main
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      borderRadius: 8,
      backgroundColor: theme.palette.background.default,
      left: 24,
      top: -24,
      paddingLeft: 10,
      paddingRight: 10
    },
    title: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '150%',
      textTransform: 'uppercase'
    },
    valueContainer: {
      display: 'flex !important',
      justifyContent: 'center',
    },
    value: {
      // padding: '12px 24px !important',
      display: 'flex !important',
      flexBasis: '30% !important',
      height: '90px !important',
      zIndex: 2,
    },
    informationIcon: {
      position: 'absolute',
      top: 9,
      right: 9,
      fontSize: 16,
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    icon: {
      fontSize: 36,
      marginRight: '10px',
      marginBottom: '3px'
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      margin: '2px 0px 0px 0px'
    },
    number: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      fontSize: '14px',
      lineHeight: '22px',
      margin: '2px 0px 0px 5px'
    },
    percent: {
      margin: '8px 4px',
      padding: '4px',
      height: '8px',
      width: '8px',
      borderRadius: 40
    },
    label: {
      display: 'flex',
      alignItems: 'center'
    },
    totalRequest: {
      maxHeight: '100%',
      alignItems: 'center'
    }
  })
)
