import { StripeSecret } from 'api/punchlist/payments/types'
import { Payment } from 'ducks/types'

/** TYPES **/
export const paymentsTypes = {
  FETCH_PAYMENTS: 'FETCH_PAYMENTS',
  FETCH_PAYMENT_BY_ID: 'FETCH_PAYMENT_BY_ID',
  SET_PAYMENTS: 'SET_PAYMENTS',
  SET_CURRENT_PAYMENT: 'SET_CURRENT_PAYMENT',
  SET_PAYMENT_VALUE: 'SET_PAYMENT_VALUE',
  FETCH_STRIPE_SECRET: 'FETCH_STRIPE_SECRET',
  FETCH_STRIPE_SECRET_SETUP: 'FETCH_STRIPE_SECRET_SETUP'
}

/** ACTIONS **/
export interface FetchPaymentsAction {
  type: typeof paymentsTypes.FETCH_PAYMENTS
  payload?: string
  callback?: (succ: boolean) => void
}

export interface FetchPaymentByIdAction {
  type: typeof paymentsTypes.FETCH_PAYMENT_BY_ID
  payload?: string
  callback?: (succ: boolean) => void
}

export interface SetPaymentByIdAction {
  type: typeof paymentsTypes.SET_CURRENT_PAYMENT
  payload: Payment
}

export interface SetPaymentsAction {
  type: typeof paymentsTypes.SET_PAYMENTS
  payload: Payment[]
}

export interface PaymentValue {
  paymentId: string
  attr: keyof Payment
  value: any
}

export interface SetPaymentValueAction {
  type: typeof paymentsTypes.SET_PAYMENT_VALUE
  payload: PaymentValue
}

export interface FecthStripeSecretAction {
  type: typeof paymentsTypes.FETCH_STRIPE_SECRET
  payload: StripeSecret
  callback?: (secret?: string) => void
}

export interface FecthStripeSecretSetupAction {
  type: typeof paymentsTypes.FETCH_STRIPE_SECRET_SETUP
  payload: null
  callback?: (secret?: string) => void
}

export type PaymentsActions =
  | FetchPaymentsAction
  | FetchPaymentByIdAction
  | SetPaymentsAction
  | SetPaymentByIdAction
  | SetPaymentValueAction
  | FecthStripeSecretAction
  | FecthStripeSecretSetupAction

/** REDUCER **/
export interface PaymentsType extends Array<Payment> { }
