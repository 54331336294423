import React from 'react'
import useStyles from '../styles'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getTerritories } from 'ducks/selectors'

type Props = {}

const Banner4 = (props: Props) => {
    const classes = useStyles()
    const territories = useSelector(getTerritories())



    const serviceCities = territories
        ?.filter((t) => t.title !== 'In-Area')
        ?.sort((a, b) =>
            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
        .filter((terr) => terr.serviceable)
        .map((terr) => (
            `${terr.title}`
        )).join(', ')

    const serviceCitiesJoinedAndLastItemWithAnd = serviceCities.split(', ').join(', ').replace(/,([^,]*)$/, ' and$1')
    
    return (
        <div className={classes.banner4_Container}>
            <div className={`${classes.banner4_Block1} ${classes.maxWidth}`}>
                <div className={classes.banner4_Block1Left}>
                    <iframe
                        data-testid='BOSSCAT-video'
                        title="BOSSCAT"
                        className={classes.video}
                        src="https://www.youtube.com/embed/5237HyFN44M"
                    />
                </div>
                <div className={classes.banner4_Block1Right}>
                    <Typography className={classes.banner4_Title}>
                        Home repairs made easy
                    </Typography>
                    <Typography className={classes.banner4_Text}>
                        In select markets, we have partnered with BOSSCAT to provide you with priority access to a one-stop solution that makes completing repairs quick, easy and stress-free.
                    </Typography>
                    <ul className={classes.banner4_List}>
                        <li>
                            Repair estimates within 24 hours.
                        </li>
                        <li>
                            On-demand access to qualified contractors.
                        </li>
                        <li>
                            Dedicated Project Coordinator who manages your project from start to finish.
                        </li>
                    </ul>
                    <Typography className={classes.banner4_Text}>
                        {`Available in: ${serviceCitiesJoinedAndLastItemWithAnd}.`}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default Banner4
