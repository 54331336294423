import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      display: 'grid',
      margin: '0 auto',
      marginTop: '2rem',
      gap: theme.spacing(2),
      width: '100%',
      minHeight: '50vh',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        gap: 0,
        paddingBottom: "8rem"
      }
      /* maxWidth: theme.breakpoints.values.lg, */
    },
    logoAndProgress: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      paddingInline: "2px",
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingInline: "0px",
      }
    },
    progressContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    progressBarContainer: {
      width: '100%',
      marginRight: '1rem',
      backgroundColor: "#F5F6F7",
      borderRadius: 8,
      height: 8,
      position: 'relative',
      maxWidth: 200,
    },
    progressBarFill: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      borderRadius: 8,
      backgroundColor: '#01AA59',
    },
    progressBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      maxWidth: "fit-content",
    },
    progressSteps: {
      color: "#01AA59",
      fontFamily: "LatoBold",
      fontSize: 12,
    }
  })
)
