import { FC, memo } from 'react'

import { Box, Typography } from '../../..'
import { icons } from '../../../../../assets'
import { months } from '../../../../../helpers'
import { MonthControllerProps } from './types'
import useStyles from './styles'

const LowerMonthController: FC<MonthControllerProps> = ({ month, setMonth }) => {
  const classes = useStyles()

  return (
    <Box className={classes.monthController}>
      <Box
        className={classes.monthControllerButton}
        onClick={() => setMonth(month - 1)}
      >
        <icons.KeyboardArrowLeft width={6} />
      </Box>
      <Box className={classes.monthControllerLabel}>
        <Typography className={classes.monthControllerLabelText}>
          {months[month]}
        </Typography>
      </Box>
      <Box
        className={classes.monthControllerButton}
        onClick={() => setMonth(month + 1)}
      >
        <icons.KeyboardArrowRight width={6} />
      </Box>
    </Box>
  )
}

export default memo(LowerMonthController)
