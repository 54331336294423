import { CircularProgress, Input, Typography } from '@material-ui/core'
import { PTPAddress } from '..'
import { ptpLogo } from '../../../../../assets'
import useStyles from '../styles'
import { useEffect, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { PHONE_MASK_INPUT, isEmpty, validateEmail } from 'helpers/index'
import { CONTACT_ROLE, SHARE_ROLE } from 'helpers/constants'
import { Autocomplete } from 'components/UI'

interface FormData {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  role: CONTACT_ROLE
}

type Props = {
  handleSubmit: (data: FormData) => void
  handleCheckEmail: (email: string) => void
  id: string
  address?: PTPAddress
  showSecondaryForm: boolean
  loading: boolean
  setShowSecondaryForm: (value: boolean) => void
}

const Banner1 = ({
  handleSubmit,
  id,
  address,
  handleCheckEmail,
  setShowSecondaryForm,
  showSecondaryForm,
  loading,
}: Props) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [role, setRole] = useState<any>(null)
  const [showErrors, setShowErrors] = useState<any>(null)

  const roleOptions = SHARE_ROLE.map((role, index) => {
    return {
      key: index + 1,
      value: role.label,
      param: role.role,
    }
  })

  const classes = useStyles()

  const validate =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    validateEmail(email) &&
    !isEmpty(phoneNumber) &&
    !isEmpty(role)

  useEffect(() => {
    setShowErrors(false)
  }, [showSecondaryForm])

  const onSubmit = () => {
    setShowErrors(true)
    if (loading) return
    if (showSecondaryForm && !validate) return

    if (!showSecondaryForm) {
      if (validateEmail(email)) {
        return handleCheckEmail(email)
      } else {
        return
      }
    }
    return handleSubmit({
      email,
      firstName,
      lastName,
      phoneNumber,
      role: role?.param || ('OTHER' as CONTACT_ROLE),
    })
  }

  return (
    <div className={classes.banner1_Container}>
      <div className={`${classes.banner1_Body} ${classes.maxWidth}`}>
        <div className={classes.banner1_BodyLeft}>
          <img
            className={classes.banner1_ptpLogo}
            src={ptpLogo}
            alt="PTP logo"
          />
          <Typography className={classes.banner1_LeftTitle}>
            Turn your inspection report into a repair estimate.
          </Typography>
          <ul className={classes.banner1_List}>
            <li>
              An estimate from BOSSCAT is <strong>included</strong> with your
              Pillar To Post home inspection.
              <br />
              <br />
            </li>
            <li>
              After submitting the form, your estimate will be delivered to your
              inbox in 24 hours.
              <br />
              <br />
            </li>
            <li>
              Estimates are based on local costs in your area.
              <br />
              <br />
            </li>
            <li>
              The estimate can help you with negotiations and/or planning for
              future repairs.
              <br />
              <br />
            </li>
            <li>
              In select markets, BOSSCAT has a team of licensed and insured
              contractors who can come knock out the repairs—and they manage the
              whole process for you!
              <br />
              <br />
            </li>
          </ul>
        </div>
        <div className={classes.banner1_BodyRight}>
          <div className={classes.banner1_BodyRightInner}>
            <div className={classes.banner1_BodyRightBlock1}>
              <Typography className={classes.banner1_BodyRightTitle}>
                Request Your Repair Estimate for
              </Typography>
              {address && (
                <>
                  <Typography
                    className={classes.banner1_BodyRightAddressLine1}
                  >{`${address.line1}`}</Typography>
                  <Typography
                    className={classes.banner1_BodyRightAddressLine2}
                  >{`${address.city}, ${address.countrySubDivisionCode} ${address.postalCode}`}</Typography>
                </>
              )}
            </div>
            <div>
              <Typography
                className={classes.banner1_BodyRightConfirmAddress}
              >{`Confirm your email address for Inspection Report: ${id}.`}</Typography>
              <Typography
                onClick={() => setShowSecondaryForm(!showSecondaryForm)}
                className={classes.banner1_BodyRightConfirmAddressQuestion}
              >{`${
                showSecondaryForm
                  ? 'Load contact info from inspection report?'
                  : 'Contact details not on this inspection report?'
              }`}</Typography>
            </div>
            <div className={classes.banner1_BodyRightBlock2}>
              {showSecondaryForm ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    marginBottom: '2rem',
                  }}
                >
                  <Input
                    /* className={`${classes.banner1_BodyRightInput_half} ${
                      errors.firstName
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`} */
                    className={`${classes.banner1_BodyRightInput_half} ${
                      showErrors && !firstName
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`}
                    placeholder="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                  <Input
                    /* className={`${classes.banner1_BodyRightInput_half} ${
                      errors.lastName
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`} */
                    className={`${classes.banner1_BodyRightInput_half} ${
                      showErrors && !lastName
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`}
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                  <Input
                    /*   className={`${classes.banner1_BodyRightInput_half} ${
                      errors.email ? classes.banner1_BodyRightInput_error : ''
                    }`} */
                    className={`${classes.banner1_BodyRightInput_half} ${
                      showErrors && !validateEmail(email)
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`}
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <MaskedInput
                    mask={PHONE_MASK_INPUT}
                    placeholderChar={'\u2000'}
                    showMask={false}
                    className={`${classes.banner1_BodyRightInput_half} ${
                      showErrors && !phoneNumber
                        ? classes.banner1_BodyRightInput_error
                        : ''
                    }`}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />{' '}
                  <div style={{ marginTop: '16px', width: '100%' }} />
                  <Autocomplete
                    // label="Role"
                    value={role}
                    placeholder="Select Role"
                    options={roleOptions}
                    onChange={(value: any) => {
                      setRole(value)
                    }}
                    error={showErrors && !role}
                  />
                  {showErrors && !role && (
                    <Typography style={{ color: 'red' }}>
                      Please select your role before submitting the request.
                    </Typography>
                  )}
                </div>
              ) : (
                <Input
                  /* className={`${classes.banner1_BodyRightInput}  ${
                    errors.email ? classes.banner1_BodyRightInput_error : ''
                  }`} */
                  className={`${classes.banner1_BodyRightInput} ${
                    showErrors && !validateEmail(email)
                      ? classes.banner1_BodyRightInput_error
                      : ''
                  }`}
                  placeholder="Email"
                  value={email}
                  onChange={(event) => {
                    setShowErrors(false)
                    setEmail(event.target.value)
                  }}
                />
              )}
              <div
                style={loading ? { pointerEvents: 'none' } : {}}
                onClick={loading ? () => {} : onSubmit}
                className={classes.banner1_BodyRightButton}
              >
                {loading ? (
                  <CircularProgress
                    className={classes.banner1_Loading}
                    style={{
                      color: '#fff !important',
                      width: '25px',
                      height: '25px',
                    }}
                  />
                ) : (
                  <Typography className={classes.banner1_BodyRightSubmit}>
                    SUBMIT
                  </Typography>
                )}
              </div>
              <Typography
                className={classes.banner1_BodyRightConfirmTerms}
              >{`By submitting this request, you are authorizing BOSSCAT to retreive a digital copy of inspection summary ${id} from Pillar To Post. Pillar to Post inspectors are not recommending BOSSCAT repair technicians and are only offering this included service as a value to their customers.`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner1
