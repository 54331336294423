import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    booleanCheckbox: {
      //   marginLeft: '0.7rem',
    },
    radioGroup: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      gap: '16px',
    },
    formLabel: {
      flex: 1,
      padding: '0 0 0 8px',
      margin: '0 !important',
      border: '2px solid #EBECEF',
      borderRadius: 8,
      minWidth: '146px',
      width: '100%',
      background: 'white',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    label: {
      fontSize: '18px',
    },
  })
)
