import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      display: 'grid',
      width: '100%',
      gap: '20px',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center'
    },
    logo: {
      width: '100%',
      minWidth: '350px',
      maxWidth: '425px',
      paddingBottom: '25px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '200px'
      }
    },
    loader: {
      width: '100%',
      margin: 'auto',
      borderRadius: '8px',
      maxWidth: '50%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '24rem'
      }
    }
  })
)
