import { useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'
import { history, isEmpty } from '../../../../helpers'
import { newEstimateActions, userActions } from '../../../../ducks/actions'
import { UserReferrals } from '../../../../ducks/user/types'

const UtmParamsWrapper = ({ children }: { children: any }) => {
  const dispatch = useDispatch()

  const query = history.useQuery()
  const source = query.get('utm_source') || query.get('utmsource')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')
  const affiliate = query.get('affiliate') || ''
  const isNar = !!new URLSearchParams(window.location.search).get('nar')


  useEffect(() => {
    const attrs = {} as Partial<UserReferrals>
    if (source) attrs.source = source
    if (medium) attrs.medium = medium
    if (campaign) attrs.campaign = campaign
    if (content) attrs.content = content
    dispatch(userActions.setUserValue({ attr: 'userProperties', value: attrs }))
    dispatch(
      newEstimateActions.setNewEstimateValues({
        attrs: {
          isNar,
          affiliate
        }
      })
    )
  }, [source, medium, campaign, content, dispatch, isNar, affiliate])

  return (
    <>
      {children}
    </>
  )
}

export default UtmParamsWrapper
