import axios from '../axios'
import { ApiController } from './types'

const base = '/invoices'

const apiController: ApiController = {

  getInvoiceById: async (invoiceId: string) =>
    await axios
      .get(`${base}/${invoiceId}`)
      .then(response => response.data)
}

export default apiController
