import { FC, useState, useRef, useEffect } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  InputAdornment,
  SelectAddress,
  TextFieldLabel,
  Typography,
} from '../../../../../UI'
import { AddHomeTeamMemberModalProps } from './types'
import PlaceIcon from '@material-ui/icons/Place'
import CloseIcon from '@material-ui/icons/Close'
import { CameraAlt, Delete, CheckCircle, Save } from '@material-ui/icons'

import useStyles from './styles'
import { PropertyContact } from 'ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import { filesActions, propertyContactActions } from 'ducks/actions'
import { blueUpload } from 'assets/index'
import { getPropertyId } from 'ducks/selectors'
import { HOMETEAM_ROLES } from 'helpers/constants'
import { FILES_URL } from 'components/pages/User/Register/_test_/constants'
import ErrorBanner from './ErrorBanner'
import { PHONE_MASK_INPUT } from 'helpers/index'

const AddHomeTeamMemberModal: FC<AddHomeTeamMemberModalProps> = ({
  open,
  setOpen,
  isEdit,
  profile,
}) => {
  const dispatch = useDispatch()

  const [state, setState] = useState<Partial<PropertyContact>>(
    profile ?? {
      role: HOMETEAM_ROLES[0].key,
    }
  )
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState(false)

  const propertyId = useSelector(getPropertyId())

  const styles = useStyles()

  const inputRef = useRef<any>(null)

  const isFile = !!file || !!state?.imageUrl

  useEffect(() => {
    setState(
      profile ?? {
        role: HOMETEAM_ROLES[0].key,
      }
    )
  }, [profile])

  const handleClose = () => {
    setFile(null)
    setState({ role: HOMETEAM_ROLES[0].key })
    setOpen(false)
  }

  const handleStateChange = (prop: keyof PropertyContact, value: any) => {
    setState({
      ...state,
      [prop]: value,
    })
  }

  const handleSelectPicture = () => {
    if (!isFile) {
      inputRef.current.click()
    } else {
      setFile(null)
      setState({ ...state, imageUrl: undefined })
    }
  }

  const createPropertyContact = (request: Partial<PropertyContact>) => {
    dispatch(
      propertyContactActions.createPropertyContact(
        { id: propertyId, request },
        (succ) => {
          handleClose()
          dispatch(
            propertyContactActions.fetchPropertyContacts(
              { id: propertyId },
              (succ) => {
                setLoading(false)
              }
            )
          )
        }
      )
    )
  }

  const updatePropertyContact = (request: Partial<PropertyContact>) => {
    dispatch(
      propertyContactActions.updatePropertyContact(
        { propertyId, contactId: profile?.id || '', request },
        (succ) => {
          handleClose()
          dispatch(
            propertyContactActions.fetchPropertyContacts(
              { id: propertyId },
              (succ) => {
                setLoading(false)
              }
            )
          )
        }
      )
    )
  }

  const handleAddMember = () => {
    if (loading) return ;
    if (
      !state.companyName ||
      !state.firstName ||
      !state.lastName ||
      !state.email ||
      !state.phone ||
      !state.role ||
      loading
    ) {
      setShowError(true)
      return
    }

    setShowError(false)
    setLoading(true)

    if (file) {
      dispatch(
        filesActions.uploadFile(file, (succ, fileName) => {
          createPropertyContact({
            ...state,
            imageUrl: `${FILES_URL}${fileName}`,
          })
        })
      )
    } else {
      createPropertyContact(state)
    }
  }

  const handleEditMember = () => {
    if (loading) return ;
    if (
      !state.companyName ||
      !state.firstName ||
      !state.lastName ||
      !state.email ||
      !state.phone ||
      !state.role ||
      loading
    ) {
      setShowError(true)
      return
    }

    setShowError(false)
    setLoading(true)

    if (file) {
      dispatch(
        filesActions.uploadFile(file, (succ, fileName) => {
          updatePropertyContact({
            ...getChangedProps(),
            imageUrl: `${FILES_URL}${fileName}`,
          })
        })
      )
    } else {
      updatePropertyContact(getChangedProps() || {})
    }
  }

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0])
    }
  }

  const getChangedProps = () => {
    let flag = false
    const request: Partial<PropertyContact> = {}
    Object.keys(profile || {}).forEach((key) => {
      if (
        (profile || {})[key as keyof PropertyContact] !==
        (state || {})[key as keyof PropertyContact]
      ) {
        request[key as keyof PropertyContact] =
          (state || {})[key as keyof PropertyContact] || ''
        flag = true
      }
    })

    if (flag) return request
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      fullWidth
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px', gap: '16px' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            style={{ width: '100%', gap: '4px' }}
            alignItems="center"
          >
            <PlaceIcon style={{ width: '20px', height: '20px' }} />
            <Typography className={styles.addHomeCardHeader}>
              {`${isEdit ? 'EDIT' : 'NEW'} HOME TEAM MEMBER`}
            </Typography>
          </Box>
          <Button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon style={{ width: '14px', height: '14px' }} />
          </Button>
        </Box>
        <Grid container spacing={2}>
          {showError && (
            <Grid item xs={12}>
              <ErrorBanner />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextFieldLabel
              value={state.firstName || ''}
              label="Name"
              placeholder="Member's Name"
              onChange={(e) => handleStateChange('firstName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              value={state.lastName || ''}
              label="Last Name"
              placeholder="Member's Last Name"
              onChange={(e) => handleStateChange('lastName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              value={state.email || ''}
              label="Email"
              placeholder="mail@email.com"
              onChange={(e) => handleStateChange('email', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLabel
              value={state.phone || ''}
              label="Phone Number"
              placeholder="555-555-5555"
              onChange={(e) => handleStateChange('phone', e.target.value)}
              mask={PHONE_MASK_INPUT}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldLabel
              value={''}
              label="Profile Picture"
              // onChange={(e) => handleStateChange('imageUrl', e.target.value)}
              // disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      display="flex"
                      style={{
                        gap: '4px',
                        cursor: 'pointer',
                        color: isFile
                          ? 'var(--text-text-success)'
                          : 'var(--text-text-tertiary)',
                      }}
                      alignItems="center"
                    >
                      {isFile ? (
                        <CheckCircle
                          style={{ width: '18px', height: '18px' }}
                        />
                      ) : (
                        <CameraAlt style={{ width: '18px', height: '18px' }} />
                      )}
                      <Typography
                        className={styles.title}
                        style={{ fontWeight: 400 }}
                      >
                        {isFile
                          ? 'Profile picture uploaded'
                          : 'Upload Profile Picture'}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      display="flex"
                      style={{
                        gap: '4px',
                        cursor: 'pointer',
                        color: isFile
                          ? 'var(--punchlist-red)'
                          : 'var(--text-text-info)',
                      }}
                      alignItems="center"
                      onClick={handleSelectPicture}
                    >
                      {isFile ? (
                        <Typography className={styles.title}>Delete</Typography>
                      ) : (
                        <Typography className={styles.title}>Upload</Typography>
                      )}
                      {isFile ? (
                        <Delete style={{ width: '18px', height: '18px' }} />
                      ) : (
                        <img
                          src={blueUpload}
                          alt="upload"
                          style={{ width: '16px', height: '16px' }}
                        />
                      )}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <input
              ref={inputRef}
              style={{ visibility: 'hidden' }}
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              onChange={handleUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={HOMETEAM_ROLES}
              value={HOMETEAM_ROLES.find((role) => role.key === state.role)}
              onChange={(e) => {
                handleStateChange('role', e.key)
              }}
              label="Role"
              placeholder="Member's Role"
              labelClassName={styles.lgFont}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldLabel
              value={state.companyName || ''}
              label="Company Name"
              placeholder="Company name"
              onChange={(e) => handleStateChange('companyName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={styles.addButton}
              fullWidth
              onClick={isEdit ? handleEditMember : handleAddMember}
              startIcon={isEdit && !loading ? <Save className={styles.icon} /> : <></>}
              disabled={isEdit && !getChangedProps()}
            >
              <Typography className={styles.lgFont}>
                {`${loading ? 'Saving' : isEdit ? 'Save Changes' : 'Add Member'}`}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AddHomeTeamMemberModal
