import { formatParams } from '../../../helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/employees'

const apiController: ApiController = {
    getEmployees: async (params) => {
        const parameters = formatParams(params);
        return await axios
            .get(`${base}?${parameters}`)
            .then(response => response.data)
    },
}

export default apiController
