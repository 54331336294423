import { authTypes } from 'ducks/auth/types'
import { Payment } from 'ducks/types'
import { paymentsTypes, PaymentsType, PaymentsActions, PaymentValue } from './types'

const initialState: PaymentsType = []

const reducer = (state = initialState, action: PaymentsActions): PaymentsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case paymentsTypes.SET_PAYMENTS: {
      return payload as Payment[]
    }

    case paymentsTypes.SET_PAYMENT_VALUE: {
      const { paymentId, attr, value } = payload as PaymentValue

      const index = state.findIndex(payment => {
        return payment.id === paymentId
      })

      if (index !== -1) {
        const paymentsCopy = [...state]
        paymentsCopy[index] = {
          ...state[index],
          [attr]: value
        }
        return paymentsCopy
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
