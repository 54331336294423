/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { Address } from 'ducks/types'
import { useStepperContext } from 'hooks/useStepperContext'
import {
  icons,
  obAgent,
  obHomeowner,
  obInstitutionalClient,
} from '../../../../../../assets'
import {
  newInspectionActions,
  userActions
} from '../../../../../../ducks/actions'
import { getNewInspectionValue, getUser, getUserPrimaryEmail, isAuthorized } from '../../../../../../ducks/selectors'
import {
  CONTACT_ROLE,
  USER_TYPE,
  agentOptions,
  history,
  institutionalOptions,
  isEmpty,
  validateEmail
} from '../../../../../../helpers'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Radio,
  RadioGroup,
  SelectAddress,
  TextFieldLabel,
  Typography
} from '../../../../../UI'
import AddressWarningModal from './AddressWarningModal'
import useStyles from './styles'
import { WorkProps } from './types'

export const roleOptions = [
  {
    title: `I'm a Homeowner/Homebuyer`,
    icon: obHomeowner,
    id: CONTACT_ROLE.HOMEOWNER,
  },
  {
    title: `I represent a Homeowner/Homebuyer`,
    icon: obAgent,
    id: CONTACT_ROLE.LISTING_AGENT,
  },
  {
    title: `I'm an Institutional Investor`,
    icon: obInstitutionalClient,
    id: CONTACT_ROLE.INSTITUTIONAL_INVESTOR,
  },
]

const Work: FC<WorkProps> = ({
  //  setShowRegister,
  // setShowLogin,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const query = history.useQuery()

  const {
    goForward,
    showWarnings,
    setShowWarnings
  } = useStepperContext()

  const savedAddress = useSelector(getNewInspectionValue('address'))
  const mainContact = useSelector(getNewInspectionValue('mainContact'))
  const clientType = useSelector(getNewInspectionValue('clientType'))
  const isInstitutional = clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR
  const authorized = useSelector(isAuthorized)

  const [firstName, setFirstName] = useState(mainContact.firstName ?? '')
  const [lastName, setLastName] = useState(mainContact.lastName ?? '')
  const [address, setAddress] = useState(!isEmpty(savedAddress.line_1) ? savedAddress : '')
  const [email, setEmail] = useState(mainContact.email ?? '')
  const [loading, setLoading] = useState(false)

  const [showProfessionalType, setShowProfessionalType] = useState((clientType === CONTACT_ROLE.LISTING_AGENT || clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR) && !authorized)

  const professionalTypeOptions = isInstitutional ? institutionalOptions : agentOptions

  const user = useSelector(getUser)

  const userMail = useSelector(getUserPrimaryEmail)

  const [showWarningModal, setShowWarningModal] = useState(false)
  const [canShowError, setCanShowError] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)


  const affiliate = query.get('affiliate')

  const addressIsValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address.latitude &&
    address.longitude
    && !apiLoading

  const validate = () => {
    if (authorized) {
      return addressIsValid
    } else {
      return !isEmpty(firstName) && !isEmpty(email) && validateEmail(email) && addressIsValid && !isEmpty(clientType) && (showProfessionalType ? professionalTypeOptions.some(option => option.key === (isInstitutional ? mainContact.institutionalRole : mainContact.userClientType)) : true)
    }
  }

  const updateMainContact = (attr: string, value: any) => {
    dispatch(
      newInspectionActions.setNewInspectionValue({
        attr: 'mainContact',
        value: { ...mainContact, [attr]: value },
      })
    )
  }

  const handleSelect = (idx: any): void => {
    updateMainContact('role', idx)
    dispatch(
      newInspectionActions.setNewInspectionValue({
        attr: 'clientType',
        value: idx,
      })
    )
    updateMainContact('clientType', idx)
  }

  
  const handleChangeAddress = (newAddress: Address) => {
    dispatch(
      newInspectionActions.setNewInspectionValue({
        attr: 'address',
        value: newAddress
      })
    )
    setAddress(newAddress)
  }

  const handleNext = () => {
    if (validate()) {
      setLoading(true)

      dispatch(
        userActions.fetchUserHead(
          {
            email: email || mainContact?.email || "",
            userType: 'Client',
          },
          (succ) => {
           
              if ([CONTACT_ROLE.LISTING_AGENT, CONTACT_ROLE.INSTITUTIONAL_INVESTOR].includes(clientType) && !showProfessionalType && !authorized) {
                setShowProfessionalType(true)
                setLoading(false)
              } else {
                setLoading(true)
                dispatch(
                  newInspectionActions.setNewInspectionValue({
                    attr: 'step',
                    value: 0,
                  })
                )
              } 
            
          }
        )
      )
    }
  }

  useEffect(() => {
    if (!isEmpty(affiliate)) {
      handleSelect(CONTACT_ROLE.LISTING_AGENT)
    }
  }, [])

  useEffect(() => {
    // If user is logged in we replace the mainContact with the user info
    if (user.id) {
      dispatch(newInspectionActions.setNewInspectionValue({
        attr: 'mainContact',
        value: {
          ...mainContact,
          email: userMail,
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user.phone,
          sendEstimate: true
        }
      }))
      !clientType && dispatch(newInspectionActions.setNewInspectionValue({
        attr: 'clientType',
        value: user.clientType === USER_TYPE.HOMEOWNER
          ? user.clientType
          : CONTACT_ROLE.LISTING_AGENT
      }))
    }

  }, [user])

  const handleChangeProfessionalType = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = professionalTypeOptions.find(option => option.label === e.target.value)
    updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', selectedOption?.key)
  }



  return (
    <>
      {!showProfessionalType ?
        <Box className={classes.container}>
          <Box aria-label='estimate-account-work' >
            <AddressWarningModal
              open={showWarningModal}
              setOpen={setShowWarningModal}
              address={address}
              goForward={() => dispatch(
                newInspectionActions.setNewInspectionValue({
                  attr: 'step',
                  value: 0,
                })
              )}
            />
            
            {!authorized &&
              <>
              <Typography className={classes.title}>
              WELCOME TO OUR EASY HOME INSPECTION REQUEST FORM
              </Typography>
            </>
            }
          </Box>

          <Grid container spacing={4} direction="column" alignItems='flex-end'>
            {!authorized &&
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="First Name"
                    type="text"
                    value={firstName}
                    error={showWarnings && isEmpty(firstName)}
                    onChange={(event) => {
                      setFirstName(event.target.value)
                      updateMainContact('firstName', event.target.value)
                    }}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Last Name"
                    type="text"
                    value={lastName}
                    error={showWarnings && isEmpty(lastName)}
                    onChange={(event) => {
                      setLastName(event.target.value)
                      updateMainContact('lastName', event.target.value)
                    }}
                    placeholder="Last Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextFieldLabel
                    label="Email"
                    type="email"
                    value={email}
                    error={showWarnings && (isEmpty(email) || !validateEmail(email))}
                    onChange={(event) => {
                      setEmail(event.target.value)
                      updateMainContact('email', event.target.value)
                    }}
                    placeholder="Email"
                  />
                </Grid>
              </Grid>
            }
            <Grid item container spacing={2}>
              <Box className={classes.selectAddressContainer} aria-label='steps-location'>
              {authorized &&
                  <>
                    <Typography className={classes.title}>
                      Let's get started, {user.firstName}
                    </Typography>
                  </>
                }
                <Grid container spacing={2} justifyContent='space-between'>
                  <Grid container item xs={12} xl={12} >
                    <Grid item xs={12}>
                      <SelectAddress
                        showUnitNumberBesideAddress
                        //showVertical
                        xl={12}
                        showWarnings={canShowError && !addressIsValid}
                        onChange={handleChangeAddress}
                        title={
                          <Typography variant='subtitle2' className={classes.titleLabel}>
                            Property Address for the Inspection
                          </Typography>
                        }
                        placeholder='Property address'
                        hasCallback
                        savedAddress={savedAddress}
                        errorMessage={
                          <Box className={classes.addressError}>
                            <Typography>Hey{mainContact?.firstName && `, ${mainContact.firstName}`}!</Typography>
                            <Typography className={classes.addressErrorSubtitle}>This is an invalid address. Let’s modify the address to go on with your request.</Typography>
                          </Box>
                        }
                        showMap={false}
                        customClass={classes.selectAddress}
                        unitNumberClassName={classes.unitNumber}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {!authorized && <Grid item container spacing={2}>
              <Typography className={classes.roleTitle}>
                What is your role in this Home Inspection?
              </Typography>
              <Grid item container className={classes.options}>
                {roleOptions.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    className={clsx(
                      classes.option,
                      clientType === item.id && classes.selected
                    )}
                    onClick={() => {updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', null)
                    handleSelect(item.id)}}
                  >
                    <Box className={classes.content}>
                      <Box className={classes.details}>
                        <Typography variant="body1">
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Radio
                      color="primary"
                      disableRipple
                      checked={clientType === item.id}
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </Grid>
                ))}
                
              </Grid>
            </Grid>}
          </Grid>

        </Box>
        :
        <Box aria-label='estimate-account-work' >
          <Typography className={classes.title}>
            tell us about your professional type
          </Typography>
          <Grid item spacing={2} className={classes.professionalTypeOptions}>
            <RadioGroup
              value={professionalTypeOptions.find(option => option.key === (mainContact.institutionalRole || mainContact.userClientType))?.label}
              onChange={handleChangeProfessionalType}
            >
              {professionalTypeOptions.map((option, index) =>
                <FormRadioButton
                  style={(mainContact.institutionalRole || mainContact.userClientType) === option.key ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                  key={index}
                  value={option.label}
                  control={<Radio color='primary' />}
                  label={<Typography variant='caption'>{option?.label}</Typography>}
                />)
              }
            </RadioGroup>

          </Grid>
        </Box>
      }
      <Grid container className={classes.buttons} style={{ justifyContent: showProfessionalType ? 'space-between' : 'flex-end' }}>
        {showProfessionalType &&
          <Button
            variant="contained"
            className={classes.buttonBack}
            startIcon={<icons.ArrowBack className={classes.icon} />}
            onClick={() => {
              updateMainContact(isInstitutional ? 'institutionalRole' : 'userClientType', null)
              setShowProfessionalType(false)
            }}
          >
            Back
          </Button>
        }
        <Button
          variant="contained"
          className={classes.button}
          disabled={!validate()}
          endIcon={!loading ? <icons.ArrowForward className={classes.icon} /> : <></>}
          onClick={handleNext}
        >
          {loading
            ? (
              <CircularProgress
                className={classes.spinner}
                color='secondary'
                size={30}
              />
            )
            : (
              'Next'
            )}
        </Button>
      </Grid>
    </>
  )
}

export default Work
