import { icons } from 'assets';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Grid, ProfilePicture, Typography } from '../../../../../UI';
import { servicePendingStatusIcon, completedServiceIcon, needContract } from 'assets';
import useStyles from './styles';
import BottomSheet from 'components/UI/CustomUI/molecules/BottomSheet';
import { SingleDatePicker } from 'components/UI/CustomUI/atoms/DatePicker/SingleDatePicker';
import TimeSelector from 'components/UI/CustomUI/organisms/TimeSelector';
import { formatDateToUnixTimestamp, formatTimestamp } from 'helpers/index';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionsPlanService } from 'ducks/types';
import { orderActions, subscriptionsActions } from 'ducks/actions';
import { toast } from 'react-toastify';
import { getUser } from 'ducks/selectors';
import { blueSmallLogo } from 'assets'
import { getOrder } from 'ducks/order/selectors';

export type StatusType = "UNINITIALIZED" | "REQUESTED" | "SCHEDULED" | "IN_PROGRESS" | "COMPLETE";

type GiftServiceItemProps = {
    subscriptionId: string;
    action: () => void;
    service: SubscriptionsPlanService;
    contractSigned?: boolean;
    giftedBy?: {
        name: string;
        company: string;
        picture: string;
    };
}

function TagStatus({ status }: { status: StatusType }) {
    const classes = useStyles();
    const [statusInfo, setStatusInfo] = useState({
        title: "",
        icon: "",
        colors: {
            background: "",
            text: ""
        }
    })
    const renderInfo = () => {
        switch (status) {
            case "UNINITIALIZED":
                return setStatusInfo({
                    title: "SCHEDULE PENDING",
                    icon: servicePendingStatusIcon,
                    colors: {
                        background: "#F9FAFA",
                        text: "#494A5A"
                    }
                })
            case "REQUESTED":
            case "SCHEDULED":
            case "IN_PROGRESS":
                return setStatusInfo({
                    title: "UPCOMING",
                    icon: "",
                    colors: {
                        background: "#FAFDFF",
                        text: "#0071A3"
                    }
                })
            case "COMPLETE":
            default:
                return setStatusInfo({
                    title: "COMPLETED",
                    icon: "",
                    colors: {
                        background: "#EDFDF6",
                        text: "#0E905A"
                    }
                })
        }
    }

    useEffect(() => {
        renderInfo()
    }, [status])

    return (<Box className={classes.Tag} style={{ backgroundColor: statusInfo.colors.background }}>
        <Typography className={classes.TagText} style={{ color: statusInfo.colors.text }}>{statusInfo.title}</Typography>
        {statusInfo.icon !== "" ? <img src={statusInfo.icon} alt="" /> : null}
    </Box>)
}

function GiftServiceItem({ action, giftedBy, service, subscriptionId, contractSigned }: GiftServiceItemProps) {
    const { title, requestedDate, scheduledDate, status } = service || {};
    // const technician = "Test"

    const [openRequestScheduling, setOpenRequestScheduling] = useState(false)
    const [selectingTime, setSelectingTime] = useState(false)
    const [scheduledDateSelected, setScheduledDateSelected] = useState<any>("")
    const [technician, setTechnician] = useState<any>("-")
    const [scheduledTime, setScheduledTime] = useState<any>("")
    const [requestLoading, setRequestLoading] = useState(false)
    const [showSignContractFirstModal, setShowSignContractFirstModal] = useState(false)

    const order = useSelector(getOrder)

    const user = useSelector(getUser)


    const classes = useStyles()
    const dispatch = useDispatch();

    function handleOpenRequestScheduling() {
        if (!contractSigned) {
            return setShowSignContractFirstModal(true)
        }
        setOpenRequestScheduling(true)
    }

    function handleCancel() {
        setOpenRequestScheduling(false)
    }

    function handleGoToTime() {
        if (!scheduledDateSelected) return;
        // handleToggleTime()
    }

    function handleToggleTime() {
        setSelectingTime(prevSelectingTime => !prevSelectingTime)
    }

    const Msg = () => (
        <Grid container
            direction="row"
            wrap='nowrap'
        >
            <icons.CheckCircle className={classes.ToastIcon} />
            <Box className={classes.ToastTexts}>
                <Typography className={classes.ToastTitle}>We've received your schedule request!</Typography>
                <Typography className={classes.ToastSubtitle}>We will confirm within one business day.</Typography>
            </Box>
        </Grid>
    )




    function handleRequest() {
        setRequestLoading(true)
        // const formatedHour = scheduledTime.slice(-2) === "PM" ? `${Number(scheduledTime.slice(0, 2)) + 12}` : scheduledTime.slice(0, 2);
        // const formatedTime = scheduledTime.slice(3, 5);
        const dateWithTime = new Date(scheduledDateSelected);
        // dateWithTime.setHours(formatedHour, formatedTime);
        const timestamp = formatDateToUnixTimestamp(dateWithTime);
        let serviceToSend = { ...service, requestedDate: timestamp.toString() };
        dispatch(subscriptionsActions.requestScheduleServicePlan({ subscriptionId, request: serviceToSend }, (succ) => {
            if (succ) {
                setOpenRequestScheduling(false)
                toast(Msg, { className: classes.Toast })
                dispatch(subscriptionsActions.fetchSubscriptions({}))
            }
            setRequestLoading(false)
        }))
    }

    function handleSelectTime(time: string) {
        setScheduledTime(time)
    }

    function handleSignContract() {
        setShowSignContractFirstModal(false)
        action()
    }

    const completed = useMemo(() => status === "COMPLETE", [status])
    const date = useMemo(() => scheduledDate || requestedDate || "-", [scheduledDate, requestedDate])
    const inProgress = useMemo(() => status !== "COMPLETE" && status !== "UNINITIALIZED", [status])
    const today = useMemo(() => new Date(), [])

    useEffect(() => {
        if (service?.orderId) {
            dispatch(orderActions.fetchOrder(service.orderId))
        }
    }, [service])


    useEffect(() => {
        if (order?.companyContact) {
            setTechnician(order?.companyContact)
        }
        return () => {
            dispatch(orderActions.setOrder(null))
        }
    }, [order])


    return (
        <Box className={classes.ServiceContainer}>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap='nowrap'
                className={classes.Header}
            >

                <Typography className={classes.ServiceLabel}>{completed ? <img className={classes.CompletedIcon} alt="" src={completedServiceIcon} /> : null}{title}</Typography>
                <TagStatus status={status} />
            </Grid>
            {!completed ? <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap='nowrap'
                className={classes.Body}
                style={!giftedBy ? { padding: "0px 16px 16px 16px" } : {}}
            >
                <Box className={classes.ServiceWorker}
                >
                    <Typography className={classes.ServiceLabel}>Technician: <Typography className={classes.ServiceValue}>{technician}</Typography></Typography>
                    <Typography className={classes.ServiceLabel}>Date: <Typography className={classes.ServiceValue}>{date === "-" ? date : formatTimestamp(+date, "MM/DD/YYYY")}</Typography></Typography>
                </Box>
                {!inProgress && <Box onClick={handleOpenRequestScheduling} className={classes.Support}>
                    <icons.CalendarToday className={classes.Icon} />
                    <Typography className={classes.text}>Request Scheduling</Typography>
                </Box>}
            </Grid> : null}
            {giftedBy && <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap='nowrap'
                className={classes.GiftByContainer}
            >
                <ProfilePicture
                    avatar={giftedBy?.picture}
                    width='44px'
                    height='44px'
                    defaultEmptyIcon={blueSmallLogo}
                />
                <Box className={classes.GiftByInfo}>
                    <Typography className={classes.GiftByLabel}>
                        <icons.CardGiftcard className={classes.icon} />
                        Gifted by:
                    </Typography>
                    <Box className={classes.GiftByValue}>
                        <Typography className={classes.GiftByName}>
                            {giftedBy.name || "-"}
                        </Typography>
                        <Typography className={classes.GiftByCompany}>
                            {` from ${giftedBy.company || "-"}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            }
            <BottomSheet
                title={title}
                open={openRequestScheduling}
                onClose={handleCancel}>
                {selectingTime ? <TimeSelector onChange={handleSelectTime} /> : <SingleDatePicker
                    minDate={today}
                    value={scheduledDateSelected} onChange={setScheduledDateSelected}
                />}
                <Box className={classes.Buttons}>
                    {selectingTime ? <>
                        <Box onClick={handleToggleTime} className={classes.ButtonSec}>
                            <icons.ArrowDownward className={classes.ArrowBack} />
                            <Typography>Back</Typography>
                        </Box>
                        <Button
                            endIcon={
                                <icons.CalendarToday className={classes.CalendarIcon} />
                            }
                            onClick={handleRequest}
                            className={classes.ButtonPrim}
                        >
                            <Typography>Request Date</Typography>
                        </Button>
                    </> : <>
                        <Box onClick={handleCancel} className={classes.ButtonSec}>
                            <Typography>Cancel</Typography>
                        </Box>
                        <Box onClick={requestLoading || !scheduledDateSelected ? () => { } : handleRequest} style={!scheduledDateSelected ? { opacity: 0.7 } : {}} className={classes.ButtonPrim}>
                            {
                                requestLoading ? <CircularProgress size={20} color="inherit" /> : <>
                                    <Typography>Request Date</Typography>
                                    <icons.CalendarToday className={classes.CalendarIcon} />
                                </>
                            }
                        </Box>
                        {/* <Box onClick={handleGoToTime} className={classes.ButtonPrim}>
                            <Typography>Select Time</Typography>
                            <icons.ArrowDownward className={classes.Arrow} />
                        </Box> */}
                    </>
                    }
                </Box>
            </BottomSheet>
            <BottomSheet
                title=""
                open={showSignContractFirstModal}
                onClose={() => setShowSignContractFirstModal(false)}
            >
                <Box className={classes.NeedContractWrapper}>
                    <img className={classes.NeedContractImage} src={needContract} alt="Need contract" />
                    <Typography className={classes.NeedContractTitle1}>
                        Whoops!
                        <Typography className={classes.NeedContractTitle2}>
                            One final item needed!
                        </Typography>
                    </Typography>
                    <Typography className={classes.NeedContractSubtitle}>
                        We need you to sign the <b>VIP Maintenance Program Contract</b> to Request Scheduling.
                        Let’s Sign the contract, {user.firstName}!
                    </Typography>
                    <Box onClick={handleSignContract} className={classes.ButtonPrim} style={{ width: "auto" }}>
                        <Typography>
                            Sign Contract Now
                        </Typography>
                        <icons.ArrowDownward className={classes.Arrow} />
                    </Box>
                </Box>
            </BottomSheet>
        </Box >
    )
}

export default GiftServiceItem