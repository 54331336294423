/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext } from 'react'
import { logo, icons } from '../../../../../assets'
import useStyles from './styles'
import { Box, Typography, Grid, Button, Divider } from '../../../../UI'
import Cards from './Cards'
import { isEmpty, round } from '../../../../../helpers'
import { DispatchPaymentCreditCardContext } from './context'
import { dispatchPaymentCreditCardTypes } from './context/types'

const PaymentCreditCard: FC<{
  goBack: () => void
  days: number
  isExpedit?: boolean
}> = ({ goBack, days, isExpedit = false }) => {
  const classes = useStyles()

  const {
    dispatch: dispatchContext,
    state: { address, cardHolder, amount },
  } = useContext(DispatchPaymentCreditCardContext)

  const setValue = (attr: string, value: any) => {
    dispatchContext({
      type: dispatchPaymentCreditCardTypes.SET_VALUE,
      payload: { attr, value },
    })
  }

  const validate = !isEmpty(address) && !isEmpty(cardHolder)
  const total = round(amount, 2)

  return (
    <Box aria-label='payment-credit-card'>
      <Box className={classes.logoBox}>
        <img src={logo} className={classes.logo} alt="BOSSCAT Logo" />
      </Box>
      <Box className={classes.root}>
        <Typography className={classes.header}>
          {isExpedit
            ? 'Let’s expedite your estimate!'
            : 'Last step to get your estimate!'}
        </Typography>
        <Grid xs={12} container item spacing={2} className={classes.inputs}>
          <Grid item xs={12} sm={6} md={8}>
            <Cards />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.summary}>
              <Box>
                <Typography
                  variant="caption"
                  style={{ color: 'var(--text-color)' }}
                >
                  Order Summary
                </Typography>
                <Typography>Estimate</Typography>
              </Box>
              <Divider />
              <Typography>
                {`${days} Business Day ${days > 1 ? 's' : ''}`}
              </Typography>
              <Divider />
              <Typography style={{ color: 'var(--text-color)' }}>
                {`Price: $${total}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box px={2} className={classes.row}>
          <Button
            variant="contained"
            size="large"
            className={classes.back}
            startIcon={<icons.ArrowBack className={classes.icon} />}
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            onClick={() => setValue('payIntent', true)}
            endIcon={<icons.ArrowForward className={classes.icon} />}
            disabled={!validate}
          >
            Pay
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentCreditCard
