import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '238px',
      padding: '16px',
      margin: '8px 0px',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: 'var(--border-color)',
      borderRadius: 12,
      minWidth: '100px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px',
        height: '88%',
        backgroundColor: 'var(--background-color-secondary)',
        borderColor: 'var(--background-color-secondary)'
      }
    },
    title: {
      color: 'var(--head-text-color)',
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
      marginBottom: '10px'
    },
    item: {
      color: 'var(--head-text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px'
    },
    subitem: {
      color: 'var(--head-text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px'
    },
    subtitle: {
      color: 'var(--head-text-color)',
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    },
    line: {
      flexDirection: 'row',
      margin: '6px 0px',
      height: '2px',
      backgroundColor: 'var(--head-text-color)',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'var(--border-color)'
      }
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      height: '80%',
      [theme.breakpoints.down('sm')]: {
        height: '70%'
      }
    }
  })
)
