import { useState } from 'react'
import env from '@beam-australia/react-env'
import { Box, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { filesActions, newEstimateActions } from '../../../../../../../../../ducks/actions'
import { getNewEstimateValues } from '../../../../../../../../../ducks/selectors'
import { applicationMimeTypes, FILE_TYPE, imageMimeTypes, isEmpty, ParseFilesUrl, videoMimeTypes } from '../../../../../../../../../helpers'
import { TextFieldLabel, UploadDocuments } from '../../../../../../../../UI'
// import UploadDocuments from "../../../../../../../../UI/CustomUI/molecules/UploadDocument";
import useStyles from './styles'
import { PunchlistFile } from '../../../../../../../../../ducks/types'
import { useIsXsScreen } from '../../../../../../../../../hooks'
const FILES_URL = env('FILES_URL') ?? ''

export default function UploadProjectList() {
    const classes = useStyles()
    const { clientNotes } = useSelector(getNewEstimateValues())
    const dispatch = useDispatch()
    const [xsScreen] = useIsXsScreen()

    const [uploadingFile, setUploadingFile] = useState(false)
    const handleChangeComments = (event: any) => {
        dispatch(newEstimateActions.setNewEstimateValue({
            attr: 'clientNotes',
            value: event.target.value
        }))
    }
    const { repairListFiles } = useSelector(getNewEstimateValues())

    const handleInspectionChange = (
        files: null | File[],
        callback: () => void
    ) => {
        setUploadingFile(true)
        const oldFiles = repairListFiles ? [...repairListFiles] : []
        if (files) {
            dispatch(
                filesActions.uploadFiles(files, (uploadSucc, fileNameList) => {
                    if (uploadSucc && fileNameList) {
                        const newFiles = fileNameList.map((fileName, index) => {
                            if (!isEmpty(fileName)) {
                                return {
                                    name: fileName,
                                    fileUrl: ParseFilesUrl({
                                        baseUrl: FILES_URL,
                                        avatar: fileName ?? ''
                                    }),
                                    description: `Estimate Repair List File - ${index + 1}`,
                                    fileType: FILE_TYPE.REPAIR_LIST
                                }
                            }
                        })
                        dispatch(newEstimateActions.setNewEstimateValue({
                            attr: 'repairListFiles',
                            value: [...oldFiles, ...newFiles]
                        }))
                    }
                    if (callback) callback()
                })
            )
        } else {
            if (callback) callback()

            dispatch(newEstimateActions.setNewEstimateValue({
                attr: 'repairListFiles',
                value: [...oldFiles]
            }))
        }
        setUploadingFile(false)
    }

    const handleDeleteRepairFile = (newFiles: PunchlistFile[] | null) => {
        dispatch(newEstimateActions.setNewEstimateValue({
            attr: 'repairListFiles',
            value: newFiles
        }))
    }

    return (
        <Box width='100%'>
            {!xsScreen &&
                <Box width='100%' className={classes.item}>
                    <Typography style={{ fontFamily: "LatoBlack", fontSize: "18px", color: "#0b060f", marginBottom: "16px" }} variant='body2'>Please provide as much detail as you can and include measurements and photos/videos if applicable.</Typography>
                </Box>}

            <Box width='100%' className={classes.itemFirst}>
                <Typography style={{ fontFamily: "LatoBlack", fontSize: "18px", color: "#0b060f", marginBottom: "16px" }}>Upload your list and photos/videos:</Typography>
                <UploadDocuments
                    files={repairListFiles}
                    onChange={!uploadingFile ? handleInspectionChange : () => { }}
                    dropzoneText='Upload list and photos/videos'
                    handleDelete={handleDeleteRepairFile}
                    allowedFiles={[...imageMimeTypes, ...applicationMimeTypes, ...videoMimeTypes]}
                />
            </Box>

            <Box className={classes.item}>
                <TextFieldLabel
                    multiline
                    fullWidth
                    minRows={4}
                    labelClasses={classes.label}
                    label='Other requests and/or comments (optional):'
                    placeholder='Let us know any other comment you have.'
                    value={clientNotes}
                    onChange={handleChangeComments}
                />
            </Box>

        </Box>
    )
}
