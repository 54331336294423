import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'

import { icons } from 'assets'
import { ChangeEvent } from 'react'
import useStyles from './styles'

export interface BooleanCheckboxProps {
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  value?: any
  fullWidth?: boolean
  error?: boolean
}

const BooleanCheckbox = ({
  onChange,
  value,
  fullWidth = true,
  error,
}: BooleanCheckboxProps) => {
  const classes = useStyles()

  return (
    <Grid item container className={classes.booleanCheckbox}>
      <RadioGroup
        onChange={onChange}
        value={value}
        className={classes.radioGroup}
      >
        <Grid item container spacing={2}>
          <Grid item /* style={{ flex: 1 }} */ xs={12} lg={fullWidth ? 6 : 3}>
            <FormControlLabel
              style={{
                borderColor: value
                  ? 'var(--bosscat-blue-600)'
                  : error
                  ? 'red'
                  : '#EBECEF',
              }}
              className={classes.formLabel}
              control={
                <Radio
                  checkedIcon={
                    <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                  }
                  icon={<icons.RadioButtonUnchecked />}
                />
              }
              checked={value}
              value={true}
              label={<Typography className={classes.label}>Yes</Typography>}
            />
          </Grid>
          <Grid item /* style={{ flex: 1 }} */ xs={12} lg={fullWidth ? 6 : 3}>
            <FormControlLabel
              style={{
                borderColor:
                  value === false
                    ? 'var(--bosscat-blue-600)'
                    : error
                    ? 'red'
                    : '#EBECEF',
              }}
              className={classes.formLabel}
              control={
                <Radio
                  checkedIcon={
                    <icons.RadioButtonChecked htmlColor="var(--bosscat-blue-600)" />
                  }
                  icon={<icons.RadioButtonUnchecked />}
                />
              }
              checked={value === false}
              value={false}
              label={<Typography className={classes.label}>No</Typography>}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  )
}

export default BooleanCheckbox
