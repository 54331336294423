import { InviteNewClientRequest } from 'api/punchlist/users/types'
import { EMAIL_TYPE, USER_TYPE } from 'helpers'
import { Address } from 'ducks/types'

/** TYPES **/
export const adminTypes = {
  FETCH_ADMIN_CLIENTS: 'FETCH_ADMIN_CLIENTS',
  SET_ADMIN_CLIENTS: 'SET_ADMIN_CLIENTS',
  INVITE_CLIENT: 'INVITE_CLIENT',
  UPDATE_USER: 'UPDATE_USER_ADMIN',
}

/** ACTIONS **/
export interface FetchAdminClientsAction {
  type: typeof adminTypes.FETCH_ADMIN_CLIENTS
  payload: null
  callback: (succ: boolean) => void
}

export interface InviteNewClientAction {
  type: typeof adminTypes.INVITE_CLIENT
  payload: InviteNewClientRequest
  callback: (succ: boolean) => void
}

export interface AdminSetClients {
  type: typeof adminTypes.SET_ADMIN_CLIENTS
  payload: AdminType[]
}

export interface UpdateClientAction {
  type: typeof adminTypes.UPDATE_USER
  payload: Partial<AdminType>
  callback: (succ: boolean) => void
}

export type AdminActions =
  | FetchAdminClientsAction
  | AdminSetClients
  | InviteNewClientAction
  | UpdateClientAction

/** REDUCER **/
export interface Email {
  emailType: EMAIL_TYPE
  email: string
}

export interface Region {
  type: string
  values: string[] | undefined
}

export interface AdminType {
  id: string
  firstName: string
  middleName: string
  lastName: string
  email: Email[]
  phone: number | null
  picture: string
  address: Address
  type: string
  auth0Id: string
  insertedOn: number | null
  registrationComplete: boolean
  registrationCompleteOn: number | null
  clientType: USER_TYPE
  affiliate: string
  customerId: string
  isAdmin: boolean | null
  region: Region
  disabled: boolean
  admin: boolean
}

export interface AdminClientsType extends Array<AdminType> { }
