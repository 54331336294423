import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    date: {
      justifyContent: 'space-between',
      marginBottom: '10px'
    },
    year: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      }
    },
  })
)
