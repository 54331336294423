import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
      borderRadius: '8px',
      width: '100%',
      padding: '12px',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    text: {
      color: '#0B060F',
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  })
)
