import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Box, Button, ListItemText } from '../../../../../UI'
import { ItemTextProps, JobItemProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import Inspections from '../Inspections'
import {
  FEEDBACK_TYPE,
  FILE_TYPE,
  history,
  ITEM_STATUS,
  JOB_ITEM_PUBLIC_STATUS,
  round,
} from '../../../../../../helpers'
import { hammer } from '../../../../../../assets'
import { ImageFile } from '../types'
import FeedbackModal from '../FeedbackModal'
import { PunchlistFile } from '../../../../../../ducks/types'
import {
  feedbackActions,
  jobActions,
  jobItemsActions,
} from '../../../../../../ducks/actions'
import { getUserId } from '../../../../../../ducks/selectors'

const ItemText: FC<ItemTextProps> = ({
  title,
  text,
  showBetterStyle = false,
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.subTitleText} style={{ color: title !== "Requested Repair:" ? 'var(--input-text)' : 'var(--hardware-haze-600)' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

const JobItem: FC<JobItemProps> = ({ children, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const dispatch = useDispatch()

  const { id: jobId } = history.getParamValues()
  const userId = useSelector(getUserId)
  const [open, setOpen] = useState(false)

  const {
    inspectionReportReference,
    title,
    imageFiles,
    publicJobStatus,
    inspectionReportNote,
    requestedRepairNote,
    fieldNote,
    itemId,
    quantity,
    disclaimer,
    publicRepairNote,
    totalPrice,
    status,
  } = children

  const inspectionImages = imageFiles.filter(
    (image: PunchlistFile) => image.fileType === FILE_TYPE.INSPECTION_IMAGE
  )
  const proImages = imageFiles.filter(
    (image: PunchlistFile) => image.fileType === FILE_TYPE.PRO_IMAGE
  )

  const handleApprove = () => {
    dispatch(
      jobItemsActions.updateJobItem(
        { jobId, itemId, partialItem: { status: ITEM_STATUS.APPROVED } },
        () => {
          dispatch(jobItemsActions.fetchJobItems(jobId))
          dispatch(jobActions.fetchJob(jobId))
        }
      )
    )
  }

  const handleDecline = (option: string, comments: string) => {
    const feedback = {
      userId: userId,
      resourceId: jobId,
      resourceType: FEEDBACK_TYPE.JOB,
      ratings: [
        {
          description: option,
          score: 0,
        },
      ],
      comments: comments,
    }
    dispatch(
      feedbackActions.sendFeedback(feedback, () => {
        dispatch(
          jobItemsActions.updateJobItem(
            { jobId, itemId, partialItem: { status: ITEM_STATUS.REJECTED } },
            () => {
              dispatch(jobItemsActions.fetchJobItems(jobId))
            }
          )
        )
      })
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          container
          alignItems="center"
          className={classes.titleContainer}
        >
          <Typography variant="h6" className={classes.titleText}>
            {`${inspectionReportReference || ''} - ${title}`}
          </Typography>
          <Typography className={classes.price}>
            {' '}
            {`QTY: ${quantity}`}{' '}
          </Typography>
        </Grid>
        {status === ITEM_STATUS.NEW && (
          <Grid item>
            <ListItemText
              // className={classes.itemEnd}
              primary={'$'.concat(round(totalPrice, 2))}
            />
          </Grid>
        )}
      </Grid>
      {inspectionImages && inspectionImages.length > 0 && (
        <Grid item xs={12}>
          <Inspections>
            {inspectionImages as unknown as ImageFile[]}
          </Inspections>
        </Grid>
      )}
      <ItemText
        title="Inspection / Repair Notes:"
        text={inspectionReportNote}
        showBetterStyle={showBetterStyle}
      />
      <ItemText
        title="Requested Repair:"
        text={requestedRepairNote}
        showBetterStyle={showBetterStyle}
      />
      {publicRepairNote && (
        <ItemText
          title="Notes:"
          text={publicRepairNote}
          showBetterStyle={showBetterStyle}
        />
      )}

      {disclaimer && (
        <ItemText
          title="Disclaimer:"
          text={disclaimer}
          showBetterStyle={showBetterStyle}
        />
      )}
      {((proImages && proImages.length > 0) || fieldNote) && (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          className={classes.afterRepair}
        >
          <Grid item xs={12}>
            <Box className={classes.afterRepairTitle}>
              <img src={hammer} className={classes.hammer} alt="" />
              <Typography
                variant="subtitle2"
                className={classes.afterRepairFontFamily}
              >
                After Repairs:
              </Typography>
            </Box>
          </Grid>
          {proImages && proImages.length > 0 && (
            <Grid item xs={12}>
              <Inspections>{proImages as unknown as ImageFile[]}</Inspections>
            </Grid>
          )}
          {fieldNote && (
            <Grid item xs={12}>
              <ItemText
                title="Notes:"
                text={fieldNote}
                showBetterStyle={showBetterStyle}
              />
            </Grid>
          )}
        </Grid>
      )}
      {publicJobStatus === JOB_ITEM_PUBLIC_STATUS.NEEDS_ATTENTION && (
        <Grid item xs={12}>
          <FeedbackModal
            open={open}
            setOpen={setOpen}
            onSubmit={handleDecline}
            showBetterStyle={showBetterStyle}
          />
          <Box className={classes.options}>
            <Button
              type="submit"
              variant="outlined"
              size="small"
              className={classes.buttonDecline}
              onClick={() => {
                setOpen(true)
              }}
            >
              Decline
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              className={classes.buttonApprove}
              onClick={handleApprove}
            >
              Approve Item
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default JobItem
