import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import useStyles from '../../../../../styles'
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  ArrowForward,
} from '@material-ui/icons'
import { jobCompleted, newJob } from 'assets/index'
import { PropertyActivityActiveData } from 'ducks/types'
import moment from 'moment'

const JobCompleteStep = ({
  activeData,
  show,
  setShow,
}: {
  activeData?: PropertyActivityActiveData
  show?: boolean
  setShow?: (value: boolean) => {}
}) => {
  const styles = useStyles()

  return (
    <Grid item container spacing={2} direction="column">
      <Grid
        item
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <img
            src={jobCompleted}
            style={{ width: '52px', height: '52px' }}
            alt="estimate"
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography
            className={styles.nextArtTitle}
            style={{ fontWeight: 700 }}
          >
            JOB COMPLETED
          </Typography>
          <Typography className={styles.title}>
            {`Completed on: ${activeData?.jobCompletedOn ? moment(activeData?.jobCompletedOn * 1000).format("MMMM DD, YYYY") : ''}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default JobCompleteStep
