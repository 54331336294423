/** TYPES **/
export const docuSignTypes = {
  GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',
  GET_USER_INFO: 'GET_USER_INFO'
}

export interface GetAccessTokenParams {
  docusignUrl: string;
  code?: string;
  token: string;
}
export interface GetUserInfoParams {
  token: string;
  code?: string;
  docusignUrl: string;
}

export interface UserInfoResponse {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  created: string;
  email: string;
  accounts: Account[];
}

export interface Account {
  account_id: string;
  is_default: boolean;
  account_name: string;
  base_uri: string;
}

/** ACTIONS **/
export interface GetAccessTokenAction {
  type: typeof docuSignTypes.GET_ACCESS_TOKEN
  payload: GetAccessTokenParams
  callback?: (succ: boolean, accessToken?: string) => void
}

export interface GetUserInfoAction {
  type: typeof docuSignTypes.GET_USER_INFO
  payload: GetUserInfoParams
  callback?: (succ: boolean, userInfo?: UserInfoResponse) => void
}

export type DocuSignActions =
  | GetAccessTokenAction
  | GetUserInfoAction
