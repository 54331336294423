import env from "@beam-australia/react-env";
import { isEmpty } from "lodash";
import ReactGA from "react-ga4";

const TRACKING_ID = env('ANALYTICS_ID') ?? ''

if (isEmpty(TRACKING_ID)) {
    console.error('No Google Analytics Tracking ID found')
}

type TrackGoogleAnalyticsProps = {
    category: string,
    action: string,
    label?: string,
    options?: any
}

const InitializeGoogleAnalytics = () => {
    !isEmpty(TRACKING_ID) && ReactGA.initialize(TRACKING_ID);
};

const TrackGoogleAnalyticsEvent = ({
    category,
    action,
    label,
    options
}: TrackGoogleAnalyticsProps) => {
    !isEmpty(TRACKING_ID) && ReactGA.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        ...options
    });
};

function generateSessionId() {
    return 'session_' + Math.random().toString(36).slice(2, 9)
  }

  function getSessionId() {
    let sessionId = getCookie('session_id')
    if (!sessionId) {
      sessionId = generateSessionId()
      document.cookie = `session_id=${sessionId}; expires=; path=/estimate`
    }

    return sessionId
  }

  function getCookie(cookieName: string) {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookieArray = decodedCookie.split(';')
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return ''
  }

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, getSessionId };