import { authTypes } from 'ducks/auth/types'
import {
  orderMaintenanceTypes,
  OrderMaintenanceType,
  OrderMaintenanceActions,
  OrderMaintenanceValue,
  OrderMaintenanceValues,
} from './types'

const initialState: OrderMaintenanceType = {
  step: 0, // first step
  mobileStep: 0,
  homeownerReceives: true,
  id: '',
  protectPlan: null,
  title: "",
  services: [],
  homeowner: null,
  purchaser: null,
  propertyAddress: null,
  files: [],
  status: null,
  price: null,
  insertedOn: null,
  expiresOn: null,
  contractSigned: null,
  giftBox: null,
  renewals: null,
  gift: false,
  promoCode: null,
  promo: null
}

const reducer = (
  state = initialState,
  action: OrderMaintenanceActions
): OrderMaintenanceType => {
  const { type, payload } = action
  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case orderMaintenanceTypes.GO_BACK: {
      return {
        ...state,
        step: state?.step as number - 1,
        mobileStep: state?.mobileStep as number - 1,
      }
    }
    case orderMaintenanceTypes.GO_FORWARD: {
      return {
        ...state,
        step: state?.step as number + 1,
        mobileStep: state?.mobileStep as number + 1,
      }
    }
    case orderMaintenanceTypes.SET_VALUE: {
      const { attr, value } = payload as OrderMaintenanceValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case orderMaintenanceTypes.SET_VALUES: {
      const { attrs } = payload as OrderMaintenanceValues
      return {
        ...state,
        ...attrs,
      }
    }

    case orderMaintenanceTypes.SET_ORDER_MAINTENANCE: {
      return payload as OrderMaintenanceType
    }

    case orderMaintenanceTypes.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
