import { FC, useState } from 'react'
import { Typography, Box, STextField, Button } from '../../..'
import useStyles from './styles'
import { Props } from './types'

const Promotion: FC<Props> = () => {
  const classes = useStyles()
  const [repair, setRepair] = useState('')

  return (
    <Box className={classes.root}>
      <Box className={classes.color}>
        <Box className={classes.row}>
          <Typography variant='body2' className={classes.textAddress}>
            Have a
          </Typography>
          <Typography variant='body2' className={classes.textLink}>
            promo code
          </Typography>
          <Typography variant='body2' className={classes.textAddress}>
            ? Enter it here:
          </Typography>
        </Box>
        <Box className={classes.content}>
          <STextField
            variant='standard'
            label=''
            className={classes.comboRepair}
            value={repair}
            onChange={(event) => setRepair(event.target.value)}
            placeholder='PUNCH-RJ2021'
          />
          <Button
            type='submit'
            variant='contained'
            size='small'
            className={classes.add}
          >
            <Typography> Apply Code </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Promotion
