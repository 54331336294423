import { icons } from 'assets';
import { Box, DocumentPreview, Grid, Typography } from '../../../../../UI';
import useStyles from './styles';
import { isMobile } from 'react-device-detect';
import { PunchlistFile } from 'ducks/types';
import { ParseFilesUrl } from 'helpers/filesUrlParser';
import { FILES_URL } from 'components/pages/User/Register/_test_/constants';

interface DocumentType {
    title: string;
    subtitle: string;
    uploaded: boolean;
    icon: string;
    file?: PunchlistFile;
    button: {
        action: () => void;
        title: string;
    }
}
type DocumentLibraryProps = {
    documents: DocumentType[]
}

function DocumentLibrary({ documents }: DocumentLibraryProps) {
    const classes = useStyles()

    const handleDownloadDoc = () => {
    }

    return (
        <Box className={classes.Container}>
            <Typography className={classes.Title}>Document Library</Typography>
            {documents.map(doc =>
                <Box className={classes.DocContainer}>
                    <img className={classes.DocIcon} src={doc.icon} alt="" />
                    <Box className={classes.DocInfo}>
                        <Typography className={classes.DocTitle}>{doc.title}</Typography>
                        <Typography className={classes.DocSubtitle}>{doc.subtitle}</Typography>
                    </Box>
                    <Box className={classes.Buttons}>
                        {doc.uploaded && doc.file ? <>
                            <a href={doc.file.fileUrl} target="_blank" rel="noreferrer" className={classes.ButtonSec} >
                                {isMobile && <Typography>View</Typography>}
                                <icons.Visibility />
                            </a>
                            {/* <a href={ParseFilesUrl({
                                baseUrl: FILES_URL,
                                avatar: doc.file.fileName ?? ''
                            })} download={doc.file.fileName} className={classes.ButtonSec} >
                                {isMobile && <Typography>Download</Typography>}
                                <icons.ArrowDownward />
                            </a> */}
                        </>
                            :
                            <Box onClick={doc.button.action} className={classes.ButtonPrim}>
                                {doc.button.title}
                            </Box>
                        }
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default DocumentLibrary