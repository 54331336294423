import { Job } from 'ducks/types'

/** TYPES **/
export const jobsTypes = {
  FETCH_JOBS: 'FETCH_JOBS',
  FETCH_JOBS_STATS: 'FETCH_JOBS_STATS',
  SET_JOBS: 'SET_JOBS'
}

/** ACTIONS **/
export interface FetchJobsAction {
  type: typeof jobsTypes.FETCH_JOBS
  payload: {
    startDate?: number,
    endDate?: number,
    status?: string[],
    start?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
    search?: string 
  }
  callback?: (succ: boolean) => void
}

export interface FetchJobsStatsAction {
  type: typeof jobsTypes.FETCH_JOBS_STATS
  payload: { startDate?: number, endDate?: number, status?: string }
  callback?: (succ: boolean) => void
}

export interface SetJobsAction {
  type: typeof jobsTypes.SET_JOBS
  payload: Job[]
}

export type JobsActions =
  | FetchJobsAction
  | FetchJobsStatsAction
  | SetJobsAction

export interface JobsIndicators {
  total: number
  upcoming: number
  inProgress: number
  attention: number
  completed: number
}

/** REDUCER **/
export interface JobsType extends Array<Job> { }
