import { FC, useCallback, useRef, useState } from 'react'
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Box,
  MultilineText,
  ImagesSelector,
} from '../../../../../UI'
import useStyles from './styles'
import { icons, requestMoreItems } from '../../../../../../assets'
import { RequestMoreItemsModalProps } from './types'
import { CircularProgress, TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { estimateActions, filesActions } from 'ducks/actions'
import { isMobile } from 'react-device-detect'
import { imageMimeTypes } from 'helpers/files'
import { ParseFilesUrl } from 'helpers/filesUrlParser'
import { FILES_URL } from 'components/pages/User/Register/_test_/constants'
import { FILE_TYPE } from 'helpers/constants'
import { UpdateRequest } from 'api/types'

const RequestMoreItemsModal: FC<RequestMoreItemsModalProps> = ({
  open,
  setOpen,
}) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [imageFiles, setImageFiles] = useState<File[]>()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClose = () => {
    setImageFiles([])
    setOpen(false)
    setShowError(false)
  }

  const handleSubmit = () => {
    if (!inputRef?.current?.value) {
      setShowError(true)
      return
    }
    setLoading(true)
      dispatch(
        estimateActions.requestMoreItems(inputRef?.current?.value, (succ: boolean) => {
          if (!imageFiles?.length) {
            setLoading(false)
            succ && handleClose()
          } else {
            dispatch(
              filesActions.uploadFiles(
                imageFiles as File[],
                (uploadSucc, fileNameList) => {
                  if (
                    uploadSucc &&
                    fileNameList &&
                    fileNameList.length === imageFiles?.length
                  ) {
                    const images: UpdateRequest = imageFiles?.map(
                      (_image, index) => {
                        const fileName = fileNameList[index]
                        return {
                          op: 'add',
                          path: '/properties/files/-',
                          value: {
                            name: fileName,
                            fileUrl: ParseFilesUrl({
                              baseUrl: FILES_URL,
                              avatar: fileName ?? '',
                            }),
                            fileType: FILE_TYPE.MORE_ITEMS_REQUEST_IMAGE,
                            description: 'Request More Items',
                          },
                        }
                      }
                    )

                    dispatch(
                      estimateActions.updateEstimateByPath(images, (succ) => {
                        setLoading(false)
                        succ && handleClose()
                      })
                    )
                  } else {
                    setLoading(false)
                  }
                }
              )
            )
          }
        })
      )
  }

  return (
    <Dialog
      onClose={handleClose}
      className={styles.modal}
      open={open}
      classes={{ paper: styles.root }}
    >
      <DialogContent className={styles.header}>
        <Box className={styles.inspectionTitleContainer}>
          <img src={requestMoreItems} alt="request more items" />
          <Box>
            <Typography className={styles.title}>
              REQUEST ESTIMATES FOR ADDITIONAL ITEMS
            </Typography>
            <Typography className={styles.subtitle}>
              List additional items with details and descriptions.
            </Typography>
          </Box>
        </Box>
        <Button onClick={handleClose} className={styles.closeButton}>
          <icons.Close />
        </Button>
      </DialogContent>
      <DialogContent className={styles.body}>
        <Box>
          <Typography className={styles.inputLabel}>List your items</Typography>
          <MultilineText
            className={styles.itemsInput}
            placeholder={`I need: 
            - A bedroom painted – 13 x 15 x 8, I will provide paint but need primer.​
            - An exterior door replaced​`}
            disabled={loading}
            inputRef={inputRef}
            error={showError && isEmpty(inputRef.current?.value)}
          />
        </Box>
        <Box>
          <Typography className={styles.inputLabel}>Images</Typography>
        </Box>
        <ImagesSelector
          images={imageFiles || []}
          submitAction={(images: any) => {
            setImageFiles(images)
          }}
          multiple
          disabled={!!loading}
          allowedFiles={[...imageMimeTypes]}
          canUpload
        />
      </DialogContent>
      <DialogContent className={styles.submitContainer}>
        {!isMobile && (
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={styles.cancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={styles.submit}
          onClick={handleSubmit}
          // disabled={isEmpty(newItems)}
        >
          {loading ? <CircularProgress className={styles.loading} size={20} /> : 'Request More Items'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default RequestMoreItemsModal
