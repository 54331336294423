import { Insight, Property } from 'ducks/types'
import { propertiesTypes, PropertiesActions, UpdatePropertyPayload, UploadPropertyPayload, ConditionType } from './types'

const actions = {
  fetchProperties: (
    payload: { state?: string, city?: string, zipCode?: string, archived?: boolean },
    callback?: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.FETCH_PROPERTIES,
    payload,
    callback
  }),
  setProperties: (payload: Property[]): PropertiesActions => ({
    type: propertiesTypes.SET_PROPERTIES,
    payload
  }),
  createProperty: (
    payload: Partial<Property>,
    callback: (succ: boolean, isDuplicated?: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.CREATE_PROPERTY,
    payload,
    callback
  }),
  updateProperty: (
    payload: UpdatePropertyPayload,
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.UPDATE_PROPERTY,
    payload,
    callback
  }),
  deleteProperty: (
    payload: string,
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.DELETE_PROPERTY,
    payload,
    callback
  }),
  uploadProperties: (
    payload: UploadPropertyPayload,
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.UPLOAD_PROPERTIES,
    payload,
    callback
  }),
  updatePropertyProject: (
    payload: {
      propertyId: string
      projectName: string
      request: Partial<Insight>
    },
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.UPDATE_PROPERTY_PROJECT,
    payload,
    callback
  }),
  requestPropertyRenovation: (
    payload: {
      propertyId: string
      request: Partial<ConditionType>
    },
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.REQUEST_PROPERTY_RENOVATION,
    payload,
    callback
  }),
  requestPropertyRoom: (
    payload: {
      propertyId: string
      request: Partial<ConditionType>
    },
    callback: (succ: boolean) => void
  ): PropertiesActions => ({
    type: propertiesTypes.REQUEST_PROPERTY_ROOM,
    payload,
    callback
  }),
}

export default actions
