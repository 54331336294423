import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // position: 'relative',
      minHeight: '100vh',
      // minWidth: '280px',
      backgroundColor: 'var(--bosscat-grey-200)',
      borderRight: '1px solid var(--bosscat-black-200);',
      // overflow: 'hidden'
      // overflow: 'visible',
    },
    arrowContainer: {
      position: 'absolute',
      right: '-0.78rem',
      top: '10rem',
      zIndex: 999,
      height: '25px',
      width: '25px',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      border: '1px solid var(--bosscat-black-200)',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      borderRadius: '100%',
      transition: '300ms all',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-600)',
        border: '1px solid var(--bosscat-blue-600)',
        transition: '300ms all',

        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'white',
            transition: '300ms all',
          }
        }
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '.7rem',
          fill: 'var(--bosscat-black-400)',
          marginLeft: '0.1rem',
          transition: '300ms all'
        }
      }
    },
    rootClosed: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // padding: '1rem',
      '@global': {
        '.MuiButtonBase-root': {
          width: '50px'
        },
        '.MuiListItemText-root': {
          display: 'none'
        },
        '.MuiBox-root': {
          justifyContent: 'center'
        }
      }
    },
    rootOpen: {
      padding: '1rem',
      '@global': {
        '.MuiButton-label': {
          gap: '.5rem'
        },
        '.MuiBox-root:first-child': {
          padding: 0
        }
      }
    },
    sidenav: {
      width: '270px',
      transition: 'width 0.3s ease-in-out',
      position: 'relative',
      // overflow: 'scroll',
      // height: '100vh',
      // paddingTop: '28px',
      '@global': {
        '::-webkit-scrollbar': {
          display: "none",
          width: '0px'
        }
      }
    },
    sidenavClosed: {
      composes: 'sidenav',
      transition: 'width 0.3s ease-in-out',
      width: '90px',
      position: 'relative',

      // overflowY: 'scroll',
      '@global': {
        '&::-webkit-scrollbar': {
          background: 'yellow'
        }
      }
    },
    rootMobile: {
      width: '100%',
      maxWidth: '350px',
      minHeight: '100vh',
      position: 'absolute',
      zIndex: 99993,
      backgroundColor: 'white',
      overflow: 'scroll',
      maxHeight: '100vh'
    },
    slideMenuBack: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: 99992,
      backgroundColor: 'rgba(0,0,0,0.2)'
    },
    logo: {
      cursor: 'pointer',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      padding: '1rem',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        width: '80%'
        /* '@global': {
          'img': {
            maxWidth: '200px'
          }
        } */
      }
    },
    logoOpened: {
      minWidth: '100%',
      // marginBottom: '1rem',
      [theme.breakpoints.down('md')]: {
        marginLeft: '4rem'
      }
      // transition: '300ms all',
      // visibility: 'visible',
    },
    logoClosed: {
      // padding: '1rem',
      width: '4rem',
      borderRadius: 8,
      transition: '300ms all',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      '&:hover': {
        boxShadow: '2px 3px 7px 0px rgba(9, 25, 49, .2), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
        transition: '300ms all'
      }
    },
    menuButton: {
      position: 'absolute',
      zIndex: 99994,
      margin: theme.spacing(1),
      marginLeft: 0
    },
    menuIcon: {
      fontSize: '1.2em'
    },
    icon: {
      minWidth: 'fit-content',
      paddingLeft: '4px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    options: {
      // paddingLeft: '16px !important',
      // paddingRight: '16px !important',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    option: {
      position: 'relative',
      gap: '1rem',
      // marginTop: '8px',
      // minHeight: '48px',
      borderRadius: '8px',
      color: 'var(--bosscat-black-400)',
      paddingLeft: '0',
      paddingRight: '0',
      justifyContent: 'center !important'
    },
    selectedOption: {
      position: 'relative',
      marginTop: '8px',
      minHeight: '48px',
      borderRadius: '8px',
      color: 'var(--input-color)',
      backgroundColor: 'white !important',
      boxShadow: '0px 1px 1px rgba(9, 25, 49, 0.24), 0px 0px 1px rgba(9, 25, 49, 0.31)',
      gap: '1rem',
      justifyContent: 'center !important'
    },
    divider: {
      margin: '8px 24px'
    },
    subOptions: {
      display: 'grid',
      gap: '16px',
      padding: '8px 0',
      width: '100%',
      paddingBottom: '0px',
      // justifyContent: 'center',
      '@global': {
        '.MuiButton-endIcon': {
          marginLeft: '0',
          marginRight: '0'
        }
      }
    },
    line: {
      width: '90%',
      height: 0,
      border: '1px solid #CCCDD6',
      alignSelf: 'center',
      marginTop: '24px',
      margin: 'auto'
    },
    button: {
      width: '100%',
      height: '60px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontSize: '18px',
      lineHeight: '150%',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      padding: '1.7rem 0',
      "@global": {
        '.MuiButton-label': {
          paddingTop: 0
        }
      }
    },
    maintenanceButton: {
      background: 'var(--bosscat-black-1000)',
      color: 'var(--repairs-yellow-500)'
    },
    buttonReverse: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      border: '3px solid'
    },

    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: '2px solid var(--bosscat-blue-600)',
      boxSizing: 'border-box',
      borderRadius: '16px'
      // alignItems: 'center'
    },
    titleDetail: {
      display: 'flex',
      flexDirection: 'row'
    },
    iconJob: {
      marginTop: '8px',
      marginLeft: '8px',
      height: '48px',
      width: '62px'
    },
    iconArrow: {
      marginTop: '8px',
      marginRight: '8px',
      height: '14px',
      width: '14px'
    },
    arrow: {
      transform: "rotate(-90deg)"
    },
    smallInfo: {
      fontFamily: 'NEXT_ART_Normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: 'var(--date-color)',
      marginRight: '8px',
      marginBottom: '8px',
      marginLeft: '8px'
      // margin: '8px'
    },
    getEstimate: {
      display: 'grid',
      gap: '12px',
      borderRadius: '12px',
      border: '2px solid var(--bosscat-blue-600)',
      padding: '12px'
    },
    getEstimateTitle: {
      display: 'flex',
      gap: '12px'
    },
    newItemWrapper: {
      background: 'var(--repairs-yellow-100)',
      borderRadius: '4px',
      padding: "2px 4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "5px"
    },
    newItem: {
      color: "var(--repairs-yellow-700)",
      fontSize: '12px',
      lineHeight: '18px',
      marginRight: "2px"
    },
    newItemImg: {
      width: "18px",
      height: "18px"
    },
    FirstTimeMedal: {
      width: "158px",
      height: "180px",
      [theme.breakpoints.down('md')]: {
        width: "124px",
        height: "140px",
      },
    },
    FirstTimeWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      padding: "16px",
      backgroundColor: "#F9FAFA",
      [theme.breakpoints.down('md')]: {
        padding: "32px 16px",
        marginTop: "48px"
      },
    },
    FirstTimeTitle: {
      fontSize: "30px",
      fontFamily: "LatoNormal",
      textAlign: "center",
      margin: "24px 0 16px",
      [theme.breakpoints.down('md')]: {
        fontSize: "22px",
      },
    },
    FirstTimeSubtitle: {
      fontSize: "20px",
      textAlign: "center",
      [theme.breakpoints.down('md')]: {
        fontSize: "18px",
        fontFamily: "NEXT_ART_Normal",
      },
    },
    LgTitle: {
      fontFamily: "LatoNormal",
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: "24px",
    },
    MdTitle: {
      fontFamily: "LatoNormal",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: "20px",
    },
    boxContainer: {
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      borderRadius: '8px',
      padding: '8px',
      width: '100%',
      height: '100%',
    },
  })
)
