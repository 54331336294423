import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '1rem',
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: 'repeat(1, 1fr)',
            }
        },
        disclaimer: {
            color: "#494A5A",
            fontFamily: "LatoNormal",
            marginTop: "1rem"
        }
    }))
