import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: 'white',
      // minHeight: '580px',
      borderRadius: 16,
      padding: 24
    },
    stepper: {
      '@global': {
        '>.MuiBox-root:first-child': {
          justifyContent: 'center !important',
        },
        '>.MuiBox-root': {
          '@global': {
            '>.MuiBox-root': {
              position: 'relative !important',
              overflow: 'hidden',
              '@global': {
                '>.MuiBox-root': {
                  marginBottom: '10px !important',
                }
              }
            }
          }
        },
      },
    },
    root: {
      display: 'grid',
      background: 'white',
      // minHeight: '580px',
      borderRadius: 16,
      padding: 24,
      margin: '0 auto',
      gridTemplateRows: 'auto min-content',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        // marginTop: '24px !important',
        padding: 0
      }
    },
    content: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '0px 12px 0px 24px',
      maxWidth: '1250px' // theme.breakpoints.values.xxl,
    },
    textureBottom: {
      position: 'absolute',
      bottom: -100,
      right: 0
    }
  })
)