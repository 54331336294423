import { Box, Typography } from "@material-ui/core"
import { bosscatHomeServicesLogo, logo, mellohome, mellohomeTruck, mellohomeBgMaintenance, trustpilot } from "assets/index"
import useStyles from './styles'
import Banner from "../Banner"

const DiptHeader = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.headerLogos}>
        <img src={bosscatHomeServicesLogo} className={`${classes.logo} ${classes.logoHeight}`} alt='' />
        <img src="https://images.squarespace-cdn.com/content/v1/59f72ac64c0dbf163ec24337/c1605e29-78ba-47c6-b66f-16efe69ed069/Dipt_2.jpg" className={classes.logo} alt='' />
      </Box>
      <Banner
        title={
          <>
            <Typography className={classes.title}> PUT YOUR HOME MAINTENANCE ON AUTOPILOT</Typography>
          </>
        }
        subtitle={
          <>
            <Typography className={classes.subtitle}>Keep your home running smoothly with a BOSSCAT home maintenance membership</Typography>
          </>
        }
        imgSrc={mellohomeTruck}
        badgeSrc={trustpilot}
      // color="var(--bosscat-blue-700)"
      // titleColor="var(--bosscat-blue-700)"
      />
      <Box className={classes.footer}>RECEIVE AN EXCLUSIVE GIFT FOR DIPT CUSTOMERS</Box>
      {/* <img src={mellohomeBg} alt="mellohome" /> */}
    </Box>
  )
}

export default DiptHeader