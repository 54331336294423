/* eslint-disable react-hooks/exhaustive-deps */
import env from '@beam-australia/react-env'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { gmailIcon, icons, logo, mailIllustration, outlookIcon, success, yahooIcon } from '../../../../../../assets'
import {
  configActions,
  newInspectionActions
} from '../../../../../../ducks/actions'
import {
  history
} from '../../../../../../helpers'
import { useClassName } from '../../../../../../hooks'
import {
  Box,
  Button,
  Link,
  Typography
} from '../../../../../UI'
import useStyles from './styles'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)


const formatDate = 'MMMM Do, YYYY'

const Details: FC<{
  setMail: (email: 'gmail' | 'yahoo' | 'outlook' | '') => void
}> = ({ setMail }) => {
  const classes = useStyles()
  const classNames = useClassName()
  const dispatch = useDispatch()
  const query = history.useQuery()
  const email = query.get('email') ?? ''

  const showButton = email.includes('gmail') || email.includes('yahoo') || email.includes('outlook') || email.includes('hotmail')

  const getIconBasedOnEmail = () => {

    if (email.includes('gmail')) {
      return <img src={gmailIcon} className={classes.emailImg} alt='Gmail' />;
    } else if (email.includes('yahoo')) {
      return <img src={yahooIcon} className={classes.emailImg} alt='Yahoo' />;
    } else if (email.includes('outlook') || email.includes('hotmail')) {
      return <img src={outlookIcon} className={classes.emailImg} alt='Outlook' />;
    }
    // Return a default icon if no match is found
    return <img src={gmailIcon} className={classes.emailImg} alt='Gmail' />;
  };


  const getTextBasedOnEmail = () => {

    if (email.includes('gmail')) {
      return 'Open GMail'
    } else if (email.includes('yahoo')) {
      return 'Open Yahoo'
    } else if (email.includes('outlook') || email.includes('hotmail')) {
      return 'Open Outlook'
    }
    // Return a default icon if no match is found
    return 'Open GMail'
  };

  const getEmailProviderUrl = () => {

    if (email.includes('gmail')) {
      return 'https://mail.google.com/';
    } else if (email.includes('yahoo')) {
      return 'https://mail.yahoo.com/';
    } else if (email.includes('outlook') || email.includes('hotmail')) {
      return 'https://outlook.live.com/';
    }
    // Return a default URL if no match is found
    return 'https://mail.google.com/';
  };

  useEffect(() => {

    // returned function will be called on component unmount 
    return () => {
      dispatch(newInspectionActions.cleanNewInspection())
    }
  }, [])

  const push = history.usePush()


  const setOverlay = (overlay: boolean) => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: overlay })
    )
  }



  return (
    <Elements
      aria-label='success-details'
      stripe={stripePromise}
    >

      <Box className={classes.root}>
        <a href="https://www.bosscathome.com/" target="_blank" rel="noreferrer">
          <img src={logo} className={classes.logo} alt="BOSSCAT Logo" />
        </a>
        <img src={success} className={classes.success} alt="success" />
        <Typography className={classes.nextHeavyHardware}>
          We Received Your Request!
        </Typography>
        <Typography className={classes.header}>
          Thanks for submitting your {' '}
          <span className={classes.bold}>{`Inspection Request.`}</span>
        </Typography>
        <Typography className={classes.desc}>
          You will be contacted by our Inspection partner.
        </Typography>
        <Box className={classes.letsLogin}>
          <Box className={classes.grid}>
            <img src={mailIllustration} className={classes.mailIllustration} alt="mail" />
            <Typography className={classNames(classes.nextArt, classes.bold)}>CHECK YOUR EMAIL FOR YOUR INSPECTION
              REQUEST CONFIRMATION</Typography>
            {email && showButton && <Button
              variant='contained'
              className={classNames(classes.emailButton, classes.bold)}
              startIcon={getIconBasedOnEmail()}
              onClick={() => window.open(getEmailProviderUrl(), '_blank')}
            >
              {getTextBasedOnEmail()}
            </Button>}
          </Box>

        </Box>
        <Link href="https://www.bosscathome.com/" target="_blank" rel="noreferrer">
          <Typography className={classes.descLink}>
            Learn More about BOSSCAT  <icons.CallMade />
          </Typography>
        </Link>

      </Box>


    </Elements >
  )
}
export default Details
