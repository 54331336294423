import { authTypes } from 'ducks/auth/types'
import { CustomerSuccessManagerType, SubscriptionsActions, SubscriptionsType, subscriptionsTypes, SubscriptionsValue } from './types'

const initialState: SubscriptionsType = {
  subscriptions: [],
  plans: [],
  deliveryDate: '',
  customerSuccessManager: null
}

const reducer = (state = initialState, action: SubscriptionsActions): SubscriptionsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case subscriptionsTypes.SET_SUBSCRIPTIONS_VALUE: {
      const { attr, value } = payload as SubscriptionsValue
      return {
        ...state,
        [attr]: value
      }
    }
    case subscriptionsTypes.SET_CSM_VALUE: {
      const csm = payload as CustomerSuccessManagerType;
      return {
        ...state,
        customerSuccessManager: csm
      }
    }
    default:
      return state
  }
}

export default reducer
