import { FC, useState } from 'react'
import { Grid, Typography, Box } from '../../../../../UI'
import { JobItemsProps } from './types'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { icons } from '../../../../../../assets'
import JobItem from '../JobItem'
import { getCategoryIcon, ITEM_CATEGORY } from '../../../../../../helpers'

const JobItems: FC<JobItemsProps> = ({ children, category, showBetterStyle = false }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const [open, setOpen] = useState(true)

  if (children && children.length > 0) {
    return (
      <Box className={classes.itemsBox}>
        <Box
          className={classes.title}
          onClick={() => setOpen(!open)}
        >
          <Box className={classes.row}>
            <img src={getCategoryIcon(category, showBetterStyle)} alt='' className={classes.iconCategory} />
            <Typography variant='subtitle1' className={classes.titleText}>
              {ITEM_CATEGORY[category as keyof typeof ITEM_CATEGORY]}
            </Typography>
          </Box>
          {open
            ? <icons.KeyboardArrowUp className={classes.icon} />
            : <icons.KeyboardArrowDown className={classes.icon} />}
        </Box>
        <Box hidden={!open}>
          <Grid container spacing={5} className={classes.items}>
            {children.map((item, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <JobItem showBetterStyle={showBetterStyle}>{item}</JobItem>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
    )
  }
  return null
}

export default JobItems
