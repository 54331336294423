import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'


export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      height: '80px',
      alignItems: 'center',
      padding: '0px 180px 0 0px',
      [theme.breakpoints.down('lg')]: {
        padding: '0px 180px 0 70px',
        height: '180px'
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      padding: '20px 0px 20px 50px'
    },
    text: {
      fontFamily: 'Lato',
      fontWeight: 400,
      lineHeight: '22px',
      fontSize: '14px',
      margin: '0px 18px 0px 0px',
      padding: '4px 0px',
      color: 'var(--input-color)'
    },
    icon: {
      height: '15px',
      width: '13px',
      color: 'var(--bosscat-blue-600)',
      margin: '0px 9px 0px 0px'
    },
    button: {
      width: '210px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      border: '3px solid',
      marginRight: '20px',
      justifySelf: 'flex-end',
      '&:hover': {
        backgroundColor: 'var(--white-color)'
        // color: 'var(--button-focus-color)'
      }
    }
  })
)
