import storeInfo from 'ducks/store'
import { Property } from 'ducks/types'

export const getProperties = () => (): Property[] => {
  const { properties } = storeInfo.store.getState()
  if (!properties || !properties.length) return []
  return properties
}

export const getPropertyById = (id: string) => (): Property => {
  const { properties } = storeInfo.store.getState()
  return properties.find((prop) => prop.id === id) as Property
}
