import { PropertyContact } from 'ducks/types'
import {
  PropertyContactsActions,
  propertyContactType,
  PropertyContactsType,
} from './types'

const actions = {
  fetchPropertyContacts: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyContactsActions => ({
    type: propertyContactType.FETCH_PROPERTY_CONTACTS,
    payload,
    callback,
  }),
  createPropertyContact: (
    payload: { id: string; request: Partial<PropertyContact> },
    callback?: (succ: boolean) => void
  ): PropertyContactsActions => ({
    type: propertyContactType.CREATE_PROPERTY_CONTACT,
    payload,
    callback,
  }),
  updatePropertyContact: (
    payload: { propertyId: string, contactId: string, request: Partial<PropertyContact> },
    callback?: (succ: boolean) => void
  ): PropertyContactsActions => ({
    type: propertyContactType.UPDATE_PROPERTY_CONTACT,
    payload,
    callback
  }),
  deletePropertyContact: (
    payload: { propertyId: string, contactId: string },
    callback?: (succ: boolean) => void
  ): PropertyContactsActions => ({
    type: propertyContactType.DELETE_PROPERTY_CONTACT,
    payload,
    callback
  }),
  setPropertyContact: (
    payload: PropertyContactsType
  ): PropertyContactsActions => ({
    type: propertyContactType.SET_PROPERTY_CONTACTS,
    payload,
  }),
}

export default actions
