import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--white-color)',
      [theme.breakpoints.down('sm')]: {
        gap: '0 !important'
      }
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[100],
      alignItems: 'center',
      padding: '20px 0px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0px !important',
      }

    },
    headerTitle: {
      fontFamily: 'LatoNormal',
      fontSize: '22px',
      lineHeight: '58px'
    },
    headerDescription: {
      fontFamily: 'LatoNormal',
      maxWidth: '360px',
      fontSize: '16px',
      lineHeight: '24px',
      paddingBottom: '12px',
      color: 'rgba(73, 74, 90, 1)'
    },
    separator: {
      width: '100%',
      height: '0px',
      borderWidth: '0px',
      borderTopWidth: '4px',
      borderColor: theme.palette.primary.dark,
      borderStyle: 'solid',
      margin: '1rem 0',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    greyColorBorder: {
      borderColor: theme.palette.grey[200]
    },
    dashedSeparator: {
      width: '128px',
      [theme.breakpoints.down('md')]: {
        width: '96px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '46px',
        transform: 'rotate(90deg)',
        margin: 18
      },
      border: '2px dashed #AAACBB',
      alignSelf: 'center',
      borderWidth: '0px',
      borderTopWidth: '2px',
      borderColor: theme.palette.grey[400],
      margin: '60px 24px 56px'
    },
    estimatedTimeContainer: {
      display: 'flex',
      alignIems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: '1rem'
      }
    },
    estimatedTimeItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '220px',
      [theme.breakpoints.down('md')]: {
        width: '196px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    estimatedTimeLabel: {
      fontFamily: 'Lato',
      fontSize: '18px',
      lineHeight: '28px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    estimatedTimeDate: {
      fontSize: '20px',
      lineHeight: '30px'
    },
    estimatedTime: {
      textAlign: 'center',
      borderWidth: '2px',
      borderRadius: '8px',
      padding: '8px 16px',
      margin: '8px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[700],
      backgroundColor: 'var(--white-color)',
      color: theme.palette.grey[700],
      width: '220px',
      [theme.breakpoints.down('md')]: {
        width: '196px'
      }
    },
    borderBlue: {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light
    },
    sectionsRoot: {
      margin: '20px 20px 0px',
      display: 'flex',
      flexDirection: 'column'
    },
    sectionTitle: {
      fontSize: '24px',
    },
    links: {
      display: 'flex',
      gap: '8px'
    },
    hyperlink: {
      color: 'var(--bosscat-blue-600)',
      cursor: 'pointer',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: 14
      }
    },
    horizontalContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: 0,

      }
    },
    closingDate: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    horizontalBox: {
      width: '100%',
      '@global': {
        '.MuiTypography-body1': {
          color: 'var(--input-color)',
          fontFamily: 'LatoNormal'
        }
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center !important',
        margin: '1.5rem 0'
      }
    },
    horizontalBoxRight: {
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      }
    },
    centerContainer: {
      display: 'flex',
      justifyContent: 'center',

    },
    orderTotal: {
      [theme.breakpoints.down('sm')]: {
        margin: '1rem 0',
        padding: '.5rem 0',
        borderBottom: '2px solid var(--head-text-color)'
      }
    },
    sectionInfo: {
      fontSize: '16px',
      color: theme.palette.grey[700],
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.5rem',
        flexDirection: 'row',
        borderBottom: '2px solid var(--border-color)',
        paddingBottom: '1.5rem'
      }
    },
    total: {
      marginBottom: 0,
      borderBottom: 0
    },
    textSpacing: {
      margin: '10px 0px'
    },
    textRight: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    footer: {
      width: '100%',
      margin: '20px 0px 0px',
      padding: '16px 0px',
      fontSize: '16px',
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[100]
    }
  })
)
