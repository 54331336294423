import { authTypes } from 'ducks/auth/types'
import { Estimate } from 'ducks/types'
import { estimatesTypes, EstimatesType, EstimatesActions, EstimateValue } from './types'

const initialState: EstimatesType = []

const reducer = (state = initialState, action: EstimatesActions): EstimatesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case estimatesTypes.SET_ESTIMATES: {
      return payload as Estimate[]
    }

    case estimatesTypes.SET_ESTIMATE_VALUE: {
      const { estimateId, attr, value } = payload as EstimateValue

      const index = state.findIndex(estimate => {
        return estimate.id === estimateId
      })

      if (index !== -1) {
        const estimatesCopy = [...state]
        estimatesCopy[index] = {
          ...state[index],
          [attr]: value
        }
        return estimatesCopy
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
