import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, LoginRandomAnimation } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'
import { HouseSvg, blackHouse, blueHouse, greenHouse, loginTexture, redHouse, texture, whiteLogo } from '../../../../assets'
import { PublicLoader } from '../..'
import { getConfigValue } from '../../../../ducks/selectors'

const Template: FC<TemplateProps> = ({ children }) => {
  const classes = useStyles()
  const publicLoading = useSelector(getConfigValue('publicLoading'))

  const bgcolors = ['var(--handyman-green-900)', 'var(--bosscat-blue-900)', 'var(--hardware-haze-1000)', 'var(--hardware-haze-1000)']

  const getRandomNum = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const randomIndex = getRandomNum(0, bgcolors.length)

  return (
    <PublicLoader loading={publicLoading}>
      <Grid className={classes.container}>
        <Grid item className={classes.center}>
          <Box /* className={classes.childrenBox} */>{children}</Box>
        </Grid>
        <img src={HouseSvg} alt="" className={classes.textureBottom} />

      </Grid>
      {/*  <img src={whiteLogo} alt="" className={classes.logoAlt} />
      <Grid container className={classes.root}>
        <Grid
          component={Box}
          display={{ xs: 'none', sm: 'none', md: 'none', lg: 'grid' }}
          item
          xs={5}
          className={classes.randomAnimationBox}
          style={{ backgroundColor: bgcolors[randomIndex], position: 'relative' }}
        >
          <LoginRandomAnimation index={randomIndex} />
        </Grid>
        <Grid item xs={12} lg={7} className={classes.center}>
          <Box className={classes.childrenBox}>{children}</Box>
        </Grid>
      </Grid>
      <img src={loginTexture} alt="" className={classes.loginTexture} /> */}
    </PublicLoader>
  )
}

export default Template