import { Theme, createStyles, makeStyles } from '../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridAutoRows: 'min-content auto'
    },
    primaryText: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoBold',
      fontWeight: 700,
      marginLeft: '10px'
    },
    combo: {
      minWidth: '340px',
      height: '52px',
      marginRight: '10px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    complete: {
      minWidth: '100px',
      height: '53px'
    },
    margin: {
      marginTop: '10px',
      display: 'flex',
      alignContent: 'start'
    },
    address: {
      marginLeft: '10px',
      height: '100%'
    },
    statusSelect: {
      '& li:first-child': {
        '&:after': {
          content: '""',
          display: 'block',
          borderBottom: '2px solid var(--black-color)',
          borderRadius: '8px',
          transform: 'translateY(12px)',
          width: '100%'
        }
      }
    },
    tablerow: {
      '&:hover': {
        backgroundColor: 'var(--row-focus)'
      }
    },
    selected: {
      backgroundColor: 'var(--row-focus)'
    },
    selectedText: {
      fontFamily: 'LatoBold !important',
      fontWeight: 500,
      fontSize: 14,
      borderWidth: 0
    },
    tableComponent: {
      minWidth: '300px',
      margin: '10px 0px',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12
    },
    cellHead: {
      backgroundColor: 'var(--background-color-secondary)',
      color: 'var(--head-text-color)',
      borderWidth: 0,
      fontFamily: 'Lato',
      fontSize: '16px'
    },
    cell: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 14,
      borderWidth: 0
    },
    line: {
      display: 'flex',
      flexDirection: 'column',
      width: '2px',
      backgroundColor: 'var(--border-color)',
      height: '98%'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    rowSpace: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '98%',
      maxWidth: '98%'
    },
    delete: {
      width: '28px',
      height: '28px'
    },
    deleteButton: {
      width: '28px',
      height: '28px',
      padding: '0px',
      border: '0px solid',
      boxShadow: '0px 0px 0px 0px',
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        border: '0px solid',
        boxShadow: '0px 0px 0px 0px',
        backgroundColor: 'var(--white-color)'
      }
    },
    noResultContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }
  })
)
