import { FC } from 'react'
import { Props } from './types'
import useStyles from './styles'
import { Typography, Box, Button, Avatar } from '@material-ui/core'
import { arrowLeft, arrowRight } from '../../../../../assets'
import moment from 'moment'

const YearPicker: FC<Props> = ({
  value, onChange
}) => {
  const classes = useStyles()
  const thisYear = moment().year()

  const handleAdd = (): void => {
    if (value < thisYear) { value = value + 1 }
    onChange(value)
  }

  const handleLess = (): void => {
    value = value - 1
    onChange(value)
  }

  return (
    <Box className={classes.root}>
      <Button
        className={classes.arrow}
        endIcon={<Avatar alt='' src={arrowLeft} className={classes.icon} />}
        onClick={() => handleLess()}
        size='small'
        type='submit'
        variant='text'
      />
      <Typography variant='body1' className={classes.yearLabel}>
        {value}
      </Typography>
      <Button
        type='submit'
        variant='text'
        size='small'
        className={classes.arrow}
        onClick={() => handleAdd()}
        startIcon={<Avatar alt='' src={arrowRight} className={classes.icon} />}
        disabled={value === thisYear}
      />
    </Box>
  )
}

export default YearPicker
