/* eslint-disable @typescript-eslint/no-unused-vars */
import { Auth } from 'api'
import storeInfo from 'ducks/store'
import { isEmpty } from 'helpers'
import { tokenExpired } from 'api/punchlist/axios'

export const isAuthorized = (): boolean => {
  const { user } = storeInfo.store.getState()

  const hasId = !isEmpty(user.id)
  const token = Auth.getToken()
  const hasToken = token !== null
  const authorized = hasId && hasToken && !tokenExpired(token as string)
  return authorized
  // return false
}

export const isRegisterCompleted = (): boolean => {
  const { user } = storeInfo.store.getState()
  return user.registrationComplete
}
