/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { Navigation, ScrollWrapper } from '../../../../templates'
import Details from './Details'
import useStyles from './styles'
import Register from './Register'
import FeedbackModal from 'components/pages/Desktop/Dashboard/Modals/FeedbackModal'
import { REFERRAL_TYPE } from 'ducks/types'
import { useDispatch, useSelector } from 'react-redux'
import { discoveryActions, newEstimateActions, newOrderMaintenanceActions, subscriptionActions, userActions } from 'ducks/actions'
import { getNewOrderMaintenanceValue, getSubscription, getUser, isAuthorized } from 'ducks/selectors'
import { BOSSCAT_PHONE_NUMBER, CONTACT_ROLE, USER_TYPE } from 'helpers/constants'
import { bosscatLogo, createAccountLoading } from '../../../../../assets'
import { history } from 'helpers/index'


const Success: FC = () => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const subscription = useSelector(getSubscription())
  const showSurvey = useSelector(getNewOrderMaintenanceValue("showSurvey"))
  const clientType = useSelector(getNewOrderMaintenanceValue("clientType"))
  const mainContact = useSelector(getNewOrderMaintenanceValue("mainContact"))
  const propertyAddress = useSelector(getNewOrderMaintenanceValue("propertyAddress"))
  const [newMainContact, setNewMainContact] = useState(mainContact)
  const [address, setAddress] = useState(propertyAddress)
  const [loading, setLoading] = useState(false)
  const authorized = useSelector(isAuthorized)
  const [clientTypeSaved, setClientTypeSaved] = useState(clientType)
  const user = useSelector(getUser)

  const query = history.useQuery()
  const affiliate = query.get('affiliate')

  const isAgent = [USER_TYPE.BROKER, USER_TYPE.INSPECTOR, USER_TYPE.CLOSING_COORDINATOR, USER_TYPE.PROPERTY_MANAGER, USER_TYPE.OTHER].includes(user.clientType)

  useEffect(() => {
    if (showSurvey) {
      setTimeout(() => {
        setModalOpen(true)
      }, 600)
    } else {
      dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
      dispatch(subscriptionActions.clearSubscription())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSurvey])

  useEffect(() => {
    if (!authorized) {
      if (clientType === CONTACT_ROLE.LISTING_AGENT) {
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
          dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "showSurvey", value: true }))
        }, 3000)
      }
    } else {
      if (isAgent && user?.protectPlanIsNew !== false) {
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "showSurvey", value: true }))
      }
    }
    if (clientType === CONTACT_ROLE.INSTITUTIONAL_INVESTOR) {
      dispatch(subscriptionActions.clearSubscription())
      dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
    }
  }, [clientType])

  const handleSendFeedback = (option: REFERRAL_TYPE | string, details: string) => {
    dispatch(discoveryActions.sendFeedback({ resourceId: subscription?.id as string, resourceType: 'SUBSCRIPTION', source: option, details }, (succ) => {
      if (succ) {
        setModalOpen(false)
        dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "showSurvey", value: false }))
        dispatch(subscriptionActions.clearSubscription())
        dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
        if (authorized) {
          let dataToUpdate: any = {};
          dataToUpdate.protectPlanIsNew = false;
          if (!user.affiliate) dataToUpdate.affiliate = affiliate || ""
          dispatch(userActions.updateUser({
            ...dataToUpdate
          }, (succ) => {
            if (succ) {
              dispatch(userActions.fetchCurrentUser(() => { }))
            }
          }))
        }
      } else {
        dispatch(subscriptionActions.clearSubscription())
        dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
      }
    }))
  }

  const [page, setPage] = useState<'success' | 'expedite' | 'register' | null>(
    'success'
  )
  const goBack = () => setPage('success')

  const handleCloseModal = () => {
    setModalOpen(false)
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "showSurvey", value: false }))
    dispatch(subscriptionActions.clearSubscription())
    dispatch(newOrderMaintenanceActions.clearOrderMaintenance())
    if (authorized) {
      let dataToUpdate: any = {};
      dataToUpdate.protectPlanIsNew = false;
      if (!user.affiliate) dataToUpdate.affiliate = affiliate || ""
      dispatch(userActions.updateUser({
        ...dataToUpdate
      }, (succ) => {
        if (succ) {
          dispatch(userActions.fetchCurrentUser(() => { }))
        }
      }))
    }
  }


  return (
    <Navigation>
      <>
        <FeedbackModal
          open={modalOpen}
          setOpen={handleCloseModal}
          onSubmit={handleSendFeedback}
          titleToShow='How did you hear about the Maintenance Membership?'
        />
        <Box className={classes.root} aria-label='estimate-success'>
          <Box className={classes.content} aria-label='success-content'>
            <ScrollWrapper>
              <>
                {page === 'register' && <Register goBack={goBack} />}
                {page === 'success' && <Details clientType={clientTypeSaved} setPage={(value) => {
                  dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: "mainContact", value: newMainContact }))
                  dispatch(newEstimateActions.setNewEstimateValue({
                    attr: 'estimate', value: {
                      properties: {
                        address: address,
                      }
                    }
                  }))
                  setPage(value)
                }
                } />}
              </>
            </ScrollWrapper>
          </Box>
          <Box className={classes.footer}>
            <Box className={classes.footerContent}>
              <Typography variant='body2'>
                Questions? Speak with a live Customer Care Professional
              </Typography>
              <Typography variant='body2'>
                <a href={`tel:${BOSSCAT_PHONE_NUMBER}`}>{BOSSCAT_PHONE_NUMBER}</a> M-F, 8:00am - 6:00pm
                EST
              </Typography>
            </Box>
          </Box>
        </Box>
        <div className={`${classes.loading} ${loading ? '' : classes.loadingHidden}`}>
          <img src={bosscatLogo} alt='BOSSCAT Logo' style={{ width: '160px' }} />
          <Box className={classes.loadingContainer}>
            <img src={createAccountLoading} alt="BOSSCAT loading" style={{ width: "240px", height: "240px" }} />
            <Typography className={classes.loadingCopy}>A BOSSCAT account will be created, and a temporary password will be sent to your email.</Typography>
            <CircularProgress size={40} />
          </Box>
        </div>
      </>

    </Navigation>
  )
}
export default Success
