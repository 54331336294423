import React from 'react'
import { Box, CircularProgress, Typography } from '../../../../../UI';
import BottomSheet from 'components/UI/CustomUI/molecules/BottomSheet';
import useStyles from './styles'
import { icons } from 'assets'
import { Skeleton } from '@material-ui/lab';

type Props = {
    open: boolean;
    loading: boolean;
    handleClose: () => void;
    data: {
        picture: string;
        position: string;
        email: string;
        phone: string;
        name: string;
        lastName: string;
    }
}

function CustomerSuccessManagerModal({ open, loading, handleClose, data }: Props) {
    const classes = useStyles();

    return (
        <BottomSheet
            title={""}
            open={open}
            onClose={handleClose}>
            {loading ? <Box className={classes.Container}>

                <Skeleton variant="rect" style={{ marginBottom: "16px" }} width={448} height={369} />
                <Skeleton variant="rect" style={{ marginBottom: "16px" }} width={150} height={20} />
                <Skeleton variant="rect" width={175} height={20} />
            </Box>
                : <Box className={classes.Container}>
                    {data.picture ? <img className={classes.CustomerSuccessImage} src={data.picture} alt="" /> : <icons.Person className={classes.CustomerSuccessImage} />}

                    <Typography className={classes.Title}>{`${data.name} ${data.lastName}`}</Typography>
                    <Typography className={classes.Position}>{data.position}</Typography>
                    <Box className={classes.Buttons}>
                        <a href={`tel:${data.phone}`} className={classes.ButtonSec}>
                            <icons.Phone className={classes.icon} />
                            <Typography className={classes.text}>{data.phone}</Typography>
                        </a>
                        <a href={`mailto:${data.email}`} className={classes.ButtonSec}>
                            <icons.Mail className={classes.icon} />
                            <Typography className={classes.text}>{`Email ${data.name}`}</Typography>
                        </a>
                    </Box>
                </Box>}
        </BottomSheet>
    )
}

export default CustomerSuccessManagerModal