import { authTypes } from 'ducks/auth/types'
import { CampaignsActions, CampaignsType, campaignsTypes } from './types'

const initialState: CampaignsType = null

const reducer = (state = initialState, action: CampaignsActions): CampaignsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case campaignsTypes.SET_CAMPAIGNS: {
      return payload as CampaignsType
    }

    default:
      return state
  }
}

export default reducer
