import { Theme, createStyles, makeStyles } from '../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '58px',
      color: 'var(--head-text-color)',
      margin: '8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '40px',
      },
    },
    price: {
      display: 'flex',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px',
      justifyContent: 'flex-end'
    },
    subtitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--text-color)',
      margin: '8px'
    },
    detail: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--head-text-color)',
      margin: '8px'
    },
    buttonReverse: {
      width: '70px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'none',
      color: 'var(--head-text-color)',
      backgroundColor: 'var(--progress-color-back)',
      border: '0px solid',
      marginBottom: '20px',
      borderRadius: '8px',
    },
    iconBack: {
      margin: '0px !important',
    },
    itemTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    iconBox: {
      margin: '10px 0px 8px 8px',
      height: '60px',
      width: '60px',
      backgroundColor: 'var(--background-color-secondary)'
    },
    iconJob: {
      margin: '8px 18px',
      height: '24px',
      width: '24px'
    },
    iconPay: {
      margin: '8px',
      height: '40px',
      width: '91px'
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    iconTitle: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      color: 'var(--head-text-color)',
      margin: '10px'
    },
    line: {
      width: '2px',
      backgroundColor: 'var(--border-color)',
    },
    lineHorizontal: {
      backgroundColor: 'var(--border-color)',
      width: '100%',
      height: '2px',
      margin: '36px 0',
    },
    button: {
      width: '220px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '50px 0px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      }
    },
    buttonContant: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      }
    },
    percent: {
      margin: '5px 0px',
      height: '12px',
      width: '12px',
      borderRadius: 40
    },
    itemType: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--text-color)',
      margin: '0px 12px'
    },
    itemDate: {
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: 'var(--text-color)',
      display: 'grid',
      justifyContent: 'flex-end',
      // width: '50%'
    },
    dotLine: {
      width: '1px',
      height: '100%',
      border: 'none',
      borderRight: '3px dashed var(--gray-color)',
      margin: '0px 4px'
    },
    dotContent: {
      minHeight: '70px',
      backgroundColor: 'var(--white-color)'
    },
    dotEmpty: {
      minHeight: '10px',
      backgroundColor: 'var(--white-color)'
    },
    statusContainer: {
      display: 'flex'
    },
    statusDate: {
      justifyContent: 'space-between'
    },
    label: {
      padding: '0px 4px 0px 0px !important'
    },
    paymentDataContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column-reverse',
      }
    },
    titleBox: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        backgroundColor: 'var(--background-color-secondary)',
        textAlign: 'center',
      }
    }
  })
)
