import { createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles(() =>
  createStyles({
    image: {
      margin: 'auto',
      width: '90%'
    }
  })
)
