import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '408px',
      borderRadius: '16px',
      textAlign: 'center',
    },
    text: {
      padding: '0 24px',
      fontFamily: 'LatoNormal',
    },
    icon: {
      width: '68px',
      height: '68px',
      marginBottom: '12px',
    },
    phone: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
    },
    phoneIcon: {
      width: '21px',
      height: '21px',
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important',
      }
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
    },
  })
)
