/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import { subscriptionsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'
import { FetchEmployeesAction, FetchSubscriptionsDeliveryDate, FetchSubscriptionsAction, FetchSubscriptionsPlansAction, RequestScheduleServiceAction, subscriptionsTypes, CheckUserVIPAction } from './types'

export function* fetchSubscriptions({ payload, callback }: FetchSubscriptionsAction): SagaIterator {
  let success = false
  try {
    const subscriptions = yield call(Punchlist.subscriptions.getSubscriptions, payload)

    if (subscriptions) {
      yield put(subscriptionsActions.setSubscriptionsValue({ attr: 'subscriptions', value: subscriptions }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the subscription'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchEmployees({ payload, callback }: FetchEmployeesAction): SagaIterator {
  let success = false
  try {
    const csm = yield call(Punchlist.employees.getEmployees, payload)
    if (csm) {
      yield put(subscriptionsActions.setCSMValue(csm))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the employee info'))
    if (callback) yield call(callback, false)
  }
}

export function* checkUserVip({ payload, callback }: CheckUserVIPAction): SagaIterator {
  let success = false
  try {
    const isVip = yield call(Punchlist.subscriptions.checkVip, payload.email)
    success = true
    if (isVip) {
      yield call(toast.error, 'The user already belongs to the maintenance program')
    }
    if (callback) yield call(callback, success, isVip)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the user info'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchSubscriptionsPlans({ payload, callback }: FetchSubscriptionsPlansAction): SagaIterator {
  let success = false
  try {
    const plans = yield call(Punchlist.subscriptions.getSubscriptionsPlans)

    if (plans) {
      yield put(subscriptionsActions.setSubscriptionsValue({ attr: 'plans', value: plans }))
    }
    success = true
    if (callback) yield call(callback, success, plans)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the plans'))
    if (callback) yield call(callback, false, null)
  }
}

export function* fetchSubscriptionsDeliveryDate({ payload, callback }: FetchSubscriptionsDeliveryDate): SagaIterator {
  let success = false
  try {
    const deliveryDate = yield call(Punchlist.subscriptions.getSubscriptionsDeliveryDate)

    if (deliveryDate) {
      yield put(subscriptionsActions.setSubscriptionsValue({ attr: 'deliveryDate', value: deliveryDate }))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the delivery date'))
    if (callback) yield call(callback, false)
  }
}


export function* requestScheduleServicePlan({ payload, callback }: RequestScheduleServiceAction): SagaIterator {
  let success = false

  try {
    const { subscriptionId, request } = payload;
    const subscriptionServicePlan = yield call(Punchlist.subscriptions.requestScheduleService, subscriptionId, request)

    if (!isEmpty(subscriptionServicePlan)) {
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('request the schedule service'))
    if (callback) yield call(callback, false)
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(subscriptionsTypes.FETCH_SUBSCRIPTIONS, fetchSubscriptions)
  yield takeLatest(subscriptionsTypes.FETCH_SUBSCRIPTIONS_PLANS, fetchSubscriptionsPlans)
  yield takeLatest(subscriptionsTypes.FETCH_SUBSCRIPTIONS_DELIVERY_DATE, fetchSubscriptionsDeliveryDate)
  yield takeLatest(subscriptionsTypes.FETCH_EMPLOYEES, fetchEmployees)
  yield takeLatest(subscriptionsTypes.CHECK_USER_VIP, checkUserVip)
  yield takeLatest(subscriptionsTypes.REQUEST_SCHEDULE_SERVICE, requestScheduleServicePlan)
}
