import { history } from 'helpers/index'
import React, { useEffect } from 'react'

type Props = {}

const RedirectForLegacy = (props: Props) => {

    const { id: estimateId } = history.getParamValues()
    const push = history.usePush()
    useEffect(() => {
        if (estimateId) {
            push(`p/estimates/${estimateId}`)
        }
    }, [estimateId])

    return (
        <></>
    )
}

export default RedirectForLegacy