import { Checkbox, Grid, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { STextField, TextFieldLabel } from 'components/UI'

export interface AccessDetailsProps {}

const AccessDetails: FC<AccessDetailsProps> = () => {
  const classes = useStyles()

  const { showWarnings, saveNewValue } = useStepperContext()

  const { accessDetails, lockboxConsent, lockbox } = useSelector(
    getApprovalValues()
  )

  return (
    <>
      <Grid item>
        <Typography className={classes.title}>🔑 access details</Typography>
        <Typography variant="body1" className={classes.latoPrimaryText}>
          Instructions for accessing this property (if any)
        </Typography>
        <STextField
          className={classes.comboUnit}
          value={accessDetails}
          onChange={(event: any) => {
            saveNewValue('accessDetails', event.target.value)
          }}
          placeholder="Gate code is #1234"
        />
        <Typography variant="body1" className={classes.latoPrimaryText}>
          Lockbox code (If applicable)
        </Typography>
        <TextFieldLabel
          label=""
          value={lockbox || ''}
          onChange={(event: any) => {
            saveNewValue('lockbox', event.target.value)
          }}
          placeholder="3466"
          className={classes.input}
        />
      </Grid>

      {!lockbox && (
        <Grid
          container
          item
          className={classes.consent}
          style={{
            borderColor:
              showWarnings && !lockboxConsent
                ? 'var(--border-border-error-light)'
                : '#ACCAFC',
            background: '#FCFCFC',
          }}
        >
          <Checkbox
            checked={lockboxConsent}
            onChange={() => saveNewValue('lockboxConsent', !lockboxConsent)}
            name="lockboxConsent"
            color="primary"
            style={{
              color: showWarnings && !lockboxConsent ? 'red' : 'primary',
              padding: 0,
              height: '24px',
            }}
          />
          <Typography className={classes.consentText}>
            If a lockbox is not provided, an adult 18 or older will meet the Pro
            at the property.
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default AccessDetails
