import { Box, Typography } from '@material-ui/core'
import useStyles from '../../../../styles'
import classes from '../styles.module.scss'
import React from 'react'

const PropertyInformationBox = ({
  title,
  level,
  price,
  titleIcon,
}: {
  title: string
  level: React.ReactNode
  price: string
  titleIcon?: React.ReactNode
}) => {
  const styles = useStyles()

  return (
    <Box className={`${styles.containerBox} ${classes.propertyInformationBox}`}>
      <Box display='flex' style={{ gap: '4px' }} alignItems='center'>
        <Typography
          className={styles.nextArtTitle}
          style={{ fontWeight: 700, textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        {titleIcon}
      </Box>
      <Typography
        className={styles.title}
        style={{ color: 'var(--bosscat-black-400)', minHeight: '24px' }}
      >
        {level}
      </Typography>
      <Typography
        className={styles.propertyTitle}
        style={{ color: 'var(--bosscat-green-700)' }}
      >
        {price}
      </Typography>
    </Box>
  )
}

export default PropertyInformationBox
