import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    xlFont: {
      fontSize: '20px',
      fontFamily: 'NextArtNormal',
      fontWeight: 700,
      lineHeight: '32px',
    },
    lgFont: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      lineHeight: '24px',
    },
    mdFont: {
      fontSize: '16px',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      lineHeight: '24px',
    },
    smFont: {
      fontSize: '14px',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    xsFont: {
      fontSize: '12px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      lineHeight: '16px',
    },
    smallPhoto: {
      borderRadius: '8px',
      width: '56px',
      height: '56px',
    },
    largePhoto: {
      borderRadius: '8px',
      width: '205px',
      height: '205px',
    },
    smallIcon: {
      width: '12px',
      height: '12px',
    },
    largeIcon: {
      width: '16px',
      height: '16px',
    },
    contactContainer: {
      borderRadius: '8px',
      backgroundColor: 'var(--bosscat-grey-200)',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      color: 'var(--text-text-info)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      height: '40px',
      width: '100%',
    },
    iconLabel: {
      display: 'flex',
      gap: '4px',
    },
    cardContainer: {
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
    smallCardContainer: {
      padding: '4px',
    },
    largeCardContainer: {
      padding: '16px',
      borderRadius: '12px',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)'
    },
    buttomWrapper: {
      position: 'absolute',
      right: '16px',
      bottom: '16px',
      display: 'flex',
      gap: '8px'
    },
    iconButton: {
      width: '32px',
      minWidth: '32px !important',
      height: '32px',
      borderRadius: '4px',
      backgroundColor: 'white',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      '&:hover': {
        backgroundColor: 'var(--bosscat-grey-200)'
      }
    },
    nextArtLgFont: {
      fontFamily: 'NextArtNormal',
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '24px',
      color: 'var(--text-text-primary)'
    }
  })
)
