import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../../UI/MaterialUI';

export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        CustomerSuccessImage: {
            width: "448px",
            height: "369px",
            objectFit: "cover",
            borderRadius: "8px",
            [theme.breakpoints.down('md')]: {
                width: "345px",
                height: "220px",
            }
        },
        Title: {
            fontSize: "32px",
            fontFamily: "LatoBold",
            marginTop: "16px",
        },
        Position: {
            fontSize: "20px",
            color: "#0E905A",
            fontFamily: "LatoNormal",
            margin: "8px 0",
            textAlign: "center",
        },
        Buttons: {
            width: "100%",
            display: "flex",
            columnGap: "24px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                rowGap: "16px"
            }
        },
        ButtonSec: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FAFBFF",
            width: "100%",
            borderRadius: "8px",
            padding: "16px 32px",
            color: "var(--bosscat-blue-600)",
            cursor: "pointer",
            textDecoration: "none",
            columnGap: "8px",
            transition: "all 350ms ease-in-out",
            '&:hover': {
                boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            },
            [theme.breakpoints.down('md')]: {
                width: "100%",
                borderRadius: "8px",
                columnGap: "8px",
                fontSize: "18px"
            }
        },
        icon: {
            width: '1rem'
        },
        text: {
            fontFamily: "LatoNormal",
        }
    }))
