import React, { FC } from 'react'
import useStyles from './styles'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { Button, Box, Grid, Typography } from '../../../../../UI'
import { BannerProps } from './types'
import { useHistory } from 'react-router-dom'

const BORDER_PROCESS = 'var(--bosscat-green-700)'
const BORDER_ATTENTION = 'var(--renovation-red-600)'
const BUTTON_PROCESS = 'var(--bosscat-green-600)'
const BUTTON_ATTENTION = 'var(--bosscat-blue-600)'

const Banner: FC<BannerProps> = ({bannerProp, jobId}) => {
  const classes = useStyles()
  const { iconSrc, title, description, status } = bannerProp
  const { push } = useHistory()

  const handleGoToJob = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    push(`/p/jobs/${jobId}`)
  }

  return (
    <Grid
      container
      className={classes.banner}
      justifyContent="space-between"
      alignItems="center"
      style={{
        borderColor: status ? BORDER_PROCESS : BORDER_ATTENTION,
      }}
    >
      <Grid
        container
        item
        spacing={2}
        direction="row"
        className={classes.content}
      >
        <img src={iconSrc} alt="In progress icon" />
        <Box alignItems="center" display="flex" justifyContent="center">
          <Box padding={0}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Button
        variant="contained"
        style={{
          backgroundColor: status ? BUTTON_PROCESS : BUTTON_ATTENTION,
          width: '220px',
          height: '56px',
          borderRadius: '8px',
          textTransform: 'none',
          color: 'white',
          alignItems: 'center',
        }}
        className={`${classes.description} ${status && classes.button}`}
        endIcon={<ArrowForward />}
        onClick={handleGoToJob}
      >
        Go to Job
      </Button>
    </Grid>
  )
}

export default Banner
