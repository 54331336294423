import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'linear-gradient(90deg, rgba(19,49,99,1) 0%, rgba(9,25,49,1) 100%)',
            padding: '1rem',
            borderRadius: 8,
            // marginTop: '1rem',
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        title: {
            fontFamily: "LatoBold",
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                fontFamily: 'inherit',
                fontWeight: 700,
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '70%'
            }
        },
        vipMedal: {
            position: 'absolute',
            opacity: '.2',
            right: '-1.5rem',
            top: '-0.8rem',
            width: 80
        },
        link: {
            // color: '#FFC531',
            fontWeight: 700,
            textDecoration: 'underline',
            display: 'flex',
            gap: '.3rem',
            alignItems: 'center',
            textTransform: 'inherit',
            height: 'fit-content',
            padding: '0.5rem 0rem',
        },
        linkIcon: {
            fontSize: '1rem !important'
        }
    })
)
