/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { jobTypes, FetchJobAction, CreateJobAction } from './types'
import { jobActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchJob({ payload, callback }: FetchJobAction): SagaIterator {
  let success = false
  try {
    const job = yield call(Punchlist.jobs.getJobById, payload)

    if (job) {
      yield put(jobActions.setJob(job))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the job'))
    if (callback) yield call(callback, false)
  }
}

export function* createJobs({ payload, callback }: CreateJobAction): SagaIterator {
  let success = false
  try {
    const job = yield call(Punchlist.jobs.createJobs, payload)

    if (job) { success = true }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the job'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(jobTypes.FETCH_JOB, fetchJob)
  yield takeLatest(jobTypes.CREATE_JOB, createJobs)
}
