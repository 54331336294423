import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { icons } from 'assets'
import {
  Button,
  DatePicker,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextFieldLabel,
  Typography
} from 'components/UI'
import { PrivateLoader } from 'components/templates'
import {
  OCCUPANCY_STATUS,
  PHONE_MASK_INPUT,
  isEmpty
} from 'helpers'

import { newInspectionActions } from 'ducks/actions'
import { getNewInspectionValue, getNewInspectionValues } from 'ducks/selectors'
import { useStepperContext } from 'hooks/useStepperContext'

import { NewInspectionType } from 'ducks/newInspection/types'
import moment from 'moment'
import { isAuthorized } from '../../../../../../../../ducks/selectors'
import { history } from '../../../../../../../../helpers'
import useStyles from './styles'
import { toast } from 'react-toastify'

const formatUTCTimestampDate = (timestamp: number | null): Date => {
  if (timestamp !== null) {
    const date: Date = new Date(timestamp)
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    )
  }
  return new Date()
}

const Project = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { showWarnings, goForward, goBack, setCanGoForward, setShowWarnings, saveNewValue } =
    useStepperContext()
  const dispatch = useDispatch()
  const pushParams = history.usePushParams()
  const push = history.usePush()

  const clientType = useSelector(getNewInspectionValue('clientType'))

  const {
    preferredDate,
    homeSize,
    occupancyStatus,
    mainContact,
  } = useSelector(getNewInspectionValues())


  const [phone, setPhone] = useState(mainContact.phone ?? '')

  const validatePhone = !isEmpty(phone) && parseInt(phone?.replaceAll('-', ''))?.toString().length === 10
  const isValidHomeSize = (size: string | number | undefined) => {
    return size ? /^\d{0,9}$/.test(size.toString()) : false; // Validates size with 1 to 9 digits
  };

  const validate: boolean =
    !isEmpty(homeSize?.toString()) && isValidHomeSize(homeSize) &&
    (!isEmpty(preferredDate?.toString()) && preferredDate as number > 0) &&
    !isEmpty(occupancyStatus) &&
    validatePhone

    const updateMainContact = (attr: string, value: any) => {
      dispatch(
        newInspectionActions.setNewInspectionValue({
          attr: 'mainContact',
          value: { ...mainContact, [attr]: value },
        })
      )
    }



  const updateNewInspection = useCallback(
    (attr: keyof NewInspectionType, value: any) => {
      saveNewValue(attr, value)
      dispatch(
        newInspectionActions.setNewInspectionValue({
          attr,
          value,
        })
      )
    },
    [dispatch, saveNewValue]
  )

  useEffect(() => {
    setCanGoForward(validate)
  }, [setCanGoForward, validate])

  useEffect(() => {
    setShowWarnings(false)
  }, [setShowWarnings])

  const handleSelect = (date: any) => {
    if (date.startDate) {
      updateNewInspection('preferredDate', date.startDate.valueOf())
    } else {
      updateNewInspection('preferredDate', date.valueOf())
    }
  }

  const createInspection = () => {
    if (validate) {
      setLoading(true)
      dispatch(
        newInspectionActions.createInspection(
          null,
          (success, inspectionId) => {
            setLoading(false)
            if (success) {
              const mail = mainContact.email
              dispatch(newInspectionActions.cleanNewInspection())
              toast.success('Inspection Requested!')
              push(`inspection/success?email=${mail}`)
            }
            else {
              toast.error('Something went wrong')
            }
          }
        )
      )
    }
  }




  return (
    <PrivateLoader loading={loading} building="fragment">
      <Grid container item spacing={6} className={classes.root} aria-label='assignment-inspection-project'>
        <Grid item xs={12} className={classes.container}>
          <Typography variant="h4" className={classes.title}>
            A FEW MORE QUESTIONS!
          </Typography>
          <Grid container className={classes.containerForm} spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1" className={classes.subtitle}>
                Home Size (Square Footage)
                </Typography>
                <TextFieldLabel
                  error={showWarnings && (isEmpty(homeSize) || !isValidHomeSize(homeSize))}
                  onChange={(ev) => {
                    const inputValue = ev.target.value;
                    const sanitizedInput = inputValue.replace(/[^0-9]/g, ''); // Remove dots
                    if(sanitizedInput === ''){
                      updateNewInspection('homeSize', '');
                    } else{
                      if(sanitizedInput.length > 9) return; // Max length with 9 digits
                      updateNewInspection('homeSize', parseInt(sanitizedInput));
                    }
                  }}
                  type='text'
                  value={homeSize?.toString() || ''}
                  InputProps={{
                    maxLength: 9, // Max length with 9 digits
                    //max: 999999999, // Max value with 9 digits
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1" className={classes.subtitle}>
                Prefered Inspection Date
                </Typography>
                <DatePicker className={classes.datePicker}
                  onChange={handleSelect}
                  value={(preferredDate !== undefined && preferredDate > 0) ? formatUTCTimestampDate(preferredDate) : null}
                  minDate={new Date()}
                />
              </Grid>
        
              
          <Grid item xs={12}>
  
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1" className={classes.subtitle}>
                  Property is
                </Typography>
                <Grid
                  item
                  container
                  style={{ marginTop: '.5rem', gap: '1rem' }}
                >
                  <RadioGroup
                    onChange={(ev) => {
                      updateNewInspection(
                        'occupancyStatus',
                        ev.target.value
                      )
                    }}
                    value={occupancyStatus}
                    className={classes.propertyContract}
                  >
                    <FormControlLabel
                      style={occupancyStatus === OCCUPANCY_STATUS.OCCUPIED ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                      control={
                        <Radio
                          checkedIcon={
                            <icons.CheckBox htmlColor="var(--bosscat-blue-600)" />
                          }
                          icon={<icons.CheckBoxOutlineBlank />}
                        />
                      }
                      checked={occupancyStatus === OCCUPANCY_STATUS.OCCUPIED}
                      value={OCCUPANCY_STATUS.OCCUPIED}
                      label={<Typography variant="body2">Occupied</Typography>}
                      className={classes.check}
                    />
                    <FormControlLabel
                      style={occupancyStatus === OCCUPANCY_STATUS.VACANT ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                      control={
                        <Radio
                          checkedIcon={
                            <icons.CheckBox htmlColor="var(--bosscat-blue-600)" />
                          }
                          icon={<icons.CheckBoxOutlineBlank />}
                        />
                      }
                      checked={occupancyStatus === OCCUPANCY_STATUS.VACANT}
                      value={OCCUPANCY_STATUS.VACANT}
                      label={<Typography variant="body2">Vacant</Typography>}
                      className={classes.check}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1" className={classes.subtitle}>
                  Your Mobile Phone Number
                </Typography>
                <TextFieldLabel
                    type='tel'
                    value={phone?.toString() || ''}
                    mask={PHONE_MASK_INPUT}
                    error={showWarnings && !validatePhone}
                    onChange={(event) => {
                      setPhone(event.target.value)
                      updateMainContact('phone', event.target.value)
                    }}
                    placeholder='555-555-1234'
                />
              </Grid>
            </Grid>
              
            
          </Grid>
        </Grid>
        
        <Grid
          container
          item
          xs={12}
          style={{ display: 'flex', marginTop: '1rem', width: '100%' }}
          justifyContent="space-between"
          className={classes.buttonsContainer}
        >
          <Button
            type="submit"
            variant="outlined"
            size="small"
            className={classes.buttonBack}
            startIcon={<icons.ArrowBack />}
            onClick={goBack}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.buttonNext}
            endIcon={<icons.ArrowForward />}
            disabled={!validate}
            onClick={createInspection}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default Project
