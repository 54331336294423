import { Button, Grid, Typography } from '@material-ui/core'
import { people } from '../../../../../assets'
import { NoResultsProps } from './types'
import { icons } from './../../../../../assets/'

import useStyles from './styles'


const NoResults = ({ setOpen }: NoResultsProps) => {
    const classes = useStyles()

    return (
        <Grid container spacing={2} direction="column" justifyContent='center' alignItems='center' className={classes.root}>
            <Grid className={classes.container}>
                <Grid item>
                    <img src={people} alt="No Results" />
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1' className={classes.title} align="center">No members yet</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' className={classes.subtitle} align="center">Let’s invite your first member by clicking below</Typography>
                </Grid>
                <Grid item>
                    <Button
                        type='submit'
                        variant='outlined'
                        className={`${classes.button} ${classes.addMemberButton}`}
                        onClick={() => setOpen(true)}
                    >
                        Invite new member
                        <icons.Add />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NoResults