import { FC } from 'react'
import { ImagesSelectorProps } from './types'
import useStyles from './styles'
import { ImagePicker } from '../../'
import { toast } from 'react-toastify'
import {
  imageMimeTypes,
  applicationMimeTypes,
} from '../../../../../helpers/files'
import * as Sentry from '@sentry/react'
/**
 *
 * This component allows to upload or delete
 * a lot of images almost at the same time
 *
 * @param submitAction Callback to take the picture's array
 * @param images An array of files passed by props
 * @param disable
 *
 * @returns JSX.Element
 *
 * @example <ImageSelector />
 *
 */

const allowedFilesExtensions = [...imageMimeTypes, ...applicationMimeTypes]

const ImagesSelector: FC<ImagesSelectorProps> = ({
  submitAction = () => {},
  deleteImageFilesAction = () => {},
  images = [],
  imageFiles,
  disabled = false,
  multiple = false,
  canUpload = true,
  className = '',
  allowedFiles = allowedFilesExtensions,
  canDelete = true,
}) => {
  const classes = useStyles()
  const handleUploadImage = (newImage: File[]): void => {
    const newFilesFilter = [...newImage].filter((file) =>
      allowedFiles.includes(file.type)
    )
    const newFilesNotFilter = [...newImage].filter(
      (file) => !allowedFiles.includes(file.type)
    )

    if (newFilesNotFilter.length > 0) {
      let errorMsg = 'File type not supported:'
      newFilesNotFilter.forEach((f, index) => {
        errorMsg = errorMsg + (index === 0 ? ' ' : ', ') + f.name
      })
      toast.error(errorMsg)
    }

    if (newFilesFilter.length > 0) {
      const newImageArr = [
        ...images.filter((i) => i !== null),
        ...newFilesFilter,
      ]
      if (submitAction) submitAction(newImageArr)
    }
  }

  const handleDeleteImage = (index: number) => {
    if (index && index > images.length) return
    const filteredArray = images.filter((_, i) => i !== index)
    if (submitAction) submitAction(filteredArray)
  }

  const randomNumber = () => {
    return Math.floor(Math.random() * 1000 - 1)
  }

  return (
    <div className={`${classes.root} ${className}`}>
      {(!disabled || !canUpload) && (
        <ImagePicker
          handleUploadFile={handleUploadImage}
          disabled={disabled || !submitAction || !canUpload}
          key="empty"
          multiple={multiple}
          allowedFiles={allowedFiles}
        />
      )}
      {imageFiles &&
        imageFiles.length > 0 &&
        imageFiles.map((image, index) => {
          return (
            <>
              <ImagePicker
                key={index}
                imageUrl={image.fileUrl}
                handleDeleteFile={() => {
                  deleteImageFilesAction(index)
                }}
                disabled={disabled}
                multiple={multiple}
                allowedFiles={allowedFiles}
                canDelete={canDelete}
              />
            </>
          )
        })}
      <>
        {images.map((image: any, index) => {
          let img = image
          if (image.fileUrl) {
            img = image.fileUrl
          } else {
            try {
              img = URL.createObjectURL(image)
            } catch (error) {
              Sentry.captureException(error)
            }
          }
          return (
            <ImagePicker
              imageUrl={img}
              handleUploadFile={handleUploadImage}
              handleDeleteFile={() => handleDeleteImage(index)}
              disabled={disabled || !submitAction}
              key={
                image
                  ? image.lastModified + randomNumber()
                  : index + randomNumber()
              }
              multiple={multiple}
              allowedFiles={allowedFiles}
              canDelete={canDelete}
            />
          )
        })}
      </>
    </div>
  )
}

export default ImagesSelector
