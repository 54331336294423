import { FC } from 'react'
import { CircularProgress, LinearProgress } from '../../../'
import { LoaderProps, LOADER_TYPES } from './types'

const Loader: FC<LoaderProps> = ({ type = LOADER_TYPES.CIRCULAR }) => {
  switch (type) {
    case LOADER_TYPES.LINEAR:
      return <LinearProgress />
    default:
      return (
        <CircularProgress
          style={{
            display: 'block',
            margin: '30px auto'
          }}
        />
      )
  }
}

export default Loader
