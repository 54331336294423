import { FC } from 'react'
import { Typography, Box, LinearProgress } from '../../../../../UI'
import useStyles from './styles'
import useStylesBetter from './stylesBetter'
import { ProgressProps } from './types'

const Progress: FC<ProgressProps> = ({ value, total, showBetterStyle = false, hideItems = false, ...rest }) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  return (
    <Box display='flex' alignItems='initial'>
      <Box width='100%' mr={1}>
        <LinearProgress
          {...rest}
          style={{ marginTop: '8px' }}
          classes={{
            root: classes.linearRoot,
            bar: classes.linearColor
          }}
          variant='determinate'
          value={total !== undefined
            ? total > 0 ? (value * 100 / total) : 0
            : value}
        />
      </Box>
      <Box className={classes.value}>
        {total !== undefined
          ? (
            <Typography className={classes.mainFont}>
              {`${value}/${total}`}
            </Typography>)
          : (
            <Typography className={classes.mainFont}>
              {`${Math.round(value)}%`}
            </Typography>)}
      </Box>
      {!hideItems &&
        <Box className={classes.valueText}>
          <Typography className={classes.mainFont}>
            {` Item${total !== 1 ? 's' : ''}`}
          </Typography>
        </Box>}
    </Box>
  )
}

export default Progress
