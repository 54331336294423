/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* global google */
import { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { Grid } from '@material-ui/core'

import {
  Typography,
  Button,
  STextField,
  AutocompleteSearch,
  Box,
} from '../../../../UI'
import { icons, mapPointer, googleMaps } from '../../../../../assets'

import { SelectAddressProps } from './types'
import { useClassName } from '../../../../../hooks'
import { flattenedAddress, isEmpty } from '../../../../../helpers'
import { territoriesActions } from '../../../../../ducks/actions'
import { Address } from '../../../../../ducks/types'

import useStyles from './styles'
import { debounce } from 'lodash'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -100 }
const START_SEARCHING_FROM = 3

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px',
}

const emptyAddress = {
  city: '',
  state: '',
  line_1: '',
  line_2: '',
  zipCode: '',
  latitude: defaultCenter.lat,
  longitude: defaultCenter.lng,
  country: '',
  county: ''
}

const SelectAddress: FC<SelectAddressProps> = ({
  xl = 6,
  showSave = false,
  onChange,
  onSubmit,
  onCallback,
  onAdd,
  onAddressTextChange,
  showWarnings = false,
  showVertical = false,
  showUnit = true,
  edit = false,
  initAdd = '',
  initUnit = '',
  initCoord = defaultCenter,
  customClass = '',
  savedAddress,
  title,
  compact = false,
  placeholder,
  hasCallback = false,
  errorMessage,
  showUnitNumberBesideAddress,
  showMap = true,
  scrollOnFocus,
  unitNumberClassName = "",
  containerClass = "",
  resetOnBlur = true
}) => {
  const classes = useStyles()
  const className = useClassName()
  const dispatch = useDispatch()

  const compRef = useRef<HTMLDivElement>(null)

  const Input = null as any
  const [address, setAddress] = useState<Address>(
    savedAddress?.line_1
      ? savedAddress
      : {
        city: '',
        state: '',
        line_1: '',
        line_2: initUnit,
        zipCode: '',
        latitude: defaultCenter.lat,
        longitude: defaultCenter.lng,
      }
  )
  const mapCountry = (country: string): string => {
    return country === 'United States' ? 'USA' : 'Canada';
  }
  const [addressText, setAddressText] = useState<string>(
    savedAddress?.line_1
      ? `${savedAddress?.line_1}, ${savedAddress?.city}, ${savedAddress?.state}${savedAddress?.zipCode ? " " + savedAddress?.zipCode : ""}, ${mapCountry(savedAddress?.country)}`
      : initAdd
  )

  const [options, setOptions] = useState<any[]>([])
  const [optionCount, setOptionCount] = useState<number>(0)
  const [coordinates, setCoordinates] = useState(
    savedAddress
      ? { lat: savedAddress?.latitude, lng: savedAddress?.longitude }
      : initCoord
  )
  const [openMap, setOpenMap] = useState(true)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${GOOGLE_MAPS_API_KEY}&loading=async`,
  })


  const placesService = useMemo(() => {
    if (window && window.google && window.google.maps && window.google.maps.places) {
      return new window.google.maps.places.PlacesService(document.createElement('div'));
    }
    return undefined;
  }, [window]);

  const autoCompleteService = useMemo(() => {
    if (window && window.google && window.google.maps && window.google.maps.places) {
      return new window.google.maps.places.AutocompleteService();
    }
    return undefined;
  }, [window]);

  const geocoder = useMemo(() => {
    if (window && window.google && window.google.maps) {
      return new window.google.maps.Geocoder();
    }
    return undefined;
  }, [window]);

  const hasCoordinates =
    coordinates?.lat !== defaultCenter?.lat &&
    coordinates?.lng !== defaultCenter?.lng


  useEffect(() => {
    if (!savedAddress) {
      setAddress({
        city: '',
        state: '',
        line_1: '',
        line_2: initUnit,
        zipCode: '',
        latitude: initCoord?.lat,
        longitude: initCoord?.lng,
        country: '',
        county: ''
      })

      setCoordinates(initCoord)
    }
  }, [initAdd])

  useEffect(() => {
    if (onChange) {
      onChange(address)
    }
    !hasCallback && address?.zipCode &&
      dispatch(territoriesActions.fetchTerritory(address?.zipCode, (succ) => onCallback && onCallback(succ)))
  }, [address])

  const fetchData = (value: string) => {
    const request = {
      input: value,
      componentRestrictions: { country: ['us', 'ca'] },
    }

    value && autoCompleteService?.getPlacePredictions(request).then((results) => {
      const { predictions } = results
      let tempOptions: any[] = []
      setOptionCount(predictions.length)
      predictions.forEach((prediction) => {
        placesService?.getDetails(
          {
            placeId: prediction?.place_id,
          },
          (place: any) => {
            if (place !== null && flattenedAddress(place).zipCode) {
              tempOptions = [
                ...tempOptions,
                {
                  id: prediction?.place_id,
                  label: prediction?.description,
                  value: prediction?.description,
                },
              ]
              setOptions(tempOptions)
            }
          }
        )
      })
    })
  }

  const debounceFn = useCallback(debounce(fetchData, 500), [])

  useEffect(() => {
    setAddressText(
      savedAddress?.line_1
        ? `${savedAddress?.line_1}, ${savedAddress?.city}, ${savedAddress?.state}${savedAddress?.zipCode ? " " + savedAddress?.zipCode : ""}, ${mapCountry(savedAddress?.country)}`
        : initAdd
    )
  }, [savedAddress])

  const handleSelectedAddress = (e: any) => {
    if (e) {
      placesService?.getDetails(
        {
          placeId: e?.id,
        },
        (place: any) => {
          const {
            city,
            state,
            zipCode,
            line_1,
            street_number,
            latitude,
            longitude,
            country,
            county,
            fullAddress,
            googleUrl,
          } = flattenedAddress(place)

          setAddressText(e.label)
          setAddress({
            city,
            state: state,
            zipCode,
            line_1: `${street_number || ''} ${line_1 || ''}`,
            line_2: address?.line_2,
            latitude,
            longitude,
            country,
            county,
            fullAddress,
            googleUrl,
          })
          setCoordinates({ lat: latitude, lng: longitude })
        }
      )
    }

  }

  const handleMapClick = async (cords: { lat: number; lng: number }) => {
    geocoder?.geocode(
      {
        location: {
          lat: cords?.lat,
          lng: cords?.lng,
        },
      },
      (results: any, status: any) => {
        const {
          city,
          state,
          zipCode,
          line_1,
          street_number,
          latitude,
          longitude,
          formatted_address,
          country,
          county
        } = flattenedAddress(results[0])

        setAddressText(formatted_address)
        setAddress({
          city,
          state: state,
          zipCode,
          line_1: `${street_number || ''} ${line_1 || ''}`,
          line_2: address?.line_2,
          latitude,
          longitude,
          country,
          county
        })
        setCoordinates(cords)
      }
    )
  }

  const resetData = () => {
    // setAddress(emptyAddress)
    setOptions([])
    setAddressText('')
  }

  const handleSubmit = (): void => {
    if (onSubmit) onSubmit(address)
    resetData()
  }

  const handleInput = (inputAddress: string): void => {
    resetData()
    onAddressTextChange && onAddressTextChange(inputAddress || "")
    setAddressText(inputAddress || "")
    debounceFn(inputAddress || "")
  }

  const handleOnBlur = () => {
    if (isEmpty(address.state) && resetOnBlur) {
      resetData()
    }
  }

  const onMapLoad = (map: google.maps.Map) => {
    if (!savedAddress || savedAddress?.line_1 === '') {
      setCoordinates(defaultCenter)
      if (navigator?.geolocation) {
        navigator?.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            setCoordinates({ lat, lng })
          }
        )
      }
    } else {
      setCoordinates({
        lat: savedAddress?.latitude,
        lng: savedAddress?.longitude,
      })
    }
  }

  const scrollIntoView = () => {
    if (compRef.current) {
      compRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  return (
    <div ref={compRef} onFocus={() => scrollOnFocus && scrollIntoView()} className={containerClass}>
      <Grid container spacing={2} className={customClass}>
        <Grid item xs={showVertical ? 12 : 11} xl={showVertical ? 12 : xl} className={` ${showUnitNumberBesideAddress ? classes.showUnitNumberBesideAddress : ''} ${showVertical ? classes.showVertical : ''}`}>
          <Box className={classes.addressSearch}>
            <Box>
              {title || (
                <Typography variant="subtitle2" className={classes.label}>
                  Property Address
                </Typography>
              )}
              {Input ? (
                <Input />
              ) : (
                <AutocompleteSearch
                  label=""
                  error={showWarnings}
                  options={
                    addressText?.length > START_SEARCHING_FROM ? options : []
                  }
                  optionCount={optionCount}
                  value={edit ? { value: initAdd || "" } : { value: addressText || "" }}
                  valueInput={{ value: addressText || "" }}
                  renderOption={(option) => {
                    return (
                      <Box zIndex={99999999} className={classes.optionContainer}>
                        <img
                          src={mapPointer}
                          className={classes.pointerIcon}
                          alt="map pointer"
                        />
                        <Typography className={classes.mainDirection}>
                          {option.value}
                        </Typography>
                      </Box>
                    )
                  }}
                  onChange={handleSelectedAddress}
                  valueChange={handleInput}
                  popupIcon={
                    <icons.Search
                      className={className(classes.addressSearcherIcon)}
                      aria-label=""
                    />
                  }
                  noOptionText
                  onBlur={handleOnBlur}
                  placeholder={placeholder}
                />
              )}
            </Box>
            {compact && (
              <Button
                onClick={() => setOpenMap(!openMap)}
                variant="outlined"
                className={classes.googleMapsButton}
                endIcon={
                  <img src={googleMaps} alt="" className={classes.iconMap} />
                }
              >
                {openMap ? 'Hide Map' : 'Use Map'}
              </Button>
            )}
          </Box>
          {showUnit && (!compact || (compact && !openMap)) && (
            <Box className={unitNumberClassName}>
              <Typography variant="subtitle2" className={classes.unitNumber}>
                Unit Number
              </Typography>
              <STextField
                value={address.line_2}
                className={classes.comboUnit}
                onChange={(event) =>
                  setAddress({ ...address, line_2: event.target.value })
                }
                placeholder="Unit Number"
              />
            </Box>
          )}
        </Grid>
        {showWarnings && errorMessage && errorMessage}
        {showMap && <Grid item xs={showVertical ? 12 : 11} xl={showVertical ? 12 : xl}>
          {isLoaded && openMap && (
            <GoogleMap
              center={coordinates ? coordinates : { lat: defaultCenter?.lat, lng: defaultCenter?.lng }}
              zoom={hasCoordinates ? 18 : 3}
              mapContainerStyle={containerStyle}
              mapContainerClassName={customClass}
              options={{ clickableIcons: false }}
              // onLoad={!savedAddress.line_1 ? onMapLoad : undefined}
              onLoad={onMapLoad}
              onClick={(e) => {
                if (e?.latLng) {
                  const cords = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  }
                  handleMapClick(cords)
                }
              }}
            >
              {hasCoordinates && <Marker position={coordinates} />}
            </GoogleMap>
          )}
        </Grid>}
        {showSave && (
          <Grid item xs={11}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              className={classes.submit}
              onClick={handleSubmit}
              fullWidth
              endIcon={<icons.Save className={classes.icon} />}
              disabled={
                isEmpty(address.state) ||
                isEmpty(address.city) ||
                isEmpty(address.zipCode) ||
                isEmpty(address.line_1)
              }
            >
              {edit ? 'Update Property Info' : 'Save Property'}
            </Button>
          </Grid>
        )}
        {edit && (
          <Grid item xs={11}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              className={classes.submit}
              onClick={onAdd}
              fullWidth
              endIcon={<icons.Add className={classes.icon} />}
            >
              Add new Property
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default SelectAddress
