import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'



export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '64px',
      width: '64px',
      margin: '0px 2px 0px 15px',
      display: 'flex',
      backgroundColor: 'var(--row-background)',
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      fontFamily: 'LatoNormal',
      '&:hover': {
        backgroundColor: 'var(--row-background)',
        '@global': {
          '.MuiButton-label': {
            '& svg path': {
              fill: 'var(--button-focus-color)',
              transition: 'all 0.2s ease-in-out'
            }
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px',
        margin: '0px 2px 0px 5px'
      },
      '@global': {
        '.MuiButton-label': {
          flexDirection: 'column'
        }
      }
    },
    black: {
      color: 'var(--black-color)'
    },
    blue: {
      '& span': {
        color: 'var(--bosscat-blue-600)',
        transition: 'all 0.2s ease-in-out'
      },
      '&:hover': {
        '& span': {
          color: 'var(--button-focus-color)',
          transition: 'all 0.2s ease-in-out'
        }
      }
    },
    text: {
      fontSize: '16px',
      marginTop: '10px'
    }
  })
)
