import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '.5rem 0',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '1px'
      }
    }
  })
)
