import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoBlack',
      fontWeight: 900,
      lineHeight: '24px',
      margin: '16px 9px 0px 0px'
    },
    unitNumber: {
      fontFamily: 'LatoBlack',
      lineHeight: '24px',
      fontSize: "18px !important",
      color: "#0B060F"
    },
    comboUnit: {
      width: '100%',
      //minHeight: '110px',
      marginTop: '2px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      endIcon: {
        width: '5px',
        height: '5px'
      }
    },
    icon: {
      width: '14px',
      height: '14px'
    },
    addressSearcherIcon: {
      color: theme.palette.grey[400],
      cursor: 'default'
    },
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      marign: '4px 0px !important',
      position: 'relative',
      maxWidth: '300px !important',
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    pointerIcon: {
      width: '48px',
      borderRadius: '100%',
      paddingRight: '8px'
    },
    mainDirection: {
      fontSize: '16px',
      fontFamily: 'Lato',
      paddingRight: '4px',
      width: '90%'
    },
    addressSearch: {
      display: 'grid',
      gridAutoColumns: 'auto min-content',
      gridAutoFlow: 'column',
      gap: '16px',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: "100% !important"
      }
    },
    googleMapsButton: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      minWidth: '150px'
    },
    iconMap: {
      width: '24px',
      [theme.breakpoints.down('sm')]: {
        width: '14px',
      }
    },
    showUnitNumberBesideAddress: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: '1rem',
      '@global': {
        '.MuiBox-root:first-child': {
          width: '66.25%',
          '@global': {
            '.MuiBox-root': {
              width: '100%'
            }
          }
        },
        '.MuiTextField-root ': {
          minHeight: 'fit-content !important'
        }
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        maxWidth: '100%',
        '@global': {
          '.MuiBox-root:first-child': {
            '@global': {
              '.MuiBox-root': {
                width: '100%'
              }
            }
          },
          '.MuiTextField-root ': {
            minHeight: 'fit-content !important',
            width: '100%',
            boxShadow: "0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)",
          }
        },
      }
    },
    showVertical: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '@global': {
          '.MuiBox-root, .MuiFormControl-root': {
            width: '100% !important'
          },
        }
      }
    }
  })
)
