import { FC } from 'react'
import { DaysProps } from './types'
import useStyles from './styles'
import { Typography, Box, Button } from '@material-ui/core'
import { round } from '../../../../../helpers'
import { useClassName, useIsXsScreen } from '../../../../../hooks'

const Days: FC<DaysProps> = ({
  title, date, subtitle, enable, best, price, onClickPay = null, className
}) => {
  const classes = useStyles()
  const classNames = useClassName()
  const [xsScreen] = useIsXsScreen()

  return (
    <Box className={classNames(classes.root, className ?? '')}>
      {!xsScreen && <Typography className={classes.title}> {title} </Typography>}
      {!xsScreen && <Box className={enable ? classes.enabled : classes.disabled}>
        {best ? <Typography className={classes.bestTop}> Best option for you! </Typography> : <div className={classes.top} />}
        <Typography className={classes.date}> {date} </Typography>
        <Typography className={enable ? classes.titleBlack : classes.subtitle}> {subtitle} </Typography>
        {best
          ? <Typography className={classes.best}> The sooner, the better </Typography>
          : <Box className={`${price > 0 ? classes.box : classes.boxFree}`} />}
        {enable &&
          <Button
            type='submit'
            variant='contained'
            size='small'
            className={price > 0 ? classes.pay : classes.free}
            onClick={() => { if (onClickPay) onClickPay() }}
          >
            <Typography> {price > 0 ? `Pay $${round(price, 2)}` : 'Free'} </Typography>
          </Button>}
      </Box>}
      {xsScreen && <Box className={enable ? classes.enabled : classes.disabled}>
        {best ? <Typography className={classes.bestTop}> Best option for you! </Typography> : <div className={classes.top} />}
        <Typography className={enable ? classes.dateEnabled : classes.date}> {title + ' ' + date} </Typography>

        {best && <Box className={classes.bestBox}>
          <Typography className={enable ? classes.titleBlack : classes.subtitle}> {subtitle} </Typography>
          {best && <Typography className={classes.best}> The sooner, the better </Typography>}
        </Box>}

        {!best && <Typography className={enable ? classes.titleBlack : classes.subtitle}> {subtitle} </Typography>}

        {enable &&
          <Button
            type='submit'
            variant='contained'
            size='small'
            className={price > 0 ? classes.pay : classes.free}
            onClick={() => { if (onClickPay) onClickPay() }}
          >
            <Typography> {price > 0 ? `Pay $${round(price, 2)}` : 'Free'} </Typography>
          </Button>}
      </Box>}
    </Box>
  )
}

export default Days
