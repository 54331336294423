import { FC, useEffect } from 'react'
import { Grid } from '../../../../../../../UI'
import useStyles from '../../../styles'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'

import PaymentScreen from '../../../components/PaymentScreen'

const Step4Homeowner: FC = () => {
  const classes = useStyles()
  const { setShowWarnings, setCanGoForward } =
    useStepperContext()

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid className={classes.container} spacing={2}>
      <PaymentScreen />
    </Grid>
  )
}

export default Step4Homeowner
