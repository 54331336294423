import { FC, useEffect, useState } from 'react'
import { Container, CircularProgress, Box, TitleFirst } from '../../../UI'
import useStyles from './styles'
import { useIsSmScreen, useIsXsScreen, useIsMdScreen } from '../../../../hooks'
import { loadingHammer } from '../../../../assets'
import { LoaderProps } from '../types'

const PrivateLoader: FC<LoaderProps> = ({ children, loading = true, building = 'page' }) => {
  const classes = useStyles()

  const [smScreen] = useIsSmScreen()
  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen
  const [dots, setDots] = useState(0)
  const buildingPage = building === 'page'

  useEffect(() => {
    const timer = setInterval(() => {
      setDots((dots + 1) % 4)
    }, 1000)
    return () => { clearInterval(timer) }
  }, [dots])

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Box className={classes.root}>
      {/*  <Box>
        <TitleFirst value={buildingPage ? 'Building' : 'Preparing'} black={buildingPage ? 'this section' : 'the data'} />
        <TitleFirst black='for you' />
      </Box> */}
      {buildingPage
        ? (
          <Box className={classes.center}>
            <img src={loadingHammer} alt='' className={classes.loadingHammer} />
          </Box>)
        : (
          <Box className={classes.center}>
            <CircularProgress disableShrink size={smallScreen ? 56 : 96} thickness={4} />
          </Box>
        )}
      <Box>
        <TitleFirst justifyContent='flex-start !important' black={`Loading${'.'.repeat(dots)}`} />
      </Box>
    </Box>
  )
}

export default PrivateLoader
