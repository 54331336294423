import { Theme, createStyles, makeStyles } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    linearRoot: {
      background: 'rgba(39, 186, 45, 0.08)',
      height: 8,
      borderRadius: 4
    },
    linearColor: {
      backgroundColor: 'var(--better-color)'
    },
    value: {
      '& p': {
        fontFamily: 'BetterSansRegular'
      },
      minWidth: '35px',
      paddingLeft: '4px',
      textAlign: 'end',
      color: 'var(--better-color)',
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '150%',
    },
    mainFont: {
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '150%',
    },
    valueText: {
      '& p': {
        fontFamily: 'BetterSansRegular'
      },
      minWidth: '35px',
      paddingLeft: '12px',
      textAlign: 'end',
      color: 'var(--better-color)',
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '150%',
    }
  })
)
