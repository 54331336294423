import axios from '../axios'
import { ApiController } from './types'

const base = '/discount-banner'

const apiController: ApiController = {
  getDiscountBanner: async () =>
    await axios.get(`${base}/current`).then((response) => response.data),
}

export default apiController
