import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '408px',
      borderRadius: '16px'
    },
    input: {
      width: '100%',
      height: '80px',
      marginTop: '4px',
      paddingLeft: '20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      '& .MuiInput-underline:before': {
        borderBottomColor: '#FFF'
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#FFF'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFF'
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--border-color-focus)',
          borderRadius: 8
        }
      },
      '&.Mui-hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--border-color-focus)',
          borderRadius: 8
        }
      }
    },
    formControl: {
      '& .MuiButtonBase-root': {
        height: 'auto'
      }
    },
    text: {
      fontFamily: 'LatoNormal'
    },
    description: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      lineHeight: '24px'
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'LatoNormal'
    },
    logo: {
      margin: '-70px -50px',
      zIndex: 1500,
      position: 'fixed'
    },
    header: {
      background: 'var(--background-color-secondary)'
    },
    headerText: {
      paddingTop: '36px'
    },
    headerTX: {
      color: 'rgba(114, 116, 141, 1)'
    },
    inputNumber: {
      margin: 0,
      borderRadius: '8px 8px 0 0'
    },
    inputDate: {
      borderTop: '0px',
      borderRight: '0px',
      marginTop: '-1px',
      borderRadius: '0 0 0 8px'
    },
    inputCVV: {
      borderTop: '0px',
      marginTop: '-1px',
      borderRadius: '0 0 8px 0'
    },
    check: {
      display: 'grid',
      justifyContent: 'end'
    },
    checkText: {
      color: 'rgba(114, 116, 141, 1)'
    },
    errorMessage: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: '16px',
      padding: '8px 16px',
      borderRadius: '8px',
      background: 'var(--expired-color-back)'
    },
    errorColor: { borderColor: 'var(--error-color)' },
    errorTopColor: { borderTopColor: 'var(--error-color)' },
    errorBottomColor: { borderBottomColor: 'var(--error-color)' },
    errorLeftColor: { borderLeftColor: 'var(--error-color)' },
    errorRightColor: { borderRightColor: 'var(--error-color)' },
    cancel: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      borderRadius: 8,
      height: '44px',
      fontFamily: 'BetterSansRegular',
      '&:hover': {
        color: 'var(--bosscat-blue-600)',
        backgroundColor: 'var(--white-color)'
      }
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
      marginTop: '10px'
    },
    inputsContainer: {
      display: 'grid',
      gap: '12px'
    },
    inputsRoleContainer: {
      display: 'flex',
      flexDirection:'column',
      gap: '6px'
    },
    flexOne: {
      flex: 1
    }
  })
)
