import auth0 from 'auth0-js'
import env from '@beam-australia/react-env'
import { isEmpty } from '../helpers'
import * as Sentry from '@sentry/react'

const AUTH_PREFIX = env('AUTH_PREFIX') ?? 'pro_app'

const JWT_TOKEN = AUTH_PREFIX.concat(':jwtToken')
const USER_INFO = AUTH_PREFIX.concat(':userInfo')

const AUTH0_DOMAIN = env('AUTH0_DOMAIN') ?? ''
const AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID') ?? ''

export type UserInfoType = {
  sub: string
  nickname: string
  name: string
  picture: string
  'updated_at': string
  email: string
  'email_verified': boolean
}

const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear: (key: string): void | null => {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key)
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key)
    }

    return null
  },

  /**
   * Clear all app storage
   */
  clearAppStorage: (): void => {
    if (localStorage) {
      localStorage.clear()
    }
    if (sessionStorage) {
      sessionStorage.clear()
    }
  },

  clearToken: (tokenKey = JWT_TOKEN): void | null => {
    return auth.clear(tokenKey)
  },

  clearUserInfo: (userInfo = USER_INFO): void | null => {
    return auth.clear(userInfo)
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get: (key: string): string | JSON | null => {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.getItem(key) || null
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.getItem(key) || null
    }

    return null
  },

  getToken: (tokenKey = JWT_TOKEN): string | null => {
    return auth.get(tokenKey) as string
  },

  getUserInfo: (userInfo = USER_INFO): UserInfoType | null => {
    return !isEmpty(auth.get(userInfo))
      ? JSON.parse(auth.get(userInfo) as string)
      : null
  },

  /**
   * Set data in storage
   * @param {String|Object} value The data to store
   * @param {String} key
   * @param {Boolean} isLocalStorage Defines if we need to store in localStorage or sessionStorage
   */
  set: (value: string, key: string, isLocalStorage: boolean): void | null => {
    if (isEmpty(value)) {
      return null
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, value)
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, value)
    }

    return null
  },

  setToken: (value = '', isLocalStorage = false, tokenKey = JWT_TOKEN): void | null => {
    return auth.set(value, tokenKey, isLocalStorage)
  },

  setUserInfo: (value = '', isLocalStorage = false, userInfo = USER_INFO): void | null => {
    return auth.set(value, userInfo, isLocalStorage)
  },

  /** Auth0 WebAuth Methods ***************/
  getWebAuth: (): auth0.WebAuth => {
    const webAuth = new auth0.WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      responseType: 'token id_token',
      redirectUri: window.location.origin
    })
    // webAuth.crossOriginVerification()
    return webAuth
  },
}

export default auth
