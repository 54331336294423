import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles<Theme, { withLogo: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100%',
      gridAutoRows: 'min-content auto',
    },
    rootButtons: {
      display: 'grid',
      height: '100%',
      gridAutoRows: 'min-content auto min-content',
      // backgroundColor: 'blue'
    },
    stepper: {
      display: 'flex',
      maxWidth: '750px',
      backgroundColor: 'var(--border-color-focus)',
      borderRadius: 32,
      padding: 3,
      gap: 2,

      '@global': {
        '.MuiStepConnector-line': {
          display: 'none',
        },
      },
      // minWidth: (props) => (props.withLogo ? '50%' : '40%'),
      '& .MuiButtonBase-root': {
        zIndex: 1,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center !important',
        },
        [theme.breakpoints.up('sm')]: {
          justifyContent: (props) => (props.withLogo ? 'right' : 'left'),
        },
      },
      '& .MuiStepConnector-root': {
        [theme.breakpoints.down('sm')]: {
          top: -4,
          left: '-50%  !important',
          right: '50%  !important',
        },
        [theme.breakpoints.up('sm')]: {
          top: 0,
          left: (props) => (props.withLogo ? '-32%' : '-93%'),
          right: (props) => (props.withLogo ? '25%' : '100%'),
        },
      },
      '& .MuiStepButton-root': {
        [theme.breakpoints.down('sm')]: {
          margin: '-24px 0px !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        // minWidth: '100%',
        // padding: '36px 0px 32px 0px',
        // maxWidth: '100%',
        // marginLeft: '-24px !important',
      },
    },
    stepperOnboard: {
      display: 'flex',
      padding: '32px 8px',
      maxWidth: '750px',
      minWidth: (props) => (props.withLogo ? '50%' : '40%'),
      '& .MuiButtonBase-root': {
        zIndex: 1,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center !important',
        },
        [theme.breakpoints.up('sm')]: {
          justifyContent: (props) => (props.withLogo ? 'right' : 'left'),
        },
      },
      '& .MuiStepConnector-root': {
        [theme.breakpoints.down('sm')]: {
          top: -4,
          left: '-50%  !important',
          right: '50%  !important',
        },
        [theme.breakpoints.up('sm')]: {
          top: 0,
          left: (props) => (props.withLogo ? '-32%' : '-93%'),
          right: (props) => (props.withLogo ? '25%' : '100%'),
        },
      },
      '& .MuiStepButton-root': {
        [theme.breakpoints.down('sm')]: {
          margin: '-24px 0px !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        padding: '36px 0px 32px 0px',
        maxWidth: '100%',
        marginLeft: '-20% !important',
      },
    },

    step: {
      height: 12,
      width: 77,
      borderRadius: 32,
      margin: '0',
      backgroundColor: 'white',
      padding: '0',
    },

    activeStep: {
      backgroundColor: 'var(--bosscat-green-600)',
    },

    stepIcon: {
      display: 'flex',
      backgroundColor: '#eee',
      marginBottom: '6px',
      width: 42,
      height: 42,
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 36,
        height: 36,
      },
    },
    completedStep: {
      zIndex: 1,
      color: '#FFF',
      backgroundColor: 'var(--bosscat-blue-600)',
    },
    controls: {
      display: 'grid',
      gridAutoFlow: 'column',
      padding: '20px 0',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '.7rem',
        padding: '20px',
      },
    },
    button: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoBold',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        // width: '135px',
        minHeight: '50px',
        margin: '0px 16px 0px 0px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px',
      },
    },
    buttonBig: {
      width: '100%',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      padding: '2rem',
      '@global': {
        '.MuiButton-label': {
          padding: '0',
        },
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '50px',
        margin: '0px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px',
      },
    },
    buttonReverse: {
      width: '185px',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px',
      },
    },
    withLogo: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: '60px',
      },
    },
    counterContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '12px',
      },
    },
    counter: {
      color: 'var(--bosscat-blue-600)',
    },
    counterButtonsContainer: {
      display: 'flex',
      gap: '1rem',
    },
    counterStep: {
      background: '#FAFBFF',
      borderRadius: '100%',
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 2px',
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
    },
    counterButton: {
      background: 'var(--bosscat-blue-600)',
      borderRadius: '100%',
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 2px',
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      transition: 'opacity 350ms ease-in-out',
      '&:hover': {
        background: 'var(--bosscat-blue-600) !important',
      },
      '&:focus': {
        background: 'var(--bosscat-blue-600) !important',
      },
      '&:active': {
        background: 'var(--bosscat-blue-600) !important',
      },
    },
    logoAndProgress: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    progressContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    progressBarContainer: {
      width: '100%',
      marginRight: '1rem',
      backgroundColor: '#F5F6F7',
      borderRadius: 8,
      height: 8,
      position: 'relative',
      maxWidth: 200,
    },
    progressBarFill: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      borderRadius: 8,
      backgroundColor: '#01AA59',
    },
    progressBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      maxWidth: 'fit-content',
    },
    progressSteps: {
      color: '#01AA59',
      fontFamily: 'LatoBold',
      fontSize: 12,
    },
    stepperContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: {
        margin: '16px',
      },
      '@global': {
        '.MuiMobileStepper-dots': {
          backgroundColor: 'var(--border-color-focus)',
          borderRadius: 32,
          padding: 2,
          gap: 2,
        },
        '.MuiMobileStepper-dot': {
          height: 12,
          width: 77,
          borderRadius: 32,
          margin: '0',
          backgroundColor: 'white',
        },
        '.MuiMobileStepper-dotActive': {
          backgroundColor: 'var(--bosscat-green-600)',
        },
      },
    },
    stepperLabel: {
      color: 'var(--bosscat-green-800)',
      fontFamily: 'LatoBold',
      marginRight: '.5rem',
      fontSize: '16px',
    },
  })
)
