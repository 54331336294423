import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    cart: {
      minWidth: 600,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
      }
    },
    bold: {
      fontWeight: 700,
    },
    gray: {
      color: '#6D6A6F'
    },
    header: {
      display: 'flex',
      gap: '.5rem',
      padding: '0 .5rem',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      }
    },
    promoCodeButton: {
      color: '#2F7BF7',
      textTransform: 'inherit',
      fontWeight: 700,
      height: 'fit-content',
      lineHeight: 1.5,
      fontSize: 16,
      padding: 0
    },
    itemSubtitle: {
      fontSize: 12,
      color: '#6D6A6F',
      marginRight: '1rem'
    },
    successPromoCodeContainer: {
      width: 'fit-content'
    },
    showPromoCode: {
      color: "#2F7BF7",
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: 900,
      lineHeight: "24px",
      wordWrap: "break-word",
      cursor: "pointer",
    },
    promoSuccessIcon: {
      fill: '#17B975',
      marginRight: '.5rem',
      width: '1rem'
    },
    promoSuccessText: {
      color: '#0E905A',
      fontWeight: 700
    },
    row: {

    },
    title: {
      fontSize: 20,
      fontFamily: 'NextArtHeavy'
    },
    subtitle: {
      color: '#6D6A6F'
    },
    savedForLater: {
      color: '#2F7BF7'
    },
    dontForgetTitle: {
      color: '#01AA59',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      '@global': {
        '.MuiSvgIcon-root': {
          fill: '#FFC531'
        }
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    submitButton: {
      height: '54px',
      padding: '0 1rem',
      color: 'var(--white-color)',
      // backgroundColor: 'var(--repairs-yellow-500)',
      backgroundColor: '#01AA59 !important',
      borderRadius: 8,
      textTransform: 'inherit',
      margin: '5px',
      maxWidth: 'fit-content',
      '&:hover': {
        backgroundColor: 'var(--bosscat-green-700) !important',
      },
      '&.Mui-disabled': {
        opacity: .6,
        color: 'white'
      },
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
        }
      },
      endIcon: {
        width: '5px',
        height: '5px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: '100%',
        margin: 0
        // height: '40px'
      }
    },
    promoCode: {
      padding: '.2rem'
    },
    footer: {
      display: 'flex',
      height: 'fit-content',
      padding: '.5rem',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      background: 'white',
      [theme.breakpoints.down('sm')]: {
        padding: '.5rem',
        paddingLeft: 4,
        // height: '200px',
        bottom: 'env(safe-area-inset-bottom)',
        // position: 'fixed',
        flexDirection: 'column',
        width: '100%',
        zIndex: 9999,
        marginBottom: '5rem'
      }
    },
    footerContent: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    /*     relativeContainer: {
          minHeight: '70vh',
          [theme.breakpoints.down('sm')]: {
            minHeight: 'inherit',
            height: '70vh',
            position: 'static',
            // minHeight: 'fit-content'
          }
        }, */

    relativeContainer: {
      minHeight: '70vh',
      [theme.breakpoints.down('sm')]: {
        minHeight: '100%',
      }
    },
    showPriceDetail: {
      [theme.breakpoints.down('sm')]: {
        height: '60vh',
      }
    },
    priceDetailContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        // marginBottom: '4rem',
        // position: 'fixed',
        bottom: '0',
        width: '100%',
        height: 'fit-content'
      }
    },
    absoluteContainer: {
      /*  display: 'flex',
       justifyContent: 'space-between',
       flexDirection: 'column', */
      [theme.breakpoints.down('sm')]: {
        // minHeight: '55vh',
        position: 'static',
        // marginBottom: '3rem'
      }
    },
    buttonAdd: {
      minWidth: '86px',
      height: '48px',
      textTransform: 'none',
      color: '#01AA59',
      fontWeight: 700,
      backgroundColor: '#E6F7EE',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: '#E6F7EE',
      }
    },
    spinner: {
      display: 'flex',
      justifyContent: 'center',
      margin: '3rem'
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '.5rem'
    },
    itemsContainer: {
      gap: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '.5rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0 !important'
      }
    },
    cartItem: {
      // gridTemplateColumns: '1fr 0.2fr !important', 
      gridColumnGap: '0',
      [theme.breakpoints.down('sm')]: {
        gap: '.5rem',
        gridTemplateAreas: "'itemTitle price' 'quantity .'",
      }
    },
    buttonRemove: {
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      backgroundColor: 'white !important',
      color: '#0B060F',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      minWidth: 86,
      height: 48,
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    buttonSaveForLater: {
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      backgroundColor: 'white !important',
      color: '#2F7BF7',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      minWidth: 86,
      height: 48,
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)'
      }
    },
    buttons: {
      flexDirection: 'row-reverse'
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    buttonContactUs: {
      color: '#2F7BF7',
      textDecoration: 'underline',
      fontWeight: '700 !important' as unknown as number,
      cursor: 'pointer'
    },
  })
)
