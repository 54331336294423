import { PropertyActivity } from 'ducks/types'

/** TYPES **/
export const propertyActivityType = {
  FETCH_PROPERTY_ACTIVITIES: 'FETCH_PROPERTY_ACTIVITIES',
  FETCH_PROPERTIES_ACTIVITIES: 'FETCH_PROPERTIES_ACTIVITIES',
  SET_PROPERTY_ACTIVITIES: 'SET_PROPERTY_ACTIVITIES',
}

/** ACTIONS **/
export interface FetchPropertyActivityAction {
  type: typeof propertyActivityType.FETCH_PROPERTY_ACTIVITIES
  payload: { id: string }
  callback?: (succ: boolean) => void
}

export interface FetchPropertiesActivityAction {
  type: typeof propertyActivityType.FETCH_PROPERTIES_ACTIVITIES
  payload: { }
  callback?: (succ: boolean) => void
}

export interface SetPropertyActivityAction {
  type: typeof propertyActivityType.SET_PROPERTY_ACTIVITIES
  payload: PropertyActivityType
}

export type PropertyActivityActions =
  | FetchPropertyActivityAction
  | FetchPropertiesActivityAction
  | SetPropertyActivityAction

/** REDUCER **/
export type PropertyActivityType = PropertyActivity
