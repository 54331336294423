import { Theme as ThemeMUI } from "@material-ui/core";
import { createStyles, makeStyles } from '../../../../UI/MaterialUI';


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        Container: {
            display: "flex",
            flexDirection: "column",
            padding: "17px 0px",
            minHeight: "97vh",
            overflowY: "scroll",
            [theme.breakpoints.down('md')]: {
                padding: "20px 0 16px 16px",
                maxHeight: "none",
                marginRight: '16px !important'
            },
        },
        Title: {
            fontSize: "32px",
            fontFamily: "LatoBold",
            marginBottom: "32px"
        },
        GiftsWrapper: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            rowGap: "24px",
            columnGap: "24px",
            padding: "0px 4px"
        },
        GiftItem: {
            width: "48%",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            },
        },
        Header: {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "24px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                alignItems: "center"
            },
        },
        Icon: {
            color: "var(--bosscat-blue-600)"
        },
        Search: {
            maxWidth: "48%",
            [theme.breakpoints.down('md')]: {
                maxWidth: "100%",
                width: "100%",
                marginTop: "24px"
            },
        },
        Tab: {
            color: "var(--bosscat-blue-600)",
            opacity: 1,
            border: "1px solid var(--bosscat-blue-600)",
            borderRadius: "0px 8px 8px 0px",
            '&:first-child': {
                borderRadius: "8px 0px 0px 8px",
            },
            '&.Mui-selected': {
                color: "#fff",
            },
        },
        VipContainer: {
            display: "flex",
            alignItems: "center",
            padding: "16px",
            columnGap: "24px",
            background: "linear-gradient(90.7deg, #263E7D 0%, #1A2A55 100%)",
            borderRadius: "8px",
            width: "100%",
            marginBottom: "24px",
            [theme.breakpoints.down('md')]: {
                padding: "12px 9px",
                columnGap: "10px",
                flexWrap: "wrap"
            },
        },
        VipMedal: {
            width: "80px",
            height: "90px",
            [theme.breakpoints.down('md')]: {
                width: "22px",
                height: "24px",
            },
        },
        VipTitle: {
            fontFamily: 'LatoNormal',
            fontWeight: 500,
            fontSize: "22px",
            color: "#FFC633",
            lineHeight: "58px",
            [theme.breakpoints.down('md')]: {
                lineHeight: "21px",
                fontSize: "14px",
                fontFamily: "LatoNormal",
            },
        },
        VipSubtitle: {
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            color: "#FFC633",
            columnGap: "8px"
        },
        VipLink: {
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            color: "#FFC633",
            textTransform: 'initial',
            textDecoration: 'underline',
            fontFamily: 'LatoNormal',
            [theme.breakpoints.down('md')]: {
                width: "100%",
                justifyContent: "flex-end",
                fontSize: "12px",
                marginTop: "8px"
            },
        },
        Arrow: {
            transform: "rotate(45deg)",
            color: "#FFC633",
        },
        FirstTimeMedal: {
            width: "158px",
            height: "180px",
            [theme.breakpoints.down('md')]: {
                width: "124px",
                height: "140px",
            },
        },
        FirstTimeWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#F9FAFA",
            [theme.breakpoints.down('md')]: {
                padding: "32px 16px",
                marginTop: "48px"
            },
        },
        FirstTimeTitle: {
            fontSize: "30px",
            fontFamily: 'LatoNormal',
            fontWeight: 500,
            textAlign: "center",
            margin: "24px 0 16px",
            [theme.breakpoints.down('md')]: {
                fontSize: "22px",
            },
        },
        FirstTimeSubtitle: {
            fontSize: "20px",
            textAlign: "center",
            [theme.breakpoints.down('md')]: {
                fontSize: "18px",
            },
        },
        modal: {
            '@global': {
                '.MuiPaper-root': {
                    width: '100%',
                    height: '100%'
                }
            }
        },
        inputAutocomplete: {

        },
        inputAuto: {
            height: "42px",
            "@global": {
                input: {
                    fontFamily: "LatoLight",
                    marginTop: "2.5px",
                    opacity: 0.5
                }
            }
        },
        search: {
            display: "flex",
            alignItems: "center",
            width: "48%",
            gap: "1rem"
        }
    }))
