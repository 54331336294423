import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
    createStyles({
        title: {
            // fontFamily: "NextArtHeavy",
            fontSize: "18px",
            color: "var(--bosscat-black-600)",
        },
        address: {
            fontSize: "20px",
            color: "var(--hardware-haze-700)",
            lineHeight: 1.2,
            fontWeight: 900,
            fontFamily: 'LatoBlack'
        },
        imgContainer: {
            position: "relative",
            // marginBottom: "1rem"
        },
        text: {
            padding: '0',
            fontFamily: 'NextArtHeavy',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
                textAlign: 'center',
                marginBottom: '.5rem'
            }
        },
        propertyImg: {
            width: "100%",
            maxHeight: "132px",
            borderRadius: "8px",
            objectFit: "cover",
            border: "2px var(--border-color) solid",
            boxShadow: "0px 0px 1px rgba(9, 25, 49, 0.31)"
        },
        dialogButtons: {
            position: "absolute",
            zIndex: 10,
            top: "112.5%",
            left: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem",
            gap: "1rem",
            backgroundColor: "var(--bosscat-blue-600)",
            border: "2px solid var(--border-color)",
            boxShadow: "0px 0px 5px rgba(9, 25, 49, 0.31)",
            borderRadius: "8px",
            [theme.breakpoints.down("sm")]: {
                left: "-230%",
            }
        },
        dialogText: {
            fontFamily: "LatoBold",
            fontSize: "14px",
            color: "white",
            textAlign: "center",
            textTransform: "none",
        },
        container: {
            padding: "1rem",
            // height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',

            [theme.breakpoints.down("md")]: {
                borderBottom: "1px solid var(--border-color)",
                gridTemplateAreas: '"header" "banner" "buttons"'
            }
        },
        flexWrap: {
            flexWrap: 'wrap',
            '@global': {
                '.MuiBox-root': {
                    flex: 1,
                    width: '100%'
                }
            },
        },
        buttonRow: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            // marginTop: "1rem",
            width: "100%",
            justifyContent: 'space-between',
            [theme.breakpoints.down("md")]: {
                justifyContent: "center",
                width: 'fit-content'
            }
        },
        actionButtonMobile: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "var(--bosscat-grey-200)",
            boxShadow: "0px 0px 1px rgba(9, 25, 49, 0.31)",
        },
        actionButton: {
            display: "flex",
            padding: "8px",
            borderRadius: "8px",
            backgroundColor: "var(--bosscat-grey-200)",
            boxShadow: "0px 0px 1px rgba(9, 25, 49, 0.31)",
            height: "auto",
            width: "100%",
            textAlign: 'left',
            // minHeight: '80px',
            '@global': {
                ".MuiButton-label": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "flex-start",
                }
            },
            [theme.breakpoints.down(900)]: {
                minWidth: 'fit-content',
                borderRadius: '100%',
                boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)'

            }
        },
        actionButtonWithText: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "32px",
            gap: "8px",
            backgroundColor: "var(--bosscat-grey-200)",
            boxShadow: "0px 0px 1px rgba(9, 25, 49, 0.31)",
        },
        actionButtonText: {
            fontSize: "12px",
            fontFamily: "Lato",
            textTransform: "none",
            color: "var(--bosscat-grey-1000)",
            [theme.breakpoints.down(990)]: {
                display: 'none'
            }
        },
        infoRow: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            // marginTop: "1rem",
            width: "100%"
        },
        infoLabel: {
            fontFamily: "LatoBlack",
            fontWeight: 900,
            fontSize: "16px",
            color: "var(--bosscat-black-600)"
        },
        infoValue: {
            fontFamily: "Lato",
            fontSize: "16px",
            color: "var(--bosscat-black-600)"
        },
        goldButton: {
            backgroundColor: "var(--hardware-haze-100)",
            color: "var(--hardware-haze-600)",
            borderRadius: "32px",
            padding: "0 0.5rem",
            boxShadow: "0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)",
            width: '100%',
            height: 48,
            transition: '300ms all',
            '&:hover': {
                backgroundColor: 'var(--hardware-haze-100)',
                boxShadow: '2px 3px 7px 0px rgba(9, 25, 49, .2), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
                transition: '300ms all'
            },
            '&:active': {
                backgroundColor: 'var(--hardware-haze-100)',
                boxShadow: 'none',
                transition: '300ms all'
            },
            '@global': {
                '.MuiButton-label': {
                    [theme.breakpoints.down('md')]: {
                        padding: '8px !important'
                    },
                }
            },
            [theme.breakpoints.down(990)]: {
                minWidth: 'fit-content',
                width: 64,
                height: 64,
                '@global': {
                    '.MuiButton-endIcon': {
                        marginLeft: '0'
                    }
                }
            },
            [theme.breakpoints.down('md')]: {
                color: '#0B060F',
                height: 'fit-content',
                width: '100%',
            },

        },
        goldButtonText: {
            color: "var(--hardware-haze-600)",
            fontFamily: "LatoBold",
            textTransform: "none",
            marginRight: '.4rem',
            [theme.breakpoints.down('md')]: {
                color: '#0B060F',
            },
        },
        /*  modal: {
             '@global': {
                 '.MuiPaper-root': {
                     minHeight: '80vh',
                     height: 'calc(100% - 64px)',
                     maxWidth: '520px !important'
                 },
             }
         }, */
        modal: {
            '@global': {
                '.MuiPaper-root': {
                    height: 'fit-content',
                    paddingBottom: '1rem',
                    maxWidth: '520px !important'
                },
            }
        },
        headerMobile: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                gridArea: 'header'
            }
        },
        topInfoMobile: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
        },
        addressTitle: {
            fontFamily: "NextArtHeavy",
            fontSize: "20px",
            color: "var(--bosscat-black-600)",
            textAlign: "center",
        },
        addressMobile: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
            maxWidth: "70%"
        },
        addressSubtitle: {
            fontFamily: "LatoBold",
            fontSize: "12px",
            color: "var(--bosscat-black-400)",
            textAlign: "center",
        },
        infoButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor: "var(--bosscat-grey-200)",
            boxShadow: "0px 0px 1px rgba(9, 25, 49, 0.31)",
            [theme.breakpoints.up("md")]: {
                width: 'fit-content',
                height: 'fit-content'
            },

        },
        video: {
            border: '1px solid var(--gray-color)',
            padding: '4px',
            margin: '8px 0',
            borderRadius: '16px',
            maxWidth: '650px',
            width: '650px',
            height: '500px'
        },
        marketingImg: {
            borderRadius: '8px',
            border: '2px solid var(--bosscat-grey-400)',
            boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },

        marketContent: {
            display: 'flex',
            // borderRadius: '8px',
            backgroundColor: 'var(--bosscat-grey-200)',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '2px solid var(--bosscat-grey-400)',
            flexGrow: 1,
            // padding: '86px 21px',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        marketText: {
            color: 'var(--bosscat-black-400)',
            textAlign: 'center',
            fontFamily: 'NextArtNormal',
            fontSize: '28px',
            fontWeight: 900,
            lineHeight: '40px',
        },
        buttonsContainer: {
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'row-reverse',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gridArea: 'buttons'
            }
        },
        discountBannerContainer: {
            gridArea: 'banner'
        },
        howCanWeHelp: {
            [theme.breakpoints.down('md')]: {
                width: 'fit-content',
            }
        },
        estimateInfoModal: {
            '@global': {
                '.MuiDialog-container': {
                    alignItems: 'flex-end'
                },
                '.MuiButtonBase-root': {
                    height: 'fit-content',
                },
                '.MuiGrid-root': {
                    minHeight: 'fit-content'
                },
                '.MuiPaper-root': {
                    margin: 0,
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                        minHeight: '70vh'
                    }
                }
            }
        }
    })
)
