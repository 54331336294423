import { FC } from "react"
import { SUB_OPTIONS } from "../.."
import { Box, Button, Typography } from "@material-ui/core"
import useStyles from './styles'
import { estimateNotepad, expert, icons } from 'assets'


interface GeneralProps {
  setSubActiveOption: (option: SUB_OPTIONS) => void
}


const General: FC<GeneralProps> = ({ setSubActiveOption }) => {
  const styles = useStyles()

  const handleClick = (subOption: SUB_OPTIONS) => {
    setSubActiveOption(subOption)
  }

  return (
    <Box width={"100%"} display="flex" flexDirection="column" gridGap="16px" className={styles.root}>
      <Typography variant="h5" className={styles.text}>
        speak with an expert
      </Typography>
      <Box width={"100%"} display="flex" gridGap="16px" className={styles.container}>
        <Box className={styles.box} onClick={() => handleClick(SUB_OPTIONS.general)}>
          <Button className={styles.radioButton}>
            <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />
          </Button>
          <img src={expert} alt="FAQ" />
          <Typography className={styles.title}>General Questions</Typography>
          <Typography className={styles.subtitle}>Questions about scheduling, payment terms, how to navigate your estimate, and the BOSSCAT process.</Typography>
        </Box>
        <Box className={styles.box} onClick={() => handleClick(SUB_OPTIONS.expert)}>
          <Button className={styles.radioButton}>
            <icons.RadioButtonUnchecked htmlColor='#F5F6F7' />
          </Button>
          <img src={estimateNotepad} alt="FAQ" className={styles.optionImg} />
          <Typography className={styles.title}>Questions about your Estimate</Typography>
          <Typography className={styles.subtitle}>Questions about pricing and items in your estimate.</Typography>
        </Box>
      </Box>
      <Button
        onClick={() => setSubActiveOption(SUB_OPTIONS.initial)}
        className={styles.button}
        startIcon={<icons.ArrowBack />}
      >
        Back
      </Button>
    </Box>
  )
}

export default General