import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from './styles'
import { FC } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { useSelector } from 'react-redux'
import { getApprovalValues } from 'ducks/selectors'
import Contact from '../Contact'


export interface HomeownerInformationProps {}

const HomeownerInformation: FC<HomeownerInformationProps> = () => {
  const classes = useStyles()

  const { saveNewValue } = useStepperContext()
  const { homeowner } = useSelector(getApprovalValues())

  return (
    <>
      <Grid item className={classes.homeownerInformation}>
        <Typography className={classes.titleWithoutMarging}>
          📞 homeowner contact information
        </Typography>
        <Typography className={classes.subtitle}>
          Please confirm and update as needed.
        </Typography>
        <Grid item>
          <Contact
            contact={homeowner}
            style={{
              marginBottom: '2rem',
            }}
            onChange={(key, val) => {
              saveNewValue('homeowner', {
                ...homeowner,
                [key]: val,
              })
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default HomeownerInformation
