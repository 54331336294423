import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    orderSummaryContainer: {
      backgroundColor: '#F9FAFA',
      padding: '0 1rem',
      borderRadius: '8px'
    },
    orderSummaryTitle: {
      color: '#494A5A',
      fontFamily: 'LatoNormal'
    },
    bold: {
      fontFamily: 'LatoBold'
    },
    plan: {
      fontSize: '18px'
    },
    box: {
      padding: '0.5rem 0',
      margin: 0
    },
    padding: {
      padding: '1rem 0'
    },
    gift: {
      color: '#6E5BE8'
    },
    promoCode: {
      flex: 1
    },
    giftIcon: {
      fill: '#6E5BE8',
      marginRight: '.5rem',
      width: '1rem'
    },
    subscriptionPrice: {
      color: 'var(--head-text-color)',
    },
    subscriptionPriceHasPromo: {
      fontFamily: 'LatoNormal',
      textDecoration: 'line-through'
    },
    promoPrice: {
      color: 'var(--bosscat-blue-600)',
    },
    spinner: {
      margin: '1rem',
      display: 'flex',
      justifyContent: 'center'
    }
  })
)
