import { useState } from 'react'
import useStyles from './styles'
import { Box, Grid, Typography } from '@material-ui/core'

const CostTypeRadio = ({ costType, setCostType }: { costType: string, setCostType: (newValue: string) => void }) => {
  const styles = useStyles()

  const costTypeOptions = [
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Better',
      value: 'Better',
    },
    {
      label: 'Best',
      value: 'Best',
    },
  ]
  const getOptionStyle = (option: string) => {
    if (option === 'Good') {
      return styles.selectedGoodCostTypeOption
    } else if (option === 'Better') {
      return styles.selectedBetterCostTypeOption
    } else {
      return styles.selectedBestCostTypeOption
    }
  }

  const handleOptionChange = (newOption: string) => {
    setCostType(newOption)
  }

  return (
    <Box className={styles.containerBox} width="158px" marginTop="4px">
      <Grid container>
        {costTypeOptions.map((option) => (
          <Grid
            item
            onClick={() => handleOptionChange(option.value)}
            xs
            container
            justifyContent="center"
            alignItems="center"
            className={
              option.value === costType
                ? getOptionStyle(option.value)
                : styles.unSelectedCostTypeOption
            }
          >
            <Typography className={styles.description}>
              {option.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CostTypeRadio
