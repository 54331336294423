import { CircularProgress, Grid, Typography } from "@material-ui/core"
import PromotionalCode from "../PromotionalCode"
import useStyles from './styles'

import { icons } from 'assets'
import { getNewOrderMaintenanceValue } from "ducks/selectors"
import { isEmpty, round } from "lodash"
import { useSelector } from "react-redux"

const OrderSummary = () => {
  const classes = useStyles()

  const homeowner = useSelector(getNewOrderMaintenanceValue('homeowner'))
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))
  const price = useSelector(getNewOrderMaintenanceValue('price'))
  const promo = useSelector(getNewOrderMaintenanceValue('promo'))
  const protectPlan = useSelector(getNewOrderMaintenanceValue('protectPlan'))
  const priceLoading = useSelector(getNewOrderMaintenanceValue('priceLoading'))

  return (
    <Grid container item className={classes.orderSummaryContainer} xs={12} direction="column">
      <Grid item className={classes.box}>
        <Typography className={classes.orderSummaryTitle}>Order Summary</Typography>
        <Typography className={`${classes.bold} ${classes.plan}`}>VIP Maintenance Membership {gift ? '(Gift)' : ''}</Typography>
      </Grid>
      {gift &&
        <Grid item container className={classes.box}>
          <icons.CardGiftcard className={classes.giftIcon} />
          <Typography className={` ${classes.gift}`}>Gift for {homeowner?.firstName} {homeowner?.lastName}</Typography>
        </Grid>
      }
      <Grid item container className={`${classes.box}`} spacing={2}>
        <PromotionalCode />
      </Grid>
      {!priceLoading ? <Grid item container direction="column" className={`${classes.padding}`} spacing={1}>
        <Grid item>
          <Typography className={`${classes.subscriptionPrice} ${!isEmpty(promo) ? classes.subscriptionPriceHasPromo : ''} ${classes.bold}`}>
            {protectPlan === "PLAN_A" ? 'Yearly' : 'Quarterly'} Membership: ${round(price, 2)}/{protectPlan === "PLAN_A" ? 'yearly' : 'quarterly'}
          </Typography>
        </Grid>
        {!isEmpty(promo) &&
          <Grid item>
            <Typography className={`${classes.subscriptionPrice} ${!isEmpty(promo) ? classes.promoPrice : ''}  ${classes.bold}`}>
              {protectPlan === "PLAN_A" ? 'Yearly' : 'Quarterly'} Membership: ${round(price - promo.amount, 2)}/{protectPlan === "PLAN_A" ? 'year' : 'quarter'}
            </Typography>
          </Grid>
        }
      </Grid>
        :
        <Grid item className={classes.spinner}>
          <CircularProgress />
        </Grid>}
    </Grid>
  )
}

export default OrderSummary
