
import useStyles from './styles'
import MellohomeHeader from "./components/Header"
import Banner from './components/Banner'
import { Box, Button, Typography } from '@material-ui/core'
import { coupleComputer, faqQC, homeEntrance, melloHomeBg, bosscatHomeServicesLogo, mellohome } from 'assets/index'
import Renovations from './components/Renovations'
import ContactForm from './components/ContactForm'
import { useDispatch } from 'react-redux'
import { newEstimateActions, territoriesActions } from 'ducks/actions'
import Footer from './components/Footer'
import { CONTACT_ROLE, FORM_TYPE } from 'helpers/constants'
import { history } from 'helpers/index'
import UtmParamsWrapper from 'components/pages/Auth/UtmParamsWrapper'
import Improvements from './components/Improvements'
import ServicesSlider from './components/ServicesSlider'
import { useIsXlScreen } from 'hooks/useMediaQuery'
import { isMobile } from 'react-device-detect'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import { useState } from 'react'
import OutOfAreaModal from './components/OutOfAreaModal'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const MellohomeLandingMaintenance = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const push = history.usePush()
  const query = history.useQuery()
  const [openAModal, setOpenModal] = useState(false)
  const source = query.get('utm_source') || query.get('utmsource')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')
  const [xlScreen] = useIsXlScreen()
  const [success, setSuccess] = useState(false)

  const handleSubmitForm = (data: any) => {
    dispatch(newEstimateActions.setNewEstimateValue({ attr: 'address', value: data.address }))
    dispatch(newEstimateActions.setNewEstimateValue({ attr: 'serviceable', value: data.serviceable }))
    dispatch(newEstimateActions.setNewEstimateValue({
      attr: "clientType",
      value: CONTACT_ROLE.HOMEOWNER
    }))
    dispatch(newEstimateActions.setNewEstimateValue({
      attr: 'mainContact', value: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        clientType: CONTACT_ROLE.HOMEOWNER,
        role: CONTACT_ROLE.HOMEOWNER,
      }
    }))

    dispatch(
      territoriesActions.fetchTerritory(data?.address?.zipCode, (succ, terr) => {
        if (succ) {
          if (terr?.serviceable) {
            dispatch(newEstimateActions.createEstimateV2((succ, estimateId) => {
              if (succ) {
                setSuccess(true)
                document.body.style.overflow = 'hidden'
                //   if (data.serviceable) {
                //     dispatch(
                //       newEstimateActions.setNewEstimateValue({
                //         attr: 'step',
                //         value: 0,
                //       })
                //     )
                //   } else {
                //     dispatch(
                //       newEstimateActions.setNewEstimateValue({
                //         attr: 'formType',
                //         value: FORM_TYPE.CLIENT_INSPECTION_REPORT
                //       })
                //     )
                //     dispatch(
                //       newEstimateActions.setNewEstimateValue({
                //         attr: 'step',
                //         value: 0,
                //       })
                //     )
                //   }
                //   push("estimate")
              }
            }, { formType: FORM_TYPE.CLIENT_REPAIR_LIST, dontCreateAccount: true, marketingProperties: { source: source || "", campaign: campaign || "", medium: medium || "", content: content || "", promo: data.promoCode }, properties: { clientNotes: data.describeHome, affiliate: source || "" } }))
          } else {
            setOpenModal(true)
          }
        }
      })
    )


  }

  const handleCloseSuccess = () => {
    window.location.replace("https://mellohome.com/")
  }

  return (
    <UtmParamsWrapper>
      <Modal open={openAModal} setOpen={setOpenModal} size="lg">
        <OutOfAreaModal setOpen={setOpenModal} />
      </Modal>
      <MellohomeHeader />
      {/* <div style={{ width: "100%", paddingInline: "24px", marginTop: "24px" }}>
        <HolidayBanner />
      </div> */}
      <ContactForm titleClasses={classes.formTitle} title="Tell us about your project and receive a quote in 1-2 business days" onSubmit={handleSubmitForm} />
      <div style={{ width: "100%", height: "40px", backgroundColor: "var(--bosscat-blue-600)" }} />
      <Improvements />
      <ServicesSlider />
      <Renovations />
      <Banner
        title={
          <>
            <Typography style={{ fontSize: "3rem" }}>PRICE MATCH GUARANTEE</Typography>
          </>
        }
        subtitle={
          <Typography style={{ fontSize: "1rem" }}>
            We’re confident we offer excellent value for your home improvement project. Our pricing is highly competitive, and we’ll work with you to make sure you get a fair quote that fits your budget. If you find a better price from a licensed contractor, let us know and we’ll match it.
          </Typography>
        }
        imgSrc={melloHomeBg}
        imgAside={coupleComputer}
        padding={`7rem 0% 7rem ${xlScreen ? '1' : '2'}%`}
        direction="row"
        withMaxWidth={isMobile ? "100%" : "1200px"}
      />
      <Banner
        title={
          <>
            <Typography style={{ maxWidth: "50%" }}>QUALITY GUARANTEED</Typography>
          </>
        }
        subtitle={
          <Typography style={{ fontSize: "1.5rem", fontFamily: "LatoBold" }}>
            Because we are a one-step solution for any home project, you can count on us to deliver consistent results, regardless of the circumstances, backed by our one-year labor warranty.
          </Typography>
        }
        // imgSrc={faqQC}
        imgAside={homeEntrance}
        padding={`7rem ${xlScreen ? '25' : '13'}%`}
        direction="row-reverse"
        color="black"
        titleColor="#A5965A"
      />
      {/* <Box className={classes.testimonials}>
        <Box>
          <Typography className={classes.testimonialTitle}>Hands down Bosscat will be our go-to provider.</Typography>
          <Typography className={classes.testimonialComment}>They really care and it is more than just a service call to them; It's about ensuring families are taken care of.</Typography>
          <Typography className={classes.testimonialSubtitle}>Daniela S, Homeowner</Typography>
        </Box>
        <Box>
          <Typography className={classes.testimonialComment}>Easy to use, fair pricing, quality workmanship & guarantees! What's not to love?</Typography>
          <Typography className={classes.testimonialSubtitle}>Brian Wilson, Realtor, eXp Realty</Typography>
        </Box>
      </Box> */}
      <Footer />
      <Modal open={success} setOpen={setSuccess} size="lg">
        <Box className={classes.headerLogos}>
          <Box flexGrow={1} display='flex' justifyContent='center' alignItems='center'>
            <img src={bosscatHomeServicesLogo} alt='BOSSCAT Logo' style={{ width: '200px' }} />
          </Box>
          <Box flexGrow={1} display='flex' justifyContent='center' alignItems='center'>
            <img src={mellohome} alt='Mellohome Logo' style={{ width: '160px', borderRight: 0 }} />
          </Box>
        </Box>
        <Box className={classes.successContainer}>
          <Typography className={classes.successCopy}>Thank you for your request.</Typography>
          <Typography className={classes.successCopy}>A BOSSCAT Home Specialist will be in touch shortly.</Typography>
          <Typography className={classes.successCopy}>We look forward to helping you improve your home.</Typography>
          <Button className={classes.button} variant='contained' onClick={handleCloseSuccess}>
            OK
          </Button>
        </Box>
      </Modal>

    </UtmParamsWrapper>
  )
}

export default MellohomeLandingMaintenance
