import { Theme as ThemeMUI, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: ThemeMUI) =>
  createStyles({
    root: {
      padding: '8px 0'
    },
    text: {
      padding: '0',
      fontFamily: 'NextArtHeavy',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: '.5rem'
      }
    },
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    radioButton: {
      position: 'absolute',
      right: '8px',
      padding: 0,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: 'fit-content',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content'
        }
      }
    },
    title: {
      color: '#0B060F',
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        gridArea: 'title',
        marginBottom: '0'
      }
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center'
      }
    },
    subtitle: {
      color: '#6D6A6F',
      fontSize: "14px",
      textAlign: 'left'
    },
    phone: {
      color: 'var(--bosscat-blue-600)',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'var(--bosscat-blue-600)',
        color: 'white',
        width: '100%',
        padding: '1rem',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center'
      }
    },

    button: {
      width: 'fit-content',
      height: '45px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      borderRadius: 8,
      border: '0px solid',
      padding: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        width: '165px',
        minHeight: '50px',
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '16px'
      }
    }
  })
)
