import { Payment } from 'ducks/types'

/** TYPES **/
export const paymentTypes = {
  FETCH_PAYMENT: 'FETCH_PAYMENT',
  SET_PAYMENT: 'SET_PAYMENT',
}

/** ACTIONS **/
export interface FetchPaymentAction {
  type: typeof paymentTypes.FETCH_PAYMENT
  payload?: string
  callback?: (succ: boolean) => void
}
export interface SetPaymentAction {
  type: typeof paymentTypes.SET_PAYMENT
  payload: Payment
}

export interface PaymentValue {
  paymentId: string
  attr: keyof Payment
  value: any
}


export type PaymentActions =
  | FetchPaymentAction
  | SetPaymentAction

/** REDUCER **/
export interface PaymentType extends Payment {}
