import { Box, Grid, List, ListItem, Typography } from '@material-ui/core'
import { BulletListProps } from './types'
import { isMobile } from 'react-device-detect'

const BulletList = ({ items }: BulletListProps) => {
  return (
    <Box>
      {items.map((bullet) => (
        <>
          <ListItem key={bullet.desc} style={{ listStyle: 'inside', display: 'list-item', textAlign: isMobile ? 'left' : 'justify', fontFamily: 'LatoNormal' }}>
            <b>{bullet.bold}</b>
            {bullet.desc}
          </ListItem>
          {bullet.bullets?.length && bullet.bullets?.length > 0 && <List className="ASADASD" style={{ marginLeft: '16px' }}><BulletList items={bullet.bullets || []} /></List>}
          {bullet.extra && <Typography style={{ fontFamily: "LatoNormal", marginTop: '.4rem' }}> {bullet.extra}</Typography>}
        </>
      ))}
    </Box>
  )
}

export default BulletList
