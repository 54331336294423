/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { propertyActivityType, FetchPropertyActivityAction } from './types'
import * as Sentry from '@sentry/react'
import { propertyActivityActions } from 'ducks/actions'

export function* fetchPropertyActivity({ payload, callback }: FetchPropertyActivityAction): SagaIterator {
  let success = false
  try {
    const propertyActivity = yield call(Punchlist.properties.getPropertyActivity, payload.id)

    if (propertyActivity) yield put(propertyActivityActions.setPropertyActivity(propertyActivity))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the property activity'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchPropertiesActivity({ payload, callback }: FetchPropertyActivityAction): SagaIterator {
  let success = false
  try {
    const propertyActivity = yield call(Punchlist.properties.getPropertiesActivity)

    if (propertyActivity) yield put(propertyActivityActions.setPropertyActivity(propertyActivity))
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the properties activity'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(propertyActivityType.FETCH_PROPERTY_ACTIVITIES, fetchPropertyActivity)
  yield takeLatest(propertyActivityType.FETCH_PROPERTIES_ACTIVITIES, fetchPropertiesActivity)
}
