import { Grid, Typography } from "@material-ui/core"
import { FC } from "react"
import { PerkBoxProps } from "./types"

import useStyles from './styles'

const PerkBox: FC<PerkBoxProps> = ({ backgroundColor, bullets, img, title, className = "", content }) => {
  const classes = useStyles()
  return (
    <Grid className={`${classes.container} ${className} Perks--box`} style={{
      backgroundColor: backgroundColor
    }}>
      {img && <img src={img} alt={title} />}
      <Grid className={`Perks--content`}>
        <Typography className={`${classes.title} Perks--title`}>{title}</Typography>
        {bullets?.length ? <ul className={`${classes.list} Perks--list`}>
          {bullets?.map(bullet => <li className={classes.listItem}>{bullet}</li>)}
        </ul> : null}
        {content && <Typography className={`${classes.paragraph} Perks--paragraph`}>{content}</Typography>}
      </Grid>
    </Grid>
  )
}

export default PerkBox