import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '720px',
      width: '100%',
      borderRadius: '16px',
      textAlign: 'center',
      minHeight: 400,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        margin: 0,
      },
    },
    modal: {
      '@global': {
        '.MuiDialogContent-root': {
          padding: '16px',
        },
        '.MuiDialog-container': {
          [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
          },
        },
      },
    },
    header: {
      flex: 0,
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '1rem',
      justifyContent: 'space-between',
      // position: 'absolute',
      right: 0,
      top: 0,
      padding: '16px !important',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content',
        },
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        padding: '8px !important',
      },
    },
    body: {
      // paddingBottom: '1rem'
    },
    text: {
      padding: '0',
      fontFamily: 'NextArtHeavy',
      textAlign: 'left',
    },
    boxes: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1rem',
    },
    box: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '280px',
      textAlign: 'left',
      border: '2px solid #F5F6F7',
      borderRadius: 8,
      boxShadow:
        '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      padding: '8px 8px 32px 8px',
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        border: '2px solid var(--bosscat-blue-600)',
        transition: '300ms all',
        '@global': {
          '.MuiSvgIcon-root': {
            fill: 'var(--bosscat-blue-600)',
          },
        },
      },
    },
    noAbsolute: {
      position: 'relative',
    },
    radioButton: {
      position: 'absolute',
      right: '8px',
      padding: 0,
      width: 'fit-content',
      height: 'fit-content',
      minWidth: 'fit-content',
      '@global': {
        '.MuiButton-label': {
          width: 'fit-content',
        },
      },
    } /* 
    title: {
      color: '#0B060F',
      fontSize: 18,
      marginBottom: '.5rem'
    }, */,
    subtitle: {
      color: '#6D6A6F',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    closeButton: {
      padding: 0,
      minWidth: '32px',
      height: '32px',
      '@global': {
        '.MuiSvgIcon-root': {
          width: '0.7em',
        },
      },
    },
    cancel: {
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-primary)',
      borderRadius: 8,
      height: '56px',
      fontFamily: 'LatoBold',
      '&:hover': {
        color: 'var(--bosscat-blue-700)',
        backgroundColor: 'var(--white-color)',
      },
    },
    submitContainer: {
      display: 'flex',
      gap: '12px',
      padding: '16px',
      marginTop: '10px',
      justifyContent: 'flex-end',
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      height: '56px',
      fontFamily: 'LatoBold',
      minWidth: '160px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    inspectionTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        '@global': {
          img: {
            // display: 'none'
          },
        },
      },
    },
    title: {
      fontFamily: 'NextArtHeavy',
    },
    inputLabel: {
      fontWeight: 900,
      textAlign: 'left',
    },
    itemsInput: {
      height: 'fit-content',
      '@global': {
        textarea: {
          height: '95px !important',
        },
      },
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      '@global': {
        '.MuiCircularProgress-circle': {
          color: 'white'
        }
      }
    },
  })
)
