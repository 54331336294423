import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    promoCode: {
      flex: 1,
      marginTop: '1rem',
      '@global': {
        '.MuiInputBase-root': {
          height: 60
        }
      }
    },
    promoCodeTitle: {
      fontSize: 18,
      fontWeight: 900
    },
    input: {
      '@global': {
        '.MuiFormControl-root': {
          position: 'relative'
        }
      }
    },
    promoCodeContainer: {
      alignItems: 'flex-end',
    },
    button: {
      position: 'absolute',
      background: 'var(--bosscat-blue-600)',
      color: 'white',
      textTransform: 'inherit',
      borderRadius: '8px',
      height: 48,
      right: 0,
      bottom: 0,
      margin: '.3rem',
      fontWeight: 700,
      '@global': {
        '.MuiButton-label': {
          paddingTop: 0
        }
      }
    },
    showPromoCode: {
      color: "#2F7BF7",
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: 900,
      lineHeight: "24px",
      wordWrap: "break-word",
      cursor: "pointer",
    },
    promoSuccessIcon: {
      fill: '#17B975',
      marginRight: '.5rem',
      width: '1rem'
    },
    promoSuccessText: {
      color: '#0E905A'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    errorMessage: {
      color: 'red'
    }
  })
)
