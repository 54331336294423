/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import {
  paymentTypes,
  FetchPaymentAction,
} from './types'
import { paymentActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchPayment({ payload, callback }: FetchPaymentAction): SagaIterator {
  let success = false
  try {
    const Payment = yield call(Punchlist.payments.getPaymentById, payload || '')

    if (Payment) {
      yield put(paymentActions.setPayment(Payment))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the payment'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(paymentTypes.FETCH_PAYMENT, fetchPayment)
}
