import { createStyles, makeStyles, Theme } from '../../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      position: 'absolute',
      bottom: '-64px',
      boxShadow: '0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      borderRadius: '12px',
      [theme.breakpoints.down('md')]: {
        position: 'static',
        width: 'calc(100% - 40px)',
        margin: 12,
        marginTop: '1rem', 
      }
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '36px',
      position: 'relative',
      backgroundColor: 'var(--head-text-color)',
      padding: '4px 0px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px'
    },
    headerMarks: {
      position: 'absolute',
      display: 'flex',
      right: '0px'
    },
    headerText: {
      color: theme.palette.background.default,
      fontFamily: 'Lato',
      fontSize: '18px',
      lineHeight: '28px'
    },
    bodyContainer: {
      display: 'flex',
      padding: '16px 12px',
      backgroundColor: 'var(--white-color)',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '1rem'
      }
    },
    specialFont: {
      fontFamily: 'Lato'
    },
    button: {
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: '32px',
      padding: '12px 16px',
      width: '220px'
    }
  })
)
