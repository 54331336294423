import { Invoice } from 'ducks/types'

/** TYPES **/
export const invoiceTypes = {
  FETCH_INVOICE: 'FETCH_INVOICE',
  SET_INVOICE: 'SET_INVOICE'
}

/** ACTIONS **/
export interface FetchInvoiceAction {
  type: typeof invoiceTypes.FETCH_INVOICE
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetInvoiceAction {
  type: typeof invoiceTypes.SET_INVOICE
  payload: Invoice
}

export type InvoiceActions =
  | FetchInvoiceAction
  | SetInvoiceAction

/** REDUCER **/
export type InvoiceType = Invoice | null
