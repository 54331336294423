import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '12px',
      width: '100%'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '320px'
      }
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: '320px'
      }
    },
    textAddress: {
      color: 'var(--text-color)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '10px 5px 0px 0px'
    },
    textLink: {
      color: 'var(--bosscat-blue-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '10px 0px 0px 0px'
    },
    color: {
      backgroundColor: 'var(--row-background)',
      padding: '16px',
      borderRadius: '6px',
      [theme.breakpoints.down('sm')]: {
        padding: '12px',
        minWidth: '342px'
      }
    },
    comboRepair: {
      minWidth: '300px',
      width: '617px',
      height: '56px',
      margin: '10px 20px 0px 0px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      backgroundColor: 'var(--white-color)',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '70%',
        minWidth: '60%',
        margin: '10px 10px 0px 0px'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%'
      }
    },
    add: {
      width: '151px',
      height: '48px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      margin: '10px 5px',
      [theme.breakpoints.down('md')]: {
        minWidth: '120px',
        margin: '10px 2px'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    }
  })
)
