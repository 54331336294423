/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, TextFieldLabel, Autocomplete, SelectAddress, Button, Typography, RadioGroup, Radio, BottomLink } from 'components/UI'

import { canadaStates, getAbbFromNameAndCountry, getCountriesOptionList, getCountrySelectedAsOption, getSelectedAsOption, getStatesOptionList, getStatesOptionListCanada, history, isEmpty, PHONE_MASK_INPUT, PHONE_MASK_REGEXP, PHONE_MASK_REGEXP_NO_SCOPE, states, USER_TYPE } from 'helpers'

import { icons, obAgent, obHomeowner, obInstitutionalClient, canadaFlag, googleMaps, usaFlag } from 'assets'


import { Address } from 'ducks/types'
import { getConfigValue, getNewEstimateValue, getNewEstimateValues, getUser } from 'ducks/selectors'

import { useStepperContext } from 'hooks/useStepperContext'

import useStyles from './styles'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'
import { LinearProgress } from '@material-ui/core'
import { authActions, configActions, userActions } from 'ducks/actions'
import { UserType } from 'ducks/user/types'

const Location: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showWarnings, saveNewValue, setCanGoForward, setShowWarnings, goBack } = useStepperContext()
  const replace = history.useReplace()

  const user = useSelector(getUser)
  const estimate = useSelector(getNewEstimateValue('estimate'))
  const address = !isEmpty(user?.address?.line_1) ? user?.address : estimate?.properties?.address
  const [street, setStreet] = useState(address?.line_1)
  const [city, setCity] = useState(address?.city)
  const [state, setState] = useState(address?.state)
  const [zipCode, setZipCode] = useState(address?.zipCode)
  const [unitNumber, setUnitNumber] = useState(address?.unitNumber)
  const [latitude, setLatitude] = useState(address?.latitude)
  const [longitude, setLongitude] = useState(address?.longitude)

  // const contacts = estimate.properties?.contacts ? estimate.properties.contacts : []
  const mainContact = useSelector(getNewEstimateValue('mainContact'))
  const [phone, setPhone] = useState(user.phone ? user.phone?.toString() : mainContact?.phone ? mainContact?.phone : '')

  const [country, setCountry] = useState(address?.country ? address?.country : "United States")

  const userIsNotHomeowner = ![USER_TYPE.HOME_BUYER, USER_TYPE.HOMEOWNER].includes(user.clientType)

  const [openMap, setOpenMap] = useState(userIsNotHomeowner || false)
  const redirectPath = useSelector(getConfigValue('redirectPath'))
  const savedAddress = {
    line_1: street?.trim(),
    line_2: unitNumber,
    city: city?.trim(),
    state: state?.trim(),
    zipCode: zipCode?.trim(),
    latitude: latitude,
    longitude: longitude,
    country: country
  }
  // const showCompanyName = ["BROKER", "INSTITUTIONAL"].includes(user?.clientType)

  const zipCodeMaxLength = country === "Canada" ? 8 : 6

  const isValidZIPCode = useCallback(() => {
    if (country === 'Canada') {
      return zipCode?.trim().length <= 7
    } else {
      return zipCode?.length === 5
    }
  }, [country, zipCode])

  const validateAddress: boolean = !isEmpty(street) &&
    !isEmpty(city) &&
    !isEmpty(state) &&
    !isEmpty(zipCode) &&
    isValidZIPCode() &&
    !isEmpty(country)

  const validatePhone = !isEmpty(phone) && parseInt(phone?.replaceAll('-', ''))?.toString().length === 10

  const validate = validateAddress &&
    validatePhone


  useEffect(() => {
    saveNewValue('address', {
      line_1: street?.trim(),
      line_2: unitNumber,
      city: city?.trim(),
      state: state?.trim(),
      zipCode: zipCode?.trim(),
      latitude: latitude,
      longitude: longitude,
      country: country
    })
  }, [city, state, street, zipCode, latitude, longitude, country, unitNumber])


  useEffect(() => {
    setCanGoForward(validate)
  }, [validate])

  useEffect(() => { setShowWarnings(false) }, [])

  const handleChange = (newAddress: Address) => {
    setStreet(newAddress.line_1)
    setCity(newAddress.city)
    setState(!isEmpty(getAbbFromNameAndCountry(newAddress.state, newAddress.country)) ? newAddress.state : '')
    setZipCode(newAddress.zipCode)
    setLatitude(newAddress.latitude)
    setLongitude(newAddress.longitude)
    setCountry(newAddress.country)
    setUnitNumber(newAddress.line_2)
  }

  const handleChangeCountry = (e: ChangeEvent<HTMLInputElement>) => {
    if (country !== e.target.value) {
      setCountry(e.target.value)
      setState(null)
    }
  }

  const updateRegisterImg = (value: USER_TYPE) => {
    switch (value) {
      case USER_TYPE.BROKER:
        dispatch(configActions.setConfigValue({ type: 'registerImg', value: obAgent }))

        break
      case USER_TYPE.HOMEOWNER:
        dispatch(configActions.setConfigValue({ type: 'registerImg', value: obHomeowner }))

        break
      case USER_TYPE.INSTITUTIONAL:
        dispatch(configActions.setConfigValue({ type: 'registerImg', value: obInstitutionalClient }))

        break
      default:
        break
    }
  }

  const updateUser = () => {
    setShowWarnings(true)
    if (validate) {
      dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: true }))
      saveNewValue(
        'phone',
        phone
      )

      const request: Partial<UserType> = {
        address: {
          line_1: street?.trim(),
          line_2: unitNumber,
          city: city?.trim(),
          state: state?.trim(),
          zipCode: zipCode?.trim(),
          latitude: latitude,
          longitude: longitude,
          country: country
        },
        registrationComplete: true,
        phone: parseInt(phone.replaceAll('-', '')),
      }
      saveNewValue('address', request)

      dispatch(configActions.setConfigValue({ type: 'showReferralModal', value: true }))

      if (!isEmpty(user?.companyName)) request.companyName = user?.companyName?.toLowerCase()
      dispatch(userActions.updateUser(request, () => {
        updateRegisterImg(user.clientType)
        dispatch(configActions.setConfigValue({ type: 'registrationLoading', value: false }))
        if (redirectPath && redirectPath !== "/p/docusignSuccess") {
          replace(redirectPath)
        } else {
          replace('/p/dashboard')
        }
      }))
    }
  }


  return (
    <Box className={classes.root}>
      <Grid item>
        <Typography variant="h5" className={classes.title}>What is your contact information?</Typography>
      </Grid>

      <Grid>
        <LinearProgress variant="determinate" value={70} />
        {/* <Typography className={classes.subtitle}>Your role</Typography> */}
      </Grid>
      {/*  {userIsNotHomeowner &&
        <Grid>
          <Typography className={classes.brokerTitle}>
            Please enter your office location
          </Typography>
        </Grid>} */}
      {/* {showCompanyName && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldLabel
              label='Company Name:'
              type='text'
              id='company-name'
              value={companyName}
              autoComplete='off'
              error={showWarnings && isEmpty(companyName)}
              onChange={(ev) => {
                saveNewValue('companyName', ev.target.value)
                setCompanyName(ev.target.value)
              }}
              placeholder='Company Name'
            />
          </Grid>
        </Grid>
      )} */}
      <Grid item className={`${classes.middleButtonItem} ${openMap ? classes.middleButtonItemOpen : ''}`}>
        <Button
          onClick={() => setOpenMap(!openMap)}
          variant='outlined'
          className={classes.googleMapsButton}
          endIcon={<img src={googleMaps} alt='' className={classes.iconMap} />}
        >
          {openMap ? 'Enter Address Manually' : 'Use Google Map'}
        </Button>
      </Grid>
      <Grid container spacing={2} /* className={`${classes.grid} ${openMap ? classes.gridOpen : ''}`} */>
        <Grid item className={classes.middleInputItem}>
          {openMap
            ? (
              <SelectAddress
                showVertical
                title={
                  <Typography variant='body1' className={classes.title}>
                    Address:
                  </Typography>
                }
                showUnit={true}
                onChange={handleChange}
                savedAddress={savedAddress}
                showWarnings={showWarnings && !validateAddress}
                customClass={`${classes.modalContent} ${openMap ? classes.modalContentOpen : ''}`}
                showMap={false}
              />)
            : (
              <>
                <Grid style={{ paddingBottom: 20 }}>
                  <Typography style={{ paddingBottom: 8 }} className={classes.inputAutocomplete}> Country: </Typography>
                  <RadioGroup
                    value={country}
                    onChange={handleChangeCountry}
                    className={classes.radioContainer}
                  >
                    <FormRadioButton
                      style={country === "United States" ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                      value={'United States'}
                      control={<Radio color='primary' />}
                      label={
                        <>
                          <img src={usaFlag} alt='' className={classes.flags} />
                          <Typography variant='caption'>USA</Typography>
                        </>
                      }
                      className={classes.radio}
                    />
                    <FormRadioButton
                      style={country === "Canada" ? { borderColor: "var(--bosscat-blue-600)" } : {}}
                      value={'Canada'}
                      control={<Radio color='primary' />}
                      label={
                        <>
                          <img src={canadaFlag} alt='' className={classes.flags} />
                          <Typography variant='caption'>Canada</Typography>
                        </>
                      }
                      className={classes.radio}
                    />
                  </RadioGroup>
                </Grid>

                <TextFieldLabel
                  label='Your Address:'
                  onChange={ev => { setStreet(ev.target.value) }}
                  error={showWarnings && isEmpty(street?.trim())}
                  value={street}
                  placeholder='Enter your address'
                />
              </>
            )}
        </Grid>
      </Grid>

      {!openMap && (
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel
                type={undefined}
                label='Unit Number'
                // error={showWarnings && (isEmpty(unitNumber) || !isValidZIPCode())}
                onChange={ev => {
                  setUnitNumber(ev.target.value)
                }}
                value={unitNumber}
                placeholder='Apt. 101'
              />
            </Grid>
            <Grid item xs={6} className={classes.bottomInputItems}>
              <TextFieldLabel
                type={country !== 'Canada' ? 'number' : undefined}
                label='ZIP/Postal'
                error={showWarnings && (isEmpty(zipCode) || !isValidZIPCode())}
                onChange={ev => {
                  setZipCode(ev.target.value.length < zipCodeMaxLength ? ev.target.value : zipCode)
                }}
                value={zipCode}
                placeholder='Enter your ZIP code'
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid xs={6} item className={classes.bottomInputItems}>
              <Autocomplete
                label='State'
                onChange={(value: any) => setState(value.key)}
                value={!isEmpty(states) && !isEmpty(state)
                  ? getSelectedAsOption(states.find(opt => opt?.abbreviation === state) || canadaStates.find(opt => opt?.abbreviation === state))
                  : null}
                error={showWarnings && !state}
                options={country === 'Canada' ? getStatesOptionListCanada() : getStatesOptionList()}
                placeholder='Select your state'
                labelClassName={classes.inputAutocomplete}
              />
            </Grid>
            <Grid xs={6} item className={classes.bottomInputItems}>
              <TextFieldLabel
                label='City'
                onChange={ev => { setCity(ev.target.value) }}
                error={showWarnings && isEmpty(city?.trim())}
                value={city}
                placeholder='Enter your city'
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item style={{ marginBottom: "1rem" }}>
        <TextFieldLabel
          label="Your Mobile Phone Number"
          onChange={(ev) => {
            setPhone(ev.target.value)
          }}
          mask={PHONE_MASK_INPUT}
          value={phone}
          error={
            showWarnings &&
            !validatePhone
          }
          placeholder="123-123-1234"
          className={classes.phoneInput}
        />
      </Grid>
      <BottomLink
        question='Already have an account?'
        linkText='Sign in here'
        onClick={() => dispatch(authActions.logoutRequest())}
      />
      <Grid container item xs={12} style={{ display: 'flex', marginTop: '1rem', width: '100%' }} justifyContent='space-between'>
        <Button
          type='submit'
          variant='outlined'
          size='small'
          className={classes.buttonBack}
          startIcon={<icons.ArrowBack />}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          type='submit'
          variant='contained'
          size='small'
          className={classes.buttonNext}
          endIcon={<icons.ArrowForward />}
          // disabled={!validate}
          onClick={updateUser}
        >
          Next
        </Button>
      </Grid>
    </Box>
  )
}

export default Location
