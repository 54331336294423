import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    promoCode: {
      flex: 1,
      padding: "0 !important",
    },
    promoCodeContainer: {
      alignItems: 'center'
    },
    button: {
      background: 'var(--bosscat-blue-600)',
      color: 'white',
      textTransform: 'inherit',
      borderRadius: '8px',
      fontFamily: 'LatoBold',
      '@global': {
        '.MuiButton-label': {
          paddingTop: 0
        }
      }
    },
    promoSuccessIcon: {
      fill: '#17B975',
      marginRight: '.5rem',
      width: '1rem'
    },
    promoSuccessText: {
      color: '#0E905A'
    },
    spinner: {
      '& .MuiCircularProgress-circle': {
        color: 'var(--white-color)'
      }
    },
    errorMessage: {
      color: 'red'
    },
    showPromoCode: {
      color: "#2F7BF7",
      fontSize: "16px",
      fontFamily: "Lato",
      fontWeight: 900,
      lineHeight: "24px",
      wordWrap: "break-word",
      cursor: "pointer",
    }
  })
)
