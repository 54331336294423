import { FC } from 'react'
import { Dialog, Button, DialogContent, Typography } from '../../../../../UI'
import useStyles from './styles'
import { icons, obHomeowner } from '../../../../../../assets'
import useStylesBetter from './stylesBetter'
import { ContactUsModalProps } from './types'
import { BOSSCAT_PHONE_NUMBER } from 'helpers/constants'

const ContactUsModal: FC<ContactUsModalProps> = ({
  open,
  setOpen,
  showBetterStyle = false,
}) => {
  const styles = useStyles()
  const stylesBetter = useStylesBetter()
  const classes = showBetterStyle ? stylesBetter : styles

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant="h5" className={classes.text}>
          Contact Us
        </Typography>
      </DialogContent>
      <DialogContent>
        <img src={obHomeowner} alt="" className={classes.icon} />
        <Typography variant="body1" className={classes.text}>
          Give us a call! Our team is standing by and always happy to help.
        </Typography>
      </DialogContent>
      <DialogContent className={classes.phone}>
        <icons.PhoneEnabled className={classes.phoneIcon} />
        <Typography variant="body1">{BOSSCAT_PHONE_NUMBER}</Typography>
      </DialogContent>
      <DialogContent className={classes.submitContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          fullWidth
          onClick={handleClose}
        >
          Got it!
        </Button>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default ContactUsModal
