import { FC } from 'react'
import { TitleProps } from './types'
import useStyles from './styles'

import {
  Typography, Box
} from '@material-ui/core'

const TitleFirst: FC<TitleProps> = ({
  value, black, justifyContent
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.text} justifyContent={justifyContent}>
      {value && <Typography className={classes.title}> {value} </Typography>}
      {black && <Typography className={classes.titleBlack}> {black} </Typography>}
    </Box>
  )
}

export default TitleFirst
