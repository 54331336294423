import {
  PropertyActivityActions,
  propertyActivityType,
  PropertyActivityType,
} from './types'

const actions = {
  fetchPropertyActivity: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyActivityActions => ({
    type: propertyActivityType.FETCH_PROPERTY_ACTIVITIES,
    payload,
    callback,
  }),
  fetchPropertiesActivity: (
    payload: { },
    callback?: (succ: boolean) => void
  ): PropertyActivityActions => ({
    type: propertyActivityType.FETCH_PROPERTIES_ACTIVITIES,
    payload,
    callback,
  }),
  setPropertyActivity: (
    payload: PropertyActivityType
  ): PropertyActivityActions => ({
    type: propertyActivityType.SET_PROPERTY_ACTIVITIES,
    payload,
  }),
}

export default actions
