import { authTypes } from 'ducks/auth/types'
import { Invoice } from 'ducks/types'
import { InvoiceActions, InvoiceType, invoiceTypes } from './types'

const initialState: InvoiceType = null

const reducer = (state = initialState, action: InvoiceActions): InvoiceType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case invoiceTypes.SET_INVOICE: {
      return payload as Invoice
    }

    default:
      return state
  }
}

export default reducer
