import { Box, Typography } from "@material-ui/core"
import { computer, handShake, logo, mellohomeBg, qualityPros, trustpilot } from "assets/index"
import useStyles from './styles'

const Renovations = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}> Renovations made easy </Typography>
      <Typography className={classes.subtitle}> Get an expert consultation, chose your finishes online, and let our licensed and insured Pro team create the home you've dreamed of.</Typography>
      <Box className={classes.card__container}>
        <Box className={classes.card}>
          <img src={handShake} alt="logo" className={classes.icon} />
          <Box >
            <Typography className={classes.card__title}>
              In-Home Consultation
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            Our renovation experts will meet with you to discuss your goals and provide a quote.
          </Typography>

        </Box>
        <Box className={classes.card}>
          <img src={computer} alt="logo" className={classes.icon} />
          <Box >
            <Typography className={classes.card__title}>
              get inspired
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            Browse professionally-curated design packages and select your finishes with our digital design look book.
          </Typography>
        </Box>
        <Box className={classes.card}>
          <img src={qualityPros} alt="logo" className={classes.icon} />
          <Box >
            <Typography className={classes.card__title}>
              quality pros
            </Typography>
          </Box>
          <Typography className={classes.card__subtitle}>
            Sit back and relax while our team transforms your home with guaranteed quality.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Renovations