/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo, isEmpty } from 'helpers'
import { Punchlist } from 'api'
import {
  paymentsTypes,
  FetchPaymentsAction,
  FecthStripeSecretAction,
  FecthStripeSecretSetupAction
} from './types'
import { paymentsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'

export function* fetchPayments({ payload, callback }: FetchPaymentsAction): SagaIterator {
  let success = false
  try {
    const Payments = yield call(Punchlist.payments.getPayments, payload)

    if (Payments) {
      yield put(paymentsActions.setPayments(Payments))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the payments'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchStipeSecret({ payload, callback }: FecthStripeSecretAction): SagaIterator {
  try {
    const secret = yield call(Punchlist.payments.getStripeSecret, payload)

    if (!isEmpty(secret)) {
      if (callback) yield call(callback, secret)
    }
    if (callback) yield call(callback, undefined)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('initialize the payment'))
    if (callback) yield call(callback, undefined)
  }
}

export function* fetchStipeSecretSetup({ callback }: FecthStripeSecretSetupAction): SagaIterator {
  try {
    const secret = yield call(Punchlist.payments.getStripeSecretSetup)

    if (!isEmpty(secret)) {
      if (callback) yield call(callback, secret)
    }
    if (callback) yield call(callback, undefined)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('initialize the payment'))
    if (callback) yield call(callback, undefined)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(paymentsTypes.FETCH_PAYMENTS, fetchPayments)
  yield takeLatest(paymentsTypes.FETCH_STRIPE_SECRET, fetchStipeSecret)
  yield takeLatest(paymentsTypes.FETCH_STRIPE_SECRET_SETUP, fetchStipeSecretSetup)
}
