import storeInfo from 'ducks/store'
import { ITEM_PRIORITY, ITEM_STATUS, ESTIMATE_VIEW, ITEM_CATEGORY, ITEM_CATEGORY_FOR_FILTERS } from 'helpers'
import { Item } from 'ducks/types'

export const getEstimateItems = (hideRemoved: boolean) => (): Item[] | [] => {
  const { estimateItems } = storeInfo.store.getState()
  if (hideRemoved) return estimateItems.filter((item: Item) => item.status === ITEM_STATUS.APPROVED) || []
  return estimateItems
}

export const getItemsInCart = () => (): Item[] | [] => {
  const { estimateItems } = storeInfo.store.getState()
  return estimateItems.filter((item: Item) => item.status === ITEM_STATUS.APPROVED) || []
}

export const getEstimateItemsGroupBy = (selectedType: ESTIMATE_VIEW, hideRemoved: boolean, categoryFilter?: ITEM_CATEGORY_FOR_FILTERS | ITEM_PRIORITY, status?: ITEM_STATUS, itemsToFilter?: Item[]) => (): any => {
  const { estimateItems } = storeInfo.store.getState()
  let items = itemsToFilter || estimateItems
  if (hideRemoved) items = items.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED)
  if (status) items = items.filter((item: Item) => item.status === status)

  if (categoryFilter) {
    const selectedFiltIsFromCategory = Object.keys(ITEM_CATEGORY_FOR_FILTERS).includes(categoryFilter)
    if (selectedFiltIsFromCategory) {
      items = items.filter((item: any) => item.category === categoryFilter)
    } else {
      items = items.filter((item: any) => item.severity === categoryFilter)
    }
  }

  if (selectedType === ESTIMATE_VIEW.TYPE) {
    const groupByCategory = items.reduce((group: any, item: Item) => {
      const { category } = item
      group[category] = group[category] ?? []
      group[category].push(item)
      return group
    }, {})

    return groupByCategory
  }

  if (selectedType === ESTIMATE_VIEW.PRIORITY) {
    const groupByPriority: {
      [ITEM_PRIORITY.Urgent]?: Item[], [ITEM_PRIORITY.High]?: Item[],
      [ITEM_PRIORITY.Medium]?: Item[], [ITEM_PRIORITY.Low]?: Item[],
      [ITEM_PRIORITY.Cosmetic]?: Item[]
    } = {}

    const itemsWithoutEvaluate = items.filter((item: Item) => item.category !== ITEM_CATEGORY.EVALUATE)

    const urgent = itemsWithoutEvaluate.filter((item: Item) => item.severity === ITEM_PRIORITY.Urgent)
    const high = itemsWithoutEvaluate.filter((item: Item) => item.severity === ITEM_PRIORITY.High)
    const medium = itemsWithoutEvaluate.filter((item: Item) => item.severity === ITEM_PRIORITY.Medium)
    const low = itemsWithoutEvaluate.filter((item: Item) => item.severity === ITEM_PRIORITY.Low)
    const cosmetic = itemsWithoutEvaluate.filter((item: Item) => item.severity === ITEM_PRIORITY.Cosmetic)

    if (urgent.length > 0) groupByPriority.Urgent = urgent
    if (high.length > 0) groupByPriority.High = high
    if (medium.length > 0) groupByPriority.Medium = medium
    if (low.length > 0) groupByPriority.Low = low
    if (cosmetic.length > 0) groupByPriority.Cosmetic = cosmetic

    return groupByPriority
  }

  if (selectedType === ESTIMATE_VIEW.APPROVED) {
    const groupByApproved: { Approved?: Item[], Rejected?: Item[] } = {}
    const approved = items.filter((item: Item) => item.status !== ITEM_STATUS.REJECTED)
    const rejected = items.filter((item: Item) => item.status === ITEM_STATUS.REJECTED)
    if (approved.length > 0) groupByApproved.Approved = approved
    if (rejected.length > 0) groupByApproved.Rejected = rejected
    return groupByApproved
  }

  return { All: items }
}
