import {
  Box,
  Grid,
  MultilineText,
  ProfilePicture,
  Typography,
} from 'components/UI'
import { filesActions, newOrderMaintenanceActions, orderMaintenanceActions } from 'ducks/actions'
import {
  getNewOrderMaintenanceValue,
  getNewOrderMaintenanceValues,
  getUser,
} from 'ducks/selectors'
import { isEmpty } from 'helpers/index'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'

const GiftMessage = () => {
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const orderMaintenance = useSelector(getNewOrderMaintenanceValues())
  const giftBox = useSelector(getNewOrderMaintenanceValue('giftBox'))
  const purchaser = useSelector(getNewOrderMaintenanceValue('purchaser'))

  const [picture, setPicture] = useState(purchaser?.picture ?? user?.picture)
  const [uploading, setUploading] = useState(false)

  const handleUpload = (image: File | null): void => {
    if (image) {
      setUploading(true)
      setPicture('')
      dispatch(
        filesActions.uploadFile(image, (uploadSucc, fileName) => {
          if (uploadSucc && !isEmpty(fileName)) {
            setUploading(false)
            dispatch(
              newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                attr: 'purchaser',
                value: { ...orderMaintenance.purchaser, picture: fileName },
              })
            )
            setPicture(fileName)
          } else {
            setPicture(user.picture)
            setUploading(false)
          }
        })
      )
    }
  }

  return (
    <Box className={styles.GiftMessage}>
      <Typography className={styles.GiftMessage__title}>
        Gift Message
      </Typography>
      <Grid
        container
        direction="column"
        spacing={2}
        className={styles.GiftMessage__container}
      >
        <Grid item className={styles.GiftMessage__picture}>
          <ProfilePicture
            avatar={picture}
            onUpload={handleUpload}
            uploading={uploading}
            width={isMobile ? '100%' : '200px'}
          />
        </Grid>
        {isMobile && (
          <Typography variant="caption" className={styles.GiftMessage__caption}>
            Your photo will be displayed in your gift recipient's maintenance
            dashboard
          </Typography>
        )}
        <Grid item className={styles.GiftMessage__message}>
          <Typography className={styles.GiftMessage__message_subtitle}>
            Gift Message
          </Typography>
          <MultilineText
            value={giftBox?.message}
            onChange={(event) => {
              dispatch(
                newOrderMaintenanceActions.setNewOrderMaintenanceValue({
                  attr: 'giftBox',
                  value: { ...giftBox, message: event.target.value },
                })
              )
            }}
            placeholder="Dear Client,&#10;&#10;&#10;Thank you for allowing me to serve you in buying your dream home. To protect your investment and make homeownership easier, I am gifting you a one-year membership to a home maintenance program. With this membership, BOSSCAT will take care of your routine maintenance services, and you’ll be able to spend more time enjoying your new home!"
            className={styles.GiftMessage__message__textarea}
          />
        </Grid>
      </Grid>
      {!isMobile && (
        <Typography variant="caption" className={styles.GiftMessage__caption}>
          Your photo will be displayed in your gift recipient's maintenance
          dashboard
        </Typography>
      )}
    </Box>
  )
}

export default GiftMessage
