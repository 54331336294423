import React from 'react'

type Props = {
    show?: boolean
}

const HolidayBanner = ({ show = false }: Props) => {
    if (!show) return null
    return (
        <picture style={{ minHeight: 'auto', maxWidth: 'min(76vw, 100%)' }}>
            <source
                media="(min-width: 768px)"
                srcSet="https://d1olzu62un2y2c.cloudfront.net/holiday-banner-desktop.png"
            />
            <source
                media="(max-width: 767px)"
                srcSet="https://d1olzu62un2y2c.cloudfront.net/holiday-banner-mobile.png"
            />
            <img
                style={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                }}
                src="https://d1olzu62un2y2c.cloudfront.net/holiday-banner-desktop.png"
                alt="Holiday banner"
            />
        </picture>
    )
}

export default HolidayBanner