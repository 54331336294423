import { DiscountsActions, DiscountsValue, discountsTypes } from './types'

const actions = {
  fetchDiscountBanner: (
    callback?: (succ: boolean) => void
  ): DiscountsActions => ({
    type: discountsTypes.FETCH_DISCOUNT_BANNER,
    payload: null,
    callback
  }),
  setValue: (payload: DiscountsValue): DiscountsActions => ({
    type: discountsTypes.SET_VALUE,
    payload
  }),
}

export default actions
