import { createStyles, makeStyles, Theme } from '../../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    comboUnit: {
      width: '100%',
      minHeight: '80px',
      marginTop: '2px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    repairOption: {
      margin: '0 16px 0 8px',
      padding: '16px',
      alignItems: 'center',
      backgroundColor: 'rgba(249, 250, 250, 1)',
      borderRadius: 8,
    },
    primaryText: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'NextArtHeavy',
      fontWeight: 900,
      fontSize: '18px',
      marginRight: '8px',
      lineHeight: '24px',
      paddingTop: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    latoPrimaryText: {
      color: 'var(--bosscat-black-600)',
      fontFamily: 'LatoNormal',
      fontWeight: 900,
      fontSize: '18px',
      marginRight: '8px',
      lineHeight: '24px',
      paddingTop: '5px',
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    signature: {
      width: '100%',
      minHeight: '54px',
      maxHeight: '54px',
      marginTop: '8px',
      padding: '0px 20px 0px 20px',
      borderRadius: '8px',
      border: '2px solid var(--border-color)',
      color: 'var(--text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      fontSize: 16,
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8,
      },
    },
    terms: {
      paddingTop: '20px',
      marginLeft: '2px',
      color: 'var(--bosscat-blue-600)',
    },
    rigth: {
      justifyContent: 'flex-end',
    },
    termsButton: {
      border: '0px solid',
      textTransform: 'none',
      height: '35px',
      margin: '0px',
      '&:hover': {
        backgroundColor: 'var(--white-color)',
      },
    },
    title: {
      color: 'var(--head-text-color)',
      fontFamily: 'NextArtHeavy',
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '16px',
    },
    label: {
      fontFamily: 'LatoBold',
      fontSize: 18,
    },
    consent: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
      borderRadius: '8px',
      border: '2px solid #99DDBD',
      width: 'calc(100% - 20px)',
      padding: '16px 16px 16px 12px !important',
      backgroundColor: '#E6F7EE',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    consentText: {
      color: 'black',
      fontFamily: 'LatoNormal',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    checkboxContainer: {
      paddingTop: '5px',
      display: 'flex',
      gap: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0px !important',
        paddingBottom: '18px !important',
      },
      '@global': {
        '.MuiTypography-caption:first-child': {
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        },
      },
    },
    checkbox: {
      padding: '0px',
      height: 'fit-content',
    },
    container: {
      flexDirection: 'column',
      display: 'flex',
      gap: '32px',
      '@global': {
        '>.MuiGrid-root': {
          flexBasis: 'auto',
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: '16px',
      },
    },
    question: {
      fontSize: '18px',
      fontWeight: 900,
      lineHeight: '24px',
    },
    input: {
      width: '100%',
    },
    radioContainer: {
      width: '185px',
    },
    datePicker: {
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    boldUnderline: {
      textDecoration: 'underline',
      fontWeight: 900,
      display: 'inline',
      marginRight: 0
    },
  })
)
