import { ConditionType } from 'ducks/properties/types'
import { PropertyDetailsActions, propertyDetailType, PropertyDetailType } from './types'
import { Insight } from 'ducks/types'

const actions = {
  fetchPropertyDetail: (
    payload: { id: string },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.FETCH_PROPERTY_DETAIL,
    payload,
    callback
  }),
  orderInsights: (
    payload: { propertyId: string, item: string, request: Insight },
    callback?: (succ: boolean) => void
  ): PropertyDetailsActions => ({
    type: propertyDetailType.ORDER_INSIGHTS,
    payload,
    callback
  }),
  setPropertyDetail: (payload: Partial<PropertyDetailType>): PropertyDetailsActions => ({
    type: propertyDetailType.SET_PROPERTY_DETAIL,
    payload
  }),
  resetPropertyDetail: (payload: Partial<PropertyDetailType>): PropertyDetailsActions => ({
    type: propertyDetailType.RESET_PROPERTY_DETAIL,
    payload
  }),
  setPropertyInquiredItems: (payload: string[]): PropertyDetailsActions => ({
    type: propertyDetailType.SET_PROPERTY_INQUIRED_ITEMS,
    payload
  }),
}

export default actions
