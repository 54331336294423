import { Theme, createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'black',
      padding: '2rem 6rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem'
      }
    },
    content: {
      display: 'flex',
      gap: '3rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1rem',
      }
    },
    title: {
      textTransform: 'uppercase',
      color: 'white',
      fontFamily: 'NextArtHeavy',
      fontSize: '22px',
      marginBottom: '2rem'
    },
    subtitle: {
      margin: '.5rem 0',
      display: 'block',
      color: 'var(--gray-color)',
      fontSize: '16px'
    },
    item: {
      color: 'var(--gray-color)'
    },
    territoriesContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '1rem'
    },
    logo: {
      width: '60px',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
      }
    },
    copies: {
      display: 'flex',
      color: 'white',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: '2rem',
        alignItems: 'center',
        paddingTop: '1rem',

      }
    },
    logoCopyRights: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        gap: '.5rem'
        // flexDirection: 'column'
      }
    },
    socialMedia: {
      display: 'flex',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        gap: '.5rem',
      }
    },
    link: {
      color: 'white'
    },
    caption: {
      width: '50%',
      textAlign: 'center',
      fontSize: '12px',
      fontStyle: 'italic',
      "@global": {
        'a': {
          textDecoration: 'underline'
        }
      },
      [theme.breakpoints.down('sm')]: {
        width: "90%"
      }
    }
  })
)
