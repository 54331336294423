import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'baseline',
      color: theme.palette.primary.dark
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(4)
    },
    submit: {
      margin: theme.spacing(3, 0, 4),
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'LatoNormal',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700) !important'
      },
      '&:active': {
        backgroundColor: 'var(--bosscat-blue-800) !important'
      }
    },
    email: {
      paddingBottom: theme.spacing(1)
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    pass: {
      paddingTop: '24px'
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px'
      }
    },
    subtitle: {
      // fontFamily: 'NextArtHeavy',
      // fontSize: '16px',
      // fontWeight: 700,
      // lineHeight: '24px',
      textAlign: 'center',
      marginBottom: '2rem',
      // textTransform: 'uppercase'
    },
    icon: {
      margin: '0 0 2px 0',
      width: '12px',
      height: '12px'
    },
    logo: {
      margin: '20px',
      width: '180px'
    },
    caption: {
      color: 'var(--input-color)'
    },
    ruleTitle: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '28px',
      color: 'var(--gray-color)',
      textAlign: 'center'
    },
    ruleSubtitle: {
      fontFamily: 'LatoNormal',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      color: 'var(--gray-color)',
      textAlign: 'center'
    },
    validate: {
      "@global": {
        '.MuiTypography-root': {
          color: 'var(--completed-color)'
        }
      }
    },
    notValidate: {
      "@global": {
        '.MuiTypography-root': {
          color: 'var(--gray-color)'
        }
      }
    }
  })
)