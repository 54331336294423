import { Box, Grid, Typography } from '@material-ui/core'
import useStyles from '../../../../styles'

const Condition = ({ desc, score, bgcolor }: { desc: string; score: number, bgcolor: string }) => {
  const styles = useStyles()

  return (
    <Grid item container>
      <Grid item xs={3}>
        <Typography className={styles.lgFont}>{desc}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Box width="100%" display='flex' style={{ gap: '8px' }} alignItems='center'>
          <Box
            height="40px"
            borderRadius="8px"
            width={`${((6 - score) / 6) * 100}%`}
            bgcolor={bgcolor}
          ></Box>
          <Typography className={styles.title}>{`C${score}`}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Condition
