import { FC } from 'react'
import { icons } from '../../../../../assets'
import { ActionButtonProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'

/**
 *
 * @param backgroundColor refers to the background color of the icon\
 *
 * @returns JSX.Element
 *
 * @example // normal
 * <ActionButton
 *   background="primary"
 *   icon="add"
 *   onClick={() => { }}
 * />
 */
const ActionButton: FC<ActionButtonProps> = ({
  backgroundColor,
  disabled,
  icon,
  id,
  onClick = () => { }
}) => {
  const classes = useStyles()
  const className = useClassName()

  const getBackgroundColor = (): string => {
    switch (backgroundColor) {
      case 'secondary':
        return classes.bgError
      case 'primary':
        return classes.bgPrimary
      case 'better':
        return classes.bgBetter
      default:
        return classes.bgPrimary
    }
  }

  const renderIcon = (): JSX.Element => {
    switch (icon) {
      case 'add':
        return <icons.Add className={classes.icon} />
      case 'collapsed':
        return <icons.KeyboardArrowUp className={classes.icon} />
      case 'delete':
        return <icons.DeleteOutline className={classes.icon} />
      case 'edit':
        return <icons.Edit className={classes.icon} />
      case 'expanded':
        return <icons.KeyboardArrowDown className={classes.icon} />
      case 'remove':
        return <icons.Remove className={classes.icon} />
      case 'save':
        return <icons.Save className={classes.icon} />
      default:
        return <icons.Add className={classes.icon} />
    }
  }

  const handleClick = (event: { stopPropagation: () => void }): void => {
    event.stopPropagation()
    if (!disabled) {
      onClick()
    }
  }

  return (
    <div
      data-testid="action-button"
      className={className(
        classes.root,
        !disabled ? classes.bgWhite : classes.bgGrey
      )}
      id={id}
      onClick={handleClick}
    >
      <div
        className={className(
          classes.iconContainer,
          !disabled ? getBackgroundColor() : classes.bgDisabled
        )}
      >
        {renderIcon()}
      </div>
    </div>
  )
}

export default ActionButton
