import { Item } from 'ducks/types'

/** TYPES **/
export const jobItemsTypes = {
  FETCH_JOB_ITEMS: 'FETCH_JOB_ITEMS',
  SET_JOB_ITEMS: 'SET_JOB_ITEMS',
  SET_JOB_ITEM_VALUES: 'SET_JOB_ITEM_VALUES',
  UPDATE_JOB_ITEM: 'UPDATE_JOB_ITEM'
}

/** ACTIONS **/
export interface FetchJobItemsAction {
  type: typeof jobItemsTypes.FETCH_JOB_ITEMS
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetJobItemsAction {
  type: typeof jobItemsTypes.SET_JOB_ITEMS
  payload: Item[]
}

export interface JobItemValues {
  itemId: string
  attrs: Partial<Item>
}

export interface SetJobItemValuesAction {
  type: typeof jobItemsTypes.SET_JOB_ITEM_VALUES
  payload: JobItemValues
}

export interface UpdateJobItemPayload {
  jobId: string
  itemId: string
  partialItem: Partial<Item>
}

export interface UpdateJobItemAction {
  type: typeof jobItemsTypes.UPDATE_JOB_ITEM
  payload: UpdateJobItemPayload
  callback?: (succ: boolean) => void
}

export type JobItemsActions =
  | FetchJobItemsAction
  | SetJobItemsAction
  | SetJobItemValuesAction
  | UpdateJobItemAction

/** REDUCER **/
export interface JobItemsType extends Array<Item> { }
