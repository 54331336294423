import {
  OrderMaintenanceValue,
  OrderMaintenanceValues,
  orderMaintenanceTypes,
  OrderMaintenanceActions,
  OrderMaintenanceType,
} from './types'

const actions = {
  goBackOrderMaintenance: (): OrderMaintenanceActions => ({
    type: orderMaintenanceTypes.GO_BACK,
    payload: null
  }),
  goForwardOrderMaintenance: (): OrderMaintenanceActions => ({
    type: orderMaintenanceTypes.GO_FORWARD,
    payload: null
  }),
  setOrderMaintenanceValue: (payload: OrderMaintenanceValue): OrderMaintenanceActions => {
    return ({
      type: orderMaintenanceTypes.SET_VALUE,
      payload
    })
  },
  setOrderMaintenanceValues: (payload: OrderMaintenanceValues): OrderMaintenanceActions => ({
    type: orderMaintenanceTypes.SET_VALUES,
    payload
  }),
  clearOrderMaintenance: (): OrderMaintenanceActions => ({
    type: orderMaintenanceTypes.CLEAR,
    payload: null
  }),
  setOrderMaintenance: (payload: OrderMaintenanceType): OrderMaintenanceActions => ({
    type: orderMaintenanceTypes.SET_ORDER_MAINTENANCE,
    payload
  }),
}

export default actions
