import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Tag, Button } from '../../../../UI'
import { ItemProps } from './types'
import useStyles from './styles'
import { USER_TYPE } from '../../../../../helpers'
import Tags from '../../../../UI/CustomUI/molecules/Tags'
import { getConfigValue } from '../../../../../ducks/selectors'

const AdminItem: FC<ItemProps> = ({ adminClient, handleAddMember, handleOpenEditStates }) => {
  const classes = useStyles()
  const { id, firstName, email, lastName, region, registrationComplete, disabled } = adminClient
  const status = disabled ? 'Disabled' : !registrationComplete ? 'Pending' : 'Enabled'
  const userType = useSelector(getConfigValue('userType'))
  const isInstitutional = userType === USER_TYPE.INSTITUTIONAL

  return (
    <Grid item className={classes.container} key={id} spacing={3}>
      <Grid item className={classes.cell} xs={12}>
        <Typography className={classes.title}>
          {firstName} {lastName}
        </Typography>
        {isInstitutional && (
          <Typography className={classes.description}>
            {email[0].email}
          </Typography>)}
      </Grid>


      {isInstitutional
        ? (
          <Grid item className={classes.itemContainer} xs={12}>
            {region?.values && <Tags tags={region.values} limit={4} />}
          </Grid>)
        : (
          <Grid item className={classes.itemContainer} xs={12}>
            {email[0].email}
          </Grid>)}

      <Grid item className={`${classes.itemContainer} ${classes.status}`} xs={12}>
        <Tag type={status} variation='body1' />
      </Grid>

      <Grid item className={classes.itemContainer} xs={12}>
        {status === 'Pending' && (
          <Button
            type='submit'
            variant='outlined'
            onClick={() => handleAddMember({ email: email[0].email, firstName, lastName, type: 'Client' })}
            className={`${classes.button} ${classes.resendButton}`}
          >
            Resend invite
          </Button>)}
      </Grid>

      <Grid item className={classes.itemContainer} xs={12}>
        {isInstitutional && (
          <Button
            type='submit'
            variant='outlined'
            onClick={() => handleOpenEditStates({ email: email[0].email, id, states: region?.values })}
            className={`${classes.button} ${classes.resendButton}`}
          >
            Edit States
          </Button>)}
      </Grid>

    </Grid>
  )
}

export default AdminItem
