import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import BooleanCheckbox from 'components/UI/CustomUI/molecules/BooleanCheckbox'
import { useStepperContext } from 'hooks/useStepperContext'
import { useSelector } from 'react-redux'
import { getApprovalValues, getUserIsHomeowner } from 'ducks/selectors'
import useStyles from './../../styles'

const PayAtClose = () => {
  const classes = useStyles()

  const { showWarnings, saveNewValue } = useStepperContext()
  const {
    paymentOption,
    propertyToBeSold,
    agentIsHomeowner,
    agentAsHomeownerRepresentative,
  } = useSelector(getApprovalValues())

  const isHomeowner = useSelector(getUserIsHomeowner)

  const options = [
    { key: 'listing_agent', value: 'Yes' },
    { key: 'no_selected', value: 'No' },
    {
      key: 'myself',
      value: 'As a licensed real estate agent, I am representing myself',
    },
  ]

  return (
    <>
      <Grid
        style={{
          background: '#FCFCFC',
          borderRadius: '8px',
          padding: '1rem',
          boxShadow:
            '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
        }}
      >
        <Typography className={`${classes.noMargin} ${classes.title}`}>💵  payment options</Typography>
        {isHomeowner && paymentOption === "pay_at_close" && <Typography style={{ marginBottom: 12 }}>You indicated in your estimate request that you are selling or about to sell your home.</Typography>}
        <Typography variant="body1" className={classes.latoPrimaryText}>
          Would you like to pay-at-close?
        </Typography>
        <BooleanCheckbox
          onChange={(ev) => {
            const isPayAtClose = ev.target.value === 'true'
            saveNewValue('agentAsHomeownerRepresentative', null)
            saveNewValue(
              'paymentOption',
              isPayAtClose ? 'pay_at_close' : 'pay_at_completion'
            )
          }}
          value={paymentOption === 'pay_at_close'}
          error={showWarnings && typeof propertyToBeSold === 'undefined'}
          fullWidth={false}
        />

        {agentIsHomeowner && paymentOption === 'pay_at_close' && (
          <>
            <Divider style={{ margin: '1rem 0 .5rem', opacity: 0.4 }} />
            <Typography variant="body1" className={classes.latoPrimaryText}>
              Do you have a Listing Agent?
            </Typography>
            <Grid>
              <RadioGroup
                value={agentAsHomeownerRepresentative}
                onChange={(ev) => {
                  saveNewValue('agentAsHomeownerRepresentative', ev.target.value)
                }}
                style={{ width: '100%', flexDirection: 'row', gap: '1rem' }}
              >
                {options.map((option, index) => (
                  <Grid item style={{ flex: 1 }}>
                    <FormControlLabel
                      key={index}
                      value={option.key}
                      control={<Radio color="primary" />}
                      label={
                        <Typography
                          style={{ fontSize: '18px', lineHeight: 0.8 }}
                          variant="caption"
                        >
                          {option.value}
                        </Typography>
                      }
                      className={classes.formLabel}
                    />
                  </Grid>
                ))}
              </RadioGroup>
            </Grid>
          </>
        )}

        {agentIsHomeowner && agentAsHomeownerRepresentative === 'no_selected' && (
          <Grid className={classes.listingDisclaimer}>
            <Typography
              className={classes.yellowBox}
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              ⚠️ In order to Pay at Close, we require listing agreement between
              you and real estate agent.
            </Typography>
          </Grid>
        )}
      </Grid>
      {isHomeowner && paymentOption === "pay_at_close" && <Grid>
        <Typography className={classes.latoPrimaryText}>💰  What is “Pay at Close”?</Typography>
        <Typography style={{ marginBottom: '1rem' }}>"Pay at close" is a convenient payment option allowing homeowners to defer the majority of their home improvement costs until their home is sold. A 20% deposit is due upfront, and the balance is deducted from the sale proceeds at closing, making it easier for homeowners to enhance their property without immediate financial strain.</Typography>
        <Typography>This option helps increase the home's market value, potentially leading to a higher selling price. It benefits both the homeowner and the agent, as necessary improvements are completed, enhancing the property's appeal and marketability.</Typography>
      </Grid>}
    </>
  )
}
export default PayAtClose
