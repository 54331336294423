import { Grid, Typography } from "components/UI"
import { PERKS } from "helpers/index";
import PerkBox from "./PerkBox"

import useStyles from './styles';

const PerksModal = () => {
  const classes = useStyles()

  return (
    <Grid>
      <Grid
        className={classes.container}>
        {PERKS.map(perk =>
          <PerkBox
            img={perk.img}
            backgroundColor={perk.backgroundColor}
            title={perk.title}
            bullets={perk.bullets}
          />
        )}
      </Grid>
      <Typography className={classes.disclaimer}>
        The scope of the maintenance services is intended to cover customary equipment and costs; any equipment or material that is not customary may not be covered or may require additional fees.
      </Typography>
    </Grid>
  )
}
export default PerksModal