/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from 'helpers'
import { Punchlist } from 'api'
import { campaignsActions } from 'ducks/actions'
import * as Sentry from '@sentry/react'
import { FetchCampaignsAction, campaignsTypes } from './types'
import { getUser } from 'ducks/selectors'

export function* fetchCampaigns({ payload, callback }: FetchCampaignsAction): SagaIterator {
  const user = yield select(getUser)

  let success = false
  try {
    const campaigns = yield call(Punchlist.campaigns.getCampaigns, payload)

    if (campaigns) {
      yield put(campaignsActions.setCampaigns(campaigns))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('get the discount banner'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(campaignsTypes.FETCH_CAMPAIGNS, fetchCampaigns)
}
