import { Grid, Radio, Typography } from '@material-ui/core'
import FormRadioButton from 'components/UI/CustomUI/atoms/FormRadioButton'

import useStyles from './../../styles'
import { Autocomplete, STextField, TextFieldLabel } from 'components/UI'
import clsx from 'clsx'
import { EstimateContact } from 'ducks/types'
import {
  APPROVAL_TYPE,
  CONTACT_ROLE,
  PHONE_MASK_INPUT,
  getContactRelationOpt,
  validatePhone,
} from 'helpers/index'
import { useEffect, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { useSelector } from 'react-redux'
import {
  getApprovalValues,
  getConfigValue,
  getContactInEstimate,
  getEstimate,
  getUser,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from 'ducks/selectors'

export interface SchedulerContactProps {
  // onClick: (val?: 'me' | 'other') => void
  // value: 'me' | 'other'
  showWarnings?: boolean
}

const SchedulerContact = ({}: // onClick,
// value,
SchedulerContactProps) => {
  const classes = useStyles()
  const { saveNewValue, showWarnings } = useStepperContext()
  const { scheduler, schedulerSelect, agentIsHomeowner, role } = useSelector(
    getApprovalValues()
  )
  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)

  const homeowner = estimate?.properties?.contacts?.find(
    (c) => c.role === CONTACT_ROLE.HOMEOWNER
  )
  const contactInEstimate = useSelector(getContactInEstimate(userEmail))
  const isHomeowner = agentIsHomeowner || getUserIsHomeowner() 

  const updateValue = (key: keyof EstimateContact, value: string | boolean) => {
    saveNewValue('scheduler', { ...scheduler, [key]: value })
  }

  const handleChangeScheduler = (val?: 'me' | 'other' | 'homeowner') => {
    saveNewValue('schedulerSelect', val)
    if (val === 'other') {
      saveNewValue('scheduler', {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        approvalType: APPROVAL_TYPE.NOT_NEEDED,
        isPayer: false,
        isScheduler: true,
      })
    }
    if (val === 'me') {
      saveNewValue('scheduler', { ...contactInEstimate, isScheduler: true })
    }
    if (val === 'homeowner') {
      saveNewValue('scheduler', { ...homeowner, isScheduler: true })
    }
  }

  return (
    <Grid>
      <Typography className={classes.title}>
        {isHomeowner
          ? `📞  contact to coordinate scheduling`
          : `🗓️ contact for scheduling the project`}
      </Typography>
      <Grid container item spacing={2} style={{ width: '100%' }}>
        <Grid lg={3} xs={6} item style={{ flex: 1 }}>
          <FormRadioButton
            label={
              <Typography style={{ color: 'var(--text-text-primary)' }}>
                Me
              </Typography>
            }
            value={true}
            control={<Radio color="primary" />}
            onClick={() => handleChangeScheduler('me')}
            checked={schedulerSelect === 'me'}
            style={{ width: '100%' }}
          />
        </Grid>

        {isHomeowner && (
          <Grid lg={3} xs={6} item style={{ flex: 1 }}>
            <FormRadioButton
              label={
                <Typography style={{ color: 'var(--text-text-primary)' }}>
                  Other
                </Typography>
              }
              value={false}
              control={<Radio color="primary" />}
              onClick={() => handleChangeScheduler('other')}
              checked={schedulerSelect === 'other'}
              style={{ width: '100%' }}
            />
          </Grid>
        )}
        {!isHomeowner && (
          <Grid lg={3} xs={6} item style={{ flex: 1 }}>
            <FormRadioButton
              label={
                <Typography style={{ color: 'var(--text-text-primary)' }}>
                  Homeowner
                </Typography>
              }
              value={false}
              control={<Radio color="primary" />}
              onClick={() => handleChangeScheduler('homeowner')}
              checked={schedulerSelect === 'homeowner'}
              style={{ width: '100%' }}
            />
          </Grid>
        )}
      </Grid>
      {schedulerSelect === 'other' && (
        <Grid item container spacing={2} lg={12}>
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} lg={6}>
              <Typography className={classes.latoPrimaryText}>
                Relationship to you
              </Typography>
              <Autocomplete
                label=""
                value={getContactRelationOpt().find(
                  (o) => o.key === scheduler?.role
                )}
                error={showWarnings && !scheduler?.role}
                options={getContactRelationOpt()}
                onChange={(value: any) => {
                  updateValue('role', value.key)
                  // saveNewValue('relation', value.key)
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className={classes.latoPrimaryText}>
              First Name
            </Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !scheduler?.firstName ? classes.errorField : {}
              )}
              value={scheduler?.firstName}
              error={showWarnings && !scheduler?.firstName}
              onChange={(event: any) => {
                updateValue('firstName', event.target.value)
              }}
              placeholder="First Name"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className={classes.latoPrimaryText}>
              Last Name
            </Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !scheduler?.lastName ? classes.errorField : {}
              )}
              value={scheduler?.lastName}
              error={showWarnings && !scheduler?.lastName}
              onChange={(event: any) => {
                updateValue('lastName', event.target.value)
              }}
              placeholder="Last Name"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className={classes.latoPrimaryText}>Email</Typography>
            <STextField
              className={clsx(
                classes.signature,
                showWarnings && !scheduler?.email ? classes.errorField : {}
              )}
              value={scheduler?.email}
              error={showWarnings && !scheduler?.email}
              onChange={(event: any) => {
                updateValue('email', event.target.value)
              }}
              placeholder="Email"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              className={classes.latoPrimaryText}
              style={{ marginBottom: '8px' }}
            >
              Phone:
            </Typography>
            <TextFieldLabel
              label=""
              type="tel"
              value={scheduler?.phone || ''}
              mask={PHONE_MASK_INPUT}
              error={
                showWarnings &&
                (!scheduler?.phone || !validatePhone(scheduler?.phone))
              }
              onChange={(event: any) => {
                updateValue('phone', event.target.value)
              }}
              placeholder="555-555-1234"
              className={classes.cellphone}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default SchedulerContact
