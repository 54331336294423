import { Checkbox, Grid, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'

export interface SmsConsentProps {}

const SmsConsent: FC<SmsConsentProps> = () => {
  const classes = useStyles()
  const { showWarnings, saveNewValue } = useStepperContext()

  const { smsConsent } = useSelector(getApprovalValues())

  return (
    <>
      <Grid
        container
        item
        className={classes.consent}
        style={{
          borderColor:
            showWarnings && !smsConsent
              ? 'var(--border-border-error-light)'
              : '#99DDBD',
        }}
      >
        <Checkbox
          checked={smsConsent}
          onChange={() => saveNewValue('smsConsent', !smsConsent)}
          name="consent"
          color="primary"
          style={{
            color: showWarnings && !smsConsent ? 'red' : 'primary',
            padding: 0,
            height: '24px',
          }}
        />
        <Typography className={classes.consentText}>
          💬 📲 I consent to receive SMS messages from BOSSCAT regarding the
          project.
        </Typography>
      </Grid>
    </>
  )
}

export default SmsConsent
