import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      // margin: '30px 0px 10px 0px'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    rows: {
      display: 'flex',
      flexDirection: 'row'
    },
    rowRight: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-end !important',
      justifyContent: 'flex-end !important',
      [theme.breakpoints.down('md')]: {
        alignContent: 'center !important',
        justifyContent: 'center !important'
      }
    },
    container: {
      flexWrap: 'nowrap',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '.5rem .5rem 0',
      gap: '1rem',
      [theme.breakpoints.down('md')]: {
        gap: '0.2rem'
      }
    },
    autocompleteContainer: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    autocomplete: {
      width: '90px',
      height: '40px',
      margin: '0px',
      minWidth: '160px',
      padding: '0 0.5rem',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      '@global': {
        '.MuiInputBase-root': {
          height: 'fit-content'
        },
        '.MuiButtonBase-root': {
          height: 'fit-content'
        },
        '.MuiAutocomplete-endAdornment': {
          top: '0.2rem'
        }
      }
    },
    title: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 20px 0px 0px',
      color: 'var(--better-bold-color)',
      minWidth: '60px',
      [theme.breakpoints.down('md')]: {
        minWidth: '20px',
        margin: '0px'
      }
    },
    subtitle: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-bold-color)'
    },
    subtitleSelected: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--white-color)'
    },
    text: {
      minWidth: '106px',
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '5px 16px 0px 2px',
      color: 'var(--better-bold-color)'
    },
    detail: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 400,
      lineHeight: '24px',
      fontSize: '16px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-color)'
    },
    total: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 700,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 0px 0px 0px',
      color: 'var(--better-bold-color)'
    },
    totalValue: {
      fontFamily: 'BetterSansRegular',
      fontWeight: 500,
      lineHeight: '58px',
      fontSize: '24px',
      margin: '0px 4px',
      color: 'var(--better-bold-color)'
    },
    icon: {
      color: 'var(--better-color)'
    },
    logo: {
      height: '66px',
      margin: '0px 9px 0px 0px'
    },
    pdf: {
      height: '64px',
      width: '64px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
        width: '44px'
      }
    },
    button: {
      height: '33px',
      width: '136px',
      minWidth: '136px',
      margin: '0px 2px',
      textTransform: 'none',
      border: '1px solid var(--better-color)',
      borderRadius: '8px',
      backgroundColor: 'var(--better-light-color)',
      '&:hover': {
        backgroundColor: 'var(--better-light-color)'
      },
      [theme.breakpoints.down('md')]: {
        width: '100px'
      }
    },
    combo: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      margin: '0px 6px',
      textTransform: 'none',
      borderRadius: '8px',
      backgroundColor: 'var(--row-background)',
      '&:hover': {
        backgroundColor: 'var(--row-background)'
      }
    },
    comboSelected: {
      height: '33px',
      width: '104px',
      minWidth: '100px',
      margin: '0px 6px',
      textTransform: 'none',
      borderRadius: '8px',
      backgroundColor: 'var(--better-color)',
      '&:hover': {
        backgroundColor: 'var(--better-color)'
      }
    },
    iconCombo: {
      color: 'var(--better-bold-color)'
    },
    iconSelected: {
      color: 'var(--white-color)'
    },
    switch: {
      width: '28px !important',
      height: '22px !important',
      padding: '2px 0px !important',
      // margin: '6px 5px',
      alignSelf: 'center',
      display: 'flex !important',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)'
        }
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        top: -16,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: 'var(--better-color)'
          }
        }
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200
        })
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
      },
    },
    unSelected: {
      borderRadius: '8px',
      border: '2px solid var(--bosscat-grey-400)',
      padding: '8px',
      boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
      textTransform: 'none',
      backgroundColor: 'white',
      height: '40px',
      minWidth: 'fit-content',
    },
    selected: {
      padding: '8px',
      borderRadius: '8px',
      border: '2px solid var(--hardware-haze-700)',
      backgroundColor: 'var(--hardware-haze-600)',
      textTransform: 'none',
      height: '40px',
      minWidth: 'fit-content',
      '@global': {
        '.MuiTypography-root': {
          fontWeight: 900,
          color: 'white !important'
        }
      }
    },
    midFont: {
      fontFamily: 'LatoNormal',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '150%',
      textTransform: 'capitalize',
    },
    Tabs: {
      display: 'flex',
      gap: '.5rem',
      // minHeight: 40,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '85vw'
      },

      '@global': {
        '.MuiTabScrollButton-root.Mui-disabled': {
          display: 'none !important'
        },
        '.MuiButtonBase-root': {
          minHeight: 'fit-content',
          padding: '0.2rem 0.5rem',
          // opacity: '1 !important'
        },
        '.MuiTabs-scrollButtons': {
          padding: '0',
          width: 40,
          height: 40,
          backgroundColor: '#F5F6F7',
          borderRadius: 8,
          boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)'
        },
        '.MuiTabs-scroller': {
          background: 'white',
          //display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            display: 'block'
          }
        }
      },

    },
    filterButton: {
      width: 40,
      height: 40,
      boxShadow: '3px 3px 14px 0px rgb(0 0 0 / 11%)',
      '@global': {
        '.MuiSvgIcon-root': {
          width: '1rem',
          fill: 'var(--bosscat-blue-600)'
        }
      }
    },
    filtersModal: {
      '@global': {
        '.MuiDialog-container': {
          alignItems: 'flex-end'
        },
        '.MuiDialogContent-root': {
          gap: '1rem'
        },
        '.MuiPaper-root': {
          maxWidth: '100% !important',
          margin: 0,
          width: '100%'
          // height: '50vh'
        }
      }
    },
    filtersModalTitle: {
      fontFamily: 'NextArtHeavy',
      textAlign: 'center',
      width: '100%'
    }
  })
)
