import { createStyles, makeStyles, Theme as ThemeMUI } from "@material-ui/core";


export default makeStyles((theme: ThemeMUI) =>
    createStyles({
        container: {

        },
        item: {
            fontFamily: 'LatoNormal',
            fontSize: 18,
            padding: '.2rem'
        }
    }))
