import { Box, CircularProgress, Typography } from "@material-ui/core"
import { FC } from "react"
import { ImageBadgeProps } from "./types"

import styles from './styles.module.scss'

const ImageBadge: FC<ImageBadgeProps> = ({ img, badge, alt = img, variant = "primary", maxWidth, maxHeight, loading }) => {
  return (
    <Box className={`${styles.ImageBadge} ${styles[variant]}`} style={{ maxWidth: maxWidth, maxHeight: maxHeight }}>
      {loading ?
        <CircularProgress className={styles.ImageBadge__loading} />
        :
        badge &&
          typeof 'string' ?
          <Typography className={styles.ImageBadge__text}> {badge}</Typography>
          : badge
      }
      <img src={img} alt={alt} className={styles.ImageBadge__image} />
    </Box >
  )
}

export default ImageBadge