import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollWrapper } from '../..'
import { configActions } from '../../../../ducks/actions'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from '../../../../hooks'
import { Box, Typography } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const Template: FC<TemplateProps> = ({
  children,
  title,
  header,
  relativeClass = '',
  absoluteClass = '',
  customPadding = '',
  showBanner = true,
  titleAlign = 'center',
}) => {
  const classes = useStyles()

  const [xsScreen] = useIsXsScreen()
  const [smScreen] = useIsSmScreen()
  const [mdScreen] = useIsMdScreen()
  const smallScreen = xsScreen || smScreen || mdScreen
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(
        configActions.setConfigValue({ type: 'redirectPath', value: null })
      )
    }
  }, [dispatch])

  return (
    <Box className={classes.root}>
      {header}
      {title &&
        (typeof title === 'string' ? (
          <Typography variant="h4" className={`${classes.title} ${titleAlign === 'left' && classes.titleLeft}`}>
            {title}
          </Typography>
        ) : (
          title
        ))}
      <ScrollWrapper
        relativeClass={relativeClass}
        absoluteClass={absoluteClass}
        relativeStyle={smallScreen ? { width: '100%' } : {}}
        absoluteStyle={
          customPadding
            ? { padding: customPadding }
            : smallScreen
            ? { padding: '0' }
            : { padding: '0 48px' }
        }
      >
        <>
          {/* {showBanner && <HolidayBanner />} */}
          {children}
        </>
      </ScrollWrapper>
    </Box>
  )
}

export default Template
