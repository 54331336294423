import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import useStyles from './styles'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { ESTIMATE_PUBLIC_STATUS, ESTIMATE_VIEW, ITEM_CATEGORY, ITEM_STATUS, formatTimestamp, formatTimestampDate, sortItemsByCategories } from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate, getEstimateItemsGroupBy, getUser } from 'ducks/selectors'
import EstimateItem from '../EstimateItem'
import { Item } from 'ducks/types'
import { blueHouse, house, houseInfrastructure, icons } from 'assets'
import Modal from 'components/UI/CustomUI/molecules/Modal'
import { DatePicker } from 'components/UI'
import { SingleDatePicker } from 'components/UI/CustomUI/atoms/DatePicker/SingleDatePicker'
import { clientQuestionsActions } from 'ducks/actions'
import { COMMUNICATION_METHODS } from 'ducks/clientQuestions/types'
import moment from 'moment'
import Badge from '../EstimateLeft/Badge'
import MarketingContent from '../MarketingContent'


export interface EstimateDetailModalProps {
  ptp?: boolean
  // setOpenModal: (value: boolean) => void
}

const EstimateDetailModal: FC<EstimateDetailModalProps> = ({ ptp }) => {
  const classes = useStyles()
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography className={classes.estimateInfoTitle}>
          {estimate?.properties.address?.line_1 || ''}
        </Typography>
        <Typography className={classes.estimateInfoSubtitle}>
          {estimate?.properties.address?.city || ''}
        </Typography>
      </Grid>
      <Grid item justifyContent='center' className={classes.row}>
        <Badge
          status={estimate?.publicStatus as ESTIMATE_PUBLIC_STATUS}
          styles={{
            position: 'static',
            width: 'fit-content'
          }}
        />
      </Grid>

      <Grid item>
        <Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>Requested by:</Typography>
            <Typography className={classes.infoValue}>
              {estimate?.properties?.requester?.fullName}
            </Typography>
          </Box>
          <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate created on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.createdOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>
          {estimate?.lastDeliveredOn && <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate updated on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.lastDeliveredOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>}
          {estimate?.approvedOn && !ptp && <Box className={classes.infoRow}>
            <Typography className={classes.infoLabel}>
              Estimate approved on:
            </Typography>
            <Typography className={classes.infoValue}>
              {formatTimestamp(estimate?.approvedOn, 'MM/DD/YYYY')}
            </Typography>
          </Box>}
        </Box>
      </Grid>
      <MarketingContent />
    </Grid>
  )
}

export default EstimateDetailModal
