import { FC } from 'react'
import { Typography, Box, PaymentCalendar, Promotion } from '../../..'
import { PaymentOption } from '../../../../../ducks/types'
import useStyles from './styles'
import { PaymentProps } from './types'

const PaymentStep: FC<PaymentProps> = ({
  days = [],
  serviceable = true,
  address = '',
  type = 'feedback',
  onPaymentSuccess,
  publicEstimate = false
}) => {
  const classes = useStyles()


  const onClick = (paymentOption: PaymentOption) => {
    if (onPaymentSuccess) onPaymentSuccess(paymentOption)
  }

  return (
    <Box className={classes.root} aria-label='payment-step'>
      <Box className={classes.row}>
        <Typography className={classes.title1}>
          {publicEstimate ? 'Last step:' : 'How soon'}
        </Typography>
        <Typography className={classes.title2}>
          {publicEstimate
            ? 'select your delivery date.'
            : `would you like your ${type}?`}
        </Typography>
      </Box>
      <Typography variant='body2' className={classes.textAddress}>
        Choose your delivery date below. You'll receive an email when your estimate is ready.
      </Typography>

      {!serviceable && !publicEstimate && (
        <Box className={classes.noServiceable}>
          {address && (
            <Typography style={{ fontSize: '18px' }}>{address}</Typography>
          )}
          <Box>
            <Typography style={{ display: 'inline', fontSize: '16px' }}>
              This address is outside of our current service area. We are happy
              to provide a fast and accurate estimate
            </Typography>
            <Typography style={{ display: 'inline', fontSize: '16px' }}>
              {
                ' but will not be able to perform the repairs, for this address, at this time.'
              }
            </Typography>
          </Box>
        </Box>
      )}
      {!publicEstimate && <Promotion />}
      <PaymentCalendar days={days} onClick={onClick} />

      <Typography variant='body2' className={classes.estimate}>
        <span>*</span> Estimates will be delivered by 9pm at the end of business
        on the selected day.
      </Typography>
      <Typography variant='body2' className={classes.estimates}>
        <span>**</span> Estimate delivery dates may be affected by holidays.
      </Typography>
    </Box>
  )
}

export default PaymentStep
