import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import { useSelector } from 'react-redux'
import useStyles from './styles'
import { FC, ReactNode, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { TextFieldLabel } from 'components/UI'
import Checkbox from 'components/UI/CustomUI/molecules/Checkbox'
import { manNotes } from 'assets/index'
import { PHONE_MASK_INPUT, validateEmail, validatePhone } from 'helpers/index'

import { icons } from 'assets'

interface Button {
  title: string
  action?: () => void
  disable?: boolean
  icon?: ReactNode
  loading?: boolean
}

export interface BottomButtonsProps {
  buttonNext: Button
  buttonBack?: Button
  showNextButton?: boolean
}

const BottomButtons: FC<BottomButtonsProps> = ({
  buttonBack,
  buttonNext,
  showNextButton = true,
}) => {
  const classes = useStyles()

  return (
    <Grid
      item
      container
      xs={12}
      style={{ display: 'flex' }}
      justifyContent="space-between"
    >
      <Button
        type="submit"
        variant="contained"
        size="small"
        className={classes.buttonBack}
        startIcon={buttonBack?.icon || <icons.ArrowBack />}
        onClick={buttonBack?.action || (() => {})}
        disabled={buttonBack?.disable || buttonBack?.loading}
      >
        {buttonBack?.loading ? <CircularProgress /> : buttonBack?.title}
      </Button>
      {showNextButton && (
        <Button
          type="submit"
          variant="contained"
          size="small"
          className={classes.buttonNext}
          endIcon={buttonNext?.icon || <icons.ArrowForward />}
          onClick={buttonNext?.action || (() => {})}
          disabled={buttonNext?.disable || buttonNext?.loading}
        >
          {buttonNext?.loading ? <CircularProgress /> : buttonNext?.title}
        </Button>
      )}
    </Grid>
  )
}

export default BottomButtons
