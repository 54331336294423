/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  getNewEstimateData,
  getNewEstimateExpediteOption,
  getNewEstimateSelectedOption,
} from '../../../../../../ducks/selectors'
import { PaymentOption } from '../../../../../../ducks/types'
import { isEmpty } from '../../../../../../helpers'
import { DispatchPaymentCreditCardContext } from '../../../../../UI/CustomUI/organisms/PaymentCreditCard/context'
import { dispatchPaymentCreditCardTypes } from '../../../../../UI/CustomUI/organisms/PaymentCreditCard/context/types'
import { PaymentCreditCard } from '../../../../../UI/CustomUI'

const ExpeditePage: FC<{ goBack: () => void }> = ({ goBack }) => {
  const { id: estimateId } = useSelector(getNewEstimateData())
  const paymentOption = useSelector(getNewEstimateSelectedOption())
  const expediteOption = useSelector(getNewEstimateExpediteOption())

  const { dispatch: dispatchContext } = useContext(
    DispatchPaymentCreditCardContext
  )

  const setValue = (attr: string, value: any) => {
    dispatchContext({
      type: dispatchPaymentCreditCardTypes.SET_VALUE,
      payload: { attr, value },
    })
  }

  const { days } = expediteOption as PaymentOption
  const amount = (expediteOption?.price ?? 0) - (paymentOption?.price ?? 0)

  useEffect(() => {
    if (amount > 0) setValue('amount', amount)
  }, [amount])

  useEffect(() => {
    if (!isEmpty(estimateId)) setValue('estimateId', estimateId)
  }, [estimateId])

  return <PaymentCreditCard goBack={goBack} days={days} isExpedit={true} />
}

export default ExpeditePage
