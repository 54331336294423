import React, { FC } from 'react'
import { Tag } from '../../atoms'
import { Box } from '../../../../UI'
import { TagsProps } from './types'

import useStyles from './styles'

const Tags: FC<TagsProps> = ({ tags, limit }) => {
    const classes = useStyles()
    //const tagsToShow = limit ? tags.slice(0, limit) : tags
    const tagsToShow = tags

    return (
        <Box className={classes.container}>
            {tagsToShow.map((tag, index) =>
                <Tag className={classes.tag} key={index}>{tag}
                </Tag>
            )}
            {/*   {limit && tags.length > limit && (
                <Tag className={`${classes.tag} ${classes.tagMore}`} color="primary">+ {tags.length - limit} more</Tag>
            )} */}
        </Box>
    )
}

export default Tags