import { ChartProps } from './types'
import { Pie } from 'react-chartjs-2'

const PieChart = (props: ChartProps): JSX.Element => {
  return (
    <div>
      <Pie
        data={props.data}
        options={{ plugins: { legend: { display: false } } }}
      />
    </div>
  )
}
export default PieChart
