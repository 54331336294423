import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import env from '@beam-australia/react-env'
import { icons } from '../../../../../assets'
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress
} from '../../../MaterialUI'
import useStyles from './styles'
import { authActions, configActions } from '../../../../../ducks/actions'
import { history, isEmpty, ParseFilesUrl, USER_TYPE } from '../../../../../helpers'
import {
  getConfigValue,
  isRegisterCompleted
} from '../../../../../ducks/selectors'
import { useClassName } from '../../../../../hooks'

const FILES_URL = env('FILES_URL') ?? ''
const isDev = env('ENVIRONMENT') === 'development'

const UserMenu: FC<{ avatar: string }> = ({ avatar }) => {
  const classes = useStyles()
  const className = useClassName()
  const dispatch = useDispatch()
  const replace = history.useReplace()
  const push = history.usePush()

  const [error, setError] = useState(false)

  const userType = useSelector(getConfigValue('userType'))
  const isAdmin = useSelector(getConfigValue('isAdmin'))

  const registerCompleted = useSelector(isRegisterCompleted)

  const [attempts, setAttempts] = useState(5)
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isHomeowner = userType === USER_TYPE.HOMEOWNER

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSwitchUserType = () => {
    dispatch(configActions.switchUserType())
  }

  const handleLogout = () => {
    dispatch(
      authActions.logoutRequest({
        callback: success => {
          if (success) replace('/')
        }
      })
    )
  }

  useEffect(() => {
    setAttempts(5)
    !isEmpty(avatar) && setError(false)
  }, [avatar])

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  const handleError = () => {
    if (attempts > 0 && avatar) {
      setAttempts(attempts - 1)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setLoading(false)
      setError(true)
    }
  }

  const getPicture = (): JSX.Element => {
    if (loading) {
      return (
        <Box>
          <CircularProgress color='inherit' className={classes.loading} />
        </Box>
      )
    }

    if (!error) {
      return (
        <img
          onError={handleError}
          alt=''
          src={
            avatar !== ''
              ? ParseFilesUrl({ baseUrl: FILES_URL, avatar })
              : ''
          }
          className={classes.avatar}
        />
      )
    }
    return (
      <Avatar alt='' className={classes.icon}>
        <icons.Person
          fontSize='large'
          className={classes.personIcon}
        />
      </Avatar>
    )
  }

  return (
    <Box className={classes.root} id='user-menu'>
      {isDev && (
        <Box className={classes.dev}>
          <Typography variant='caption' color='textSecondary'>
            DEV: {userType}
          </Typography>
        </Box>
      )}
      <AppBar elevation={0} position='static' color='transparent'>
        <Toolbar style={{ minHeight: '48px' }}>
          <Box className={classes.menu}>
            {/*
            <IconButton size='small' className={classes.icon}>
              <icons.NotificationsNone />
            </IconButton>
            */}
            <Box onClick={handleOpenMenu} className={classes.avatarBox}>
              {getPicture()}
            </Box>
            <Menu
              anchorEl={anchorEl}
              //keepMounted
              getContentAnchorEl={null}
              className={classes.menuContent}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {isDev && (
                <MenuItem
                  className={className(
                    classes.menuItems,
                    classes.firstMenuItem
                  )}
                  onClick={handleSwitchUserType}
                >
                  <Typography>Switch (dev)</Typography>
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuItems}
                // disabled={!registerCompleted}
                onClick={() => {
                  handleCloseMenu()
                  push('u/profile')
                }}
              >
                <icons.Person className={classes.menuItemIcon} />
                <Typography>Profile</Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItems}
                disabled={!registerCompleted}
                onClick={() => {
                  handleCloseMenu()
                  push('u/settings')
                }}
              >
                <icons.Settings className={classes.menuItemIcon} />
                <Typography>Settings</Typography>
              </MenuItem>
              {isAdmin && !isHomeowner &&
                <MenuItem
                  className={classes.menuItems}
                  onClick={() => {
                    handleCloseMenu()
                    push('u/admin')
                  }}
                >
                  <icons.SupervisedUserCircle className={classes.menuItemIcon} />
                  <Typography>Admin Panel</Typography>
                </MenuItem>
              }
              <MenuItem
                className={className(
                  classes.menuItems,
                  classes.logOut,
                  classes.lastMenuItem
                )}
                onClick={handleLogout}
              >
                <icons.SubdirectoryArrowLeft className={classes.menuItemIcon} />
                <Typography>Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default UserMenu
