import { Box, CircularProgress } from '@material-ui/core';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import useStyles from './styles';

function CalendlyConsultation() {
    const classes = useStyles();

    useCalendlyEventListener({
        onEventScheduled: (e: any) => {
            const windowAny = window as any;
            const calendlyEventId = e?.data?.payload?.event?.uri;
            windowAny?.ReactNativeWebView?.postMessage(`calendlyEventId=${calendlyEventId}`);
        },
    });

    return (
        <Box width={"100%"} display="flex" gridGap="16px">
            <InlineWidget
                styles={{
                    width: '100%',
                    maxWidth: '100%',
                    minHeight: '100vh'
                }}
                LoadingSpinner={() => <Box className={classes.spinner}><CircularProgress size={60} /></Box>}
                url="https://calendly.com/bosscat/phone-call-consultation?hide_gdpr_banner=1"
            />
        </Box>
    )
}

export default CalendlyConsultation