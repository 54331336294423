import { Box, Button, Grid, Popover, Typography } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow'
import { ReactNode, useState } from 'react'

export const ThreeDotsPopup = ({
  otherIcon,
  options,
  onChange,
}: {
  otherIcon: ReactNode
  options: any[]
  onChange: (newOption: string) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        style={{
          borderRadius: '50%',
          minWidth: '32px',
          width: '32px',
          height: '32px',
          backgroundColor: '#F5F6F7',
        }}
      >
        {otherIcon || <MoreVert style={{ width: '16px', height: '16px' }} />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ style: { overflow: 'hidden' } }}
      >
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ padding: '4px' }}
        >
          {options.map((option) => (
            <Button
              style={{
                padding: '4px',
                paddingLeft: '16px',
                gap: '8px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: 'fit-content',
              }}
              onClick={() => {
                handleClose()
                onChange(option.label)
              }}
              startIcon={option.icon}
            >
              <Typography style={{ fontSize: '18px', textTransform: 'none' }}>
                {option.label}
              </Typography>
            </Button>
          ))}
        </Grid>
      </Popover>
    </>
  )
}
