/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Files, Punchlist } from 'api'
import { errorTextTryingTo, getFileExtension } from 'helpers'

import {
  filesTypes,
  UploadFileAction,
  UploadFilesAction
} from './types'
import * as Sentry from '@sentry/react'

export function* uploadFile({ payload, callback }: UploadFileAction): SagaIterator {
  let success = false

  try {
    const extension = getFileExtension(payload)
    const { fileName, uploadUrl } = yield call(Punchlist.files.uploadFile, { extension })

    const result = yield call(Files.uploadFile, uploadUrl, payload)
    success = result !== null
    yield call(callback, success, fileName)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('upload a file'))
    yield call(callback, false)
  }
}

export function* uploadFiles({ payload, callback }: UploadFilesAction): SagaIterator {
  let success = true
  const fileNameList: string[] = []

  try {
    for (let index = 0; index < payload.length; index++) {
      if (success) {
        const file = payload[index] as File
        const extension = getFileExtension(file)
        const { fileName, uploadUrl } = yield call(Punchlist.files.uploadFile, { extension })
        const result = yield call(Files.uploadFile, uploadUrl, file)
        if (result !== null) fileNameList.push(fileName)
        else success = false
      }
    }
    yield call(callback, success, fileNameList)
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('upload a file'))
    yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(filesTypes.UPLOAD_FILE, uploadFile)
  yield takeLatest(filesTypes.UPLOAD_FILES, uploadFiles)
}
