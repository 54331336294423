/** TYPES **/
export const filesTypes = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILES: 'UPLOAD_FILES'
}

/** ACTIONS **/
export interface UploadFileAction {
  type: typeof filesTypes.UPLOAD_FILE
  payload: File
  callback: (succ: boolean, fileName?: string) => void
}

export interface UploadFilesAction {
  type: typeof filesTypes.UPLOAD_FILES
  payload: File[]
  callback: (succ: boolean, fileNameList?: string[]) => void
}

export type FilesActions =
  | UploadFileAction
  | UploadFilesAction
