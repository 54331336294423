import { fetchUserHead } from './saga'
import {
  userTypes,
  UserValue,
  UserValues,
  UserActions,
  UserType,
  UpdateUserListPayload,
  FetchUserHeadParams
} from './types'

const actions = {
  fetchCurrentUser: (
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.FETCH_CURRENT_USER,
    payload: null,
    callback
  }),
  createUser: (
    payload: any,
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.CREATE_USER,
    payload,
    callback
  }),
  updateUser: (
    payload: Partial<UserType>,
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.UPDATE_USER,
    payload,
    callback
  }),
  updateUserList: (
    payload: UpdateUserListPayload,
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.UPDATE_USER_LIST,
    payload,
    callback
  }),
  setUserValue: (payload: UserValue): UserActions => ({
    type: userTypes.SET_VALUE,
    payload
  }),
  setUserValues: (payload: UserValues): UserActions => ({
    type: userTypes.SET_VALUES,
    payload
  }),
  fetchUserHead: (
    payload: FetchUserHeadParams,
    callback?: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.FETCH_USER_HEAD,
    payload,
    callback
  })
}

export default actions
