import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      gap: theme.spacing(4),
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      width: '140px',
      height: '173px',
      [theme.breakpoints.down('md')]: {
        width: '90px',
        height: '123px',
      },
    },
    loader: {
      width: '80%',
      margin: 'auto',
      borderRadius: '8px',
      maxWidth: '50%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '24rem',
      },
    },
  })
)
