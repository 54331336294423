import { Dialog, DialogContent, Typography, Grid, Button, CircularProgress } from '@material-ui/core'
import { isEmpty, validateEmail } from '../../../../../helpers'
import React, { useState } from 'react'
import { AddModalProps, Member } from './types'

import useStyles from './styles'
import { computer } from '../../../../../assets'
import { Autocomplete, TextFieldLabel } from '../../../../UI'

import { icons } from './../../../../../assets/'
import { states } from '../states'

const AddMember = ({ open, setOpen, action, isInstitutional }: AddModalProps) => {
    const classes = useStyles()
    const [member, setMember] = useState<Member>({
        firstName: '',
        lastName: '',
        email: '',
        states: [],
        type: 'Client',
    })
    const [loading, setLoading] = useState(false)

    let isValid = !isEmpty(member.firstName) && !isEmpty(member.lastName) && validateEmail(member.email)

    const statesOptions = states.map((state) => {
        return {
            key: state,
            value: state,
        }
    });

    const handleClick = () => {
        setLoading(true)
        action(member, () => setLoading(false))
    }

    return (
        <Dialog onClose={() => setOpen && setOpen(!open)} open={open} classes={{ paper: `${classes.root} ${!isInstitutional && classes.rootBroker}` }}>
            <DialogContent className={classes.header}>
                {loading && <Grid container justifyContent='center' alignItems="center" className={classes.loading}>
                    <CircularProgress />
                </Grid>}
                <Grid container spacing={2} direction='column' className={classes.container}>
                    <Grid container justifyContent='center' alignItems="center" className={classes.headerContainer}>
                        <img alt="add new member icon" src={computer} />
                        <Typography className={classes.title}>Enter your member info</Typography>
                    </Grid>
                    <form>
                        <Grid item className={`${classes.boxes} ${!isInstitutional && classes.brokerBoxes}`}>

                            <Grid className={classes.box}>
                                <TextFieldLabel
                                    label='First Name:'
                                    onChange={ev => {
                                        setMember({ ...member, firstName: ev.target.value })
                                    }}
                                    value={member.firstName}
                                    placeholder='Enter your member’s name'
                                />
                            </Grid>
                            <Grid className={classes.box}>
                                <TextFieldLabel
                                    label='Last Name:'
                                    onChange={ev => {
                                        setMember({ ...member, lastName: ev.target.value })
                                    }}
                                    value={member.lastName}
                                    placeholder='Enter your member’s last name'
                                />
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.boxes} ${!isInstitutional && classes.brokerBoxes}`}>
                            <Grid className={classes.box}>
                                <TextFieldLabel
                                    label='Mail:'
                                    onChange={ev => {
                                        setMember({ ...member, email: ev.target.value })
                                    }}
                                    value={member.email}
                                    placeholder='Write your member’s e-mail...'
                                />
                            </Grid>
                            {isInstitutional && <Grid className={classes.box}>
                                <Autocomplete
                                    multiple
                                    label='States:'
                                    value={undefined}
                                    options={statesOptions}
                                    onChange={(value: any) => {
                                        setMember({ ...member, states: value.map((value: any) => value.key) })
                                    }}
                                    placeholder={member?.states?.length === 0 ? 'Select the states for your member' : ''}
                                />
                            </Grid>}

                        </Grid>
                    </form>
                    <Typography variant='caption' className={classes.disclousure}>
                        An e-mail with the invitation will be sent to your new member. Once your member has accepted his/her status will be updated in the Admin Panel.
                    </Typography>
                </Grid>
                <Grid className={`${classes.footer} ${!isInstitutional && classes.footerBroker}`}>
                    <Button
                        variant="text"
                        className={`${classes.buttonClose} ${classes.button}`}
                        onClick={() => setOpen && setOpen(!open)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        size='large'
                        disabled={!isValid}
                        className={`${classes.buttonSubmit} ${classes.button}`}
                        onClick={handleClick}
                        fullWidth
                    >
                        Send Invite
                        <icons.NearMe />
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AddMember
