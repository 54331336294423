import { Box, Typography } from '@material-ui/core'
import { discoveryActions } from 'ducks/actions'
import { getSubscriptions } from 'ducks/subscriptions/selectors'
import { REFERRAL_TYPE } from 'ducks/types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { maintenanceSuccess } from '../../../../../assets'
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/maintenance/arrow-right.svg'
import { history } from '../../../../../helpers'
import { Button } from '../../../../UI/MaterialUI'
import FeedbackModal from '../../Dashboard/Modals/FeedbackModal'
import useStyles from './styles'


interface MaintenanceSuccessI {
    title?: string;
    subtitle?: string;
    userInfo?: {
        name: string;
        lastName: string;
        phone: string | number;
        email: string;
        image: string;
    };
}

/**
 *
 *
 * @example
    <MaintenanceSuccess
        userInfo={{ image: "https://tn.com.ar/resizer/DPOzC560YSAl3zutcdybHjr-3Q4=/767x0/smart/filters:format(webp)/cloudfront-us-east-1.images.arcpublishing.com/artear/5OA64SRGYNBX5LFQHMAJ2J2YQU.jfif", name: "Mallory", lastName: "Wolfe", phone: "+875-977-7772", email: "mallory.wolfe@bosscathome.com" }}
        title='Thank you!'
        subtitle="You've successfully gifted BOSSCAT's home maintenance service to your clients. Please check your email for details about their first service and welcome gift."
    />
 */

const MaintenanceSuccess: React.FunctionComponent<MaintenanceSuccessI> = ({
    title = " Thank you!",
    subtitle = "Thank you for purchasing BOSSCAT's VIP Maintenance membership program. Please check your email for confirmation of your purchase.",
    userInfo
}: MaintenanceSuccessI) => {
    const classes = useStyles()
    const push = history.usePush()
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const subscriptions = useSelector(getSubscriptions("subscriptions"))

    useEffect(() => {
        setTimeout(() => {
            setModalOpen(true)
        }, 600)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSendFeedback = (option: REFERRAL_TYPE | string, details: string) => {
        dispatch(discoveryActions.sendFeedback({ resourceId: subscriptions[0]?.id, resourceType: 'SUBSCRIPTION', source: option, details }, (succ) => {
            if (succ) {
                setModalOpen(false)
            }
        }))
    }

    return (
        <Box className={classes.SuccessContainer}>
            {/* We show the heard about modal only if it's the first subscription for the user subscriptions.length <= 1 && modalOpen */}

            {subscriptions.length <= 1 && modalOpen &&
                <FeedbackModal
                    open={modalOpen}
                    setOpen={() => setModalOpen(false)}
                    onSubmit={handleSendFeedback}
                    titleToShow='How did you hear about the Maintenance Membership?'
                />
                // <Modal
                //     open={modalOpen}
                //     setOpen={() => setModalOpen(false)}
                //     size="xl"
                //     className={classes.modal}
                //     // title={<Typography variant='h5' style={{ paddingLeft: 0, fontFamily: 'LatoNormal' }}>How did you hear about the Maintenance Membership?</Typography>}
                //     title="How did you hear about the Maintenance Membership?"
                // >
                //     <HeardAboutModal onClose={() => setModalOpen(false)} />
                // </Modal>
            }
            <Box className={classes.MessageContainer}>
                <img className={classes.Image} src={maintenanceSuccess} alt='Success' />
                <Typography variant='h1' align='center' className={classes.Title}>
                    {title}
                </Typography>
                <Typography align='center' className={classes.Subtitle}>
                    {subtitle}
                </Typography>
            </Box>
            {/*  THIS IS THE CUSTOMER MANAGER SUCCESS COMPONENT
            <Box className={classes.GiftInfoContainer}>
                <img className={classes.GiftImage} src={userInfo.image} alt='Success' />
                <Box className={classes.InfoWrapper}>
                    <Typography className={classes.InfoName}>
                        {`${userInfo.name} ${userInfo.lastName}`}
                    </Typography>
                    <Box className={classes.InfoItem}>
                        <Typography className={classes.InfoLabel}>
                            Phone:
                        </Typography>
                        <Typography className={classes.InfoValue}>
                            {userInfo.phone}
                        </Typography>
                    </Box>
                    <Box className={classes.InfoItem}>
                        <Typography className={classes.InfoLabel}>
                            Mail:
                        </Typography>
                        <Typography className={classes.InfoValue}>
                            {userInfo.email}
                        </Typography>
                    </Box>
                </Box>
            </Box> */}
            <Box className={classes.NextButton}>
                <Button
                    onClick={() => push('p/maintenance')}
                    className={classes.Button}
                >
                    Go to Maintenance Dashboard
                    <ArrowRight className={classes.Arrow} />
                </Button>
            </Box>
        </Box>
    )
}
export default MaintenanceSuccess
