import { authTypes } from 'ducks/auth/types'
import {
  adminTypes,
  AdminType,
  AdminActions,
  AdminClientsType
} from './types'

const initialState: AdminClientsType = []

const reducer = (state = initialState, action: AdminActions): AdminClientsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case adminTypes.SET_ADMIN_CLIENTS: {
      return payload as AdminType[]
    }
    default:
      return state
  }
}

export default reducer
