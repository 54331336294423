import { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { icons } from '../../../../assets'
import {
  getConfigValue,
  getNewEstimateStep,
  getUserFullName,
  getUser,
  getPaymentOptions
} from '../../../../ducks/selectors'
import { USER_TYPE } from '../../../../helpers'
import { DesktopPage, PrivateLoader } from '../../../templates'
import { UserDetails, Stepper, Success, Button } from '../../../UI'
import { configActions, newEstimateActions } from '../../../../ducks/actions'
import { NewEstimateValue } from '../../../../ducks/newEstimate/types'
import useStyles from './styles'
import { Assignment, Location, Payment, Person } from './Steps'
import { parsePhoneFromNumToStr } from '../../../../helpers/parsePhone'
import { useReplace } from '../../../../helpers/history'

const HOMEOWNER = USER_TYPE.HOMEOWNER
const BROKER = USER_TYPE.BROKER
const INSTITUTIONAL = USER_TYPE.INSTITUTIONAL

const GetEstimate: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const replace = useReplace()

  const userType = useSelector(getConfigValue('userType'))
  const activeStep = useSelector(getNewEstimateStep())
  const userFullName = useSelector(getUserFullName)
  // const userAddress = useSelector(getUserAddress)
  // const userPhone = useSelector(getUserPhone)
  const user = useSelector(getUser)
  const paymentOptions = useSelector(getPaymentOptions())

  const steps = []
  const [pageLoading, setPageLoading] = useState(true)
  const [estimateCompleted, setEstimateCompleted] = useState(false)

  useEffect(() => {
    setPageLoading(false)
  }, [activeStep])

  const handleCreateForInstitutional = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    dispatch(
      newEstimateActions.createEstimate(paymentOptions[0], success => {
        dispatch(
          configActions.setConfigValue({ type: 'overlayLoading', value: false })
        )
        if (success) {
          setEstimateCompleted(true)
          toast.success('Estimate Created!')
          dispatch(newEstimateActions.cleanNewEstimate())
        }
      })
    )
  }

  steps.push({
    icon: <icons.LocationOn />,
    content: <Location />,
    nextText: 'Upload Inspection'
  })

  if ([BROKER, INSTITUTIONAL].includes(userType)) {
    steps.push({
      icon: <icons.Person />,
      content: <Person />,
      nextText: 'Upload Inspection'
    })
  }

  steps.push({
    icon: <icons.Assignment />,
    content: <Assignment />,
    nextText:
      userType === INSTITUTIONAL ? 'Get Estimate' : 'Continue',
    actionNext:
      userType === INSTITUTIONAL ? handleCreateForInstitutional : undefined
  })

  if ([HOMEOWNER, BROKER].includes(userType)) {
    steps.push({
      icon: <icons.Payment />,
      content: <Payment />
    })
  }

  return (
    <DesktopPage title='Get an Estimate' absoluteClass={classes.root}>
      <PrivateLoader loading={pageLoading}>
        {!estimateCompleted
          ? (
            <>
              <UserDetails
                primary={userFullName}
                secondary={
                  userType === INSTITUTIONAL
                    ? [userType]
                    : [
                      userType,
                      user.email[0].email,
                      user.phone ? parsePhoneFromNumToStr(user.phone) : ''
                    ]
                }
              />
              <Stepper
                steps={steps}
                activeStep={activeStep}
                setActiveStepAction={(step: number) =>
                  dispatch(newEstimateActions.setNewEstimateStep(step))}
                saveNewValueAction={(value: NewEstimateValue) =>
                  dispatch(newEstimateActions.setNewEstimateValue(value))}
                margin={activeStep === 1 ? '0px 20px 0px 0px' : ''}
              />
            </>
          )
          : (
            <Success>
              <Button
                type='submit'
                variant='contained'
                size='large'
                className={classes.button}
                color='primary'
                onClick={() => replace('/p/estimates')}
              >
                View Estimates
              </Button>
            </Success>
          )}
      </PrivateLoader>
    </DesktopPage>
  )
}

export default GetEstimate
