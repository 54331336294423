import { Theme, createStyles, makeStyles } from '../../../../../UI/MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 0px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      // alignItems: 'flex-end',
      flexDirection: 'column',
      padding: '0 16px',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'inherit'
      }
    },
    listItem: {
      display: 'flex',
      minHeight: '58px',
      justifyContent: 'space-between !important',
      width: 'inherit'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%'
      }
    },
    body2: {
      fontFamily: 'Lato',
      fontSize: '16px',
      color: 'var(--head-text-color)',
      lineHeight: '1!important',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        marginBottom: '8px',
      }
    },
    price: {
      fontFamily: 'Lato',
      // fontSize: '16px',
      color: 'var(--head-text-color)',
      marginLeft: '1rem'
    },
    decimals: {
      // fontFamily: 'Lato',
      // fontSize: '12px',
      color: 'var(--head-text-color)',
      fontWeight: 'inherit',
      // marginBottom: '.1rem'
    }
  })
)
