import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '400px',
      borderRadius: '16px',
      width: '100%',
      padding: '2rem'
    },
    rootBroker:{
      maxWidth: '400px',
    },
    header: {
      padding: 0
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      fontFamily: 'LatoNormal',
      fontSize: 24,
      margin: '1rem 0'
    },
  })
)
