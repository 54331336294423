import { createContext } from 'react'
import { IDashboardContext } from './context'
import { Item } from 'ducks/types'
import { GroupedOrderType } from './Modals/PurchaseModal/types'

export const initialState: IDashboardContext = {
  groupedOrders: [],
  purchaseModalTitleColor: '',
  purchaseModalOpen: false,
  purchaseModalTitle: '',
  addModalOpen: false,
  cart: [],
  setGroupedOrders: () => {},
  setPurchaseModalTitleColor: () => {},
  setPurchaseModalOpen: () => {},
  setPurchaseModalTitle: () => {},
  setAddModalOpen: () => {},
  setCart: () => {},
}

export const DashboardContext = createContext(initialState)
