import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      margin: '0 auto',
      gridTemplateRows: 'auto min-content',
      height: '100vh',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '24px !important'
      }
    },
    content: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '30px 12px 0px 24px'
    },
    footer: {
      background: 'rgba(249, 250, 250, 1)'
    },
    footerContent: {
      display: 'grid',
      gap: '4px',
      maxWidth: '1250px', // theme.breakpoints.values.xxl,
      width: '100%',
      margin: '0 auto',
      padding: '24px 0 12px 24px'
    }
  })
)
