/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import * as Sentry from '@sentry/react'
import { Punchlist } from 'api'
import { newInspectionActions } from 'ducks/actions'
import { Address } from 'ducks/types'
import { getUserId } from 'ducks/user/selectors'
import {
  OCCUPANCY_STATUS,
  errorTextTryingTo
} from 'helpers'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getNewInspectionValues } from './selectors'
import {
  CreateInspectionAction,
  NewInspectionType,
  newInspectionTypes
} from './types'

export function* createInspection({
  payload,
  callback
}: CreateInspectionAction): SagaIterator {
  try {
    const userId = yield select(getUserId)
    const values: NewInspectionType = yield select(getNewInspectionValues())


    const newInspection: Partial<NewInspectionType> = {
      //TODO data que va contra la api, que matchee
      requester: values.mainContact,
      address: values.address as Address,
      homeSize: values.homeSize,
      preferredDate: values.preferredDate,
      occupancyStatus: values.occupancyStatus as OCCUPANCY_STATUS,
      affiliate: values.affiliate,

    }

    const inspection = yield call(Punchlist.inspections.createInspection, newInspection)

    if (inspection && !isEmpty(inspection.id)) {
      yield put(
        newInspectionActions.setNewInspectionValue({
          attr: 'id',
          value: inspection.id
        })
      )
      if (callback) yield call(callback, true, inspection.id)
    } else {
      yield call(toast.error, errorTextTryingTo('create an inspection'))
      if (callback) yield call(callback, false)
    }
  } catch (error) {
    Sentry.captureException(error);
    yield call(toast.error, errorTextTryingTo('create an inspection'))
    if (callback) yield call(callback, false)
  }
}









export default function* saga(): SagaIterator {
  yield takeLatest(newInspectionTypes.CREATE_INSPECTION, createInspection)

}
