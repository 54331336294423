import { authTypes } from 'ducks/auth/types'
import { Job } from 'ducks/types'
import { jobsTypes, JobsType, JobsActions } from './types'

const initialState: JobsType = []

const reducer = (state = initialState, action: JobsActions): JobsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case jobsTypes.SET_JOBS: {
      return payload as Job[]
    }

    default:
      return state
  }
}

export default reducer
