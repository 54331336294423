import storeInfo from 'ducks/store'
import { SubscriptionsType } from './types'

export const getSubscriptions = (type: keyof SubscriptionsType) => (): any => {
  const { subscriptions } = storeInfo.store.getState()
  return subscriptions[type as keyof SubscriptionsType];
}

export const getSubscriptionsValue = (type: keyof SubscriptionsType) => (): any => {
  const { subscriptions } = storeInfo.store.getState()
  // if (!orderMaintenance) return null
  return subscriptions[type as keyof SubscriptionsType]
}

export const territoryIsAvailableForMaintenance = (territoryId?: string) => (): boolean => {
  const { subscriptions } = storeInfo.store.getState()
  if (!territoryId || subscriptions.plans.length <= 0) return false
  return subscriptions.plans[0].validTerritoryIds.includes(territoryId)
}
