import { Resource } from './saga'
import { DashboardActions, dashboardTypes } from './types'

const actions = {
  fetchDashboardData: (
    payload: { startDate?: number, endDate?: number },
    callback?: (succ: boolean) => void
  ): DashboardActions => ({
    type: dashboardTypes.FETCH_DASHBOARD_DATA,
    payload,
    callback
  }),
  setResources: (payload: Resource[]): any => ({
    type: dashboardTypes.SET_DASHBOARD_DATA,
    payload
  })
}

export default actions
