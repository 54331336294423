import { FC } from 'react'
import { TextFieldProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'

import { Typography } from '@material-ui/core'

const Tag: FC<TextFieldProps> = ({
  type,
  children,
  className,
  customSize = "inherit",
  style,
  textStyle
}) => {
  const classes = useStyles()
  const classNames = useClassName()

  const getTitleColor = (): string => {
    switch (type) {
      case 'Expired':
        return classes.expired
      case 'Completed':
        return classes.completed
      case 'Ready_for_release':
      case 'In Progresss':
        return classes.progress
      case 'Scheduling':
        return classes.scheduling
      case 'Dispatched':
        return classes.dispatched
      case 'Hold':
        return classes.hold
      case 'Needs Attention':
        return classes.expired
      case 'Approved':
        return classes.approved
      case 'Enabled':
        return classes.completed
      case 'Disabled':
        return classes.expired
      case 'Pending':
        return classes.progress
      case 'Neutral':
        return classes.neutral
      default:
        return classes.expired
    }
  }

  return (
    <div className={classNames(getTitleColor(), className ?? '', classes[customSize], classes.shadow)} style={style}>
      <Typography
        variant='body2' style={{ width: 'fit-content', ...textStyle }}>
        {children ?? type}
      </Typography>
    </div>
  )
}

export default Tag
