import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'


export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    disabled: {
      backgroundColor: 'var(--border-color)',
      minWidth: '160px',
      maxWidth: '300px',
      height: '186px',
      borderRadius: '8px',
      padding: "16px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down('sm')]: {
        height: '54px',
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }
    },
    enabled: {
      backgroundColor: 'var(--white-color)',
      minWidth: '210px',
      maxWidth: '300px',
      height: '186px',
      borderRadius: '8px',
      border: '2px solid var(--bosscat-blue-600)',
      padding: "16px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        // height: '142px',
        minWidth: '100%'
      }
    },
    date: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      lineHeight: '30px',
      fontSize: '18px',
      alignSelf: 'flex-start',
      // margin: '0px 16px 0px 16px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px',
        textAlign: 'left',
        flex: 1
      }
    },
    dateEnabled: {
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      lineHeight: '30px',
      fontSize: '18px',
      // margin: '0px 16px 0px 16px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px',
        textAlign: 'left',
        width: '100%'
      }
    },
    title: {
      fontFamily: 'LatoNormal',
      fontWeight: 'normal',
      lineHeight: '30px',
      fontSize: '18px',
      margin: '15px 0px'
    },
    subtitle: {
      fontFamily: 'Lato',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 500,
      textAlign: 'center',
      marginTop: '30px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px',
        textAlign: 'right',
        flex: 1
      }
    },
    titleBlack: {
      fontFamily: 'LatoBold',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 12px',
        textAlign: 'left'
      }
    },
    bestTop: {
      width: '153px',
      fontFamily: 'Lato',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: 'var(--hardware-haze-600)',
      backgroundColor: 'var(--scheduling-color-back)',
      margin: '-32px 0px 0px 64px',
      padding: '4px 1px',
      [theme.breakpoints.down('sm')]: {
        margin: '-16px 0px 0px 50%'
      }
    },
    best: {
      fontFamily: 'Lato',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      textAlign: 'center',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--progress-color-back)',
      margin: '12px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px',
        margin: '5px 5px 0px 5px',
        flex: 1
      }
    },
    bestBox: {
      display: 'flex',
      flexDirection: 'row',
      // justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      }
    },
    pay: {
      width: '100%',
      height: '44px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      // margin: '5px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 16px',
        width: '100%'
      }
    },
    free: {
      width: '100%',
      minHeight: '44px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--white-color)',
      border: '2px solid var(--bosscat-blue-600)',
      borderRadius: 8,
      // margin: '5px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: 'var(--white-color)',
      boxShadow:
        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      '&:hover': {
        backgroundColor: 'var(--white-color) !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    top: {
      width: '0px',
      height: '0px'
    },
    box: {
      margin: '0px 44px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '25px 44px 0px'
      }
    },
    boxFree: {
      margin: '16px 44px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '25px 44px 0px'
      }
    }
  })
)
