import { concatSeparator } from 'helpers'
import storeInfo from 'ducks/store'
import { Estimate, PaymentOption } from 'ducks/types'
import { NewRepairListType } from './types'

export const getNewRepairListStep = () => (): any => {
  const { newRepairList: { step } } = storeInfo.store.getState()
  return step || 0
}

export const getNewRepairListValue = (type: string) => (): any => {
  const { newRepairList } = storeInfo.store.getState()
  return newRepairList[type as keyof NewRepairListType] || null
}

export const getNewRepairListValues = () => (): NewRepairListType => {
  const { newRepairList } = storeInfo.store.getState()
  return newRepairList
}

export const getNewRepairListAddress = (): string => {
  const { newRepairList } = storeInfo.store.getState()
  const { city, line_1: street1, line_2: unit, state } = newRepairList.address
  const cityState = concatSeparator(city ?? '', state ?? '', ', ')
  const streetUnit = concatSeparator(street1 ?? '', unit ?? '', ', ')
  const address = concatSeparator(streetUnit ?? '', cityState ?? '', ', ')
  return address || 'No address'
}

export const getNewRepairListFullAddress = (): string => {
  const { newRepairList } = storeInfo.store.getState()
  const { city, line_1: street1, state, zipCode } = newRepairList.address
  let address = concatSeparator(street1 ?? '', city ?? '', ', ')
  address = concatSeparator(address, state + ' ' + zipCode, ', ')
  return concatSeparator(address, 'USA', ', ') || 'No address'
}

export const getNewRepairListData = () => (): Partial<Estimate> => {
  const { newRepairList } = storeInfo.store.getState()
  return newRepairList.estimate
}

export const getNewRepairListPaymentOptions = () => (): PaymentOption[] | null => {
  const { newRepairList } = storeInfo.store.getState()
  return newRepairList.paymentOptions
}

export const getNewRepairListPaymentOption = () => (): PaymentOption | null => {
  const { newRepairList } = storeInfo.store.getState()
  return newRepairList.paymentOption
}

export const getNewRepairListExpediteOption = () => (): PaymentOption | null => {
  const { newRepairList } = storeInfo.store.getState()
  const selected = newRepairList.paymentOption
  if (!selected) return null
  return newRepairList.paymentOptions?.find(option => option.date < selected.date) ?? null
}
