import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    FirstTimeMedal: {
      width: "158px",
      height: "180px",
      [theme.breakpoints.down('md')]: {
        width: "124px",
        height: "140px",
      },
    },
    FirstTimeWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      padding: "16px",
      backgroundColor: "#F9FAFA",
      [theme.breakpoints.down('md')]: {
        padding: "32px 16px",
        marginTop: "48px"
      },
    },
    FirstTimeTitle: {
      fontSize: "30px",
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      textAlign: "center",
      margin: "24px 0 16px",
      [theme.breakpoints.down('md')]: {
        fontSize: "22px",
      },
    },
    FirstTimeSubtitle: {
      fontSize: "20px",
      textAlign: "center",
      [theme.breakpoints.down('md')]: {
        fontSize: "18px",
        fontFamily: "LatoNormal",
      },
    },
    arrow: {
      transform: "rotate(-90deg)"
    },
    button: {
      width: '100%',
      height: '45px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      fontFamily: 'LatoNormal',
      padding: '1.7rem 0',
      "@global": {
        '.MuiButton-label': {
          paddingTop: 0
        }
      }
    },
  })
)
