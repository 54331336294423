import { REFERRAL_TYPE } from 'ducks/types'

/** TYPES **/
export const discoveryTypes = {
  SEND_DISCOVERY: 'SEND_DISCOVERY'
}

export interface Discovery {
  resourceId: string
  resourceType: string
  source: REFERRAL_TYPE | string
  details: string
}

/** ACTIONS **/
export interface SendDiscoveryAction {
  type: typeof discoveryTypes.SEND_DISCOVERY
  payload: Discovery
  callback?: (succ: boolean) => void
}

export type DiscoveryActions =
  | SendDiscoveryAction
