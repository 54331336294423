import { FetchEmployeesParams } from 'api/punchlist/employees/types';
import { UpdateRequest } from 'api/types';
import { Subscription, SubscriptionsPlan, SubscriptionsPlanService } from 'ducks/types'

/** TYPES **/
export const subscriptionsTypes = {
  FETCH_SUBSCRIPTIONS: 'FETCH_SUBSCRIPTIONS',
  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  SET_SUBSCRIPTIONS_VALUE: 'SET_SUBSCRIPTIONS_VALUE',
  SET_CSM_VALUE: 'SET_CSM_VALUE',
  FETCH_SUBSCRIPTIONS_PLANS: 'FETCH_SUBSCRIPTIONS_PLANS',
  FETCH_SUBSCRIPTIONS_DELIVERY_DATE: 'FETCH_SUBSCRIPTIONS_DELIVERY_DATE',
  REQUEST_SCHEDULE_SERVICE: 'REQUEST_SCHEDULE_SERVICE',
  UPDATE_SUBSCRIPTION_PLAN: "UPDATE_SUBSCRIPTION_PLAN",
  CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
  CHECK_USER_VIP: "CHECK_USER_VIP",
}

export interface SubscriptionsValue {
  attr: keyof SubscriptionsType
  value: any
}

export interface UpdateSubscriptionPlanParams {
  subscriptionId: string;
  request: UpdateRequest;
}
export interface RequestScheduleServiceParams {
  subscriptionId: string;
  request: SubscriptionsPlanService;
}
export interface CheckUserVipParams {
  email: string;
}

export interface FetchSubscriptionsParams {
  isGift?: boolean
  stripeSubscriptionId?: string
  includeIncomplete?: boolean
}

/** ACTIONS **/
export interface FetchSubscriptionsAction {
  type: typeof subscriptionsTypes.FETCH_SUBSCRIPTIONS
  payload: FetchSubscriptionsParams
  callback?: (succ: boolean) => void
}
export interface FetchEmployeesAction {
  type: typeof subscriptionsTypes.FETCH_EMPLOYEES
  payload: FetchEmployeesParams
  callback?: (succ: boolean) => void
}

export interface SubscriptionsSetValueAction {
  type: typeof subscriptionsTypes.SET_SUBSCRIPTIONS_VALUE
  payload: SubscriptionsValue
}
export interface CMSSetValueAction {
  type: typeof subscriptionsTypes.SET_CSM_VALUE
  payload: CustomerSuccessManagerType
}

export interface FetchSubscriptionsPlansAction {
  type: typeof subscriptionsTypes.FETCH_SUBSCRIPTIONS_PLANS
  payload: null
  callback?: (succ: boolean, plans: SubscriptionsPlan[] | null) => void
}

export interface FetchSubscriptionsDeliveryDate {
  type: typeof subscriptionsTypes.FETCH_SUBSCRIPTIONS_PLANS
  payload: null
  callback?: (succ: boolean) => void
}


export interface RequestScheduleServiceAction {
  type: typeof subscriptionsTypes.REQUEST_SCHEDULE_SERVICE
  payload: RequestScheduleServiceParams
  callback?: (succ: boolean) => void
}
export interface CheckUserVIPAction {
  type: typeof subscriptionsTypes.CHECK_USER_VIP
  payload: CheckUserVipParams;
  callback?: (succ: boolean, isVip?: boolean) => void
}

export type SubscriptionsActions =
  | FetchSubscriptionsAction
  | FetchEmployeesAction
  | FetchSubscriptionsPlansAction
  | SubscriptionsSetValueAction
  | CMSSetValueAction
  | RequestScheduleServiceAction
  | FetchSubscriptionsDeliveryDate
  | CheckUserVIPAction

/** REDUCER **/
export type SubscriptionsType = {
  subscriptions: Subscription[],
  plans: SubscriptionsPlan[],
  deliveryDate: string,
  customerSuccessManager: CustomerSuccessManagerType | null;
}

export interface CustomerSuccessManagerType {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: Email[];
  phone: number;
  picture: string;
  address: null;
  type: string;
  auth0Id: string;
  auth0Logins: null;
  insertedOn: number;
  registrationComplete: boolean;
  registrationCompleteOn: null;
  userProperties: UserProperties;
  roles: Role[];
  admin: boolean;
  approved: boolean;
  territories: string[];
  primaryRole: string;
}

export interface Email {
  id: null;
  emailType: string;
  email: string;
  name: null;
  description: null;
}

export interface Role {
  roleName: string;
  roleType: string;
  primary: null;
}

export interface UserProperties {
}
