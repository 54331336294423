import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from '../../styles'
import { Condition, Insight, RenovationItem } from 'ducks/types'
import CostTypeRadio from 'components/UI/CustomUI/molecules/CostTypeRadio'
import { useState } from 'react'
import { Check } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { propertiesActions, propertyDetailActions } from 'ducks/actions'
import { history } from 'helpers/index'
import DateFnsUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ConditionType } from 'ducks/properties/types'
import {
  getPropertyDetail,
  getPropertyInquiredItems,
  getTerritory,
} from 'ducks/selectors'

const RenovationEstimateRow = ({
  item,
  setModalOpen,
}: {
  item: RenovationItem
  setModalOpen: (open: boolean) => void
}) => {
  const dispatch = useDispatch()

  const styles = useStyles()

  const [costType, setCostType] = useState<string>('Good')

  const property = useSelector(getPropertyDetail())
  const inquiredItems = useSelector(getPropertyInquiredItems())
  const territory = useSelector(getTerritory())

  const handleInquire = (condition: any) => {
    // setInquiredItems([...inquiredItems, condition.project])
    dispatch(
      propertiesActions.requestPropertyRenovation(
        { propertyId: property?.id, request: condition },
        () => {}
      )
    )
    dispatch(
      propertyDetailActions.setPropertyInquiredItems([
        ...inquiredItems,
        condition.project,
      ])
    )
  }

  const props = [
    {
      title: 'Count',
      component: (
        <Typography className={styles.title}>
          {`${item.quantity} ${item.unit}`}
        </Typography>
      ),
    },
    {
      title: 'Cost',
      component: (
        <Typography className={styles.title}>
          {`$${(item as any)[`cost${costType}`]?.toLocaleString()}`}
        </Typography>
      ),
    },
  ]

  return (
    <Grid
      container
      item
      className={styles.containerBox}
      direction="column"
      style={{ padding: '12px', gap: '8px' }}
    >
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          style={{ width: 'fit-content' }}
        >
          <Grid item>
            <Typography className={styles.lgFont}>
              {item.project || ''}
            </Typography>
          </Grid>
          <Grid item>
            <CostTypeRadio costType={costType} setCostType={setCostType} />
          </Grid>
        </Grid>
        {territory?.serviceable && (
          <Grid item>
            <Button
              startIcon={
                inquiredItems.includes(item.project) ? (
                  <Check className={styles.buttonIcon} />
                ) : (
                  <></>
                )
              }
              variant="contained"
              className={
                inquiredItems.includes(item.project)
                  ? styles.inquiredButton
                  : styles.inquireButton
              }
              style={{
                height: '44px',
              }}
              fullWidth
              onClick={() => {
                if (!inquiredItems.includes(item.project)) {
                  setModalOpen(true)
                  handleInquire(item)
                }
              }}
            >
              <Typography
                className={styles.lgFont}
                style={{
                  textTransform: 'none',
                }}
              >
                {inquiredItems.includes(item.project) ? '' : 'Inquire'}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item>
        {props.map((prop) => (
          <Grid item xs={6}>
            <Typography
              className={styles.latoSmFont}
              style={{ color: 'var(--text-text-tertiary)' }}
            >
              {prop.title}
            </Typography>
            {prop.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default RenovationEstimateRow
