import axios from '../axios'
import { ApiController } from './types'

const base = '/items'

const apiController: ApiController = {
  getRecommendedItems: async (territoryId: string, estimateId: string) => {
    return await axios
      .get(`${base}/recommended/${territoryId}?estimateId=${estimateId}`)
      .then(response => response.data)
  }
}

export default apiController
