import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  Text,
  View,
} from '@react-pdf/renderer'
import { formatTimestamp, round } from '../../../../../../helpers'
import logo from '../../../../../../assets/icons/custom/blue_logo.svg'
import moment from 'moment'

import Moranga from '../../../../../../assets/fonts/NEXT ART_SemiBold.otf'
import MorangaR from '../../../../../../assets/fonts/NEXT ART_Regular.otf'
import LatoBold from '../../../../../../assets/fonts/Lato-Bold.ttf'
import LatoNormal from '../../../../../../assets/fonts/Lato-Regular.ttf'
import BetterSansRegular from '../../../../../../assets/fonts/BetterSans-Bold.otf'
import BetterSansBold from '../../../../../../assets/fonts/BetterSans-Regular.otf'

import style from './style'
import styleBetter from './styleBetter'

Font.register({ family: 'Moranga', src: Moranga })
Font.register({ family: 'MorangaR', src: MorangaR })
Font.register({ family: 'LatoBold', src: LatoBold })
Font.register({ family: 'LatoNormal', src: LatoNormal })
Font.register({ family: 'BetterSansRegular', src: BetterSansRegular })
Font.register({ family: 'BetterSansBold', src: BetterSansBold })

const FooterView = ({ showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <>
      <Text
        fixed
        render={() => formatTimestamp(moment().unix(), 'MM/DD/YYYY HH:mm')}
        style={styles.printDate}
      />
      <Text
        fixed
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        style={styles.pageNumber}
      />
    </>
  )
}

const total = (item, isFromCanada) => {
  if (item.minPriceRange && item.maxPriceRange) {
    if (item.minPriceRange === null || item.maxPriceRange === null)
      return `$`.concat(round(item.totalPrice, 2)).concat(isFromCanada ? " CAD" : "")

    if (item.minPriceRange === item.maxPriceRange)
      return `$`.concat(round(item.totalPrice, 2)).concat(isFromCanada ? " CAD" : "")

    if (item.minPriceRange !== item.maxPriceRange)
      return `$`
        .concat(round(item.minPriceRange, 2).slice(0, -3)).concat(isFromCanada ? " CAD" : "")
        .concat(` - $`)
        .concat(round(item.maxPriceRange, 2).slice(0, -3)).concat(isFromCanada ? " CAD" : "")
  }
  return `$`.concat(round(item.totalPrice, 2)).concat(isFromCanada ? " CAD" : "")
}

const ItemView = ({ item, showBetterStyle, isFromCanada }) => {
  const styles = showBetterStyle ? styleBetter : style
  if (item.status === 'REJECTED') return null
  return (
    <View style={styles.bodyItem}>
      <View
        style={[styles.containerHorizontal, styles.content, styles.justifyEnd]}
      >
        <Text
          style={[styles.itemText, styles.textBold]}
          //render={() => `$ ${round(item.totalPrice, 2)}`}
          render={() => total(item, isFromCanada)}
        />
      </View>

      <View
        style={[
          styles.containerHorizontal,
          styles.content,
          styles.justifyBetween,
        ]}
      >
        <Text
          style={[styles.itemText, styles.textBold]}
          render={() => `${item.title} • QTY: ${item.quantity}`}
        />
      </View>
      {item?.disclaimer ? (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={[styles.itemText, styles.textBold]}
            render={() => 'Disclaimer: '}
          />
          <Text style={[styles.itemText, styles.leftSeparation]}>
            {item.disclaimer}
          </Text>
        </View>
      ) : null}
      <View style={[styles.containerHorizontal, styles.content]}>
        <Text
          style={[styles.itemText, styles.textBold]}
          render={() => 'Notes: '}
        />
        <Text style={[styles.itemText, styles.leftSeparation]}>
          {`${!item.inspectionReportNote || item.inspectionReportNote === ''
            ? 'No notes in this item'
            : item.inspectionReportNote
            }`}
        </Text>
      </View>
      {item?.requestedRepairNote ? (
        <View style={[styles.containerHorizontal, styles.content]}>
          <Text
            style={[styles.itemText, styles.textBold]}
            render={() => 'Requested Repair: '}
          />
          <Text style={[styles.itemText, styles.leftSeparation]}>
            {item.requestedRepairNote}
          </Text>
        </View>
      ) : null}
      <View style={[styles.containerHorizontal, styles.content]}>
        {item.status === 'REJECTED' && (
          <Text style={[styles.itemText, styles.textBold, styles.removed]}>
            {' '}
            REMOVED{' '}
          </Text>
        )}
      </View>
      {item.imageFiles?.length > 0 ? (
        <View style={[styles.content, styles.itemImages, styles.topSeparation]}>
          {item.imageFiles?.map((imgFile, index) => {
            if (!imgFile.url) return null
            return (
              <Image key={index} src={imgFile.fileUrl} style={styles.image} />
            )
          })}
        </View>
      ) : null}
    </View>
  )
}

const ItemSectionView = ({
  items,
  groupKey,
  index,
  price,
  showBetterStyle,
  isFromCanada
}) => {
  const styles = showBetterStyle ? styleBetter : style

  let total = `$`.concat(round(price, 2)).concat(isFromCanada ? " CAD" : "")
  const rangePriceMin = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.minPriceRange
          ? obj.minPriceRange
          : 0
        : 0)
    )
  }, 0)

  const rangePriceMax = items.reduce((acc, obj) => {
    return (
      acc +
      (obj?.status !== 'REJECTED'
        ? obj.maxPriceRange
          ? obj.maxPriceRange
          : 0
        : 0)
    )
  }, 0)

  if (rangePriceMin && rangePriceMax) {
    if (rangePriceMin === null || rangePriceMax === null)
      total = `$`.concat(round(price, 2)).concat(isFromCanada ? " CAD" : "")

    if (rangePriceMin === rangePriceMax) total = `$`.concat(round(price, 2)).concat(isFromCanada ? " CAD" : "")

    if (rangePriceMin !== rangePriceMax)
      total = `$`.concat(round(rangePriceMin, 0).slice(0, -3)).concat(isFromCanada ? " CAD" : "").concat(` - $`).concat(round(rangePriceMax, 0).slice(0, -3)).concat(isFromCanada ? " CAD" : "")
  }

  return (
    <View
      key={index + Math.floor(Math.random() * 1000 + 1)}
      style={[styles.container, styles.topSeparation]}
    >
      <View style={[styles.containerHorizontal, styles.sectionTitle]}>
        <Text style={styles.title} render={() => `${groupKey}: `} />
        <Text style={[styles.price, styles.textBold]}>{total}</Text>
      </View>
      {items.map((item, indx) => {
        if (item.status === 'REJECTED') return null
        return (
          <View key={indx}>
            <ItemView item={item} showBetterStyle={showBetterStyle} isFromCanada={isFromCanada} />
            {indx < items.length - 1 && (
              <View
                style={[
                  styles.separator,
                  item.imageFiles?.length > 0
                    ? styles.bigSpacing
                    : styles.littleSpacing,
                ]}
              />
            )}
          </View>
        )
      })}
    </View>
  )
}

const UserInfoView = ({ userInfo, imageUris, showBetterStyle }) => {
  const styles = showBetterStyle ? styleBetter : style
  const hasRanges = userInfo.total.includes("-")
  return (
    <View
      style={[
        styles.containerHorizontal,
        styles.verticalSeparator,
        styles.justifyBetween,
        styles.alignEnd
      ]}
    >
      <View style={[styles.containerHorizontal, styles.alignEnd]}>
        <View style={styles.itemContainer}>
          {imageUris?.punchlistLogoUri && (
            <Image
              src={imageUris.punchlistLogoUri}
              style={styles.userInfoImage}
            />
          )}
        </View>
        {!showBetterStyle && (
          <View style={[styles.itemContainer, styles.alignBetween]}>
            <Text style={styles.userInfoText}>{userInfo.name}</Text>
            <Text style={styles.userInfoText}>{userInfo.city}</Text>
            <Text style={styles.userInfoText}>{userInfo.address}</Text>
          </View>
        )}
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.mailIconUri ? (
          <Image
            src={imageUris.mailIconUri}
            style={styles.userInfoLabelImage}
          />
        ) : null}
        <Text style={styles.userInfoText}>{userInfo.mail}</Text>
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.sentIconUri && (
          <Image
            src={imageUris.sentIconUri}
            style={styles.userInfoLabelImage}
          />
        )}
        <Text style={styles.userInfoText}>{userInfo.sent}</Text>
      </View>
      <View style={styles.itemContainer}>
        {imageUris?.approvedOnIconUri && (
          <Image
            src={imageUris.approvedOnIconUri}
            style={styles.userInfoLabelImage}
          />
        )}
        <Text style={styles.userInfoText}>{userInfo.approvedOn}</Text>
      </View>
      {!hasRanges && <View style={[styles.itemContainer, styles.justifyCenter]}>
        <View style={styles.containerHorizontal}>
          <Text style={[styles.itemText, styles.textBold]}>Total:{" "}</Text>
          <Text style={styles.itemText}>{` ${userInfo.total}`}</Text>
        </View>
      </View>}
    </View>
  )
}

const CompleteBodyView = ({
  groupedItems,
  userInfo,
  imageUris,
  showBetterStyle,
  isFromCanada
}) => {
  const styles = showBetterStyle ? styleBetter : style
  let totalPrice = 0

  const hasRanges = userInfo.total.includes("-")
  return (
    <View style={styles.bodyItems}>
      <UserInfoView
        imageUris={imageUris}
        userInfo={userInfo}
        showBetterStyle={showBetterStyle}
      />
      {imageUris?.chartUri && (
        <Image src={imageUris.chartUri} style={styles.chartImage} />
      )}
      {Object.keys(groupedItems).map((groupKey, index) => {
        const items = groupedItems[groupKey]
        const price = items.reduce((acc, obj) => {
          return acc + (obj?.status !== 'REJECTED' ? obj.totalPrice : 0)
        }, 0)
        totalPrice += price
        return (
          <ItemSectionView
            key={index}
            groupKey={groupKey}
            index={index}
            items={items}
            price={price}
            showBetterStyle={showBetterStyle}
            isFromCanada={isFromCanada}
          />
        )
      })}
      {userInfo.taxRate > 0 ||
        (userInfo.nar && (
          <View
            style={[
              styles.containerHorizontal,
              styles.sectionTitle,
              styles.justifyBetween,
            ]}
          >
            <Text style={styles.subtitle}>Subtotal:</Text>
            <Text style={styles.subtitle}>{`$ ${round(totalPrice, 2)}`}</Text>
          </View>
        ))}

      {userInfo.taxRate > 0 && (
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <Text style={styles.subtitle}>Tax:</Text>
          <Text style={styles.subtitle}>{`(${round(
            userInfo.taxRate * 100,
            2
          )}%) ${round(userInfo.taxAmount ?? 1, 2)}`}</Text>
        </View>
      )}

      {userInfo.nar && (
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <Text style={styles.subtitle}>NAR Exclusive Discount (5%):</Text>
          <Text style={styles.subtitle}>{`- ${round(totalPrice * 0.05)}`}</Text>
        </View>
      )}

      {imageUris?.disclaimerUri && (
        <Image src={imageUris.disclaimerUri} style={styles.disclaimer} />
      )}
      {!hasRanges &&
        <View
          style={[
            styles.containerHorizontal,
            styles.sectionTitle,
            styles.justifyBetween,
          ]}
        >
          <Text style={[styles.title, styles.titleBold]}>Total:</Text>
          <Text style={[styles.price, styles.textBold]}>{`${userInfo.total}`}</Text>
        </View>
      }

      {imageUris?.disclaimerUri && (
        <Image src={imageUris.disclaimerUri} style={styles.disclaimer} />
      )}
      {!hasRanges && <View
        style={[
          styles.containerHorizontal,
          styles.sectionTitle,
          styles.justifyBetween,
        ]}
      >
        <Text style={[styles.title, styles.titleBold]}>Total:</Text>
        <Text style={[styles.price, styles.textBold]}>{`${userInfo.total}`}</Text>
      </View>}
    </View>
  )
}

const ItemsPdf = ({ groupedItems, userInfo, imageUris, showBetterStyle, isFromCanada }) => {
  const styles = showBetterStyle ? styleBetter : style
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.logoView}>
          <Image style={styles.logo} src={logo} alt="punchlist" />
        </View>
        <CompleteBodyView
          groupedItems={groupedItems}
          imageUris={imageUris}
          userInfo={userInfo}
          showBetterStyle={showBetterStyle}
          isFromCanada={isFromCanada}
        />
        <FooterView showBetterStyle={showBetterStyle} />
      </Page>
    </Document>
  )
}

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({
  groupedItems,
  userInfo,
  imageUris,
  showBetterStyle,
  isFromCanada
}) => {
  download(
    await pdf(
      <ItemsPdf
        groupedItems={groupedItems}
        userInfo={userInfo}
        imageUris={imageUris}
        showBetterStyle={showBetterStyle}
        isFromCanada={isFromCanada}
      />
    ).toBlob(),
    `Order_${new Date().getTime()}`
  )
}
