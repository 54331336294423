import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '125%'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem'
    },
    title: {
      fontSize: '40px',
      fontFamily: 'LatoNormal',
      marginTop: '2rem'
    },
    subtitle: {
      fontFamily: 'LatoNormal',
      fontSize: '18px'
    },
    dueDate: {
      fontSize: '20px'
    },
    button: {
      color: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      border: '2px solid',
      textTransform: 'none',
      fontFamily: 'LatoNormal'
    }
  })
)
