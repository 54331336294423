import { Theme, createStyles, makeStyles } from '../../..'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '32px 32px',
    },
    contianer: {
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    containerDescription: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    ilustration: {
      width: '90px',
      alignSelf: 'center',
      margin: '0px 32px 0px 0px'
    },
    button: {
      width: '80%',
      height: '32px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
    },
    title: {
      fontSize: '20px',
      fontFamily: 'LatoBold',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '300px',
      },
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    },
    description: {
      fontSize: '16px',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '300px',
      },
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center'
      }
    },
  })
)
