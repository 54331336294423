import { Box, Typography } from "@material-ui/core"
import useStyles from './styles'

interface BannerProps {
  title: any,
  subtitle: any,
  imgSrc?: string,
  badgeSrc?: string
  imgAside?: string
  padding?: string
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
  color?: string
  titleColor?: string
  withMaxWidth?: string
}

const Banner = ({ imgSrc, title, subtitle, badgeSrc, imgAside, padding = "6rem", direction = "column", color = "white", titleColor = "white", withMaxWidth = "100%" }: BannerProps) => {
  const classes = useStyles()

  return (
    <Box className={`${classes.header} ${imgAside ? classes.imgAside__container : ''}`} style={{
      padding: padding,
      backgroundImage: imgSrc ? `url(${imgSrc})` : '',
      flexDirection: direction,
      color: color
    }}>
      {
        withMaxWidth ? <Box className={`${classes.headerWithMaxWidth} ${imgAside ? classes.imgAside__container : ''}`} style={{
          flexDirection: direction,
          color: color,
          maxWidth: withMaxWidth
        }}>
          {badgeSrc && <img src={badgeSrc} className={classes.badge} alt='' />}
          <Box>
            {typeof title === 'object'
              ? <Box style={{ color: titleColor }} className={classes.title}>{title}</Box>
              : <Typography style={{ color: titleColor }} className={classes.title}>{title}</Typography>}

            {typeof subtitle === 'object'
              ? <Box className={classes.subtitle}>{subtitle}</Box>
              : <Typography className={classes.subtitle}>{subtitle}</Typography>}
          </Box>
          {imgAside && <img src={imgAside} className={classes.imgAside} alt='' />}
        </Box> : <>
          {badgeSrc && <img src={badgeSrc} className={classes.badge} alt='' />}
          <Box>
            {typeof title === 'object'
              ? <Box style={{ color: titleColor }} className={classes.title}>{title}</Box>
              : <Typography style={{ color: titleColor }} className={classes.title}>{title}</Typography>}

            {typeof subtitle === 'object'
              ? <Box className={classes.subtitle}>{subtitle}</Box>
              : <Typography className={classes.subtitle}>{subtitle}</Typography>}
          </Box>
          {imgAside && <img src={imgAside} className={classes.imgAside} alt='' />}
        </>
      }
    </Box>
  )
}

export default Banner