import { FC, useState, useRef } from 'react'
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '../../../../../UI'
import { SelectPropertyPictureModalProps } from './types'
import PlaceIcon from '@material-ui/icons/Place'
import CloseIcon from '@material-ui/icons/Close'
import ImageIcon from '@material-ui/icons/Image'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { blackUpload, googleMapStr } from 'assets/index'
import { ArrowBack, Save } from '@material-ui/icons'
import { getPropertyById, getPropertyDetail } from 'ducks/selectors'
import { filesActions, propertiesActions } from 'ducks/actions'
import { FILES_URL } from 'components/pages/User/Register/_test_/constants'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'hooks/useMediaQuery'

const SelectPropertyPictureModal: FC<SelectPropertyPictureModalProps> = ({
  property,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch()

  const [file, setFile] = useState(null)
  const [selectedImage, setSelectedImage] = useState<string | null>('')
  const [filePath, setFilePath] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const inputRef = useRef<any>(null)

  const styles = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const f = event.target.files[0]
      setFile(f)

      if (f) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setFilePath(reader.result)
        }
        reader.readAsDataURL(f)
      }
    }
  }

  const handleFileSelectClick = () => {
    if (!file) {
      inputRef.current.click()
    } else {
      setFile(null)
    }
  }

  const handleReplaceImage = () => {
    setFilePath(null)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleGoogleImageSelectClick = () => {
    setFilePath('google-image')
  }

  const changeImage = (e: any) => {
    setSelectedImage(e.target.value || null)
  }

  const handleSave = () => {
    if (filePath === null) return

    setLoading(true)

    if (filePath === 'google-image') {
      dispatch(
        propertiesActions.updateProperty(
          {
            propertyId: property.id,
            partialProperty: { imageUrl: selectedImage || '' },
          },
          (succ) => {
            handleClose()
            dispatch(
              propertiesActions.fetchProperties({}, (s) => setLoading(false))
            )
          }
        )
      )
    } else {
      dispatch(
        filesActions.uploadFile(file as unknown as File, (succ, fileName) => {
          dispatch(
            propertiesActions.updateProperty(
              {
                propertyId: property.id,
                partialProperty: { imageUrl: `${FILES_URL}${fileName}` },
              },
              (succ) => {
                handleClose()
                dispatch(
                  propertiesActions.fetchProperties({}, (s) =>
                    setLoading(false)
                  )
                )
              }
            )
          )
        })
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      PaperProps={{
        style: {
          borderRadius: '16px',
          minWidth: '350px',
          width: 'fit-content',
          maxWidth: 'none',
        },
      }}
      fullWidth
      onClick={(e) => e.stopPropagation()}
    >
      <Grid
        container
        direction="column"
        style={{ padding: '16px', gap: '16px' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            style={{ width: '100%', gap: '4px' }}
            alignItems="center"
          >
            <PlaceIcon style={{ width: '20px', height: '20px' }} />
            <Typography className={styles.addHomeCardHeader} style={{ fontSize: isSmallScreen ? '14px' : '20px' }}>
              SELECT PROPERTY PHOTO
            </Typography>
          </Box>
          <Button className={styles.closeButton} onClick={handleClose}>
            <CloseIcon style={{ width: '14px', height: '14px' }} />
          </Button>
        </Box>

        <Grid item container spacing={2} direction="column">
          {property.imageUrl && !filePath && (
            <Grid item>
              <img
                src={property.imageUrl}
                alt="img"
                style={{
                  width: '100%',
                  maxWidth: '480px',
                  height: '300px',
                  borderRadius: '8px',
                  border: '2px solid var(--bosscat-grey-400)',
                  objectFit: 'contain',
                  boxShadow:
                    '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
                }}
              />
            </Grid>
          )}
          {filePath ? (
            filePath !== 'google-image' ? (
              <>
                <Grid item>
                  <img
                    src={filePath}
                    alt="img"
                    style={{
                      maxWidth: '480px',
                      height: '300px',
                      borderRadius: '8px',
                      border: '2px solid var(--bosscat-grey-400)',
                      objectFit: 'contain',
                      boxShadow:
                        '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    startIcon={
                      <ImageIcon style={{ width: '20px', height: '20px' }} />
                    }
                    style={{
                      color: 'var(--text-text-info)',
                      padding: 0,
                      minHeight: 0,
                      height: 'fit-content',
                    }}
                    onClick={handleReplaceImage}
                  >
                    <Typography
                      className={styles.title}
                      style={{ textTransform: 'none', fontWeight: 700 }}
                    >
                      Upload New Photo
                    </Typography>
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom="16px"
                  style={{ gap: '8px' }}
                >
                  <Typography className={styles.title}>Images from</Typography>
                  <img src={googleMapStr} alt="google map str" />
                </Box>
                <FormControl className={styles.formContainer}>
                  <RadioGroup
                    onChange={changeImage}
                    value={selectedImage}
                    defaultValue={selectedImage || ''}
                  >
                    <Grid
                      item
                      container
                      spacing={2}
                      // style={{ flexWrap: 'nowrap' }}
                    >
                      {property.files
                        .filter((file) => !file.fileUrl.endsWith('unknown'))
                        .slice(0, 3)
                        .map((image, index) => (
                          <Grid item style={{ position: 'relative' }}>
                            <img
                              key={index}
                              src={image.fileUrl}
                              alt="selector"
                              style={{
                                width: '320px',
                                height: '200px',
                                objectFit: 'contain',
                                borderRadius: '8px',
                                border: '2px solid var(--bosscat-grey-400)',
                                boxShadow:
                                  '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)',
                              }}
                            />
                            <FormControlLabel
                              value={image?.fileUrl}
                              control={<Radio />}
                              label="Select"
                              key={image?.fileUrl}
                              className={styles.radioButton}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </>
            )
          ) : (
            <>
              <Grid item>
                <Button
                  className={styles.whiteButton}
                  style={{
                    padding: '10px 16px',
                    color: 'var(--bosscat-black-600)',
                  }}
                  endIcon={<img src={blackUpload} alt="upload" />}
                  onClick={handleFileSelectClick}
                  fullWidth
                >
                  <Typography className={styles.lgFont}>
                    Upload My Property Photo
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={styles.whiteButton}
                  style={{
                    padding: '10px 16px',
                    color: 'var(--bosscat-black-600)',
                  }}
                  endIcon={
                    <img
                      src={googleMapStr}
                      style={{ height: '20px' }}
                      alt="upload"
                    />
                  }
                  onClick={handleGoogleImageSelectClick}
                  fullWidth
                >
                  <Typography className={styles.lgFont}>Select from</Typography>
                </Button>
              </Grid>
            </>
          )}
          {filePath && (
            <Grid
              item
              container
              // style={{ flexWrap: 'nowrap' }}
              justifyContent="space-between"
              direction={isSmallScreen ? 'column' : 'row'}
              spacing={2}
            >
              <Grid item>
                <Button
                  className={styles.whiteButton}
                  style={{ width: isSmallScreen ? '100%' : '236px' }}
                  onClick={handleReplaceImage}
                  startIcon={
                    <ArrowBack style={{ width: '16px', height: '16px' }} />
                  }
                >
                  <Typography className={styles.lgFont}>Back</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={styles.primaryButton}
                  style={{ width: isSmallScreen ? '100%' : '236px' }}
                  disabled={loading}
                  endIcon={
                    !loading && (
                      <Save style={{ width: '16px', height: '16px' }} />
                    )
                  }
                  onClick={handleSave}
                >
                  <Typography className={styles.lgFont}>
                    {loading ? 'Saving...' : 'Save Photo'}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <input
        ref={inputRef}
        style={{ visibility: 'hidden' }}
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        onChange={handleUpload}
      />
    </Dialog>
  )
}

export default SelectPropertyPictureModal
