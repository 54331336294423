import { FC } from 'react'
import { Typography, Box, Button } from '../../..'
import { newEstimate, newJob, quote } from '../../../../../assets'
import { ZeroResultViewProps } from './types'
import useStyles from './styles'
import { useClassName } from '../../../../../hooks'

/**
 * @param title `string` title of the component
 * @param description `string` description of the component
 * @param showButton `boolean` shows or not the button
 * @param buttonText `string` text to render on the button
 * @param onClick `function` this is executed when the button is clicked
 * @returns `JSX.Element`
 */
const ZeroResultView: FC<ZeroResultViewProps> = ({
  title,
  description,
  showButton,
  buttonText = 'Click Me',
  onClick = () => {},
  customButtonRender,
  customIlustrationRender,
  descriptionClassName,
  titleClassName
}) => {
  const classes = useStyles()
  const className = useClassName()

  return (
    <Box className={classes.root}>
      <Box className={classes.contianer}>
        {customIlustrationRender ? (
          customIlustrationRender
        ) : (
          <img src={title?.toString().toLowerCase().includes('job') ? newJob : newEstimate} alt='quote icon' className={classes.ilustration} />
        )}
        <Box className={classes.containerDescription}>
          <Typography
            className={className(
              classes.title,
              titleClassName ? titleClassName : ''
            )}
            component={'span'}
          >
            {title}
          </Typography>
          <Typography
            className={className(
              classes.description,
              descriptionClassName ? descriptionClassName : ''
            )}
            component={'span'}
          >
            {description}
          </Typography>
          {showButton && !customButtonRender ? (
            <Button
              variant='contained'
              type='submit'
              className={classes.button}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          ) : (
            customButtonRender
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ZeroResultView
