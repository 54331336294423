import { FC, useEffect } from 'react'
import { Box, Button, Dialog, Grid, Typography } from '../../../../../UI'
import { PurchaseModalProps } from './types'
import CloseIcon from '@material-ui/icons/Close'
import CartIcon from '@material-ui/icons/LocalGroceryStoreOutlined'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import useStyles from './styles'
import { DashboardContext } from '../../context.provider'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimates, getJobs } from 'ducks/selectors'
import { estimatesActions, jobsActions } from 'ducks/actions'
import EntryView from './EntryView'
import { consultationDiscuss } from 'assets/index'
import { ESTIMATE_STATUS } from 'helpers/constants'

const EstimateJobsModal: FC<PurchaseModalProps> = ({
  open,
  setOpen,
  title,
  titleColor,
  icon,
  isEstimate,
}) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const estimates = useSelector(getEstimates())
  const jobs = useSelector(getJobs())

  const data = isEstimate ? estimates : jobs

  const handleRequestNew = () => {
    window.open('/estimate')
  }

  useEffect(() => {
    if (isEstimate) {
      dispatch(
        estimatesActions.fetchEstimates({
          status: ['Completed', 'In Progress', 'Composing', 'In Review', 'Released', 'Not Submitted', 'New'],
          sortBy: 'createdOn',
          sortOrder: 'desc',
        })
      )
    } else {
      dispatch(
        jobsActions.fetchJobs({
          status: [
            'In Progress',
            'Scheduling',
            'Dispatched',
            'Hold',
            'Needs Attention',
          ],
          sortBy: 'createdOn',
          sortOrder: 'desc',
        })
      )
    }
  }, [isEstimate])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      fullWidth
      maxWidth={data.length ? 'lg' : 'md'}
      PaperProps={{
        style: {
          overflow: 'hidden',
          borderRadius: '16px',
          height: 'fit-content',
        },
      }}
    >
      <Grid
        container
        direction="column"
        style={{
          padding: '16px 4px 16px 16px',
          height: '100%',
          flexWrap: 'nowrap',
          width: '100%',
        }}
        spacing={2}
      >
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              style={{ width: '100%', gap: '4px', color: titleColor }}
              alignItems="center"
            >
              {icon}
              <Typography className={styles.addHomeCardHeader}>
                {title}
              </Typography>
            </Box>
            <Button className={styles.closeButton} onClick={handleClose}>
              <CloseIcon style={{ width: '14px', height: '14px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          style={{
            overflow: 'overlay',
            maxHeight: '700px',
            flexFlow: 'column',
          }}
        >
          {data.length ? (
            <>
              {data.map((item) => (
                <EntryView item={item} isEstimate={isEstimate} />
              ))}

              <Typography
                className={styles.latoSm}
                style={{ marginTop: '24px', marginLeft: '4px' }}
              >
                Active estimates list all estimates in progress or ready for
                review. <br />
                Completed jobs, expired estimates will not show here. Please
                click the estimates section to view all historical.
              </Typography>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              style={{ gap: '16px' }}
            >
              <img src={consultationDiscuss} alt="empty state" />
              {isEstimate ? (
                <Typography
                  className={styles.lgFont}
                  style={{ textAlign: 'center', color: 'var(--text-text-primary)' }}
                >
                  You currently have no active estimates .
                  <br />
                  Let’s request a new estimate.
                </Typography>
              ) : (
                <Typography
                  className={styles.lgFont}
                  style={{ textAlign: 'center', color: 'var(--text-text-primary)' }}
                >
                  No Active Jobs at this time.
                  <br />
                  Our BOSSCAT Home Specialists are ready to assist you
                </Typography>
              )}
              {isEstimate && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={
                      <ArrowForwardIcon
                        style={{ width: '16px', height: '16px' }}
                      />
                    }
                    style={{ marginTop: '8px' }}
                    fullWidth
                    onClick={handleRequestNew}
                  >
                    <Typography
                      className={styles.lgFont}
                      style={{ textTransform: 'none' }}
                    >
                      Request Estimate
                    </Typography>
                  </Button>
                </>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default EstimateJobsModal
