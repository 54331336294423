import { useEffect, FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import env from '@beam-australia/react-env'
import { PaymentStatus, Receipt } from '../../../../UI'
import { history, isEmpty } from '../../../../../helpers'
import {
  getNewEstimateValue,
  getNewEstimateData,
  getNewEstimatePaymentOptionById,
  getNewEstimateExpediteOption
} from '../../../../../ducks/selectors'
import { configActions, newEstimateActions } from '../../../../../ducks/actions'
import { toast } from 'react-toastify'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

const clientSecretIntent = new URLSearchParams(window.location.search).get(
  'payment_intent_client_secret'
) ?? ''

const ReceiptScreen: FC = () => {
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const estimate = useSelector(getNewEstimateData())
  const clientSecret = useSelector(getNewEstimateValue('clientSecret'))
  const isExpedite = useSelector(getNewEstimateValue('isExpedite'))
  const paymentOption = useSelector(getNewEstimatePaymentOptionById(estimate?.deliveryServiceId as string))
  const expediteOption = useSelector(getNewEstimateExpediteOption())

  useEffect(() => { if (isEmpty(estimate)) replace('/') }, [estimate, replace])

  const handleExpediteSuccess = useCallback(() => {
    dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: true }))
    dispatch(newEstimateActions.updateEstimatePaymentOption(expediteOption,
      (success, estimateId) => {
        if (success && estimateId) {
          toast.success('Estimate Updeated!')
          dispatch(newEstimateActions.setNewEstimateValues({
            attrs: { clientSecret: '', isExpedite: false, paymentOption: expediteOption }
          }))
        }
        dispatch(configActions.setConfigValue({ type: 'overlayLoading', value: false }))
      }))
  }, [dispatch, expediteOption])

  useEffect(() => {
    if (isExpedite && clientSecretIntent === clientSecret) {
      handleExpediteSuccess()
    }
  }, [clientSecret, handleExpediteSuccess, isExpedite])

  const handleSaveExpediteInfo = (clientSecret: string) => {
    dispatch(newEstimateActions.setNewEstimateValues({
      attrs: { clientSecret, isExpedite: true }
    }))
  }

  if (isEmpty(estimate)) return <div />

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: clientSecretIntent ?? '' }}>
      <PaymentStatus
        clientSecret={clientSecretIntent}
        clear={() => dispatch(newEstimateActions.cleanNewEstimate())}
      >
        <Receipt
          estimate={estimate}
          paymentOption={paymentOption}
          expediteOption={expediteOption}
          onExpediteSuccess={handleExpediteSuccess}
          saveExpediteInfo={handleSaveExpediteInfo}
        />
      </PaymentStatus>
    </Elements>
  )
}
export default ReceiptScreen
