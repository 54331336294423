/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { Punchlist } from 'api'
import * as Sentry from '@sentry/react'
import { ClientQuestionsActions, clientQuestionsTypes } from './types'

export function* createClientQuestion({ payload, callback }: ClientQuestionsActions): SagaIterator {
  let success = false
  try {
    const clientQuestions = yield call(Punchlist.clientQuestions.createClientQuestion, payload)
    if (clientQuestions) { success = true }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(clientQuestionsTypes.CREATE_CLIENT_QUESTION, createClientQuestion)
}
