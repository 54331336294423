import { Grid, Typography } from '@material-ui/core'
import { SelectAddress } from 'components/UI'
import { newOrderMaintenanceActions, territoriesActions } from 'ducks/actions'
import {
  getNewOrderMaintenanceValue,
  getNewOrderMaintenanceValues,
  getTerritory
} from 'ducks/selectors'
import { territoryIsAvailableForMaintenance } from 'ducks/subscriptions/selectors'
import { Address } from 'ducks/types'
import {
  isEmpty
} from 'helpers/index'
import { useStepperContext } from 'hooks/useStepperContext'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'

const HomeToBeServiced = () => {
  const dispatch = useDispatch()
  const gift = useSelector(getNewOrderMaintenanceValue('gift'))

  const { showWarnings, setCanGoForward, setShowWarnings } =
    useStepperContext()
  const propertyAddress = useSelector(
    getNewOrderMaintenanceValue('propertyAddress')
  )
  const [loadingAddress, setLoadingAddress] = useState(false)
  const territory = useSelector(getTerritory())
  const orderMaintenanceValues = useSelector(
    getNewOrderMaintenanceValues(),
    shallowEqual
  )
  const cityIsAvailableForMaintenance = useSelector(
    territoryIsAvailableForMaintenance(territory?.id)
  )

  const validateAddress: boolean =
    !isEmpty(propertyAddress?.line_1) &&
    propertyAddress?.line_1 !== ' ' &&
    !!propertyAddress?.zipCode &&
    propertyAddress.latitude &&
    propertyAddress.longitude &&
    cityIsAvailableForMaintenance &&
    !loadingAddress

  // const validate: boolean = validateAddress &&
  //   !isEmpty(orderMaintenanceValues.homeowner?.firstName) &&
  //   !isEmpty(orderMaintenanceValues.homeowner?.lastName) &&
  //   (orderMaintenanceValues.homeowner?.phone ? PHONE_MASK_REGEXP_NO_SCOPE.test(orderMaintenanceValues.homeowner?.phone?.toString()) || PHONE_MASK_REGEXP.test(orderMaintenanceValues.homeowner?.phone?.toString()) : false) &&
  //   !isEmpty(orderMaintenanceValues.mainContact?.email?.[0]?.email) &&
  //   validateEmail(email[0]?.email)

  const handleChange = (newAddress: Address) => {
    if (newAddress.zipCode) {
      dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'propertyAddress', value: newAddress }))
    }
  }

  useEffect(() => { setShowWarnings(false) }, [])
  useEffect(() => {
    if (isMobile) {
      setCanGoForward(validateAddress)
    } else {
      setCanGoForward(true)
    }
  }, [validateAddress])

  useEffect(() => {
    dispatch(newOrderMaintenanceActions.setNewOrderMaintenanceValue({ attr: 'disableNext', value: !cityIsAvailableForMaintenance }))
  }, [cityIsAvailableForMaintenance])


  useEffect(() => {
    if (
      !orderMaintenanceValues.gift &&
      propertyAddress?.zipCode &&
      !territory?.zipCodes?.includes(propertyAddress?.zipCode)
    ) {
      dispatch(territoriesActions.fetchTerritory(propertyAddress?.zipCode))
    }
  }, [orderMaintenanceValues, dispatch])

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1rem' }}
        className={styles.HomeToBeServiced}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item lg={12} xs={12} className={styles.addressContainer}>
            {/* <Typography variant="h5">Address</Typography> */}
            <SelectAddress
              showWarnings={showWarnings && !validateAddress}
              savedAddress={propertyAddress}
              onChange={handleChange}
              customClass={styles.SelectAddress}
              unitNumberClassName={styles.unitNumber}
              showUnitNumberBesideAddress
              placeholder="Enter Home Address"
              showVertical={isMobile}
              title={
                <Typography variant="h6" className={styles.SelectAddress__title}>
                  {gift ? 'Address for the maintenance service' : 'Your home address'}
                </Typography>
              }
              scrollOnFocus
              showMap={false}
              onCallback={() => setLoadingAddress(false)}
            />
          </Grid>
          {/* <Grid item lg={6} xs={12}>
          <FourSeasonsSlider />
        </Grid> */}
        </Grid>
      </Grid>
    </>
  )
}

export default HomeToBeServiced
