import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.grey[200],
      borderRadius: 12,
      height: 91,
      paddingTop: 5
      // minWidth: '170px'
    },
    primaryColor: {
      color: theme.palette.primary.main
    },
    secondaryColor: {
      color: theme.palette.primary.light
    },
    informationColor: {
      color: theme.palette.primary.light
    },
    successColor: {
      color: 'var(--bosscat-green-600)'
    },
    warningColor: {
      color: theme.palette.warning.dark
    },
    errorColor: {
      color: theme.palette.error.main
    },
    approvedColor: {
      color: 'var(--approved-color)'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      borderRadius: 8,
      backgroundColor: theme.palette.background.default,
      left: 10,
      top: -24,
      padding: '0px 8px',
      [theme.breakpoints.down('lg')]: {
        top: -16
      },
      [theme.breakpoints.down('lg')]: {
        top: -24,
      }
    },
    title: {
      fontWeight: 700,
      fontFamily: 'NextArtHeavy !important',
      fontSize: '16px',
      lineHeight: '150%',
      textTransform: 'uppercase',
      [theme.breakpoints.down('lg')]: {
        fontSize: 12
      },
    },
    value: {
      fontWeight: 900,
      fontFamily: 'NextArtHeavy',
      fontSize: 48,
      lineHeight: '72px',
      color: 'var(--input-color)',
      textAlign: 'center'
    },
    informationIcon: {
      position: 'absolute',
      top: 9,
      right: 9,
      fontSize: 16,
      color: theme.palette.grey[400],
      cursor: 'pointer'
    },
    icon: {
      fontSize: 16,
      marginRight: '10px',
      marginBottom: '3px'
    }
  })
)
