import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#FFF9EA',
      padding: '.5rem !important',
      flexDirection: 'column',
      borderRadius: 8,
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem'
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: 20,
    },
    subtitle: {
      fontSize: 14,
    },
    container: {
      // display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    button: {
      background: '#2F7BF7',
      color: 'white',
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      height: 40,
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)'
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    expandButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    iconOpened: {

    },
    iconClosed: {
      transform: 'rotate(180deg)'
    },

    modal: {
      '@global': {
        '.MuiDialogTitle-root': {
          position: 'absolute',
          right: 0
        },
        /* '.MuiDialog-paperWidthSm': {
          maxWidth: '500px !important'
        } */
      }
    },
    categoryContainer: {
      padding: '.5rem 0'
    },
    subHeader: {
      // backgroundColor: 'var(--progress-color-back)',
      borderRadius: '8px',
      padding: 0,
      position: 'static'
    },
    categoryTitle: {
      fontFamily: 'NextArtHeavy',
      color: 'var(--bosscat-blue-600)',
      marginRight: '10px'
    },
    categoryHeader: {
      display: 'flex',
      gap: '.2rem'
    },
    price: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '58px',
      color: 'var(--head-text-color)'
    },
    buttonAdd: {
      minWidth: '86px',
      height: '48px',
      textTransform: 'none',
      color: '#01AA59',
      fontWeight: 700,
      backgroundColor: '#E6F7EE',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      margin: '5px',
      '&:hover': {
        backgroundColor: '#E6F7EE',
      }
    },
    addAllButton: {
      color: '#01AA59',
      fontWeight: 700,
      textTransform: 'none',
      transition: '300ms all',

      '&:hover': {
        backgroundColor: 'transparent',
        color: 'rgba(26, 42, 85, 1)',
        transition: '300ms all'
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '1rem'
        },
        '.MuiButton-startIcon': {
          marginRight: '4px'
        }
      }
    },
    buttonRemove: {
      textTransform: 'none',
      fontWeight: 900,
      borderRadius: 8,
      backgroundColor: 'white !important',
      color: '#0B060F',
      boxShadow: '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
      minWidth: 86,
      height: 48,
      margin: '5px',
      '&:hover': {
        backgroundColor: 'var(--bosscat-blue-700)'
      }
      // border: '2px solid black',
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    expandButton: {
      width: 'fit-content',
      height: 'fit-content',
      textTransform: 'inherit',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'transparent !important'
      },
      '@global': {
        '.MuiTypography-root': {
          fontSize: 12,
          fontWeight: 900,
          color: '#2F7BF7',
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.5rem',
        }
      }
    },
  })
)
