import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto 0',
      paddingRight: '24px',
    },
    backButton: {
      position: 'absolute',
      left: '4px',
      top: '0px',
      height: '44px',
    },
    lgFont: {
      fontSize: '18px',
      fontFamily: 'LatoNormal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: 28,
      color: '#A5965A',
      margin: "0.8rem 0 0.8rem 0",
      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        marginTop: '1rem'
      }
    },
    dropzone: {
      minHeight: '300px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '180px'
      },
      '@global': {
        '.MuiDropzoneArea-text': {
          fontSize: 16,
          fontFamily: 'Lato'
        }
      }
    },
    addendum: {
      paddingRight: '0px !important',
    },
    textArea: {
      padding: "16px 24px"
    },
    label: {
      paddingBottom: '8px',
      fontFamily: 'LatoBlack',
      fontSize: "18px",
      color: "#0B060F",
    },
    buttonBack: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    buttonNext: {
      width: '185px',
      height: '58px',
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '135px'
      }
    },
    repairTimeTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    flexibleCheck: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '0px'
    },
    clientNotes: {
      display: 'flex',
      flexDirection: 'column',
      '@global': {
        '.MuiFormControl-root': {
          flex: 1
        }
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px'
      }
    },
    consent: {
      flexWrap: 'nowrap'
    },
  })
)
