import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '392px',
      borderRadius: '16px',
      textAlign: 'center',
      // padding: '32px 32px 16px 32px'
    },
    submitContainer: {
      backgroundColor: theme.palette.grey[100],
      position: 'relative',
      width: '100%',
      height: 76,
      padding: '16px'
    },
    submit: {
      textTransform: 'none',
      color: 'var(--white-color)',
      backgroundColor: 'var(--bosscat-blue-600)',
      borderRadius: 8,
      width: '360px',
      height: '44px',
      left: 16,
      fontFamily: 'LatoNormal',
      position: 'absolute',
    },
    logo: {
      margin: '32px auto 0px auto',
    },
    title: {
      fontFamily: 'Lato',
      fontSize: '20px',
      margin: '0px 35px',
    },
    description: {
      fontFamily: 'LatoLight',
      fontSize: '18px',
      fontWeight: 400,
      margin: '10px 35px',
    },
    moreInformation: {
      fontFamily: 'LatoLight',
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 24
    }
  })
)
