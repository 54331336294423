import { authTypes } from 'ducks/auth/types'
import { Property } from 'ducks/types'
import { PropertyContactsType, PropertyContactsActions, propertyContactType } from './types'

const initialState: PropertyContactsType = []

const reducer = (state = initialState, action: PropertyContactsActions): PropertyContactsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case propertyContactType.SET_PROPERTY_CONTACTS: {
      return payload as PropertyContactsType
    }

    default:
      return state
  }
}

export default reducer
