import { Item } from 'ducks/types'
import {
  JobItemValues,
  UpdateJobItemPayload,
  JobItemsActions,
  jobItemsTypes
} from './types'

const actions = {
  fetchJobItems: (
    payload: string,
    callback?: (succ: boolean) => void
  ): JobItemsActions => ({
    type: jobItemsTypes.FETCH_JOB_ITEMS,
    payload,
    callback
  }),
  setJobItems: (payload: Item[]): JobItemsActions => ({
    type: jobItemsTypes.SET_JOB_ITEMS,
    payload
  }),
  setJobItemValues: (payload: JobItemValues): JobItemsActions => ({
    type: jobItemsTypes.SET_JOB_ITEM_VALUES,
    payload
  }),
  updateJobItem: (
    payload: UpdateJobItemPayload,
    callback?: (succ: boolean) => void
  ): JobItemsActions => ({
    type: jobItemsTypes.UPDATE_JOB_ITEM,
    payload,
    callback
  })
}

export default actions
