import { createStyles, makeStyles, Theme } from '../../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      // padding: '20px 40px',
    },
    indicator: {
      backgroundColor: '#EDFDF6',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
      borderRadius: 8
    },
    name: {
      fontSize: 16,
      fontWeight: 700,
      marginRight: 5
    },
    text: {
      fontWeight: 400,
      fontSize: 16
    },
    icon: {
      marginRight: 10,
      fill: '#0E905A'
    }
  })
)
