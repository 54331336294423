import { Theme, createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    list: {
      // margin: '20px 0px',
      padding: '.5rem !important'
    },
    subHeader: {
      // backgroundColor: 'var(--progress-color-back)',
      borderRadius: '8px',
      padding: 0,
      position: 'static'
    },
    categoryContainer: {
      padding: '.5rem 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem'
    },
    listItem: {
      justifyContent: 'space-between'
    },
    item: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoNormal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    },
    itemType: {
      color: 'var(--head-text-color)',
      fontFamily: 'LatoBold',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    titleContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    categoryTitle: {
      fontFamily: 'NextArtHeavy',
      color: 'var(--head-text-color)',
      marginRight: '10px'
    },
    title: {
      fontFamily: 'NextArtHeavy',
      fontSize: 20,
      // marginBottom: '1rem'
    },
    categoryHeader: {
      display: 'flex',
      gap: '.2rem'
    },
    evaluateDisclamerBox: {
      padding: '12px 16px',
      margin: '0.25rem',
      borderRadius: '6px',
      color: 'var(--punchlist-red)',
      border: '1px solid var(--punchlist-red)'
    },
    evaluateDisclamerIcon: {
      float: 'left',
      height: '20px',
      marginRight: '6px'
    },
    iconOpened: {

    },
    iconClosed: {
      transform: 'rotate(180deg)'
    },
    addAllButton: {
      color: '#01AA59',
      fontWeight: 700,
      textTransform: 'none',
      transition: '300ms all',

      '&:hover': {
        backgroundColor: 'transparent',
        color: 'rgba(26, 42, 85, 1)',
        transition: '300ms all'
      },
      '@global': {
        '.MuiSvgIcon-root': {
          fontSize: '1rem'
        },
        '.MuiButton-startIcon': {
          marginRight: '4px'
        }
      },
    },
    evaluateDisclamerText: {
      fontSize: '13px',
      fontStyle: 'italic',
      fontFamily: 'LatoNormal',
      fontWeight: 400,
      lineHeight: '21px',
      color: 'var(--punchlist-red)'
    },
    evaluateContainer: {

    },
    icon: {
      color: 'var(--head-text-color)',
      // margin: '18px 8px',
      width: '22px',
      height: '22px'
    },
    expandButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 'fit-content',
      }
    },
    expandButton: {
      width: 'fit-content',
      height: 'fit-content',
      '&:hover': {
        backgroundColor: 'transparent !important'
      },
      '@global': {
        '.MuiTypography-root': {
          fontSize: 12,
          fontWeight: 900,
          color: '#2F7BF7',
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.5rem',
        }
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 'fit-content',
        padding: 0
      }
    },
    iconMobile: {
      fontSize: '1rem'
    },
  })
)
