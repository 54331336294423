import { FC, memo } from 'react'

import { Grid, Typography, Button } from '../../..'
import { MonthControllerProps } from './types'
import useStyles from './styles'
import { months } from '../../../../../helpers'
import { useClassName } from '../../../../../hooks'

const UpperMonthController: FC<MonthControllerProps> = ({
  month,
  setMonth
}) => {
  const classes = useStyles()
  const className = useClassName()

  return (
    <Grid container item xs={12} className={classes.chartRow}>
      {months.map((item: string, index: number) => (
        <Grid container item xs={2} md={1} key={index}>
          <Button
            type='submit'
            variant='contained'
            size='small'
            className={
              index === month ? classes.buttonSelected : classes.button
            }
            onClick={() => setMonth(index)}
          >
            <Typography
              className={className(
                index === month ? classes.monthSelected : '',
                classes.month
              )}
            >
              {item}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(UpperMonthController)
