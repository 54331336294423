import {
  ApprovalActions,
  ApprovalValue,
  ApprovalValues,
  approvalTypes,
} from './types'

const actions = {
  cleanApproval: (): ApprovalActions => ({
    type: approvalTypes.CLEAN,
    payload: null,
  }),
  setApprovalStep: (payload: number): ApprovalActions => ({
    type: approvalTypes.SET_STEP,
    payload,
  }),
  setApprovalValue: (payload: ApprovalValue): ApprovalActions => ({
    type: approvalTypes.SET_VALUE,
    payload,
  }),
  setApprovalValues: (payload: ApprovalValues): ApprovalActions => ({
    type: approvalTypes.SET_VALUES,
    payload,
  }),
}

export default actions
