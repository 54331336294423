import axios from 'axios'
import { GetAccessTokenParams, GetUserInfoParams } from 'ducks/docusign/types'
import { ApiController } from './types'

const apiController: ApiController = {
  getAccessToken: async ({ docusignUrl, code, token }: GetAccessTokenParams) =>
    await axios
      .post(docusignUrl, {
        "grant_type": "authorization_code",
        "code": code
      }, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      })
      .then(response => response),
  getUserInfo: async ({ docusignUrl, token }: GetUserInfoParams) =>
    await axios
      .get(docusignUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response)
}

export default apiController
