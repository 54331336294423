import { authTypes } from 'ducks/auth/types'
import { DiscountsActions, DiscountsType, DiscountsValue, discountsTypes } from './types'

const initialState: DiscountsType = {
  discountBanner: null,
}

const reducer = (state = initialState, action: DiscountsActions): DiscountsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case discountsTypes.SET_VALUE: {
      const { attr, value } = payload as DiscountsValue
      return {
        ...state,
        [attr]: value
      }
    }

    default:
      return state
  }
}

export default reducer
