import { Button, Grid, Typography } from "@material-ui/core"
import { CONTACT_ROLE } from "helpers/constants"
import { FC } from 'react'
import { ContactCardProps } from "./types"
import useStyles from './styles'

import { icons } from 'assets'

const ContactCard: FC<ContactCardProps> = ({ index, showWarnings, handleChange, contact, onEdit, onDelete, showButtons = true }) => {
    const classes = useStyles()

    const getTitleColor = (): string => {
        switch (contact.role) {
            case CONTACT_ROLE.HOMEOWNER:
                return classes.homeowner
            case CONTACT_ROLE.HOME_BUYER:
                return classes.homebuyer
            default:
                return classes.role
        }
    }

    return (
        <Grid className={classes.root} container direction="column">
            <Grid container item className={classes.container}>
                <Grid item container direction="column" className={classes.left}>
                    <Typography className={classes.titleContact}>
                        {index + 1}. {contact.firstName} {contact.lastName}
                    </Typography>
                    <Typography className={classes.iconLabel}><icons.Email />{contact.email}</Typography>
                    <Typography className={classes.iconLabel}><icons.Phone />{contact.phone}</Typography>
                </Grid>
                <Grid item className={classes.right} container direction="column" spacing={2}>
                    <Grid item className={`${getTitleColor()} ${classes.roleTag}`}>
                        <Typography>{contact.role?.replaceAll('_', '')?.toLocaleLowerCase()}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {showButtons && <Grid item container spacing={2} className={classes.actions}>
                <Grid item className={classes.icon} xs={6}>
                    <Button
                        type='submit'
                        variant='contained'
                        size='small'
                        className={classes.buttonEdit}
                        endIcon={<icons.Edit />}
                        onClick={onEdit}
                    >
                        Edit
                    </Button>

                </Grid>
                <Grid item className={classes.icon} xs={6}>
                    <Button
                        type='submit'
                        variant='contained'
                        size='small'
                        className={classes.buttonRemove}
                        endIcon={<icons.Delete className={classes.removeIcon} />}
                        onClick={onDelete}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>}
        </Grid>
    )
}

export default ContactCard