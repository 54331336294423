import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    upload: {
      display: 'grid',
      alignItems: 'center',
      minHeight: '150px',
      position: 'relative',
      border: '2px solid var(--border-color)',
      borderRadius: '8px',
      '&:hover': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      },
      '&.Mui-focused': {
        borderColor: 'var(--border-color-focus)',
        borderRadius: 8
      }
    },
    dropzone: {
      display: 'flex',
      border: '2px dashed ' + theme.palette.primary.light,
      backgroundColor: 'rgba(245, 247, 255, 1)',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      minHeight: 'auto',
      margin: '24px',
      top: '-3px',
      bottom: '-3px',
      left: '-3px',
      right: '-3px',
      '& .MuiDropzoneArea-textContainer': {
        color: theme.palette.primary.light,
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
      }
    },
    buttonBox: {
      textAlign: 'end'
    },
    button: {
      width: '185px',
      height: '36px',
      margin: '12px',
      textTransform: 'none',
      alignItems: 'base',
      color: 'var(--bosscat-blue-600)',
      backgroundColor: 'var(--background-color-secondary)',
      borderRadius: 8,
      border: '0px solid',
      [theme.breakpoints.down('sm')]: {
        width: '135px',
      }
    },
    image: {
      width: '16px',
      height: '16px',
      paddingBottom: '2px'
    },
    filesBox: {
      margin: '16px',
      padding: '16px 8px',
      borderRadius: '8px',
      display: 'grid',
      gap: '16px',
      backgroundColor: 'var(--background-color-secondary)'
    },
    file: {
      display: 'flex',
      padding: '12px',
      margin: '0 8px',
      borderRadius: '8px',
      gap: '8px',
      alignItems: 'center',
      backgroundColor: 'var(--white-color)',
      position: 'relative'
    },
    delete: {
      position: 'absolute',
      right: '16px'
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    errorColor: { borderColor: 'var(--error-color)' }
  })
)
