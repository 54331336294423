/** TYPES **/
export const promoTypes = {
  GET_PROMO: 'GET_PROMO'
}

export interface Promo {
  id: string,
  title: string,
  deliveryDays: number,
  amount: number,
  active: boolean,
  maxUses: number,
  totalUses: number,
  createdOn: number,
  createdBy: number,
  resourceType: string,
  percentOff: number
}

/** ACTIONS **/
export interface GetPromoAction {
  type: typeof promoTypes.GET_PROMO
  payload: {
    promoCode: string,
    stripeProductPriceId: string
  }
  callback?: (succ: boolean, promo: Promo | null) => void
}


export type PromoActions =
  | GetPromoAction
