import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      minHeight: '100vh',
      [theme.breakpoints.down('sm')]: {
        gridTemplateRows: 'auto',
      }
    },
    header: {
      padding: '30px 0px'
    },
    title: {
      padding: '20px 0px 20px 50px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 0px 20px 70px'
      }
    }
  })
)
