import { Dialog, DialogContent, Typography, Grid, Button, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core'

import useStyles from './styles'
import { EditStatesModalProps } from './types'

import { computer } from '../../../../../assets'
import { states } from '../states'
import { useEffect, useState } from 'react'

const EditStatesModal = ({ open, setOpen, email, userId, selectedStates, action }: EditStatesModalProps) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [newStates, setNewStates] = useState<Array<string>>()

  useEffect(() => {
    selectedStates && setNewStates(selectedStates)
  }, [selectedStates])

  const handleClick = () => {
    setLoading(true)
    action({ userId, newStates })
  }

  const handleOnChange = (state: string) => {
    setHasChanged(true)
    if (newStates?.includes(state)) {
      setNewStates(newStates?.filter(s => s !== state))
    } else {
      setNewStates([...newStates as Array<string>, state])
    }
  }

  return (
    <Dialog onClose={() => setOpen && setOpen(!open)} open={open} classes={{ paper: `${classes.root}` }}>
      <DialogContent className={classes.header}>
        {loading && (
          <Grid container justifyContent='center' alignItems='center' className={classes.loading}>
            <CircularProgress />
          </Grid>)}
        <Grid container spacing={2} direction='column' className={classes.container}>
          <Grid container justifyContent='center' alignItems='center' className={classes.headerContainer}>
            <img alt='add new member icon' src={computer} />
            <Typography className={classes.title} align='center'>Edit States for: {email}</Typography>
          </Grid>
          <Grid item className={classes.states}>
            {states.map((state: string, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={newStates?.includes(state)}
                      onChange={() => handleOnChange(state)}
                      name='state'
                      color='primary'
                    />
                      }
                  label={<Typography variant='body2'>{state}</Typography>}
                />
              )
            })}
          </Grid>
        </Grid>
        <Grid className={classes.footer}>
          <Button
            type='submit'
            variant='contained'
            size='large'
            className={`${classes.buttonSubmit} ${classes.button}`}
            onClick={handleClick}
            fullWidth
            disabled={!hasChanged}
          >
            <Typography> Update States </Typography>
          </Button>
          <Button
            variant='text'
            className={`${classes.buttonClose} ${classes.button}`}
            onClick={() => setOpen && setOpen(!open)}
          >
            Cancel
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default EditStatesModal
