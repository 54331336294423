import { Discovery, DiscoveryActions, discoveryTypes } from "./types"

const actions = {
  sendFeedback: (
    payload: Discovery,
    callback?: (succ: boolean) => void
  ): DiscoveryActions => ({
    type: discoveryTypes.SEND_DISCOVERY,
    payload,
    callback
  })
}

export default actions
